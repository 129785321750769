import React, { Component } from 'react'
import { Toast, } from 'antd-mobile'
import Head from '../../../component/Head'
import Nodata from '../../../component/Nodata'
import xt from '../../../static/img/xt.png'
import yh from '../../../static/img/yh.png'
import dd from '../../../static/img/dd.png'
import defaultImg from '../../../static/img/default.png'
import icon1 from "../../../static/img/fanhuihei.png"
import { twoOrderdetails, twoMessage, delMessage } from '../../../api/request/msg'
import getUrl from '../../../component/GetUrlParam'
import './style.less'
import JRoll from 'jroll';
export default class orderDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            visiable: 'none',
            messageList: '',
            mine: ''
        }
        this.deleteOne = this.deleteOne.bind(this);
    }

    // 删除
    deleteOne = async (e, id) => {
        const {userId} = this.state
        e.stopPropagation()
        let arr = []
        let res0 = await delMessage(this.state.userId, id)
        console.log(res0);
        if (res0.code == 1) {
            twoMessage({userId, type3: 1}).then(res => {
                console.log(res);
                if (res.data[1].length) {
                    res.data[1].map(item => {
                        item.orderNumber = item.messageTitle.split(":")[1]
                        item.title = item.messageTitle.split(":")[0]
                        item.pharmacyName = ''
                        item.medPhoto = ''
                        item.pharmacyPhoto = ""
                        item.pharmacyId = ''
                        item.violation = ''
                        twoOrderdetails(item.orderNumber).then(res2 => {
                            console.log(res2);
                            if (res2.data[0].length) {
                                item.pharmacyName = res2.data[0][0].pharmacyName
                                item.pharmacyPhoto = res2.data[0][0].pharmacyPhoto
                                item.pharmacyId = res2.data[0][0].pharmacyId
                            }
                            if (res2.data[1].length) {
                                item.medPhoto = res2.data[1][0].medPhoto
                                item.violation = res2.data[1][0].violation
                            }
                            arr = res.data[1]
                            this.setState({
                                messageList: arr
                            }, () => {
                                // this.onScoll()
                                // window.location.reload()
                            })
                        })

                    })
                    // setTimeout(() => {

                    // }, 500);
                } else {
                    this.setState({
                        messageList: []
                    })
                }
            })
        }
    }

    componentDidMount() {
        const {userId} = this.state
        this.setState({
            mine: getUrl('mine', this.props.location.search)
        })
        let arr = []
        twoMessage({userId, type3: 1}).then(res => {
            console.log(res);
            if (res.data[1].length) {
                let data=[]
                res.data[1].map(item => {
                    item.orderNumber = item.messageTitle.split(":")[1]
                    item.title = item.messageTitle.split(":")[0]
                    item.pharmacyName = ''
                    item.medPhoto = ''
                    item.pharmacyPhoto = ""
                    item.pharmacyId = ''
                    item.violation = ''
                    data.push(item.orderNumber)
                })
                twoOrderdetails({orderNumber:data}).then(res2 => {
                    console.log(res2);
                    if (res2.data[0].length) {
                        res.data[1].map(item => {
                            res2.data[0].map(i => {
                                if (item.orderNumber==i.orderNumber){
                                    item.pharmacyName = i.pharmacyName
                                    item.pharmacyPhoto = i.pharmacyPhoto
                                    item.pharmacyId = i.pharmacyId
                                    item.pharmacyState = i.pharmacyState
                                }

                            })
                            res2.data[1].map(i => {
                                if (item.orderNumber==i.orderNumber){
                                    item.medPhoto = i.medPhoto
                                    item.violation = i.violation
                                }

                            })
                        })
                    //     item.pharmacyName = res2.data[0][0].pharmacyName
                    //     item.pharmacyPhoto = res2.data[0][0].pharmacyPhoto
                    //     item.pharmacyId = res2.data[0][0].pharmacyId
                    //     item.pharmacyState = res2.data[0][0].pharmacyState
                    // }
                    // if (res2.data[1].length) {
                    //     item.medPhoto = res2.data[1][0].medPhoto
                    //     item.violation = res2.data[1][0].violation
                    }
                    arr = res.data[1]
                })
                setTimeout(() => {
                    this.setState({
                        messageList: arr
                    }, () => {
                        // this.onScoll()
                        console.log(arr)
                    })
                }, 500);
            }
        })
    }

    // onScoll = () => {
    //     var w = Math.floor(document.getElementsByClassName('del')[0].offsetWidth / 2);
    //     // console.log(w);//‘删除’按钮的一般宽度。
    //     // 创建外层jroll实例
    //     var jroll = new JRoll("#wrapper", {
    //         scrollBarY: true
    //     });
    //
    //     var items = document.querySelectorAll(".item");
    //     var current = null; //保存当前滑开的item
    //     for (var i = 0, l = items.length; i < l; i++) {
    //         // 每行创建jroll实例
    //         var j = new JRoll(items[i], {
    //             scrollX: true,
    //             bounce: false
    //         });
    //
    //         j.on("scrollStart", function () {
    //             if (current && current !== this) {
    //                 current.scrollTo(0, 0, 100);
    //                 current = null;
    //             }
    //         });
    //
    //         j.on("scroll", function (e) {
    //             if (this.x === 0 && !current) {
    //                 this.call(jroll, e);
    //             } else {
    //                 current = this;
    //             }
    //         });
    //
    //         j.on("scrollEnd", function () {
    //             if (this.x > -w) {
    //                 this.scrollTo(0, 0, 100);
    //                 current = null;
    //             } else {
    //                 this.scrollTo(this.maxScrollX, 0, 100);
    //             }
    //         })
    //     };
    // }
    toPhatmacy = (pharmacyId, pharmacyState) => {
        const {messageList, mine} = this.state
        if (pharmacyState == 1) {
            this.props.history.replace(`/orderDelivery?mine=${mine}`)
            this.props.history.push(`/shop/${pharmacyId}?delivery=${1}&mine=${mine}`)
        } else {
            Toast.info('店铺已下架')
        }
    }
    toOrder = (orderNumber, pharmacyState) => {
        const {messageList, mine} = this.state
        if (pharmacyState == 1) {
            this.props.history.replace(`/orderDelivery?mine=${mine}`)
            this.props.history.push(`/mine/order/id?tradeno=${orderNumber}&delivery=${1}&mine=${mine}`)
        } else {
            Toast.info('店铺已下架')
        }
    }

    render() {
        const {messageList, mine} = this.state
        console.log(messageList)
        return (
            <>
                <div className='orderDelivery-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push(`/tidings?mine=${mine}`)}/> 订单配送
                </div>
                {/*<div id="wrapper" >*/}
                {/*    <div className="scroller">*/}
                {/*        /!*<div id="valid">*!/*/}
                {/*            {messageList.length ? messageList.map((item, index) => (*/}
                {/*                <div id="valid">*/}
                {/*                    {item.pharmacyId ? <div key={index} className="item">*/}
                {/*                        <p className='item-scroller'>*/}
                {/*                            <p className='item-scroller2'>*/}
                {/*                                <p className='orderDelivery-outer' onClick={() => this.toPhatmacy(item.pharmacyId,item.pharmacyState)}>*/}
                {/*                                    <p>*/}
                {/*                                        <img src={item.pharmacyPhoto} alt="" />*/}
                {/*                                        <span>{item.pharmacyName}</span>*/}
                {/*                                    </p>*/}
                {/*                                    <span>{'>'}</span>*/}
                {/*                                </p>*/}
                {/*                                <p className='orderDelivery-time'>*/}
                {/*                                    <p>{item.title}</p>*/}
                {/*                                    <p>{item.messageTime}</p>*/}
                {/*                                </p>*/}
                {/*                            </p>*/}
                {/*                            <p className='item-scroller1'>*/}
                {/*                                <div className='content'>*/}
                {/*                                    <div>*/}
                {/*                                        <img src={item.medPhoto != "null" ? item.medPhoto : defaultImg} alt="" />*/}
                {/*                                    </div>*/}
                {/*                                    <p className='orderDelivery-outer-bottom1' onClick={() => this.toOrder(item.orderNumber,item.pharmacyState,item.violation)}>*/}
                {/*                                        <p>{item.messageContent}</p>*/}
                {/*                                        <p>点击查看订单详情{'>'}{'>'}</p>*/}
                {/*                                    </p>*/}
                {/*                                </div>*/}
                {/*                                <div className="del" onClick={(e) => this.deleteOne(e, item.id)}>删除</div>*/}
                {/*                            </p>*/}
                {/*                        </p>*/}
                {/*                    </div> : null}*/}
                {/*                </div>*/}
                {/*            )) :*/}
                {/*                <Nodata type='消息' />*/}
                {/*            }*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="scroller">
                    {messageList.length ? messageList.map((item, index) => (
                        <>
                            {
                                item.pharmacyId ? <div key={index} className="item">
                                    <p className='item-scroller2'>
                                        <p className='orderDelivery-outer'
                                           onClick={() => this.toPhatmacy(item.pharmacyId, item.pharmacyState)}>
                                            <p className='pharmacyName'>
                                                <img src={item.pharmacyPhoto} alt=""/>
                                                <span>{item.pharmacyName}</span>
                                            </p>
                                            <span>{'>'}</span>
                                        </p>
                                        <p className='orderDelivery-time'>
                                            <p>{item.title}</p>
                                            <p>{item.messageTime}</p>
                                        </p>
                                    </p>
                                    <p className='item-scroller1'>
                                        <div className='content'>
                                            <div>
                                                <img src={item.medPhoto != "null" ? item.medPhoto : defaultImg}
                                                     alt=""/>
                                            </div>
                                            <p className='orderDelivery-outer-bottom1'
                                               onClick={() => this.toOrder(item.orderNumber, item.pharmacyState, item.violation)}>
                                                <p>{item.messageContent}</p>
                                                <p>点击查看订单详情{'>'}{'>'}</p>
                                            </p>
                                        </div>
                                    </p>
                                </div> : null
                            }
                        </>
                        )) :
                        <Nodata type='消息'/>
                    }

                </div>
            </>
        )
    }

}