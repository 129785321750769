import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/fanhuihei.png"

export default class index extends Component {
    constructor() {
        super()
        this.state = {

        }
    }
    componentDidMount = () => {

    }
    render() {
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top box-top1'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/login/Login1")} />   众康云药购隐私政策
                </div>
                <h2 className='risk-title'>众康云药购隐私政策</h2>
                <p className='risk-body'>
                    <p>众康云药购（以下或称“我们”）非常注重保护用户（“您”）的个人信息及隐私，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。我们希望通过本隐私政策</p>
                    <p>向您清晰地介绍在使用我们的产品/服务时，我们如何处理您的个人信息，以及我们为您提供的访问、更正、删除和保护这些信息的方式。</p>
                    <p>本政策将帮助您了解以下内容：</p>
                    <p>一、我们如何收集和使用您的个人信息</p>
                    <p>二、我们如何使用 Cookie 和同类技术</p>
                    <p>三、我们如何共享、转让、公开披露您的个人信息</p>
                    <p>四、我们如何保存和保护您的个人信息</p>
                    <p>五、您如何管理您的个人信息</p>
                    <p>六、未成年人的个人信息保护</p>
                    <p>七、通知和修订</p>
                    <p>八、附录</p>
                    <p>【特别提示】请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私政策》并作出相应选择。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本
                        隐私政策处理您的相关信息。</p>
                    <p>如对本隐私政策有任何疑问，您可以通过本隐私政策“如何联系我们”中提供的方式与我们联系）。
                        众康云药购的产品/服务是由四川智云众康网络科技有限公司（注册地址：四川省成都市新都区大丰街道蓉北路一段92号）通过下述途径向您提供的产品/服务：包括但不限于公众号H5等。</p>
                    <p>请您注意，本政策不适用于您通过我们的产品/服务而接入的其他第三方产品/服务(“其他第三方”，包括您的交易相对方、任何第三方网站以及第三方服务提供者等)，具体规定请参照该第三方的隐私政策或类似声明。</p>
                    <p>一、我们如何收集和使用您的个人信息</p>
                    <p>在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的场景包括：</p>
                    <p>1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如您拒绝提供必要信息，您将无法正常使用我们的产品/服务；</p>
                    <p>2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。</p>
                    <p>（一）您需要授权我们收集和使用个人信息的场景我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。</p>
                    <p>1、帮助您成为我们的用户</p>
                    <p>为遵守法律法规的要求，以及向您提供更便捷的服务，在您注册成为众康云药购用户时，您需要至少提供手机号码以创建账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）。 在提供账号信息的过程中。如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息（如头像、昵称、地区、性别等信息）与您的众康云药购账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</p>
                    <p>2、向您提供基于地理位置的信息展示</p>
                    <p>为了向您提供周边药房信息的展示和搜索服务，提升我们的服务表现、效率和功能，经您授权我们会收集您在使用我们服务过程中产生的相关信息，包括：</p>
                    <p>（1）位置信息。当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息以便为您推荐周边的相关服务（例如您不需要手动输入所在地理位置就可获得相关服务，估算商家与您之间的实际距离方便您进行消费决策，为您推荐附近的服务品类和优惠信息等）。
                        我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括IP 地址、GPS 以及能够提供相关信息的 WLAN（如 Wi-Fi）接入点、蓝牙和基站、传感器信息。您使用服务中提供的信息中也可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</p>
                    <p>（2）日志信息。当您使用我们提供的产品/服务时，我们会收集您的浏览、搜索、点击、收藏、添加购物车、交易、售后、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的 IP 地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。</p>
                    <p>3、为您提供预订、下单、订单管理功能</p>
                    <p>您在众康云药购上下单时提供联系人信息（姓名、性别、电话号码等）、地址信息包括但不限于您的身份证、军官证、护照、驾驶证、学生证等载明您身份的证件照片、复印件、号码等）、联系人信息（姓名、性别、电话号码等）、电子邮箱地址（用于确认订单信息等）。</p>
                    <p>为向您展示您账号的订单信息及便于您对订单信息的管理，我们会收集您在使用我们服务过程中产生的订单信息。
                        您在众康云药购上生成的订单中，将可能包含您的身份信息、联络信息、地址信息、支付信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供，我们也将会在展示订单信息时，在保证信息可用性的基础上尽量去标识化
                        处理。</p>
                    <p>4、为您提供收藏、关注功能</p>
                    <p>为了便于您管理自己的偏好，获得更轻松的访问体验，与好友及第三方进行分享等，您可以收藏/关注/加入您感兴趣的商户，也可以通过功能组件向第三方进行信息分享，我们会收集您在使用上述功能中产生的日志信息，用于在个人主页中向您展示、实现在线管理。</p>
                    <p>5、为您提供涉及配送功能</p>
                    <p>当您在众康云药购上使用相应功能涉及配送服务时，您需要提供取/收货人的姓名、性别（用于配送人员联络时称呼您，如刘女士）、手机号码、收货地址、门牌号等，以便于您的订单能够安全准确送达并完成。您还可以选择为常用地址加注标签（如家、公司等），以便于此后的查找使用。</p>
                    <p>6、为您提供写点评等信息发布功能</p>
                    <p>您在众康云药购上主动对商家的产品/服务进行评价或发布其他内容时，我们将收集您发布的信息，并展示您的昵称、头像和发布内容等。</p>
                    <p>7、帮助您完成支付</p>
                    <p>您在众康云药购上进行订单支付时，您可以选择第三方合作机构（如微信支付）所提供的服务进行支付。我们需要收集您的订单信息、对账信息及其他法律要求的必要信息并与这些合作机构共享，以确认您的支付指令并帮助您完成支付。</p>
                    <p>8、为您提供客服和售后服务</p>
                    <p>当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息以便核验身份；当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息（如配送地址、联系方式等）时，您可能还需要提供上述信息外的其他信息以便完成修改。</p>
                    <p>为了保证服务体验、处理服务纠纷，您在订单履行过程中拨打或者接听开启号码保护（隐私号）的电话时，您与商家、骑手间的通话信息可能会被录音。有关上述信息的收集和使用规则如下，或参见订单页面中的提示内容。</p>
                    <p>（1）经授权，当您与商家、骑手间出现服务纠纷时，平台可调取并使用录音信息作为解决纠纷的参考依据。</p>
                    <p>（2）平台将切实保障通话双方的隐私安全，录音将上传至众康云药购服务器保存，各方无法自行下载、调取或播放录音；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取。</p>
                    <p>（3）录音在保存 7 天后将自动永久删除，如遇差评、投诉、尚未处理完毕的纠纷等，平台将适当延长录音保存期限。</p>
                    <p>9、为您提供安全保障</p>
                    <p>为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您访问及使用我们的产品/服务过程中，经您授权我们会获取您的设备信息、网络状态信息，包括您使用的设备型号、硬件序列号、设备 MAC 地址、唯一设备识别码（如IMEI/AndroidID/IDFA/IDFV/OPENUDID/GUID/OAID、SIM 卡 IMSI、ICCID 信息等设备标识符）、运行中进程信息、移动应用列表、WIFI 状态、电信运营商等软硬件特征信息。未经您的授权，我们不会提前获取以上信息。</p>
                    <p>为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们的关联方、合作伙伴取得您授权或者依法共享的信息，根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。</p>
                    <p>10、我们可能间接收集的个人信息</p>
                    <p>（一）为完成身份核验，保障账号安全，确认交易状态及为您提供售后与争议解决等目的，经您授权后我们可能会从第三方获取您的个人信息（例如通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易等信息，以便于我们处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为）。</p>
                    <p>（二）您可以选择授权我们收集和使用个人信息的场景</p>
                    <p>为向您提供个性化、便捷的服务，您可以选择使用我们提供的拓展功能，我们会在符合法律规定并根据您具体授权的情况下收集并使用如下信息。这类信息将在您选择的具体功能和业务场景中进行收集，如果您不提供这些信息，不会影响您使用基本功能。</p>
                    <p>1、基于相机授权的拓展功能</p>
                    <p>您可以选择开启系统的相机权限，通过使用拍照、录视频、扫码等功能授权我们访问您的相机，以便于您可以实现“扫一扫”、或者通过拍摄照片或录制视频等方式发布内容，如果您需要录制并发布有声视频时，您还需开启麦克风权限。相机功能您可以在系统权限中关闭，一旦关闭您将可能无法实现扫码功能，或者无法通过拍摄图片、视频等方式进行点评或互动，但不会影响您享受我们服务的基本功能。</p>
                    <p>2、基于相册授权的拓展功能</p>
                    <p>您可以选择开启系统的相册权限（部分机型中为存储权限），通过主动上传图片、视频等方式授权我们访问您的相册，以便于您通过上传照片或上传视频等方式发布内容。我们会收集您选择上传发布的上述信息，此项功能您可以在系统权限中关闭，一旦关闭您将可能无法通过上传图片、视频等方式进行更换头像、发布带图点评等，但不会影响您享受我们服务的基本功能。</p>
                    <p>3、基于通讯录授权的拓展功能</p>
                    <p>您可以授权通讯录使用我们的服务创建交流与分享功能，并允许我们获取您手机或其他第三方社交平台中的通讯录信息和公开信息（头像、昵称等），从而使您及您通讯录中同样授权开通此项功能的联系人，能够在我们分享有关内容（头像、昵称、评价信息等）以实现互动功能。您还可以授权我们读取您的通讯录信息用于为他人预定服务而无需再手动输入，例如为他人进行手机话费充值。此项功能您可以在系统权限中关闭，一旦关闭您将可能无法实现基于通讯录的互动功能，但不会影响您享受我们服务的基本功能。</p>
                    <p>（三）其他</p>
                    <p>您可以通过我们为其他人预定各类产品/服务，并提供该实际服务接收人的有关信息，此前请您确保已取得相关人的授权。</p>
                    <p>如果我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。</p>
                    <p>（四）征得同意的例外</p>
                    <p>请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
                    <p>1、为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
                    <p>2、为履行法定职责或者法定义务所必需；</p>
                    <p>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
                    <p>4、为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
                    <p>5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
                    <p>6、法律法规规定的其他情形。</p>
                    <p>请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。</p>
                    <p>（五）个人信息的使用规则</p>
                    <p>为满足您的个性化需求，维护、改进我们的产品或服务质量，我们会在符合法律规定并根据您具体授权的情况下使用如下信息：</p>
                    <p>1、我们可能会收集您的订单信息、浏览及搜索信息、行为偏好、位置信息等，以及将您在我们上使用某项服
                        务中提供的信息与来自其他服务中的信息结合起来，进行综合统计、分析以形成用户画像，用来向您推荐或展示您可能感兴趣的商品/服务信息，或通过系统向您展示个性化的第三方推广信息。包括在页面向您推送消息通知，为您提供智能推荐，通过短信、电子邮件等给您发送推送消息、推广信息或展示商业广告，通过电话进行回访、向您提供信息咨询等服务或邀请您参与服务、产品或功能有关的客户调研。例如将您在使用我们某项服务时收集的信息，用于在另一项服务中向您展示个性化的内容或广告。</p>
                    <p>2、我们可能会收集您在参与产品/服务调查时主动向我们提供的信息，以及您与我们的关联方、合作伙伴之间互动时提供的相关信息，以便于您追踪订单情况、发起用户投诉以及我们优化客户服务的质量与流程。</p>
                    <p>3、我们可能将业务中收集的个人信息用于统计分析和改进运营，将已经去标识化无法识别您身份且不能复原的信息用于建立数据库并进行商业化利用。例如通过您所在的位置、偏好等进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。</p>
                    <p>（六）设备权限调用</p>
                    <p>为确保相关业务功能的正常实现，我们需要根据具体的使用场景调用对应的必要权限，并在调用前向您弹窗询问，具体的权限调用说明请见下表：</p>
                    <p>我们权限列表您可以在设备的设置中选择关闭部分或者全部权限，这可能导致对应的业务功能无法实现或者无法达到预期效果。</p>
                    <p>二、我们如何使用 Cookie 和同类技术</p>
                    <p>（一）Cookie 的使用</p>
                    <p>为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为 Cookie 的小数据文件。Cookie 会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全等。我们不会将 Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝 Cookie。大多数浏览器会自动接受 Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie；另外，您也可以清除软件内保存的所有 Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。</p>
                    <p>（二）网络 Beacon 和同类技术的使用</p>
                    <p>除 Cookie 外，我们网页上常会包含一些电子图像(称为“单像素”GIF 文件或“网络 Beacon”)等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些 Cookie。我们使用网络 Beacon 的方式有：1、我们通过在我们网站上使用网络 Beacon，计算用户访问数量，并通过访问Cookie 辨认注册用户。2、我们通过得到的 Cookie 信息，可以在我们网站提供个性化服务。</p>
                    <p>三、我们如何共享、转让、公开披露您的个人信息</p>
                    <p>（一）共享</p>
                    <p>在我们向您提供产品/服务时，我们会采取严格的安全措施，由我们所完成的产品/服务中我们不会与我们以外的任何公司、组织和个人共享您的个人信息。但请您知悉，我们提供的产品/服务大多是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息，以保障和优化我们为您提供的产品/服务，此类情况包括：</p>
                    <p>1、提供平台服务。我们可能会作为电子商务平台向您提供产品/服务，如您在我们的平台上使用商家或其他合作方提供的产品/服务，我们可能会与相应产品/服务的提供方共享您的必要个人信息，以便于您能够正常使用相应的浏览、下单、交易、支付等功能。</p>
                    <p>2、提供统一管理服务。如您使用我们的账号登录和使用我们关联公司和其他第三方所提供的产品/服务，我们可能会共享您的个人信息。我们只会共享必要的个人信息以便向您提供一致化的服务和统一管理，例如在我们的产品/服务内查看订单并进行管理，如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的授权同意。</p>
                    <p>3、提供必要的合作服务。仅为实现本政策中声明的目的，我们的某些服务将由业务合作伙伴提供。为保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给我们的合作伙伴，包括配送业务、技术服务、支付服务、金融业务服务等的供应商、服务合作商、第三方商家等，其中可能包括您的联络信息、订单信息、支付信息、地址信息等。例如在您使用我们的服务时，我们必须与配送服务合作商共享您的订单和配送信息，以保证订单的安全准确送达；又如我们需要将您的订单号和订单金额与第三方支付机构共享以实现确认您的支付指令并完成支付；将您的订单信息、必要交易信息与第三方商家共享，以便其向您提供商品、服务及售后等。其中您的联络信息和地址信息属于个人敏感信息，我们将努力通过采取“号码保护”、单据上的“地址隐藏”等合适的举措尽全力保护您的个人信息。</p>
                    <p>4、保障安全和优化服务。为了保障您在使用我们产品/服务时的安全，以及优化我们的服务，我们可能会与我们的相关合作伙伴共享您的必要信息。安全保障服务。为了保障您的账号安全，避免您遭受欺诈、网络病毒、网络攻击等风险，我们可能会用与我们安全保障的合作伙伴共享与您有关的必要设备信息、日志信息，以及您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，预防安全事件的发生。统计、优化服务。经您授权，我们可能会与提供广告投放推广和统计分析的合作伙伴共享信息，用于相关合作伙伴可能会对上述信息进行汇聚分析，提供广
                        告曝光监测或服务决策，为您提供更好的服务。但我们仅会向这些合作伙伴提供广告推广的覆盖面、有效性以及统计类等信息，而不会共享可以识别您身份的个人信息，否则我们会对这些信息进行去标识化处理以避免对您身份的识别。</p>
                    <p>5、二次号检查服务。本业务中的手机号码二次号检查服务由第三方中国电信运营商、中国联通运营商、中国移动运营商提供，为保障您的账号安全，我们可能会将您的手机号、手机号在我们的绑定时间（精确到天）提供给此类服务提供商。</p>
                    <p>6、基于法定情形提供。根据法律法规的规定、诉讼争议解决需要、您与我们签署的相关协议(包括在线签署的电子协议及平台规则)或法律文件，或行政、司法等有权机关依法提出要求时，我们可能会共享您的个人信息。</p>
                    <p>7、除了上述情况外，我们如果对其他任何公司、组织和个人共享您的个人信息，会再次征求您的明确同意或授权。我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对
                        第三方进行严格的安全检测并约定数据保护措施。我们与第三方共享个人信息清单请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本政策中声明的目的与之共享个人信息的公司组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。</p>
                    <p>（二）转让</p>
                    <p>随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、重组、资产转让或类似的交易，如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。除了上述情况，我们不会未经您的明确同意将您的个人信息进行转让。</p>
                    <p>（三）公开披露</p>
                    <p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您的个人信息：</p>
                    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
                    <p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。</p>
                    <p>请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。</p>
                    <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
                    <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
                    <p>1、与国家安全、国防安全有关的；</p>
                    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                    <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                    <p>5、其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；</p>
                    <p>6、您自行向社会公众公开的个人信息；</p>
                    <p>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。但是您明确拒绝或者处理该信息侵害您重大利益的除外；</p>
                    <p>8、其他依法规定可以无需征得授权的情况。根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。</p>
                    <p>四、我们如何保存和保护您的个人信息</p>
                    <p>（一）个人信息的保存</p>
                    <p>1、保存期限：我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律法规有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；保证我们为您提供服务的安全和质量；您是否同意更长的留存期间；是否存在关于保留期限的其他特别约定或法律法规规定。如遇其他特殊管理产品，我们将会按照《医疗器械网络销售管理办法》记录应当保存至医疗器械有效期后2年；无有效期的，保存时间不得少于5年；植入类医疗器械交易信息应当永久保存，相关记录应当真实、完整、可追溯。在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。在您主动注销账号时，我们将根据法律法规的要求进行数据处理。</p>
                    <p>2、保存地域：我们在中华人民共和国境内收集和产生的个人信息，将存储在中国境内，但以下情形除外：</p>
                    <p>（1）法律法规有明确规定的；</p>
                    <p>（2）单独征得您的授权同意；</p>
                    <p>在上述情形中，我们会根据法律法规要求履行相应流程和义务，并要求数据接收方按照不低于本隐私政策以及其他相关的安全保密措施来处理个人信息。</p>
                    <p>3、终止运营：如果发生终止运营等情形，我们将会至少提前 30 天通知您，并在终止运营后对您的个人信息根据法律法规的要求进行处理。</p>
                    <p>（二）个人信息的保护措施</p>
                    <p>1、数据安全措施</p>
                    <p>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如在您的浏览器与“服务”之间交换数据(如信用卡信息)时采用 SSL 加密保护技术；同时对网站本身提供 https 安全浏览方式；使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
                    <p>2、请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。</p>
                    <p>3、安全事件</p>
                    <p>在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                    <p>五、您如何管理您的个人信息</p>
                    <p>您对您的个人信息享有以下权利。根据法律法规要求，在特定情形下，除您另有安排，您的近亲属为了自身的合法、正当利益，可以对您的相关个人信息行使以下权利。</p>
                    <p>地址信息——您可以通过访问我们页面上“我的”-“我的地址”中随时添加、更改、删除您的收货地址信息（包括收货人姓名、性别、收货地址、电话号码等）。</p>
                    <p>搜索浏览信息——您可以通过搜索栏点击删除、“我的”—“浏览记录”等方式访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。</p>
                    <p>订单信息——您可以通过“我的”页面访问“我的订单”页面查看您的所有已经完成、待付款、待配送、待售后、待评价的订单。您可以选择删除已完成的订单信息，但这样可能导致我们无法根据您的购买信息而准确提供相应的售后服务。</p>
                    <p>评价信息——您可以通过“我的”页面中访问“我的订单”-“待评价”-“我的评价”来访问、增加、删除您的评价内容。请您知悉，根据法律法规的要求及不同信息的技术条件需要，我们可能无法立即从系统中完全删除您所希望删除的相应信息，在此之前，我们将根据法规要求对相应信息仅进行存储并采取必要的安全保护措施方面的处理。</p>
                    <p>六、未成年人的个人信息保护</p>
                    <p>我们非常重视对未成年人个人信息的保护。我们的网站和服务主要面向成人。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
                        对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
                        如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快对相关数据采取适当处理。</p>
                    <p>七、通知和修订</p>
                    <p>为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整，对我们的基本情况、您的个人信息的处理目的方式种类和保存期限、您管理个人信息的方式、我们的联系方式发生变更的，我们会进行及时更新。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本政策并受之约束。</p>
                    <p>八、附录</p>
                    <p>1、个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码、性别、住址、生日等）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；网络身份标识信息（包括系统账号、IP 地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；个人健康生理信息（包括病症、病历、病史等）；联系人信息（包括通讯录信息、好友列表等）；个人上网记录（包括网站浏览记录、点击记录等）；个人设备信息（包括唯一设备识别码等描述个人常用设备基本
                        情况的信息）；个人位置信息（包括行踪轨迹、精准定位信息、住宿信息等）。</p>
                    <p>2、敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。本隐私政策中可能涉及到的个人敏感信息包括：个人身份认证信息（包括身份证、军官证、护照、驾驶证、学生证等）；个人财产信息（银行账号、交易和消费记录、信贷记录以及虚拟财产信息等）；其他信息（行踪轨迹、通讯录信息、住宿信息、精准定位信息、医疗健康信息、生物识别信息等）；14 周岁以下的未成年人信息。</p>
                    <p>3、我们：本政策中所指的“我们”是指众康云药购平台。</p>
                    <p>4、联系方式：028-83921595</p>
                    <p className='risk-right'>2022年11月21日</p>
                </p>
            </div>
        )
    }
}