import { axiosInstance } from '../config'

//用户添加
export const adduserRequest = () => {
    return axiosInstance.get('/api/hosp/user/add')
}

//获取用户收支明细
export const getuserbusiness = (data) => {
    return axiosInstance.post('/api/hosp/business/user-list',data)
}


//获取用户门店信息
export const getuserstoreinfo = (data) => {
    return axiosInstance.post('/api/hosp/user/get-qualifications',data)
}
//获取用户发票信息
export const getUserInvoice = (data) => {
    return axiosInstance.post('/api/hosp/invoice/list-template',data)
}
//添加用户发票信息
export const addUserInvoice = (data) => {
    return axiosInstance.post('/api/hosp/invoice/add-template',data)
}
//修改用户发票信息
export const changeUserInvoice = (data) => {
    return axiosInstance.post('/api/hosp/invoice/edit-template',data)
}
//修改用户发票默认
export const changeUserInvoiceMR = (data) => {
    return axiosInstance.post('/api/hosp/invoice/default-template',data)
}

//用户密码登录
export const loginRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/login', data)
}

//账号密码注册
export const accountsignRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/register', data)
}

//发送注册短信验证码
export const regsmsRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/registersms', data)
}

// 短信验证码验证
export const verifyRequest = (phone, code,pharmacyId) => {
    return axiosInstance.get(`/api/hosp/user/verify?userPhone=${phone}&smsCode=${code}&pharmacyId=${pharmacyId}`)
}

//短信验证码登录
export const codesignRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/smslogin', data)
}

//登录验证码
export const logincodeRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/loginver', data)
}

//身份验证
export const identyRequest = (data) => {
    return axiosInstance.post('/api/hosp/user/identity',data)
}

//用户短信注册
export const smssignRequest = (phone,acountType,pharmacyId) => {
    return axiosInstance.get(`/api/hosp/user/smsregister?userPhone=${phone}&acountType=${acountType}&pharmacyId=${pharmacyId}`)
}

//验证账号唯一
export const onlyAccountRequest = (userAcount,pharmacyId) => {
    return axiosInstance.get(`/api/hosp/user/only?userAcount=${userAcount}&pharmacyId=${pharmacyId}`)
}

//获取用户信息
export const getuserinfo = (data) => {
    return axiosInstance.post(`/api/hosp/user/userinfo`,data)
}

// 我的
export const mineRequest = (userId) => {
    return axiosInstance.get(`/api/hosp/user/mine?userId=${userId}`)
}
// 修改密码
export const changePwdRequest = (data) => {
    return axiosInstance.post(`/api/hosp/user/updatepwd`,data)
}
// 获取token
export const getToken = (code) => {
    return axiosInstance.get(`/restful/hosp/user/wxcode/access_token?appid=wxabf674ac2a12c46e&secret=b18f7979c9dc99d7a6c822a4b1e680bd&code=${code}&grant_type=authorization_code`)
}
// 通过手机号更新信息
export const changeUser = (data) => {
    return axiosInstance.post(`/api/hosp/user/updatewx`,data)
}
// 通过手机号获取用户信息
export const getUser = (data) => {
    return axiosInstance.post(`/api/hosp/user/userinfo`,data)
}
// URL转码
export const getJMURL = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/geturl`,data)
}
// 查询用户资质审核列表详情
export const getUserSH = (data) => {
    return axiosInstance.post(`/api/hosp/user/getqualifications`,data)
}
// 用户单个资质上传
export const uploadOne = (data) => {
    return axiosInstance.post(`/api/hosp/user/up-qualifications`,data)
}
//md5加密
export const getmd5 = (data) => {
    return axiosInstance.post(`/faas/hosp/erp-base/getmd5`,data)
}



// 获取蜂鸟token
export const getTau = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/ele-sign', data)
}
// 查询商品范围
export const getRange = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/get-range', data)
}
// 微信登录信息存储
export const saveWx = (data) => {
    return axiosInstance.post('/api/hosp/user/wxlogin', data)
}
// 微信登录查询绑定手机号
export const savePhone = (data) => {
    return axiosInstance.post(`/api/hosp/user/wxphone`,data)
}
// 更新失效验证码状态	
export const updatesxVerifystate = (phone) => {
    return axiosInstance.get(`/api/hosp/user/updatesx-verifystate?userPhone=${phone}`)
}
// 查询用户状态	
export const getUserstatus = (userAcount) => {
    return axiosInstance.get(`/api/hosp/user/get-userstatus?userAcount=${userAcount}`)
}
// 零售新增发票模板		
export const addInvoice = (data) => {
    return axiosInstance.post(`/api/hosp/invoice/add-invoice`,data)
}
// 查询默认发票模板		
export const getDefultInvoice = (data) => {
    return axiosInstance.post(`/api/hosp/invoice/get-defult-invoice`,data)
}
// 修改默认发票		
export const defaultInvoice = (invoiceId) => {
    return axiosInstance.get(`/api/hosp/invoice/default-invoice?invoiceId=${invoiceId}`)
}
//  查询骑手位置	
export const deliveryLocation = (data) => {
    return axiosInstance.post(`/faas/hosp/maiyatian/delivery-location`,data)
}
//微信登录查用户id
export const wxGetuserid = (userOpenid) => {
    return axiosInstance.get(`/api/hosp/user/wx-getuserid?userOpenid=${userOpenid}`)
}