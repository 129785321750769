import React, { Component } from 'react'
import { Tabs,Switch } from 'antd-mobile'
import {Stepper,Popup} from "antd-mobile-v5"
import FH from "../../../static/img/mine/fanhui.png"
import sousuo from "../../../static/img/sousuo.png"
import defaultImg from '../../../static/img/default.png'
import icon1 from "../../../static/img/fanhuihei.png"
import { Toast} from 'antd-mobile'
import {allInvoice,allOrderInvoice} from "../../../api/request/yt"
import {addInvoice,defaultInvoice} from '../../../api/request/user'
import Nodata from '../../../component/Nodata'
import "./style.less"
import close from '../../../static/img/close1.png'
export default class controlInvoices extends Component {
    constructor(){
        super()
        this.state={
            userId:localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            invoiceList:'',
            orderinvoiceList:'',
            tabs : [{ title: '我的发票抬头' }, { title: '发票记录' }],
            visiable:false,
            fapiaoName0:'',
            fapiaoDefault:0
        }
    }
    componentDidMount=async()=>{
        const {userId}=this.state
        let res=await allInvoice(userId)
        console.log(res);
        if (res.data[0].length) {
            this.setState({
                invoiceList:res.data[0]
            })
        }
        let res1= await allOrderInvoice({userId})
        console.log(res1);
        if (res1.data[0].length) {
            res1.data[0].map(item=>{
                let arr=[]
                let count1=0
                item.photo=[]
                item.count=0
                if (res1.data[1].length) {
                    res1.data[1].map(i=>{
                        if (i.orderNumber==item.orderNumber) {
                            arr.push(i.medPhoto)
                            count1+=1
                        }
                    })
                }
                item.photo=arr
                item.count=count1
            })
            this.setState({
                orderinvoiceList:res1.data[0]
            })
        }
    }
    addHead=()=>{
        this.setState({
            visiable:true
        })
    }
    fapiaoName=(e)=>{
        console.log(e.target.value);
        this.setState({
            fapiaoName0:e.target.value
        })
    }
    sureFapiao=async()=>{
        const {fapiaoName0,fapiaoDefault,userId}=this.state
        if (fapiaoName0) {
            let params={
                invoiceDefault:fapiaoDefault?'1':'0',
                userId,
                invoiceTitle:fapiaoName0
            }
            let res=await addInvoice(params)
            console.log(res);
            if (res.code==1) {
                let res2=await allInvoice(userId)
                console.log(res2);
                if (res2.data[0].length) {
                    this.setState({
                        invoiceList:res2.data[0]
                    })
                }
                this.setState({
                    visiable:false
                })
            }
        }else{
            Toast.info('请填写抬头名称')
        }
    }
    useHead=async(invoiceId)=>{
        const {fapiaoName0,fapiaoDefault,userId}=this.state
        let res=await defaultInvoice(invoiceId)
        console.log(res);
        if (res.code==1) {
            let res2=await allInvoice(userId)
            console.log(res2);
            if (res2.data[0].length) {
                this.setState({
                    invoiceList:res2.data[0]
                })
            }
        }
    }
    renderContent = (tab, index) => {
        console.log(index);
        const {invoiceList,orderinvoiceList,visiable,fapiaoName0,fapiaoDefault}=this.state
        return (
            <>
                {index==0?<div className='myheaad-invoices'>
                    <p onClick={()=>this.addHead()}><span>+</span> 添加抬头</p>
                    {invoiceList?invoiceList.map(item=>(<p>
                        <p className='myheaad-invoices-user'>
                            <span>{item.invoiceTitle}</span>
                            {item.invoiceDefault==1?<span className='myheaad-invoices-user-moren'>默认</span>:null}
                        </p>
                        <span onClick={()=>this.useHead(item.invoiceId)}>使用</span>
                    </p>)):<Nodata type='抬头'/>}
                </div>:
                index==1?<div className='invoices-jilu'>
                    {orderinvoiceList?orderinvoiceList.map(item=>(
                    <p className='invoices-jilu-inner' onClick={()=>this.props.history.push(`/mine/invoiceDetails?orderNumber=${item.orderNumber}`)}>
                        <p className='invoices-jilu-inner-top'>
                            <span>{item.pharmacyName}</span>
                            {item.invoicePath?<span>已开票</span>:<span>未开票</span>}
                        </p>
                        <p className='invoices-jilu-inner-goods'>
                            <p>
                            {item.photo.map(i=>(<img src={i!="null"?i:defaultImg} alt="" />))}
                            </p>
                            <p>共{item.count}件</p>
                        </p>
                        <p className='invoices-jilu-putong'>电子普通发票-商品明细-个人</p>
                        <p className='invoices-jilu-price'>
                            <p>抬头名称 {item.invoiceTitle}</p>
                            <p>¥{(item.orderAmount/100).toFixed(2)}</p>
                        </p>
                    </p>)):<Nodata type='发票'/>}
                </div>
                :null}
                <Popup
                visible={visiable}
                onMaskClick={() => {
                    this.setState({
                        visiable:false
                    })
                }}
                bodyStyle={{ minHeight: '68vh', borderTopLeftRadius: "10px", borderTopRightRadius: "10px",overflow:'scroll' }}
            >
                <div className="fapiao-all">
                    <div className="fapiao-title">
                        <p>填写电子发票信息</p>
                        <img src={close} alt="" onClick={()=>this.setState({
                            visiable:false
                        })}/>
                    </div>
                    <p className='fapiao-cu'>发票类型</p>
                    <span className='fapiao-blue'>电子普通发票</span>
                    <p className='fapiao-shuoming'>电子普通发票，与纸质发票具备同等法律效力，订单完成后24小时内在“我的订单”查看。</p>
                    <p className='fapiao-cu'>发票抬头</p>
                    <span className='fapiao-blue'>个人</span>
                    <p className='fapiao-head'>
                        <p><span>*</span>抬头名称</p>
                        <input type="text" placeholder='请填写个人姓名' value={fapiaoName0} onChange={(e)=>this.fapiaoName(e)}/>
                    </p>
                    {/* <p className='fapiao-cu'>收票人信息</p>
                    <p className='fapiao-head'>
                        <span>收票人手机</span>
                        <span>{reservedTelephone}</span>
                    </p> */}
                    <p className='fapiao-cu'>发票内容</p>
                    <span className='fapiao-blue'>商品明细</span>
                    <p className='fapiao-shuoming'>发票内容将显示详细商品名称与价格信息，发票金额为实际支付金额，不含虚拟资产、优惠券等扣减金额。</p>
                    <p className='fapiao-head'>
                        <h1>设为默认</h1>
                        <Switch color='#1F87FF'
                            checked={fapiaoDefault}
                            onChange={() => {
                                this.setState({
                                    fapiaoDefault:!fapiaoDefault
                                })
                            }} />
                    </p>
                    <p className='fapiao-save'onClick={() => this.sureFapiao()}>保存</p>
                </div>
            </Popup>
            </>
        )
    }
    render() {
        const {tabs,time1,time0,flag,allMoney,someMoney}=this.state
        return (
            <div>
                <div className='controlInvoices-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />   发票管理
                </div>
                <div style={{backgroundColor:"#fff"}}>
                    <Tabs
                        tabs={tabs}
                        tabBarTextStyle={{ fontSize: '16px', padding: '0', height: '62px' }}
                        tabBarActiveTextColor="#000"
                        // tabBarInactiveTextColor="#FFFFFF"
                        tabBarBackgroundColor='none'
                        tabBarUnderlineStyle={{ border: '1px #FFFFFF solid', transform: 'scaleX(0.4)', bottom: '10px' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={6} />}
                        >
                        {this.renderContent}
                    </Tabs>
                </div>
            </div>
        )
    }
}
