
import React, { Component } from 'react'
import { getlocation } from "../../api/request/logistics"
import { icon2 } from "../../static/img/icon/home.png"
import "./style.less"
const BMap = window.BMap
export default class Map extends Component {
    constructor() {
        super();
        this.state = {
            // 这两个是用户当前坐标
            longitude: localStorage.longitude ? localStorage.longitude : 104.06576236480714,
            latitude: localStorage.latitude ? localStorage.latitude : 30.65745887548027,
            width: "",
            height: ""
        }
    }
    componentWillMount() {
        //console.log(this.props)
        this.setState({
            width: this.props.width,
            height: this.props.height
        })
    }
    componentDidMount() {
        const { longitude, latitude } = this.state
        var map = new BMap.Map("allmap");
        var point = new BMap.Point(longitude, latitude);
        map.centerAndZoom(point, 20)
    }
    theLocation = async () => {
        const getCode = this.props.getCode
        console.log(this.props)
        const { longitude, latitude } = this.state
        // var map = new BMap.Map("allmap");
        // var point = new BMap.Point(longitude, latitude);
        // map.centerAndZoom(point, 18)
        var city = document.getElementById("cityName").value;
        // if (city != "") {
        //     map.centerAndZoom(city, 18);      // 用城市名设置地图中心点
        // }


        var map = new BMap.Map('allmap');
        map.centerAndZoom(new BMap.Point(longitude, latitude), 18);

        //创建地址解析器实例
        var myGeo = new BMap.Geocoder();
        // 将地址解析结果显示在地图上，并调整地图视野
        myGeo.getPoint(city, (point) => {
            if (point) {
                // 用户搜索地址的经纬度
                //console.log(point)
                getCode(point.lng, point.lat)
                map.centerAndZoom(point, 18);
                map.addOverlay(new BMap.Marker(point, { title: city }))
            } else {
                alert('您选择的地址没有解析到结果！');
            }
        }, "成都市")
    }
    render() {
        const { width, height } = this.state
        return (
            <div>
                <div id='allmap' style={{ width: `${width}`, height: `${height}` }}></div>
                <div id='result'>
                    城市名: <input id="cityName" type="text" />
                    <button onClick={this.theLocation}>查询</button>
                </div>
            </div >
        )
    }
}
