import React, { Component } from "react";
import { Flex, Toast } from "antd-mobile";

import { wxConfig } from "../../component/scan/index";
import FootNav from "../../component/FootNav";
import HeaderNav from "../../component/HeaderNav";
import Swiper from "../../component/Swiper/index";
import Swiper4 from "../../component/Swiper/index4";

import Activity from "./Activity";
import Shopitem from "../../component/Listitem/shopitem";
import RecommmendMed from "../../component/Goods/rmd-med";

import {
  homeactivityRequest,
  limitactivityRequest,
  homerecommandRequest,
  docWenzhen,
} from "../../api/request/home";
import { getNoticeList } from "../../api/request/msg";

import "./style.less";

import iconA from "../../static/img/icon/hot1.png";
import iconB from "../../static/img/icon/mingyi.png";
import iconC from "../../static/img/qxba.png";
import seckill from "../../static/img/med/title.png";
import { wzUrl, baseUrl, baseUrl0, code1 } from "../../publicurl";
import getUrl from "../../component/GetUrlParam";
import { getJMURL, wxGetuserid } from "../../api/request/user";
class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 接收props
      props: this.props.props,
      categoryTab: [],
      category: [],
      activeBanner: [],
      activeBanner4: [],
      activeBannerLink: [],
      activity: [],
      activityLink: [],
      limitKill: [],
      preference: [],
      recommand: [],
      hot: null,
      userId:
        localStorage.type == 1
          ? localStorage.userId1
          : localStorage.type == 2
          ? localStorage.userId2
          : localStorage.userId3,
      sunthis: null,
      phone: localStorage.getItem("phone"),
      phone1: localStorage.getItem("userAcount1"),
      nowTime: "",
      scrollTop: 0,
      scrollTop1: 0,
      home: 1,
    };
    this.useRef = React.createRef();
  }

  componentDidMount = async () => {
    localStorage.setItem("pharmacyName", "云药购");

    // 获取经纬度
    let longitude = localStorage.longitude
      ? localStorage.longitude
      : 104.06576236480714;
    let latitude = localStorage.latitude
      ? localStorage.latitude
      : 30.65745887548027;
    // let longitude = localStorage.longitude ? localStorage.longitude : 104.06061062388697
    // let latitude = localStorage.latitude ? localStorage.latitude : 30.760123851940616
    // let x_pi=   3.14159265358979324 * 3000.0 / 180.0;
    // let  x = Number(longitude- 0.0065)
    // let y = Number( latitude- 0.006)
    // let z = Number(Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi))
    // let theta = Number(Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi))
    // longitude  = Number(z * Math.cos(theta))
    // latitude  = Number(z * Math.sin(theta))
    // console.log(longitude);
    // console.log(latitude);

    this.setState({
      scrollTop1: this.state.scrollTop
        ? this.state.scrollTop
        : getUrl("scrollTop", this.state.props.location.search),
    });
    this.homeRequest(0, longitude, latitude);
    // if (navigator.geolocation){
    //     navigator.geolocation.getCurrentPosition(
    //         function(ev){
    //             let latitude = ev.coords.latitude
    //             let longitude = ev.coords.longitude
    //             console.log(latitude,longitude)
    //         },
    //         function(err){
    //             console.log(err.code+':'+err.message)
    //         })
    // } else {
    //     alert('该浏览器不支持')
    // }
    // 公告
    getNoticeList().then((res) => {
      console.log(res);
      let arr = [];
      if (res.data[0].length) {
        res.data[0].map((item) => {
          arr.push(item.notice_title);
        });
        this.setState({
          activeBanner4: arr,
        });
      }
    });
  };

  cameraScan = () => {
    wxConfig();
  };

  homeRequest = async (pharmacyId, longitude, latitude) => {
    // const {nowTime}=this.state
    // let time=new Date()
    // console.log(time.getHours()*60+time.getMinutes());
    // this.setState({
    //     nowTime:time.getHours()*60+time.getMinutes()
    // })
    console.log(pharmacyId, longitude, latitude);
    console.log(this.state.userId);
    const res = await Promise.all([
      homeactivityRequest(pharmacyId, longitude, latitude),
      limitactivityRequest(2),
      homerecommandRequest({
        pharmacyId: pharmacyId,
        userId: this.state.userId || undefined,
        page: 0,
        size: 20,
      }),
    ]);
    console.log(res);
    console.log(res[2]);
    // console.log(parseInt(res[0].data[2][0].pharmacyStartTime.substring(0,2)*60)+parseInt(res[0].data[2][0].pharmacyStartTime.substring(3,5)));
    // let startTime=parseInt(res[0].data[2][0].pharmacyStartTime.substring(0,2)*60)+parseInt(res[0].data[2][0].pharmacyStartTime.substring(3,5))
    // let endTime=parseInt(res[0].data[2][0].pharmacyEndTime.substring(0,2)*60)+parseInt(res[0].data[2][0].pharmacyEndTime.substring(3,5))
    // console.log(startTime,endTime);
    // let arr=[]
    // if (res[0].data[2]) {
    //     res[0].data[2].map(item=>{
    //         if (parseInt(item.pharmacyStartTime&&item.pharmacyStartTime.substring(0,2)*60)+parseInt(item.pharmacyStartTime&&item.pharmacyStartTime.substring(3,5))<(time.getHours()*60+time.getMinutes())&&
    //         parseInt(item.pharmacyEndTime&&item.pharmacyEndTime.substring(0,2)*60)+parseInt(item.pharmacyEndTime&&item.pharmacyEndTime.substring(3,5))>(time.getHours()*60+time.getMinutes())) {
    //             arr.push(item)
    //             this.setState({
    //                 // 特惠药房列表
    //                 preference: arr,
    //             })
    //         }
    //     })
    // }
    // 获取分类列表
    let category = res[0].data[0];
    res[0].data[1].forEach((item) => {
      if (item.activityBanner) {
        item.activityBanner = item.activityBanner.split(",");
        item.activityUrl = item.activityUrl.split(",");
      } else if (item.activityPic) {
        item.activityPic = item.activityPic.split(",");
        item.activityUrl = item.activityUrl.split(",");
      }
    });
    this.setState(
      {
        category: category,
        categoryTab: category.slice(1, category.length),
        activeBanner: res[0].data[1].length
          ? res[0].data[1][0].activityBanner
          : [],
        activeBannerLink: res[0].data[1].length
          ? res[0].data[1][0].activityUrl
          : [],
        activity: res[0].data[1].length ? res[0].data[1][1].activityPic : [],
        activityLink: res[0].data[1].length
          ? res[0].data[1][1].activityUrl
          : [],
        limitKill: res[1].data[0],
        // 特惠药房列表
        preference: res[0].data[2],
        recommand: res[2].data[res[2].data.length - 1],
        hot: res[0].data[3][0],
      },
      () => {
        if (this.useRef) {
          this.useRef.current.scrollTop = this.state.scrollTop1;
        }
      }
    );
  };
  // 点击分类列表跳转
  toMedList = (item) => {
    const { categoryId, categoryName } = item;
    // localStorage.setItem('categoryName',categoryName)
    if (categoryName === "全部分类") {
      this.state.props.history.push(`/classify?home=${2}`);
    } else {
      this.state.props.history.push(`/medlist/${categoryId}/0/${categoryName}`);
    }
  };
  // 跳转搜索界面
  activeClick = (link) => {
    console.log(link);
    if (link == 0) {
      this.state.props.history.push("/actpage1");
    } else if (link == 1) {
      this.state.props.history.push("/actpage2");
    }
    // this.state.props.history.push(link)
  };
  // 更多药房
  moreStore = () => {
    this.state.props.history.push("/morestore");
  };
  // 问诊跳转
  inquiry = () => {
    // Toast.info('管理员未开放', 1)
    // this.state.props.history.push('http://wz.sczyzk.com/#/user/home')
    // window.location.href=(`${baseUrl}/#/user/home`)
    const { phone, userId, phone1 } = this.state;
    console.log(phone, userId, phone1);
    if (localStorage.userId1) {
      if (code1 == 1000) {
        Toast.info("暂未开通名医问诊功能");
      } else {
        let a = localStorage.getItem("userAcount1")
          ? localStorage.getItem("userAcount1")
          : localStorage.getItem("phone") &&
            localStorage
              .getItem("phone")
              .substring(1, localStorage.getItem("phone").length - 1);
        let c = new Date().getTime();
        console.log(a);
        docWenzhen({ patientPhone: a }).then((res) => {
          console.log(res);
          console.log(res.data.data[1][0]);
          if (res.code == 1) {
            if (res.data.data[2]) {
              let patientPhone = res.data.data[2][0].patientPhone;
              let patientAvatar = res.data.data[2][0].patientAvatar;
              let patientName = res.data.data[2][0].patientName;
              let patientId = res.data.data[2][0].patientId;
              window.location.href = encodeURI(
                encodeURI(
                  `${baseUrl0}/index.html#/?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}`
                )
              );
            } else {
              let patientPhone = res.data.data[1][0].patientPhone;
              let patientAvatar = res.data.data[1][0].patientAvatar;
              let patientName = res.data.data[1][0].patientName;
              let patientId = res.data.data[1][0].patientId;
              window.location.href = encodeURI(
                encodeURI(
                  `${baseUrl0}/index.html#/?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}`
                )
              );
            }
          }
        });
      }
    } else {
      this.state.props.history.push("/login/Login1");
    }
  };
  //获取子组件this
  onRef = (ref) => {
    this.setState({
      sunthis: ref,
    });
  };
  onscroll = (e) => {
    // console.log(e.target.scrollTop)
    // setscrollTop(e.target.scrollTop)
    this.setState({
      scrollTop: e.target.scrollTop,
    });
  };
  render() {
    const {
      props,
      category,
      categoryTab,
      activeBanner4,
      activeBanner,
      scrollTop,
      home,
      activeBannerLink,
      activity,
      activityLink,
      limitKill,
      preference,
      recommand,
      hot,
    } = this.state;
    // console.log(activity);
    return (
      <div>
        <div className="screen-home" onScroll={this.onscroll} ref={this.useRef}>
          <div className="color-block"></div>
          <HeaderNav onclick={this.cameraScan} props={props} />
          <div id="allmap" style={{ width: "0", height: "0" }} />
          <div className="home-downpart-headernav">
            <div className="home-downpart-whiteback"></div>
            <div className="main-use flex-apart-row">
              <div
                className="function flex-row"
                style={{ color: "#F34848" }}
                onClick={() => {
                  // if(hot) this.props.history.push(hot.hotUrl)
                  wxConfig();
                }}
              >
                <img src={iconA} alt="" />
                <div className="flex-col">
                  <span>{hot ? hot.hotTitle : null}</span>
                  <span>{hot ? hot.hotSubtitle : null}</span>
                </div>
              </div>
              <div
                className="function flex-row"
                onClick={this.inquiry}
                style={{ color: "#00B7F5" }}
              >
                <img src={iconB} alt="" />
                <div>
                  <div className="flex-col">
                    <span>名医问诊</span>
                    <span>24小时在线</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="other-use">
              <ul className="clear-fix">
                {category.map((item) => (
                  <li
                    key={item.categoryId}
                    onClick={() => this.toMedList(item)}
                  >
                    <img src={item.categoryPng} alt="" />
                    <span>{item.categoryName}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className='swiper-part flex-row'>
                            <div className='swiper-img'>
                                <Swiper banners={activeBanner}
                                link={activeBannerLink}/>
                            </div>
                        </div> */}
            <Flex justify="between" className="flex-activity">
              {activity.map((item, index) => (
                <Flex.Item
                  key={index}
                  className="flex-actie-row"
                  // onClick={()=>this.activeClick(activityLink[index])}
                  onClick={() => this.activeClick(index)}
                >
                  <img src={item} alt="" />
                </Flex.Item>
              ))}
            </Flex>
            <div className="add-three-number">
              <img src={iconC} />
            </div>
            <div className="swiper4-part flex-row">
              <div
                className="swiper4-img"
                onClick={() => this.state.props.history.push("/bulletinboard")}
              >
                <p className="notice">公告</p>
                <Swiper4 banners={activeBanner4} />
                <p className="look">查看</p>
              </div>
            </div>
            {limitKill.length === 0 ? null : (
              <div className="timelimit-part">
                <div className="timelimit-title-line flex-apart-row">
                  <div className="timelimit-title flexr">
                    <div className="timelimit-seckill">
                      <img src={seckill} alt="" />
                    </div>
                    <div className="timimg flexr">
                      <div>10点场</div>
                      <div>00:21:16</div>
                    </div>
                  </div>
                  <div
                    className="timelimit-more flexr"
                    onClick={() => this.state.props.history.push("/seckill")}
                  >
                    <span>查看更多</span>
                    <i className="iconfont icon-xiangyou"></i>
                  </div>
                </div>
                <div className="med-part">
                  <Activity category={categoryTab} limitKill={limitKill} />
                </div>
              </div>
            )}
          </div>
          <div className="cost-dagstore">
            <div className="dragstore-title-line flex-apart-row">
              <div className="dragstore-title flexr">
                <span>特惠药房</span>
              </div>
              <div className="dragstore-more flexr" onClick={this.moreStore}>
                <span>查看更多</span>
                <i className="iconfont icon-xiangyou"></i>
              </div>
            </div>
            {/* <div className='shop-around flexc'>
                            <div className='shop-around-title flex-row'>附近药房</div>
                            <div className='flex-apart-row'>
                                <span>综合排序</span>
                                <span>销量</span>
                                <span>距离</span>
                                <span>优惠</span>
                                <span>筛选</span>
                            </div>
                        </div> */}
            {preference.map((item) => (
              <div key={item.pharmacyId}>
                <Shopitem pharmacy={item} />
              </div>
            ))}
          </div>
          <div className="recommend-med">
            <div className="recommend-med-title flex-apart-row">
              <div>平台推荐</div>
              {/* <div className='vary-line'/>
                            <img src={rmd} alt=''/>
                            <div className='vary-line'/> */}
            </div>
            <div className="recommend-med-list">
              <RecommmendMed
                recommand={recommand}
                scrollTop={scrollTop}
                home={home}
                sunthis={this.state.sunthis}
              />
            </div>
          </div>
        </div>
        <FootNav onRef={this.onRef} />
      </div>
    );
  }
}

export default Platform;
