import React, { useState, useEffect } from 'react'
import FucButton from '../../../baseui/fuctionbutton/index'

import './style.less'
import defaultImg from '../../../static/img/default.png'
import quxiao from "../../../static/img/quxiao.png";
import zhifu from "../../../static/img/zhifu.png";
import lianxi from "../../../static/img/lianxi.png";
import close from "../../../static/img/close.png";
import qishou from "../../../static/img/qishou.png";
import BDMapw from "../../Map/BDMapw";
import dizhi from "../../../static/img/dizhi.png";
import tuikuan from "../../../static/img/tuikuan.png";
import xiadan2 from "../../../static/img/xiadan2.png";
import pingjia from "../../../static/img/pingjia.png";
import you from "../../../static/img/you.png";
import jixu from "../../../static/img/jixu.png";
import shangjia from "../../../static/img/shangjia.png";
import xiadan from "../../../static/img/xiadan.png";

export default function Odersome(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const {
        state,
        orderShipping,
        orderDetail,
        payFor,
        refundApply0,
        lastTime,
        qishounews,
        m2,
        s2,
        tel,
        refundApply,
        visible1,
        timeb,
        randomNum,
        ridertel,
        setVisible1,
        setRefundModal,
        toDrawback,
        buyAgin,
        toComment,
        toOtherstor,
        consultationId,
        toWenzhen,
        toWenzhen1,
        medClassify,
        cancelRefund,
        storelatitude,
        storelongitude,
        orderstatus,
        storename,
        daYang,consultationStu,
        startTime,timecha,endTime,promoteStu,afterMarket,
        quxiaorefundApply
    } = props

    return (
        <div>
            {state === '-2' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>等待支付，剩余<span style={{ color: '#1F87FF', fontSize: '22px' }}>{m2}:{s2}</span></p>
                    <div className='order-state-explain flexc'>
                        {/* {orderShipping=='2'?<p className='time'>预计<span>{lastTime}</span>送达</p>:null} */}
                        {orderShipping=='2'?<p style={{ color: '#353A42' }}>由商家提供配送服务，送达时间会根据您实际支付时间为准</p>:null}
                        <div>
                            <div className='chuli'>
                                <div className='contact' onClick={() => refundApply0()}>
                                    <img src={quxiao} alt="" />
                                    <span> 取消订单</span>
                                </div>
                                    <div className='contact' onClick={() => payFor(orderDetail.orderNumber, orderDetail.orderAmount)}>
                                        <img src={zhifu} alt="" />
                                        <span style={{ color: '#1F87FF' }}>立即支付</span>
                                    </div>
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                        </div>

                    </div>

                </>
                : null}
            {state === '-1' || state === '-11' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>商家已接单</p>
                    <div className='order-state-explain flexc'>
                        {/* <p className='time'>预计<span>{qishounews.estimate_arrive_time}</span>送达 </p> */}
                        <p>由商家提供配送服务</p>
                        <div style={state >= '5' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                {/* {state === '-1' ? */}
                                <div className='contact' onClick={() => refundApply(orderDetail.refundRefuse)}>
                                    <img src={quxiao} alt="" />
                                    <span> 取消订单</span>
                                </div>
                                {/* :<div className='contact' onClick={() => props.history.push({ pathname: '/drawback', orderNumber: orderNumber })}>
                                                        <img src={quxiao} alt="" />
                                                        <span> 取消订单</span>
                                                    </div>} */}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '-4' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>商品待配送</p>
                    {/* {storelatitude && storelongitude && orderstatus ? <BDMapw orderstatus={orderstatus} storelongitude={storelongitude} storelatitude={storelatitude} storename={storename}></BDMapw> : null} */}
                    <div className='order-state-explain flexc'>
                        {/* <p className='time'>预计<span>{qishounews.estimate_arrive_time}</span>送达  </p> */}
                        <p>由商家提供配送服务</p>
                        <div style={state >= '5' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                {/* <div className='contact' onClick={() => lineShangjia()}>
                                                    <img src={quxiao} alt="" />
                                                    <span> 申请退款</span>
                                                </div> */}
                                {/*<a href={`tel:${qishounews.rider_phone}`}><div className='contact'>*/}
                                {/*    <img className='lianxi' src={qishou} alt="" />*/}
                                {/*    <span className='lianxi' style={{ color: '#2086FE' }}> 联系骑手</span>*/}
                                {/*</div></a >*/}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'> 联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '0' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>商品配送中</p>
                    {storelatitude && storelongitude && orderstatus ? <BDMapw orderstatus={orderstatus} storelongitude={storelongitude} storelatitude={storelatitude} storename={storename}></BDMapw> : null}
                    <div className='order-state-explain flexc'>
                        {/* <p className='time'>预计<span>{qishounews.estimate_arrive_time}</span>送达  </p> */}
                        {/* <button style={{ float: "right" }} onClick={showLogistics}>查看物流信息</button> */}
                        <p>由商家提供配送服务</p>
                        <div style={state >= '5' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                {/* <div className='contact' onClick={() => lineShangjia()}>
                                                    <img src={quxiao} alt="" />
                                                    <span> 申请退款</span>
                                                </div> */}
                                <a href={`tel:${qishounews.rider_phone}`}><div className='contact'>
                                    <img className='lianxi' src={qishou} alt="" />
                                    <span className='lianxi' style={{ color: '#2086FE' }}> 联系骑手</span>
                                </div></a >
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'> 联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '1' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>药品已备好</p>
                    <div style={{marginTop:'20px',marginLeft:'15px'}}>请及时到指定药店自取</div>
                    <div className='order-state-explain flexc'>
                        <div style={state != '1' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                <div className='contact'>
                                    <span style={{marginTop:'-10px',fontSize:"30px",marginBottom:'20px'}}>{randomNum}</span>
                                    <span style={{fontSize:'16px',marginBottom:'20px'}}> 取件码</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='order-state-explain' style={{marginTop:'10px', height: '100px',padding:'0px' }}>
                        {/* <p className='time'>待商家接单</p> */}
                        <div>
                            <div className='chuli' style={{ marginTop: '20px' }}>
                                <div className='contact' onClick={() => refundApply(orderDetail.refundRefuse)}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 取消订单</span>
                                </div>
                                {/* <div className='contact' onClick={() => buyAgin()}>
                                                    <img className='lianxi' src={xiadan2} alt="" />
                                                    <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                                </div> */}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '2' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>订单已完成</p>
                    <div className='order-state-explain' style={{ height: '140px' }}>
                        <p className='time'>感谢您对平台的信任，期待再次光临</p>
                        <div>
                            <div className='chuli' style={{ marginTop: '40px' }}>
                                {/* onClick={() => props.history.push({ pathname: '/drawback', orderNumber: orderNumber })} */}
                                <div className='contact' onClick={() => afterMarket()}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 申请退款</span>
                                </div>
                                {startTime<timecha&&timecha<endTime&&promoteStu=="0"?<div className='contact' onClick={() => buyAgin()}>
                                        <img className='lianxi' src={xiadan2} alt="" />
                                        <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                    </div>
                                    :<div className='contact' onClick={() => daYang()}>
                                        <img className='lianxi' src={xiadan2} alt="" />
                                        <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                    </div>}
                                <div className='contact' onClick={() => toComment(orderDetail.orderNumber)}>
                                    <img src={pingjia} alt="" />
                                    <span style={{ color: '#FF8D30' }}>去评价</span>
                                </div>
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                        </div>
                    </div>
                </>
                : null}
            {state === '-3' ||state === '8' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>待商家接单</p>
                    {/* <span>{state==='-1'?'商家正在备货中':'商家正在运输中'}</span>
                    {state!=='-1'?<span>快递公司：{orderDetail.orderCourier}</span>:null}
                    {state!=='-1'?<span>快递单号：{orderDetail.courierNumber}</span>:null} */}
                    {/* <p>待补充用药信息</p> */}
                    <div className='order-state-explain' style={{paddingTop:'0px',height:'100px' }}>
                        {/* <p className='time'>待商家接单</p> */}
                        <div>
                            <div className='chuli' style={{ marginTop: '20px' }}>
                                <div className='contact' onClick={() => refundApply(orderDetail.refundRefuse)}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 取消订单</span>
                                </div>
                                {/* <div className='contact' onClick={() => buyAgin()}>
                                                <img className='lianxi' src={xiadan2} alt="" />
                                                <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                            </div> */}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '16' ?
                <>
                    {!consultationId||(consultationStu&&consultationStu=='3')?<p>待填写问诊信息</p>:
                        <p>已填写问诊信息</p>}
                    {/* <span>{state==='-1'?'商家正在备货中':'商家正在运输中'}</span>
                    {state!=='-1'?<span>快递公司：{orderDetail.orderCourier}</span>:null}
                    {state!=='-1'?<span>快递单号：{orderDetail.courierNumber}</span>:null} */}
                    {/* <p>待补充用药信息</p> */}
                    {medClassify=='1'?<div className='wenzhen'>
                        <div className='wenzhen-top'>
                            <span>填写信息</span>
                            <img src={you} alt="" />
                            <span>医生开方</span>
                            <img src={you} alt="" />
                            <span>医师审方</span>
                            <img src={you} alt="" />
                            <span>药房发货</span>
                        </div>
                        <div className='xian'></div>
                        {!consultationId||(consultationStu&&consultationStu=='3')?<h2>请填写用药人信息</h2>:
                            <h2>已填写用药人信息</h2>}
                        <p>预约处方需填写用药人信息，商家将在审核通过后发货</p>
                        {!consultationId||(consultationStu&&consultationStu=='3')?
                            <div className='tianxie' onClick={() => toWenzhen1()}>
                                <img src={jixu} alt="" />
                                <span>立即填写</span>
                            </div>:
                            consultationId?<div className='tianxie' onClick={()=>toWenzhen()}>
                                <img src={jixu} alt="" />
                                <span>继续问诊</span>
                            </div>:null}
                    </div>:null}
                    <div className='order-state-explain' style={{ height: '100px' }}>
                        {/* <p className='time'>待商家接单</p> */}
                        <div>
                            <div className='chuli' style={{ marginTop: '20px' }}>
                                <div className='contact' onClick={() => refundApply(orderDetail.refundRefuse)}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 取消订单</span>
                                </div>
                                {/* <div className='contact' onClick={() => buyAgin()}>
                                                <img className='lianxi' src={xiadan2} alt="" />
                                                <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                            </div> */}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div>
                    </div>
                </>
                : null}
            {state === '3' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>订单已完成</p>
                    <div className='order-state-explain' style={{ height: '140px' }}>
                        <p className='time'>感谢您对平台的信任，期待再次光临</p>
                        <div>
                            <div className='chuli' style={{ marginTop: '40px' }}>
                                <div className='contact' onClick={() => afterMarket()}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 申请售后</span>
                                </div>
                                {startTime<timecha&&timecha<endTime&&promoteStu=="0"?<div className='contact' onClick={() => buyAgin()}>
                                        <img className='lianxi' src={xiadan2} alt="" />
                                        <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                    </div>
                                    :<div className='contact' onClick={() => daYang()}>
                                        <img className='lianxi' src={xiadan2} alt="" />
                                        <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>
                                    </div>}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                            </div>
                        </div>
                    </div>
                </>
                : null}
            {state === '4' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>订单已取消</p>
                    <div className='order-state-explain flexc'>
                        <p className='time' style={{ marginBottom: '25px' }}>订单已取消</p>
                        <div style={state != '4' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                <div className='contact' onClick={() => toOtherstor()}>
                                    <img src={shangjia} alt="" />
                                    <span> 其他商家</span>
                                </div>
                                {startTime<timecha&&timecha<endTime&&promoteStu=="0"?<div className='contact' onClick={() => buyAgin()}>
                                        <img className='lianxi' src={xiadan} alt="" />
                                        <span className='lianxi'> 重新下单</span>
                                    </div>
                                    :<div className='contact' onClick={() => daYang()}>
                                        <img className='lianxi' src={xiadan} alt="" />
                                        <span className='lianxi'> 重新下单</span>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </>
                : null}
            {/*{state === '1' ?*/}
            {/*    <>*/}
            {/*        <p>待自提订单</p>*/}
            {/*        <div className='order-state-explain flexc'>*/}
            {/*            <div style={state != '8' ? { display: 'none' } : null}>*/}
            {/*                <div className='chuli'>*/}
            {/*                    <div className='contact'>*/}
            {/*                        <span style={{marginTop:'-10px',fontSize:"30px",marginBottom:'20px'}}>{randomNum}</span>*/}
            {/*                        <span style={{fontSize:'16px',marginBottom:'20px'}}> 取件码</span>*/}
            {/*                    </div>*/}

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className='order-state-explain' style={{marginTop:'10px', height: '100px',padding:'0px' }}>*/}
            {/*            /!* <p className='time'>待商家接单</p> *!/*/}
            {/*            <div>*/}
            {/*                <div className='chuli' style={{ marginTop: '20px' }}>*/}
            {/*                    <div className='contact' onClick={() => refundApply(orderDetail.refundRefuse)}>*/}
            {/*                        <img className='lianxi' src={tuikuan} alt="" />*/}
            {/*                        <span className='lianxi'> 取消订单</span>*/}
            {/*                    </div>*/}
            {/*                    /!* <div className='contact' onClick={() => buyAgin()}>*/}
            {/*                                        <img className='lianxi' src={xiadan2} alt="" />*/}
            {/*                                        <span className='lianxi' style={{ color: '#2086FE' }}> 重新下单</span>*/}
            {/*                                    </div> *!/*/}
            {/*                    <a href={`tel:${tel}`}><div className='contact'>*/}
            {/*                        <img className='lianxi' src={lianxi} alt="" />*/}
            {/*                        <span className='lianxi'>联系商家</span>*/}
            {/*                    </div></a >*/}
            {/*                </div>*/}
            {/*                {visible1 == 'block' ? <div className='tankuang'>*/}
            {/*                    <div className='inner'>*/}
            {/*                        <p className='quxiao'>取消订单并退款</p>*/}
            {/*                        <p className='neirong'>商家同意后，退款将原路退回到支付账户，请注意查收。</p>*/}
            {/*                        <p className='bottom' style={{ textAlign: 'center' }}>*/}
            {/*                            <span className='xiugai' onClick={() => setVisible1('none')}>取消操作</span>*/}
            {/*                            <span className='sure' onClick={() => { setRefundModal(true) }}>确认取消订单</span>*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <img src={close} alt="" onClick={() => setVisible1('none')} />*/}
            {/*                    </div>*/}
            {/*                </div> : <div style={{ display: 'none' }}></div>}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*    : null}*/}
            {state === '5' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>订单已申请退款</p>
                    <div className='order-state-explain flexc'>
                        <p className='time'>待商家退款</p>
                        <div style={state != '5' ? { display: 'none' } : null}>
                            <div className='chuli'>
                                <div className='contact' onClick={() => quxiaorefundApply(orderDetail.orderNumber)}>
                                    <img className='lianxi' src={tuikuan} alt="" />
                                    <span className='lianxi'> 取消退款申请</span>
                                </div>
                                {/* <div className='contact' onClick={() => props.history.push(`/`)}>
                                                    <img src={shangjia} alt="" />
                                                    <span> 其他商家</span>
                                                </div> */}
                                <a href={`tel:${tel}`}><div className='contact'>
                                    <img className='lianxi' src={lianxi} alt="" />
                                    <span className='lianxi'>联系商家</span>
                                </div></a >
                                {startTime<timecha&&timecha<endTime&&promoteStu=="0"?<div className='contact' onClick={() => buyAgin()}>
                                        <img className='lianxi' src={xiadan} alt="" />
                                        <span className='lianxi'> 重新下单</span>
                                    </div>:
                                    <div className='contact' onClick={() => daYang()}>
                                        <img className='lianxi' src={xiadan} alt="" />
                                        <span className='lianxi'> 重新下单</span>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </>
                : null}
            {state === '6' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>退款成功</p>
                </>
                : null}
            {state === '7' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>商家拒绝退款<img className='jujue-img' src={you} alt="" /></p>
                    <div className='order-state-explain-jujue'>
                        <p className='jujue' style={{ marginBottom: '25px' }}>商家拒绝原因</p>
                        <p className='xian'></p>
                        <p className='time' >图片中的商品不是你下单的商品，非本店的商品，因此拒绝你的退款申请。</p>
                    </div>
                    <div className='chuki-jujue'>
                        <a href={`tel:${tel}`}><div className='contact'>
                            <img className='lianxi' src={lianxi} alt="" />
                            <p className='lianxi'>联系商家</p>
                        </div></a >
                    </div>
                </>
                : null}
            {state === '12' ?
                <>
                    <p style={{ marginLeft: "20px",fontSize:'20px',marginBottom:'-10px',fontWeight:'bold' }}>申请售后中</p>
                </>
                : null}
        </div>
    )
}