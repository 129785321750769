import { axiosInstance } from '../config'

//消息列表
export const msglistRequest = (userId) => {
    return axiosInstance.get(`/api/hosp/news/list?userId=${userId}`)
}

//标记已读
export const updateMsgRequest = (data) => {
    return axiosInstance.post('/api/hosp/news/read', data)
}
// 一级消息列表	
export const oneMessage = (userId) => {
    return axiosInstance.get(`/api/hosp/message-push/one-message?userId=${userId}`)
}
// 所有消息设为已读		
export const allnewsRead = (data) => {
    return axiosInstance.post(`/api/hosp/message-push/allnews-read`,data)
}
// 查询二级消息	
export const twoMessage = (data) => {
    return axiosInstance.post(`/api/hosp/message-push/two-message`,data)
}
// 二级消息订单详情	
export const twoOrderdetails = (data) => {
    return axiosInstance.post(`/api/hosp/message-push/two-orderdetails`,data)
}
// h5公告列表	
export const getNoticeList = () => {
    return axiosInstance.get(`/api/hosp/notice/notice-lists`)
}
// 查看公告	
export const oneNotice = (id) => {
    return axiosInstance.get(`/api/hosp/notice/one-notice?id=${id}`)
}
// 用户删除消息	
export const delMessage = (userId,messageId) => {
    return axiosInstance.get(`/api/hosp/message-push/del-message?userId=${userId}&messageId=${messageId}`)
}