import {  Toast } from 'antd-mobile'



//成功提示
export const success = (msg) => {
    Toast.success(msg)
};

//失败
export const error = (msg) => {
    Toast.fail(msg)
};

//警告
export const warning = (msg) => {
    Toast.show({
        icon: 'warning',
        content: msg,
    })
};

//警告
export const info = (msg) => {
    Toast.show({
        icon: 'info',
        content: msg,
    })
};