import React, { useState, useEffect, useRef } from 'react'
import FH from "../../../static/img/mine/fanhui.png"
import sousuo from "../../../static/img/sousuo.png"
import defaultImg from '../../../static/img/default.png'
import icon1 from "../../../static/img/fanhuihei.png"
import close from '../../../static/img/close1.png'
import { Toast,Pagination,} from 'antd-mobile'
import "./announcementdetails.less"
import Pdf from '@mikecousins/react-pdf'
import getUrl from '../../../component/GetUrlParam'
import { oneNotice } from '../../../api/request/msg'
export default function AnnouncementDetails(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [invoiceList,setinvoiceList]=useState('')
    const [orderinvoiceList,setorderinvoiceList]=useState('')
    const [invoicePath,setinvoicePath]=useState("")
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [visiable,setvisiable]=useState(false)
    const [currentPage,setCurrentPage]=useState(1)
    const [currentPage1,setCurrentPage1]=useState(1)
    const [totalPage,setTotalPages]=useState(1)
    const bulletinId=getUrl('bulletinId', props.location.search)
    useEffect(() => {
         // 公告
         oneNotice(bulletinId).then(res=>{
            console.log(res);
            if (res.data[0].length) {
                setinvoicePath(res.data[0][0].noticeUrl)
            }
        })
    }, [])
    const onChangePage=(page)=>{
        console.log(page)
        setCurrentPage(page)
    }
    const hangdleShowSizeChange=()=>{
        console.log(currentPage)
        setCurrentPage1(currentPage)
    }
    const onDocumentLoadSuccess=(totalPage)=>{
        console.log(totalPage);
        setTotalPages(totalPage._pdfInfo.numPages);
        setCurrentPage(1);
    }
    const back=()=>{
        props.history.push(`/middledetails?bulletinId=${bulletinId}`)
    }
    console.log(currentPage)
    return (
        <div style={{backgroundColor:'#fff'}}>
            <div className='Announcementdetails-top'>
                <img src={icon1} alt="" onClick={() => back()} />
            </div>
            {invoicePath?<div className='Announcementdetails-outer'>
                <Pdf className='pdf-top' scale={0.8} file={invoicePath}
                    onDocumentLoadSuccess={onDocumentLoadSuccess} page={currentPage} />
                <Pagination className='pdf-bottom'  onChange={(page) => onChangePage(page)} total={totalPage} current={currentPage} />
            </div> :null}
        </div>
    )
}
