import { axiosInstance } from '../config'

//推荐药店列表
export const storeListRequest = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/storelist`, data)
}

//推荐批发商列表
export const pharmacylistRequest = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/pharmacylist`, data)
}

//查询药店详情
export const storeDetailsRequest = (pharmacyId, userId) => {
    return axiosInstance.get(`/api/hosp/pharmacy/details?pharmacyId=${pharmacyId}&userId=${userId}`)
}

//查询药店分类
export const storeClassifyRequest = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/medicine/store_classify?pharmacyId=${pharmacyId}`)
}

//药店药品分页
export const storeMedPage = (data) => {
    return axiosInstance.get(`/api/hosp/pharmacy/medlist?pharmacyId=${data.pharmacyId}&page=${data.page}&size=${data.size}`)
}
//获取店铺信息
export const getstore = (data) => {
    return axiosInstance.post(`/api/hosp/order/getstorecs`,data)
}

//获取商家具体信息
export const getstoreinfo = (companyId) => {
    return axiosInstance.get(`/api/hosp/pharmacy/storecs?companyId=${companyId}`)
}
// 店铺药品小分类查询	
export const smallClassify = (medicineId) => {
    return axiosInstance.get(`/api/hosp/medicine/small-classify?medicineId=${medicineId}`)
}
// 查询用户是否该店铺新用户	
export const isPharamacyNewuser = (pharmacyId, userId) => {
    return axiosInstance.get(`/api/hosp/pharmacy/is-pharamacy-newuser?pharmacyId=${pharmacyId}&userId=${userId}`)
}
// 查询是否有新客立减活动	
export const isActivity = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/pharmacy/is-activity?pharmacyId=${pharmacyId}`)
}
// 计算运费	/
export const calculatePrice = (data) => {
    return axiosInstance.post(`/faas/hosp/maiyatian/calculate-price`,data)
}
//查询发单模式	
export const getDispatchMode = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/get-dispatch-mode`,data)
}
// 查询店铺
export const storePharmacy = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/chain-store/get-pharmacy-detail?pharmacyId=${pharmacyId}`)
}