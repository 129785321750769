import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "antd-mobile";
import { Popup, Space, Toast } from "antd-mobile-v5";

import Tag from "../../baseui/tag/index";
import { meddetailRequest } from "../../api/request/med";
import {
  querycartRequest,
  updatecartRequest,
  addcartRequest,
  addcartidentical,
  cartlistRequest,
} from "../../api/request/cart";

import "./rmd-med2.less";
import "../../static/css/iconfont.css";
import defaultImg from "../../static/img/default.png";
import zhezaho from "../../static/img/zhezaho.jpg";
import addImg from "../../static/img/home/add.png";
import closeimg from "../../static/img/home/close.png";

function RecommendMed2(props) {
  let pharmacyId = "1";
  const [visible1, setvisible1] = useState(false);
  const [meditem, setMeditem] = useState(null);
  const [mednum, setMednum] = useState(null);
  const [num, setNum] = useState(1);
  const userId =
    localStorage.type == 1
      ? localStorage.userId1
      : localStorage.type == 2
      ? localStorage.userId2
      : localStorage.userId3;
  const userId2 = localStorage.userId2;
  const userId1 = localStorage.userId1;
  const recommand = props.recommand;
  const type = localStorage.type;
  const sunthis = props.sunthis;
  const [cartlist, setcartlist] = useState(null);
  //console.log(sunthis)
  //console.log(props.recommand)
  const VIP = localStorage.userVip;
  //const userId = localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
  // const medId = props.match.params.medId
  // const{redirectDispatch}=props
  const medDetail = (medId) => {
    props.history.push(`/med/${medId}`);
  };
  useEffect(() => {
    if (type == "2" || type == "1") {
      getcart();
    }
  }, []);
  //获取用户购物车数据
  const getcart = async () => {
    const res = await cartlistRequest(userId, pharmacyId);
    //console.log(res);
    setcartlist(res.data[0]);
  };

  // const addCart = (e, val) => {
  //     e.stopPropagation()
  //     const params = {
  //         userId: localStorage.userId,
  //         pharmacyId: val.pharmacyId,
  //         medId: val.medId,
  //         medQuantity: 1
  //     }
  //     querycartRequest(params.userId, params.medId).then(res => {
  //         if(res.code === 1) {
  //             if(res.data[0].length > 0) {
  //                 let data = res.data[0][0]
  //                 const quantity = data.medQuantity + 1
  //                 updatecartRequest(quantity, data.cartId).then(res1 => {
  //                     if(res1.code === 1) {
  //                         Toast.info('已加入购物车', 1)
  //                     }
  //                 })
  //             } else {
  //                 addcartRequest(params).then(res => {
  //                     if(res.code === 1) {
  //                         Toast.info('已加入购物车', 1)
  //                     }
  //                 })
  //             }
  //         }
  //     })
  // }
  //显示加购物车页面
  const openaddcar = async (e, v) => {
    if ((type == "2" && userId2) || (type == "1" && userId1)) {
      e.stopPropagation();
      const res = await meddetailRequest(v.medId);
      //console.log(res.data);
      setMeditem(res.data[0][0]);
      let nownum = 0;
      if (cartlist.length) {
        cartlist.forEach((item) => {
          if (item.medId == res.data[0][0].medId) {
            nownum = item.medQuantity;
          }
        });
        setMednum(res.data[3][0].stockNum - nownum);
      } else {
        setMednum(res.data[3][0].stockNum);
      }

      //console.log(res)
      setvisible1(true);
      console.log(v);
    } else {
      e.stopPropagation();
      Toast.show({
        content: "请先登录",
      });
      props.history.push("/login/Login1");
    }
  };
  //改变加入数量
  const cahngenum = (e) => {
    if (e.target.value) {
      if (isNaN(e.target.value)) {
        Toast.show({
          content: `请输入数字`,
          duration: 800,
        });
      } else {
        if (mednum <= 0) {
          Toast.show({
            content: `该药品暂无库存`,
            duration: 800,
          });
          setNum("");
        } else {
          if (parseInt(e.target.value) > mednum) {
            Toast.show({
              content: `该药品库存只剩${mednum}`,
              duration: 800,
            });
            setNum(mednum);
          } else {
            setNum(parseInt(e.target.value));
          }
        }
      }
    } else {
      if (mednum <= 0) {
        Toast.show({
          content: `该药品暂无库存`,
          duration: 800,
        });
      }
      setNum("");
    }
    // if (isNaN(e.target.value)) {
    //     Toast.show({
    //         content: `请输入数字`,
    //         duration: 800
    //     })
    // }else{
    //     if (e.target.value > mednum) {
    //         Toast.show({
    //             content: `该药品库存只剩${mednum}`,
    //             duration: 800
    //         })
    //         setNum(mednum)
    //     } else {
    //         setNum(e.target.value)
    //     }
    // }
  };
  useEffect(() => {
    //console.log(num);
  }, [num]);
  //数量加减
  const movenum = (code) => {
    //console.log(code);
    if (code == 1) {
      if (num > 1) {
        setNum(num - 1);
      }
    } else {
      if (Number(mednum) <= 0) {
        Toast.show({
          content: `该药品暂无库存`,
          duration: 800,
        });
        setNum("");
      } else {
        if (num >= mednum) {
          Toast.show({
            content: `该药品库存只剩${mednum}`,
            duration: 800,
          });
          setNum(mednum);
        } else {
          setNum(Number(num) + 1);
        }
      }
    }
  };

  //点击添加购物车
  const goshopcar = async () => {
    setTimeout(() => {
      getcart();
    }, 1000);

    if (userId) {
      if (num > mednum) {
        if (mednum <= 0) {
          Toast.show({
            content: "该药品已售完",
          });
        } else {
          Toast.show({
            content: `该药品库存只剩${mednum}`,
          });
        }
      } else {
        if (num == 0) {
          Toast.show({
            content: "请填写大于0的数",
          });
        } else {
          let code = false;
          console.log(cartlist);
          //let nownum = 0
          cartlist.forEach((item) => {
            if (item.medId == meditem.medId) {
              code = true;
            }
          });
          if (num > mednum) {
            if (mednum >= 0) {
              Toast.show({
                content: `该商品当前仅剩${mednum}`,
              });
            } else {
              Toast.show({
                content: `该商品暂无库存`,
              });
            }
          } else {
            if (code) {
              const res = await addcartidentical({
                userId: userId,
                pharmacyId: meditem.pharmacyId,
                medId: meditem.medId,
                medQuantity: num,
              });
            } else {
              const res = await addcartRequest({
                userId: userId,
                pharmacyId: meditem.pharmacyId,
                medId: meditem.medId,
                medQuantity: num,
              });
            }
            if (sunthis) {
              //console.log(sunthis);
              sunthis.getarrlist();
            }
            Toast.show({
              content: "添加成功",
              type: "success",
              duration: 1000,
            });
            setNum(1);
            setvisible1(false);
          }
        }
      }
    } else {
      Toast.fail("请先登录");
      props.history.push("/login");
    }
  };
  // console.log(recommand)
  return (
    <div className="recommend2-med-area flexr">
      {recommand.length
        ? recommand.map((v, index) => {
            return (
              <div key={index}>
                <div
                  className="recommend-meditem"
                  key={index}
                  onClick={() => medDetail(v.medId)}
                >
                  <img
                    className="recommend-meditem-img"
                    onClick={(e) => openaddcar(e, v)}
                    src={addImg}
                    alt=""
                  />
                  <div className="recommend-img">
                    {/* src={v.medPhoto ? v.medPhoto : defaultImg} */}
                    <img
                      src={
                        v.medClassify === "1"
                          ? zhezaho
                          : v.medPhoto
                          ? v.medPhoto
                          : defaultImg
                      }
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImg;
                      }}
                    />
                  </div>
                  <div className="recommend-info">
                    <div className="yaopin-name">
                      {v.medClassify === "1" ? (
                        <div>
                          <span className="chufangyao">Rx</span>
                          <span>{v.medName}</span>
                          <br />
                          <span>{v.medSpec}</span>
                          <br />
                        </div>
                      ) : (
                        <div>
                          {/* <span className="tishi">包邮到家</span> */}
                          <span>{v.medName}</span>
                          <br />
                          <span>{v.medSpec}</span>
                          <br />
                        </div>
                      )}
                      {/* <p>{v.medName}</p> */}
                    </div>
                    {/* <p>{v.medName}</p> */}
                    {/* <span>{v.medSpec}</span> */}
                    <div className="recommend-meditem-row flex-row">
                      <a className="yao-store">{v.medCompany}</a>
                      {userId2 ? (
                        <span className="recommend-price">
                          ¥{v.medPrice / 100}
                        </span>
                      ) : (
                        <span
                          className="recommend-price"
                          style={{ fontSize: "13px" }}
                        >
                          登录后可见
                        </span>
                      )}
                      <div className="med-viplevel">
                        {!VIP || VIP === "0" ? null : (
                          <Tag type="vip" text={`LV${VIP}会员`} />
                        )}
                      </div>
                      {v.originPrice === v.medPrice ? null : (
                        <span className="recommend-spike">
                          ¥{v.originPrice / 100}
                        </span>
                      )}
                      {/* <div className='recommand-buy flex-row'
                                onClick={(e)=>addCart(e, v)}>
                                    <i className='iconfont icon-tubiaozhizuomoban'></i>
                                </div> */}
                      {/* <img src={addImg} onClick={()=>addCart(e, v)} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      <Popup
        visible={visible1}
        id="popup"
        onMaskClick={() => {
          setvisible1(false);
          setNum(1);
        }}
        bodyStyle={{
          minHeight: "60vh",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <div id="addbox">
          <div
            className="addbox-top"
            style={{ padding: "10px", textAlign: "right" }}
          >
            <img
              src={closeimg}
              onClick={() => {
                setNum(1);
                setvisible1(false);
              }}
              style={{ width: "18px" }}
              alt=""
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0px",
              paddingLeft: "20px",
              height: "100px",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <img
              src={
                meditem
                  ? meditem.medPhoto
                    ? meditem.medPhoto
                    : defaultImg
                  : null
              }
              style={{ width: "80px", height: "80px", marginRight: "10px" }}
              alt=""
            />
            <div>
              <p style={{ fontSize: "18px", fontWeight: 600 }}>
                {meditem ? meditem.medComName : null}
              </p>
              <p style={{ fontSize: "24px" }}>
                <span style={{ marginRight: "10px" }}>原价</span>
                <span style={{ color: "#FF5555" }}>
                  ￥{meditem ? meditem.medPrice / 100 : null}
                </span>
              </p>
            </div>
          </div>
          <div
            style={{
              paddingLeft: "20px",
              lineHeight: "25px",
              marginBottom: "10px",
            }}
          >
            <p>
              库存{" "}
              <span style={{ marginLeft: "10px" }}>{mednum ? mednum : 0}</span>
            </p>
            <p>
              效期{" "}
              <span style={{ marginLeft: "10px" }}>
                {meditem ? meditem.expiration : null}
              </span>
            </p>
          </div>
          <div
            id="addbox-num"
            style={{
              width: "100%",
              marginBottom: "30px",
              paddingRight: "20px",
              paddingLeft: "20px",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>购买数量</p>
            <p className="addbox-num-p">
              {/* <button onClick={() => movenum(1)} style={{borderWidth: "1px", borderColor: "rgb(239, 239, 239)", width: "36px", height: "21px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>-</button><input type="text" value={num} onChange={(e) => cahngenum(e)} style={{ height: "21px", textAlign: "center", width: "50px",boxSizing:"border-box",verticalAlign:"top", borderWidth: "1px", borderColor: "rgb(239, 239, 239)" }} /><button onClick={() => movenum(2)} style={{ width: "36px", height: "21px", borderWidth: "1px", borderColor: "rgb(239, 239, 239)", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>+</button> */}
              <button id="btn1" onClick={() => movenum(1)}>
                -
              </button>
              <input type="text" value={num} onChange={(e) => cahngenum(e)} />
              <button onClick={() => movenum(0)}>+</button>
            </p>
          </div>
          <div style={{ paddingLeft: "20px", marginBottom: "70px" }}>
            <span style={{ marginRight: "10px", fontSize: "15px" }}>合计</span>{" "}
            <span style={{ color: "#FF5555", fontSize: "15px" }}>
              ￥{meditem ? (num * meditem.medPrice) / 100 : null}{" "}
            </span>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              onClick={goshopcar}
              style={{
                width: "330px",
                border: "none",
                height: "42px",
                backgroundColor: "#FF5555",
                borderRadius: "21px",
                color: "#000",
              }}
            >
              确定
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default withRouter(RecommendMed2);
