import React, { forwardRef, useImperativeHandle, useState, useEffect,useRef } from 'react'
import { withRouter } from 'react-router-dom'

import Tag from '../../baseui/tag/index'
import icon from "../../static/img/instore.png"
import { medsearchRequest,getonemedAll} from '../../api/request/med'

import './meditem.less'
import '../../static/css/iconfont.css'
import getUrl from '../../component/GetUrlParam'
import defaultImg from '../../static/img/default.png'
import zhezaho from "../../static/img/zhezaho.jpg"
const MedList = forwardRef((props, ref) => {
    const longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
    const latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
    const { history, symptomId,pharmacyId,home,categoryId,categoryId0, classifyId, content } = props
    const [medlist, setMedList] = useState(props.medlist)
    // const pharmacyId = localStorage.pharmacyId
    const [recommend, setRecommend] = useState(props.recommend)
    const [sales, setSales] = useState(props.sales)
    const [distance, setDistance] = useState(props.distance)
    const [price, setPrice] = useState(props.price)
    const [load, setLoad] = useState(true)
    const [page, setPage] = useState(1)
    const [size] = useState(20)
    const [refresh, setRefresh] = useState(false)
    const [medNum,setmedNum]=useState(0)
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const type=localStorage.type
    const pageView=useRef(null)
    const [scrollTop, setscrollTop] = useState(0)
    const scrollTop1 = props.scrollTop1
    const page1 = props.page1
    const title1 = props.title1

    const categoryName=localStorage.getItem('categoryName')
    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    useImperativeHandle(ref, () => ({
        onRefresh
    }))
    // useEffect(()=>{
    //     getallnum()
    // },[])
    // const getallnum=async()=>{
    //     const res=await getonemedAll({
    //         categoryId:props.categoryId
    //     })
    //     setmedNum(res.data[0][0].num)
    // }
    useEffect(()=>{
        if (page1) {
            setPage(medlist&&medlist.length?Math.ceil(medlist.length/20):1)
        }
        pageView.current.scrollTop=scrollTop1
    },[medlist])

    const onRefresh = () => {
        setMedList(props.medlist)
        setRecommend(props.recommend)
        setSales(props.sales)
        setDistance(props.distance)
        setPrice(props.price)
        setRefresh(true)
    }

    const medDetails = (medId) => {
        let page=medlist&&medlist.length?Math.ceil(medlist.length/20):1
        if (title1) {
            history.replace(`/medlist/0/0/${categoryName}?scrollTop=${scrollTop}&page=${page}&title=${2}&home=${home}`)
            history.push(`/med/${medId}?scrollTop=${scrollTop}&page=${page}&title=${2}&home=${home}`)
        }else{
            history.replace(`/medlist/${categoryId}/0/${categoryName}?scrollTop=${scrollTop}&page=${page}&home=${home}&categoryId0=${categoryId0}`)
            history.push(`/med/${medId}?scrollTop=${scrollTop}&page=${page}&title=${1}&home=${home}&categoryId0=${categoryId0}`)
        }
    }

    const shopPage = (e, pharmacyId) => {
        e.stopPropagation()
        history.push(`/shop/${pharmacyId}`)
    }

    const onscroll = e => {
        setscrollTop(e.target.scrollTop)
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            pageMedlist()
        }
    }

    const pageMedlist = () => {
        if (load) {
            const params = {
                recommend: recommend,
                sales: sales,
                distance: distance,
                price: price,
                pharmacyId: pharmacyId,
                symptomId: symptomId,
                longitude: longitude,
                latitude: latitude,
                content: content,
                page: (medlist&&medlist.length?Math.ceil(medlist.length/20):1) + 1,
                size: size
            }
            if (pharmacyId > 0) {
                params.pharmacyCategoryId = categoryId
                params.pharmacyClassifyId = classifyId === '0' ? null : classifyId
            } else {
                params.categoryId = categoryId === '1' ? null : categoryId
                params.classifyId = classifyId === '0' ? null : classifyId
            }
            console.log(params)
            medsearchRequest(params).then(res => {
                if (res.code === 1) {
                    let data = res.data
                    console.log(res.data);
                    if (data[0].length > 0) {
                        data[0].forEach(item => {
                            if (data[1].length > 0) {
                                data[1].forEach(item1 => {
                                    if (item.medId === item1.medId) {
                                        item.evaluates = item1.evaluates
                                        item.rate = (item1.rate * 100) + '%'
                                    } else {
                                        item.evaluates = 0
                                        item.rate = '100%'
                                    }
                                })
                            } else {
                                item.evaluates = 0
                                item.rate = '100%'
                            }
                        })
                        setMedList([...medlist, ...data[0]])
                        setPage(Math.ceil([...medlist, ...data[0]].length/20))
                        if (data[0].length < size) {
                            setLoad(false)
                        }
                    } else {
                        if (data[0].length < size) {
                            setLoad(false)
                        }
                    }
                }
            })
            setRefresh(true)
        }
    }

    return (
        <div className='med-list flexc'
            style={{ width: '100%', maxHeight: '100vh', overflow: 'scroll' }}
            onScroll={onscroll}  ref={pageView}>
            {medlist.map((v, index) => {
                console.log(v,"9999999999");
                return (
                    <div key={index}>
                        <div className='med-card flexr' onClick={() => medDetails(v.medId)}>
                            <div className='med-img'>
                            {/* src={v.medPhoto ? v.medPhoto : defaultImg} */}
                            {/* src={v.medClassify === "1" ? zhezaho : (v.medPhoto ? v.medPhoto : defaultImg)} */}
                                <img src={v.medClassify === "1" ? zhezaho : (v.medPhoto ? v.medPhoto : defaultImg)} alt=''
                                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} />
                                    {v.stockNum==0?<div className='shouqing'><span>已售罄</span></div>:null}
                            </div>
                            <div className='med-card-detail flexc'>
                                <div className='med-name flexr'>
                                    <span>
                                        {v.medClassify=='1'?<span style={{backgroundColor:"#1F87FF",color:"#fff",
                                    fontSize:"10px",padding:'0px 5px',marginRight:'3px'}}>Rx</span>:null}
                                        {v.medMold ?
                                            <span className='recipel-tag'>{v.medMold}</span> : null
                                        }
                                        {v.medName}
                                    </span>
                                </div>
                                <div className={v.medCompany?'med-mark':'med-mark1'}>{v.medSpec}</div>
                                <div className='med-mark'>{v.medCompany}</div>
                                <div className='med-tag flexr'>
                                    {v.promoteFull ?
                                        <Tag text={`满${v.promoteFull}送${v.promoteCut}`} type='discount' />
                                        : null
                                    }
                                </div>
                                <div className='meditem-price flexr'>
                                    <span>¥{v.toB == 1 && !userId ? "登录可见" : (v.medPrice / 100)}</span>
                                    <div className='med-viplevel'>
                                        {localStorage.userVip && localStorage.userVip !== '0' ?
                                            <Tag type='vip' text={`LV${localStorage.userVip}会员`} />
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className='meditem-data flexr'>
                                    <p>{v.evaluates}评价</p>
                                    <p>{v.rate}好评</p>
                                </div>
                                <div className='meditem-data flexr'
                                    onClick={(e) => shopPage(e, v.pharmacyId)}>
                                    <span className='meditem-pharmacy-name'>{type=="2"?v.medCompany:v.pharmacyName}</span>
                                    {/* <i className='iconfont icon-xiangyou'></i> */}
                                    <img className='meditem-data-img' src={icon} alt="" />
                                </div>
                            </div>
                        </div>

                        {index !== medlist.length - 1 ?
                            <div style={{
                                height: '1px',
                                width: '100%',
                                backgroundColor: '#DDDFE5'
                            }}></div>
                            : null}
                    </div>
                )
            })}
        </div>
    )
})

export default MedList