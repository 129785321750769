import React, { Component } from 'react'
import {geteleorder,oderdetailRequest} from "../../api/request/order"
import "./style.less"
export default class index extends Component {
    constructor(){
        super()
        this.state={
            code: true,
            goodstext:{},
            ordertext:{},
            eletext:{}
        }
    }
    componentDidMount=async()=>{
     this.test()
     const res=await geteleorder()
     console.log(JSON.parse(res.data.business_data));
     const res2=await oderdetailRequest("16400652181648333334470")
     this.setState({
        goodstext:res2.data[1][0],
        ordertext:res2.data[0][0],
        eletext:JSON.parse(res.data.business_data)
     })
     console.log(res2);
    }
    test=()=>{
      const box=  document.getElementsByClassName("elebox")
      console.log(box);
    }
    doPrint = () => {
        this.setState({
            code: false
        }, () => {
            document.getElementsByTagName('body')[0].style.zoom = 0.50
            window.print();
            document.getElementsByTagName('body')[0].style.zoom = 1
            this.setState({
                code: true
            })
        })

    }
    render() {
        return (
            <div>
                 {this.state.code ? <div style={{ width: "800px", height: "35px" }}>
                    <button onClick={this.doPrint}>
                        <i className="fa fa-print"></i>打印
                    </button>
                </div> : null}
                <div className='elebox'>
                    <div className='elebox-store'>
                        <div className='elebox-store-title'>
                            <h1>*商家小票*</h1>
                            <h1>#1蜂鸟配送</h1>
                            <p>民心堂大药房(云药购店)</p>
                            <p className='store-title-money'>-在线支付[27元]-</p>
                            <p>**********************************************</p>
                        </div>
                        <div className='elebox-store-text'>
                            <p>欣欣(女士)</p>
                            <p>为保护隐私,顾客地址已隐藏。您可以登录蜂鸟商家端或骑手端查看。</p>
                            <p>手机尾号9058</p>
                            <p>为保护隐私,顾客地址已隐藏。您可以登录蜂鸟商家端或骑手端查看。</p>
                            <p>**********************************************</p>
                        </div>
                        <div className='elebox-store-num'>
                            <p>商品总数</p> <p>2件</p>
                        </div>
                        <p>---------------------1号口袋---------------------</p>
                        <div className='elebox-store-info'>
                            <p>1.[999]感冒灵颗粒*2   10g*9袋/盒 (10g*9袋/盒)</p>
                            <p>UPC     6901339913419</p>
                        </div>
                        <p>-----------------------其它-----------------------</p>
                        <div className='elebox-store-detailed'>
                            <div>
                                <p>打包袋</p>
                                <p>0.5</p>
                            </div>
                            <div>
                                <p>配送费</p>
                                <p>3.5</p>
                            </div>
                            <div>
                                <p>[见配送费]</p>
                                <p>-￥2.0</p>
                            </div>
                            <div>
                                <p>门店新客立减</p>
                                <p>-￥1.0</p>
                            </div>
                        </div>
                        <p>-----------------------------------------------------</p>
                        <div className='elebox-store-allmoney'>
                            <p>原价:30元</p>
                            <p>已付款:27元</p>
                        </div>
                        <p>**********************************************</p>
                        <div className='elebox-store-order'>
                            <p>订单编号:525616518941896</p>
                            <p>下单时间:2021-12-14 09:07:19</p>
                        </div>
                        <h1 className='elebox-store-end'>
                            *#1完*
                        </h1>
                    </div>
                    <div className='elebox-user'>
                        <div className='elebox-user-title'>
                            <h1>*给配送*</h1>
                            <h1>#1蜂鸟配送</h1>
                            <p>民心堂大药房(云药购店)</p>
                            <p>订单编号:525616518941896</p>
                            <p className='user-title-money'>-在线支付[27元]-</p>
                            <p>**********************************************</p>
                        </div>
                        <div className='elebox-user-text'>
                            <p>为保护隐私,顾客地址已隐藏。您可以登录蜂鸟商家端或骑手端查看。</p>
                            <p>手机尾号9058</p>
                            <p>为保护隐私,顾客地址已隐藏。您可以登录蜂鸟商家端或骑手端查看。</p>
                            <p>欣欣(女士)</p>
                            <p>下单时间:2021-12-14 09:07:19</p>
                            <p>**********************************************</p>
                        </div>
                        {/* <div className='elebox-user-num'>
                            <p>商品总数</p> <p>2件</p>
                        </div> */}
                        <p>---------------------1号口袋---------------------</p>
                        <div className='elebox-user-info'>
                            <p>1.[999]感冒灵颗粒*2   10g*9袋/盒 (10g*9袋/盒)</p>
                            {/* <p>UPC     6901339913419</p> */}
                        </div>
                        <p>-----------------------其它-----------------------</p>
                        <div className='elebox-user-detailed'>
                            <div>
                                <p>打包袋</p>
                                <p>0.5</p>
                            </div>
                            <div>
                                <p>配送费</p>
                                <p>3.5</p>
                            </div>
                            <div>
                                <p>[减配送费]</p>
                                <p>-￥2.0</p>
                            </div>
                            <div>
                                <p>门店新客立减</p>
                                <p>-￥1.0</p>
                            </div>
                        </div>
                        <p>-----------------------------------------------------</p>
                        <div className='elebox-user-allmoney'>
                            <p>原价:30元</p>
                            <p>已付款:27元</p>
                        </div>
                        <p>**********************************************</p>
                        {/* <div className='elebox-user-order'>
                            <p>订单编号:525616518941896</p>
                            <p>下单时间:2021-12-14 09:07:19</p>
                        </div> */}
                        <h1 className='elebox-user-end'>
                            *#1*
                        </h1>
                    </div>
                </div>
            </div>
        )
    }
}
