import React, { Component } from 'react'
import { View } from 'antd-mobile'
import Head from '../../../component/Head'
import Nodata from '../../../component/Nodata'
import xt from '../../../static/img/xt.png'
import yh from '../../../static/img/yh.png'
import dd from '../../../static/img/dd.png'
import icon1 from "../../../static/img/fanhuihei.png"
import { delMessage, twoMessage } from '../../../api/request/msg'
import getUrl from '../../../component/GetUrlParam'
import './style.less'
import JRoll from 'jroll';
export default class systemPrompt extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            visiable: 'none',
            messageList: '',
            mine:''
        }
        this.deleteOne = this.deleteOne.bind(this);
    }
    deleteOne=async(id)=> {
        let res=await delMessage(this.state.userId,id)
        console.log(res);
        if (res.code==1) {
            // twoMessage({ userId:this.state.userId, type1: 1 }).then(res => {
            //     console.log(res);
            //     if (res.data[0].length) {
            //         this.setState({
            //             messageList: res.data[0]
            //         })
            //     }else{
            //         this.setState({
            //             messageList: []
            //         })
            //     }
            // })
            window.location.reload()
        }
    }
    componentDidMount() {
        const { userId } = this.state
        this.setState({
            mine:getUrl('mine', this.props.location.search)
        })
        twoMessage({ userId, type1: 1 }).then(res => {
            console.log(res);
            if (res.data[0].length) {
                // res.data[0].map((item, index) => {
                //     item.id0 = index + 1 < 10 ? '0' + (index + 1) : index + 1
                // })
                this.setState({
                    messageList: res.data[0]
                }, () => {
                    // this.deleteOne = this.deleteOne.bind(this);
                    // this.onScoll()
                })
            }
        })
    }
    // onScoll = () => {
    //     var w = Math.floor(document.getElementsByClassName('del')[0].offsetWidth / 2);
    //     console.log(w);//‘删除’按钮的一般宽度。
    //     // 创建外层jroll实例
    //     var jroll = new JRoll("#wrapper", {
    //         scrollBarY: true
    //     });
    //
    //     var items = document.querySelectorAll(".item");
    //     var current = null; //保存当前滑开的item
    //
    //     for (var i = 0, l = items.length; i < l; i++) {
    //         // 每行创建jroll实例
    //         var j = new JRoll(items[i], {
    //             scrollX: true,
    //             bounce: false
    //         });
    //
    //         j.on("scrollStart", function () {
    //             if (current && current !== this) {
    //                 current.scrollTo(0, 0, 100);
    //                 current = null;
    //             }
    //         });
    //
    //         j.on("scroll", function (e) {
    //             if (this.x === 0 && !current) {
    //                 this.call(jroll, e);
    //             } else {
    //                 current = this;
    //             }
    //         });
    //
    //         j.on("scrollEnd", function () {
    //             if (this.x > -w) {
    //                 this.scrollTo(0, 0, 100);
    //                 current = null;
    //             } else {
    //                 this.scrollTo(this.maxScrollX, 0, 100);
    //             }
    //         })
    //     };
    // }

    render() {
        const { messageList, mine, width, isShow } = this.state
        return (
            <>
                <div className='systemPrompt-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push(`/tidings?mine=${mine}`)} />   系统通知
                </div>
                {/*<div id="wrapper">*/}
                {/*    <div className="scroller">*/}
                {/*        <div id="valid">*/}
                {/*            {*/}
                {/*            messageList ? messageList.map((item, index) => {*/}
                {/*                    return <div key={index} className="item">*/}
                {/*                        <div className="item-scroller">*/}
                {/*                            <p className='systemPrompt-outer-time'>*/}
                {/*                                {item.messageTime}*/}
                {/*                            </p>*/}
                {/*                            <div className="content">*/}
                {/*                                <p className='systemPrompt-outer-bottom'>*/}
                {/*                                    <p className='systemPrompt-outer-bottom-top'>*/}
                {/*                                        {item.messageTitle}*/}
                {/*                                    </p>*/}
                {/*                                    <p>{item.messageContent}</p>*/}
                {/*                                </p>*/}
                {/*                            </div>*/}
                {/*                            <div className="del" onClick={() => this.deleteOne(item.id)}>删除</div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                }) :*/}
                {/*                <Nodata type='消息' />*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                    <div className="scroller">
                    {
                    messageList ? messageList.map((item, index) => (
                        <>
                            <div key={index} className="item">
                                <p className='systemPrompt-outer-time'>
                                    {item.messageTime}
                                </p>
                                <p className='systemPrompt-outer-bottom'>
                                    <p className='systemPrompt-outer-bottom-top'>
                                        {item.messageTitle}
                                    </p>
                                    <p>{item.messageContent}</p>
                                </p>
                            </div>
                        </>

                        )) :
                        <Nodata type='消息' />
                    }
                </div>
            </>
        )
    }

}