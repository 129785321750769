import React, { Component } from 'react'
import "./style.less"
import { Form, Input, Button, TextArea } from "antd-mobile-v5"
import icon1 from "../../../static/img/fanhuihei.png"
import { Toast } from 'antd-mobile'
import { addPatientMember, updatePatientMember,patientinfo,patientDetail} from "../../../api/request/wzpatient"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            name0: 0,
            sex: "",
            date: "",
            city: "",
            city0: 0,
            phone: "",
            phone0: 0,
            pasthistory: "",
            pasthistory0:0,
            allergichistory: "",
            allergichistory0: 0,
            familyhistory: "",
            familyhistory0: 0,
            liver: "",
            kidney: "",
            pasthistorycode: false,
            allergichistorycode: false,
            familyhistorycode: false,
            pasthistorycode0: 0,
            allergichistorycode0: 0,
            familyhistorycode0: 0,
            livercode: false,
            kidneycode: false,
            livercode0: 0,
            kidneycode0: 0,
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            patientId:'',
            list:''
        }
    }
    componentDidMount = async () => {
        if (this.props.location.params) {
            let b=this.props.location.params.list0.patient_birthday
            let a=(new Date(b)).getFullYear()+'-'+(((new Date(b)).getMonth()+1)<10?'0'+((new Date(b)).getMonth()+1):((new Date(b)).getMonth()+1))+'-'+((new Date(b)).getDate()<10?'0'+(new Date(b)).getDate():(new Date(b)).getDate())
            // let c=a.replaceAll("-", "/")
            console.log(this.props.location.params.list0);
            patientDetail({patientId:this.props.location.params.list0.patient_id}).then(res=>{
                res.data.data[0].map(item=>{
                    if (item.member_id==this.props.location.params.list0.member_id) {
                        this.setState({
                            list:item,
                            date:a
                        })
                    }
                })
            })
            this.setState({
                date:a
            })
        }
        let a=localStorage.getItem('phone')
        console.log(a);
        let b
        if (a) {
            b=a.substring(1,a.length-1)
        }else{
            b=localStorage.getItem('userAcount1')
        }
        // 获取用药人id(问诊端)
        patientinfo({patientPhone:b}).then(res=>{
            console.log(res);
            if (res.code==1) {
                this.setState({
                    patientId:res.data.data[0][0].patientId
                })
            }
        })
    }
    onFinish = (value) => {
        console.log(value);
    }
    setvalue = (e, type) => {
        // console.log(e,type);
        switch (type) {
            case "pasthistory":
                if (e.target.value) {
                    this.setState({
                        pasthistory: e.target.value,
                        pasthistory0:2
                    })
                }else{
                    this.setState({
                        pasthistory: e.target.value,
                        pasthistory0:1
                    })
                }
                console.log(e.target.value);
                break;
            case "allergichistory":
                if (e.target.value) {
                    this.setState({
                        allergichistory: e.target.value,
                        allergichistory0:2
                    })
                }else{
                    this.setState({
                        allergichistory: e.target.value,
                        allergichistory0:1
                    })
                }
                break;
            case "familyhistory":
                if (e.target.value) {
                    this.setState({
                        familyhistory: e.target.value,
                        familyhistory0:2
                    })
                }else{
                    this.setState({
                        familyhistory: e.target.value,
                        familyhistory0:1
                    })
                }
                break;
            case "liver":
                this.setState({
                    liver: e.target.value
                })
                break;
            case "kidney":
                this.setState({
                    kidney: e.target.value
                })
                break;
            case "name":
                if (e.target.value) {
                    this.setState({
                        name: e.target.value,
                        name0:2
                    })
                    
                }else{
                    this.setState({
                        name: e.target.value,
                        name0:1
                    })
                }
                break;
            case "sex":
                    this.setState({
                        sex: e.target.value,
                    })
                break;
            case "date":
                console.log(e.target.value);
                let b=e.target.value.replaceAll("-", "")
                let a=(new Date()).getFullYear()+'-'+(((new Date()).getMonth()+1)<10?'0'+((new Date()).getMonth()+1):((new Date()).getMonth()+1))+'-'+((new Date()).getDate()<10?'0'+(new Date()).getDate():(new Date()).getDate())
                console.log(a);
                let c=a.replaceAll("-", "")

                if (b>c) {
                    this.setState({
                        date: a
                    })
                    Toast.info('请选择正确的出生日期')
                }else{
                    this.setState({
                        date: e.target.value
                    })
                }
                break;
            case "city":
                if (e.target.value) {
                    this.setState({
                        city: e.target.value,
                        city0:2
                    })
                }else{
                    this.setState({
                        city: e.target.value,
                        city0:1
                    })
                }
                break;
            case "phone":
                if (e.target.value) {
                    this.setState({
                        phone: e.target.value,
                        phone0:2
                    })
                }else{
                    this.setState({
                        phone: e.target.value,
                        phone0:2
                    })
                }
                break;
            default:
                return;
        }
    }
    // 点击修改
    uploadvalue0= async()=>{
        const { name, sex,name0, sex0, date,list, city, phone,city0, phone0,pasthistory0,allergichistory0,familyhistory0, pasthistory,pasthistorycode0,allergichistorycode0,familyhistorycode0, allergichistory, familyhistory, liver,livercode0,kidneycode0, kidney ,patientId,pasthistorycode,allergichistorycode,familyhistorycode} = this.state
        let reg = /^1[3456789]\d{9}$/;
        let a=((name0==0)||(name0==2))
        let d=((city0==0)||(city0==2))
        let e=((phone0==0)||(phone0==2))
        let f=((pasthistory0==0)||(pasthistory0==2))
        let g=((allergichistory0==0)||(allergichistory0==2))
        let h=((familyhistory0==0)||(familyhistory0==2))

        console.log(pasthistorycode0==2?1:list.past_history==1&&pasthistorycode0==0?1:0);
        console.log(allergichistorycode0==2?1:list.allergic_history==1&&allergichistorycode0==0?1:0);
        console.log(familyhistorycode0==2?1:list.family_genetic_history==1&&familyhistorycode0==0?1:0);
        console.log(livercode0==1?0:list.abnormal_liver_function==0&&livercode0==0?0:1);
        console.log(kidneycode0==1?0:list.abnormal_renal_function==0&&kidneycode0==0?0:1);
        console.log(phone0,reg.test(phone),list.patient_phone);
        if( a&&d&&e&&f&&g&&h){
            if ((phone0==2&&reg.test(phone))||(!phone0&&list.patient_phone)) {
                const res=await updatePatientMember({
                    memberId:list.member_id,
                    patientName:name?name:list.patient_name,
                    patientSex:sex?sex:list.patient_sex,
                    patientBirthday:date?date:list.patient_birthday,
                    patientCity:city?city:list.patient_city,
                    patientPhone:phone?phone:list.patient_phone,
                    pastHistory:pasthistorycode0==2?1:list.past_history==1&&pasthistorycode0==0?1:'0',
                    pastHistoryNote:pasthistorycode0==1?'无':list.past_history==0&&(pasthistorycode0==0||pasthistorycode0==1)?'无':pasthistorycode0==2&&list.past_history==0?pasthistory:pasthistorycode0==2&&list.past_history==1?(pasthistory?pasthistory:list.past_history_note):list.past_history==1&&pasthistorycode0==0?(pasthistory?pasthistory:list.past_history_note):'无',
                    allergicHistory:allergichistorycode0==2?1:list.allergic_history==1&&allergichistorycode0==0?1:'0',
                    allergicHistoryNote:allergichistorycode0==1?'无':list.allergic_history==0&&(allergichistorycode0==0||allergichistorycode0==1)?'无':allergichistorycode0==2&&list.allergic_history==0?allergichistory:allergichistorycode0==2&&list.allergic_history==1?(allergichistory?allergichistory:list.allergic_history_note):list.allergic_history==1&&allergichistorycode0==0?(allergichistory?allergichistory:list.allergic_history_note):'无',
                    familyGeneticHistory:familyhistorycode0==2?1:list.family_genetic_history==1&&familyhistorycode0==0?1:'0',
                    familyGeneticHistoryNote:familyhistorycode0==1?'无':list.family_genetic_history==0&&(familyhistorycode0==0||familyhistorycode0==1)?'无':familyhistorycode0==2&&list.family_genetic_history==0?familyhistory:familyhistorycode0==2&&list.family_genetic_history==1?(familyhistory?familyhistory:list.family_genetic_history_note):list.family_genetic_history==1&&familyhistorycode0==0?(familyhistory?familyhistory:list.family_genetic_history_note):'无',
                    abnormalLiverFunction:livercode0==1?'0':list.abnormal_liver_function==0&&livercode0==0?'0':1,
                    abnormalRenalFunction:kidneycode0==1?'0':list.abnormal_renal_function==0&&kidneycode0==0?'0':1,
                    patientDefault:1
                })
                console.log(res);
                if(res.code==1){
                    this.props.history.push("/mine/medicinalUser")
                    Toast.success("修改成功")
                }
            }else{
                Toast.fail("请填写正确的手机号")
            }
        }else{
            Toast.fail("请将信息填写完整",1)
        }
    }
    //点击新增
    uploadvalue =async () => {
        const { name, sex, date, city, phone, pasthistory, allergichistory, familyhistory, liver,livercode0,kidneycode0, kidney ,patientId,pasthistorycode,allergichistorycode,familyhistorycode} = this.state
        let reg = /^1[3456789]\d{9}$/;
        if( name&& date&&city&& phone&&(pasthistorycode?pasthistory:1)&&(allergichistorycode?allergichistory:1)&&(familyhistorycode?familyhistory:1)){
            if (reg.test(phone)) {
                const res=await addPatientMember({
                    patientId,
                    patientName:name,
                    patientSex:sex?sex:'男',
                    patientBirthday:date,
                    patientCity:city,
                    patientPhone:phone,
                    pastHistory:pasthistory==""?0:1,
                    pastHistoryNote:pasthistory==""?'无':pasthistory,
                    allergicHistory:allergichistory==""?0:1,
                    allergicHistoryNote:allergichistory==""?'无':allergichistory,
                    familyGeneticHistory:familyhistory==""?0:1,
                    familyGeneticHistoryNote:familyhistory==""?'无':familyhistory,
                    abnormalLiverFunction:livercode0==2?1:0,
                    abnormalRenalFunction:kidneycode0==2?1:0,
                    patientDefault:1
                })
                console.log(res);
                if(res.code==1){
                    this.props.history.push("/mine/medicinalUser")
                    Toast.success("保存成功")
                }
            }else{
                Toast.fail("请填写正确的手机号")
            }
        }else{
            Toast.fail("请将信息填写完整",1)
        }
    }
    gobank=()=>{
        window.history.back()
    }
    render() {
        const { name, list,sex, date, city, phone, pasthistory0,pasthistory, allergichistory,allergichistory0, familyhistory,familyhistory0, liver,
            kidney, pasthistorycode, allergichistorycode, familyhistorycode,pasthistorycode0, allergichistorycode0, familyhistorycode0, livercode0, kidneycode0 } = this.state
        console.log(date);
            return (
            <div className='patientbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>就诊人信息</h1>
                </div>
                <div className='box-tips'>
                    为了医生安全的诊疗，请填写真实信息
                </div>
                <div className='box-information'>
                    <h1>基础信息</h1>
                    <div className='information-item'>
                        <div>问诊人<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="text" placeholder='请填写真实姓名' defaultValue={list?list.patient_name:''} onChange={e => this.setvalue(e, "name")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>性别<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="radio" name="sex" value="男" checked={list&&list.patient_sex=='男'&&!sex?1:sex=='男'?1:!list&&!sex?1:0} onChange={e=>this.setvalue(e, "sex")} /><span>男</span>
                            <input type="radio" name="sex" value="女" checked={list&&list.patient_sex=='女'&&!sex?1:sex=='女'?1:0} onChange={e=>this.setvalue(e, "sex")} /><span>女</span>
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>出生日期<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="date" value={date} onChange={e => this.setvalue(e, "date")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>当前城市<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="text" placeholder='请输入城市' defaultValue={list?list.patient_city:''} onChange={e => this.setvalue(e, "city")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>手机号码<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="number" placeholder='请输入手机号码' defaultValue={list?list.patient_phone:''} onChange={e => this.setvalue(e, "phone")} />
                        </div>
                    </div>
                </div>
                <div className='box-information'>
                    <h1>病史信息</h1>
                    <div className='information-item'>
                        <div>既往史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="pasthistory" checked={list&&list.past_history==0&&pasthistorycode0==0?1:pasthistorycode0==1?1:0} onClick={() => this.setState({
                                pasthistorycode: false,pasthistorycode0: 1,pasthistory:0
                            })} /><span>无</span>
                            <input type="radio" name="pasthistory" checked={list&&list.past_history==1&&pasthistorycode0==0?1:pasthistorycode0==2?1:0} onClick={() => this.setState({
                                pasthistorycode: true,pasthistorycode0: 2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="pasthistory" defaultChecked onClick={() => this.setState({
                                pasthistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="pasthistory" onClick={() => this.setState({
                                pasthistorycode: true
                            })} /><span>有</span>
                        </div>}
                        {pasthistorycode||(list&&list.past_history==1&&pasthistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史(必填)' value={list&&list.past_history==1&&(pasthistory0==0)?list.past_history_note:pasthistory} onChange={e => this.setvalue(e, "pasthistory")} />
                            </div> : null}
                    </div>
                    <div className='information-item'>
                        <div>过敏史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="allergichistory" checked={list&&list.allergic_history==0&&allergichistorycode0==0?1:allergichistorycode0==1?1:0} onClick={() => this.setState({
                                allergichistorycode: false,allergichistorycode0:1,allergichistory0:0
                            })} /><span>无</span>
                            <input type="radio" name="allergichistory" checked={list&&list.allergic_history==1&&allergichistorycode0==0?1:allergichistorycode0==2?1:0} onClick={() => this.setState({
                                allergichistorycode: true,allergichistorycode0:2
                            })} /><span>有</span>

                        </div>:<div>
                            <input type="radio" name="allergichistory" defaultChecked onClick={() => this.setState({
                                allergichistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="allergichistory" onClick={() => this.setState({
                                allergichistorycode: true
                            })} /><span>有</span>

                        </div>}
                        {
                            allergichistorycode||(list&&list.allergic_history==1&&allergichistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的过敏史(必填)' value={list&&list.allergic_history==1&&(allergichistory0==0)?list.allergic_history_note:allergichistory} onChange={e => this.setvalue(e, "allergichistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>家族遗传史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="familyhistory" checked={list&&list.family_genetic_history==0&&familyhistorycode0==0?1:familyhistorycode0==1?1:0} onClick={() => this.setState({
                                familyhistorycode: false,familyhistorycode0:1,familyhistory0:0
                            })} /><span>无</span>
                            <input type="radio" name="familyhistory" checked={list&&list.family_genetic_history==1&&familyhistorycode0==0?1:familyhistorycode0==2?1:0} onClick={() => this.setState({
                                familyhistorycode: true,familyhistorycode0:2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="familyhistory" defaultChecked onClick={() => this.setState({
                                familyhistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="familyhistory" onClick={() => this.setState({
                                familyhistorycode: true
                            })} /><span>有</span>
                        </div>}
                        {
                            familyhistorycode ||(list&&list.family_genetic_history==1&&familyhistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的家族遗传史(必填)' value={list&&list.family_genetic_history==1&&(familyhistory0==0)?list.family_genetic_history_note:familyhistory} onChange={e => this.setvalue(e, "familyhistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>肝功能异常<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {/*<div>*/}
                        {/*    <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==0&&livercode0==0?1:livercode0==1?1:0} onClick={() => this.setState({*/}
                        {/*        livercode: false,livercode0:1*/}
                        {/*    })} /><span>无</span>*/}
                        {/*    <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==1&&livercode0==0?1:livercode0==2?1:0} onClick={() => this.setState({*/}
                        {/*        livercode: true,livercode0:2*/}
                        {/*    })} /><span>有</span>*/}

                        {/*</div>*/}
                        {list?<div>
                            <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==0&&livercode0==0?1:livercode0==1?1:0} onClick={() => this.setState({
                                livercode: false,livercode0:1
                            })} /><span>无</span>
                            <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==1&&livercode0==0?1:livercode0==2?1:0} onClick={() => this.setState({
                                livercode: true,livercode0:2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="liver" checked={livercode0==1||livercode0==0?1:0} onClick={() => this.setState({
                                livercode: false,livercode0:1
                            })} /><span>无</span>
                            <input type="radio" name="liver" checked={livercode0==2?1:0} onClick={() => this.setState({
                                livercode: true,livercode0:2
                            })} /><span>有</span>
                        </div>}
                    </div>
                    <div className='information-item'>
                        <div>肾功能异常<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {/*<div>*/}
                        {/*    <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==0&&kidneycode0==0?1:kidneycode0==1?1:0} onClick={() => this.setState({*/}
                        {/*        kidneycode: false,kidneycode0:1*/}
                        {/*    })} /><span>无</span>*/}
                        {/*    <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==1&&kidneycode0==0?1:kidneycode0==2?1:0} onClick={() => this.setState({*/}
                        {/*        kidneycode: true,kidneycode0:2*/}
                        {/*    })} /><span>有</span>*/}

                        {/*</div>*/}
                        {list?<div>
                                <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==0&&kidneycode0==0?1:kidneycode0==1?1:0} onClick={() => this.setState({
                                    kidneycode: false,kidneycode0:1
                                })} /><span>无</span>
                                <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==1&&kidneycode0==0?1:kidneycode0==2?1:0} onClick={() => this.setState({
                                    kidneycode: true,kidneycode0:2
                                })} /><span>有</span>
                            </div>:
                            <div>
                                <input type="radio" name="kidney" checked={kidneycode0==1||kidneycode0==0?1:0} onClick={() => this.setState({
                                    kidneycode: false,kidneycode0:1
                                })} /><span>无</span>
                                <input type="radio" name="kidney" checked={kidneycode0==2?1:0} onClick={() => this.setState({
                                    kidneycode: true,kidneycode0:2
                                })} /><span>有</span>
                            </div>}
                    </div>
                </div>
                <div className='box-btn'>
                    {list?<button onClick={this.uploadvalue0}>保存</button>:<button onClick={this.uploadvalue}>保存</button>}
                </div>
            </div>
        )
    }
}
