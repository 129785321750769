import React, { useState, useEffect } from "react";
import { Checkbox, Toast } from "antd-mobile";
import { connect } from "react-redux";

import NoData from "../../../component/Nodata";
import Button from "../../../baseui/fuctionbutton/index";

import {
  addcartRequest,
  querycartRequest,
  updatecartRequest,
} from "../../../api/request/cart";
import { collectlistRequest, delShouchang } from "../../../api/request/fllow";

import defaultImg from "../../../static/img/default.png";
import zhezaho from "../../../static/img/zhezaho.jpg";
import "./style.less";

const CheckboxItem = Checkbox.CheckboxItem;

function Collection(props) {
  const userId =
    localStorage.type == "1"
      ? localStorage.userId1
      : localStorage.type == "2"
      ? localStorage.userId2
      : localStorage.userId3;
  // 管理商品是否被选中
  const [tabindex, settabindex] = useState(0);
  const [edit, setEdit] = useState(false);
  // 药品列表
  const [medlist, setMedlist] = useState([]);
  // 店铺列表
  const [shoplist, setShoplist] = useState([]);
  // 药品id
  const [medId, setMedId] = useState([]);
  // 店铺id
  const [shopId, setShopId] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [load, setLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [size] = useState(20);

  useEffect(() => {
    getCollectList(tabindex);
  }, [tabindex]);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  // 获取收藏列表
  const getCollectList = (index) => {
    const type = index === 0 ? 1 : 2;
    const params = {
      type: type,
      userId: userId,
      page: 1,
      size: size,
    };
    // 发请求
    collectlistRequest(params).then((res) => {
      console.log(res);
      if (res.code === 1 && res.data[0].length > 0) {
        let data = res.data;
        // type为1全设为未选中
        if (type === 1) {
          data[0].forEach((item, index) => {
            item.checked = false;
          });
          setMedlist(data[0]);
        } else {
          data[0].forEach((item, index) => {
            item.checked = false;
            if (item.pharmacyId === data[1][index].pharmacyId) {
              item.followNumber = data[1][index].followNumber;
            }
          });
          setShoplist(data[0]);
        }
      } else {
        if (type === 1) {
          setMedlist([]);
        } else {
          setShoplist([]);
        }
      }
    });
    setRefresh(true);
  };
  // 修改界面类型(普通/编辑)
  const tabChange = (index) => {
    settabindex(index);
    setAllChecked(false);
    setMedId([]);
    setShopId([]);
    getCollectList(index);
    setPage(1);
  };
  // 触底更新后面的数据
  const onscroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      10
    ) {
      pageCollectionList();
    }
  };

  const pageCollectionList = () => {
    if (load) {
      const type = tabindex === 0 ? 1 : 2;
      const params = {
        type: type,
        userId: userId,
        page: page + 1,
        size: size,
      };
      collectlistRequest(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          if (res.data[0].length > 0) {
            const data = res.data[0];
            if (type === 1) {
              data.forEach((item, index) => {
                item.checked = false;
              });
              setMedlist([...medlist, ...data]);
            } else {
              data.forEach((item, index) => {
                item.checked = false;
                if (item.pharmacyId === data[1][index].pharmacyId) {
                  item.followNumber = data[1][index].followNumber;
                }
              });
              setShoplist([...shoplist, ...data]);
            }
            setPage(params.page);
            if (data.length < size) {
              setLoad(false);
            }
          } else {
            if (res.data[0].length < size) {
              setLoad(false);
            }
          }
        }
      });
      setRefresh(true);
    }
  };
  // 跳转到详情界面
  const medDetail = (val) => {
    if (val.medDelete == 1) {
      if (val.pharmacyState == 1) {
        if (val.violation == 0) {
          props.history.push(`/med/${val.medId}`);
        } else {
          Toast.info("该药品已失效");
        }
      } else {
        Toast.info("该店铺已下架，暂时无法查看详情");
      }
    } else {
      Toast.info("该商品已下架，暂时无法查看详情");
    }
  };

  /**
   * @description 店铺
   * @params val
   */
  const shopDetail = (val) => {
    console.log(val);
    if (val.pharmacyState == 0) {
      Toast.info("该店铺已下架");
    } else {
      props.history.push(`/shop/${val.pharmacyId}`);
    }
  };
  // 加入购物车
  const addCart = (e, val) => {
    e.stopPropagation();

    if (val.stockNum == 0) {
      Toast.info("当前库存不足，暂时无法添加库存");
    } else {
      const params = {
        userId: userId,
        pharmacyId: val.pharmacyId,
        medId: val.medId,
        medQuantity: 1,
      };
      querycartRequest(params.userId, params.medId).then((res) => {
        if (res.code === 1) {
          if (res.data[0].length > 0) {
            let data = res.data[0][0];
            const quantity = data.medQuantity + 1;
            updatecartRequest(quantity, data.cartId).then((res1) => {
              if (res1.code === 1) {
                Toast.info("已加入购物车", 1);
              }
            });
          } else {
            addcartRequest(params).then((res) => {
              if (res.code === 1) {
                Toast.info("已加入购物车", 1);
              }
            });
          }
        }
      });
    }
  };
  // 点击将选中的id放入数组
  const collectCheck = (e, val, index) => {
    e.stopPropagation();
    let medChange = medlist;
    let shopChange = shoplist;
    let medIdArr = medId;
    let shopIdArr = shopId;
    if (tabindex === 0) {
      medChange[index].checked = !medChange[index].checked;
      if (val.checked) {
        medIdArr.push(val.collectionId);
        setMedId(medIdArr);
      } else {
        medIdArr.splice(
          medIdArr.findIndex((item) => item === val.collectionId),
          1
        );
        setMedId(medIdArr);
      }
      setAllChecked(medIdArr.length === medChange.length);
      setMedlist(medChange);
    } else if (tabindex === 1) {
      shopChange[index].checked = !shopChange[index].checked;
      if (val.checked) {
        shopIdArr.push(val.collectionId);
        setMedId(shopIdArr);
      } else {
        shopIdArr.splice(
          shopIdArr.findIndex((item) => item === val.collectionId),
          1
        );
        setMedId(shopIdArr);
      }
      setAllChecked(shopIdArr.length === shopChange.length);
      setShoplist(shopChange);
    }
    setRefresh(true);
  };
  // 选中全部
  const allCheck = (e) => {
    let medChange = medlist;
    let shopChange = shoplist;
    let medIdArr = medId;
    let shopIdArr = shopId;
    if (tabindex === 0) {
      if (e.target.checked) {
        medChange.forEach((item) => {
          if (!item.checked) {
            item.checked = true;
            medIdArr.push(item.collectionId);
          }
        });
      } else {
        medIdArr = [];
        medChange.forEach((item) => {
          item.checked = false;
        });
      }
      setMedId(medIdArr);
      setMedlist(medChange);
    } else if (tabindex === 1) {
      if (e.target.checked) {
        shopChange.forEach((item) => {
          if (!item.checked) {
            item.checked = true;
            shopIdArr.push(item.collectionId);
          }
        });
      } else {
        shopIdArr = [];
        shopChange.forEach((item) => {
          item.checked = false;
        });
      }
      setShopId(shopIdArr);
      setShoplist(shopChange);
    }
    setAllChecked(e.target.checked);
    setRefresh(true);
  };
  // 删除
  const delCollection = () => {
    const params = {
      userId: userId,
      // checkAll: allChecked?'1':'0'
      checkAll: "0",
    };
    if (tabindex === 0) {
      if (medId.length > 0) {
        params.collectionId = medId;
        delShouchang(params).then((res) => {
          if (res.code === 1) {
            setLoad(true);
            setMedId([]);
            getCollectList(tabindex);
          }
        });
      }
    } else if (tabindex === 1) {
      if (shopId.length > 0) {
        params.collectionId = shopId;
        delShouchang(params).then((res) => {
          if (res.code === 1) {
            setShopId([]);
            getCollectList(tabindex);
          }
        });
      }
    }
  };
  // 药品收藏列表渲染
  const renderMedContent = () => {
    return (
      <>
        {medlist.length > 0 ? (
          <div className="col-screen-page" onScroll={onscroll}>
            <div className="viewrecord-part flex-col" style={{ margin: 0 }}>
              {medlist.map((v, index) => {
                return (
                  <div key={index} onClick={() => medDetail(v)}>
                    <div className="viewrecord-med">
                      {edit ? (
                        <div
                          className="viewrecord-item-select"
                          onClick={(e) => collectCheck(e, v, index)}
                        >
                          <CheckboxItem
                            className="my-radio"
                            checked={v.checked}
                          />
                        </div>
                      ) : (
                        <div className="viewrecord-space"></div>
                      )}
                      <div className="viewrecord-img flexc">
                        <img
                          src={
                            v.medClassify === "1"
                              ? zhezaho
                              : v.medPhoto
                              ? v.medPhoto
                              : defaultImg
                          }
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImg;
                          }}
                        />
                      </div>
                      <div className="viewrecord-med-detail flexc">
                        <div className="flexc">
                          <p className="line2">{v.medName}</p>
                          <span>{v.medSpec}</span>
                        </div>
                        <p>￥{v.medPrice / 100}</p>
                      </div>
                      {/*{edit ? null :*/}
                      {/*    v.medDelete == 1 ?*/}
                      {/*        <div className='viewrecord-buy flex-row'*/}
                      {/*             onClick={(e) => addCart(e, v)}>*/}
                      {/*            <i className='iconfont icon-tubiaozhizuomoban'></i>*/}
                      {/*        </div> : '已下架'*/}
                      {/*}*/}
                    </div>
                    {index === medlist.length - 1 ? (
                      edit ? null : (
                        <p className="no-more">没有更多了</p>
                      )
                    ) : (
                      <div
                        style={{
                          height: "1px",
                          width: "100%",
                          backgroundColor: "#DDDFE5",
                        }}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="cart-nodata">
            <NoData type="收藏" />
          </div>
        )}
      </>
    );
  };
  // 店铺收藏列表渲染
  const renderShopContent = () => {
    return (
      <>
        {shoplist.length > 0 ? (
          <div className="col-screen-page" onScroll={onscroll}>
            <div className="viewrecord-part flex-col" style={{ margin: 0 }}>
              {shoplist.map((v, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: "100%" }}
                    onClick={() => shopDetail(v)}
                  >
                    <div className="viewrecord-med" style={{ height: 40 }}>
                      {edit ? (
                        <div
                          className="viewrecord-item-select"
                          onClick={(e) => collectCheck(e, v, index)}
                        >
                          <CheckboxItem
                            className="my-radio"
                            checked={v.checked}
                          />
                        </div>
                      ) : (
                        <div className="viewrecord-space"></div>
                      )}
                      <div className="collection-img flexc">
                        <img
                          src={v.pharmacyPhoto}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImg;
                          }}
                        />
                      </div>
                      <div
                        style={{ height: 40 }}
                        className="viewrecord-med-detail flexc"
                      >
                        <div className="flexc">
                          <p
                            className="line2"
                            style={{ fontWeight: "bold", height: "20px" }}
                          >
                            {v.pharmacyName}
                          </p>
                        </div>

                        <p>
                          {v.followNumber > 9999
                            ? `${(v.followNumber / 10000).toFixed(1)}万`
                            : v.followNumber}
                          人关注
                        </p>
                      </div>
                      <div></div>
                    </div>
                    {index === shoplist.length - 1 ? (
                      edit ? null : (
                        <p className="no-more">没有更多了</p>
                      )
                    ) : (
                      <div
                        style={{
                          height: "1px",
                          width: "100%",
                          backgroundColor: "#DDDFE5",
                        }}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="cart-nodata">
            <NoData type="收藏" />
          </div>
        )}
      </>
    );
  };
  // 返回上一页
  const backPage = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="shopcart-header flex-apart-row" style={{ height: 63 }}>
        <div className="shopcart-back-page" onClick={backPage}>
          <i className="iconfont-l icon-xiangzuo"></i>
        </div>
        <p onClick={() => setEdit(!edit)}>{edit ? "完成" : "管理"}</p>

        <div className="shopcart-header-classify flex-row">
          <div className="flex-row" onClick={() => tabChange(0)}>
            商品
          </div>
          <div className="flex-row" onClick={() => tabChange(1)}>
            店铺
          </div>
          <div
            className="shopcart-choose-tab"
            style={{ left: `${(tabindex + 1) * 25}%`, bottom: 12 }}
          />
        </div>
      </div>
      {tabindex === 0 ? renderMedContent() : null}
      {tabindex === 1 ? renderShopContent() : null}
      {edit ? (
        <div className="edit-items flex-apart-row">
          <CheckboxItem
            className="my-radio"
            checked={allChecked}
            onChange={allCheck}
          >
            全选
          </CheckboxItem>
          <Button
            name="删除"
            basecolor="#F34848"
            w={54}
            onClick={delCollection}
          />
        </div>
      ) : null}
    </div>
  );
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {};
};

// 将ui组件包装成容器组件
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Collection));
