import React ,{useState,useEffect}from 'react'
import {Link} from 'react-router-dom'

import Tag from '../../baseui/tag/index'

import './shopitem.less'

export default function Shopitem(props) {
	const [pharmacyId] = useState(props.pharmacy.pharmacyId)
    const [name] = useState(props.pharmacy.pharmacyName)//商家名字
    const [promoteStu] = useState(props.pharmacy.promoteStu)//是否营业 0营业 1打烊
    const [pharmacyStartTime] = useState(props.pharmacy.pharmacyStartTime)//营业时间
    const [pharmacyEndTime] = useState(props.pharmacy.pharmacyEndTime)//打烊时间

	const [img] = useState(props.pharmacy.pharmacyPhoto)//商家图标
	const [orderFreight] = useState(props.pharmacy.orderFreight)//运费
	const [minFreight] = useState(props.pharmacy.minFreight)//起送费用
	const [salesVolume] = useState(props.pharmacy.salesVolume)//最近一个月销售量
	const [sendTime] = useState(30)//运送耗时
	const [distance] = useState(props.pharmacy.distance)//当前距离
	const [is_new] = useState('')//是否为新店
	const [is_premium] = useState(false)//是否已经逛过
	const [activities1] = useState([{text:props.pharmacy.selfDelivery==='0'?'':'资质认证',type:'activity'},{text:`满${props.pharmacy.promoteFull}送${props.pharmacy.promoteCut}`,type:'discount'}])//活动标签组
	const [activities2] = useState([{text:props.pharmacy.selfDelivery==='0'?'':'资质认证',type:'discount'}])//活动标签组

	let time=new Date()
	// console.log(time.getHours()*60+time.getMinutes());
	let nowTime=time.getHours()*60+time.getMinutes()
	const handleClick=()=>{
	}
	// console.log(props.pharmacy);
    return(
        <Link to={`/shop/${pharmacyId}`}>
			<section style={{borderBottom: '1px solid #EEF1F5'}}
			className='shoplist_item'
			onClick={()=>handleClick()}>
				<div className='shoplist_img'>
					<div className='shoplist_logo flexr'>
						<img src={img} alt='' />
					</div>
					{is_new?<div className='show_new_logo'><span>新店</span></div>:null}
				</div>
				<div className='shoplist_main'>
					<section className='show_line'>
						<h3 className={is_premium?'is_premium  show_line_h3':'show_line_h3'}>
							<span>{name}</span>
						</h3>
					</section>
					<section className='show_line show_line2'>
						<div className='show_rateWrap'>
							<span className='show_num'>月售{salesVolume?salesVolume:0}</span>
							
						</div>
					</section>
					<section className='show_line show_line2'>
						<div className='show_money'>
							<span>起送￥{(minFreight/100).toFixed(2)}</span>
							{/* <span>运费￥{(orderFreight/100).toFixed(2)}</span> */}
						</div>
						<div className='show_time'>
							{localStorage.type==1?<>
							<span>{distance?distance>=1000?(distance/1000).toFixed(1)+'km':distance+'m':'0m'}</span></>:<></>}
						</div>
					</section>
					<section className='show_active'>
						{props.pharmacy.promoteFull ?
							activities1.map((v,index)=>{
								return(
									<Tag key={index} {...v}/>
								)
							}) :
							activities2.map((v,index)=>{
								return(
									<Tag key={index} {...v}/>
								)
							})
						}
						{pharmacyStartTime&&(parseInt(pharmacyStartTime.substring(0,2)*60)+parseInt(pharmacyStartTime.substring(3,5)))<nowTime&&
							(parseInt(pharmacyEndTime.substring(0,2)*60)+parseInt(pharmacyEndTime.substring(3,5)))>nowTime&&promoteStu==0?
							null:<span style={{marginLeft:'10px',marginTop:'-2px',backgroundColor:"#aaa",color:"#fff",padding:'3px 5px',fontSize:'12px'}}>店铺已打烊</span>}
					</section>
					<div className='line'></div>
				</div>
			</section>
		</Link>
    )
}