import React, { Component } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import Meditem from '../orderitem/index'
import {ImageViewer} from "antd-mobile-v5";
import { adressListRequest } from '../../../api/request/adress'
import { orderAllnews, updateAftersale, cancelAftersale } from '../../../api/request/aftersale'
import { oderdetailRequest, upOrderStuRequest } from '../../../api/request/order'
import { storeDetailsRequest } from "../../../api/request/store"
import { addIntegralRequest } from '../../../api/request/integral'
import { pharmacyStorecs } from '../../../api/request/cart'
import getUrl from '../../../component/GetUrlParam'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import '../../../static/css/iconfont.css'
import defaultImg from '../../../static/img/default.png'
import zhezaho from "../../../static/img/zhezaho.jpg"
import fanhui from '../../../static/img/fanhuihei.png'
import kefu from '../../../static/img/kefu2.png'

import './style.less'
const { alert } = Modal
const RadioItem = Radio.RadioItem


//售后状态 1.售后待确认,3.申请被退回,4.用户取消,8.售后完成

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            orderList: '',//售后订单
            medicitailList: [],//所有售后药品
            orderNumber: '',
            tel: '',
            visiable1:'none',
            aftersaleId:'',
            allPrice:0,
            image:{
                url: '',
                visible: false
            },
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
        }
    }
    componentDidMount = async () => {
        this.setState({
            orderNumber: getUrl('orderNumber', this.props.location.search),
            tel: getUrl('tel', this.props.location.search),
        })
        let orderNumber1 = getUrl('orderNumber', this.props.location.search)
        orderAllnews({ orderNumber: orderNumber1 }).then(res => {
            console.log(res)
            let allPrice0=0
            res.data[0].map(item=>{
                item.allPrice1=0
                allPrice0+=item.orderFreight+item.pharmacyPackingFee
                let a=0
                res.data[1]&&res.data[1].map(item0=>{
                    if (item.aftersaleId==item0.aftersale_id) {
                        a+=item0.after_sales_quantity*item0.med_price
                        
                    }
                    item.allPrice1=a
                })
            })
            console.log(allPrice0);
            this.setState({
                orderList: res.data[0],
                medicitailList: res.data[1],
                allPrice:allPrice0
            })
        })
    }
    fanhui1 = () => {
        window.history.back()
    }
    // 撤销申请
    noShenqing=(aftersaleId)=>{
        this.setState({
            visiable1:'block',
            aftersaleId:aftersaleId
        })
    }
    onDelete=()=>{
        const { orderNumber,aftersaleId } = this.state
        let params2={
            aftersaleState:'4',
            orderNumber:orderNumber,
            aftersaleId:aftersaleId
        }
        updateAftersale(params2).then(res1=>{
            console.log(res1);
            this.props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
        })

    }
    render() {
        const { orderList,image, medicitailList, allPrice,tel ,visiable1} = this.state
        return (
            <div>
                <div className='lingshou-shou'>
                    <div className='lingshou-top'>
                        <img src={fanhui} onClick={() => this.fanhui1()} alt="" />
                        <p>售后详情</p>
                    </div>
                    {orderList.length&&orderList.map(item => (<div>
                        {item.aftersaleState == '8' ? <div className='refund-process-top'>
                            <h2>退款成功</h2>
                            <p>退款将在1个工作日内到账</p>
                        </div>
                            :item.aftersaleState == '3' ?  <div className='refund-process-top'>
                                <h2>您申请的售后被商家驳回</h2>
                                <p>驳回原因：{item.aftersaleRefuse}</p>
                            </div>
                            :item.aftersaleState == '1' ?  <div className='refund-process-top1 '>
                            <h2>您申请的售后待商家处理</h2>
                            <p onClick={()=>this.noShenqing(item.aftersaleId)}>撤销申请</p>
                        </div>
                        :item.aftersaleState == '4' ?  <div className='refund-process-top'>
                            <h2>用户已取消此次售后</h2>
                        </div>:null}
                        {item.aftersaleState == '8' ?
                            <div className='tuikuan-price'>
                                <p className='tuikuan-price-top'>
                                    <span>退款金额</span>
                                    <span>￥{((item.realAmount) / 100).toFixed(2)}</span>
                                </p>
                                <p className='tuikuan-price-oragin'>退回至原账户</p>
                            </div>
                            : null}
                        <div className='refund-process'>
                            <h2>退款流程</h2>
                            <p>
                                <a href={`tel:${tel}`}>
                                    <img src={kefu} alt="" />
                                    <span>联系商家</span>
                                </a></p>
                        </div>
                        {item.aftersaleState == '8' ? <div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-kong'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>微信退款入账中</span>
                                    <span>处理完成后会在1个工作日内将￥{((item.realAmount) / 100).toFixed(2)}元入账到您的原账户</span>
                                    <span>{item.aftersaleAgreetime}</span>
                                </p>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>商家同意退款</span>
                                    <span>商家处理完成</span>
                                    <span>{item.aftersaleAgreetime}</span>
                                </p>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{item.applyAftersaleTime}</span>
                                </p>
                            </p>
                        </div> : 
                        item.aftersaleState == '3' ?<div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-kong'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>商家拒绝退款</span>
                                    <span>商家拒绝</span>
                                    <span>{item.updateTime}</span>
                                </p>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{item.applyAftersaleTime}</span>
                                </p>
                            </p>
                        </div>:
                        item.aftersaleState == '1' ?<div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{item.applyAftersaleTime}</span>
                                </p>
                            </p>
                        </div>:
                            item.aftersaleState == '4' ?<div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-kong'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>取消售后申请</span>
                                    <span>用户取消</span>
                                    <span>{item.updateTime}</span>
                                </p>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{item.applyAftersaleTime}</span>
                                </p>
                            </p>
                        </div>:null}
                        <div className='refund-process-bottom'>
                            <h2>退款信息</h2>
                            {medicitailList.length&&medicitailList.map(item0 => (
                                <>
                                    {item0.aftersale_id==item.aftersaleId?<div className='refund-process-allmedicial'>
                                    {/* src={item0.med_photo?item0.med_photo:defaultImg} */}
                                        <img src={item0.medClassify === "1" ? zhezaho : (item0.medPhoto ? item0.medPhoto : defaultImg)} alt="" />
                                        <p className='refund-process-medicial'>
                                            <h3>{item0.med_name}</h3>
                                            <p>{item0.med_spec}</p>
                                            <p className='refund-process-medicial-num'>x{item0.after_sales_quantity?item0.after_sales_quantity:item0.med_refund_quantity}</p>
                                        </p>
                                        <p className='refund-process-medicial-price'>原价：￥{(item0.med_price / 100).toFixed(2)}</p>
                                        <p className='refund-process-medicial-price1'>实付：￥{(Math.round((item0.med_price/item.orderPrice)*(item.orderAmount-item.orderFreight-item.pharmacyPackingFee)) / 100).toFixed(2)}</p>
                                    </div>:null}
                                </>
                            ))}
                        </div>
                        <p className='refund-medicial-bottom'>
                            <span>售后单号</span>
                            <span>{item.aftersaleNumber}</span>
                        </p>
                        <p className='refund-medicial-bottom'>
                            <span>退款原因</span>
                            <span>{item.aftersaleReason}</span>
                        </p>
                        <p className='refund-medicial-bottom2'>
                            <p>退款说明</p>
                            <p>{item.orderAftersaleDetails}</p>
                        </p>
                        <p className='tuikuan-reason-img'>
                            {item.aftersaleCertificate?item.aftersaleCertificate.split(',').map(m=>(
                                <p>
                                    <img src={m} onClick={() => {
                                        this.setState({
                                            image:{
                                            url: m,
                                            visible: true
                                        }})
                                    }}/>
                                </p>
                            )):null}
                        </p>
                    </div>))}
                </div>
                <ImageViewer
                    image={image.url}
                    visible={image.visible}
                    onClose={() => {
                        this.setState({
                            image:{
                                url: '',
                                visible: false
                            }})
                    }}
                />
                {visiable1=='block'?
                <div className='after-outer'>
                    <div className='del-address'>
                        <p className='del-sure'>是否确认取消售后申请？</p>
                        <p className='del-bottom'>
                            <span onClick={()=>this.setState({visiable1:'none'})}>取消</span>
                            <span onClick={()=>this.onDelete()}>确认</span>
                        </p>
                    </div>
                </div>:null}
            </div>
        )
    }
}