// import React, { Component } from 'react'
// import { ImageViewer, Button } from "antd-mobile-v5"
// import { Tabs } from 'antd-mobile'
// import "./style.less"
// import FH from "../../../static/img/mine/fanhui.png"
// import { getstoreinfo } from "../../../api/request/store"
// export default class index extends Component {
//     constructor() {
//         super()
//         this.state = {
//             storetext: {},
//             isModalVisible: false,
//             picnow:null,
//             tabs: [{ title: '商家资质' }, { title: '店铺形象' }]
//         }
//     }
//
//
//     componentDidMount = async () => {
//         const companyId = this.props.pharmacyId
//         //const companyId = 11
//         const res = await getstoreinfo(companyId)
//         this.setState({
//             storetext: res.data[0][0]
//         })
//         console.log(res);
//     }
//     renderContent = (tab, index) => {
//         const { storetext,tabs, isModalVisible,picnow } = this.state
//         return (
//             <>
//                 {/* {index==0? */}
//                 <div className="storeinfobox-content-item">
//                         <img src={storetext.businessPic} alt="" onClick={() => {
//                         // this.setState({
//                         //     isModalVisible:true,
//                         //     picnow:storetext.businessPic
//                         // })
//                     }}/>
//                         <img src={storetext.circulationPic} alt="" onClick={() => {
//                         // this.setState({
//                         //     isModalVisible:true,
//                         //     picnow:storetext.circulationPic
//                         // })
//                     }}/>
//                         <img src={storetext.licensePic} alt="" onClick={() => {
//                         // this.setState({
//                         //     isModalVisible:true,
//                         //     picnow:storetext.licensePic
//                         // })
//                     }}/>
//                     </div>
//                 {/* // index==1?<div className='shop-contain-item'>
//                 //     <Shopinfo pharmacyId={pharmacyId}></Shopinfo>
//                 // </div> */}
//                 {/* :null} */}
//             </>
//         )
//     }
//     render() {
//         const { storetext,tabs, isModalVisible,picnow } = this.state
//         console.log(storetext);
//         return (
//             <div className="storeinfobox">
//
//                 {/* <div className="storeinfobox-top">
//                     <img src={FH} alt="" onClick={() => window.history.back()} /><h2>商家信息资质</h2>
//                 </div>
//                 <div className="storeinfobox-icon">
//                     <img src={storetext.pharmacyPhoto} alt="" /><p>{storetext.pharmacyName}</p>
//                 </div> */}
//                 <div className="storeinfobox-text">
//                     {/* <div className="storeinfobox-text-item">
//                         <p>公司名称</p>
//                         <p>{storetext.accountName}</p>
//                     </div> */}
//                     {/* <div className="storeinfobox-text-item">
//                         <p>商家电话</p>
//                         <p>{storetext.contacts}</p>
//                     </div> */}
//                     <div className="storeinfobox-text-item">
//                         <p>商家电话</p>
//                         <p>{storetext.contactPhone}</p>
//                     </div>
//                     <div className="storeinfobox-text-item">
//                         <p>所在地址</p>
//                         <p>{storetext.companyAddress}</p>
//                     </div>
//                     <div className="storeinfobox-text-item">
//                         <p>营业时间</p>
//                         <p>{storetext.pharmacyStartTime} - {storetext.pharmacyEndTime}</p>
//                     </div>
//                 </div>
//                 <div className="storeinfobox-content">
//                     {/* <div className='storeinfobox-top'>
//                         <h3>商家资质</h3>
//                         <h3>店铺形象</h3>
//                     </div> */}
//                     <Tabs
//                         tabs={tabs}
//                         tabBarTextStyle={{ fontSize: '14px', padding: '0px',marginBottom:'10px'}}
//                         tabBarActiveTextColor="#000"
//                         tabBarInactiveTextColor="#000"
//                         tabBarBackgroundColor='none'
//                         tabBarUnderlineStyle={{ border: '1px #FFFFFF solid', transform: 'scaleX(0.4)', bottom: '16px' }}
//                         >
//                         {this.renderContent}
//                     </Tabs>
//                     {/* <div className="storeinfobox-content-item">
//                         <img src={storetext.businessPic} alt="" onClick={() => {
//                         this.setState({
//                             isModalVisible:true,
//                             picnow:storetext.businessPic
//                         })
//                     }}/>
//                         <img src={storetext.circulationPic} alt="" onClick={() => {
//                         this.setState({
//                             isModalVisible:true,
//                             picnow:storetext.circulationPic
//                         })
//                     }}/>
//                         <img src={storetext.licensePic} alt="" onClick={() => {
//                         this.setState({
//                             isModalVisible:true,
//                             picnow:storetext.licensePic
//                         })
//                     }}/>
//                     </div> */}
//                 </div>
//                 <ImageViewer
//                     image={picnow}
//                     visible={isModalVisible}
//                     onClose={() => {
//                         this.setState({
//                             isModalVisible:false
//                         })
//                     }}
//                 />
//             </div>
//         )
//     }
// }
//框架
import React, {useEffect, useState} from 'react';

import {storePharmacy} from '../../../api/request/store'
import {error} from "../../../api/request/message";
import {Grid, Card, Space, Tabs, Image, ErrorBlock,SpinLoading} from 'antd-mobile-v5'

/**
 * @Author: 陈倩
 * @Date: 2022/8/13 13:32
 * @Description: 店铺详情
 * @FilePath: src\application\l_page\content\shopDetails\MedClassify.jsx
 */
const Shopinfo = (props) => {

    const [detail, setDetail] = useState('')
    const [load, setLoad] = useState(true)

    useEffect(() => {
        storePharmacy(props.pharmacyId).then(r => {
            console.log(r)
            setDetail(r.data[0][0])
        }).catch(e => {
            error(e.mesg)
        })
        const time=setTimeout(()=>{
            setLoad(false)
            window.clearInterval(time);
        },1500)
    }, [])

    console.log(detail)
    return (
        <div style={{paddingTop: 0}}>
            {load?<div style={{position:'absolute',paddingLeft:'45vw',paddingTop:'30vh',width:'100vw',height:'100vh',zIndex:'999'}} className='loadind'>
                    <SpinLoading  style={{ '--size': '48px' }}/>
            </div>:null}
            <Grid columns={1} gap={8}>
                <Grid.Item span={1}>
                    <Card>
                        <Grid columns={8} gap={8}>
                            <Grid.Item span={2}>销售企业全称</Grid.Item>
                            <Grid.Item span={6}>
                                {detail.pharmacy_name ?detail.pharmacy_name: '---'}
                            </Grid.Item>

                        </Grid>
                    </Card>
                </Grid.Item>
                <Grid.Item span={1}>
                    <Card>
                        <Grid columns={6} gap={8}>
                            <Grid.Item span={1}>商家电话</Grid.Item>
                            <Grid.Item span={4}>
                                {detail.service_phone ? detail.service_phone : '---'}
                            </Grid.Item>
                            <Grid.Item span={1}>
                                <a href={`tel:${detail.contact_phone}`}>
                                    <i  style={{ fontSize: '14px' }} className="iconfont icon-tubiao210"></i>
                                </a>
                            </Grid.Item>

                        </Grid>
                    </Card>
                </Grid.Item>
                <Grid.Item span={1}>
                    <Card>
                        <Grid columns={6} gap={8}>
                            <Grid.Item span={1}>所在地区</Grid.Item>
                            <Grid.Item span={5}>
                                {detail.company_address ?detail.province+ detail.company_address : '---'}
                            </Grid.Item>

                        </Grid>
                    </Card>
                </Grid.Item>
                <Grid.Item span={1}>
                    <Card>
                        <Grid columns={6} gap={8}>
                            <Grid.Item span={1}>营业时间</Grid.Item>
                            <Grid.Item span={5}>
                                {detail.pharmacy_start_time ? detail.pharmacy_start_time + '-' + detail.pharmacy_end_time : '---'}
                            </Grid.Item>

                        </Grid>
                    </Card>
                </Grid.Item>
            </Grid>


            <Card>
                <Tabs>
                    <Tabs.Tab title='商家资质' key='商家资质'>
                        <Space direction='vertical'>
                            <div className='vertical-outer'>
                                {detail.license_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.license_pic}/>
                                        {detail.license_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>统一社会信用代码：{detail.license_number}</p>:null}
                                        {detail.licenseStartTime?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>时间期限：{detail.licenseStartTime}~{detail.isLongterm==2?'长期':detail.licenseEndTime}</p>:null}
                                    </div>
                                    : null}
                                {detail.business_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.business_pic}/>
                                        {detail.business_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>药品经营许可证号：{detail.business_number}</p>:null}
                                        {detail.busStartTime?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>时间期限：{detail.busStartTime}~{detail.busEndTime}</p>:null}
                                    </div>
                                    : null}
                                {detail.pharmacist_registration_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.pharmacist_registration_pic}/>
                                        {detail.pharmacist_registration_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>执业药师注册证：{detail.pharmacist_registration_number}</p>:null}
                                        {detail.pharmacistRegistrationStart?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>时间期限：{detail.pharmacistRegistrationStart}~{detail.pharmacistRegistrationEnd}</p>:null}
                                    </div>
                                    : null}
                                {detail.medical_device_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.medical_device_pic}/>
                                        {detail.medical_device_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>医疗器械经营许可证号：{detail.medical_device_number}</p>:null}
                                        {detail.medicalStartTime?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>时间期限：{detail.medicalStartTime}~{detail.medicalEndTime}</p>:null}
                                    </div>
                                    : null}
                                {detail.circulation_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.circulation_pic}/>
                                        {detail.circulation_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>食品经营许可证号：{detail.circulation_number}</p>:null}
                                        {detail.cirStartTime?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>时间期限：{detail.cirStartTime}~{detail.cirEndTime}</p>:null}
                                    </div>
                                    : null}
                                {detail.medicalII_device_pic ?
                                    <div className='vertical-inner'>
                                        <Image height={'100%'} src={detail.medicalII_device_pic}/>
                                        {detail.medicalII_device_number?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px'}}>第二类医疗器械经营备案凭证：{detail.medicalII_device_number}</p>:null}
                                        {detail.medicalIIStartTime?<p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'40px'}}>时间期限：{detail.medicalIIStartTime}~{detail.medicalIIEndTime}</p>:null}
                                    </div>
                                    : null}

                            </div>
                        </Space>
                    </Tabs.Tab>
                    <Tabs.Tab title='店铺形象' key='vegetables'>
                        <Space direction='vertical'>
                            {detail.shop_front_photo ?
                                <>
                                    <Image height={'100%'} src={detail.shop_front_photo}/>
                                    <p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>门店门头照片</p>
                                </>
                                : null}
                            {detail.shop_interior_photos ?
                                <>
                                    <Image height={'100%'} src={detail.shop_interior_photos}/>
                                    <p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'10px'}}>门店内景照片</p>
                                </>:null}
                            {detail.cashier_photo ?
                                <>
                                    <Image height={'100%'} src={detail.cashier_photo}/>
                                    <p style={{lineHeight:'20px',fontWeight:'600',marginLeft:'10px',marginBottom:'40px'}}>收银台照片</p>
                                </>
                                : null}
                        </Space>
                        {
                            !detail.shop_front_photo && !detail.shop_interior_photo && !detail.cashier_photo ?
                                <Space block direction='vertical' style={{'--gap': '16px'}}>
                                    <ErrorBlock status='empty' style={{width: '100%',height:'45vh'}}/>
                                </Space> : null
                        }


                    </Tabs.Tab>
                </Tabs>

            </Card>


        </div>
    );
};

export default Shopinfo
