import { withRouter } from 'react-router-dom'
import { Toast, Modal,ImagePicker} from 'antd-mobile'
import Button from '../../baseui/fuctionbutton/index'
import React,{useState,useEffect} from 'react'
import { oderdetailRequest,applyRefundRequest,addRemittance,upOrderStuRequest,delRemittance,remittanceList,changenum,refundUserview,huifuCouponState} from '../../api/request/order'
import { tuiNum,allFreights } from '../../api/request/cart'
import { meddetailRequest,updateStockOccupy } from '../../api/request/med'
import {integralIsuse,getUserIntegral} from '../../api/request/integral'
import './myorder.less'
import '../../static/css/iconfont.css'
import defaultImg from '../../static/img/default.png'
import zhezaho from "../../static/img/zhezaho.jpg"
import { logDOM } from '@testing-library/react'
import {zfUrl,code1} from "../../publicurl"
import { uploadImg } from '../../api/request/upload'
import { zipImg } from '../../component/zipImage/index'
import close from '../../static/img/close.png'
import lianxi from '../../static/img/lianxi.png'
import {  updateAftersale, cancelAftersale, aftersaleList } from '../../api/request/aftersale'
const { alert } = Modal
const newList = (orderNumber, medList) => {
    // console.log(medList);
    let list = []
    medList.forEach(item => {
        if(item.orderNumber === orderNumber) {
            list.push(item)
        }
    })
    return list
}
// const time = localStorage.getItem("nowTime")

//0 全部订单 1 待评价 2 退款
function Orderitem(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const {
        state,
        onclick,
        delOrder,
        delOrder2,
        cancelRefund,
        tabId0,
        pharmacy,
        medList,
        refreshList,
        applyRefund
    } = props
    const newMedList = newList(pharmacy.orderNumber, medList)
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [visible2, setVisible2] = useState('none');
    const [remittanceId, setremittanceId] = useState()
    const [aftersaleId, setaftersaleId] = useState()
    const [visiable1, setVisiable1] = useState('none');
    const [visiable2, setVisiable2] = useState('none');
    const [visiable3, setVisiable3] = useState('none');
    const [visiable4, setVisiable4] = useState('none');

    const [orderIntegral, setorderIntegral] = useState(0)


    const [visiable7, setVisiable7] = useState('none');
    const [servicePhone, setServicePhone] = useState()

    const [nowTime, setnowTime] = useState(0);

    const [e, setE] = useState();
    const [orderNumber, setOrderNumber] = useState();

    useEffect(() => {
        let time = new Date()
        // console.log(time.getHours()*60+time.getMinutes());
        setnowTime(time.getHours() * 60 + time.getMinutes())
    }, [])
    console.log(tabId0)
    const updateOrder = () => {
        let orderMedList = []
        newMedList.forEach(item => {
            orderMedList.push({
                orderMedId: item.orderMedId,
                medId: item.medId,
                medName: item.medName,
                medPhoto: item.medPhoto,
                medSpec: item.medSpec,
                medPrice: item.medPrice,
                cartPrice: item.medPrice * item.medQuantity,
                medQuantity: item.medQuantity
            })
        })
        const cartArr = [{
            pharmacy: {
                orderNumber: pharmacy.orderNumber,
                orderShipping: pharmacy.orderShipping,
                pharmacyId: pharmacy.pharmacyId,
                pharmacyName: pharmacy.pharmacyName,
                payMethod: pharmacy.payMethod,
                freight: pharmacy.freight,
            },
            medList: orderMedList
        }]
        props.history.push({pathname: '/fillorder', state: cartArr})
    }
    const payFor = (orderNumber, orderAmount) => {
        const orderName = `${newMedList[0].medName}等`
        let b = (new Date()).getTime()
        // if(localStorage.type == "1"){
        window.location.href = `${zfUrl}/route?client_id=${code1}&channel=&client_trade_no=${orderNumber}&total_fee=${orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
        // }else if(localStorage.type == "2"){
        //     window.location.href = `${zfUrl}/route?client_id=2000&channel=&client_trade_no=${orderNumber}&total_fee=${orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
        // }else if(localStorage.type == "3"){
        //     window.location.href = `${zfUrl}/route?client_id=3000&channel=&client_trade_no=${orderNumber}&total_fee=${orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
        // }
    }

    const confirm = (e, orderNumber) => {
        e.stopPropagation()
        setVisiable3('block')
        setOrderNumber(orderNumber)
        setE(e)
    }
    const onSure = () => {
        refreshList(e, orderNumber)
        setVisiable3('none')

    }
    const refundApply = (e, orderNumber, refundRefuse, isAftersaleOrder) => {
        console.log(pharmacy)
        if (refundRefuse || isAftersaleOrder == 2) {
            e.stopPropagation()
            Toast.info('该订单不能申请退款，继续退款请联系商家')
        } else {
            e.stopPropagation()
            applyRefund(e, orderNumber)
        }
    }
    const daYang = (e) => {
        e.stopPropagation()
        Toast.info('店铺已打烊，不能进行下单')
    }
    const buyAgin = (e) => {
        e.stopPropagation()
        let pharmacyDetail = []
        let countRecall = 1
        if (pharmacy.pharmacyState == 1) {
            if ((nowTime >= (parseInt(pharmacy.pharmacyStartTime.split(':')[0] * 60) + parseInt(pharmacy.pharmacyStartTime.split(':')[1])) &&
                nowTime <= parseInt(pharmacy.pharmacyEndTime.split(':')[0] * 60) + parseInt(pharmacy.pharmacyEndTime.split(':')[1]) && pharmacy.promoteStu == '0')) {
                allFreights(pharmacy.pharmacyId).then(res0 => {
                    console.log(res0);
                    if (res0.code == 1) {
                        pharmacyDetail = res0.data[0][0]
                        let orderMedList = []
                        oderdetailRequest(pharmacy.orderNumber).then(res => {
                            console.log(res);
                            console.log(pharmacyDetail);
                            res.data[1].forEach(item => {
                                console.log(item);
                                if (item.recall == 2 || item.medExamine != 2 || item.violation == 1) {
                                    countRecall = 2
                                } else {
                                    meddetailRequest(item.medId).then(res2 => {
                                        console.log(res2);
                                        if (res2.data[0][0].medPrice == 0 || !res2.data[0][0].libraryId) {
                                            Toast.info('此订单部分商品已下架，不能进行下单')
                                        } else if (res2.data[0][0].medDelete != '1') {
                                            Toast.info('此订单部分商品已下架，不能进行下单')
                                        } else {
                                            orderMedList.push({
                                                cartId: 0,
                                                medId: item.medId,
                                                medName: item.medName,
                                                medPhoto: res2.data[0][0].medPhoto,
                                                medSpec: res2.data[0][0].medSpec,
                                                medPrice: res2.data[0][0].medPrice,
                                                medCompany: res2.data[0][0].medCompany,
                                                medPacking: '1',
                                                medApproval: res2.data[0][0].medApproval,
                                                cartPrice: item.medPrice * item.medQuantity,
                                                medQuantity: item.medQuantity,
                                                medClassify: item.medClassify,
                                                medQrcode: item.medQrcode,
                                                medApparatus: res2.data[0][0].medApparatus,
                                                medValidity: res2.data[0][0].medValidity,
                                                medEnterprise: res2.data[0][0].medEnterprise,
                                                // activitiesCycle:JSON.parse(item.activitiesCycle),
                                                // activitiesDiscount:JSON.parse(item.activitiesDiscount),
                                                // activitiesName:item.activitiesName,
                                                // activitiesStartTime:item.activitiesStartTime,
                                                // activitiesEndTime:item.activitiesEndTime,
                                            })
                                        }
                                        const cartArr = [{
                                            pharmacy: {
                                                orderShipping: pharmacy.orderShipping,
                                                pharmacyId: pharmacy.pharmacyId,
                                                pharmacyName: pharmacy.pharmacyName,
                                                payMethod: pharmacy.payMethod,
                                                orderFreight: pharmacyDetail.orderFreight,
                                                freeFreight: pharmacyDetail.freeFreight,
                                                minFreight: pharmacyDetail.minFreight
                                            },
                                            medList: orderMedList
                                        }]
                                        if (cartArr[0].medList && cartArr[0].medList.length) {
                                            props.history.push({pathname: '/fillorder', state: cartArr})
                                        }
                                    })
                                }
                                if (countRecall == 2) {
                                    Toast.info('已被召回或审核未通过的商品无法继续下单')
                                }
                            })
                        })
                    }
                })
            } else {
                Toast.info('店铺已打烊，无法继续下单')
            }
        } else {
            Toast.info('店铺已下架，无法继续下单')
        }
    }
    const toComment = (e, orderNumber) => {
        e.stopPropagation()
        props.history.push(`/comment?orderNumber=${orderNumber}`)
    }
// 退款
    const sureRefund = () => {
        // setVisible1('block')
        if (localStorage.type === '2') {
            alert('选择退款路径？', '', [
                {text: '取消申请', onPress: () => console.log('cancel'), style: 'default'},
                {
                    text: '原路返回', onPress: () => {
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator: 0,
                            refundDestination: 0,
                        }
                        applyRefundRequest(params).then(res => {
                            console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
                {
                    text: '退款到钱包', onPress: () => {
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator: 0,
                            refundDestination: 1,
                        }
                        applyRefundRequest(params).then(res => {
                            // console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
            ])
        } else {
            alert('确认退款？', '', [
                {text: '取消', onPress: () => console.log('cancel'), style: 'default'},
                {
                    text: '确认', onPress: () => {
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator: 0
                        }
                        applyRefundRequest(params).then(res => {
                            // console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
            ])
        }

    }

    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic1
        if (type === 'add') {
            let file = files[files.length - 1].file
            // console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    // console.log(res1);
                    // console.log(files);
                    if (res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
    const dianji = () => {
        setVisible2('none')
    }
    const dianji1 = (e) => {
        e.stopPropagation()
    }
    const uploadPz = () => {
        if (evaluatePic1.length > 0) {
            let data = {
                orderNumber: pharmacy.orderNumber,
                remittancePath: evaluatePic1
            }
            addRemittance(data).then(res => {
                // console.log(res);
                if (res.code == 1) {
                    upOrderStuRequest({orderState: '-5', orderNumber: pharmacy.orderNumber}).then(res1 => {
                        if (res1.code == 1) {
                            window.location.reload()
                        }
                    })
                }
            })
        } else {
            Toast.info('未上传转账凭证')
        }
    }
    const delPz = () => {
        alert('确认删除凭证？', '', [
            {text: '取消', onPress: () => console.log('cancel'), style: 'default'},
            {
                text: '确认', onPress: () => {
                    remittanceList({orderNumber: pharmacy.orderNumber}).then(res => {
                        // console.log(res);
                        if (res.data[0].length) {
                            delRemittance({
                                remittanceId: res.data[0][0].remittance_id,
                                orderNumber: pharmacy.orderNumber
                            }).then(res1 => {
                                // console.log(res1);
                                if (res1.code == 1) {
                                    window.location.reload()
                                }
                            })
                        }
                    })
                }
            },
        ])

    }
    // 上传支付凭证
    const sureUp = () => {
        setVisible2('block')
        remittanceList({orderNumber: pharmacy.orderNumber}).then(res => {
            // console.log(res);
            if (res.data[0].length) {
                let b = res.data[0][0].remittance_id
                setremittanceId(b)
            }
        })
        aftersaleList({userId, orderTime: 'down'}).then(res => {
            // console.log(res);
            if (res.code == 1) {
                if (res.data[0].length > 0) {
                    // setVisible1('block')
                    let a = []
                    res.data[0].map(item => {
                        if (item.order_number === pharmacy.orderNumber) {
                            a.push(item)
                            setaftersaleId(a[0].aftersale_id)
                        }
                    })
                }
            }
        })
    }
    const afterMarket1 = () => {
        alert('是否确认取消售后申请？', '', [
            {text: '取消', onPress: () => console.log('cancel'), style: 'default'},
            {
                text: '确认', onPress: () => {
                    cancelAftersale({aftersaleId}).then(res => {
                        // console.log(res);
                        if (res.code == 1) {
                            upOrderStuRequest({
                                orderState: '0',
                                orderNumber: pharmacy.orderNumber,
                                refundOriginator: 0
                            }).then(res1 => {
                                if (res1.code == 1) {
                                    props.history.push(`/mine/order`)
                                }
                            })
                        }
                    })
                }
            },
        ])

    }
    // 取消订单
    const sureRefund1 = () => {
        alert('是否确认取消订单？', '', [
            {text: '取消', onPress: () => console.log('cancel'), style: 'default'},
            {
                text: '确认', onPress: () => {
                    const array = []
                    const array1 = []
                    oderdetailRequest(pharmacy.orderNumber).then(res => {
                        console.log(res);
                        res.data[1].forEach(item => {
                            array.push({
                                stockNum: item.medQuantity,
                                med_id: item.medId
                            })
                            array1.push({
                                medRefundQuantity: item.medQuantity,
                                orderMedId: item.orderMedId
                            })
                        })

                        changenum({array}).then(res1 => {
                            if (res1.code == 1) {
                                upOrderStuRequest({orderState: '4', orderNumber: pharmacy.orderNumber}).then(res2 => {
                                    // console.log(res2);
                                    if (res2.code === 1) {
                                        updateStockOccupy({array: array1}).then(res1 => {
                                            console.log(res1);
                                            window.location.reload()
                                        })
                                        // Toast.info('已确认收货', 1)
                                    }
                                })
                            }
                        })
                    })
                }
            },
        ])
    }
    const confirmReceipt1 = () => {
        props.history.push(`/mine/order/aftermarket?tradeno=${pharmacy.orderNumber}`)
    }
    const afterMarket = (orderNumber) => {
        props.history.push(`/mine/order/aftermarket?tradeno=${orderNumber}`)
    }
    const lookReason = () => {
        let params = {
            orderNumber: pharmacy.orderNumber,
            userId
        }
        refundUserview(params).then(res => {
            console.log(res);
            let a = res.data[0][0].origrefundReason
            alert('商家退款理由', a, [
                {text: '确认', onPress: () => console.log('cancel'), style: 'default'},
            ])
        })
    }
    // 删除
    const delOrder1 = (e, orderNumber) => {
        console.log(e, orderNumber);
        e.stopPropagation()
        setVisiable1('block')
        setOrderNumber(orderNumber)
        setE(e)
    }
    // 确认删除
    const onDelete = () => {
        delOrder2(e, orderNumber)
        setVisiable1('none')

    }
    // 取消订单
    const quxiao = (e, servicePhone) => {
        e.stopPropagation()
        e.preventDefault()
        setVisiable7('block')
        setServicePhone(servicePhone)
    }
    // 未支付取消订单
    const qxOrder = (e, orderNumber, orderIntegral) => {
        console.log(pharmacy);
        e.stopPropagation()
        if (pharmacy.orderMedicineDiscountPrice > 0) {
            alert('此订单含有特价商品，若下单后取消订单限购数量将不予恢复', '', [
                {text: '暂不取消', onPress: () => console.log('cancel'), style: 'default'},
                {
                    text: '确认取消', onPress: () => {
                        onQuxiao()
                    }
                },
            ])
        } else {
            setVisiable2('block')
        }
        setOrderNumber(orderNumber)
        setorderIntegral(orderIntegral)
    }
    const qxOrder1 = (e, orderNumber, orderIntegral) => {
        console.log(pharmacy);
        e.stopPropagation()
        if (pharmacy.orderMedicineDiscountPrice > 0) {
            alert('此订单含有特价商品，若下单后取消订单限购数量将不予恢复', '', [
                {text: '暂不取消', onPress: () => console.log('cancel'), style: 'default'},
                {
                    text: '确认取消', onPress: () => {
                        onQuxiao()
                    }
                },
            ])
        } else {
            setVisiable4('block')
        }
        setOrderNumber(orderNumber)
        setorderIntegral(orderIntegral)
    }
    const onQuxiao = () => {
        setVisiable2('none')
        setVisiable4('none')
        const array1 = []
        oderdetailRequest(pharmacy.orderNumber).then(res0 => {
            console.log(res0);
            res0.data[1].forEach(item => {
                array1.push({
                    medRefundQuantity: item.medQuantity,
                    orderMedId: item.orderMedId
                })
            })
            upOrderStuRequest({orderState: '4', orderNumber: pharmacy.orderNumber, refundOriginator: 0}).then(res1 => {
                if (res1.code == 1) {
                    updateStockOccupy({array: array1}).then(res1 => {
                        console.log(res1);
                    })
                    integralIsuse({userId, userIntegralIsuse: -orderIntegral}).then(res3 => {
                        console.log(res3);
                    })
                    newMedList.map(item => {
                        tuiNum({
                            stockNum: item.medQuantity,
                            orderNumber: pharmacy.orderNumber,
                            medId: item.medId
                        }).then(res => {
                            console.log(res);
                            window.location.reload()
                        })
                    })
                    if (pharmacy.couponManagerId) {
                        huifuCouponState({
                            couponManagerId: pharmacy.couponManagerId,
                            pharmacyId: pharmacy.pharmacyId
                        }).then(res => {
                            console.log(res);
                        })

                    }
                }
            })
        })
    }
    // 退款成功查看详情
    const lookDetails = (e, orderNumber, servicePhone) => {
        e.stopPropagation()
        props.history.push(`/mine/order/refundDetails?tel=${servicePhone}&orderNumber=${orderNumber}`)
    }
    // console.log(nowTime);
    // console.log(newMedList);
    // 
    const nopayFor = (e) => {
        e.stopPropagation()
        Toast.info('店铺已打烊，不能进行支付')
    }
    // console.log(pharmacy);
    return (
        <>
            <div className='order-whole flexc'>
                <div className='order-alone flexr'
                     onClick={() => onclick(pharmacy.orderNumber)}>
                    <div className='order1-left flexc'>
                        <div className='order-shopname'>
                            <img src={pharmacy.pharmacyPhoto} alt=""/>
                            {(nowTime >= (parseInt(pharmacy.pharmacyStartTime.split(':')[0] * 60) + parseInt(pharmacy.pharmacyStartTime.split(':')[1])) &&
                                nowTime <= parseInt(pharmacy.pharmacyEndTime.split(':')[0] * 60) + parseInt(pharmacy.pharmacyEndTime.split(':')[1]) && pharmacy.promoteStu == '0') ?
                                <div className='order-shopname-left'>
                                    <div className='order-shopname-in'>
                                        <p>{pharmacy.pharmacyName}</p>
                                        <i className='iconfont-s icon-xiangyou'></i>
                                    </div>
                                    {/* <span>60减5</span> */}
                                </div>
                                :
                                <div className='order-shopname-left'>
                                    <div className='order-shopname-in1'>
                                        <p>{pharmacy.pharmacyName}</p>
                                        <i className='iconfont-s icon-xiangyou'></i>
                                    </div>
                                    <span className='dayang'>本店已打烊</span>
                                </div>}
                            <div className='order-right flexc'>
                                {state <= 4 ?
                                    <div className='order-state-row'>
                                        {state === 0 && pharmacy.orderState === '-2' ? <span>待付款</span> : null}
                                        {state === 0 && pharmacy.orderState === '-1' ?
                                            <span style={{marginLeft: '-25px'}}>商家已接单</span> : null}
                                        {state === 0 && pharmacy.orderState === '-11' ?
                                            <span style={{marginLeft: '-25px'}}>商家已接单</span> : null}
                                        {state === 0 && pharmacy.orderState === '1' ?
                                            <span style={{marginLeft: '-25px'}}>待自提</span> : null}

                                        {state === 0 && pharmacy.orderState === '5' ?
                                            <span style={{marginLeft: '-25px'}}>已申请退款</span> : null}
                                        {state === 0 && pharmacy.orderState === '4' ?
                                            <span className='quxiao'>已取消</span> : null}
                                        {state === 0 && pharmacy.orderState === '2' ?
                                            <span className='quxiao'>已完成</span> : null}
                                        {state === 0 && pharmacy.orderState === '3' ?
                                            <span className='quxiao'>已完成</span> : null}
                                        {state === 0 && pharmacy.orderState === '-4' ?
                                            <span className='quxiao'>待配送</span> : null}
                                        {state === 0 && pharmacy.orderState === '0' ? <span>配送中</span> : null}
                                        {state === 0 && pharmacy.orderState === '12' ? <span>售后中</span> : null}
                                        {state === 0 && pharmacy.orderState === '6' ?
                                            <span style={{marginLeft: '-15px'}}>退款成功</span> : null}
                                        {state === 0 && pharmacy.orderState === '8' ?
                                            <span style={{marginLeft: '-25px'}}>待接收订单</span> : null}
                                        {state === 0 && pharmacy.orderState === '9' ?
                                            <span style={{marginLeft: '-15px'}}>自提完成</span> : null}
                                        {state === 0 && pharmacy.orderState === '7' ?
                                            <span style={{marginLeft: '-15px'}}>退款失败</span> : null}
                                        {state === 0 && pharmacy.orderState === '-3' ?
                                            <span style={{marginLeft: '-25px'}}>待接收订单</span> : null}
                                        {state === 0 && pharmacy.orderState === '16' ?
                                            <span style={{marginLeft: '-30px'}}>填问诊信息</span> : null}
                                        {state === 1 ?
                                            <div className='order-state-row'>
                                                <div className='order-state-row'>
                                                    {pharmacy.orderState === '-2' ? <span style={{marginLeft: '-10px'}}
                                                                                          className='refund-apply'>待付款</span> : null}
                                                </div>
                                            </div>
                                            : null}
                                        {state === 2 ?
                                            <div className='order-state-row'>
                                                <div className='order-state-row'>
                                                    {pharmacy.orderState === '0' ? <span style={{marginLeft: '-10px'}}
                                                                                         className='refund-apply'>配送中</span> : null}
                                                </div>
                                            </div>
                                            : null}
                                        {state === 3 ?
                                            <div className='order-state-row'>
                                                {/* <i className='iconfont icon-icon--'
                                            onClick={(e)=>delOrder1(e, pharmacy.orderNumber)}></i> */}
                                                <div className='order-state flex-col' style={{marginLeft: '-25px'}}>
                                                    <div className='flex-apart-row order-state-star'>
                                                        <i className='iconfont-s icon-xingxing'></i>
                                                        <i className='iconfont-s icon-xingxing'></i>
                                                        <i className='iconfont-s icon-xingxing'></i>
                                                    </div>
                                                    <p style={{marginTop: '-30px'}}>已完成</p>
                                                    <div className='order-state-line'></div>
                                                </div>
                                            </div>
                                            : null}
                                        {state === 4 ?
                                            <div className='order-state-row'>
                                                {pharmacy.orderState === '7' ? <span style={{marginLeft: '-10px'}}
                                                                                     className='refund-apply'>退款失败</span> : null}
                                                {pharmacy.orderState === '5' ? <span style={{marginLeft: '-10px'}}
                                                                                     className='refund-apply'>已申请退款</span> : null}
                                                {pharmacy.orderState === '6' ? <span style={{marginLeft: '-10px'}}
                                                                                     className='refund-apply'>退款成功</span> : null}
                                            </div>
                                            : null}
                                    </div>
                                    : null}
                                {state === 5 ?
                                    // <div className='order-state-row'>
                                    //     <i className='iconfont icon-icon--'
                                    //     onClick={(e)=>delOrder(e, pharmacy.orderNumber)}></i>
                                    //     <div className='order-state flex-col'>
                                    //         <div className='flex-apart-row order-state-star'>
                                    //             <i className='iconfont-s icon-xingxing'></i>
                                    //             <i className='iconfont-s icon-xingxing'></i>
                                    //             <i className='iconfont-s icon-xingxing'></i>
                                    //         </div>
                                    //         <p>已取消</p>
                                    //         <div className='order-state-line'></div>
                                    //     </div>
                                    // </div>
                                    <div className='order-state-row'>
                                        {pharmacy.orderState === '7' ? <span style={{marginLeft: '-10px'}}
                                                                             className='refund-apply'>退款失败</span> : null}
                                        {pharmacy.orderState === '5' ? <span style={{marginLeft: '-10px'}}
                                                                             className='refund-apply'>已申请退款</span> : null}
                                        {pharmacy.orderState === '6' ? <span style={{marginLeft: '-10px'}}
                                                                             className='refund-apply'>退款成功</span> : null}
                                    </div>
                                    : null}

                            </div>
                        </div>
                        <div className='flexr'>
                            {newMedList.length > 0 ?
                                newMedList.map((item, index) => (
                                    pharmacy.orderNumber === item.orderNumber ?
                                        <div className='med-col' key={index}>
                                            {newMedList.length > 1 ?
                                                <>
                                                    <div className='myorder-contend1'>
                                                        <div className='myorder-contend-left1'>
                                                            <div className='myorder-img'>
                                                                <img src={item.medClassify === "1" ? zhezaho : (item.medPhoto ? item.medPhoto : defaultImg)}
                                                                     alt=''
                                                                     onError={(e) => {
                                                                         e.target.onerror = null;
                                                                         e.target.src = defaultImg
                                                                     }}/>
                                                            </div>
                                                            <div className='med-detail flexc'>
                                                                <span>{item.medName}{item.medSpec}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='order-show-num1 '>
                                                        <p>¥{pharmacy.orderAmount / 100}</p>
                                                        <span>共{pharmacy.quantity}件</span>
                                                    </div>
                                                    {/* <p>111{pharmacy.refundRefuse}</p> */}
                                                </>
                                                :
                                                <div className='myorder-contend'>
                                                    <div className='myorder-contend-left'>
                                                        <div className='myorder-img'>
                                                            <img src={item.medClassify === "1" ? zhezaho : (item.medPhoto ? item.medPhoto : defaultImg)} alt=''
                                                                 onError={(e) => {
                                                                     e.target.onerror = null;
                                                                     e.target.src = defaultImg
                                                                 }}/>
                                                        </div>
                                                        <div className='med-detail flexc'>
                                                            <p>{item.medName}</p>
                                                            <span>{item.medSpec}</span>
                                                        </div>
                                                    </div>
                                                    <div className='order-show-num flexc'>
                                                        <p>¥{pharmacy.orderAmount / 100}</p>
                                                        <span>共{pharmacy.quantity}件</span>
                                                    </div>
                                                    {/* <span>{pharmacy.refundRefuse}</span> */}
                                                </div>
                                            }
                                        </div> : null))
                                : null}
                        </div>
                    </div>
                    <div className='order-fuction flexr'>
                        {state === 0 ?
                            <div className='order-fuction-contend'>
                                {pharmacy.orderState === '4' ?
                                    <>
                                        <p onClick={(e) => delOrder1(e, pharmacy.orderNumber)}>删除</p>
                                        <div>
                                            {/* <p className='a' onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除记录</p>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>查看详情</p> */}
                                            <p className='a' onClick={(e) => buyAgin(e)}>重新下单</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '5' || pharmacy.orderState === '7' ?
                                    <>
                                        <p></p>
                                        <div>
                                            {/* <p className='a' onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除记录</p>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>查看详情</p> */}
                                            <p className='a' onClick={(e) => buyAgin(e)}>重新下单</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '6' ?
                                    <>
                                        <p></p>
                                        <div>
                                            {/* <p className='a' onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除记录</p>*/}
                                            <p className='b'
                                               onClick={(e) => lookDetails(e, pharmacy.orderNumber, pharmacy.servicePhone)}>查看详情</p>
                                            <p className='a' onClick={(e) => buyAgin(e)}>重新下单</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '-2' ?
                                    <>
                                        <p></p>
                                        <div>
                                            {/* <Button name='修改订单'
                                onClick={updateOrder}/> */}
                                            {/* <Button name='去支付' basecolor='#297FE3'
                                onClick={()=>payFor(pharmacy.orderNumber, pharmacy.orderAmount)}/> */}
                                            <p className='a'
                                               onClick={(e) => qxOrder(e, pharmacy.orderNumber, pharmacy.orderIntegral)}>取消订单</p>
                                            <p className='b'
                                               onClick={() => payFor(pharmacy.orderNumber, pharmacy.orderAmount)}>立即支付</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '-1' || pharmacy.orderState === '-11' ?
                                    <>
                                        <p></p>
                                        <div>
                                            <p className='a'
                                               onClick={(e) => refundApply(e, pharmacy.orderNumber, pharmacy.refundRefuse, pharmacy.isAftersaleOrder)}>取消订单</p>
                                            <p className='b' onClick={(e) => confirm(e, pharmacy.orderNumber)}>确认收货</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '1' ?
                                    <>
                                        <p></p>
                                        <div>
                                            <p className='b'>取件码</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '-4' ?
                                    <>
                                        <p></p>
                                        <div>
                                            <p className='a'
                                               onClick={(e) => refundApply(e, pharmacy.orderNumber, pharmacy.refundRefuse, pharmacy.isAftersaleOrder)}>取消订单</p>
                                            {/* <p className='b' onClick={(e)=>confirm(e, pharmacy.orderNumber)}>确认收货</p> */}
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '0' ?
                                    <>
                                        <p></p>
                                        <div>
                                            <p className='a' onClick={(e) => quxiao(e, pharmacy.servicePhone)}>取消订单</p>
                                            {/* <p className='b' onClick={(e)=>confirm(e, pharmacy.orderNumber)}>确认收货</p> */}
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '-3' || pharmacy.orderState === '16' || pharmacy.orderState === '8' ?
                                    <>
                                        <p></p>
                                        <div>
                                            <p className='a'
                                               onClick={(e) => refundApply(e, pharmacy.orderNumber, pharmacy.refundRefuse, pharmacy.isAftersaleOrder)}>取消订单</p>
                                        </div>
                                    </> : null}
                                {/*{pharmacy.orderState==='8'?*/}
                                {/*<>*/}
                                {/*<p></p>*/}
                                {/*<div>*/}
                                {/*    <p className='b' >取件码</p>*/}
                                {/*</div></>:null}*/}
                                {pharmacy.orderState === '2' ?
                                    <>
                                        {/* <p onClick={()=>onclick(pharmacy.orderNumber)}>申请退款</p> */}
                                        <p></p>
                                        <div>
                                            <p className='a' onClick={(e) => buyAgin(e)}>再来一单</p>
                                            <p className='b' onClick={(e) => toComment(e, pharmacy.orderNumber)}>评价</p>
                                        </div>
                                    </> : null}
                                {pharmacy.orderState === '3' ?
                                    <>
                                        {/* <p onClick={()=>onclick(pharmacy.orderNumber)}>申请退款</p> */}
                                        <p></p>
                                        <div>
                                            <p className='a' onClick={(e) => buyAgin(e)}>再来一单</p>
                                            {/* <p className='a' onClick={(e)=>quxiao(e, pharmacy.servicePhone)}>申请退款</p> */}

                                        </div>
                                    </> : null
                                }
                            </div>
                            : null}
                        {state === 1 ?
                            <div className='order-fuction-contend'>
                                {/* <p>删除</p> */}
                                <p></p>
                                {pharmacy.orderState === '-2' ?
                                    <div>
                                        <p className='a'
                                           onClick={(e) => qxOrder1(e, pharmacy.orderNumber, pharmacy.orderIntegral)}>取消订单</p>
                                        <p className='b'
                                           onClick={() => payFor(pharmacy.orderNumber, pharmacy.orderAmount)}>立即支付</p>
                                    </div> : null}
                            </div>
                            : null}
                        {state === 2 ?
                            <div className='order-fuction-contend'>
                                {/* <p>删除</p> */}
                                <p></p>
                                {pharmacy.orderState === '0' ?
                                    <div>
                                        <p className='a' onClick={(e) => quxiao(e, pharmacy.servicePhone)}>取消订单</p>
                                    </div> : null}
                            </div>
                            : null}
                        {state === 3 ?
                            <div className='order-fuction-contend'>
                                {/* <p>删除</p> */}
                                {/* <p onClick={(e)=>quxiao(e, pharmacy.servicePhone)}>申请退款</p> */}
                                <p></p>
                                {pharmacy.orderState === '2' ?
                                    <div>
                                        <p className='a' onClick={(e) => buyAgin(e)}>再来一单</p>
                                        <p className='b' onClick={(e) => toComment(e, pharmacy.orderNumber)}>评价</p>
                                    </div> : null}
                            </div>
                            : null}
                        {/* {state===4?
                            <div>
                                <p  onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>
                                <div>
                                    <p className='b'>评价</p>
                                </div>
                            </div>
                        :null} */}
                        {/* {state===4?
                        <>
                            <Button name='再次购买' basecolor='#1F87FF'
                            onClick={()=>buyAgin()}/>
                        </>

                        :null}
                        {state===5?
                        <Button name='再次购买' basecolor='#1F87FF'
                        onClick={()=>buyAgin()}/>

                        :null} */}

                        {state === 4 ?
                            <div className='order-fuction-contend'>
                                {/* {pharmacy.orderState==='5'?<Button name='取消申请' onClick={(e)=>cancelRefund(e, pharmacy.orderNumber)}/>:<Button name='删除记录' onClick={(e)=>delOrder(e, pharmacy.orderNumber)}/>}
                            <Button name='查看详情' basecolor='#1F87FF' onClick={()=>onclick(pharmacy.orderNumber)}/>
                            */}
                                <p></p>
                                {pharmacy.orderState === '5' ? <div>
                                    <p className='b' onClick={() => onclick(pharmacy.orderNumber)}>查看详情</p>
                                </div> : null}
                                {pharmacy.orderState === '6' || pharmacy.orderState === '7' ? <div>
                                    <p className='b' onClick={() => onclick(pharmacy.orderNumber)}>查看详情</p>
                                </div> : null}
                                {/* {pharmacy.orderState==='6'? <div>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>退款详情</p>
                            </div>:null} */}
                            </div>
                            : null}
                        {state === 5 ?
                            <div className='order-fuction-contend'>
                                <p></p>
                                {pharmacy.orderState === '5' ? <div>
                                    <p className='b' onClick={() => onclick(pharmacy.orderNumber)}>查看详情</p>
                                </div> : null}
                                {pharmacy.orderState === '6' || pharmacy.orderState === '7' ? <div>
                                    <p className='b' onClick={() => onclick(pharmacy.orderNumber)}>查看详情</p>
                                </div> : null}
                            </div>
                            : null}
                    </div>
                </div>
                {/* <div className='col-line'></div>
                <div className='order-fllow flex-apart-row'>
                    <h1>大丰路北段69号</h1>
                    <span>导航</span>
                </div> */}
            </div>
            {visiable1 == 'block' ? <div className='del-address-outer'>
                <div className='del-address'>
                    <p className='del-sure'>是否确认删除订单？</p>
                    <p className='del-bottom'>
                        <span onClick={() => setVisiable1('none')}>取消</span>
                        <span onClick={() => onDelete()}>确认</span>
                    </p></div>
            </div> : null}
            {visiable2 == 'block' ?
                <div className='del-address-outer'>
                    <div className='del-address'>
                        <p className='del-sure'>是否取消订单？</p>
                        <p className='del-bottom'>
                            <span onClick={() => setVisiable2('none')}>取消</span>
                            <span onClick={() => onQuxiao()}>确认</span>
                        </p>
                    </div>
                </div>
                : null}
            {visiable4 == 'block' ?
                <div className='del-address-outer1'>
                    <div className='del-address1'>
                        <p className='del-sure'>是否取消订单？</p>
                        <p className='del-bottom'>
                            <span onClick={() => setVisiable4('none')}>取消</span>
                            <span onClick={() => onQuxiao()}>确认</span>
                        </p>
                    </div>
                </div> : null}
            {visiable3 == 'block' ? <div className='del-address-outer'>
                <div className='del-address'>
                    <p className='del-sure'>是否确认收货？</p>
                    <p className='del-bottom'>
                        <span onClick={() => setVisiable3('none')}>取消</span>
                        <span onClick={() => onSure()}>确认</span>
                    </p></div>
            </div> : null}

            {visiable7 == 'block' ?
                <div className='del-phone0'>
                    <div className='del-address'>
                        <p className='del-sure'>退款请先联系商家</p>
                        <a href={`tel:${servicePhone}`} onClick={() => setVisiable7('none')}>
                            <div className='contact'>
                                <img className='lianxi' src={lianxi} alt=""/>
                                <span className='lianxi'> 联系商家</span>
                            </div>
                        </a>
                        <p className='del1-bottom' onClick={() => setVisiable7('none')}>X</p>
                    </div>
                </div>
                : null}
        </>
    )
}

export default withRouter(Orderitem)