import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { medcommentRequest } from '../../api/request/comment'
import {ErrorBlock, ImageViewer} from 'antd-mobile-v5'

import './style.less'
import defaultImg from '../../static/img/default.png'
import avatar from '../../static/img/head.png'

function Evaluate(props) {
    const { userId, medId, onclick, medComment } = props
    const [commentList, setComment] = useState(medComment)
    const [index, setIndex] = useState(0)
    const [image, setImage] = useState({
        url: '',
        visible: false
    })
    const evaluateType = (index) => {
        let star = [0]
        setIndex(index)
        if(index === 0) {
            star = [1,2,3,4,5]
        } else if(index === 1) {
            star = [4,5]
        } else if (index === 2) {
            star = [3]
        } else {
            star = [1,2]
        }
        medcommentRequest({medId:medId, userId:userId, star:star}).then(res => {
            if(res.code === 1) {
                let data = res.data
                if(data[0].length > 0) {
                    data[0].forEach(item => {
                        item.evaluatePic=item.evaluatePic?item.evaluatePic.split(','):[]
                    })
                }
                setComment(data)
            }
        })
    }

    const startRating = (num) => {
        let start = []
        for (let i = 0; i < num; i++) {
            start.push(<i key={i} className='iconfont icon-xingxing'></i>)
        }
        return start
    }
console.log(commentList[0]);
    return (
        <div className='evaluate-container'>
            <div className='evaluate-header flex-row'>
                <div onClick={()=>onclick()}>
                    <i className='iconfont icon-xiangzuo'></i>
                </div>
                <div className='evaluate-title'>商品评价</div>
                <div className='evaluate-zhanwei'></div>
            </div>
            <div className='evaluate-sort flexr'>
                <div className='flex-row'>
                    <div className={index===0?'evaluate-active':''}
                    onClick={()=>evaluateType(0)}>全部({commentList[1][0].total>9999?`${(commentList[1][0].total/10000).toFixed(1)}万`:commentList[1][0].total})</div>
                    <div className={index===1?'evaluate-active':''}
                    onClick={()=>evaluateType(1)}>好评({commentList[4][0].great>9999?`${(commentList[4][0].great/10000).toFixed(1)}万`:commentList[4][0].great})</div>
                    <div className={index===2?'evaluate-active':''}
                    onClick={()=>evaluateType(2)}>中评({commentList[3][0].middle>9999?`${(commentList[3][0].middle/10000).toFixed(1)}万`:commentList[3][0].middle})</div>
                    <div className={index===3?'evaluate-active':''}
                    onClick={()=>evaluateType(3)}>差评({commentList[2][0].bad>9999?`${(commentList[2][0].bad/10000).toFixed(1)}万`:commentList[2][0].bad})</div>
                </div>
            </div>
            {commentList[0].length === 0 ? null :
            commentList[0].map((item, index) => (
                <div key={index} className='med-evaluate'>
                    <div className='user-evaluate flex-row'>
                        <div className='user-avatar flex-row'>
                            <div>
                                <img src={item.userAvatar?item.userAvatar:avatar} alt=''
                                onError={(e) => {e.target.onerror = null;e.target.src=avatar}}/>
                            </div>
                            <div className='user-name flex-col'>
                                <div>{item.userName?item.userName.slice(0,1)+"**":
                                item.userAcount&&(item.userAcount.slice(0,3)+'****'+item.userAcount.slice(7,12))}</div>
                                <div className='star-rating'>
                                    {startRating(item.evaluateStar)}
                                </div>
                            </div>
                        </div>
                        <div className='evaluate-time'>
                            <div>{item.createTime}</div>
                        </div>
                    </div>
                    <div className='evaluate-text'>
                        <div>{item.evaluateText}</div>
                        {item.evaluatePic.length === 0 ? null :
                            <div>
                                <div className='evaluate-img flex-row'>
                                {item.evaluatePic.map((item1, index1) => (
                                    <div key={index1}>
                                        <img src={item1} alt=''
                                             onClick={() => {
                                                 setImage({
                                                     url: item1,
                                                     visible: true
                                                 })
                                             }}
                                        onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                    </div>
                                ))}
                                </div>
                                {/* <div className='evaluate-img flex-row'>
                                {item.evaluatePic.map((item1, index1) => (
                                    index1 > 2 ? 
                                    <div key={index1}>
                                        <img src={item1} alt=''
                                        onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                    </div> : null
                                ))}
                                </div> */}
                            </div>
                        }
                    </div>
                    {item.evaluateReply?<div className='shangjia-huifu'>
                        <p className='shangjia-huifu-top'>
                            <span>商家回复</span>
                            <span>{item.replyTime}</span>
                        </p>
                        <div className='shangjia-text'>
                            <div>{item.evaluateReply}</div>
                        </div>
                    </div>:null}
                </div>
            ))}
            <ImageViewer
                image={image.url}
                visible={image.visible}
                onClose={() => {
                    setImage({
                        url: '',
                        visible: false
                    })
                }}
            />
        </div>
    )
}

export default withRouter(Evaluate)