import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import {oderdetailRequest,applyPartRequest,upOrderStuRequest,applyRefundRequest } from '../../../../api/request/order'
import { addAftersaleMed,addAftersale,updateAftersale,aftersaleMedList } from '../../../../api/request/aftersale'
import { uploadImg } from '../../../../api/request/upload'
import { zipImg } from '../../../../component/zipImage/index'
import fanhui from '../../../../static/img/fanhuihei.png'
import finish from '../../../../static/img/finish.png'

import './style.less'
const RadioItem = Radio.RadioItem
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const reasons = [ '厂家召回', '出库单问题','没收到货','拍错/多拍/不想要','实物与商品描述不符（批号/包裹）','药品近效期','商品质量问题','发货少货','商品压损','商家发错货', '其它原因']
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [orderDetail, setOrderDetail] = useState(null)
    const [medPhoto, setmedPhoto] = useState('')
    const [medName, setmedName] = useState('')
    const [medPrice, setmedPrice] = useState()
    const [medQuantity, setmedQuantity] = useState()
    const [medQuantity1, setmedQuantity1] = useState()
    const [medCompany, setmedCompany] = useState('')
    const [visible, setVisiable] = useState('none')
    const [radioVal, setRadioVal] = useState('')
    const [reasonContent, setReasonContent] = useState()
    const [orderMedId, setorderMedId] = useState()
    const [miaosu, setMiaosu] = useState('')
    const [orderState, setorderState] = useState('')
    const [countrefundQuantity, setcountrefundQuantity] = useState(0)
    const [countQuantity1, setcountQuantity1] = useState()
    const [countQuantity, setcountQuantity] = useState()

    const [pharmacyId, setpharmacyId] = useState()
    const [medSpec, setmedSpec] = useState()
    const [medApproval, setmedApproval] = useState()
    const [medPacking, setmedPacking] = useState()

    const cartArr=props.location.state
    let orderNumber;  //先定义一个存储参数的变量
    let medId;
    let aftersaleId;
    if(cartArr){  //判断是否有参数
        orderNumber=cartArr[0].pharmacy.orderNumber;
        medId=cartArr[0].pharmacy.medId;
        localStorage.setItem('orderNumber1', orderNumber);
        localStorage.setItem('medId1', medId);
        if (cartArr[0].pharmacy.aftersaleId) {
            aftersaleId=cartArr[0].pharmacy.aftersaleId
            localStorage.setItem('aftersaleId1',aftersaleId);
        }
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber1');
        medId = localStorage.getItem('medId1');
        aftersaleId = localStorage.getItem('aftersaleId1');
    }
    // console.log(cartArr);
    console.log(aftersaleId);

    useEffect(() => {
        oderdetailRequest(orderNumber).then(res=>{
            console.log(res);
            // setOrderDetail(res.data[0][1])
            let a;
            let count=0
            let count1=0
            if (res.code==1) {
                setpharmacyId(res.data[0][0].pharmacyId)
                setorderState(res.data[0][0].orderState)
                res.data[1].map(item=>{
                    count+=item.medQuantity
                    count1+=item.medRefundQuantity
                    if (item.medId==medId) {
                        a=item
                    }
                })
                setOrderDetail(a)
                setmedPhoto(a.medPhoto)
                setmedName(a.medName)
                setmedPrice(a.medPrice)
                setmedQuantity(a.medQuantity)
                setmedQuantity1(a.medQuantity)
                setmedCompany(a.medCompany)
                setorderMedId(a.orderMedId)
                setcountQuantity(count)
                setcountQuantity1(count1)
                setmedSpec(a.medSpec)
                setmedApproval(a.medApproval)
                setmedPacking(a.medPacking)
            }
        })
        aftersaleMedList({aftersaleId}).then(res=>{
            console.log(res);
            if (res.data[0].length>0) {
                setcountrefundQuantity(res.data[0][0].med_refund_quantity)
            }
        })
    }, [])
    const fanhui1=()=>{
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic1
        if (type === 'add') {
            let file = files[files.length - 1].file
            console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    console.log(res1);
                    console.log(files);
                    if (res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
    const jian=()=>{
        setmedQuantity(medQuantity-1)
        if (medQuantity<=1) {
            setmedQuantity(1)
        }
    }
    const jia=()=>{
        setmedQuantity(medQuantity+1)
        if (medQuantity>=medQuantity1) {
            setmedQuantity(medQuantity1)
        }
    }
// console.log(medQuantity);
    const smRreasons=()=>{
        setVisiable('block')
    }
    const dianji=(e)=>{

    }
    console.log(reasonContent);
    const sureReason=()=>{
        if (reasonContent&&reasonContent != '其它原因') {
            setVisiable('none')
        }else if(radioVal=='其它原因'&&!reasonContent ){
            Toast.info('请输入退款原因')
        }else{
            Toast.info('请选择退款原因')
        }
    }
    const tijiao=()=>{
        let time=new Date()
        let time1=time.getFullYear()+'-'+(time.getMonth()+1)+'-'+time.getDate()+' '+time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()
        let params={
            orderNumber:orderNumber,
            aftersaleReason:reasonContent,
            aftersaleCertificate:evaluatePic1.toString(),
            applyAftersale_time:time1,
            userId:userId,
            pharmacyId:pharmacyId,
            aftersaleDestination:0,
            aftersaleType:'商品类型'
        }
        
        
        if (reasonContent) {
            if (miaosu) {
                addAftersale(params).then(res=>{
                    console.log(res);
                    let params1={
                        aftersaleId:res.data[1][0].aftersaleId,
                        orderNumber:orderNumber,
                        medId:medId,
                        medName:medName,
                        medPhoto:medPhoto,
                        medSpec:medSpec,
                        medApproval:medApproval,
                        medPacking:medPacking?medPacking:1,
                        medCompany:medCompany,
                        medPrice:medPrice,
                        medQuantity:medQuantity1,
                        medRefundQuantity:medQuantity
                    }
                    let params2={
                        aftersaleState:'1',
                        aftersaleRefuse:'',
                        aftersaleDetails:miaosu,
                        aftersaleId:res.data[1][0].aftersaleId
                    }
                    console.log(params1);
                    if (res.code==1) {
                        addAftersaleMed(params1).then(res2=>{
                            console.log(res2);
                            if (res2.code==1) {
                                updateAftersale(params2).then(res1=>{
                                console.log(res1);
                                props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
                                })
                            }
                        })
                    }
                })
            }else{
                Toast.info('具体原因未填写')
            }
        }else{
            Toast.info('请选择申请退款原因')
        }
    }
    const fuzhi=(v)=>{
        setMiaosu(v.target.value)
    }
    return (
        <div>
            <div className='refund-top'>
                <div className='pifa-refund'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>申请售后</p>   
                </div>
                <div className='refund-detail'>
                    <p className='refund-detail-outer'>
                        <img src={medPhoto} alt="" />
                        <p className='refund-detail-right'>
                            <h2>{medName}</h2>
                            <p className='company-name'>{medCompany}</p>
                            <p className='refund-detail-inner-right'>
                                <span className='refund-detail-l'>￥{medPrice/100}</span>
                                <span className='refund-detail-r'>x{medQuantity1}</span>
                            </p>
                        </p>
                    </p>
                </div>
            </div>
            <div className='refund-count'>
                <p className='refund-count-title'>
                    <span>申请数量</span>
                    <span className='refund-count-title-r'>（请选择您要申请售后的产品数量）</span>
                </p>
                <p className='refund-count-number'>
                    <span className='jian' onClick={()=>jian()}>-</span>
                    <span className='number'>{medQuantity}</span>
                    <span className='jia' onClick={()=>jia()}>+</span>
                </p>
            </div>
            <div className='selsect-reason'>
                <span>{reasonContent?reasonContent:'请选择申请售后原因'}</span>
                <span className='gt' onClick={()=>smRreasons()}>{'>'}</span>
            </div>
            <div className='reason-pic'>
                <p className='shuoming'>请给商家说明具体原因</p>
                <div className='upvoucher-in' onClick={(e) => dianji(e)}>
                    <textarea className='miaoshu' placeholder='请描述具体售后要求' maxlength="100" value={miaosu} name='textarea' cols={28} rows={5} onChange={(v)=>fuzhi(v)}></textarea>
                    <span style={{position:'absolute',left:'320px',marginTop:'60px'}}>
                        <span>{miaosu.length}</span>
                        <span>/</span>
                        <span>100</span>
                    </span>
                    <ImagePicker
                        files={files1}
                        length={4}
                        onChange={imgChange1}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files1.length < 4}
                        accept='image/gif,image/jpeg,image/jpg,image/png'
                    />
                    <p style={{marginTop:'-8px'}}>(最多上传4张图片)</p>
                </div>
            </div>
            <div className='tijiao' onClick={()=>tijiao()}>
                <img src={finish} alt="" />
                <span>提交申请</span>
            </div>
            {visible=='block'?
                <div className='select-all-reason'>
                    <List className='popup-list'>
                        <div className='select'>请选择申请售后原因</div>
                        {reasons.map((item, index) => (
                            <RadioItem key={index}
                                checked={radioVal === item}
                                onChange={() => {
                                    setRadioVal(item)
                                    if (item === '其它原因') {
                                        setReasonContent('')
                                    } else {
                                        setReasonContent(item)
                                    }
                                }}>
                                {item}
                            </RadioItem>
                        ))}
                        {radioVal === '其它原因' ?
                            <TextareaItem
                                placeholder='请输入售后原因'
                                rows={1}
                                count={100}
                                onChange={(val) => setReasonContent(val)} /> : null
                        }
                    <div className='sure' onClick={()=>sureReason()}>确定</div>
                    </List>
                </div>
            :null}
        </div>
    )
}