import React, { useState, useEffect,useRef } from 'react'
import { Tabs } from 'antd-mobile'
import { Popup, Space, Toast } from 'antd-mobile-v5'
import cls from 'classnames'

import { storeDetailsRequest, storeClassifyRequest, storeMedPage,smallClassify } from '../../api/request/store'
import Nodata from '../../component/Nodata'

import { meddetailRequest } from "../../api/request/med"

import { cartlistRequest,addcartRequest,addcartidentical,delCartRequest } from "../../api/request/cart"
import { fllowRequest,deletecollectRequest,delPharamacy } from '../../api/request/fllow'
import Shopinfo from './shopinfo'
import RecommmendMed from '../../component/Goods/rmd-med'
import { getonemedAll } from "../../api/request/med"
import './style.less'
import { wxConfig } from '../../component/scan/index'
import IMG from '../../static/img/store/backimg.png'
import defaultImg from '../../static/img/default.png'
import zhezaho from "../../static/img/zhezaho.jpg";
import getUrl from '../../component/GetUrlParam'
export default function Shop(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const pharmacyId = props.match.params.pharmacyId
    const pharmacyId2 = localStorage.type == "1" ? 1 : localStorage.type == "2" ? 1 : 3
    const type = localStorage.type
    const [load, setLoad] = useState(true)
    const [page, setPage] = useState(getUrl('page', props.location.search)?getUrl('page', props.location.search):1)
    const [size] = useState(20)
    const [details, setDetails] = useState(null)
    const [medList, setMedList] = useState([])
    const [isFollow, setIsFollow] = useState(false)
    const [footindex, setfootindex] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [category, setCategory] = useState([])
    const [classify, setClassify] = useState([])
    const [showClassify, setShowClassify] = useState([])
    const [selectMenuId, setSelectMenuId] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const [cartnum, setCartnum] = useState(null)
    const [cartlist, setcartlist] = useState(null)
    const [medNum, setmedNum] = useState(0)
    const [startTime, setstartTime] = useState(0)//营业时间
    const [endTime, setendTime] = useState(0)//打样时间
    const [promoteStu, setpromoteStu] = useState("0")//是否营业 0营业1打烊
    const [weeks, setWeeks] = useState()
    const [index0, setindex0] = useState(0)
    const [index1, setindex1] = useState(0)
    const [categoryId1, setCategoryId1] = useState(getUrl('categoryId1', props.location.search))
    const [scrollTop, setscrollTop] = useState(0)
    const scrollTop1 = getUrl('scrollTop', props.location.search)
    const page1 = getUrl('page', props.location.search)
    const delivery = getUrl('delivery', props.location.search)
    const morestore = getUrl('morestore', props.location.search)
    const mine = getUrl('mine', props.location.search)


    //const tabs = [{ title: '精选' }, { title: '商品' }, { title: '活动' }, { title: '上新' }, { title: '动态' }]
    const tabs = [{ title: '商品' }, { title: '商家' }]

    let nowtime00=new Date()
    let timecha=nowtime00.getHours()*60+nowtime00.getMinutes()
    const pageView=useRef(null)

    useEffect(() => {
        var tempDate = new Date();
        var days = tempDate.getDay();
        var week;
        switch (days) {
            case 1:
                week = '周一';
                break;
            case 2:
                week = '周二';
                break;
            case 3:
                week = '周三';
                break;
            case 4:
                week = '周四';
                break;
            case 5:
                week = '周五';
                break;
            case 6:
                week = '周六';
                break;
            case 0:
                week = '周日';
                break;
        }
        setWeeks(week)//今天周几
        // console.log(scrollTop1,page1);
        storeDetailsRequest(pharmacyId, userId).then(res => {
            // console.log(res);
            if (res.code === 1 && res.data[0].length ) {
                const data = res.data
                let data0=[]
                let discount=[]
                setstartTime(parseInt(res.data[0][0].pharmacyStartTime.substring(0,2)*60)+parseInt(res.data[0][0].pharmacyStartTime.substring(3,5)))
                setendTime(parseInt(res.data[0][0].pharmacyEndTime.substring(0,2)*60)+parseInt(res.data[0][0].pharmacyEndTime.substring(3,5)))
                setpromoteStu(res.data[0][0].promoteStu)
                setDetails(data[0][0])
                setIsFollow(data[1].length > 0)
                if (scrollTop1&&page1) {
                    // console.log(scrollTop1,page1);
                    const params = {
                        pharmacyId: pharmacyId,
                        page: 1,
                        size: page1*20,
                        tab1:1
                    }
                    storeMedPage(params).then(res => {
                        // console.log(res);
                        if (res.code === 1) {
                            let data = res.data
                            let data0=[]
                            let discount=[]
                            if (res.data[0]&&res.data[0].length) {
                                res.data[0][0].activitiesCycle=JSON.parse(res.data[0][0].activitiesCycle)
                                res.data[0][0].activitiesDiscount=JSON.parse(res.data[0][0].activitiesDiscount)
                                if (res.data[0][0].activitiesType==2) {
                                    data0=res.data[1]
                                    discount=res.data[2]
                                }else{
                                    if (res.data[1].length&&res.data[1][0].activeMedId) {
                                        data0=res.data[2]
                                        discount=res.data[3]
                                    }else{
                                        data0=res.data[1]
                                        discount=res.data[2]
                                    }
                                }
                            }else{
                                data0=res.data[1]
                                discount=res.data[2]
                            }
                            if (data0.length > 0) {
                                data0.map(item=>{
                                    item.state=false
                                    item.activitiesCycle=''
                                    item.activitiesDiscount=''
                                    item.activitiesId=''
                                    item.activitiesType=''
                                    item.discountPrice0=''
                                    if (res.data[0].length) {
                                        res.data[0][0].activitiesCycle.map(i=>{
                                            if (i==week) {
                                                item.state=true
                                            }
                                        })
                                        if (res.data[0][0].activitiesType==2) {
                                            if (item.state) {
                                                item.activitiesCycle=res.data[0][0].activitiesCycle
                                                item.activitiesDiscount=res.data[0][0].activitiesDiscount
                                                item.activitiesId=res.data[0][0].activitiesId
                                                item.activitiesType=res.data[0][0].activitiesType
                                            }
                                        }
                                        if (res.data[0][0].activitiesType==1) {
                                            if (res.data[1].length&&res.data[1][0].activeMedId) {
                                                res.data[1].map(v=>{
                                                    console.log(v);
                                                    if (item.medQrcode==v.medQrcode) {
                                                        if (item.state) {
                                                            item.activitiesCycle=res.data[0][0].activitiesCycle
                                                            item.activitiesDiscount=res.data[0][0].activitiesDiscount
                                                            item.activitiesStartTime=res.data[0][0].activitiesStartTime
                                                            item.activitiesEndTime=res.data[0][0].activitiesEndTime
                                                            item.activitiesId=res.data[0][0].activitiesId
                                                            item.activitiesType=res.data[0][0].activitiesType
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    if (discount && discount.length ) {
                                        discount.map(i=>{
                                            if (i.medQrcode==item.medQrcode) {
                                                i.discountCycle = JSON.parse(i.discountCycle)
                                                let state = false
                                                let price=0
                                                i.discountCycle.map(v => {
                                                    if (v == weeks) {
                                                        state = true
                                                    }
                                                })
                                                if (state) {
                                                    if (i.percentage==-1) {
                                                        price=i.discountPrice
                                                    }else{
                                                        price=i.originPrice*i.percentage/10
                                                    }
                                                    item.discountPrice0=price  //折扣商品价格
                                                }else{
                                                    // setDiscount([])
                                                }

                                            }
                                        })
                                    }
                                })
                            }
                            if (data0.length > 0) {
                                console.log([...medList, ...data0]);
                                setMedList([...medList, ...data0])
                                setPage(Math.ceil([...medList, ...data0].length/20))
                                if (data0.length < size) {
                                    setLoad(false)
                                }
                            } else {
                                if (data0.length < size) {
                                    setLoad(false)
                                }
                            }
                            pageView.current.scrollTop=scrollTop1
                        }
                    })
                }else{
                    if (res.data[2].length) {
                        res.data[2][0].activitiesCycle=JSON.parse(res.data[2][0].activitiesCycle)
                        res.data[2][0].activitiesDiscount=JSON.parse(res.data[2][0].activitiesDiscount)
                        if (res.data[2][0].activitiesType==2) {
                            data0=res.data[3]
                            discount=res.data[4]
                        }else{
                            if (res.data[3].length&&res.data[3][0].activeMedId) {
                                data0=res.data[4]
                                discount=res.data[5]
                            }else{
                                data0=res.data[3]
                                discount=res.data[4]
                            }
                        }
                    }else{
                        data0=res.data[3]
                        discount=res.data[4]
                    }
                    console.log(data0);
                    if (data0.length > 0) {
                        data0.map(item=>{
                            item.state=false
                            item.activitiesCycle=''
                            item.activitiesDiscount=''
                            item.activitiesId=''
                            item.activitiesType=''
                            item.discountPrice0=''
                            if (res.data[2].length) {
                                res.data[2][0].activitiesCycle.map(i=>{
                                    if (i==week) {
                                        item.state=true
                                    }
                                })
                                if (res.data[2][0].activitiesType==2) {
                                    if (item.state) {
                                        item.activitiesCycle=res.data[2][0].activitiesCycle
                                        item.activitiesDiscount=res.data[2][0].activitiesDiscount
                                        item.activitiesId=res.data[2][0].activitiesId
                                        item.activitiesType=res.data[2][0].activitiesType
                                    }
                                }
                                if (res.data[2][0].activitiesType==1) {
                                    if (res.data[3].length&&res.data[3][0].activeMedId) {
                                        res.data[3].map(v=>{
                                            console.log(v);
                                            if (item.medQrcode==v.medQrcode) {
                                                if (item.state) {
                                                    item.activitiesCycle=res.data[2][0].activitiesCycle
                                                    item.activitiesDiscount=res.data[2][0].activitiesDiscount
                                                    item.activitiesStartTime=res.data[2][0].activitiesStartTime
                                                    item.activitiesEndTime=res.data[2][0].activitiesEndTime
                                                    item.activitiesId=res.data[2][0].activitiesId
                                                    item.activitiesType=res.data[2][0].activitiesType
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                            if (discount && discount.length ) {
                                discount.map(i=>{
                                    if (i.medQrcode==item.medQrcode) {
                                        i.discountCycle = JSON.parse(i.discountCycle)
                                        let state = false
                                        let price=0
                                        i.discountCycle.map(v => {
                                            if (v == week) {
                                                state = true
                                            }
                                        })
                                        if (state) {
                                            if (i.percentage==-1) {
                                                price=i.discountPrice
                                            }else{
                                                price=i.originPrice*i.percentage/10
                                            }
                                            item.discountPrice0=price  //折扣商品价格
                                        }else{
                                            // setDiscount([])
                                        }

                                    }
                                })
                            }
                        })
                    }
                    console.log(data0);
                    setMedList(data0 && data0.length > 0 ? data0 : [])
                    document.title = data[0][0].pharmacyName
                }
            }
        })
    }, [pharmacyId, userId])
    // const scrollToHandler=() => {
    //     console.log(123);
    //     pageView.current.scrollTop=1300
    // }
    // console.log(page);
    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    //获取购物车信息
    useEffect(() => {
        getcartnum()
        getallnum()
        // scrollToHandler()
        // window.scrollBy(0,1300)
    }, [])
    //获取商家所有商品数
    const getallnum = async () => {
        const res = await getonemedAll({
            pharmacyId: pharmacyId
        })
        // console.log(res);
        setmedNum(res.data[0][0].num)
    }
    const getcartnum = async () => {
        const pharmacyId = pharmacyId2
        const res = await cartlistRequest(userId, pharmacyId)
        setCartnum(res.data[0].length)
        setcartlist(res.data[0])
        // console.log(res)
    }
    const onscroll = e => {
        setscrollTop(e.target.scrollTop)
       // console.log( e.target.scrollHeight , e.target.scrollTop , e.target.clientHeight);
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            pageMedlist()
        }
    }

    const pageMedlist = () => {
        if (load) {
            const params = {
                pharmacyId: pharmacyId,
                page: page + 1,
                size: size,
                tab1:1
            }
            storeMedPage(params).then(res => {
                console.log(res);
                if (res.code === 1) {
                    let data = res.data
                    let data0=[]
                    let discount=[]
                    if (res.data[0]&&res.data[0].length) {
                        res.data[0][0].activitiesCycle=JSON.parse(res.data[0][0].activitiesCycle)
                        res.data[0][0].activitiesDiscount=JSON.parse(res.data[0][0].activitiesDiscount)
                        if (res.data[0][0].activitiesType==2) {
                            data0=res.data[1]
                            discount=res.data[2]
                        }else{
                            if (res.data[1].length&&res.data[1][0].activeMedId) {
                                data0=res.data[2]
                                discount=res.data[3]
                            }else{
                                data0=res.data[1]
                                discount=res.data[2]
                            }
                        }
                    }else{
                        data0=res.data[1]
                        discount=res.data[2]
                    }
                    if (data0.length > 0) {
                        data0.map(item=>{
                            item.state=false
                            item.activitiesCycle=''
                            item.activitiesDiscount=''
                            item.activitiesId=''
                            item.activitiesType=''
                            item.discountPrice0=''
                            if (res.data[0].length) {
                                res.data[0][0].activitiesCycle.map(i=>{
                                    if (i==weeks) {
                                        item.state=true
                                    }
                                })
                                if (res.data[0][0].activitiesType==2) {
                                    if (item.state) {
                                    item.activitiesCycle=res.data[0][0].activitiesCycle
                                    item.activitiesDiscount=res.data[0][0].activitiesDiscount
                                    item.activitiesId=res.data[0][0].activitiesId
                                    item.activitiesType=res.data[0][0].activitiesType
                                    }
                                }
                                if (res.data[0][0].activitiesType==1) {
                                    if (res.data[1].length&&res.data[1][0].activeMedId) {
                                        res.data[1].map(v=>{
                                            console.log(v);
                                            if (item.medQrcode==v.medQrcode) {
                                                if (item.state) {
                                                    item.activitiesCycle=res.data[0][0].activitiesCycle
                                                    item.activitiesDiscount=res.data[0][0].activitiesDiscount
                                                    item.activitiesStartTime=res.data[0][0].activitiesStartTime
                                                    item.activitiesEndTime=res.data[0][0].activitiesEndTime
                                                    item.activitiesId=res.data[0][0].activitiesId
                                                    item.activitiesType=res.data[0][0].activitiesType
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                            if (discount && discount.length ) {
                                discount.map(i=>{
                                    if (i.medQrcode==item.medQrcode) {
                                        i.discountCycle = JSON.parse(i.discountCycle)
                                        let state = false
                                        let price=0
                                        i.discountCycle.map(v => {
                                            if (v == weeks) {
                                                state = true
                                            }
                                        })
                                        if (state) {
                                            if (i.percentage==-1) {
                                                price=i.discountPrice
                                            }else{
                                                price=i.originPrice*i.percentage/10
                                            }
                                            item.discountPrice0=price  //折扣商品价格
                                        }else{
                                            // setDiscount([])
                                        }
                                        
                                    }
                                })
                            }
                        })
                    }
                    if (data0.length > 0) {
                        console.log([...medList, ...data0]);
                        setMedList([...medList, ...data0])
                        setPage(Math.ceil([...medList, ...data0].length/20))
                        if (data0.length < size) {
                            setLoad(false)
                        }
                    } else {
                        if (data0.length < size) {
                            setLoad(false)
                        }
                    }
                }
            })
            setRefresh(true)
        }
    }
    const renderContent = (tab, index) => {
        console.log(index,medList);
        if (index==0){
            setindex0(0)
        }else{
            setindex0(1)
        }
        // return (
        //     <>
        //         {index == 0 ? <div className='shop-contain-item' onScroll={onscroll} ref={pageView}>
        //                 {medList.length ? <RecommmendMed recommand={medList} getcartnum={getcartnum} timecha={timecha}
        //                                                  startTime={startTime} endTime={endTime} promoteStu={promoteStu}
        //                                                  scrollTop={scrollTop} page={page} shop={1} pharmacyId={pharmacyId}
        //                                                  morestore={morestore}/> :
        //                     <Nodata type='店铺'/>}
        //             </div> :
        //             index == 1 ? <div className='shop-contain-item'>
        //                     <Shopinfo pharmacyId={pharmacyId}></Shopinfo>
        //                 </div>
        //                 : null}
        //     </>
        // )
    }
    const renderContent1 = (tab, index) => {
        console.log(tab);
        if (index==0){
            setindex1(0)
        }else{
            setindex1(1)
        }
        // return (
        //     <>
        //         {index == 0 ? <div className='shop-classify-body'>
        //                 <div className='menu'>
        //                     <div className='menu-scroll'>
        //                         {
        //                             category.map((v, i) => (
        //                                 <div key={i}
        //                                      className={menuModalItemStyle(v.categoryId, category, selectMenuId)}
        //                                      onClick={() => menuClick(v)}>
        //                                     <span>{v.categoryName}</span>
        //                                 </div>
        //                             ))
        //                         }
        //                     </div>
        //                 </div>
        //                 <div className='detail'>
        //                     <div>
        //                         {
        //                             showClassify.length?showClassify.map(v => (
        //                                 <div
        //                                     key={v.classifyId}
        //                                     onClick={() => subItemClick(v)}
        //                                     className='ill-item1 '
        //                                 >
        //                                     <div className='img'>
        //                                         <img src={v.med_photo?v.med_photo:defaultImg} alt='' />
        //                                     </div>
        //                                     {v.med_classify=='1'?<span className='cf-medicition'>Rx</span>:null}
        //                                     <div className='ill-item-details'>
        //                                         <h3 className='title'>{v.med_name}</h3>
        //                                         <p className='ill-item-yueshou'>{v.med_spec}</p>
        //                                         {v.state?
        //                                             <p className='ill-all-full'>
        //                                                 {v.activitiesDiscount.length?v.activitiesDiscount.map(i=>(
        //                                                     <span className='ill-item-full'>满{i.full/100}减{i.minus/100}</span>)):null}
        //                                             </p>
        //                                             : null}
        //                                         <p className='ill-item-money'>
        //                                             <span className='mow-price'>￥{(v.med_price/100).toFixed(2)}</span>
        //                                             {v.origin_price>v.med_price?<span className='origin-price'>￥{(v.origin_price/100).toFixed(2)}</span>:null}
        //                                             {v.cartnum>0?<span className='shop-all'>
        //                                                 <span className='shop-jian' onClick={(e)=>addShopping(e,1,v,v.cartnum,v.stock_num)}>-</span>
        //                                                 <span className='shop-count'>{v.cartnum}</span>
        //                                                 <span className='shop-jia' onClick={(e)=>addShopping(e,2,v,v.cartnum,v.stock_num)}>+</span>
        //                                             </span>:
        //                                                 <p className='shop-all1'>
        //                                                     <p className='shop-jia' onClick={(e)=>addShopping(e,2,v,v.cartnum,v.stock_num)}>+</p>
        //                                                 </p>}
        //                                         </p>
        //                                     </div>
        //                                 </div>
        //                             )):<Nodata type='店铺'/>
        //                         }
        //                     </div>
        //                 </div>
        //             </div> :
        //             index == 1 ? <div className='shop-contain-item'>
        //                     <Shopinfo pharmacyId={pharmacyId}></Shopinfo>
        //                 </div>
        //                 : null}
        //     </>
        // )
    }
    const backPage = () => {
        if (delivery) {
            props.history.push(`/orderDelivery?mine=${mine}`)
        }else if(morestore==1){
            props.history.push(`/morestore`)
        }else{
            props.history.push(`/`)
        }
    }

    const follow = () => {
        console.log(isFollow);
        if (userId){
            const params = {
                type: 2,
                userId: userId,
                pharmacyId: pharmacyId
            }
            const params1 = {
                userId: userId,
                pharmacyId: pharmacyId
            }
            if (isFollow && userId) {
                delPharamacy(params1).then(res => {
                    if (res.code === 1) {
                        setIsFollow(false)
                        storeDetailsRequest(pharmacyId, userId).then(res2 => {
                            console.log(res2);
                            if (res2.code === 1 && res2.data[0].length ) {
                                setDetails(res2.data[0][0])
                            }
                        })
                    }
                })
                setRefresh(true)
            }
            if (!isFollow && userId) {
                fllowRequest(params).then(res => {
                    if (res.code === 1) {
                        setIsFollow(true)
                        storeDetailsRequest(pharmacyId, userId).then(res2 => {
                            console.log(res2);
                            if (res2.code === 1 && res2.data[0].length ) {
                                setDetails(res2.data[0][0])
                            }
                        })
                    }
                })
                setRefresh(true)
            }
        }else{
            props.history.push(`/login/login1`)
        }
    }

    const storefootnav = (index) => {
        console.log(index);
        setfootindex(index)
        switch (index) {
            case 0:
                setindex1(0)
                storeDetailsRequest(pharmacyId, userId).then(res => {
                    console.log(res);
                    if (res.code === 1 && res.data[0].length ) {
                        const data = res.data
                        let data0=[]
                        let discount=[]
                        setstartTime(parseInt(res.data[0][0].pharmacyStartTime.substring(0,2)*60)+parseInt(res.data[0][0].pharmacyStartTime.substring(3,5)))
                        setendTime(parseInt(res.data[0][0].pharmacyEndTime.substring(0,2)*60)+parseInt(res.data[0][0].pharmacyEndTime.substring(3,5)))
                        setpromoteStu(res.data[0][0].promoteStu)
                        setDetails(data[0][0])
                        setIsFollow(data[1].length > 0)
                        if (res.data[2].length) {
                            res.data[2][0].activitiesCycle=JSON.parse(res.data[2][0].activitiesCycle)
                            res.data[2][0].activitiesDiscount=JSON.parse(res.data[2][0].activitiesDiscount)
                            if (res.data[2][0].activitiesType==2) {
                                data0=res.data[3]
                                discount=res.data[4]
                            }else{
                                if (res.data[3].length&&res.data[3][0].activeMedId) {
                                    data0=res.data[4]
                                    discount=res.data[5]
                                }else{
                                    data0=res.data[3]
                                    discount=res.data[4]
                                }
                            }
                        }else{
                            data0=res.data[3]
                            discount=res.data[4]
                        }
                        console.log(data0);
                        if (data0.length > 0) {
                            data0.map(item=>{
                                item.state=false
                                item.activitiesCycle=''
                                item.activitiesDiscount=''
                                item.activitiesId=''
                                item.activitiesType=''
                                item.discountPrice0=''
                                if (res.data[2].length) {
                                    res.data[2][0].activitiesCycle.map(i=>{
                                        if (i==weeks) {
                                            item.state=true
                                        }
                                    })
                                    if (res.data[2][0].activitiesType==2) {
                                        if (item.state) {
                                        item.activitiesCycle=res.data[2][0].activitiesCycle
                                        item.activitiesDiscount=res.data[2][0].activitiesDiscount
                                        item.activitiesId=res.data[2][0].activitiesId
                                        item.activitiesType=res.data[2][0].activitiesType
                                        }
                                    }
                                    if (res.data[2][0].activitiesType==1) {
                                        if (res.data[3].length&&res.data[3][0].activeMedId) {
                                            res.data[3].map(v=>{
                                                console.log(v);
                                                if (item.medQrcode==v.medQrcode) {
                                                    if (item.state) {
                                                        item.activitiesCycle=res.data[2][0].activitiesCycle
                                                        item.activitiesDiscount=res.data[2][0].activitiesDiscount
                                                        item.activitiesStartTime=res.data[2][0].activitiesStartTime
                                                        item.activitiesEndTime=res.data[2][0].activitiesEndTime
                                                        item.activitiesId=res.data[2][0].activitiesId
                                                        item.activitiesType=res.data[2][0].activitiesType
                                                    }
                                                }
                                            })
                                        }
                                    }
                                }
                                if (discount && discount.length ) {
                                    discount.map(i=>{
                                        if (i.medQrcode==item.medQrcode) {
                                            i.discountCycle = JSON.parse(i.discountCycle)
                                            let state = false
                                            let price=0
                                            i.discountCycle.map(v => {
                                                if (v == weeks) {
                                                    state = true
                                                }
                                            })
                                            if (state) {
                                                if (i.percentage==-1) {
                                                    price=i.discountPrice
                                                }else{
                                                    price=i.originPrice*i.percentage/10
                                                }
                                                item.discountPrice0=price  //折扣商品价格
                                            }else{
                                                // setDiscount([])
                                            }

                                        }
                                    })
                                }
                            })
                        }
                        console.log(data0);
                        setMedList(data0 && data0.length > 0 ? data0 : [])
                        document.title = data[0][0].pharmacyName
                    }
                })
                break;
            case 1:
                props.history.push("/shopcart")
                break;
            case 2:
                setindex0(0)
                storeClassifyRequest(pharmacyId).then(res => {
                    console.log(weeks);
                    if (res.code === 1 && res.data[0].length > 0) {
                        console.log(res);
                        const data = res.data[0]
                        let newcategory = []
                        let newclassify = []
                        let classifyArr = []
                        smallClassify(categoryId1?categoryId1:data[0].classifyId).then(res1=>{
                            console.log(res1);
                            if (res1.data[1]&&res1.data[1].length) {
                                res1.data[1][0].activitiesCycle=JSON.parse(res1.data[1][0].activitiesCycle)
                                res1.data[1][0].activitiesDiscount=JSON.parse(res1.data[1][0].activitiesDiscount)
                            }
                            if (res1.data[0].length) {
                                res1.data[0].map(item=>{
                                    item.state=false
                                    item.cartnum=0
                                    item.activitiesCycle=[]
                                    item.activitiesDiscount=[]
                                    item.activitiesStartTime=''
                                    item.activitiesEndTime=''
                                    item.activitiesId=''
                                    item.activitiesType=''
                                    if (res1.data[1].length) {
                                        res1.data[1][0].activitiesCycle.map(v=>{
                                            if (weeks==v) {
                                                item.state=true
                                            }
                                        })
                                        if (res1.data[1][0].activitiesType==2) {
                                            res1.data[1][0].activitiesCycle.map(i=>{
                                                if (i==weeks) {
                                                    item.state=true
                                                }
                                            })
                                            if (item.state) {
                                                item.activitiesCycle=res1.data[1][0].activitiesCycle
                                                item.activitiesDiscount=res1.data[1][0].activitiesDiscount
                                                item.activitiesStartTime=res1.data[1][0].activitiesStartTime
                                                item.activitiesEndTime=res1.data[1][0].activitiesEndTime
                                                item.activitiesId=res1.data[1][0].activitiesId
                                                item.activitiesType=res1.data[1][0].activitiesType
                                            }
                                        }
                                        if (res1.data[1][0].activitiesType==1) {
                                            if (res1.data[2].length) {
                                                res1.data[2].map(v=>{
                                                    console.log(v);
                                                    if (item.med_qrcode==v.med_qrcode) {
                                                        res1.data[1][0].activitiesCycle.map(i=>{
                                                            if (i==weeks) {
                                                                item.state=true
                                                            }
                                                        })
                                                        if (item.state) {
                                                            item.activitiesCycle=res1.data[1][0].activitiesCycle
                                                            item.activitiesDiscount=res1.data[1][0].activitiesDiscount
                                                            item.activitiesStartTime=res1.data[1][0].activitiesStartTime
                                                            item.activitiesEndTime=res1.data[1][0].activitiesEndTime
                                                            item.activitiesId=res1.data[1][0].activitiesId
                                                            item.activitiesType=res1.data[1][0].activitiesType
                                                        }
                                                    }
                                                })
                                            }

                                        }
                                    }
                                    if (cartlist.length) {
                                        cartlist.map(item0=>{
                                            if (item.med_id==item0.medId) {
                                                item.cartnum=item0.medQuantity
                                            }
                                        })
                                    }
                                    classifyArr.push({
                                        categoryId: item.categoryId,
                                        ...item
                                    })
                                })
                                setShowClassify(classifyArr)
                                console.log(classifyArr);
                            }else{
                                setShowClassify('')
                            }
                            // console.log(res1.data[0]);
                        })
                        data.forEach((item, index) => {
                            if (item.parentCode === '0') {
                                newcategory.push({
                                    categoryId: item.classifyId,
                                    categoryCode: item.classifyCode,
                                    categoryName: item.classifyName
                                })
                            } else {
                                item.cartnum=0
                                if (cartlist.length) {
                                    cartlist.map(item0=>{
                                        if (item.med_id==item0.medId) {
                                            item.cartnum=item0.medQuantity
                                        }
                                    })
                                }
                                newclassify.push({
                                    categoryId: item.categoryId,
                                    ...item
                                })
                                // if (item.parentCode === data[0].classifyCode) {
                                    // }
                                }
                            })
                        console.log(classifyArr);
                        setCategory(newcategory)
                        setClassify(newclassify)
                        setSelectMenuId(data[0].classifyId)
                        // setShowClassify(classifyArr)
                    }
                })
                break;

            default:
                break;
        }
    }

    const menuClick = (v) => {
        console.log(v);
        setCategoryId1(v.categoryId)
        let classifyArr = []
        smallClassify(v.categoryId).then(res=>{
            console.log(res);
            if (res.data[1]&&res.data[1].length) {
                res.data[1][0].activitiesCycle=JSON.parse(res.data[1][0].activitiesCycle)
                res.data[1][0].activitiesDiscount=JSON.parse(res.data[1][0].activitiesDiscount)
            }
            if (res.data[0].length!=0) {
                res.data[0].map(item=>{
                    item.state=false
                    item.cartnum=0
                    item.activitiesCycle=[]
                    item.activitiesDiscount=[]
                    item.activitiesStartTime=''
                    item.activitiesEndTime=''
                    item.activitiesId=''
                    item.activitiesType=''
                    if (res.data[1].length) {
                        res.data[1][0].activitiesCycle.map(v=>{
                            if (weeks==v) {
                                item.state=true
                            }
                        })
                        if (res.data[1][0].activitiesType==2) {
                            if (item.state) {
                                item.activitiesCycle=res.data[1][0].activitiesCycle
                                item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                item.activitiesId=res.data[1][0].activitiesId
                                item.activitiesType=res.data[1][0].activitiesType
                            }
                        }
                        if (res.data[1][0].activitiesType==1) {
                            if (res.data[2].length) {
                                res.data[2].map(v=>{
                                    console.log(v);
                                    if (item.med_qrcode==v.med_qrcode) {
                                        if (item.state) {
                                            item.activitiesCycle=res.data[1][0].activitiesCycle
                                            item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                            item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                            item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                            item.activitiesId=res.data[1][0].activitiesId
                                            item.activitiesType=res.data[1][0].activitiesType
                                        }
                                    }
                                })
                            }
                        }
                    }
            // console.log(cartlist);
            // if (res.data[0].length!=0) {
                // res.data[0].map(item=>{
                //     item.cartnum=0
                    if (cartlist.length) {
                        cartlist.map(item0=>{
                            if (item.med_id==item0.medId) {
                                item.cartnum=item0.medQuantity
                            }
                        })
                    }
                    classifyArr.push({
                        categoryId: v.categoryId,
                        ...item
                    })
                })
                setShowClassify(classifyArr)
                console.log(classifyArr);
            }else{
                setShowClassify('')
            }
        })
        // classify.forEach((item, index) => {
        //     if (item.parentCode === v.categoryCode) {
        //         classifyArr.push({
        //             categoryId: v.categoryId,
        //             ...item
        //         })
        //     }
        // })
        setSelectMenuId(v.categoryId)
    }

    const menuModalItemStyle = (categoryId, compareArr, compareId) => {
        // console.log(selectMenuId,categoryId, compareId)
        if (selectMenuId === null) {
            setSelectMenuId(compareArr.categoryId)
            // setSelectMenuId(categoryId1)
            const cid = categoryId1?categoryId:compareArr[0].categoryId
            return cls('item', cid == categoryId ? 'active' : null)
        }
        return cls('item', categoryId == compareId ? 'active' : null)
    }

    const subItemClick = (v) => {
        // props.history.push(`/medlist/${selectMenuId}/${v.classifyId}/${v.classifyName}?pharmacyId=${pharmacyId}`)
        props.history.replace(`/shop/${pharmacyId}`)
        props.history.push(`/med/${v.med_id}`)
    }

    const shopSearch = (val) => {
        setSearchVal(val)
    }

    const toSearch = () => {
        props.history.push(`/medlist/0/0/${searchVal}?shopId=${pharmacyId}`)
    }
    //跳转到店铺详情
    const toshopinfo = () => {
        // props.history.push({
        //     pathname: "/shopinfo",
        //     params: {
        //         pharmacyId
        //     }
        // })
    }
    // 扫码
    const cameraScan = () => {
        wxConfig()
    }
    // console.log(category);

    // 点击加入购物车
    const addShopping=(e,count,v,cartnum,stockNum)=>{
        // cartnum:购物车数量,stockNum ：库存
        e.stopPropagation()
        console.log(v,cartnum,stockNum);
        if (userId) {
            if (count==1) {
                if (cartnum>1) {
                     // 加数量
                     addcartidentical({
                        userId: userId,
                        pharmacyId: v.pharmacy_id,
                        medId: v.med_id,
                        medQuantity: -1
                    }).then(res=>{
                        console.log(res);
                        if (res.code==1) {
                            // 查询购物车
                            cartlistRequest(userId, pharmacyId).then(res0=>{
                                if (res0.code==1) {
                                    setCartnum(res0.data[0].length)
                                    setcartlist(res0.data[0])
                                    let classifyArr = []
                                    // 商品小分类
                                    smallClassify(v.pharmacy_medicine_id).then(res=>{
                                        console.log(res);
                                        if (res.data[1].length) {
                                            res.data[1][0].activitiesCycle=JSON.parse(res.data[1][0].activitiesCycle)
                                            res.data[1][0].activitiesDiscount=JSON.parse(res.data[1][0].activitiesDiscount)
                                        }
                                        if (res.data[0].length!=0) {
                                            res.data[0].map(item=>{
                                                item.state=false
                                                item.cartnum=0
                                                item.activitiesCycle=[]
                                                item.activitiesDiscount=[]
                                                item.activitiesStartTime=''
                                                item.activitiesEndTime=''
                                                item.activitiesId=''
                                                item.activitiesType=''
                                                if (res.data[1].length) {
                                                    res.data[1][0].activitiesCycle.map(v=>{
                                                        if (weeks==v) {
                                                            item.state=true
                                                        }
                                                    })
                                                    if (res.data[1][0].activitiesType==2) {
                                                        if (item.state) {
                                                            item.activitiesCycle=res.data[1][0].activitiesCycle
                                                            item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                            item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                            item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                            item.activitiesId=res.data[1][0].activitiesId
                                                            item.activitiesType=res.data[1][0].activitiesType
                                                        }
                                                    }
                                                    if (res.data[1][0].activitiesType==1) {
                                                        if (res.data[2].length) {
                                                            res.data[2].map(v=>{
                                                                console.log(v);
                                                                if (item.med_qrcode==v.med_qrcode) {
                                                                    if (item.state) {
                                                                        item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                        item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                        item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                        item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                        item.activitiesId=res.data[1][0].activitiesId
                                                                        item.activitiesType=res.data[1][0].activitiesType
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                }
                                                if (res0.data[0].length) {
                                                    res0.data[0].map(item0=>{
                                                        if (item.med_id==item0.medId) {
                                                            item.cartnum=item0.medQuantity
                                                        }
                                                    })
                                                }
                                                classifyArr.push({
                                                    categoryId: v.pharmacy_medicine_id,
                                                    ...item
                                                })
                                            })
                                            setShowClassify(classifyArr)
                                            console.log(classifyArr);
                                        }else{
                                            setShowClassify('')
                                        }
                                    })
                                }
                            })
                        }
                    })
                }else{
                    cartlist.map(item2=>{
                        if (v.med_id==item2.medId) {
                            delCartRequest({cartId:item2.cartId}).then(res2=>{
                                console.log(res2);
                                if (res2.code==1) {
                                    // 查询购物车
                                    cartlistRequest(userId, pharmacyId).then(res0=>{
                                        if (res0.code==1) {
                                            setCartnum(res0.data[0].length)
                                            setcartlist(res0.data[0])
                                            let classifyArr = []
                                            // 商品小分类
                                            smallClassify(v.pharmacy_medicine_id).then(res=>{
                                                console.log(res);
                                                if (res.data[1].length) {
                                                    res.data[1][0].activitiesCycle=JSON.parse(res.data[1][0].activitiesCycle)
                                                    res.data[1][0].activitiesDiscount=JSON.parse(res.data[1][0].activitiesDiscount)
                                                }
                                                if (res.data[0].length!=0) {
                                                    res.data[0].map(item=>{
                                                        item.state=false
                                                        item.cartnum=0
                                                        item.activitiesCycle=[]
                                                        item.activitiesDiscount=[]
                                                        item.activitiesStartTime=''
                                                        item.activitiesEndTime=''
                                                        item.activitiesId=''
                                                        item.activitiesType=''
                                                        if (res.data[1].length) {
                                                            res.data[1][0].activitiesCycle.map(v=>{
                                                                if (weeks==v) {
                                                                    item.state=true
                                                                }
                                                            })
                                                            if (res.data[1][0].activitiesType==2) {
                                                                if (item.state) {
                                                                    item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                    item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                    item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                    item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                    item.activitiesId=res.data[1][0].activitiesId
                                                                    item.activitiesType=res.data[1][0].activitiesType
                                                                }
                                                            }
                                                            if (res.data[1][0].activitiesType==1) {
                                                                if (res.data[2].length) {
                                                                    res.data[2].map(v=>{
                                                                        console.log(v);
                                                                        if (item.med_qrcode==v.med_qrcode) {
                                                                            if (item.state) {
                                                                                item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                                item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                                item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                                item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                                item.activitiesId=res.data[1][0].activitiesId
                                                                                item.activitiesType=res.data[1][0].activitiesType
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        }
                                                        if (res0.data[0].length) {
                                                            res0.data[0].map(item0=>{
                                                                if (item.med_id==item0.medId) {
                                                                    item.cartnum=item0.medQuantity
                                                                }
                                                            })
                                                        }
                                                        classifyArr.push({
                                                            categoryId: v.pharmacy_medicine_id,
                                                            ...item
                                                        })
                                                    })
                                                    setShowClassify(classifyArr)
                                                    console.log(classifyArr);
                                                }else{
                                                    setShowClassify('')
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            }else{
                if (cartnum==0) {
                    if (stockNum>0) {
                        // 加数量
                        addcartRequest({
                            userId: userId,
                            pharmacyId: v.pharmacy_id,
                            medId: v.med_id,
                            medQuantity: 1
                        }).then(res=>{
                            console.log(res);
                            if (res.code==1) {
                                // 查询购物车
                                cartlistRequest(userId, pharmacyId).then(res0=>{
                                    if (res0.code==1) {
                                        setCartnum(res0.data[0].length)
                                        setcartlist(res0.data[0])
                                        let classifyArr = []
                                        // 商品小分类
                                        smallClassify(v.pharmacy_medicine_id).then(res=>{
                                            console.log(res);
                                            if (res.data[1].length) {
                                                res.data[1][0].activitiesCycle=JSON.parse(res.data[1][0].activitiesCycle)
                                                res.data[1][0].activitiesDiscount=JSON.parse(res.data[1][0].activitiesDiscount)
                                            }
                                            if (res.data[0].length!=0) {
                                                res.data[0].map(item=>{
                                                    item.state=false
                                                    item.cartnum=0
                                                    item.activitiesCycle=[]
                                                    item.activitiesDiscount=[]
                                                    item.activitiesStartTime=''
                                                    item.activitiesEndTime=''
                                                    item.activitiesId=''
                                                    item.activitiesType=''
                                                    if (res.data[1].length) {
                                                        res.data[1][0].activitiesCycle.map(v=>{
                                                            if (weeks==v) {
                                                                item.state=true
                                                            }
                                                        })
                                                        if (res.data[1][0].activitiesType==2) {
                                                            if (item.state) {
                                                                item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                item.activitiesId=res.data[1][0].activitiesId
                                                                item.activitiesType=res.data[1][0].activitiesType
                                                            }
                                                        }
                                                        if (res.data[1][0].activitiesType==1) {
                                                            if (res.data[2].length) {
                                                                res.data[2].map(v=>{
                                                                    console.log(v);
                                                                    if (item.med_qrcode==v.med_qrcode) {
                                                                        if (item.state) {
                                                                            item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                            item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                            item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                            item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                            item.activitiesId=res.data[1][0].activitiesId
                                                                            item.activitiesType=res.data[1][0].activitiesType
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    }
                                                    if (res0.data[0].length) {
                                                        res0.data[0].map(item0=>{
                                                            if (item.med_id==item0.medId) {
                                                                item.cartnum=item0.medQuantity
                                                            }
                                                        })
                                                    }
                                                    classifyArr.push({
                                                        categoryId: v.pharmacy_medicine_id,
                                                        ...item
                                                    })
                                                })
                                                setShowClassify(classifyArr)
                                                console.log(classifyArr);
                                            }else{
                                                setShowClassify('')
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }else{
                        Toast.show({
                            content: '库存为0，不能进行加购',
                            type: "success",
                            duration: 1000
                        })
                    }
                }else if (cartnum<stockNum) {
                    addcartidentical({
                        userId: userId,
                        pharmacyId: v.pharmacy_id,
                        medId: v.med_id,
                        medQuantity: 1
                    }).then(res=>{
                        console.log(res);
                        if (res.code==1) {
                            // 查询购物车
                            cartlistRequest(userId, pharmacyId).then(res0=>{
                                if (res0.code==1) {
                                    setCartnum(res0.data[0].length)
                                    setcartlist(res0.data[0])
                                    let classifyArr = []
                                        // 商品小分类
                                    smallClassify(v.pharmacy_medicine_id).then(res=>{
                                        console.log(res);
                                        if (res.data[1].length) {
                                            res.data[1][0].activitiesCycle=JSON.parse(res.data[1][0].activitiesCycle)
                                            res.data[1][0].activitiesDiscount=JSON.parse(res.data[1][0].activitiesDiscount)
                                        }
                                        if (res.data[0].length!=0) {
                                            res.data[0].map(item=>{
                                                item.state=false
                                                item.cartnum=0
                                                item.activitiesCycle=[]
                                                item.activitiesDiscount=[]
                                                item.activitiesStartTime=''
                                                item.activitiesEndTime=''
                                                item.activitiesId=''
                                                item.activitiesType=''
                                                if (res.data[1].length) {
                                                    res.data[1][0].activitiesCycle.map(v=>{
                                                        if (weeks==v) {
                                                            item.state=true
                                                        }
                                                    })
                                                    if (res.data[1][0].activitiesType==2) {
                                                        if (item.state) {
                                                            item.activitiesCycle=res.data[1][0].activitiesCycle
                                                            item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                            item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                            item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                            item.activitiesId=res.data[1][0].activitiesId
                                                            item.activitiesType=res.data[1][0].activitiesType
                                                        }
                                                    }
                                                    if (res.data[1][0].activitiesType==1) {
                                                        if (res.data[2].length) {
                                                            res.data[2].map(v=>{
                                                                if (item.med_qrcode==v.med_qrcode) {
                                                                    if (item.state) {
                                                                        item.activitiesCycle=res.data[1][0].activitiesCycle
                                                                        item.activitiesDiscount=res.data[1][0].activitiesDiscount
                                                                        item.activitiesStartTime=res.data[1][0].activitiesStartTime
                                                                        item.activitiesEndTime=res.data[1][0].activitiesEndTime
                                                                        item.activitiesId=res.data[1][0].activitiesId
                                                                        item.activitiesType=res.data[1][0].activitiesType
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                }
                                                if (res0.data[0].length) {
                                                    res0.data[0].map(item0=>{
                                                        if (item.med_id==item0.medId) {
                                                            item.cartnum=item0.medQuantity
                                                        }
                                                    })
                                                }
                                                classifyArr.push({
                                                    categoryId: v.pharmacy_medicine_id,
                                                    ...item
                                                })
                                            })
                                            setShowClassify(classifyArr)
                                            console.log(classifyArr);
                                        }else{
                                            setShowClassify('')
                                        }
                                    })
                                }
                            })
                        }
                    })
                }else{
                    Toast.show({
                        content: '库存不足，不能继续加购',
                        type: "success",
                        duration: 1000
                    })
                }
            }
        } else {
            Toast.show({
                content: '请先登录',
                type: "success",
                duration: 1000
            })
            //Toast.fail("请先登录")
            props.history.push("/login/login1")
        }
    }
    // console.log(category)
    return (
        <div>
            <div className='shop-backimg' style={{ backgroundImage: `url(${IMG})` }}></div>
            <div className='backimg-mask' />
            <div className='shop-content'>
                <div className='shop-header'>
                    <div className='shop-header-zhanwei flex-row' onClick={backPage}>
                        <i className='iconfont icon-xiangzuo'></i>
                    </div>
                    <div className='shop-search flexr'
                        style={{ border: '1px solid #414141' }}>
                        <i className='iconfont icon-suosou'></i>
                        <input type="text" placeholder='搜索商品'
                            onChange={({ target: { value } }) => shopSearch(value)} />
                        {searchVal ? <div className='shop-search-btn' onClick={toSearch}>搜索</div> : null}
                    </div>
                    <div style={{position:'absolute',zIndex:'10000',color:'#fff',right:'16px'}} onClick={cameraScan}>
                        <i className='iconfont-l icon-saoyisao' style={{fontSize:'24px'}}></i>
                    </div>
                    <div className='shop-header-zhanwei'></div>
                </div>
                {details === null ? null :
                    <div className='shop-introduce'>
                        <div className='shop-brank flexr'>
                            <div className='shop-logo flexr'>
                                <img src={details.pharmacyPhoto} alt='' onClick={toshopinfo} />
                            </div>
                            <div className='shop-title flexc'>
                                <p>{details.pharmacyName}</p>
                                {type == "2" ? <span>{details.followNum > 9999 ? `${(details.followNum / 10000).toFixed(1)}万` : details.followNum}人关注   共 <span style={{ color: "#FF5555" }}>({medNum})</span> 种商品</span> :
                                    <span>
                                        <span>{details.followNum > 9999 ? `${(details.followNum / 10000).toFixed(1)}万` : details.followNum}人关注</span>
                                        {timecha>startTime&&timecha<endTime&&promoteStu=="0"?null:<span style={{marginLeft:'20px',border:'1px solid #aaa',color:'#aaa'}}>店铺已打烊</span>}
                                    </span>
                                }

                            </div>
                            <div className='shop-fllow flex-row'
                                onClick={follow}
                                style={{ backgroundColor: type == "2" ? "#FF5555" : "#00B7F5" }}
                            >{isFollow ? '已关注' : '关注'}</div>
                        </div>
                    </div>
                }
            </div>
            <div className='shop-tab-contain'>
                {footindex === 0 ?
                    // <Tabs
                    //     tabs={tabs}
                    //     tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '62px' }}
                    //     tabBarActiveTextColor="#FFFFFF"
                    //     tabBarInactiveTextColor="#FFFFFF"
                    //     tabBarBackgroundColor='none'
                    //     tabBarUnderlineStyle={{ border: '2px #FFFFFF solid', transform: 'scaleX(0.4)', bottom: '16px' }}
                    //     renderTabBar={props => <Tabs.DefaultTabBar {...props} page={2} />}
                    //     onTouchMove={(e)=>handleTouchMove(e)}
                    //     onTouchStart={(e)=>handleTouchStart(e)}
                    //     onTouchEnd={(e)=>handleTouchEnd(e)}
                    //         gesturesEnabled='false'
                    //     >
                    //     {renderContent}
                    // </Tabs>
                    <>
                        <div className='tabs-title'>
                            {tabs.map((item,index)=>(
                                <p className='tabs-out' onClick={()=>renderContent(item,index)}>
                                    <span className='title' style={{ fontSize: '16px', padding: '0', height: '62px' }}>{item.title}</span>
                                    <p className={index==index0?'cline':'cline1'}></p>
                                </p>))}
                        </div>
                        {index0 == 0 ? <div className='shop-contain-item' onScroll={onscroll} ref={pageView}>
                                {medList.length ? <RecommmendMed recommand={medList} getcartnum={getcartnum} timecha={timecha}
                                                                 startTime={startTime} endTime={endTime} promoteStu={promoteStu}
                                                                 scrollTop={scrollTop} page={page} shop={1} pharmacyId={pharmacyId}
                                                                 morestore={morestore}/> :
                                    <Nodata type='店铺'/>}
                            </div> :
                        index0 == 1 ? <div className='shop-contain-item'>
                                <Shopinfo pharmacyId={pharmacyId}></Shopinfo>
                            </div>
                            : null}
                    </>
                    : null}
                {footindex === 1 ?
                    <>
                        <div className='shop-sort flex-apart-row'>
                            <div className='shop-sort-item shopSortActive'>推荐</div>
                            <div className='shop-sort-item'>销量</div>
                            <div className='shop-sort-item'>价格</div>
                        </div>
                        <div className='shop-contain-item'></div>
                    </>
                    : null}
                {footindex === 2 ?
                    <>
                        <div className='tabs-title'>
                            {tabs.map((item,index)=>(
                                <p className='tabs-out' onClick={()=>renderContent1(item,index)}>
                                    <span className='title' style={{ fontSize: '16px', padding: '0', height: '62px' }}>{item.title}</span>
                                    <p className={index==index1?'cline':'cline1'}></p>
                                </p>))}
                        </div>
                        {index1 == 0 ? <div className='shop-classify-body'>
                                <div className='menu'>
                                    <div className='menu-scroll'>
                                        {
                                            category.map((v, i) => (
                                                <div key={i}
                                                     className={menuModalItemStyle(v.categoryId, category, selectMenuId)}
                                                     onClick={() => menuClick(v)}>
                                                    <span>{v.categoryName}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='detail'>
                                    <div>
                                        {
                                            showClassify.length?showClassify.map(v => (
                                                <div
                                                    key={v.classifyId}
                                                    onClick={() => subItemClick(v)}
                                                    className='ill-item1 '
                                                >
                                                    <div className='img'>
                                                    {/* src={v.medClassify === "1" ? zhezaho : (v.medPhoto ? v.medPhoto : defaultImg)} */}
                                                        <img src={v.med_classify === "1" ? zhezaho :(v.med_photo?v.med_photo:defaultImg)} alt='' />
                                                    </div>
                                                    {v.med_classify=='1'?<span className='cf-medicition'>Rx</span>:null}
                                                    <div className='ill-item-details'>
                                                        <h3 className='title'>{v.med_name}</h3>
                                                        <p className='ill-item-yueshou'>{v.med_spec}</p>
                                                        {v.state?
                                                            <p className='ill-all-full'>
                                                                {v.activitiesDiscount.length?v.activitiesDiscount.map(i=>(
                                                                    <span className='ill-item-full'>满{i.full/100}减{i.minus/100}</span>)):null}
                                                            </p>
                                                            : null}
                                                        <p className='ill-item-money'>
                                                            <span className='mow-price'>￥{(v.med_price/100).toFixed(2)}</span>
                                                            {v.origin_price>v.med_price?<span className='origin-price'>￥{(v.origin_price/100).toFixed(2)}</span>:null}
                                                            {v.cartnum>0?<span className='shop-all'>
                                                        <span className='shop-jian' onClick={(e)=>addShopping(e,1,v,v.cartnum,v.stock_num)}>-</span>
                                                        <span className='shop-count'>{v.cartnum}</span>
                                                        <span className='shop-jia' onClick={(e)=>addShopping(e,2,v,v.cartnum,v.stock_num)}>+</span>
                                                    </span>:
                                                                <p className='shop-all1'>
                                                                    <p className='shop-jia' onClick={(e)=>addShopping(e,2,v,v.cartnum,v.stock_num)}>+</p>
                                                                </p>}
                                                        </p>
                                                    </div>
                                                </div>
                                            )):<Nodata type='店铺'/>
                                        }
                                    </div>
                                </div>
                            </div> :
                        index1 == 1 ? <div className='shop-contain-item'>
                                <Shopinfo pharmacyId={pharmacyId}></Shopinfo>
                            </div>
                            : null}
                    </>
                    : <Nodata type='店铺'/>

                }
            </div>

            <div className='shop-footer'>
                <ul className="clear-fix">
                    <li>
                        <div onClick={() => storefootnav(0)} className={footindex === 0 ? 'shopFootActive' : ''}>
                            <i className="iconfont icon-shouye"></i>
                            首页
                        </div>
                    </li>

                    <li>
                        <div onClick={() => storefootnav(2)} className={footindex === 2 ? 'shopFootActive' : ''}>
                            <i className="iconfont icon-fenlei"></i>
                            分类
                        </div>
                    </li>
                    <li>
                        <div onClick={() => storefootnav(1)} className={footindex === 1 ? 'shopFootActive' : ''}>
                            <p className="p-move">{cartnum}</p>
                            <i className="iconfont icon-tubiaozhizuomoban"></i>
                            购物车
                        </div>
                    </li>
                    <li>
                        {/* <div onClick={()=>setfootindex(3)} className={footindex===3?'shopFootActive':''}>
                            <i className="iconfont icon-tubiao210"></i>
                            联系客服
                        </div> */}
                        {details === null ? null :
                            <a className='shop-telphone' href={`tel:${details.servicePhone}`}>
                                <i className="iconfont icon-tubiao210"></i>
                                联系客服
                            </a>
                        }
                    </li>
                </ul>
            </div>
        </div>

    )
}