import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Card, Toast, Button, Mask, InputItem } from 'antd-mobile'
import {
    savePhone,
    saveWx,
    logincodeRequest,
    verifyRequest,
    onlyAccountRequest,
    changeUser,
    getUser,
    getJMURL,
    wxGetuserid
} from "../../api/request/user"
import { getUserSH } from "../../api/request/user"
import Platform from './index_platform'
import Pf from "./pf"
import Single from './index_single'
import icon1 from "../../static/img/home/icon1.png"
import icon2 from "../../static/img/home/icon2.png"
import icon3 from "../../static/img/home/icon3.png"
import icon4 from "../../static/img/home/icon4.png"
import FootNav from '../../component/FootNav'
// 获取url参数的函数
import getUrl from '../../component/GetUrlParam'

import './style.less'
import { storageUser } from './userstorage'
import {wxConfig} from "../../component/scan";
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            env: '0',
            wxstate: null,
            phone: null,
            value: "",
            newvalue: "",
            hasError: false,
            hasError2: false,
            code: 0,
            time: 60,
            divstate: false,
            img: "",
            userName: "",
            userOpenid: "",
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            pharmacyId: null,
            sunthis:null
        }
    }
    // 获取微信登录状态
    componentWillMount=async()=> {
        console.log(window.location.href);
        // if(window.location.search){
        //     const arr = window.location.href.split("&")
        //     console.log(arr)
        //     let url = arr[0].substring(55, arr[0].length)
        //     // let url = arr[0].substring(48, arr[0].length)
        //     console.log(url);
        //     const res=await getJMURL({
        //         url
        //     })
        //     console.log(res)
        //     this.setState({
        //         list:res.data,
        //         l:arr
        //     },()=>{
        //         const wximg=res.data.headimgurl
        //         const wxuserName=res.data.nickname
        //         const wxuserOpenid=res.data.openid
        //         //console.log(wximg,wxuserName,wxuserOpenid)
        //         localStorage.setItem("wxuserName",wxuserName)
        //         localStorage.setItem("wximg",wximg)
        //         localStorage.setItem("wxuserOpenid",wxuserOpenid)
        //         wxGetuserid(res.data.openid).then(res1=>{
        //             console.log(res1)
        //             if (res1.data[0].length){
        //                 localStorage.setItem("userId1",res1.data[0][0].userId)
        //             }
        //         })
        //     })
        //
        //     if(!localStorage.getItem("type")){
        //         localStorage.setItem('type',1)
        //     }
        // }
        console.log(this.state.divstate);
        //京东获取token测试
        //window.location.href="https://oauth.jdl.cn/oauth/authorize?client_id=3126199b82c544f68e348463b04da301&redirect_uri=https%3A%2F%2Fpf-h5.sczyzk.com%2Findex.html%2523%2F%3Fenv%3Dsingle%26pharmacyId%3D25&response_type=code"
        // if (this.state.userId){
            this.setState({
                wxstate: window.localStorage.getItem("wxlogin") ? true : false,
                img: window.localStorage.getItem("wximg"),
                userName: window.localStorage.getItem("wxuserName"),
                userOpenid: window.localStorage.getItem("wxuserOpenid"),
                pharmacyId: window.localStorage.getItem("pharmacyId")
            }, async() => {
                //批发端判断用资质审核是否通过
                if (this.state.pharmacyId == "25"&&this.state.userId) {
                    const res = await getUserSH({
                        userId: this.state.userId
                    })
                    console.log(res)
                    if (res.data[0].length == 0) {
                        Toast.fail("未经资质审核")
                        this.props.history.push("/qualifications")
                    } else if (res.data[0][0].entrust_state != 1 || res.data[0][0].card_state != 1 || res.data[0][0].license_state != 1) {
                        Toast.fail("资质审核未通过")
                        this.props.history.push("/await")
                    }
                }
            })
        // }
    }
    // 微信授权登录后做判断、不同端口进来做判断
    componentDidMount = async () => {

        //Toast.success(window.localStorage.getItem("wxlogin"))
        //console.log(this.props)
        const urlHash = window.location.hash
        const typearr = urlHash.split("=")
        const str = urlHash.substr(urlHash.indexOf('?'))
        const env = getUrl('env', str)
        const lng = getUrl('lng', str)
        const lat = getUrl('lat', str)
        if(env === 'app') {
            wxConfig('appPosition', {}, {lng:lng, lat:lat})
        } else {
            wxConfig('position')
        }
        console.log(urlHash)
        // 判断用户类型
        if (typearr[2]) {
            if (typearr[2] == 2) {
                localStorage.setItem("type", 3)
                localStorage.setItem("pharmacyId", 2)
                //console.log(localStorage.getItem("pharmacyId"))
            } else if (typearr[2] == 25) {
                localStorage.setItem("type", 2)
                localStorage.setItem("pharmacyId", 25)
            }
        } else {
            localStorage.setItem("type", 1)
            localStorage.setItem("pharmacyId", 0)
        }
        if (urlHash.indexOf('?') !== -1) {
            const str = urlHash.substr(urlHash.indexOf('?'))
            const env = getUrl('env', str)
            const pharmacyId = getUrl('pharmacyId', str)
            localStorage.setItem('env', env || 0)
            localStorage.setItem('pharmacyId', pharmacyId || 0)
            this.setState({
                env: env
            })
        } else {
            localStorage.setItem('env', 0)
            localStorage.setItem('pharmacyId', 0)
            localStorage.setItem('singleTob', 0)
            localStorage.setItem('singleTitle', '')
            localStorage.setItem('singleSubtitlea', '')
            document.title = '众康云药购'
        }
        // const res=await savePhone({
        //     userOpenid:"13458396457"
        // })
        // Toast.success(res.code)
        // 获取用户信息
        //Toast.success(this.state.wxstate.toString())
        if (window.localStorage.getItem("wxuserOpenid")) {
            //console.log(window.location.href)
            //Toast.fail("失败")
            // const arr = window.location.href.split("&")
            // console.log(arr)
            // let url = arr[0].substring(39, arr[0].length)
            // const res=await getJMURL({
            //     url
            // })
            // user = user.split(",")
            // user = user.map(item => {
            //     return item.split(":")
            // })
            // const img = user[7][2].substring(0, user[7][2].length - 1).replace(/\\/g, "")
            // const userName = user[1][1].substring(1, user[1][1].length - 1)
            // const userOpenid = user[0][1].substring(1, user[0][1].length - 1)
            // const img=res.data.headimgurl
            // const userName=res.data.nickname
            // const userOpenid=res.data.openid
            // this.setState({
            //     img,
            //     userName,
            //     userOpenid
            // })
            const { img, userName, userOpenid } = this.state
            console.log(userOpenid,window.localStorage.getItem("wxuserOpenid"));
            // 微信登录查询绑定手机号
            const res2 = await savePhone({
                userOpenid:window.localStorage.getItem("wxuserOpenid"),
                pharmacyId: localStorage.getItem("pharmacyId")
            })
            console.log(res2);
            console.log(res2.data[0].length)
            if (res2.data[0].length) {
                console.log(res2)
                // console.log(res2.data[0].userAcount)
                // 通过手机号获取用户信息
                const res3 = await getUser({
                    userAcount: res2.data[0][0].userAcount,
                    pharmacyId: localStorage.getItem("pharmacyId")
                })
                console.log(res3)
                // if (res3.data[0].length&&res3.data[0][0].userStatus==2) {
                    const code = localStorage.getItem("type")
                    storageUser(res3, 1)
                // }else{
                //     Toast.fail("该账号已冻结，无法继续登录")
                // }
                // const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
                // localStorage.setItem("userAcount", res3.data[0][0].useracount)
                // localStorage.setItem("userAvatar", `${userAvatar}`)
                // localStorage.setItem("userVip", res3.data[0][0].uservip)
                // localStorage.setItem("userName", res3.data[0][0].username)
                // localStorage.setItem("userId", res3.data[0][0].userid)
                // localStorage.removeItem("wxlogin")
            } else {
                this.setState({
                    divstate: true
                })
            }

            // if (!localStorage.userAcount) {
            //     localStorage.setItem("userAcount", user[1][1].substring(1, user[1][1].length - 1))
            // }
            // localStorage.setItem("userAvatar", `${img}`)
            // localStorage.setItem("wxId", user[0][1])
            // localStorage.setItem("userName", user[1][1].substring(1, user[1][1].length - 1))
            // localStorage.setItem("userVip", 0)
            // // localStorage.setItem("wx", 1)
            // localStorage.setItem("wxlogin", false)
        }
    }
    onHeaderClick = () => {
        Toast.show('点击了卡片Header区域')
    }

    onBodyClick = () => {
        Toast.show('点击了卡片Body区域')
    }
    // 点击跳转到手机验证码登录
    gologin = () => {
        console.log(111)
        localStorage.setItem("wx", 2)
        this.props.history.push("/login")
    }
    // 错误反馈
    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info("请输入正确的手机号");
        }
    }
    onErrorClick2 = () => {
        if (this.state.hasError2) {
            Toast.info("验证码输入错误");
        }
    }
    //   判断输入的格式
    onChange = (phone) => {
        if (!(/^1[3456789]\d{9}$/.test(phone))) {
            this.setState({
                hasError: true,
            });
        } else {
            this.setState({
                hasError: false,
            });
        }
        this.setState({
            phone,
        });
    }
    onChange2 = (newvalue) => {
        this.setState({
            newvalue,
        })
    }
    //   判断验证码
    //   blur = () => {
    //     console.log(this.state.newvalue)
    //   }
    // 获取验证码
    getnum = () => {
        logincodeRequest({ pharmacyId: localStorage.getItem("pharmacyId"), userPhone: this.state.phone }).then(res => {
            if (res.code == 1) {
                Toast.success("获取成功");
                this.setState({
                    code: 1
                }, () => {
                    const timer = setInterval(() => {
                        this.setState({
                            time: this.state.time - 1
                        }, () => {
                            if (this.state.time == 0) {
                                this.setState({
                                    time: 60
                                }, () => {
                                    clearInterval(timer)
                                    this.setState({
                                        code: 3
                                    })
                                })
                            }
                        })
                    }, 1000);
                })
            }
        })
    }
    // 提交
    toNext = async () => {
        const pharmacyId = localStorage.getItem("pharmacyId")?localStorage.getItem("pharmacyId"):0
        // 短信验证码验证
        const res = await verifyRequest(this.state.phone, this.state.newvalue, pharmacyId)
        console.log(res)
        if (res.data[0].length) {
            // 判断手机号是否已注册 //验证账号唯一
            const res1 = await onlyAccountRequest(this.state.phone, pharmacyId)
            console.log(res1)
            if (res1.data[0].length) {
                // 已注册直接向后台数据加字段 // 通过手机号更新信息
                changeUser({
                    userName: this.state.userName,
                    userOpenid: this.state.userOpenid,
                    userAvatar: this.state.img,
                    userAcount: this.state.phone,
                    pharmacyId
                }).then(res2=>{
                    console.log(res2)
                    // 绑定成功之后存数据
                    if (res2.data[0].length) {
                        Toast.success("绑定成功")
                        this.setState({
                            divstate: false
                        })
                        // 通过手机号获取用户信息
                        getUser({
                            userAcount: this.state.phone,
                            pharmacyId
                        }).then(res3=>{
                            console.log(res3)
                            // if (res3.data[0].length&&res3.data[0][0].userStatus==2) {
                                const code = localStorage.getItem("type")
                                storageUser(res3, 1)
                            // }else{
                            //     Toast.fail("该账号已冻结，无法继续登录")
                            // }
                        })
                        // const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
                        // localStorage.setItem("userAcount", res3.data[0][0].useracount)
                        // localStorage.setItem("userAvatar", `${userAvatar}`)
                        // localStorage.setItem("userId", res3.data[0][0].userid)
                        // localStorage.setItem("userVip", res3.data[0][0].uservip)
                        // localStorage.setItem("userName", res3.data[0][0].username)
                        // localStorage.removeItem("wxlogin")
                    }
                })
            }
            // 手机号没注册一键注册加绑定
            else {
                const acountType = pharmacyId == "0" ? "1" : pharmacyId == "2" ? "3" : "2"
                const res = await saveWx({
                    userName: this.state.userName,
                    userOpenid: this.state.userOpenid,
                    userAvatar: this.state.img,
                    userAcount: this.state.phone,
                    pharmacyId,
                    acountType
                })
                console.log(res)
                // 通过手机号获取用户信息
                const res3 = await getUser({
                    userAcount: this.state.phone,
                    pharmacyId
                })
                console.log(res3)
                if (res3.data[0].length) {
                    Toast.success("绑定成功")
                    this.setState({
                        divstate: false
                    })
                    // if (res3.data[0][0].userStatus==2) {
                        const code = localStorage.getItem("type")
                        storageUser(res3, 1)
                    // }else{
                    //     Toast.fail("该账号已冻结，无法继续登录")
                    // }
                    // const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
                    // localStorage.setItem("userAcount", res3.data[0][0].useracount)
                    // localStorage.setItem("userAvatar", `${userAvatar}`)
                    // localStorage.setItem("userVip", res3.data[0][0].uservip)
                    // localStorage.setItem("userId", res3.data[0][0].userid)
                    // localStorage.setItem("userName", res3.data[0][0].username)
                    // localStorage.removeItem("wxlogin")
                }

            }
            //   localStorage.setItem("phone", this.state.phone)
            //   this.props.history.push("/login/retrieve")
        } else {
            Toast.fail("验证码错误");
        }
    }
    //获取子组件this
    onRef=(ref)=>{
        console.log(ref);
    }
    render() {
        const { env, visible,img,userOpenid,userName, wxstate, value, newvalue, divstate ,pharmacyId} = this.state
console.log(!window.localStorage.getItem("userAcount1"),userOpenid,divstate);
        return (
            <div>
                {/* <Pf props={this.props} /> */}
                {/* <Single props={this.props}></Single> */}
                <Platform props={this.props} />
                {/* {env === 'single' ? <Pf props={this.props} /> : <Platform props={this.props} />} */}
                {/* {pharmacyId === '25' ? <Pf props={this.props} /> :pharmacyId=="2"?<Single props={this.props}></Single>:<Platform props={this.props} />} */}
                {!window.localStorage.getItem("userAcount1")&&userOpenid&&divstate ? <div className="bgbox">
                    <div className="bgbox-main">
                        <div className="main-top">
                            <p>检测到您还没绑定手机请立即绑定您的手机号</p>
                        </div>
                        <div className="main-text">
                            <p>手机号码</p>
                            <InputItem
                                type="number"
                                placeholder="请输入手机号"
                                error={this.state.hasError}
                                onErrorClick={this.onErrorClick}
                                onChange={this.onChange}
                                value={this.state.phone}
                                style={{fontSize:'14px'}}
                            ></InputItem>
                        </div>
                        <div className="main-text">
                            <p>验证码</p>
                            <InputItem
                                type="number"
                                placeholder="请输入验证码"
                                style={{fontSize:'14px'}}

                                error={this.state.hasError2}
                                onErrorClick={this.onErrorClick2}
                                onChange={this.onChange2}
                                onBlur={this.blur}
                                value={this.state.newvalue}
                            ></InputItem>
                        </div>
                        <p className="title">
                            未注册的手机号验证后自动创建账户
                        </p>
                        <div className="main-bottom">
                            <button onClick={() => (this.setState({ divstate: false }),window.localStorage.removeItem('userOpenid'))}>取消</button>
                            <button onClick={this.toNext}>确定</button>
                        </div>
                        {this.state.code == 0 ? <a className="ab" onClick={this.getnum}>获取验证码</a> : this.state.code == 1 ? <a className="ab">{this.state.time}后秒重新获取</a> : <a className="ab" onClick={this.getnum}>重新获取验证码</a>}
                    </div>
                </div> : null}

                {/* <FootNav onRef={this.onRef}/> */}
            </div>
        )
    }
}

export default withRouter(Home)