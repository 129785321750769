import React from 'react'

import './style.less'

import img1 from '../../static/img/nodata/1.png'
import xiaoxi from '../../static/img/nodata/xiaoxi.png'
import dingdan from '../../static/img/nodata/dingdan.png'
import xinxi from '../../static/img/nodata/xinxi.png'
import { Link } from 'react-router-dom'
// const { props} = props
const Nodata = ({ type }) => (
  <>
    { (()=>{
      switch(type){
        case '收藏':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>暂时没有收藏</p>
            
          </div>
          )
        case '网络':return <img src={img1} alt=''/>
        case '购物车':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>您的购物车太干净了~</p>
            <Link to={'/'}><div className='flex-row'>去逛逛</div></Link>
          </div>
          )
        case '订单':return (
          <div className='no-data'
          style={{paddingTop:100}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没订单呢~</p>
          </div>
          )
        case '药房':return (
          <div className='no-data'
          style={{paddingTop:100}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没药房呢~</p>
          </div>
          )
        case '店铺':return (
          <div className='no-data'
          style={{paddingTop:100}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没商品呢~</p>
          </div>
          )
        case '发票':return (
          <div className='no-data'>
            <img src={dingdan} alt=''/>
            <p>亲，还没发票呢~</p>
          </div>
          )
        case '抬头':return (
          <div className='no-data'>
            <img src={dingdan} alt=''/>
            <p>亲，还没发票抬头呢~</p>
          </div>
          )
        case '活动':return (
            <div className='no-data'>
              <img src={dingdan} alt=''/>
              <p>亲，还没活动商品呢~</p>
            </div>
        )
        case '公告':return (
          <div className='no-data'
          style={{paddingTop:100}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没公告呢~</p>
          </div>
          )
        case '消息':return (
          <div className='no-data' style={{paddingTop:180}}>
            <img src={xiaoxi} alt=''/>
            <p>亲，还没消息呢~</p>
          </div>
          )
        case '信息':return (
          <div className='no-data'>
            <img src={xinxi} alt=''/>
            <p>抱歉，暂无相关信息~</p>
          </div>
          )
        case '地址':return (
          <div className='no-data'
               style={{paddingTop:100}}>
            <img src={xinxi} alt=''/>
            <p>亲，还没地址呢~</p>
          </div>
          )
        case '医生':return (
            <div className='no-data'
                 style={{paddingTop:100}}>
              <img src={xinxi} alt=''/>
              <p>亲，还没关注医生呢~</p>
            </div>
        )
        case '就诊人':return (
            <div className='no-data'
                 style={{paddingTop:100}}>
              <img src={xinxi} alt=''/>
              <p>亲，还没就诊人呢~</p>
            </div>
        )
        case '积分':return (
            <div className='no-data' >
              <img src={xinxi} alt=''/>
              <p>亲，还没积分记录呢~</p>
            </div>
        )
        default:return null
      }
    }
    )()}
  </>
)

export default Nodata