import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import {oderdetailRequest,applyPartRequest,upOrderStuRequest,applyRefundRequest } from '../../../../api/request/order'
import { addAftersaleMed,addAftersale,updateAftersale,addQualifications } from '../../../../api/request/aftersale'
import fanhui from '../../../../static/img/fanhuihei.png'
import finish from '../../../../static/img/finish.png'

import './style.less'
const RadioItem = Radio.RadioItem
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [baogao, setBaogao] = useState([])
    const [details, setDetails] = useState([])
    const [miaosu, setMiaosu] = useState('')
    const [pharmacyId, setpharmacyId] = useState()
    let orderNumber;  //先定义一个存储参数的变量
    if(props.location.state){  //判断是否有参数
        orderNumber=props.location.state;
        localStorage.setItem('orderNumber3', orderNumber);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber3');
    }
    console.log(orderNumber);
    useEffect(() => {
        oderdetailRequest(orderNumber).then(res=>{
            console.log(res);
            setDetails(res.data[1])
            setpharmacyId(res.data[0][0].pharmacyId)
        })
    }, [])

    const fanhui1=()=>{
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    const tijiao=()=>{
        let time=new Date()
        let time1=time.getFullYear()+'-'+(time.getMonth()+1)+'-'+time.getDate()+' '+time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()
        let params={
            aftersaleNumber:'',
            orderNumber:orderNumber,
            aftersaleReason:miaosu,
            aftersaleCertificate:'',
            applyAftersale_time:time1,
            userId:userId,
            pharmacyId:pharmacyId,
            aftersaleDestination:0,
            aftersaleType:'资质类型'
        }

        if (baogao) {
            if (miaosu) {
                addAftersale(params).then(res=>{
                    console.log(res);
                    baogao.map(item=>{
                        if (item.checked==true) {
                            console.log(item);
                            let params1={
                              aftersaleId:res.data[1][0].aftersaleId,
                              orderNumber:orderNumber,
                              medId:item.medId,
                              medName:item.medName,
                              medPhoto:item.medPhoto,
                              medSpec:item.medSpec,
                              medApproval:item.medApproval,
                              medCompany:item.medCompany,
                              medPacking:item.medPacking,
                              businessInfo:item.value=='b'?1:0,
                              drugReport:item.value=='a'?1:0,
                            }
                            addQualifications(params1).then(res1=>{
                                console.log(res1);
                            })
                        }
                    })
                    let params2={
                        aftersaleState:'1',
                        aftersaleRefuse:'',
                        aftersaleDetails:miaosu,
                        aftersaleId:res.data[1][0].aftersaleId
                    }
                    if (res.code==1) {
                        updateAftersale(params2).then(res2=>{
                        console.log(res2);
                        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
                        })
                    }
                })
            }else{
                Toast.info('具体原因未填写')
            }
        }else{
            Toast.info('请选择相关资质')
        }
       
    }
    const fuzhi=(v)=>{
        setMiaosu(v.target.value)
    }
    const baoGao=(e,medId,medName,medPhoto,medSpec,medApproval,medCompany,medPacking)=>{
        baogao.map((item,index)=>{
            if (item.medId==medId&&item.value==e.target.value) {
                console.log(index);
                baogao.splice(index,1)
            }
        })
        setBaogao(baogao.concat({value:e.target.value,medId,checked:e.target.checked,medName,medPhoto,medSpec,medApproval,medCompany,medPacking}))
    }
    console.log(baogao);
    return (
        <div>
            <div className='invoice-top'>
                <div className='pifa-invoice'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>资质相关售后</p>   
                </div>
                <div className='selsect-reason'>
                    <span>请选择需要补发的资质</span>
                </div>
            </div>
            <div className='details'>
                <p className='xiangguan'>商品相关资质</p>
                {details.map(item=>(
                <p className='details-outer'>
                    <img src={item.medPhoto} alt="" />
                    <p className='details-in'>
                        <h2>{item.medName}</h2>
                        <p>
                            <label>
                                <input type="checkbox" value='a' onChange={(e)=>baoGao(e,item.medId,item.medName,item.medPhoto,item.medSpec,item.medApproval,item.medCompany,item.medPacking)}/>
                                <span>药检报告</span>
                            </label>
                            <label>
                                <input type="checkbox" value='b' onChange={(e)=>baoGao(e,item.medId,item.medName,item.medPhoto,item.medSpec,item.medApproval,item.medCompany,item.medPacking)}/>
                                <span>首营资料</span>
                            </label>
                        </p>
                    </p>
                </p>
                ))}
            </div>
            <div className='invoice-pic'>
                <p className='shuoming'>具体说明</p>
                <div className='upvoucher-in'>
                    <textarea className='miaoshu' placeholder='有其他证件资质需求请说明' maxlength="100" value={miaosu} name='textarea' cols="54" rows="9" onChange={(v)=>fuzhi(v)}></textarea>
                    <span style={{position:'absolute',left:'320px',marginTop:'110px'}}>
                        <span>{miaosu.length}</span>
                        <span>/</span>
                        <span>100</span>
                    </span>
                </div>
            </div>
            <div className='tijiao' onClick={()=>tijiao()}>
                <img src={finish} alt="" />
                <span>提交申请</span>
            </div>
        </div>
    )
}