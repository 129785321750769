import React,{Component} from 'react'
import SwipeableViews from 'react-swipeable-views'
import {autoPlay} from 'react-swipeable-views-utils'
import Pagination from './Pagination'

import './style3.less'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends Component{

    constructor(){
        super()
        this.state={index:0}
    }

    handleChangeIndex(index){
        this.setState({
            index:index
        })
    }

    activeClick = (link) => {
        window.location.href = link
    }

    render(){
        const banners = this.props.banners
        const link = this.props.link
        return(
            <div className='swiper'>
                <AutoPlaySwipeableViews interval={6000} onChangeIndex={this.handleChangeIndex.bind(this)}>
                    {
                        banners.map((v,index)=>{
                            return(
                                <div key={index} className='swiper-view'
                                onClick={()=>this.activeClick(link[index])}>
                                    <img src={v} alt=''/>
                                </div>
                            )
                        })
                    }
                </AutoPlaySwipeableViews>
                <Pagination dots={banners.length} index={this.state.index}/>
            </div>
        )
    }
}

export default Swiper