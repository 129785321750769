import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { WhiteSpace, Toast, Modal } from 'antd-mobile'

import Head from '../../../../component/Head'
import Nodata from '../../../../component/Nodata'
import Orderitem from '../../../../application/order/myorder'
import { oderlistRequest, delOrderRequest, upOrderStuRequest, cancelRefundRequest } from '../../../../api/request/order'

import './style.less'

const { alert } = Modal

//5.已申请退款, 6.已退款, 7.申请失败
function ApplyRefund(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [page, setPage] = useState(1)
    const size = 10
    const [orderState, setOrderState] = useState(['12'])
    const [orderList, setOrderList] = useState([])
    const [load, setLoad] = useState(true)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const params = {
            userId: userId,
            orderState: orderState,
            page: 1,
            size: size
        }
        getOrderList(params)
    }, [userId, orderState, size])
    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])

    const getOrderList = (params) => {
        oderlistRequest(params).then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                let data = res.data
                data[0].forEach(item1 => {
                    let sum = 0
                    data[1].forEach(item2 => {
                        if(item1.orderNumber === item2.orderNumber) {
                            sum += item2.medQuantity
                        }
                    })
                    item1.quantity = sum
                })
                if(data[0].length < size) {
                    setLoad(false)
                }
                setOrderList(data)
            } else {
                setOrderList([])
            }
        })
    }

    const toorderdeail = (orderNumber) => {
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }

    const delOrder = (e, orderNumber) => {
        e.stopPropagation()
        alert('确定删除记录？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确认', onPress: () => {
                delOrderRequest(orderNumber).then(res => {
                    let newList = orderList
                    newList[0].forEach((item, index) => {
                        if(orderNumber === item.orderNumber) {
                            newList[0].splice(index, 1)
                        }
                    })
                    newList[1].forEach((item, index) => {
                        if(orderNumber === item.orderNumber) {
                            newList[1].splice(index, 1)
                        }
                    })
                    setOrderList(newList)
                    setRefresh(true)
                    Toast.info('已删除', 1)
                })
            } },
        ])
    }

    const cancelRefund = (e, orderNumber) => {
        e.stopPropagation()
        alert('确定取消申请吗？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确认', onPress: () => {
                cancelRefundRequest(orderNumber).then(res => {
                    if(res.data && res.data.length === 0) {
                        Toast.info('退款申请已审核，无法取消', 1)
                        const params = {
                            userId: userId,
                            orderState: orderState,
                            page: 1,
                            size: size
                        }
                        getOrderList(params)
                    } else {
                        let newList = orderList
                        newList[0].forEach((item, index) => {
                            if(orderNumber === item.orderNumber) {
                                newList[0].splice(index, 1)
                            }
                        })
                        newList[1].forEach((item, index) => {
                            if(orderNumber === item.orderNumber) {
                                newList[1].splice(index, 1)
                            }
                        })
                        setOrderList(newList)
                        setRefresh(true)
                        Toast.info('已恢复至订单列表', 1)
                    }
                })
            } },
        ])
    }

    const refreshList = (e, orderNumber) => {
        e.stopPropagation()
        alert('是否确认收货？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确认', onPress: () => {
                upOrderStuRequest({orderState:'2',orderNumber:orderNumber}).then(res => {
                    if(res.code === 1) {
                        let newList = orderList
                        newList[0].forEach((item, index) => {
                            if(orderNumber === item.orderNumber) {
                                newList[0].splice(index, 1)
                            }
                        })
                        newList[1].forEach((item, index) => {
                            if(orderNumber === item.orderNumber) {
                                newList[1].splice(index, 1)
                            }
                        })
                        setOrderList(newList)
                        setRefresh(true)
                        Toast.info('已确认收货', 1)
                    }
                })
            } },
        ])
    }

    const onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2) {
            pageOrderList()
        }
    }
    const pageOrderList = () => {
        if(load) {
            const params = {
                userId: userId,
                orderState: orderState,
                page: page + 1,
                size: size
            }
            oderlistRequest(params).then(res => {
                if(res.code === 1) {
                    if(res.data[0].length > 0) {
                        let data = res.data
                        data[0].forEach(item1 => {
                            let sum = 0
                            data[1].forEach(item2 => {
                                if(item1.orderNumber === item2.orderNumber) {
                                    sum += item2.medQuantity
                                }
                            })
                            item1.quantity = sum
                        })
                        setOrderList([[...orderList[0], ...data[0]], [...orderList[1], ...data[1]]])
                        setPage(page+1)
                        if(data[0].length < size) {
                            setLoad(false)
                        }
                    } else {
                        if(res.data[0].length < size) {
                            setLoad(false)
                        }
                    }
                }
            })
            setRefresh(true)
        }
    }
    return(
        <div className='my-order'>
            <Head title='退换/售后'/>
            <div className='my-order-item'
            onScroll={onscroll}>
            {orderList.length === 0 ? <Nodata type='订单'/> :
                <div>
                {orderList[0].map((v,index)=>{
                    return(
                        <div key={index}>
                            <WhiteSpace />
                            <Orderitem onclick={toorderdeail}
                            delOrder={delOrder}
                            cancelRefund={cancelRefund}
                            state={12}
                            pharmacy={v}
                            medList={orderList[1]}
                            refreshList={refreshList}/>
                        </div>
                    )
                })}
                </div>
            }
            </div>
        </div>
    )
}

export default withRouter(ApplyRefund)