import React, { Component } from 'react'
import "./style.less"
import { Form, Input, Button, TextArea } from "antd-mobile-v5"
import icon1 from "../../../static/img/fanhuihei.png"
import { Toast } from 'antd-mobile'
import { integralDetails} from "../../../api/request/integral"
import Nodata from "../../../component/Nodata";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            list:[],
            allIntegral:0,
        }
    }
    componentDidMount = async () => {
        const { userId } = this.state
        let res=await integralDetails(userId)
        console.log(res)
        if (res.data&&res.data[0]){
            this.setState({
                allIntegral:res.data[0].length?res.data[0][0].userIntegral:0
            })
        }
        if (res.data&&res.data[1]){
            this.setState({
                list:res.data[1]
            })
        }
    }

    gobank=()=>{
        window.history.back()
    }
    render() {
        const { allIntegral,list } = this.state
        return (
            <div className='patientbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top' style={{position:'fixed'}}>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>我的积分</h1>
                </div>
                <div className='all-jifen'>
                    <p>当前积分</p>
                    <p className='count'>{allIntegral}</p>
                    <p>规则</p>
                </div>
                <div className='jifen-mingxi'>
                    <h1>积分收支明细</h1>
                    <div className='jifen-allinner'>
                        {list.length?list.map(item=>(
                            <>
                                <div className='jifen-mingxi-outer'>
                                    {item.revenueExpenditure==1?<p className='jifen-mingxi-left'>
                                            {item.integralCount>0?<p>订单{item.orderNumber}购物返积分{item.integralCount}</p>:
                                                <p>订单{item.orderNumber}使用积分{-item.integralCount}</p>}
                                            <p>{item.createTime}</p>
                                        </p>:
                                        <p className='jifen-mingxi-left'>
                                            {item.integralCount>0?<p>订单{item.orderNumber}退款退回积分{item.integralCount}</p>:
                                                <p>订单{item.orderNumber}退款返还积分{-item.integralCount}</p>}
                                            <p>{item.createTime}</p>
                                        </p>}
                                    <p className={item.integralCount>0?'jifen-mingxi-right':'jifen-mingxi-right1'}>{item.integralCount>0?'+'+item.integralCount:item.integralCount}</p>
                                </div>
                            </>)):<Nodata type='积分'/>}
                    </div>
                </div>
            </div>
        )
    }
}
