import React, { Component } from 'react'
import { Tabs, WhiteSpace, Toast, Modal, List, Radio, TextareaItem, Button } from 'antd-mobile'

import Head from '../../../component/Head'
import Nodata from '../../../component/Nodata'
import Orderitem from '../../../application/order/myorder'
import getUrl from '../../../component/GetUrlParam'

import { oderlistRequest, delOrderRequest, upOrderStuRequest, applyRefundRequest } from '../../../api/request/order'
import { cancelorder, getTau } from "../../../api/request/logistics"
import { oderdetailRequest, cancelZTorder,getOrdersate } from "../../../api/request/order"
import './style.less'

const { alert } = Modal
const RadioItem = Radio.RadioItem
export default class Order extends Component {
    constructor() {
        super()
        this.state = {
            tabId: '',
            pharmacyId: localStorage.pharmacyId,
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            reasons: ['订单信息填写错误', '重复下单了', '订单长时间未分配骑手', '商品缺货/无法出货/已售完', '商户发错单', '商户/顾客自身定位错误', '商户改其他第三方配送', '顾客下错单/临时不想要了', '顾客自取/不在家/要求另改时间配送', '其它原因'],
            reasons2: ["不想寄了", "下错单", "重复下单", "运费太贵", "无人联系", "取件太慢", "态度差"],
            radioVal: '',
            Reasonnum: '',//蜂鸟原因编号
            orderNumber: '',
            refundModal: '',
            reasonContent: '',
            page: 1,
            size: 999,
            orderState: '-13',
            orderList: [],
            load: true,
            refresh: false,
            tabId0:'',
            tabs: [{ title: '全部订单', stu: '-13' }, { title: '待付款', stu: '-2' }, { title: '配送中', stu: '0' }, { title: '待评价', stu: '2' }, { title: '退款', stu: '5' }]
        }
    }

    componentDidMount = () => {
        const { userId, tabs, orderState, size } = this.state
        let a = {}
        let b = ''
        if (getUrl('tabId', this.props.location.search) == 1) {
            b = '-2'
        } else if (getUrl('tabId', this.props.location.search) == 2) {
            b = '0'
        } else if (getUrl('tabId', this.props.location.search) == 3) {
            b = '2'
        } else if (getUrl('tabId', this.props.location.search) == 4) {
            b = '5'
        } else {
            b = '-13'
        }

        tabs.forEach(item => {
            if (item.stu == b) {
                a = item
            }
        })
        console.log(b);
        this.setState({
            tabId: getUrl('tabId', this.props.location.search),
            orderState: b
        }, () => {
            this.tabChange(a)
        })
        // const params = {
        //     userId: userId,
        //     orderState: a.stu,
        //     page: 1,
        //     size: size
        // }
        // this.getOrderList(params)
    }

    nowRefresh = () => {
        setTimeout(() => this.setState({
            refresh: false
        }))
    }
    getOrderList = (params) => {
        const { userId, tabs, orderState, size } = this.state
        console.log(params);
        oderlistRequest(params).then(res => {
            console.log(res);
            if (res.code === 1 && res.data[0].length > 0) {
                let data = res.data
                data[0].forEach(item1 => {
                    let sum = 0
                    data[1].forEach(item2 => {
                        if (item1.orderNumber === item2.orderNumber) {
                            sum += item2.medQuantity
                        }
                    })
                    item1.quantity = sum
                })
                if (data[0].length < size) {
                    this.setState({
                        load: false,
                    })
                }
                this.setState({
                    orderList: data,
                })
                // console.log(orderList);
            } else {
                this.setState({
                    orderList: [],
                })
            }
        })
    }

    toorderdeail = (orderNumber) => {
        const {tabId,tabId0} = this.state
        let b = (new Date()).getTime()
        this.props.history.push(`/mine/order/id?tradeno=${orderNumber}&a=${b}&tabId=${tabId0?tabId0:tabId}`)
    }
    tabChange = (tab, index) => {
        const { userId, orderState, page, size, orderList, } = this.state
        console.log(tab, index);
        const params = {
            userId: userId,
            orderState: tab.stu === '-13' ? ['-1', '16', '-11', '0', '-2', '-4', '1', '2', '-3', '3', '4', '5', '6', '7', '8', '-5', '12', '10', '14', '15'] : tab.stu === '-1' ? ['-1', '-11']: tab.stu === '5' ? ['5', '6', '7', '10', '14', '15'] : [tab.stu],
            page: 0,
            size: size
        }
        console.log(params);
        this.setState({
            // orderList:params,
            orderState: tab.stu,
            tabId0:index

        }, () => {
            this.getOrderList(params)
            this.nowRefresh()
        })
    }

    onscroll = e => {
        console.log(1);
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2) {
            this.pageOrderList()
        }
    }

    pageOrderList = () => {
        const { userId, orderState, page, size, orderList, load } = this.state
        console.log(2);
        // if (load) {
        //     const params = {
        //         userId: userId,
        //         orderState: orderState === '-13' ? ['-1', '-11', '0','16', '-2', '-4', '1', '2', '-3', '3', '4', '5', '6', '7', '8', '-5', '12', '10', '14', '15'] : orderState === '-1' ? ['-1','-11']: orderState === '-4' ? ['-4']  : orderState === '5' ? ['5', '6', '7', '10', '14', '15'] : [orderState],
        //         page: page + 1,
        //         size: size
        //     }
        //     oderlistRequest(params).then(res => {
        //         console.log(res);
        //         console.log(params);
        //         if (res.code === 1) {
        //             if (res.data[0].length > 0) {
        //                 let data = res.data
        //                 data[0].forEach(item1 => {
        //                     let sum = 0
        //                     data[1].forEach(item2 => {
        //                         if (item1.orderNumber === item2.orderNumber) {
        //                             sum += item2.medQuantity
        //                         }
        //                     })
        //                     item1.quantity = sum
        //                 })
        //                 this.setState({
        //                     page:page+1,
        //                     orderList:[[...orderList[0], ...data[0]], [...orderList[1], ...data[1]]]
        //                 })
        //                 if (data[0].length < size) {
        //                     this.setState({
        //                         load:false,
        //                     })
        //                 }
        //             } else {
        //                 this.setState({
        //                     orderList:[],
        //                 })
        //                 if (res.data[0].length < size) {
        //                     this.setState({
        //                         load:false,
        //                     })
        //                 }
        //             }
        //         }
        //     })
        //     this.nowRefresh()
        // }
    }

    delOrder2 = (e, orderNumber) => {
        const { orderList } = this.state
        e.stopPropagation()
        delOrderRequest(orderNumber).then(res => {
            let newList = orderList
            newList[0].forEach((item, index) => {
                if (orderNumber === item.orderNumber) {
                    newList[0].splice(index, 1)
                }
            })
            newList[1].forEach((item, index) => {
                if (orderNumber === item.orderNumber) {
                    newList[1].splice(index, 1)
                }
            })
            this.setState({
                orderList: newList,
            }, () => {
                this.nowRefresh()
            })
            Toast.info('已删除', 1)
        })
    }
    refreshList = (e, orderNumber) => {
        const { orderList } = this.state
        e.stopPropagation()
        upOrderStuRequest({ orderState: '2', orderNumber: orderNumber }).then(res => {
            if (res.code === 1) {
                let newList = orderList
                newList[0].forEach((item, index) => {
                    if (orderNumber === item.orderNumber) {
                        newList[0].splice(index, 1)
                    }
                })
                newList[1].forEach((item, index) => {
                    if (orderNumber === item.orderNumber) {
                        newList[1].splice(index, 1)
                    }
                })
                this.setState({
                    orderList: newList,
                }, () => {
                    this.nowRefresh()
                })
                Toast.info('已确认收货', 1)
            }
        })
    }

    applyRefund = (e, orderNumber) => {
        e.stopPropagation()
        this.setState({
            refundModal: true,
            orderNumber: orderNumber
        })
    }
    //取消订单(本平台以及配送平台)
    sureRefund = async () => {
        const { pharmacyId, Reasonnum, orderNumber, reasonContent } = this.state
        if (pharmacyId == 0) {
            // // 获取蜂鸟订单及token
            // const res = await oderdetailRequest(orderNumber)
            // const orderid = res.data[0][0].courierNumber
            // const res2 = await getTau()
            // const data = JSON.parse(res2.data.business_data).access_token
            // // 蜂鸟取消订单
            // const res1 = await cancelorder({
            //     order_id: orderid,
            //     access_token: data,
            //     // 取消原因从接口获取
            //     order_cancel_code: Reasonnum,
            //     // 取消实际需扣金额，从预取消接口拿
            //     //actual_cancel_cost_cent:10,
            //     // 取消方(商家，用户)
            //     order_cancel_role: 2
            // })
            // console.log(res1)
            if (reasonContent == '') {
                Toast.fail("请选择原因")
            } else {
                let res0=await getOrdersate({orderNumber})
                console.log(res0);
                const params = {
                    orderState: '5',
                    refundReason: reasonContent,
                    orderNumber: orderNumber,
                    refundOriginator: 0,
                    refundDestination: 0,
                    oldOrderStu: res0.data[0].length&&res0.data[0][0].orderState
                }
                // console.log(reasonContent,Reasonnum);
                applyRefundRequest(params).then(res => {
                    console.log(res);
                    if (res.code === 1) {
                        Toast.info('已申请退款，正在审核中……', 1)
                        this.setState({
                            refundModal: false
                        })
                        window.location.reload()
                    }
                })
            }
        }
    }

    showitems = (num) => {
        const { orderList,tabId0 } = this.state
        // console.log(num);
        return (
            <>
                <div>
                    {orderList[0].map((v, index) => {
                        return (
                            <div key={index}>
                                <WhiteSpace />
                                <Orderitem onclick={this.toorderdeail}
                                    delOrder2={this.delOrder2}
                                    state={num}
                                    pharmacy={v}
                                    medList={orderList[1]}
                                    tabId0={tabId0}
                                    refreshList={this.refreshList}
                                    applyRefund={this.applyRefund} />
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
    // 商城端选择原因
    chioseReason = (item) => {
        this.setState({
            radioVal: item,
        })
        switch (item) {
            case "订单信息填写错误":
                this.setState({
                    Reasonnum: 32,
                })
                break;
            case "重复下单了":
                this.setState({
                    Reasonnum: 36,
                })
                break;
            case "订单长时间未分配骑手":
                this.setState({
                    Reasonnum: 1,
                })
                break;
            case "商品缺货/无法出货/已售完":
                this.setState({
                    Reasonnum: 4,
                })
                break;
            case "商户发错单":
                this.setState({
                    Reasonnum: 6,
                })
                break;
            case "商户/顾客自身定位错误":
                this.setState({
                    Reasonnum: 7,
                })
                break;
            case "商户改其他第三方配送":
                this.setState({
                    Reasonnum: 8,
                })
                break;
            case "顾客下错单/临时不想要了":
                this.setState({
                    Reasonnum: 9,
                })
                break;
            case "顾客自取/不在家/要求另改时间配送":
                this.setState({
                    Reasonnum: 10,
                })
                break;
            case "其它原因":
                this.setState({
                    Reasonnum: 0,
                })
                break;
        }
        //console.log(Reasonnum)
        if (item === '其它原因') {
            this.setState({
                reasonContent: '',
            })
        } else {
            this.setState({
                reasonContent: item,
            })
        }
    }

    renderContent = (tab, index) => {
        const { tabId, orderList } = this.state
        return (
            <div className='my-order-item'
                onScroll={this.onscroll}>
                {orderList.length > 0 ? this.showitems(index) : <Nodata type='订单' />}
            </div>
        )
    }

    render() {
        const { tabId, pharmacyId,  reasons, reasons2, radioVal, pharmacy, orderNumber, refundModal, reasonContent, page, size, orderState, orderList, tabs, refresh, load } = this.state
        return (
            <div className='my-order'>
                <Head title='订单' />
                {
                tabId == 1 ? <Tabs tabs={tabs}
                    initialPage={1}
                    tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}
                    tabBarActiveTextColor="#0B1523"
                    tabBarInactiveTextColor="#353A42"
                    tabBarUnderlineStyle={{ border: '3px #1F87FF solid', transform: 'scaleX(0.25)' }}
                    renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
                    onChange={this.tabChange}>
                    {this.renderContent}
                </Tabs> 
                :null}
                    {tabId == 2 ? <Tabs tabs={tabs}
                        initialPage={2}
                        tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}
                        tabBarActiveTextColor="#0B1523"
                        tabBarInactiveTextColor="#353A42"
                        tabBarUnderlineStyle={{ border: '3px #1F87FF solid', transform: 'scaleX(0.25)' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
                        onChange={this.tabChange}>
                        {this.renderContent}
                    </Tabs> :null}
                    {tabId == 3 ? <Tabs tabs={tabs}
                        initialPage={3}
                        tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}
                        tabBarActiveTextColor="#0B1523"
                        tabBarInactiveTextColor="#353A42"
                        tabBarUnderlineStyle={{ border: '3px #1F87FF solid', transform: 'scaleX(0.25)' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
                        onChange={this.tabChange}>
                        {this.renderContent}
                    </Tabs> :null}
                    {tabId == 4 ? <Tabs tabs={tabs}
                        initialPage={4}
                        tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}
                        tabBarActiveTextColor="#0B1523"
                        tabBarInactiveTextColor="#353A42"
                        tabBarUnderlineStyle={{ border: '3px #1F87FF solid', transform: 'scaleX(0.25)' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
                        onChange={this.tabChange}>
                        {this.renderContent}
                    </Tabs> :null}
                        {tabId == 0 ? <Tabs tabs={tabs}
                        initialPage={0}
                        tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}
                        tabBarActiveTextColor="#0B1523"
                        tabBarInactiveTextColor="#353A42"
                        tabBarUnderlineStyle={{ border: '2px #1F87FF solid', transform: 'scaleX(0.25)' }}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={4} />}
                        onChange={this.tabChange}>
                        {this.renderContent}
                    </Tabs>:
                   null}
                {refundModal?<div className='refund-reasons'>
                    <p className='refund-reasons-title'>退款申请原因</p>
                    {reasons.map((item, index) => (
                        <>
                            <div key={index} className='refund-reasons-all'>
                                <div>{item}</div>
                                <input type="radio" name='b' checked={radioVal === item} onChange={() => {
                                    // const commit = document.getElementsByClassName('commit')[0];
                                    // commit.style.background = "#1F87FF"
                                    this.setState({
                                        radioVal:item
                                    })
                                    if (item === '其它原因') {
                                        this.setState({ reasonContent: '' })
                                    } else {
                                        this.setState({ reasonContent: item })
                                    }
                                }}/>
                            </div>
                        </>
                    ))}
                    {radioVal === '其它原因' ?
                        <TextareaItem
                            placeholder='请输入退款原因'
                            rows={2}
                            count={100}
                            onChange={(val) => this.setState({ reasonContent: val })} /> : null
                    }
                    <div className='commit' onClick={this.sureRefund}>确定</div>
                    <div  className='commit2' onClick={() => {
                        this.setState({
                            refundModal: false,
                            reasonContent: '',
                            radioVal: ''
                        })
                        // setVisible1('none')
                    }}>取消</div>
                </div>:null}
                {/*<Modal*/}
                {/*    popup*/}
                {/*    visible={refundModal}*/}
                {/*    onClose={() => {*/}
                {/*        this.setState({*/}
                {/*            refundModal: false,*/}
                {/*            reasonContent: '',*/}
                {/*            radioVal: ''*/}
                {/*        })*/}
                {/*    }}*/}
                {/*    animationType='slide-up' >*/}
                {/*    <List renderHeader={() => <div>退款申请原因</div>} className='popup-list'>*/}
                {/*        {pharmacyId == 0 ? reasons.map((item, index) => (*/}
                {/*            <RadioItem key={index}*/}
                {/*                checked={radioVal === item}*/}
                {/*                onChange={() => this.chioseReason(item)}>*/}
                {/*                {item}*/}
                {/*            </RadioItem>*/}
                {/*        )) : null}*/}
                {/*        {radioVal === '其它原因' ?*/}
                {/*            <TextareaItem*/}
                {/*                placeholder='请输入退款原因'*/}
                {/*                rows={1}*/}
                {/*                count={100}*/}
                {/*                onChange={(val) => this.setState({ reasonContent: val })} /> : null*/}
                {/*        }*/}
                {/*        <List.Item>*/}
                {/*            <Button type='primary' onClick={this.sureRefund}>确定</Button>*/}
                {/*            <Button type='primary' style={{ backgroundColor: '#aaa', marginTop: '5px' }} onClick={() => {*/}
                {/*                this.setState({*/}
                {/*                    refundModal: false,*/}
                {/*                    reasonContent: '',*/}
                {/*                    radioVal: ''*/}
                {/*                })*/}
                {/*            }}>取消</Button>*/}

                {/*        </List.Item>*/}
                {/*    </List>*/}
                {/*</Modal>*/}
            </div>
        )
    }
}