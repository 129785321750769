import React, { Component } from 'react'

import Head from '../../component/Head'
import Label from './Label'
import List from "./List/index"
import './style.less'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hislist: localStorage.hislist ? JSON.parse(localStorage.hislist) : [],
            hotlist: ['心脑血管', '石斛枫斗', '蜂胶', '六味地黄丸', '感冒灵颗粒', '达格列净片']
        }
    }

    searchMed = (value) => {
        let hislist = this.state.hislist
        if (value !== '') {
            hislist.push(value)
            this.setState({
                hislist: hislist
            })
            console.log(hislist)
            for (var i = 0; i < hislist.length; i++) {
                for (var j = i + 1; j < hislist.length; j++) {
                    if (hislist[i] == hislist[j]) {
                        hislist.splice(j, 1);
                        j--;
                    }
                }
            }
            localStorage.hislist = JSON.stringify(hislist)
            this.props.history.push(`/medlist/0/0/${value}`)
        }
    }

    delAll = () => {
        this.setState({
            hislist: []
        })
        if (this.state.hislist.length > 0) {
            localStorage.removeItem('hislist')
        }
    }

    searchLabel = (value) => {
        console.log(value);
        // localStorage.setItem('categoryName',value)
        this.props.history.push(`/medlist/0/0/${value}?title=${2}`)
    }

    delHistory = (index) => {
        let hislist = this.state.hislist
        hislist.splice(index, 1)
        this.setState({
            hislist: hislist
        })
    }

    render() {
        return (
            <div className='search-search'>
                <Head searchword='搜索商品' ability='搜索' clickmethod={this.searchMed} />
                <div style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: '#DDDFE5'
                }}></div>
                <div className='search-screen-page' style={{ background: '#FFFFFF' }}>
                    <div className='search-part'>
                        <div className='search-title'>
                            <span>历史搜索</span>
                            <i className="iconfont icon-icon--"
                                onClick={this.delAll}></i>
                        </div>
                        {/* <List
                            hislist={this.state.hislist}
                            hotlist={this.state.hotlist}
                            type="history"
                            onclick={this.searchLabel}
                        ></List> */}
                        <Label onclick={this.searchLabel}
                        list={this.state.hislist}
                        searchType='history'
                        delHistory={this.delHistory}/>
                    </div>
                    {/* <div className='search-part'>
                        <div className='search-title'>
                            <span>热门搜索</span>
                        </div> */}
                        {/* <List
                            hislist={this.state.hislist}
                            hotlist={this.state.hotlist}
                            onclick={this.searchLabel}
                            type="hot"
                        ></List> */}
                        {/* <Label onclick={this.searchLabel}
                        list={this.state.hotlist}
                        searchType='hot'/>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Search