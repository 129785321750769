import { axiosInstance } from '../config2'

//预下单
export const preCreateOrder = (data) => {
    return axiosInstance.post('api/preCreateOrder', data)
}
// 获取code
export const getCode = (data) => {
    return axiosInstance.post('api/preCreateOrder', data)
}
//查询配送范围
export const getRange = (data) => {
    return axiosInstance.post('api/chainstoreRange', data)
}
// 获取token
export const getToken = (data) => {
    return axiosInstance.post('api/openapi/token', data)
}