import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import Head from "../../../component/Head";
import "./TradSnapshot.less";
import { oderdetailRequest } from "../../../api/request/order";
import { Toast } from "antd-mobile";

function TradSnapshot(props) {
  const [orderDetail, setOrderDetail] = useState({});
  const [orderMed, setOrderMed] = useState({});
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderNumber = query.get("orderNumber");
  const toShop = () => {
    if (orderDetail.pharmacyState === 1) {
      props.history.push(`/shop/${orderDetail.pharmacyId}`);
    } else {
      Toast.info("店铺已下架");
    }
  };
  const toSnapshotDetail = (v) => {
    console.log(v);
    let mid = v.medId;
    props.history.push(`/snapshotmed?mid=${mid}&orderno=${orderNumber}`);
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      console.log(orderNumber, "订单号");
      try {
        const res = await oderdetailRequest(orderNumber);
        console.log(res, "订单详情res");
        setOrderDetail(res.data[0][0]);
        setOrderMed(res.data[1]);
      } catch (error) {}
    };
    fetchOrderDetails();
  }, [orderNumber]);

  return (
    <div className="snapshot-box">
      <Head title="交易快照" />
      <div className="top-tip">
        <p>
          交易快照包含订单创建时的商品描述和下单信息，在买卖双方和平台发生交易争议时可作为判断依据。
        </p>
      </div>

      {orderDetail && (
        <div className="snap-cont">
          <p className="com-name">{orderDetail.pharmacyName} &gt; </p>
          {orderMed &&
            orderMed.length > 0 &&
            orderMed.map((item) => (
              <div className="med-box" key={item.orderMedId}>
                <img src={item.medPhoto} />
                <div className="med-info">
                  <p className="med-name">
                    {item.medClassify == "1" && <span>Rx</span>} {item.medName}
                  </p>
                  <p className="med-spec">{item.medSpec}</p>
                  <p className="med-num">{`x${item.medQuantity}`}</p>
                </div>
                <div className="med-total">
                  <p>
                    ￥
                    {((item.medQuantity * item.medPrice) / 100)
                      .toFixed(2)
                      .replace(/\.?0+$/, "")}
                  </p>
                  <p
                    className="med-snap"
                    onClick={() => toSnapshotDetail(item)}
                  >
                    交易快照
                  </p>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
export default withRouter(TradSnapshot);
