import React, { Component } from 'react'
import "./middledetails.less"
import FH from "../../../static/img/fanhuihei.png"
import you from "../../../static/img/you.png"
import { getNoticeList } from '../../../api/request/msg'
import getUrl from '../../../component/GetUrlParam'
export default class bulletinboard extends Component {
    constructor(){
        super()
        this.state={
            userId: window.localStorage.getItem("userId2"),
            userarr:{},
            user:[],
            companyType:'',
            examine:'',
            activeBanner4:'',
            bulletinList:''
        }
    }
   
    componentDidMount=async()=>{
         // 公告
        getNoticeList().then(res=>{
            console.log(res);
            let arr=[]
            if (res.data[0].length) {
                res.data[0].map(item=>{
                    if (item.id==getUrl('bulletinId', this.props.location.search)) {
                        console.log(item);
                        this.setState({
                            bulletinList:item
                        })
                    }
                })
            }
        })
    }
    goon=(id)=>{
        this.props.history.replace(`/middledetails?bulletinId=${id}`)
        this.props.history.push(`/Announcementdetails?bulletinId=${id}`)
    }
    render() {
        const {bulletinList} =this.state
        return (
            <div>
                <div className="middledetails-top">
                    <img src={FH} alt="" onClick={() =>  this.props.history.push('/bulletinboard')} />
                    {bulletinList?<h2>{bulletinList.notice_title}</h2>:null}
                </div>
                <div className='middledetails-body'>
                {bulletinList?<h2>{bulletinList.notice_title}</h2>:null}
                    {bulletinList?<div>
                        <span>{bulletinList.notice_content}</span>
                    </div>:null}
                    {bulletinList?<p>
                        <p className='push-time'>推送时间：{bulletinList.noticeTime}</p>
                    </p>:null}
                    {bulletinList&&bulletinList.notice_url?
                    <p className='look-wenjian'  onClick={()=>this.goon(bulletinList.id)}>查看文件 {'>'} {'>'}</p>:null}
                </div>
            </div>
        )
    }
}
