// export const baseUrl="https://pf-gateway.sczyzk.com"
// export const baseUrl="https://gateway.sczyzk.com"
//零售测试环境
// export const baseUrl="http://sczyzk.eatuo.com:2333"
export const baseUrl = "https://gateway.sczyzk.com";
export const baseUrl0 = "https://h5.yyj-health.com";
// export const baseUrl0="http://192.168.10.24:3001"

//export const baseUrl="https://d-gateway.sczyzk.com"
// 微信授权登录跳转
export const baseUrl2 = "https://pay.sczyzk.com/pay/weixin/user";
// 问诊跳转
export const wzUrl = "http://wz.sczyzk.com";
// 支付
export const zfUrl = "https://pay.sczyzk.com/pay";

// 清除缓存跳转链接
// export const cacheUrl = "http://sczyzk.f3322.net:3002"; //测试
export const cacheUrl = "https://h5.sczyzk.com"; //正式

// 跳转参数
export const code = "2000";
export const code1 = "1000";
// export const code1="6000"
export const code2 = "3000";
