import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { List, WhiteSpace, Switch, Modal, Toast, InputItem } from "antd-mobile";
import { Stepper, Popup } from "antd-mobile-v5";

import Head from "../../component/Head";
import StepButon from "../../baseui/stepbutton/index";
import {
  adressListRequest,
  updateAdressRequest,
} from "../../api/request/adress";
import {
  getDefaultinvoice,
  addorderRequest,
  addAll,
  addordermedRequest,
  setOrdernumber,
  payYouhui,
  upOrderStuRequest,
  payManjian,
  changenum,
  getDefaultname,
  payDiscount,
  updateDiscountSum,
  payReducedFreight,
} from "../../api/request/order";
import {
  updatecartRequest,
  delCartRequest,
  pharmacyStorecs,
  getStock,
  getstoreTemplate,
} from "../../api/request/cart";
import { consumptionWallet, walletBalance } from "../../api/request/wallet";
import { meddetailRequest } from "../../api/request/med";
import {
  getUserstatus,
  addInvoice,
  getDefultInvoice,
} from "../../api/request/user";
import { allInvoice } from "../../api/request/yt";

import { beforehand } from "../../api/request/logistics";
import {
  getstoreinfo,
  isPharamacyNewuser,
  isActivity,
  calculatePrice,
  getDispatchMode,
} from "../../api/request/store";

import { homeactivityRequest } from "../../api/request/home";
import {
  integralIsuse,
  getUserIntegral,
  pointExchange,
} from "../../api/request/integral";

import "./fillorder.less";
import defaultImg from "../../static/img/default.png";
import zhezaho from "../../static/img/zhezaho.jpg";
import phonesj from "../../static/img/phonesj.svg";
import write from "../../static/img/write.svg";
import coupon from "../../static/img/coupon.png";

import close from "../../static/img/close1.png";

import { zfUrl, code, code1, code2 } from "../../publicurl";
import { number } from "prop-types";
// import { useMemo } from 'babel-plugin-react-html-attrs'
const alert = Modal.alert;
const Item = List.Item;
function Fillorder(props) {
  //console.log(props);
  const userId =
    localStorage.type == "1"
      ? localStorage.userId1
      : localStorage.type == "2"
      ? localStorage.userId2
      : localStorage.userId3;
  const posProvince = localStorage.posProvince
    ? localStorage.posProvince
    : "四川省";
  const type = localStorage.type;
  const [loading, setLoading] = useState(true);
  const [orderShipping, setorderShipping] = useState("2");
  const [cartArr, setCartArr] = useState(props.location.state);
  const [money, setMoney] = useState(0);
  const [orderFreight, setorderFreight] = useState(-1); //运费
  const [freeFreight, setfreeFreight] = useState(0); //包邮金额
  const [minFreight, setminFreight] = useState(0); //起送金额
  const [pharmacyPackingFee, setpharmacyPackingFee] = useState(0); //打包费
  const [userIntegral, setuserIntegral] = useState(0); //积分
  const [userIntegralIsuse, setuserIntegralIsuse] = useState(0); //暂存积分

  const [orderNumber1, setOrderNumber1] = useState(""); //订单号
  const [show, setShow] = useState(0);
  const [address, setAddress] = useState(null);
  const [allAddress, setallAddress] = useState(null);
  const [sumPrice, setSumPrice] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [pay, setPay] = useState(["微信支付", "钱包支付", "对公转账"]);
  const [pay1, setPay1] = useState("微信支付"); //默认选项
  const [balance, setBalance] = useState(0);
  const [visible, setVisible] = useState("none");
  const [visible0, setVisible0] = useState(false);
  const [visiable5, setVisiable5] = useState(false);
  const [visiable7, setVisiable7] = useState(false);

  const [visible2, setVisible2] = useState(false); //积分
  const [visible3, setVisible3] = useState(false); //优惠券
  const [visible4, setVisible4] = useState(false); //发票
  const [fapiaoDefault, setfapiaoDefault] = useState(false); //发票
  const [fapiaoName0, setfapiaoName0] = useState(""); //发票抬头名称
  const [fapiaoName1, setfapiaoName1] = useState(""); //新增发票抬头名称

  const [nofapia, setnofapia] = useState(1); //2不要发票
  const [dispatchMode, setdispatchMode] = useState(1); //指派对象
  const [logistic, setlogistic] = useState(""); //发单模式
  const [selectOne, setselectOne] = useState(-1);

  const [isuseJifen, setisuseJifen] = useState(0); //是否使用积分 0不使用 1使用  1000积分=10元
  const [orderIntegral, setorderIntegral] = useState(0); //使用积分数
  const [orderIntegral0, setorderIntegral0] = useState(0); //使用积分数
  const [couponDetails, setcouponDetails] = useState([]); //店铺满减可使用优惠券
  const [usercouponDetails, setusercouponDetails] = useState([]); //部分商品满减可使用的优惠券
  const [maxUsercoupon, setmaxUsercoupon] = useState(0); //订单最大优惠券金额
  const [selectCoupon, setselectCoupon] = useState(); //最终选择的优惠券
  const [couponManagerId, setcouponManagerId] = useState(); //最终选择的优惠券id
  const [activitiesId, setactivitiesId] = useState(); //满减活动id
  const [manjianPrice, setmanjianPrice] = useState(0); //最终的满减金额
  const [discountAllprice, setdiscountAllprice] = useState(0); //折扣商品优惠总价
  const [discountAllId, setdiscountAllId] = useState([]); //折扣商品所有id
  const [reducedDetail, setreducedDetail] = useState(); //配送费活动
  const [reducedPrice, setReducedPrice] = useState(); //配送费优惠金额
  const [isnewUser, setisnewUser] = useState(0); //是否店铺新用户
  const [firstReductionAmount, setfirstReductionAmount] = useState(0); //新客立减金额

  const [ownDyjifen, setownDyjifen] = useState([]); //自定义积分

  const [companyName, setcompanyName] = useState(); //公司名
  const [bankName, setbankName] = useState(); //公司开户行名称
  const [bankAccount, setbankAccount] = useState(); //公司银行账户
  const [companyAddress, setcompanyAddress] = useState(); //公司地址
  const [contacts, setContacts] = useState(); //联系人
  const [contactPhone, setcontactPhone] = useState(); //联系电话
  const [sum, setSum] = useState(0);
  const [reservedTelephone, setreservedTelephone] = useState(""); //自提预留电话
  const [weeks, setWeeks] = useState();

  const [visible1, setVisible1] = useState("none");
  const [remark, setRemark] = useState("无");
  const [fapiao, setFapiao] = useState(["不要发票", "需要发票"]);
  const [fapiao1, setFapiao1] = useState("不要发票"); //默认选项
  const [isFapiao, setisFapiao] = useState(0); //0不要发票 1要发票

  const [invoice, setInvoice] = useState(0);
  const [invoice1, setInvoice1] = useState();
  const [templateName, setTemplateName] = useState();
  const [stockNum, setstockNum] = useState([]);
  const [isClassify, setisClassify] = useState(1);
  const [userStatus, setuserStatus] = useState(2);
  const [isInvoice, setisInvoice] = useState(1); //店铺是否可开发票1可
  const [exchangePoints, setexchangePoints] = useState(0); //exchangePoints积分兑换exchangeAmount/100元
  const [exchangeAmount, setexchangeAmount] = useState(0);
  const [enablePoints, setenablePoints] = useState(0); //积分兑换是否生效 1生效
  const [invoiceList, setinvoiceList] = useState("");

  // 获取经纬度
  // let longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
  // let latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
  // // this.homeRequest(0,longitude,latitude)
  useEffect(() => {
    getUserstatus(localStorage.userAcount1).then((res) => {
      console.log(res);
      if (res.data[0].length) {
        setuserStatus(res.data[0][0].userStatus);
      }
    });
    getUserIntegral({ userId }).then((res) => {
      console.log(res);
      if (res.data[0].length) {
        setuserIntegral(res.data[0][0].userIntegral);
        setuserIntegralIsuse(res.data[0][0].userIntegralIsuse);
      }
    });
    pointExchange().then((res) => {
      console.log(res);
      if (res.code == 1 && res.data[0].length) {
        setexchangePoints(res.data[0][0].exchangePoints);
        setexchangeAmount(res.data[0][0].exchangeAmount);
        setenablePoints(res.data[0][0].enablePoints);
      }
    });
    allInvoice(userId).then((res) => {
      console.log(res);
      if (res.data[0].length) {
        setinvoiceList(res.data[0]);
        res.data[0].map((item, index) => {
          if (item.invoiceDefault == 1) {
            setselectOne(index);
          }
        });
      }
    });
    if (cartArr) {
      let companyId = cartArr[0].pharmacy.pharmacyId;
      getDefaultinvoicenews();
      console.log(cartArr);
      cartArr[0].medList.map((item) => {
        if (item.medClassify == "1") {
          setisClassify(2);
        }
      });

      // getstoreTemplate(companyId, 1).then(res2=>{
      //     console.log(res2);
      //     if (res2.code==1&&res2.data[0].length) {
      //         setpharmacyPackingFee(res2.data[0][0].pharmacyPackingFee)
      //     }
      // })

      pharmacyStorecs(companyId).then((res) => {
        console.log(res);
        setpharmacyPackingFee(res.data[0][0].pharmacyPackingFee);
        setcompanyName(res.data[0][0].companyName);
        setbankName(res.data[0][0].bankName);
        setbankAccount(res.data[0][0].bankAccount);
        setcompanyAddress(res.data[0][0].companyAddress);
        setContacts(res.data[0][0].contacts);
        setcontactPhone(res.data[0][0].contactPhone);
        let sum1 = 0;
        // cartArr.forEach(item => {
        setorderShipping(
          cartArr[0].pharmacy.orderShipping
            ? cartArr[0].pharmacy.orderShipping
            : "2"
        );
        console.log(cartArr[0].medList);
        if (cartArr[0].medList.length) {
          cartArr[0].medList.forEach((item1) => {
            sum1 += item1.medPrice * item1.medQuantity;
            console.log(sum1);
            setSum(sum1);
          });
        }
        // })
      });
    }
    getDefaultinvoice({ userId }).then((res) => {
      if (res.data[0].length > 0) {
        setInvoice(1);
        setInvoice1(res.data[0][0]);
      } else {
        setInvoice(0);
      }
    });
  }, []);
  useEffect(() => {
    if (cartArr) {
      let dispatchMode = "";
      let logistic = "";
      getDispatchMode({ pharmacyId: cartArr[0].pharmacy.pharmacyId }).then(
        (res3) => {
          console.log(res3);
          if (res3.code == 1 && res3.data[0].length) {
            dispatchMode = res3.data[0][0].dispatchMode;
            logistic = res3.data[0][0].logistic;
            setdispatchMode(res3.data[0][0].dispatchMode);
            setlogistic(res3.data[0][0].logistic);
          }
          adressListRequest(userId).then((res) => {
            console.log(res);
            if (res.code === 1 && res.data[0].length > 0) {
              const data = res.data[0];
              let state00 = false;
              data.forEach((item, index) => {
                homeactivityRequest(
                  cartArr[0].pharmacy.pharmacyId,
                  item.longitude,
                  item.latitude
                ).then((res) => {
                  // console.log(res);
                  let data0 = res.data[2];
                  data0.map((item0) => {
                    if (item0.pharmacyId == cartArr[0].pharmacy.pharmacyId) {
                      // console.log(item0.distance>=1000?(item0.distance/1000).toFixed(1)+'km':item0.distance+'m');
                      item.distance = item0.distance;
                    }
                  });
                });
                if (item.addressDefault === "1") {
                  console.log(item);
                  state00 = true;
                  setAddress(item);
                  // setreservedTelephone(item.phone)
                  console.log(dispatchMode);
                  calculatePrice({
                    dispatch_mode: dispatchMode,
                    logistic: logistic,
                    shop_id: cartArr[0].pharmacy.pharmacyId,
                    receiver_longitude: item.longitude,
                    receiver_latitude: item.latitude,
                    receiver_address:
                      item.province + item.city + item.district + item.address,
                  }).then((res) => {
                    console.log(res);
                    let count0 = 0;
                    let a = "";
                    if (res.data.detail && res.data.detail.length) {
                      if (res.data.detail.length == 1) {
                        setorderFreight(res.data.detail[0].amount * 100);
                      } else {
                        res.data.detail.map((item) => {
                          if (item.check) {
                            count0++;
                            a = item;
                            // setorderFreight(item.amount*100)
                          }
                        });
                        console.log(count0, a);
                        if (count0 == 1) {
                          setorderFreight(a.amount * 100);
                        } else {
                          setorderFreight(res.data.detail[0].amount * 100);
                        }
                      }
                    } else {
                      setorderFreight(0);
                    }
                  });
                }
              });
              if (!state00) {
                setAddress(data[0]);
                let count0 = 0;
                let a = "";
                // setreservedTelephone(data[0].phone)
                calculatePrice({
                  dispatch_mode: dispatchMode,
                  logistic: logistic,
                  shop_id: cartArr[0].pharmacy.pharmacyId,
                  receiver_longitude: data[0].longitude,
                  receiver_latitude: data[0].latitude,
                  receiver_address:
                    data[0].province +
                    data[0].city +
                    data[0].district +
                    data[0].address,
                }).then((res) => {
                  if (res.data.detail && res.data.detail.length) {
                    if (res.data.detail.length == 1) {
                      setorderFreight(res.data.detail[0].amount * 100);
                    } else {
                      res.data.detail.map((item) => {
                        if (item.check) {
                          count0++;
                          a = item;
                          console.log(item);
                          // setorderFreight(item.amount*100)
                        }
                      });
                      console.log(count0, a);
                      if (count0 == 1) {
                        setorderFreight(a.amount * 100);
                      } else {
                        setorderFreight(res.data.detail[0].amount * 100);
                      }
                    }
                  } else {
                    setorderFreight(0);
                  }
                });
              }
              // console.log(data);
              setallAddress(data);
            } else if (res.code === 1 && res.data[0].length == 0) {
              // alert('暂无地址', '可选择自提或添加地址', [
              //     { text: '自取', onPress: () => { setorderShipping('1') } },
              //     {
              //         text: '去添加', onPress: () => {
              //             props.history.push('/mine/adress')
              //         }
              //     },
              // ])
              setVisiable7(true);
            }
          });
        }
      );
    }
  }, [userId]);
  const getDefaultinvoicenews = async () => {
    // 查询默认发票模板
    let res = await getDefultInvoice({
      pharmacyId: cartArr[0].pharmacy.pharmacyId,
      userId,
    });
    console.log(res);
    if (res.data[0].length) {
      setisInvoice(res.data[0][0].isInvoice); //店铺是否可开发票1可
    }
    if (res.data[1].length) {
      setfapiaoName0(res.data[1][0].invoiceTitle);
      setisFapiao(1);
    }
  };
  // console.log(sum);
  useEffect(() => {
    // console.log(cartArr);
    let params = {
      userId: userId,
    };
    walletBalance(params).then((res) => {
      //console.log(res)
      let a = res.data[0].length ? res.data[0][0].Balance : 0;
      setBalance(a);
    });
    if (cartArr) {
      isPharamacyNewuser(cartArr[0].pharmacy.pharmacyId, userId).then((res) => {
        console.log(res);
        // 是否是新客
        if (res.data[0][0].count == 0) {
          //是该店铺新用户
          isActivity(cartArr[0].pharmacy.pharmacyId).then((res1) => {
            console.log(res1);
            // 是否有新客立减活动
            if (res1.code == 1 && res1.data[0].length) {
              // 新客立减金额
              setfirstReductionAmount(res1.data[0][0].firstReductionAmount);
            }
          });
          setisnewUser(1);
        }
      });
      // const nowTime=localStorage.setItem('nowTime',cartArr[0].nowTime)

      // let sum = 0
      // // cartArr.forEach(item => {
      //     setorderShipping(cartArr[0].pharmacy.orderShipping ? cartArr[0].pharmacy.orderShipping : '2')
      //     console.log( cartArr[0].medList);
      //     if (cartArr[0].medList.length) {
      //         cartArr[0].medList.forEach(item1 => {
      //             sum += item1.medPrice*item1.medQuantity
      //             console.log(sum);
      //         })
      //     }
      // // })
      // console.log(sum,freeFreight);
      // if (type=='1') {
      //     // console.log(props.location.params);
      //     if (sum<cartArr[0].pharmacy.freeFreight) {
      //         setorderFreight(cartArr[0].pharmacy.orderFreight)
      //     }else{
      //         setorderFreight(0)
      //     }
      //    if (props.location.params) {
      //        setminFreight(props.location.params.minFreight)
      //     }else{
      //        setminFreight(cartArr[0].pharmacy.minFreight)
      //    }
      //     setfreeFreight(cartArr[0].pharmacy.freeFreight)
      // }
      console.log(sum);
      if (type == "2") {
        if (sum / 100 > freeFreight) {
          setSumPrice(sum);
        } else {
          setSumPrice(Number(sum) + orderFreight * 100);
          setMoney(orderFreight);
        }
      } else {
        setSumPrice(sum);
      }

      // if (sum / 100 >= 1000 & sum / 100 < 2000) {
      //     setSumPrice(sum - 20000)
      // } else if (sum >= 200000) {
      //     setSumPrice(sum - 50000)
      // } else {
      //     setSumPrice(sum)
      // }
      //console.log(sum);
      //setSumPrice(sum)
      setLoading(false);
    } else {
      props.history.go(-1);
    }
  }, [cartArr, sum]);
  console.log(orderFreight);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  useEffect(() => {
    let obj;
    let arrCoupons = [];
    let state = false;
    let manjianMoney = 0; //店铺满减金额
    let anymanjianMoney = 0; //部分满减金额
    let activitiesDiscount = [];
    let a = 0; //部分满减商品总金额
    var tempDate = new Date();
    var days = tempDate.getDay();
    var week;
    let manjianPrice0 = 0;
    switch (days) {
      case 1:
        week = "周一";
        break;
      case 2:
        week = "周二";
        break;
      case 3:
        week = "周三";
        break;
      case 4:
        week = "周四";
        break;
      case 5:
        week = "周五";
        break;
      case 6:
        week = "周六";
        break;
      case 0:
        week = "周日";
        break;
    }
    if (cartArr && sumPrice) {
      let companyId = cartArr[0].pharmacy.pharmacyId;
      cartArr[0].medList.map((item) => {
        arrCoupons.push(item.medQrcode);
        if (item.medClassify == "1") {
          setisClassify(2);
        }
      });
      console.log(arrCoupons);
      // 查询满减商品
      payManjian({ pharmacyId: companyId, medQrcode: arrCoupons }).then(
        (res) => {
          console.log(res);
          if (res.data[0].length) {
            obj = res.data[0][0].activitiesType;
          }
          let manjian = res.data[0];
          let manjianDetails = res.data[1];
          if (manjian.length) {
            manjian[0].activitiesCycle = JSON.parse(manjian[0].activitiesCycle);
            manjian[0].activitiesDiscount = JSON.parse(
              manjian[0].activitiesDiscount
            );
            for (var i = 0; i < manjian[0].activitiesDiscount.length - 1; i++) {
              for (
                var j = 0;
                j < manjian[0].activitiesDiscount.length - i - 1;
                j++
              ) {
                // 1.对每一个值和它的下一个值进行比较
                if (
                  manjian[0].activitiesDiscount[j].full >
                  manjian[0].activitiesDiscount[j + 1].full
                ) {
                  // 如果第一个值更多，则将其赋予自定义计数值 count
                  var count = manjian[0].activitiesDiscount[j];
                  // 反复交换
                  manjian[0].activitiesDiscount[j] =
                    manjian[0].activitiesDiscount[j + 1];
                  manjian[0].activitiesDiscount[j + 1] = count;
                }
              }
            }
            manjian[0].activitiesCycle.map((item) => {
              if (item == week) {
                state = true;
              }
            });
          }
          console.log(state);
          if (manjianDetails && state) {
            manjianDetails.map((v) => {
              v.activitiesCycle = [];
              v.activitiesDiscount = [];
              v.activitiesStartTime = "";
              v.activitiesEndTime = "";
              v.activitiesId = "";
              v.activitiesType = 2;
              v.pharmacyId = "";
            });
          }
          // 店铺满减
          if (manjian && state) {
            cartArr[0].medList.map((item) => {
              manjian.map((i) => {
                if (i.activitiesType == 2) {
                  item.activitiesCycle = i.activitiesCycle;
                  item.activitiesDiscount = i.activitiesDiscount;
                  item.activitiesStartTime = i.activitiesStartTime;
                  item.activitiesEndTime = i.activitiesEndTime;
                  item.activitiesId = i.activitiesId;
                  item.activitiesType = i.activitiesType;
                }
              });
            });
          }
          if (manjianDetails && manjian.length && state) {
            manjianDetails.map((item) => {
              manjian.map((i) => {
                if (item.activeId == i.activitiesId && i.activitiesType == 1) {
                  item.activitiesCycle = i.activitiesCycle;
                  item.activitiesDiscount = i.activitiesDiscount;
                  item.activitiesStartTime = i.activitiesStartTime;
                  item.activitiesEndTime = i.activitiesEndTime;
                  item.activitiesId = i.activitiesId;
                  item.activitiesType = i.activitiesType;
                  item.pharmacyId = i.pharmacyId;
                }
              });
            });
          }
          // 部分满减
          if (manjianDetails && state) {
            cartArr[0].medList.map((item) => {
              manjianDetails.map((i) => {
                if (i.activitiesType == 1 && item.medQrcode == i.medQrcode) {
                  item.activitiesCycle = i.activitiesCycle;
                  item.activitiesDiscount = i.activitiesDiscount;
                  item.activitiesStartTime = i.activitiesStartTime;
                  item.activitiesEndTime = i.activitiesEndTime;
                  item.activitiesId = i.activitiesId;
                  item.activitiesType = i.activitiesType;
                }
              });
            });
          }
          // console.log(sumPrice);
          if (obj && state) {
            if (obj == 2) {
              //全部
              // manjianMoney=manjian[0].activitiesDiscount[0].full
              if (sumPrice < manjian[0].activitiesDiscount[0].full) {
                // setmanjianPrice(manjian[0].activitiesDiscount[0].minus)
                manjianPrice0 = 0;
              } else {
                for (let i = 0; i < manjian[0].activitiesDiscount.length; i++) {
                  if (sumPrice >= manjian[0].activitiesDiscount[i].full) {
                    manjianMoney = manjian[0].activitiesDiscount[i].full;
                    //    setmanjianPrice(manjian[0].activitiesDiscount[i].minus)
                    manjianPrice0 = manjian[0].activitiesDiscount[i].minus;
                  }
                }
              }
              // console.log(manjian[0].activitiesDiscount,manjianPrice0);
              setmanjianPrice(manjianPrice0);
              setactivitiesId(manjian[0].activitiesId);
            } else {
              //部分
              cartArr[0].medList.map((i) => {
                if (i.activitiesType) {
                  activitiesDiscount = i.activitiesDiscount;
                  a += i.medPrice * i.medQuantity;
                  setactivitiesId(i.activitiesId);
                }
              });
              console.log(activitiesDiscount[0]);
              if (activitiesDiscount[0]) {
                // anymanjianMoney=activitiesDiscount[0].full
                if (a < activitiesDiscount[0].full) {
                  manjianPrice0 = 0;
                } else {
                  for (let i = 0; i < activitiesDiscount.length; i++) {
                    if (a >= activitiesDiscount[i].full) {
                      // anymanjianMoney=activitiesDiscount[i].full
                      manjianPrice0 = activitiesDiscount[i].minus;
                    }
                  }
                }
              }
              setmanjianPrice(manjianPrice0);
            }
          }
        }
      );
      // 查询所有优惠券
      payYouhui({ pharmacyId: companyId, medQrcode: arrCoupons, userId }).then(
        (res) => {
          console.log(res);
          let array = cartArr[0].medList;
          let array1 = []; //店铺可用优惠券
          let array2 = []; //购物车勾选商品多个商品存在商品优惠券
          let array3 = []; //购物车勾选商品仅一个商品存在商品优惠券
          let array4 = []; //部分商品最终可使用的商品优惠券
          let array5 = []; //所有满减
          let flag2 = false;
          if (res.data[0].length) {
            res.data[0].map((i) => {
              if (i.couponMoney <= sumPrice) {
                array1.push(i); //店铺满减可使用
              }
            });
          }
          if (array1.length) {
            for (let i = 0; i < array1.length; i++) {
              for (let j = i + 1; j < array1.length; j++) {
                if (array1[i].couponManagerId == array1[j].couponManagerId) {
                  array1.splice(j, 1);
                  j--;
                }
              }
            }
          }
          if (res.data[1].length) {
            let nArr = res.data[1].filter(
              (currentValue, currentIndex, selfArr) => {
                return (
                  selfArr.findIndex(
                    (x) => x.medQrcode === currentValue.medQrcode
                  ) === currentIndex
                );
              }
            );
            array.map((i) => {
              nArr.map((v) => {
                if (i.medQrcode == v.medQrcode) {
                  v.medPrice = i.medPrice;
                  v.medQuantity = i.medQuantity;
                  v.date = [v.medQrcode];
                  v.medPriceAll = i.medPrice * i.medQuantity;
                  array2.push(v);
                }
              });
            });
          }
          if (array2.length) {
            if (array2.length > 1) {
              for (let i = 0; i < array2.length; i++) {
                for (let j = i + 1; j < array2.length; j++) {
                  if (array2[i].couponManagerId == array2[j].couponManagerId) {
                    array2[i].date.push(array2[j].medQrcode);
                    array2[i].medPriceAll +=
                      array2[j].medPrice * array2[j].medQuantity;
                    array2.splice(j, 1);
                    j--;
                    flag2 = true;
                  }
                }
              }
            } else {
              flag2 = true;
            }
          }
          if (!flag2) {
            array2.map((i) => {
              if (i.medQuantity * i.medPrice > i.couponMoney) {
                array3.push(i);
              }
            });
          } else {
            array2.map((i) => {
              if (i.couponMoney < i.medPriceAll) {
                array4.push(i);
              }
            });
          }
          array5 = array1.concat(array4);
          if (array5 && array5.length) {
            let a = array5[0].couponLimit;
            for (let i = 0; i < array5.length; i++) {
              if (array5[i].couponLimit >= a) {
                a = array5[i].couponLimit;
                setselectCoupon(array5[i]);
                setcouponManagerId(array5[i].couponManagerId);
              }
            }
            setmaxUsercoupon(a);
          }
          console.log(array1, array4);
          // 可使用的店铺优惠券
          setcouponDetails(array1);
          //部分商品满减可使用的优惠券
          setusercouponDetails(array4);
        }
      );
    }
  }, [sumPrice, cartArr, show]);
  const shippingType = (val) => {
    console.log(val);
    if (val) {
      if (address) {
        setorderShipping("2");
      } else {
        alert("暂无地址，请先添加", "", [
          {
            text: "取消",
            onPress: () => {
              console.log("取消");
            },
          },
          {
            text: "去添加",
            onPress: () => {
              props.history.push("/mine/adress");
            },
          },
        ]);
      }
    } else {
      setorderShipping("1");
    }
  };
  //
  const shippingType1 = (val) => {
    console.log(val);
    if (val) {
      if (address) {
        setorderShipping("2");
      } else {
        setVisiable7(true);
        // alert('暂无地址，请先添加', '', [
        //     { text: '取消', onPress: () => { console.log('取消') } },
        //     {
        //         text: '去添加', onPress: () => {
        //             props.history.push('/mine/adress')
        //         }
        //     },
        // ])
      }
    } else {
      setorderShipping("1");
    }
  };
  const onDelete = () => {
    props.history.push("/mine/adress");
    setVisiable7(false);
  };
  useEffect(() => {
    var tempDate = new Date();
    var days = tempDate.getDay();
    var week;
    switch (days) {
      case 1:
        week = "周一";
        break;
      case 2:
        week = "周二";
        break;
      case 3:
        week = "周三";
        break;
      case 4:
        week = "周四";
        break;
      case 5:
        week = "周五";
        break;
      case 6:
        week = "周六";
        break;
      case 0:
        week = "周日";
        break;
    }
    setWeeks(week);
    console.log(cartArr);
    let array = [];
    let array0 = [];
    if (cartArr) {
      cartArr[0].medList.map((item) => {
        getStock({ medId: item.medId }).then((res) => {
          console.log(res);
          if (
            item.medQuantity > parseInt(res.data[0][0].stockNum) ||
            parseInt(res.data[0][0].stockNum) < 0
          ) {
            array.push({
              name: item.medName,
              count: parseInt(res.data[0][0].stockNum),
            });
          }
          setstockNum(array);
        });
      });

      let data0 = [];
      if (cartArr[0].medList.length) {
        cartArr[0].medList.map((item) => {
          data0.push(item.medQrcode);
          item.discountId = "";
          item.discountLimits = "";
          item.discountMedicineCount = 0;
          item.discountPrice = "";
          item.discountStock = "";
          item.percentage = "";
        });
        let discountDetail = [];
        // 折扣商品
        payDiscount({ medQrcode: data0 }).then((res) => {
          console.log(res);
          if (res.data[0].length) {
            res.data[0].map((item) => {
              item.state = false;
              item.discountCycle = JSON.parse(item.discountCycle);
              item.discountCycle.map((v) => {
                if (v == week) {
                  item.state = true;
                }
              });
              if (item.state) {
                discountDetail.push(item);
              }
            });
            console.log(discountDetail);
          }
          if (discountDetail.length) {
            cartArr[0].medList.map((i) => {
              discountDetail.map((v) => {
                if (i.medQrcode == v.medQrcode) {
                  i.discountId = v.discountId;
                  i.discountLimits = v.discountLimits;
                  i.discountMedicineCount = v.discountMedicineCount;
                  i.discountPrice = v.discountPrice;
                  i.discountStock = v.discountStock;
                  i.percentage = v.percentage;
                }
              });
            });
          }
          cartArr[0].medList.map((item) => {
            item.discountSum = 0; //折扣数量
            item.discountMoney = 0; //折后单价
            item.discountchaMoney = 0; //折后差价
            item.discountoneMoney = 0; //折后单个药品价格
            let allmoney = 0;

            if (item.discountId) {
              if (!item.discountMedicineCount) {
                item.discountMedicineCount = 0;
              }
              // 立减
              if (item.percentage == -1) {
                item.discountMoney = item.discountPrice;
                item.discountchaMoney = item.medPrice - item.discountPrice;
                // 库存不限
                if (item.discountStock == -1) {
                  if (
                    item.discountLimits == -1 ||
                    (item.discountLimits != -1 &&
                      item.discountLimits - item.discountMedicineCount >=
                        item.medQuantity)
                  ) {
                    item.discountSum = item.medQuantity;
                    item.discountoneMoney = item.discountPrice;
                  } else if (
                    item.discountLimits != -1 &&
                    item.discountLimits - item.discountMedicineCount <
                      item.medQuantity
                  ) {
                    item.discountSum =
                      item.discountLimits - item.discountMedicineCount;
                    allmoney =
                      (item.discountLimits - item.discountMedicineCount) *
                        item.discountPrice +
                      item.medPrice *
                        (item.medQuantity -
                          (item.discountLimits - item.discountMedicineCount));
                    item.discountoneMoney = Math.round(
                      allmoney / item.medQuantity
                    );
                  }
                  // 限库存
                } else {
                  if (item.discountStock >= item.medQuantity) {
                    if (
                      item.discountLimits == -1 ||
                      (item.discountLimits != -1 &&
                        item.discountLimits - item.discountMedicineCount >=
                          item.medQuantity)
                    ) {
                      item.discountSum = item.medQuantity;
                      item.discountoneMoney = item.discountPrice;
                    } else if (
                      item.discountLimits != -1 &&
                      item.discountLimits - item.discountMedicineCount <
                        item.medQuantity
                    ) {
                      item.discountSum =
                        item.discountLimits - item.discountMedicineCount;
                      allmoney =
                        (item.discountLimits - item.discountMedicineCount) *
                          item.discountPrice +
                        item.medPrice *
                          (item.medQuantity -
                            (item.discountLimits - item.discountMedicineCount));
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    }
                  } else {
                    if (
                      item.discountStock >=
                      item.discountLimits - item.discountMedicineCount
                    ) {
                      item.discountSum =
                        item.discountLimits - item.discountMedicineCount;
                      allmoney =
                        (item.discountLimits - item.discountMedicineCount) *
                          item.discountPrice +
                        item.medPrice *
                          (item.medQuantity -
                            (item.discountLimits - item.discountMedicineCount));
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    } else {
                      item.discountSum = item.discountStock;
                      allmoney =
                        item.discountStock * item.discountPrice +
                        item.medPrice * (item.medQuantity - item.discountStock);
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    }
                  }
                }
                // 折扣
              } else if (item.percentage != -1) {
                item.discountMoney = (item.medPrice * item.percentage) / 10;
                item.discountchaMoney =
                  item.medPrice - (item.medPrice * item.percentage) / 10;
                // 库存不限
                if (item.discountStock == -1) {
                  if (
                    item.discountLimits == -1 ||
                    (item.discountLimits != -1 &&
                      item.discountLimits - item.discountMedicineCount >=
                        item.medQuantity)
                  ) {
                    item.discountSum = item.medQuantity;
                    item.discountoneMoney =
                      (item.medPrice * item.percentage) / 10;
                  } else if (
                    item.discountLimits != -1 &&
                    item.discountLimits - item.discountMedicineCount <
                      item.medQuantity
                  ) {
                    item.discountSum =
                      item.discountLimits - item.discountMedicineCount;
                    allmoney =
                      ((item.discountLimits - item.discountMedicineCount) *
                        item.medPrice *
                        item.percentage) /
                        10 +
                      item.medPrice *
                        (item.medQuantity -
                          (item.discountLimits - item.discountMedicineCount));
                    item.discountoneMoney = Math.round(
                      allmoney / item.medQuantity
                    );
                  }
                  // 限库存
                } else {
                  if (item.discountStock >= item.medQuantity) {
                    if (
                      item.discountLimits == -1 ||
                      (item.discountLimits != -1 &&
                        item.discountLimits - item.discountMedicineCount >=
                          item.medQuantity)
                    ) {
                      item.discountSum = item.medQuantity;
                      item.discountoneMoney =
                        (item.medPrice * item.percentage) / 10;
                    } else if (
                      item.discountLimits != -1 &&
                      item.discountLimits - item.discountMedicineCount <
                        item.medQuantity
                    ) {
                      item.discountSum =
                        item.discountLimits - item.discountMedicineCount;
                      allmoney =
                        ((item.discountLimits - item.discountMedicineCount) *
                          item.medPrice *
                          item.percentage) /
                          10 +
                        item.medPrice *
                          (item.medQuantity -
                            (item.discountLimits - item.discountMedicineCount));
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    }
                  } else {
                    if (
                      item.discountStock >=
                      item.discountLimits - item.discountMedicineCount
                    ) {
                      item.discountSum =
                        item.discountLimits - item.discountMedicineCount;
                      allmoney =
                        ((item.discountLimits - item.discountMedicineCount) *
                          item.medPrice *
                          item.percentage) /
                          10 +
                        item.medPrice *
                          (item.medQuantity -
                            (item.discountLimits - item.discountMedicineCount));
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    } else {
                      item.discountSum = item.discountStock;
                      allmoney =
                        (item.discountStock * item.medPrice * item.percentage) /
                          10 +
                        item.medPrice * (item.medQuantity - item.discountStock);
                      item.discountoneMoney = Math.round(
                        allmoney / item.medQuantity
                      );
                    }
                  }
                }
              }
              if (item.discountSum > 0) {
                array0.push({
                  discountId: item.discountId,
                  discountSum: item.discountSum,
                });
              }
            }
          });
          setdiscountAllId(array0);
          let allmoney2 = 0;
          cartArr[0].medList.map((item) => {
            if (item.discountId) {
              allmoney2 += item.discountchaMoney * item.discountSum;
            }
          });
          setdiscountAllprice(allmoney2);
        });
      }
    }
  }, [cartArr]);
  console.log(discountAllId);
  const submitOrder = async () => {
    if (stockNum.length > 0) {
      setVisible0(true);
    } else {
      setVisible0(false);
      if (discountAllprice > 0) {
        alert("此订单含有特价商品，若下单后取消订单限购数量将不予恢复", "", [
          { text: "取消", onPress: () => console.log(1) },
          {
            text: "继续支付",
            onPress: () => {
              submitOrder0();
            },
          },
        ]);
      } else {
        submitOrder0();
      }
    }
    // shippingType();
  };
  const submitOrder0 = async () => {
    const medList = cartArr[0].medList;
    const cartIdArr = [];
    if (medList[0].cartId) {
      medList.forEach((item) => {
        cartIdArr.push(item.cartId);
      });
    } else {
      cartIdArr.push(0);
    }
    if (pay1 == "钱包支付" && balance == 0) {
      Toast.info("钱包余额为0，请重新选择支付方式");
    } else {
      if (
        orderShipping == "2" &&
        address.distance > 5000 &&
        localStorage.type == "1"
      ) {
        Toast.info("该地址超出配送范围，请重新选择地址");
      } else {
        if (
          !reservedTelephone &&
          orderShipping == "1" &&
          localStorage.type == "1"
        ) {
          Toast.info("自提订单请输入预留电话");
        } else {
          if (
            orderShipping == "1" &&
            localStorage.type == "1" &&
            !/^1[3456789]\d{9}$/.test(reservedTelephone)
          ) {
            Toast.info("请输入正确格式的预留电话");
          } else {
            if (fapiaoName0 || isFapiao == 0 || isInvoice == 2) {
              if (userStatus == 2) {
                const params1 = {
                  userId: userId,
                  orderShipping: orderShipping,
                  province:
                    address && orderShipping === "2" ? address.province : null,
                  city: address && orderShipping === "2" ? address.city : null,
                  district:
                    address && orderShipping === "2" ? address.district : null,
                  address:
                    address && orderShipping === "2" ? address.address : null,
                  addressee:
                    address && orderShipping === "2" ? address.addressee : null,
                  phone:
                    address && orderShipping === "2" ? address.phone0 : null,
                  latitude:
                    address && orderShipping === "2" ? address.latitude : null,
                  longitude:
                    address && orderShipping === "2" ? address.longitude : null,
                  addressId:
                    address && orderShipping === "2" && localStorage.type == "1"
                      ? address.addressId
                      : -1,
                  pharmacyId: cartArr[0].pharmacy.pharmacyId,
                  pharmacyName: cartArr[0].pharmacy.pharmacyName,
                  couponManagerId: couponManagerId,
                  orderMedicineDiscountPrice: discountAllprice,
                  activitiesId: manjianPrice > 0 ? activitiesId : null,
                  couponPrice: maxUsercoupon,
                  activitiesPrice: manjianPrice,
                  orderNumber1: orderNumber1,
                  fapiaoNews: isFapiao && isInvoice == 1 ? fapiaoName0 : 0,
                  orderReceipt: isFapiao && isInvoice == 1 ? 1 : 0,
                  firstReductionAmount: firstReductionAmount,
                  orderIntegralPrice: orderIntegral
                    ? (orderIntegral / exchangePoints) * exchangeAmount
                    : 0,
                  // payMethod: '在线支付',
                  payMethod: localStorage.type == "1" ? "微信支付" : pay1,
                  orderPrice: sumPrice - discountAllprice,
                  orderFreight:
                    localStorage.type == "1"
                      ? orderShipping === "1"
                        ? 0
                        : orderFreight
                      : 0,
                  pharmacyPackingFee: pharmacyPackingFee
                    ? pharmacyPackingFee
                    : 0,
                  orderCoupon: 0,
                  orderIntegral: localStorage.type == "1" ? orderIntegral : 0,
                  orderAmount:
                    localStorage.type == "1"
                      ? orderShipping === "1"
                        ? sumPrice +
                          pharmacyPackingFee -
                          firstReductionAmount -
                          (exchangePoints
                            ? (orderIntegral / exchangePoints) * exchangeAmount
                            : 0) -
                          maxUsercoupon -
                          discountAllprice -
                          manjianPrice
                        : sumPrice -
                          firstReductionAmount -
                          maxUsercoupon -
                          discountAllprice -
                          manjianPrice +
                          orderFreight +
                          pharmacyPackingFee -
                          (exchangePoints
                            ? (orderIntegral / exchangePoints) * exchangeAmount
                            : 0)
                      : sumPrice,
                  order_user_remarks: remark,
                  reservedTelephone:
                    localStorage.type == "1" && reservedTelephone
                      ? reservedTelephone
                      : "",
                  isClassfy: localStorage.type == "1" ? isClassify : "",
                  logistic: logistic,
                  dispatchMode: dispatchMode,
                  invoiceId: invoice1 ? invoice1.invoiceId : null,
                };
                let params2 = { array: [] };
                Toast.loading("请稍等...");
                console.log(cartArr[0]);
                if (cartArr[0].pharmacy.orderNumber) {
                  const params = {
                    orderNumber: cartArr[0].pharmacy.orderNumber,
                    orderShipping: orderShipping,
                    province:
                      address && orderShipping === "2"
                        ? address.province
                        : null,
                    city:
                      address && orderShipping === "2" ? address.city : null,
                    district:
                      address && orderShipping === "2"
                        ? address.district
                        : null,
                    address:
                      address && orderShipping === "2" ? address.address : null,
                    addressee:
                      address && orderShipping === "2"
                        ? address.addressee
                        : null,
                    phone:
                      address && orderShipping === "2" ? address.phone0 : null,
                    latitude:
                      address && orderShipping === "2"
                        ? address.latitude
                        : null,
                    longitude:
                      address && orderShipping === "2"
                        ? address.longitude
                        : null,
                    addressId:
                      address &&
                      orderShipping === "2" &&
                      localStorage.type == "1"
                        ? address.addressId
                        : -1,
                    // payMethod: '在线支付',
                    payMethod: localStorage.type == "1" ? "微信支付" : pay1,
                    orderPrice: sumPrice - discountAllprice,
                    orderNumber1: orderNumber1,
                    fapiaoNews: isFapiao && isInvoice == 1 ? fapiaoName0 : 0,
                    orderReceipt: isFapiao && isInvoice == 1 ? 1 : 0,
                    couponManagerId: couponManagerId,
                    activitiesId: manjianPrice > 0 ? activitiesId : null,
                    couponPrice: maxUsercoupon,
                    orderMedicineDiscountPrice: discountAllprice,
                    activitiesPrice: manjianPrice,
                    firstReductionAmount: firstReductionAmount,
                    orderFreight:
                      localStorage.type == "1"
                        ? orderShipping === "1"
                          ? 0
                          : orderFreight
                        : cartArr[0].pharmacy.freight,
                    orderCoupon: 0,
                    orderIntegralPrice: orderIntegral
                      ? (orderIntegral / exchangePoints) * exchangeAmount
                      : 0,
                    orderIntegral: localStorage.type == "1" ? orderIntegral : 0,
                    orderAmount:
                      localStorage.type == "1"
                        ? orderShipping === "1"
                          ? sumPrice +
                            pharmacyPackingFee -
                            firstReductionAmount -
                            (exchangePoints
                              ? (orderIntegral / exchangePoints) *
                                exchangeAmount
                              : 0) -
                            maxUsercoupon -
                            manjianPrice
                          : sumPrice +
                            orderFreight -
                            firstReductionAmount +
                            pharmacyPackingFee -
                            maxUsercoupon -
                            manjianPrice -
                            (exchangePoints
                              ? (orderIntegral / exchangePoints) *
                                exchangeAmount
                              : 0)
                        : sumPrice,
                    pharmacyPackingFee: pharmacyPackingFee
                      ? pharmacyPackingFee
                      : 0,
                    reservedTelephone:
                      localStorage.type == "1" && reservedTelephone
                        ? reservedTelephone
                        : "",
                    order_user_remarks: remark,
                    invoiceId: invoice1 ? invoice1.invoiceId : null,
                    isClassfy: localStorage.type == "1" ? isClassify : "",
                    logistic: logistic,
                    dispatchMode: dispatchMode,
                    array: [],
                  };
                  const orderName = `${medList[0].medName}等`;
                  medList.forEach((item) => {
                    params.array.push({
                      orderMedId: item.orderMedId,
                      medQuantity: item.medQuantity,
                    });
                  });
                  medList.forEach((item) => {
                    params2.array.push({
                      medId: item.medId,
                      medName: item.medName,
                      medPhoto: item.medPhoto,
                      medSpec: item.medSpec,
                      medPacking: item.medPacking,
                      medApproval: item.medApproval,
                      medCompany: item.medCompany,
                      medPrice: item.discountoneMoney
                        ? item.discountoneMoney
                        : item.medPrice,
                      medQuantity: item.medQuantity,
                      discountId: item.discountId ? item.discountId : 0,
                      orderMedicineDiscount: item.discountSum,
                      medApparatus: item.medApparatus,
                      medValidity: item.medValidity,
                      medEnterprise: item.medEnterprise
                        ? item.medEnterprise
                        : "无",
                    });
                  });
                  const array = [];
                  cartArr[0].medList.forEach((item) => {
                    array.push({
                      stockNum: -item.medQuantity,
                      medId: item.medId,
                    });
                  });
                  params2.array.map((item, index) => {
                    Object.assign(item, array[index]);
                  });
                  let array1 = params2.array;
                  addAll({ ...params1, array: array1, cartId: cartIdArr }).then(
                    (res) => {
                      console.log(res);
                      // Toast.hide()
                      if (res.code === 1) {
                        if (pay1 == "钱包支付") {
                          if (balance == 0) {
                            Toast.info("钱包余额为0，请重新选择支付方式");
                          } else if (sumPrice > balance) {
                            alert(
                              "钱包余额不足？",
                              "选择钱包支付超出部分将使用微信支付",
                              [
                                {
                                  text: "取消",
                                  onPress: () => console.log("cancel"),
                                  style: "default",
                                },
                                {
                                  text: "确认",
                                  onPress: () => {
                                    let params4 = {
                                      userId: userId,
                                      Tag: "药品支出",
                                      Comments: "钱包支付",
                                      amount:
                                        sumPrice > balance ? balance : sumPrice,
                                      status: sumPrice > balance ? 0 : 1,
                                      tradeno: params.orderNumber,
                                    };
                                    consumptionWallet(params4).then((data) => {
                                      //console.log(data);
                                      if (data.code === 1) {
                                        if (sumPrice > balance) {
                                          let b = new Date().getTime();
                                          window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${
                                            params.orderNumber
                                          }&total_fee=${
                                            sumPrice - balance
                                          }&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                                        } else {
                                          props.history.push("/mine/order");
                                        }
                                      }
                                    });
                                  },
                                },
                              ]
                            );
                          } else {
                            let params4 = {
                              userId: userId,
                              Tag: "药品支出",
                              Comments: "钱包支付",
                              amount: sumPrice,
                              status: 1,
                              tradeno: params.orderNumber,
                            };
                            consumptionWallet(params4).then((data) => {
                              if (data.code === 1) {
                                props.history.push("/mine/order");
                              }
                            });
                          }
                        } else {
                          if (pay1 == "微信支付") {
                            let b = new Date().getTime();
                            if (type == "2") {
                              window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${params.orderNumber}&total_fee=${sumPrice}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                            } else if (type == "1") {
                              if (orderIntegral) {
                                integralIsuse({
                                  userIntegralIsuse: orderIntegral,
                                  userId,
                                }).then((res) => {
                                  console.log(res);
                                });
                              }
                              if (discountAllId.length) {
                                // discountAllId.map(item=>{
                                updateDiscountSum({
                                  array: discountAllId,
                                  userId,
                                }).then((res) => {
                                  console.log(res);
                                });

                                // })
                              }
                              console.log(params.orderNumber);
                              if (orderShipping === "1") {
                                window.location.href = `${zfUrl}/route?client_id=${code1}&channel=&client_trade_no=${
                                  params.orderNumber
                                }&total_fee=${Math.round(
                                  sumPrice +
                                    pharmacyPackingFee -
                                    discountAllprice -
                                    firstReductionAmount -
                                    maxUsercoupon -
                                    manjianPrice -
                                    (exchangePoints
                                      ? (orderIntegral / exchangePoints) *
                                        exchangeAmount
                                      : 0)
                                )}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                              } else {
                                window.location.href = `${zfUrl}/route?client_id=${code1}&channel=&client_trade_no=${
                                  params.orderNumber
                                }&total_fee=${Math.round(
                                  sumPrice +
                                    orderFreight +
                                    pharmacyPackingFee -
                                    firstReductionAmount -
                                    discountAllprice -
                                    maxUsercoupon -
                                    manjianPrice -
                                    (exchangePoints
                                      ? (orderIntegral / exchangePoints) *
                                        exchangeAmount
                                      : 0)
                                )}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                              }
                            } else if (type == "3") {
                              window.location.href = `${zfUrl}/route?client_id=${code2}&channel=&client_trade_no=${params.orderNumber}&total_fee=${sumPrice}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                            }
                          } else if (pay1 == "对公转账") {
                            upOrderStuRequest({
                              orderState: "-2",
                              orderNumber: params.orderNumber,
                            }).then((res) => {
                              if (res.code == 1) {
                                props.history.push("/mine/order");
                              }
                            });
                          }
                        }
                      }
                    }
                  );
                } else {
                  medList.forEach((item) => {
                    params2.array.push({
                      medId: item.medId,
                      medName: item.medName,
                      medPhoto: item.medPhoto,
                      medSpec: item.medSpec,
                      medPacking: item.medPacking,
                      medApproval: item.medApproval,
                      medCompany: item.medCompany,
                      medPrice: item.discountoneMoney
                        ? item.discountoneMoney
                        : item.medPrice,
                      medQuantity: item.medQuantity,
                      discountId: item.discountId ? item.discountId : 0,
                      orderMedicineDiscount: item.discountSum,
                      medApparatus: item.medApparatus,
                      medValidity: item.medValidity,
                      medEnterprise: item.medEnterprise
                        ? item.medEnterprise
                        : "无",
                    });
                  });
                  const array = [];
                  cartArr[0].medList.forEach((item) => {
                    array.push({
                      stockNum: -item.medQuantity,
                      medId: item.medId,
                    });
                  });
                  params2.array.map((item, index) => {
                    Object.assign(item, array[index]);
                  });
                  let array1 = params2.array;
                  addAll({ ...params1, array: array1, cartId: cartIdArr })
                    .then((res) => {
                      console.log(res);
                      // Toast.hide()
                      let orderNumber = "";
                      if (orderShipping === "1") {
                        orderNumber = res.data[1][0].orderNumber;
                      } else {
                        orderNumber = res.data[2][0].orderNumber;
                      }
                      const orderName = `${medList[0].medName}等`;
                      if (res.code === 1) {
                        if (pay1 == "钱包支付") {
                          if (balance == 0) {
                            Toast.info("钱包余额为0，请重新选择支付方式");
                          } else if (sumPrice > balance) {
                            alert(
                              "钱包余额不足？",
                              "选择钱包支付超出部分将使用微信支付",
                              [
                                {
                                  text: "取消",
                                  onPress: () => console.log("cancel"),
                                  style: "default",
                                },
                                {
                                  text: "确认",
                                  onPress: () => {
                                    let params4 = {
                                      userId: userId,
                                      Tag: "药品支出",
                                      Comments: "钱包支付",
                                      amount:
                                        sumPrice > balance ? balance : sumPrice,
                                      status: sumPrice > balance ? 0 : 1,
                                      tradeno: orderNumber,
                                    };
                                    consumptionWallet(params4).then((data) => {
                                      if (data.code === 1) {
                                        if (sumPrice > balance) {
                                          let b = new Date().getTime();
                                          window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${
                                            sumPrice - balance
                                          }&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                                        } else {
                                          props.history.push("/mine/order");
                                        }
                                      }
                                    });
                                  },
                                },
                              ]
                            );
                          } else {
                            let params4 = {
                              userId: userId,
                              Tag: "药品支出",
                              Comments: "钱包支付",
                              amount: sumPrice,
                              status: 1,
                              tradeno: orderNumber,
                            };
                            consumptionWallet(params4).then((data) => {
                              if (data.code == 1) {
                                props.history.push("/mine/order");
                              }
                            });
                          }
                        } else {
                          if (pay1 == "微信支付") {
                            let b = new Date().getTime();
                            if (type == "2") {
                              window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${sumPrice}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                            } else if (type == "1") {
                              if (orderIntegral) {
                                integralIsuse({
                                  userIntegralIsuse: orderIntegral,
                                  userId,
                                }).then((res) => {
                                  console.log(res);
                                });
                              }
                              if (discountAllId.length) {
                                // discountAllId.map(item=>{
                                updateDiscountSum({
                                  array: discountAllId,
                                  userId,
                                }).then((res) => {
                                  console.log(res);
                                });

                                // })
                              }
                              console.log(
                                sumPrice +
                                  orderFreight +
                                  pharmacyPackingFee -
                                  firstReductionAmount -
                                  discountAllprice -
                                  maxUsercoupon -
                                  manjianPrice -
                                  (exchangePoints
                                    ? (orderIntegral / exchangePoints) *
                                      exchangeAmount
                                    : 0)
                              );
                              if (orderShipping === "1") {
                                window.location.href = `${zfUrl}/route?client_id=${code1}&channel=&client_trade_no=${orderNumber}&total_fee=${Math.round(
                                  sumPrice +
                                    pharmacyPackingFee -
                                    firstReductionAmount -
                                    discountAllprice -
                                    maxUsercoupon -
                                    manjianPrice -
                                    (exchangePoints
                                      ? (orderIntegral / exchangePoints) *
                                        exchangeAmount
                                      : 0)
                                )}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                              } else {
                                window.location.href = `${zfUrl}/route?client_id=${code1}&channel=&client_trade_no=${orderNumber}&total_fee=${Math.round(
                                  sumPrice +
                                    orderFreight +
                                    pharmacyPackingFee -
                                    firstReductionAmount -
                                    discountAllprice -
                                    maxUsercoupon -
                                    manjianPrice -
                                    (exchangePoints
                                      ? (orderIntegral / exchangePoints) *
                                        exchangeAmount
                                      : 0)
                                )}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                              }
                            } else if (type == "3") {
                              window.location.href = `${zfUrl}/route?client_id=${code2}&channel=&client_trade_no=${orderNumber}&total_fee=${sumPrice}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
                            }
                          } else if (pay1 == "对公转账") {
                            upOrderStuRequest({
                              orderState: "-2",
                              orderNumber: orderNumber,
                            }).then((res) => {
                              if (res.code == 1) {
                                props.history.push("/mine/order");
                              }
                            });
                          }
                        }
                      }
                    })
                    .catch((err) => {
                      Toast.hide();
                    });
                }
              } else {
                Toast.show(
                  "账号已冻结，无法下单，如需继续下单，请联系商家解除冻结"
                );
              }
            } else if (!fapiaoName0 && isInvoice == 1) {
              Toast.info("请填写发票信息");
            }
          }
        }
      }
      // const array=[]
      // cartArr[0].medList.forEach(item=>{
      //     array.push({
      //         stockNum:-(item.medQuantity),
      //         med_id:item.medId
      //     })
      // })
      // // console.log(array);

      // const res=await changenum({
      //     array
      // })
      // console.log(res);
    }
    // shippingType();
  };
  const payWay = (e) => {
    setPay1(e.target.value);
  };
  useEffect(() => {
    if (pay1 == "对公转账") {
      setVisible("block");
    } else {
      setVisible("none");
    }
  }, [pay1]);
  const fapiaoUse = (e) => {
    setFapiao1(e.target.value);
  };
  const fapiaoUse1 = () => {
    localStorage.setItem("cartstatus", "1");
    props.history.push("/mine/storeticket");
  };
  //   选择地址
  const changeaddress = (e, item, index) => {
    console.log(item);
    let medList = allAddress;
    let newCartArr = cartArr;
    medList[index].checked = e.target.checked;
    //console.log(e.target,index,medList[index].checked);
    if (e.target.checked) {
      newCartArr.push({
        medList: [
          {
            address: item.address,
            addressDefault: item.addressDefault,
            addressee: item.addressee,
            phone: item.phone,
            addressId: item.addressId,
            province: item.province,
            district: item.district,
            city: item.city,
            longitude: item.longitude,
            latitude: item.latitude,
            checked: true,
          },
        ],
      });
    } else {
      newCartArr.push({
        medList: [
          {
            address: item.address,
            addressDefault: item.addressDefault,
            addressee: item.addressee,
            phone: item.phone,
            addressId: item.addressId,
            province: item.province,
            district: item.district,
            city: item.city,
            longitude: item.longitude,
            latitude: item.latitude,
            checked: false,
          },
        ],
      });
    }
    let a = newCartArr[newCartArr.length - 1].medList[0];
    setAddress(a);
    let count0 = 0;
    let a0 = "";
    calculatePrice({
      dispatch_mode: dispatchMode,
      logistic: logistic,
      shop_id: cartArr[0].pharmacy.pharmacyId,
      receiver_longitude: a.longitude,
      receiver_latitude: a.latitude,
      receiver_address: a.province + a.city + a.district + a.address,
    }).then((res) => {
      console.log(res);
      if (res.data.detail && res.data.detail.length) {
        if (res.data.detail.length == 1) {
          setorderFreight(res.data.detail[0].amount * 100);
        } else {
          res.data.detail.map((item) => {
            if (item.check) {
              count0++;
              a0 = item;
              console.log(item);
              // setorderFreight(item.amount*100)
            }
          });
          console.log(count0, a0);
          if (count0 == 1) {
            setorderFreight(a0.amount * 100);
          } else {
            setorderFreight(res.data.detail[0].amount * 100);
          }
        }
      } else {
        setorderFreight(0);
      }
    });
    setVisible1("none");
  };
  useEffect(() => {
    if (orderShipping === "2" && address && !orderNumber1) {
      // console.log(sumPrice);
      setOrdernumber().then((res) => {
        // console.log(res);
        if (res.code == 1) {
          getstoreinfo(cartArr[0].pharmacy.pharmacyId).then((res0) => {
            console.log(res0);
            let params = {
              merchant_id: res0.data[0][0].ele_pharmacyid,
              access_token: res0.data[0][0].ele_token,
              partner_order_code: res.data[0][0].orderNumber,
              receiver_address:
                address.city + address.district + address.address, //顾客地址
              receiver_longitude: address.longitude, //收货经度
              receiver_latitude: address.latitude, //收货经度
              goods_total_amount_cent: sumPrice ? sumPrice : 1000, //订单商品总金额
              goods_actual_amount_cent: sumPrice ? sumPrice : 1000, //订单实付总金额
              goods_weight: 1, //货物重量,可默认为0.1
              goods_count: 1, //货物件数
              chain_store_id: cartArr[0].pharmacy.pharmacyId, //门店id
            };
            beforehand(params).then((res1) => {
              console.log(res1);
              if (res.code == 1) {
                payReducedFreight({
                  pharmacyId: cartArr[0].pharmacy.pharmacyId,
                }).then((res2) => {
                  console.log(res2);
                  if (res2.code && res2.data[0].length) {
                    setreducedDetail(res2.data[0][0]);
                  }
                });
              }
            });
          });
        }
        setOrderNumber1(res.data[0][0].orderNumber);
      });
    }
  }, [address]);
  useEffect(() => {
    // 运费活动
    if (reducedDetail && weeks) {
      reducedDetail.reducedCycle = JSON.parse(reducedDetail.reducedCycle);
      reducedDetail.state = false;
      reducedDetail.reducedCycle.map((item) => {
        if (item == weeks) {
          reducedDetail.state = true;
        }
      });
      if (reducedDetail.state) {
        // 立减
        if (reducedDetail.reducedType == 1) {
          setReducedPrice(reducedDetail.reducedPrice);
        } else {
          // 满减
          // 阶梯减
          if (reducedDetail.freightType == 1) {
            reducedDetail.ladderCost = JSON.parse(reducedDetail.ladderCost);
          } else {
            //免配送费
            setorderFreight(0);
          }
        }
      }
    }
  }, [reducedDetail]);
  // console.log(orderNumber1);
  const unableChangeaddress = () => {
    Toast.info("该地址不在配送范围内，请重新选择");
  };
  const handleChange = (val) => {
    setRemark(val);
  };
  const quxiao = () => {
    setVisible1("none");
  };
  const quxiao1 = (e) => {
    e.stopPropagation();
  };
  // 使用积分
  const userJifen = (e, val) => {
    switch (val) {
      // 不使用积分
      case "no":
        setisuseJifen(0);
        setorderIntegral(0);
        setownDyjifen([]);
        break;
      // 使用积分
      case "yes":
        setisuseJifen(1);

        break;
      default:
        break;
    }
  };
  // 选择积分
  const clickJifen = (val) => {
    switch (val) {
      // 不使用积分
      case 1:
        setorderIntegral(exchangePoints);
        setVisible2(false);
        setownDyjifen([]);
        break;
      // 使用积分
      case 2:
        setorderIntegral(exchangePoints * 2);
        setVisible2(false);
        setownDyjifen([]);
        break;
      case 3:
        setorderIntegral(exchangePoints * 3);
        setVisible2(false);
        setownDyjifen([]);
        break;
      case 4:
        if (
          orderIntegral == 0 ||
          orderIntegral == exchangePoints ||
          orderIntegral == exchangePoints * 2 ||
          orderIntegral == exchangePoints * 3
        ) {
          setorderIntegral(exchangePoints * 4);
        }
        console.log(sumPrice);
        if (
          ((userIntegral - userIntegralIsuse) / exchangePoints) *
            exchangeAmount >
          sumPrice / 2
        ) {
          let a = (sumPrice / 2 - exchangeAmount * 3) / 100;
          console.log(parseInt(a));
          let arr = [];
          for (let i = 0; i < parseInt(a); i++) {
            arr.push(i + 4);
          }
          setownDyjifen(arr);
        } else {
          let a =
            (userIntegral - userIntegralIsuse - exchangePoints * 3) /
            exchangePoints;
          console.log(parseInt(a));
          let arr = [];
          for (let i = 0; i < parseInt(a); i++) {
            arr.push(i + 4);
          }
          setownDyjifen(arr);
        }
        break;
      default:
        break;
    }
  };
  // 自定义积分
  const ownDyjf = (e) => {
    console.log(e.target.value * 100);
    setorderIntegral(e.target.value * exchangePoints);
    setVisible2(false);
  };
  // const ownDyjf1=useMemo(() => {
  //     return <span>，抵￥{(orderIntegral/100).toFixed(2)}</span>
  // }, [orderIntegral])

  const submitOrder1 = () => {
    Toast.info(
      `所选商品未达到最低起送价` +
        (minFreight / 100).toFixed(2) +
        `(不包含配送费)，不能进行提交预约`
    );
  };
  // 输入预留电话
  const reservedPhone = (e) => {
    if (
      !isNaN(e.target.value) &&
      e.target.value % 1 == 0 &&
      e.target.value.substr(0, 1) != "-" &&
      e.target.value.substr(0, 1) != "+"
    ) {
      console.log(e.target.value);
      setreservedTelephone(e.target.value);
      if (e.target.value.substr(-1) == ".") {
        setreservedTelephone(
          e.target.value.substr(0, e.target.value.length - 1)
        );
      }
    }
  };
  const payYouhuiquan = () => {
    setVisible3(true);
    setShow(1);
  };
  // 选择优惠券
  const userCoupon = (e, item) => {
    setselectCoupon(item);
    setmaxUsercoupon(item.couponLimit);
    setcouponManagerId(item.couponManagerId);
  };
  const userCoupon1 = (e) => {
    setselectCoupon("");
    setmaxUsercoupon(0);
    setcouponManagerId("");
  };
  const fapiaoName = (e) => {
    console.log(e.target.value);
    setfapiaoName1(e.target.value);
  };
  const sureFapiao = async (e) => {
    if (fapiaoName1) {
      setfapiaoName0(fapiaoName1);
      let params = {
        invoiceDefault: fapiaoDefault ? "1" : "0",
        userId,
        invoiceTitle: fapiaoName1,
      };
      let res = await addInvoice(params);
      console.log(res, "添加发票");
      if (res.code == 1) {
        setisFapiao(1);
        setVisiable5(false);
        setfapiaoDefault(false);
        let res = await allInvoice(userId);
        if (res.data[0].length) {
          setinvoiceList(res.data[0]);
          showTtname(null, res.data[0][0], 0);
        }
      }
    } else {
      Toast.info("请填写抬头名称");
    }
  };
  const toStop = (e) => {
    e.stopPropagation();
  };
  const jifenExchange = () => {
    if (enablePoints) {
      setVisible2(true);
    } else {
      Toast.info("积分兑换暂时无法使用");
    }
  };
  const selectFapiao = async () => {
    let res = await allInvoice(userId);
    console.log(res);
    if (res.data[0].length) {
      setinvoiceList(res.data[0]);
      res.data[0].map((item, index) => {
        if (item.invoiceDefault == 1 && selectOne == -1) {
          setselectOne(index);
        }
      });
    }
    setVisible4(true);
  };
  const noFapiao0 = (e) => {
    console.log(e.target.checked);
    if (isFapiao) {
      setisFapiao(0);
      setselectOne(-1);
      setfapiaoName0("");
    } else {
      getDefaultinvoicenews();
      setisFapiao(1);
    }
  };
  // console.log(Math.floor((userIntegral-userIntegralIsuse)/exchangePoints),Math.floor(sumPrice/2/100)*100,Math.floor((userIntegral-userIntegralIsuse)/exchangePoints)*exchangeAmount);
  const addHead = () => {
    setVisible4(false);
    setVisiable5(true);
  };
  // const useHead=(invoiceId)=>{

  // }
  const showTtname = (e, invoiceId, index) => {
    setselectOne(index);
    if (
      invoiceId &&
      typeof invoiceId == "object" &&
      invoiceId.hasOwnProperty("invoiceId")
    ) {
      setfapiaoName0(invoiceId.invoiceTitle);
    } else {
      setfapiaoName0(invoiceList[index].invoiceTitle);
    }
  };
  console.log(allAddress);
  return loading ? null : (
    <>
      <Head title="填写订单" fillorder={1}></Head>
      <div className="screen fill-screen">
        <div className="adress-switch">
          <div className="flexr1">
            <div
              onClick={() => shippingType1(true)}
              className={orderShipping === "2" ? "adress-checked1" : ""}
            >
              配送服务
            </div>
            <div
              onClick={() => shippingType1(false)}
              className={orderShipping === "1" ? "adress-checked2" : ""}
            >
              自提服务
            </div>
          </div>
        </div>
        {visiable7 ? (
          <div className="del-address-outer">
            <div className="del-address">
              <p className="del-sure">暂无地址</p>
              <p
                style={{
                  textAlign: "center",
                  color: "#888",
                  marginTop: "-15px",
                  fontSize: "14px",
                }}
              >
                可选择自取或添加地址
              </p>
              <p className="del-bottom">
                <span
                  onClick={() => (setVisiable7(false), setorderShipping("1"))}
                >
                  自取
                </span>
                <span onClick={() => onDelete()}>确认</span>
              </p>
            </div>
          </div>
        ) : null}
        {visiable5 ? (
          <div
            className="add-taitou-outer"
            onClick={() => (setVisiable5(false), setfapiaoDefault(false))}
          >
            <div className="add-taitou" onClick={(e) => toStop(e)}>
              <p className="add-taitou-name">新增抬头</p>
              <p className="add-taitou-all">
                <span>抬头类型</span>
                <span className="one">个人</span>
              </p>
              <p className="add-taitou-all">
                <span>抬头名称</span>
                <input
                  type="text"
                  placeholder="填写个人姓名"
                  onChange={(e) => fapiaoName(e)}
                />
              </p>
              <p className="add-taitou-all add-taitou-all1">
                <h1>设为默认</h1>
                <Switch
                  color="#1F87FF"
                  checked={fapiaoDefault}
                  onChange={() => {
                    setfapiaoDefault(!fapiaoDefault);
                  }}
                />
              </p>
              <p className="fapiao-save" onClick={(e) => sureFapiao(e)}>
                保存并使用
              </p>
            </div>
          </div>
        ) : null}
        <div className="set-adress flexr" style={{ marginBottom: "10px" }}>
          {address ? (
            <>
              {orderShipping === "1" ? (
                <div>
                  <div className="no-adress-outer">
                    <div className="no-adress">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#333",
                          lineHeight: "24px",
                        }}
                      >
                        {companyAddress}
                      </p>
                      {/* <p style={{fontSize:'14px',color:'#333',lineHeight:'24px'}}><span style={{fontSize:'14px',fontWeight:'bold'}}>联系电话：</span>{contactPhone}</p> */}
                    </div>
                    {/* <a href={`tel:${contactPhone}`}><img src={phonesj} alt="" /></a> */}
                  </div>
                  <div className="no-adress-own-news">
                    {/* <p>
                                        <span>自取时间</span>
                                        <span>
                                            <input type="text" />
                                            <img src={write} alt="" />
                                        </span>
                                    </p> */}
                    <p>
                      <span>预留信息</span>
                      <span>
                        <input
                          type="tel"
                          value={reservedTelephone}
                          maxLength={11}
                          onChange={(e) => reservedPhone(e)}
                          placeholder="请输入电话"
                        />
                        {/* <img src={write} alt="" /> */}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: "100%" }}
                  onClick={() => setVisible1("block")}
                >
                  <div className="adress-area flexr">
                    {address.addressDefault == "1" ? (
                      <div style={{ backgroundColor: "#1F87FF" }}>默认</div>
                    ) : null}
                    <span>
                      {address.province + address.city + address.district}
                    </span>
                  </div>
                  <span className="adress-street">{address.address}</span>
                  <div className="adress-person-info">
                    <span>{address.addressee}</span>
                    <span>{address.phone}</span>
                  </div>
                  <span
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "30px",
                      fontSize: "28px",
                      color: "#999",
                    }}
                  >
                    {">"}
                  </span>
                </div>
              )}
              {visible1 == "block" ? (
                <div className="all-address-outer" onClick={() => quxiao()}>
                  <div className="all-address1" onClick={(e) => quxiao1(e)}>
                    {allAddress.map((item, index) => (
                      <div key={index}>
                        {item.distance < 5000 ? (
                          <div
                            className="all-address-in"
                            onClick={(v) => changeaddress(v, item, index)}
                          >
                            <input
                              type="radio"
                              name="b"
                              checked={
                                item.addressId == address.addressId
                                  ? "checked"
                                  : ""
                              }
                            />
                            <p className="all-address-phone">
                              <p className="address">{item.address}</p>
                              <p className="name-phone">
                                <span>{item.addressee}</span>
                                <span className="phone">
                                  {item.phone.toString().substr(0, 3)}****
                                  {item.phone.toString().substr(7, 11)}
                                </span>
                              </p>
                            </p>
                          </div>
                        ) : (
                          <div
                            className="all-address-in"
                            style={{ color: "#aaa" }}
                            onClick={() => unableChangeaddress()}
                          >
                            <input type="radio" name="b" disabled />
                            <p className="all-address-phone">
                              <p className="address">{item.address}</p>
                              <p className="name-phone">
                                <span>{item.addressee}</span>
                                <span className="phone">
                                  {item.phone.toString().substr(0, 3)}****
                                  {item.phone.toString().substr(7, 11)}
                                </span>
                              </p>
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <div>
              {orderShipping === "1" ? (
                <>
                  <div className="no-adress-outer">
                    <div className="no-adress">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#333",
                          lineHeight: "24px",
                        }}
                      >
                        {companyAddress}
                      </p>
                      {/* <p style={{fontSize:'14px',color:'#333',lineHeight:'24px'}}><span style={{fontSize:'14px',fontWeight:'bold'}}>联系电话：</span>{contactPhone}</p> */}
                    </div>
                    {/* <a href={`tel:${contactPhone}`}><img src={phonesj} alt="" /></a> */}
                  </div>
                  <div className="no-adress-own-news">
                    {/* <p>
                                    <span>自取时间</span>
                                    <span>
                                        <input type="text" />
                                        <img src={write} alt="" />
                                    </span>
                                </p> */}
                    <p>
                      <span>预留信息</span>
                      <span>
                        <input
                          type="tel"
                          value={reservedTelephone}
                          maxLength={11}
                          onChange={(e) => reservedPhone(e)}
                          placeholder="请输入电话"
                        />
                        {/* <img src={write} alt="" /> */}
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                <div>暂无地址</div>
              )}
            </div>
          )}
        </div>
        {/* <WhiteSpace></WhiteSpace> */}

        <WhiteSpace></WhiteSpace>
        {/* {cartArr.map((item, i) => { */}
        {/* return ( */}
        <div
          className="cart-part fill-order"
          style={{
            margin: 0,
            borderRadius: 0,
            background: "white",
            marginBottom: "10px",
          }}
        >
          <p>{cartArr[0].pharmacy.pharmacyName}</p>
          {cartArr[0].medList.map((v, index) => {
            return (
              <div key={index} className="fill-order-med">
                <div className="cart-med flex-row">
                  <div className="flex-row">
                    <img
                      className="fill-order-img"
                      src={
                        v.medClassify === "1"
                          ? zhezaho
                          : v.medPhoto
                          ? v.medPhoto
                          : defaultImg
                      }
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImg;
                      }}
                    />
                    <div className="cart-med-detail">
                      <div className="fill-order-name1 flexc">
                        <p>
                          {v.discountId ? (
                            <span
                              style={{
                                color: "#fff",
                                backgroundColor: "#ff0000",
                                borderRadius: "3px",
                                marginRight: "2px",
                                padding: "0px 2px",
                              }}
                            >
                              折
                            </span>
                          ) : null}
                          {v.medName}
                        </p>
                        {v.discountId ? (
                          <div style={{ color: "#999" }}>
                            含{v.discountSum}份折扣商品
                          </div>
                        ) : null}
                        <span>{v.medSpec}</span>
                      </div>
                    </div>
                  </div>
                  <p style={{ display: "flex", flexDirection: "column" }}>
                    {v.discountId && v.discountSum > 0 ? (
                      <p className="money">
                        <span className="origin-price">
                          ￥{v.medPrice / 100}
                        </span>{" "}
                        <span>￥{(v.discountMoney / 100).toFixed(2)}</span>
                      </p>
                    ) : (
                      <p className="money">￥{v.medPrice / 100}</p>
                    )}
                    <span className="count">x{v.medQuantity}</span>
                  </p>
                  {/* <Stepper
                                                style={{
                                                    '--border-inner': 'none',
                                                    '--border':"none",
                                                    '--button-background-color': '#F8F9FA',
                                                    '--height': '30px',
                                                    '--input-width': '40px',
                                                    '--active-border': '1px solid #1677ff',
                                                    '--button-text-color': type == "2" ? "red" : "blue"
                                                }}
                                                showNumber={true}
                                                min={1}
                                                defaultValue={v.medQuantity}
                                                onChange={(value) => { quantity(index, value) }} 
                                                /> */}
                </div>
                {/* {index !== cartArr[0].medList.length - 1 ?
                                            <div style={{
                                                height: '1px',
                                                width: '100%',
                                                backgroundColor: '#DDDFE5'
                                            }}></div>
                                            : null} */}
              </div>
            );
          })}
          <div
            style={{
              height: "1px",
              width: "92%",
              marginLeft: "4%",
              backgroundColor: "#eee",
            }}
          ></div>
          {orderShipping === "1" || address === null ? null : (
            // <Item className='price-red'
            //     extra={`+ ¥${posProvince === address.province ? cartArr[0].pharmacy.freight / 100 : (cartArr[0].pharmacy.freight + 6) / 100}`}>运费</Item>
            <Item
              extra={`+ ¥${
                orderShipping === "2" && orderFreight > 0
                  ? (orderFreight / 100).toFixed(2)
                  : "0.00"
              }`}
            >
              配送费
            </Item>
          )}
          <Item extra={`+ ¥${(pharmacyPackingFee / 100).toFixed(2)}`}>
            打包费
          </Item>
          <Item
            className="price-red"
            extra={`- ¥${(maxUsercoupon / 100).toFixed(2)}`}
            arrow="horizontal"
            onClick={() => payYouhuiquan()}
          >
            优惠券
          </Item>
          <Item
            className="price-red"
            extra={`- ¥${(manjianPrice / 100).toFixed(2)}`}
          >
            店铺满减
          </Item>
          <Item
            className="price-red"
            extra={`- ¥${(discountAllprice / 100).toFixed(2)}`}
          >
            折扣优惠
          </Item>
          {firstReductionAmount ? (
            <Item
              className="price-red"
              extra={`- ¥${(firstReductionAmount / 100).toFixed(2)}`}
            >
              新客立减
            </Item>
          ) : null}
          <Item
            extra={
              orderIntegral == 0
                ? "不使用"
                : orderIntegral +
                  "积分" +
                  ",抵" +
                  orderIntegral / exchangePoints +
                  "元"
            }
            arrow="horizontal"
            onClick={() => jifenExchange()}
          >
            积分
          </Item>
          <WhiteSpace></WhiteSpace>
          {isInvoice == 1 ? (
            <Item
              extra={isFapiao == 0 ? "不需要发票" : fapiaoName0}
              arrow="horizontal"
              onClick={() => selectFapiao()}
            >
              发票
            </Item>
          ) : (
            <Item extra={"该店不支持线上开票"}>发票</Item>
          )}
          {/* <div className='pifa-fapiao'>
                                <p>发票</p>
                                <select value={fapiao1} onChange={(e)=>fapiaoUse(e)}>
                                    {fapiao.map((item,index)=>{
                                        return(
                                            <option key={index}>{item}</option>
                                        )
                                    })}
                                </select>
                            </div> */}
          <Item extra={"微信支付"}>支付方式</Item>
          <InputItem
            placeholder="请填写备注信息"
            onChange={(v) => handleChange(v)}
          >
            订单备注
          </InputItem>
          {/* <Item extra={`¥${sumPrice / 100}`}>商品金额</Item> */}
        </div>
        {/* )
                {/* })} */}
        {/* <Item extra='无' onClick={() => { }}>优惠券</Item>
                <Item extra='未使用' arrow='horizontal' onClick={() => { }}>积分</Item>
                <WhiteSpace></WhiteSpace>
                <Item extra={`¥${sumPrice / 100}`}>商品金额</Item>
                {orderShipping === '1' || address === null ? null :
                    <Item className='price-red'
                        extra={`+ ¥${posProvince === address.province ? cartArr[0].pharmacy.freight / 100 : (cartArr[0].pharmacy.freight + 6) / 100}`}>运费</Item>
                }
                <Item className='price-red' extra={'- ¥0'}>优惠券</Item>
                <Item extra={<Switch />}>电子发票</Item> */}
      </div>
      <div className="fillorder-warn">发货后非质量问题不予退货</div>
      {orderFreight < 0 && orderShipping === "2" ? (
        <>
          {minFreight <= sumPrice &&
          sumPrice +
            pharmacyPackingFee -
            maxUsercoupon -
            firstReductionAmount -
            discountAllprice -
            manjianPrice -
            (orderIntegral / exchangePoints) * exchangeAmount >
            0 ? (
            <div className="tijiao-bottom">
              {orderShipping === "1" ? (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      maxUsercoupon -
                      discountAllprice -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              ) : (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      maxUsercoupon -
                      discountAllprice -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              )}
              <button style={{ lightingColor: "#ccc" }}>提交预约</button>
            </div>
          ) : (
            <div className="tijiao-bottom">
              {orderShipping === "1" ? (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      discountAllprice -
                      maxUsercoupon -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              ) : (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      discountAllprice -
                      firstReductionAmount -
                      manjianPrice -
                      maxUsercoupon -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              )}
              <button style={{ lightingColor: "#ccc" }}>提交预约</button>
            </div>
          )}
        </>
      ) : (
        <>
          {minFreight <= sumPrice &&
          sumPrice +
            pharmacyPackingFee -
            maxUsercoupon -
            firstReductionAmount -
            discountAllprice -
            manjianPrice -
            (exchangePoints
              ? (orderIntegral / exchangePoints) * exchangeAmount
              : 0) >
            0 ? (
            <StepButon name="提交预约" onclick={submitOrder}>
              {orderShipping === "1" ? (
                <span>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      maxUsercoupon -
                      discountAllprice -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </span>
              ) : (
                <span>
                  合计：￥
                  {(
                    (sumPrice +
                      orderFreight +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      maxUsercoupon -
                      discountAllprice -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </span>
              )}
            </StepButon>
          ) : (
            <div className="tijiao-bottom">
              {orderShipping === "1" ? (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      pharmacyPackingFee -
                      firstReductionAmount -
                      discountAllprice -
                      maxUsercoupon -
                      manjianPrice -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              ) : (
                <p>
                  合计：￥
                  {(
                    (sumPrice +
                      orderFreight +
                      pharmacyPackingFee -
                      discountAllprice -
                      firstReductionAmount -
                      manjianPrice -
                      maxUsercoupon -
                      (exchangePoints
                        ? (orderIntegral / exchangePoints) * exchangeAmount
                        : 0)) /
                    100
                  ).toFixed(2)}
                </p>
              )}
              <button onClick={submitOrder1}>提交预约</button>
            </div>
          )}
        </>
      )}

      {visible0 ? (
        <div className="outer-kc">
          {stockNum.map((item) => {
            return <p>{item.name + "库存仅剩" + item.count}</p>;
          })}
          <button
            onClick={() => {
              setVisible0(false);
            }}
          >
            确认
          </button>
        </div>
      ) : null}
      {/* <Modal
                visible={visible0}
                content={stockNum.map(item=>{
                    return(
                        <p>{item.name+'库存仅剩'+item.count}</p>
                    )
                })}
                closeOnAction
                onClose={() => {
                setVisible0(false)
                }}
                actions={[
                {
                    key: 'confirm',
                    text: '确认',
                },
                ]}
            /> */}
      <Popup
        visible={visible2}
        onMaskClick={() => {
          setVisible2(false);
        }}
        bodyStyle={{
          minHeight: "50vh",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <div className="P-body2">
          <div className="P-body2-title">
            <p>
              积分<span>(可用积分：{userIntegral - userIntegralIsuse})</span>
            </p>
            <img src={close} alt="" onClick={() => setVisible2(false)} />
          </div>
          <div className="own-ready">
            <span>不使用</span>
            <input
              type="radio"
              name="jifen"
              checked={isuseJifen == 0 ? "checked" : ""}
              onClick={(e) => userJifen(e, "no")}
            />
          </div>
          <div className="own-ready">
            <span>使用积分</span>
            {userIntegral - userIntegralIsuse >= exchangePoints ? (
              <input
                type="radio"
                onClick={(e) => userJifen(e, "yes")}
                name="jifen"
              />
            ) : (
              <input type="radio" name="jifen" disabled />
            )}
          </div>
          {userIntegral - userIntegralIsuse >= exchangePoints ? (
            <div>
              {isuseJifen == 1 ? (
                <div className="write-jifen1">
                  {userIntegral - userIntegralIsuse >= exchangePoints &&
                  sumPrice / 2 >= exchangeAmount ? (
                    <p>
                      <span>
                        使用 {exchangePoints} 积分，抵￥
                        {(exchangeAmount / 100).toFixed(2)}
                      </span>
                      <input
                        type="radio"
                        name="usejifen"
                        onClick={() => clickJifen(1)}
                      />
                    </p>
                  ) : (
                    <p>
                      <span>
                        使用 {exchangePoints} 积分，抵￥
                        {(exchangeAmount / 100).toFixed(2)}
                      </span>
                      <input type="radio" disabled />
                    </p>
                  )}
                  {userIntegral - userIntegralIsuse >= exchangePoints * 2 &&
                  sumPrice / 2 >= exchangeAmount * 2 ? (
                    <p>
                      <span>
                        使用 {exchangePoints * 2} 积分，抵￥
                        {((exchangeAmount / 100) * 2).toFixed(2)}
                      </span>
                      <input
                        type="radio"
                        name="usejifen"
                        onClick={() => clickJifen(2)}
                      />
                    </p>
                  ) : (
                    <p>
                      <span>
                        使用 {exchangePoints * 2} 积分，抵￥
                        {((exchangeAmount / 100) * 2).toFixed(2)}
                      </span>
                      <input type="radio" disabled />
                    </p>
                  )}
                  {userIntegral - userIntegralIsuse >= exchangePoints * 3 &&
                  sumPrice / 2 >= exchangeAmount * 3 ? (
                    <p>
                      <span>
                        使用 {exchangePoints * 3} 积分，抵￥
                        {((exchangeAmount / 100) * 3).toFixed(2)}
                      </span>
                      <input
                        type="radio"
                        name="usejifen"
                        onClick={() => clickJifen(3)}
                      />
                    </p>
                  ) : (
                    <p>
                      <span>
                        使用 {exchangePoints * 3} 积分，抵￥
                        {((exchangeAmount / 100) * 3).toFixed(2)}
                      </span>
                      <input type="radio" disabled />
                    </p>
                  )}
                  {userIntegral - userIntegralIsuse >= exchangePoints * 4 &&
                  sumPrice / 2 >= exchangeAmount * 4 ? (
                    <p>
                      <span>
                        自定义积分
                        {ownDyjifen.length ? (
                          <>
                            <select name="" onChange={(e) => ownDyjf(e)}>
                              {ownDyjifen.map((item, index) => (
                                <option value={item}>
                                  {item * exchangePoints}
                                </option>
                              ))}
                            </select>
                            {/* {ownDyjf1} */}
                            {orderIntegral > exchangePoints * 3 ? (
                              <span>
                                ，抵￥
                                {(
                                  ((orderIntegral / exchangePoints) *
                                    exchangeAmount) /
                                  100
                                ).toFixed(2)}
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <select name="" id="" disabled="disabled">
                            <option value="">选择积分</option>
                          </select>
                        )}
                      </span>
                      <input
                        type="radio"
                        name="usejifen"
                        onClick={() => clickJifen(4)}
                      />
                    </p>
                  ) : (
                    <p>
                      <span>
                        自定义积分
                        <select name="" id="" disabled="disabled">
                          <option value="">选择积分</option>
                        </select>
                      </span>
                      <input type="radio" disabled />
                    </p>
                  )}

                  {/*{userIntegral-userIntegralIsuse>=exchangePoints&&(sumPrice/2)<exchangeAmount*2&&(sumPrice/2)>=exchangeAmount?<div className='can-use'>本单您可以使用{exchangePoints}积分</div>:*/}
                  {/*userIntegral-userIntegralIsuse>=exchangePoints*2&&(sumPrice/2)<exchangeAmount*3&&(sumPrice/2)>=exchangeAmount*2?<div className='can-use'>本单您可以使用{exchangePoints}~{exchangePoints*2}积分</div>:*/}
                  {/*userIntegral-userIntegralIsuse>=exchangePoints*3&&(sumPrice/2)>=exchangeAmount*3?<div className='can-use'>本单您可以使用{exchangePoints}~{Math.floor(sumPrice/2/100)*100>=Math.floor((userIntegral-userIntegralIsuse)/exchangePoints)*exchangeAmount?*/}
                  {/*    Math.floor((userIntegral-userIntegralIsuse)/100)*100:Math.floor(sumPrice/2/100)*100/exchangeAmount*exchangePoints}积分</div>:null}*/}
                  <div className="can-use1">
                    使用积分规则：积分大于{exchangePoints}
                    ，并且积分抵扣价格必须小于等于商品总价的50%
                  </div>
                </div>
              ) : (
                <>
                  {/*{userIntegral-userIntegralIsuse>=exchangePoints&&(sumPrice/2)<exchangeAmount*2&&(sumPrice/2)>=exchangeAmount?<div className='can-use'>本单您可以使用{exchangePoints}积分</div>:*/}
                  {/*userIntegral-userIntegralIsuse>=exchangePoints*2&&(sumPrice/2)<exchangeAmount*3&&(sumPrice/2)>=exchangeAmount*2?<div className='can-use'>本单您可以使用{exchangePoints}~{exchangePoints*2}积分</div>:*/}
                  {/*userIntegral-userIntegralIsuse>=exchangePoints*3&&(sumPrice/2)>=exchangeAmount*3?<div className='can-use'>本单您可以使用{exchangePoints}~{Math.floor(sumPrice/2/100)*100>=Math.floor((userIntegral-userIntegralIsuse)/exchangePoints)*exchangeAmount?*/}
                  {/*    Math.floor((userIntegral-userIntegralIsuse)/100)*100:Math.floor(sumPrice/2/100)*100/exchangeAmount*exchangePoints}积分</div>:null}*/}
                  {/*<div className='can-use1'>使用积分规则：积分大于{exchangePoints}，并且积分抵扣价格必须小于等于商品总价的50%</div>*/}
                </>
              )}
            </div>
          ) : (
            <div>
              <div className="write-jifen">
                每单最低使用{exchangePoints}积分，{exchangePoints}积分可抵￥
                {(exchangeAmount / 100).toFixed(2)}
              </div>
              <div className="can-use">本单您可以使用0积分</div>
            </div>
          )}
        </div>
      </Popup>
      <Popup
        visible={visible3}
        onMaskClick={() => {
          setVisible3(false);
        }}
        bodyStyle={{
          minHeight: "60vh",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          overflow: "scroll",
        }}
      >
        <div className="coupon-all">
          <div className="coupons-title">
            <p>
              可用优惠券
              <span>
                (共{couponDetails.length + usercouponDetails.length}张)
              </span>
            </p>
            <img src={close} alt="" onClick={() => setVisible3(false)} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0",
            }}
          >
            <p>不使用优惠卷</p>
            <input
              type="radio"
              name="coupon"
              checked={maxUsercoupon == 0 && !couponManagerId ? "checked" : ""}
              onChange={(e) => userCoupon1(e)}
            />
          </div>
          {couponDetails.length
            ? couponDetails.map((item) => (
                <div className="coupons-body">
                  <div className="coupons-body-top">
                    <img src={coupon} alt="" />
                    <p className="coupons-body-center">
                      <h2>店铺优惠券</h2>
                      <p>有效期至{item.couponExpire}</p>
                    </p>
                    <p className="coupons-body-right">
                      <p className="coupons-body-minus">
                        ￥<span>{item.couponLimit / 100}</span>
                      </p>
                      <p className="coupons-body-full">
                        满{item.couponMoney / 100}可用
                      </p>
                    </p>
                    <input
                      type="radio"
                      name="coupon"
                      checked={
                        maxUsercoupon == item.couponLimit &&
                        item.couponManagerId == couponManagerId
                          ? "checked"
                          : ""
                      }
                      onChange={(e) => userCoupon(e, item)}
                    />
                  </div>
                  <div className="coupons-body-bottom">
                    规则说明：店铺内所有结算商品的总金额达到
                    {item.couponMoney / 100}即可使用
                  </div>
                </div>
              ))
            : null}
          {usercouponDetails.length
            ? usercouponDetails.map((item) => (
                <div className="coupons-body">
                  <div className="coupons-body-top">
                    <img src={coupon} alt="" />
                    <p className="coupons-body-center">
                      <h2>部分商品优惠券</h2>
                      <p>有效期至{item.couponExpire}</p>
                    </p>
                    <p className="coupons-body-right">
                      <p className="coupons-body-minus">
                        ￥<span>{item.couponLimit / 100}</span>
                      </p>
                      <p className="coupons-body-full">
                        满{item.couponMoney / 100}可用
                      </p>
                    </p>
                    <input
                      type="radio"
                      name="coupon"
                      checked={
                        maxUsercoupon == item.couponLimit ? "checked" : ""
                      }
                      onChange={(e) => userCoupon(e, item)}
                    />
                  </div>
                  <div className="coupons-body-bottom">
                    规则说明：店铺可使用此优惠券商品的总金额达到
                    {item.couponMoney / 100}即可使用
                  </div>
                </div>
              ))
            : null}
        </div>
      </Popup>
      <Popup
        visible={visible4}
        onMaskClick={() => {
          setVisible4(false);
        }}
        bodyStyle={{
          minHeight: "68vh",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          overflow: "scroll",
        }}
      >
        <div className="fapiao-all">
          <div className="fapiao-title">
            <p>填写电子发票信息</p>
            <img src={close} alt="" onClick={() => setVisible4(false)} />
          </div>
          <div className="no-fapiao">
            <input
              type="radio"
              name="fp"
              checked={isFapiao == 0 ? "checked" : ""}
              onClick={(e) => noFapiao0(e)}
            />
            <p>不需要发票</p>
          </div>
          <div className="no-fapiao">
            <input
              type="radio"
              name="fp"
              checked={isFapiao == 1 ? "checked" : ""}
              onClick={(e) => noFapiao0(e)}
            />
            <p>需要发票</p>
          </div>
          <div className="myheaad-invoices">
            <p onClick={() => addHead()}>
              <span>+</span> 添加抬头
            </p>
            {invoiceList
              ? invoiceList.map((item, index) => (
                  <p>
                    <p className="myheaad-invoices-user">
                      <span>{item.invoiceTitle}</span>
                      {item.invoiceDefault == 1 ? (
                        <span className="myheaad-invoices-user-moren">
                          默认
                        </span>
                      ) : null}
                    </p>
                    {/* onClick={()=>useHead(item.invoiceId)} */}
                    {isFapiao ? (
                      <input
                        type="radio"
                        name="ttname"
                        checked={index == selectOne ? "checked" : ""}
                        onClick={(e) => showTtname(e, item.invoiceId, index)}
                      />
                    ) : (
                      <input
                        type="radio"
                        name="ttname"
                        checked={index == selectOne ? "checked" : ""}
                        disabled
                      />
                    )}
                  </p>
                ))
              : null}
          </div>
          {/* <p className='fapiao-cu'>发票类型</p>
                    <span className='fapiao-blue'>电子普通发票</span>
                    <p className='fapiao-shuoming'>电子普通发票，与纸质发票具备同等法律效力，订单完成后24小时内在“我的订单”查看。</p>
                    <p className='fapiao-cu'>发票抬头</p>
                    <span className='fapiao-blue'>个人</span>
                    <p className='fapiao-head'>
                        <p><span>*</span>抬头名称</p>
                        <input type="text" placeholder='请填写个人姓名' value={fapiaoName0} onChange={(e)=>fapiaoName(e)}/>
                    </p>
                    <p className='fapiao-cu'>发票内容</p>
                    <span className='fapiao-blue'>商品明细</span>
                    <p className='fapiao-shuoming'>发票内容将显示详细商品名称与价格信息，发票金额为实际支付金额，不含虚拟资产、优惠券等扣减金额。</p>
                    <p className='fapiao-head'>
                        <h1>设为默认</h1>
                        <Switch color='#1F87FF'
                            checked={fapiaoDefault}
                            onChange={() => {
                                setfapiaoDefault(!fapiaoDefault)
                            }} />
                    </p>
                    <p className='fapiao-save'onClick={() => sureFapiao()}>保存</p> */}
        </div>
      </Popup>
    </>
  );
}

export default withRouter(Fillorder);
