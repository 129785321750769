
import React, { Component } from 'react'
import { Toast ,Button,InputItem} from "antd-mobile"
import {changePwdRequest} from "../../api/request/user"
import "./retrieve.less"
// import { List, InputItem } from 'antd-mobile';
export default class retrieve extends Component {
    constructor() {
        super();
        this.state = {
            phone: localStorage.getItem("phone"),
            value: "",
            newvalue: "",
            hasError: false,
            hasError2: false,
            pharmacyId:localStorage.getItem("pharmacyId")=="25"?"0":localStorage.getItem("pharmacyId")
        }
    }
    
    // 提交
    prepwd=async() =>{
        if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(this.state.value)) || !(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(this.state.value))) {
            Toast.fail("请输入6~18位由数字和字母组成的密码")
        } else {
            if (this.state.value != this.state.newvalue) {
                Toast.fail("两次密码不同请重新输入")
            } else {
                const res = await changePwdRequest({
                    userPwd: this.state.value,
                    userAcount: this.state.phone,
                    pharmacyId: this.state.pharmacyId
                })
                Toast.success("修改成功")
                this.props.history.push("/login")
            }
        }
    }
   
    // 错误反馈
    onErrorClick = () => {
        if (this.state.hasError) {
          Toast.info("请输入6~18位由数字和字母组成的密码");
        }
      }
      onErrorClick2 = () => {
        if (this.state.hasError2) {
          Toast.info("两次密码不相同");
        }
      }
    //   判断输入的格式
      onChange = (value) => {
        if (value.replace(/\s/g).length > 18||value.replace(/\s/g, '').length<8) {
          this.setState({
            hasError: true,
          });
        } else {
          this.setState({
            hasError: false,
          });
        }
        if (value.length<19){
            this.setState({
                value,
            });
        }
      }
      onChange2 = (newvalue) => {
          if (newvalue.length<19) {
              this.setState({
                  newvalue,
              }, () => {
                  if (this.state.value != this.state.newvalue) {
                      this.setState({
                          hasError2: true,
                      });
                  } else {
                      this.setState({
                          hasError2: false,
                      });
                  }
              })
          }
      }
      // 返回上一个界面
      back=()=>{
        window.history.back()
      }
    render() {
      const {value,newvalue} =this.state
        return (
            <div className="box2">
                <div className="top1">
                    <button  onClick={this.back}></button>                 设置新密码
                </div>
                <p className="topp">请为你的账号+86 {this.state.phone} 设置一个新密码</p>
                <InputItem
                    type="password"
                    placeholder="请输入6~18位密码"
                    style={{fontSize:'16px'}}
                    error={this.state.hasError}
                    onErrorClick={this.onErrorClick}
                    onChange={this.onChange}
                    value={this.state.value}
                ></InputItem>
                 <InputItem
                    type="password"
                    placeholder="再输入一次密码"
                    style={{fontSize:'16px'}}
                    error={this.state.hasError2}
                    onErrorClick={this.onErrorClick2}
                    onChange={this.onChange2}
                    value={this.state.newvalue}
                ></InputItem>
                <Button onClick={this.prepwd}  className={value.length>0||newvalue.length>0?"btn2":"btn"}><p style={{fontSize:"16px",lineHeight:"45px"}}>保存密码</p></Button>
            </div>
        )
    }
}

