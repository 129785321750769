import React, { Component } from 'react'

import Head from '../../../component/Head'
import Nodata from '../../../component/Nodata'
import xt from '../../../static/img/xt.png'
import yh from '../../../static/img/yh.png'
import dd from '../../../static/img/dd.png'
import icon1 from "../../../static/img/fanhuihei.png"
import { delMessage, twoMessage } from '../../../api/request/msg'
import getUrl from '../../../component/GetUrlParam'
import './style.less'
import JRoll from 'jroll';
export default class preferentialPromotion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            visiable: 'none',
            messageList: '',
            mine:'',
        }
        // this.deleteOne = this.deleteOne.bind(this);
    }
    // 删除
    deleteOne = async (e,id) => {
        e.stopPropagation()
        console.log(this.state.messageList,id)
        let res0 = await delMessage(this.state.userId, id)
        console.log(res0);
        if (res0.code == 1) {
            // let res=await twoMessage({ userId: this.state.userId, type2: 1 })
            //     console.log(res);
            //     if (res.data[0].length) {
            //         this.setState({
            //             messageList: res.data[0]
            //         }, () => {
            //         })
            //     }else{
            //         this.setState({
            //             messageList: []
            //         })
            //     }
            window.location.reload()
        }
    }
    componentDidMount() {
        const { userId ,item0} = this.state
        this.setState({
            mine:getUrl('mine', this.props.location.search)
        })
        twoMessage({ userId, type2: 1 }).then(res => {
            console.log(res);
            if (res.data[0].length) {
                this.setState({
                    messageList: res.data[0]
                }, () => {
                    // this.onScoll()
                })
            }
        })
    }
    // onScoll = () => {
    //     const { userId ,item0} = this.state
    //     var w = Math.floor(document.getElementsByClassName('del')[0].offsetWidth / 2);
    //     console.log(w);//‘删除’按钮的一般宽度。
    //     // 创建外层jroll实例
    //     var jroll = new JRoll("#wrapper", {
    //         // scrollBarY: true,
    //         autoStyle:false,
    //         edgeRelease:false
    //     });
    //
    //     var items = document.querySelectorAll(".item");
    //     var current = null; //保存当前滑开的item
    //     for (var i = 0, l = items.length; i < l; i++) {
    //         // 每行创建jroll实例
    //         var j = new JRoll(items[i], {
    //             scrollX: true,
    //             bounce: false
    //         });
    //
    //         j.on("scrollStart", function () {
    //             if (current && current !== this) {
    //                 current.scrollTo(0, 0, 100);
    //                 current = null;
    //             }
    //         });
    //
    //         j.on("scroll", function (e) {
    //             if (this.x === 0 && !current) {
    //                 this.call(jroll, e);
    //             } else {
    //                 current = this;
    //             }
    //         });
    //
    //         j.on("scrollEnd", function () {
    //             if (this.x > -w) {
    //                 this.scrollTo(0, 0, 100);
    //                 current = null;
    //             } else {
    //                 this.scrollTo(this.maxScrollX, 0, 100);
    //             }
    //         })
    //     };
    // }
    toActpage = (activitiesType) => {
        const { mine } = this.state
        if (activitiesType == 1) {
            this.props.history.replace(`/preferentialPromotion?mine=${mine}`)
            this.props.history.push(`/actpage2?mine=${mine}`)
        } else if (activitiesType == 2) {
            this.props.history.replace(`/preferentialPromotion?mine=${mine}`)
            this.props.history.push(`/actpage1?mine=${mine}`)
        }
    }
    render() {
        const { messageList,mine } = this.state
        return (
            <p style={{backgroundColor:'#fff'}}>
                <div className='preferentialPromotion-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push(`/tidings?mine=${mine}`)} />   优惠促销
                </div>
                {/*<div id="wrapper">*/}
                {/*    <div className="scroller">*/}
                {/*        <div id="valid">*/}
                {/*            {messageList ? messageList.map((item, index) => (<>*/}
                {/*                <div key={index} className="item" onClick={() => this.toActpage(item.activitiesType)}>*/}
                {/*                    <div className="item-scroller">*/}
                {/*                        <div className='content'>*/}
                {/*                            <p className='preferentialPromotion-outer-bottom'>*/}
                {/*                                <p className='preferentialPromotion-outer-bottom-top '>*/}
                {/*                                    <span>{item.messageTitle}</span>*/}
                {/*                                    <span>{item.messageTime1}</span>*/}
                {/*                                </p>*/}
                {/*                                <p>{item.messageContent} {'>'}{'>'}</p>*/}
                {/*                            </p>*/}
                {/*                        </div>*/}
                {/*                        <div className="del" onClick={(e) => this.deleteOne(e,item.id)}>删除</div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </>)) :*/}
                {/*                <Nodata type='消息' />*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}
                <p className='scroller'>
                    {messageList ? messageList.map((item, index) => (<>
                            <div key={index} className="item" onClick={() => this.toActpage(item.activitiesType)}>
                                <p className='preferentialPromotion-outer-bottom'>
                                    <p className='preferentialPromotion-outer-bottom-top '>
                                        <span>{item.messageTitle}</span>
                                        <span>{item.messageTime1}</span>
                                    </p>
                                    <p>{item.messageContent} {'>'}{'>'}</p>
                                </p>
                            </div>
                        </>)) :
                        <Nodata type='消息' />
                    }
                </p>
            </p>
        )
    }

}