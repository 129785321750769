import React, { Component } from "react";
import { Modal, Toast } from "antd-mobile-v5";
import "./style.less";
import icon1 from "../../../static/img/fanhuihei.png";
import yyzz from "../../../static/img/qualification/yyzz.png";
import ylqxpz from "../../../static/img/qualification/ylqxpz.png";
import ypfwzs from "../../../static/img/qualification/ypfwzs.png";
import zzdxyw from "../../../static/img/qualification/zzdxyw.png";
import { ErrorBlock, ImageViewer } from "antd-mobile-v5";
import {
  docterlist,
  getGzdoc,
  patientinfo,
} from "../../../api/request/wzpatient";
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      patientId: "",
      docList: [],
      list: [],
      image: {
        url: "",
        visible: false,
      },
    };
  }
  componentDidMount = () => {};
  render() {
    const { list, image } = this.state;
    return (
      <div className="setbox1">
        <div className="box-top1">
          <img
            src={icon1}
            alt=""
            onClick={() => this.props.history.push("/mine")}
          />{" "}
          平台资质
        </div>
        <div className="qual-cont">
          <div className="qual-info">
            <p>营业执照</p>
            <span>资质编号：91510100MA69E7JY02</span>
            <img
              src={yyzz}
              onClick={() => {
                this.setState({
                  image: {
                    url: yyzz,
                    visible: true,
                  },
                });
              }}
            />
          </div>
          <div className="qual-info">
            <p>医疗器械网络交易服务第三方平台备案凭证</p>
            <span>备案编号：(川)网械平台备字(2022)第00004号</span>
            <img
              src={ylqxpz}
              onClick={() => {
                this.setState({
                  image: {
                    url: ylqxpz,
                    visible: true,
                  },
                });
              }}
            />
          </div>
          <div className="qual-info">
            <p>互联网药品信息服务资格证书</p>
            <span>证书编号：(川)-经营性-2020-0033</span>
            <img
              src={ypfwzs}
              onClick={() => {
                this.setState({
                  image: {
                    url: ypfwzs,
                    visible: true,
                  },
                });
              }}
            />
          </div>
          <div className="qual-info">
            <p>增值电信业务经营许可证</p>
            <span>经营许可证编号：川B2-20220468</span>
            <img
              src={zzdxyw}
              onClick={() => {
                this.setState({
                  image: {
                    url: zzdxyw,
                    visible: true,
                  },
                });
              }}
            />
          </div>
        </div>
        <ImageViewer
          image={image.url}
          visible={image.visible}
          onClose={() => {
            this.setState({
              image: {
                url: "",
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}
