import React from 'react'
import cls from 'classnames'

import './style.less'

export default function Card(props) {
    const {bottom=false,pure=false}=props
    return(
        <div
        className={cls({'base-card':!bottom},{'base-card-bottom':bottom},{'pure-word':pure})}
        >
            {props.children}
        </div>
    )
}