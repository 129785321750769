import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Head from '../../../component/Head/index'
import Tag from '../../../baseui/tag/index'
import Nodata from '../../../component/Nodata'
import { storeListRequest, pharmacylistRequest } from '../../../api/request/store'

import './style.less'

export default function Shopitem(props) {
    const type = localStorage.getItem("type")
    const longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
    const latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
    const [storeList, setStoreList] = useState([])
    const [newstoreList, setnewStoreList] = useState([])
    const [code,setcode]=useState(false)
    const [inputval,setinputval]=useState("")
    const [sendTime] = useState(30)//运送耗时
    const [is_new] = useState('')//是否为新店
    const [is_premium] = useState(false)//是否已经逛过
    const [activities, setActivities] = useState([])//活动标签组
    const [newactivities, setnewActivities] = useState([])//活动标签组
    const [page] = useState(1)
    const [size] = useState(20)
    const [nowTime,setnowTime]=useState(0)

    useEffect(() => {
        let time=new Date()
        console.log(time.getHours()*60+time.getMinutes());
        setnowTime(time.getHours()*60+time.getMinutes())
        const params = {
            longitude: longitude,
            latitude: latitude,
            size: size,
            page: page
        }
        console.log(type);
        if (type == "2") {
            pharmacylistRequest(params).then(res => {
                console.log(params)
                console.log(res)
                if (res.code === 1 && res.data[0].length > 0) {
                    let actTag = []
                    res.data[0].forEach(item => {
                        if (item.promoteFull) {
                            actTag.push([{
                                text: item.selfDelivery === '0' ? '' : '商家自送',
                                type: 'activity'
                            }, {
                                text: `满${item.promoteFull}送${item.promoteCut}`,
                                type: 'discount'
                            }])
                        } else {
                            actTag.push([{
                                text: item.selfDelivery === '0' ? '' : '商家自送',
                                type: 'activity'
                            }])
                        }
                    })
                    setActivities(actTag)
                    setStoreList(res.data[0])
                }
            })
        }else{
            storeListRequest({
                latitude: latitude,
                longitude: longitude,
                size: size,
            }).then(res=>{
                console.log(res);
                if (res.code === 1 && res.data[0].length > 0) {
                    let actTag = []
                    res.data[0].forEach(item => {
                        if (item.promoteFull) {
                            actTag.push([{
                                text: item.selfDelivery === '0' ? '' : '商家自送',
                                type: 'activity'
                            }, {
                                text: `满${item.promoteFull}送${item.promoteCut}`,
                                type: 'discount'
                            }])
                        } else {
                            actTag.push([{
                                text: item.selfDelivery === '0' ? '' : '商家自送',
                                type: 'activity'
                            }])
                        }
                    })
                    console.log(res.data[0]);
                    setActivities(actTag)
                    setStoreList(res.data[0])
                }
            })
        }

    }, [page, size])

    const handleClick = () => {
    }
    const searchstore=async()=>{
        if (inputval){
            setcode(true)
            const res=await storeListRequest({
                latitude: latitude,
                longitude: longitude,
                size: size,
                content:inputval
            })
            console.log(res);
            if (res.code === 1 && res.data[0].length > 0) {
                let actTag = []
                res.data[0].forEach(item => {
                    if (item.promoteFull) {
                        actTag.push([{
                            text: item.selfDelivery === '0' ? '' : '商家自送',
                            type: 'activity'
                        }, {
                            text: `满${item.promoteFull}送${item.promoteCut}`,
                            type: 'discount'
                        }])
                    } else {
                        actTag.push([{
                            text: item.selfDelivery === '0' ? '' : '商家自送',
                            type: 'activity'
                        }])
                    }
                })
                console.log(actTag,res.data[0]);
                setnewActivities(actTag)
                setnewStoreList(res.data[0])
            }else{
                setnewStoreList([])
            }
        }

    }
    const changevalue=(e)=>{
        setinputval(e.target.value)
    }
    // useEffect(() => {
    //    console.log(activities);
    // }, [activities])
    // useEffect(() => {
        console.log(storeList,newstoreList);
    //  }, [storeList])
    return (
        <div className='morestore'>
            <Head title={type == "2" ? "特惠商铺" : "特惠药房"} />
            <div className='morestore-search'>
                <input type="text" value={inputval} placeholder='请输入药房名称' onChange={e=>changevalue(e)}/> <span onClick={searchstore}>搜索</span>
            </div>
            {storeList.length == 0&&newstoreList.length === 0? <Nodata type='药房'/>:
            <>
            {storeList.length == 0||code ?null:
                storeList.map((item, index) => (
                    <Link key={index} to={`/shop/${item.pharmacyId}?morestore=${1}`}>
                        <section style={{ borderBottom: '1px solid #EEF1F5' }}
                                 className='shoplist_item'
                                 onClick={() => handleClick()}>
                            <div className='shoplist_img'>
                                <div className='shoplist_logo flexr'>
                                    <img src={item.pharmacyPhoto} alt='' />
                                </div>
                                {is_new ? <div className='show_new_logo'><span>新店</span></div> : null}
                            </div>
                            <div className='shoplist_main'>
                                <section className='show_line'>
                                    <h3 className={is_premium ? 'is_premium  show_line_h3' : 'show_line_h3'}>
                                        <span>{item.pharmacyName}</span>
                                    </h3>
                                </section>
                                <section className='show_line show_line2'>
                                    <div className='show_rateWrap'>
                                        <span className='show_num'>月售{item.salesVolume ? item.salesVolume : 0}</span>

                                    </div>
                                </section>
                                <section className='show_line show_line2'>
                                    <div className='show_money'>
                                        <span>起送￥{(item.minFreight / 100).toFixed(2)}</span>
                                        {/*<span>运费￥{(item.orderFreight / 100).toFixed(2)}</span>*/}
                                    </div>
                                    <div className='show_time'>
                                        {type == "1"?<>
                                            {/* <span>{sendTime}分钟</span> */}
                                            <span>{item.distance?item.distance>=1000?(item.distance/1000).toFixed(1)+'km':item.distance+'m':'0m'}</span></>:<></>}
                                    </div>
                                </section>
                                <section className='show_active'>
                                    {
                                        activities[index].map((v, index) => {
                                            return (
                                                // <Tag key={index} {...v} />
                                                <span style={{backgroundColor:"#F34848",color:'#fff',height:'13px'}}>资质认证</span>
                                            )
                                        })
                                    }
                                    {(parseInt(item.pharmacyStartTime.substring(0,2)*60)+parseInt(item.pharmacyStartTime.substring(3,5)))<nowTime&&
                                    (parseInt(item.pharmacyEndTime.substring(0,2)*60)+parseInt(item.pharmacyEndTime.substring(3,5)))>nowTime&&
                                    item.promoteStu=='0'?null:<span style={{marginLeft:'10px',marginTop:'-2px',backgroundColor:"#aaa",color:"#fff",padding:'3px 5px',fontSize:'12px'}}>店铺已打烊</span>}
                                </section>
                                <div className='line'></div>
                            </div>
                        </section>
                    </Link>
                ))}
                {newstoreList.length === 0||!code ? storeList.length === 0? <Nodata type='药房'/> :null :
                    newstoreList.map((item, index) => (
                        <Link key={index} to={`/shop/${item.pharmacyId}?morestore=${1}`}>
                            <section style={{ borderBottom: '1px solid #EEF1F5' }}
                                     className='shoplist_item'
                                     onClick={() => handleClick()}>
                                <div className='shoplist_img'>
                                    <div className='shoplist_logo flexr'>
                                        <img src={item.pharmacyPhoto} alt='' />
                                    </div>
                                    {is_new ? <div className='show_new_logo'><span>新店</span></div> : null}
                                </div>
                                <div className='shoplist_main'>
                                    <section className='show_line'>
                                        <h3 className={is_premium ? 'is_premium  show_line_h3' : 'show_line_h3'}>
                                            <span>{item.pharmacyName}</span>
                                        </h3>
                                    </section>
                                    <section className='show_line show_line2'>
                                        <div className='show_rateWrap'>
                                            <span className='show_num'>月售{item.salesVolume ? item.salesVolume : 0}</span>
                                        </div>
                                    </section>
                                    <section className='show_line show_line2'>
                                        <div className='show_money'>
                                            <span>起送￥{(item.minFreight / 100).toFixed(2)}</span>
                                            {/*<span>运费￥{(item.orderFreight / 100).toFixed(2)}</span>*/}
                                        </div>
                                        <div className='show_time'>
                                            {/* <span>{sendTime}分钟</span>
                                <span>{item.distance?item.distance>=1000?(item.distance/1000).toFixed(1)+'km':item.distance+'m':'0m'}</span> */}
                                        </div>
                                    </section>
                                    <section className='show_active'>
                                        {
                                            newactivities[index]?newactivities[index] .map((v, index) => {
                                                return (
                                                    // <Tag key={index} {...v} />
                                                    <span style={{backgroundColor:"#F34848",color:'#fff',height:'13px'}}>资质认证</span>
                                                )
                                            }):null
                                        }
                                    </section>
                                    <div className='line'></div>
                                </div>
                            </section>
                        </Link>
                    ))
                }
            </>}

        </div>
    )
}