import { axiosInstance } from '../config'
// 正式下单
export const pfDownOrder = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/pf-downorder', data)
}
// 查看所有发票模板	 
export const allInvoice = (userId) => {
    return axiosInstance.get(`/api/hosp/invoice/all-invoice?userId=${userId}`)
}
// 查看所有发票订单		
export const allOrderInvoice = (data) => {
    return axiosInstance.post(`/api/hosp/invoice/all-order-invoice`,data)
}