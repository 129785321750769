import React, { Component } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button,ImagePicker } from 'antd-mobile'
import Meditem from '../orderitem/index'

import { adressListRequest } from '../../../api/request/adress'
import { aftersaleList, aftersaleMedList, cancelAftersale } from '../../../api/request/aftersale'

import { oderdetailRequest, upOrderStuRequest } from '../../../api/request/order'
import { storeDetailsRequest } from "../../../api/request/store"
import { addIntegralRequest } from '../../../api/request/integral'
import { pharmacyStorecs } from '../../../api/request/cart'
import getUrl from '../../../component/GetUrlParam'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import '../../../static/css/iconfont.css'
import defaultImg from '../../../static/img/default.png'
import fanhui from '../../../static/img/fanhuihei.png'
import kefu from '../../../static/img/kefu2.png'

import './style.less'
const { alert } = Modal
const RadioItem = Radio.RadioItem


//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            orderList:'',//购买详情
            medicitailList:[],//药品详情
            orderNumber:'',
            tel:'',
            userId:localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
        }
    }
    componentDidMount = async () => {
        this.setState({
            orderNumber:getUrl('orderNumber', this.props.location.search),
            tel:getUrl('tel', this.props.location.search),
        })
        let orderNumber1=getUrl('orderNumber', this.props.location.search)
        oderdetailRequest(orderNumber1).then(res=>{
            console.log(res)
            this.setState({
                orderList:res.data[0][0],
                medicitailList:res.data[1]
            })
        })
    }
    fanhui1=()=>{
        window.history.back()
    }
    render() {
            const { orderList,medicitailList,tel } = this.state
        return (
            <div>
                <div>
                    <div className='lingshou-shou'>
                        <div className='lingshou-top'>
                            <img src={fanhui} onClick={()=>this.fanhui1()} alt="" />
                            <p>售后详情</p>  
                        </div>
                        {orderList.orderState=='6'?<div className='refund-process-top'>
                            <h2>退款成功</h2>
                            <p>退款将在1个工作日内到账</p>
                        </div>
                        :<div className='refund-process-top'>
                            <h2>您申请的售后被商家驳回</h2>
                            <p>驳回原因：{orderList.refundRefuse}</p>
                        </div>}
                        {orderList.orderState=='6'?
                        <div className='tuikuan-price'>
                            <p className='tuikuan-price-top'>
                                <span>退款金额</span>
                                <span>￥{(orderList.orderAmount/100).toFixed(2)}</span>
                            </p>
                            <p className='tuikuan-price-oragin'>退回至原账户</p>
                        </div>
                        :null}
                        <div className='refund-process'>
                            <h2>退款流程</h2>
                            <p>
                            <a href={`tel:${tel}`}>
                                <img src={kefu} alt="" />
                                <span>联系商家</span>
                            </a></p>
                        </div>
                        {orderList.orderState=='6'?<div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-kong'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>微信退款入账中</span>
                                    <span>处理完成后会在1个工作日内将￥{(orderList.orderAmount/100).toFixed(2)}元入账到您的原账户</span>
                                    <span>{orderList.refundTime}</span>
                                </p>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>商家同意退款</span>
                                    <span>商家处理完成</span>
                                    <span>{orderList.refundTime}</span>
                                </p>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{orderList.applyRefundTime}</span>
                                </p>
                            </p>
                        </div>:<div className='specific-refund-process'>
                            <p className='refund-process-left'>
                                <p className='refund-process-left-kong'></p>
                                <p className='refund-process-left-shu'></p>
                                <p className='refund-process-left-shi'></p>
                            </p>
                            <p className='refund-process-right'>
                                <p className='refund-process-right-shnagjia'>
                                    <span className='shangjia-tital'>商家拒绝退款</span>
                                    <span>商家拒绝</span>
                                    <span>{orderList.refundRefuseTime}</span>
                                </p>
                                <p className='refund-process-right-user'>
                                    <span className='shangjia-tital'>发起售后申请</span>
                                    <span>商家商家审核通过后将为您退款</span>
                                    <span>{orderList.applyRefundTime}</span>
                                </p>
                            </p>
                        </div>}
                        <div className='refund-process-bottom'>
                            <h2>退款信息</h2>
                            {medicitailList.map(item=>(<div className='refund-process-allmedicial'>
                                <img src={defaultImg} alt="" />
                                <p className='refund-process-medicial'>
                                    <h3>{item.medName}</h3>
                                    <p>{item.medSpec}</p>
                                    <p className='refund-process-medicial-num'>x{item.medQuantity}</p>
                                </p>
                                <p className='refund-process-medicial-price'>￥{(item.medPrice/100).toFixed(2)}</p>
                            </div>))}
                        </div>
                        <p className='refund-medicial-bottom'>
                            <span>订单号</span>
                            <span>{orderList.orderNumber}</span>
                        </p>
                        <p className='refund-medicial-bottom2'>
                            <span>退款原因</span>
                            <span>{orderList.refundReason}</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}