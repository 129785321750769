import React ,{ useState, useEffect } from 'react'
import kefu from '../../../static/img/kefu.png'
import you from '../../../static/img/you.png'
import defaultImg from '../../../static/img/default.png'
import zhezaho from "../../../static/img/zhezaho.jpg"
import lianxi from '../../../static/img/lianxi.png'
import './style.less'

export default function Refuse(props){
    const cartArr1 = props.location.state
    const pharmacyName1 = props.location.pharmacyName
    console.log(cartArr1);
    let cartArr;  //先定义一个存储参数的变量
    let pharmacyName;
    if(pharmacyName1&&cartArr1){  //判断是否有参数
        cartArr=cartArr1.medList;
        pharmacyName=pharmacyName1;
        localStorage.setItem('cartArr',JSON.stringify(cartArr));
        localStorage.setItem('pharmacyName', pharmacyName);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        cartArr =JSON.parse(localStorage.getItem('cartArr')); 
        pharmacyName = localStorage.getItem('pharmacyName');//当state没有参数时，取sessionStorage中的参数
    }
    const backPage=()=>{
        window.history.back()
    }

    const toMedicine = (medId) => {
        props.history.push(`/med/${medId}`)
    }
    console.log(cartArr);
    let total=0;
    cartArr&&cartArr.map((item, index) => (
        total+=item.medPrice/100*item.medQuantity
    ))
    
    return(
        <>
            <div className='header1'>
                <div className='drawback-header'>
                    <div className='back-icon flexr' onClick={backPage}>
                        <i className='icon-xiangzuo iconfont'></i>
                    </div> 
                    <div className='order-kefu'>
                        <img src={kefu} alt="" />
                    </div>
                </div>
                <p className='drawback'>商家拒绝退款<img className='jujue-img' src={you} alt="" /></p>
                <div className='teshu-drawback'>
                    <p className='teshu-drawback-title'>商家拒绝原因</p>
                    <div className='xian'></div>
                    <p className='teshu-drawback-explain'>图片中的商品不是你下单的商品，非本店铺的商品，因此拒绝你的退款申请。</p>
                </div>
                <div className='contact'>
                    <img src={lianxi} alt="" />
                    <span>联系骑手</span>
                </div>
                <div className='pharmacy'>
                    {/*  onClick={() => props.history.push(`/shop/${orderDetail.pharmacyId}`)} */}
                    <div>
                        <span className='phname'>{pharmacyName}</span>
                        <i className='iconfont icon-xiangyou'></i>
                    </div>
                    <div>
                    {cartArr && cartArr.map((item, index) => (
                       <div className='pharmacy-name'>
                          <div key={index} className='order-meditem flex-apart-row'
                            onClick={()=>toMedicine(item.medId)}>
                                <div className='flexr'>
                                    {/* src={item.medPhoto?item.medPhoto:defaultImg} */}
                                    <img src={item.medClassify === "1" ? zhezaho : (item.medPhoto ? item.medPhoto : defaultImg)} alt=''
                                    onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                    <div className='flexr-right'>
                                        <p>
                                            <span className='name'>
                                                {item.medClassify==='1'?<span className='chufang'>Rx</span>:null}
                                                {item.medName}
                                            </span>
                                            <span className='price'>
                                                {/* {item.originPrice?<span>￥{item.originPrice/100}</span>:null} */}
                                                <span style={{marginRight:'10px',textDecoration:"line-through",color:'#999'}}>￥30.00</span>
                                                ￥{item.medPrice/100}
                                            </span>
                                        </p>
                                        <p className='guige'>{item.medSpec}</p>
                                        <span className='money'>x{item.medQuantity}</span>
                                    </div>
                                    
                                </div>
                                {item.length>1&&index!=item.length-1?<div className='xian'></div>:null}
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='xian'></div>
                    <div className='all-select'>
                        <p></p>
                        <p>
                            <span>退款金额：</span>
                            ￥<span className='total' style={{marginRight:'20px'}}>{total}</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}