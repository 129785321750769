export function storageUser(res3,code){
    if(code==1){
        console.log(res3.data[0][0]);
        if (res3.data[0][0].useravatar) {
            const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
            localStorage.setItem("userAvatar1", `${userAvatar}`)
        }
        localStorage.setItem("userAcount1", res3.data[0][0].useracount)
        localStorage.setItem("userVip1", res3.data[0][0].uservip)
        localStorage.setItem("userName1", res3.data[0][0].username)
        localStorage.setItem("userId1", res3.data[0][0].userid)
        localStorage.removeItem("wxlogin")
    }else if(code==2){
        const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
        localStorage.setItem("userAcount2", res3.data[0][0].useracount)
        localStorage.setItem("userAvatar2", `${userAvatar}`)
        localStorage.setItem("userVip2", res3.data[0][0].uservip)
        localStorage.setItem("userName2", res3.data[0][0].username)
        localStorage.setItem("userId2", res3.data[0][0].userid)
        localStorage.removeItem("wxlogin")
    }else{
        const userAvatar = res3.data[0][0].useravatar.replace(/\\/g, "")
        localStorage.setItem("userAcount3", res3.data[0][0].useracount)
        localStorage.setItem("userAvatar3", `${userAvatar}`)
        localStorage.setItem("userVip3", res3.data[0][0].uservip)
        localStorage.setItem("userName3", res3.data[0][0].username)
        localStorage.setItem("userId3", res3.data[0][0].userid)
        localStorage.removeItem("wxlogin")
    }
   
}