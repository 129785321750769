import { axiosInstance } from '../config'

//申请订单售后
export const addAftersale = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/add-aftersale', data)
}
//更新售后状态
export const updateAftersale = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/update-aftersale', data)
}
//取消售后状态
export const cancelAftersale = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/cancel-aftersale', data)
}
// 用户售后订单列表
export const aftersaleList = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/list', data)
}
// 添加售后订单商品
export const addAftersaleMed = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/add-aftersale-med', data)
}
// 获取售后订单商品列表
export const aftersaleMedList = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/aftersale-med-list', data)
}
// 修改售后快递信息
export const updateCourier = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/updatecourier', data)
}

// 通过订单编号获取售前状态
export const getState = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/get-state', data)
}
// 添加售后商品资质
export const addQualifications = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/add-qualifications', data)
}
// 获取指定状态订单列表
export const stateList = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/state-list', data)
}
// 修改售后状态	
export const updateAfterSaleall = (frequencyLimit,orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/update-after-sale?frequencyLimit=${frequencyLimit}&orderNumber=${orderNumber}`)
}
// 修改售后次数		
export const updateFrequencyLimit = (frequencyLimit,orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/update-frequency-limit?frequencyLimit=${frequencyLimit}&orderNumber=${orderNumber}`)
}
// 查询平台设置的售后次数	
export const getFrequencyLimit = () => {
    return axiosInstance.get(`/api/hosp/order/get-frequency-limit`)
}
// 单个订单所有售后信息	
export const orderAllnews = (data) => {
    return axiosInstance.post('/api/hosp/aftersale/order-allnews', data)
}