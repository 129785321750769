import React, { Component } from 'react'
import {pfDownOrder} from "../../api/request/yt"
//import {getLodop} from "../../dayin/LodopFuncs"
export default class index extends Component {
    constructor(){
        super()
        this.state={
        
        }
    }
    componentDidMount=async()=>{
        const res=await pfDownOrder()
        console.log(res)
    }
    render() {
        return (
            <div>
                <button>物流测试</button>
            </div>
        )
    }
}
