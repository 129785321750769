import React, { useState, useEffect, useRef, useMemo } from "react";
import { WhiteSpace, Checkbox, Modal, List } from "antd-mobile";
import { Popup, Stepper, Button, Toast } from "antd-mobile-v5";
import { connect } from "react-redux";
import { getUserSH } from "../../api/request/user";
import { wxConfig } from "../../component/scan/index";
import { resetdirect } from "../../redux/user.redux";
import getUrl from "../../component/GetUrlParam";

import FootNav from "../../component/FootNav";
import NoData from "../../component/Nodata";
import RecommmendMed from "../../component/Goods/rmd-med";

import { addcollectRequest } from "../../api/request/fllow";
import { adressListRequest } from "../../api/request/adress";
import { meddetailRequest } from "../../api/request/med";
import {
  cartlistRequest,
  updatecartRequest,
  delCartRequest,
  changeState,
  allStatus,
  pharmacyAndFreight,
  firstPharmacyid,
  changestoreallState,
  cartDiscount,
  cartYouhuiquan,
  cardManjian,
} from "../../api/request/cart";

import rmd from "../../static/img/recommend.png";
import defaultImg from "../../static/img/default.png";
import zhezaho from "../../static/img/zhezaho.jpg";
import icon from "../../static/img/home/close.png";
import "./style.less";
import { concat, isNumber, set } from "lodash";
const { alert } = Modal;
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;

function Shopcart(props) {
  // let pharmacyId = getUrl('pharmacyId', props.location.search)
  let pharmacyId = "1";
  let pharmacyId2 = localStorage.getItem("pharmacyId");
  //店铺起送价格
  const [visible1, setVisible1] = useState(false);
  // 判断是否删除购物车商品
  const [visiable7, setVisiable7] = useState(false);
  // 当前店铺id
  const [nowstoreId, setNowstoreId] = useState(null);
  // 当前店铺名字
  const [nowstorename, setNowstorename] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabindex, settabindex] = useState(0);
  // 平台推荐商品
  const [recommand, setRecommand] = useState([]);
  // 购物车商品
  const [cartList, setCartList] = useState([]);
  // 所有药房
  const [pharmacyList, setPharmacyList] = useState([]);
  // 药店和选中商品
  const [cartArr, setCartArr] = useState([]);
  // 选中的药品种类
  const [cartSum, setCartSum] = useState(0);
  // 总价
  const [cartPrice, setCartPrice] = useState(0);
  const [cartPrice1, setCartPrice1] = useState(0);

  // 满减商品总金额
  const [activitiesPrice, setactivitiesPrice] = useState(0);
  // 满减活动
  const [activitiesManjian, setactivitiesManjian] = useState([]);
  // 满减满足条件
  const [activitiesUse, setactivitiesUse] = useState();

  const [cartPriceNow, setCartPriceNow] = useState(0);
  // 全选
  const [allChecked, setAllChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [useraddress, setuseraddress] = useState("");
  const [freeFreight, setfreeFreight] = useState(0); //包邮金额
  const [orderFreight, setorderFreight] = useState(0); //运费
  const [minFreight, setminFreight] = useState(0); //起送金额
  const [pharmacyPackingFee, setpharmacyPackingFee] = useState(0); //打包费

  const [maxDistance, setmaxDistance] = useState(0); //最大配送距离
  const [nowTime, setnowTime] = useState(0);
  const [weeks, setWeeks] = useState();
  const [flag, setflag] = useState(false); //是否显示满减

  const [arr, setarr] = useState();

  const [couponDetails, setcouponDetails] = useState([]); //店铺满减可使用优惠券
  const [usercouponDetails, setusercouponDetails] = useState([]); //部分商品满减可使用的优惠券
  const [maxUsercoupon, setmaxUsercoupon] = useState(0); //订单最大优惠券金额
  const [discountDetails, setdiscountDetails] = useState([]); //活动商品
  const [maxUserdiscount, setmaxUserdiscount] = useState(0); //活动商品优惠金额

  // 编辑管理购物车
  const [titlecode, setTitlecode] = useState(false);
  const userId =
    localStorage.type == "1"
      ? localStorage.userId1
      : localStorage.type == "2"
      ? localStorage.userId2
      : localStorage.userId3;
  const { redirectDispatch } = props;
  const type = localStorage.type;
  const [deleteStaus, setdeleteStaus] = useState(false);
  let [cref, setcref] = useState(null);
  useEffect(() => {
    if (type == "1") {
      //获取购物车列表
      getCartList(userId);
    }
  }, [userId]);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
    getCartList(userId);
  }, [refresh]);

  // 扫描搜索药品
  const cameraScan = () => {
    wxConfig();
  };

  //获取购物车列表
  const getCartList = async (userId) => {
    let time = new Date();
    let dt = await firstPharmacyid(userId);
    console.log(dt);
    if (dt.data[0].length) {
      pharmacyId = dt.data[0][0].pharmacyId;
    }
    // console.log(time.getHours()*60+time.getMinutes());
    // 当前时间 eg:  9:20  nowTime=9*60+20
    let nowTime = time.getHours() * 60 + time.getMinutes();
    setnowTime(time.getHours() * 60 + time.getMinutes());
    if (type == "1") {
      // console.log(cartList);
      let couponArr = []; //存在的优惠券id
      let couponArr0 = []; //可用的优惠券id
      let couponArr1 = []; //已领取的优惠券id
      let couponList1 = {};
      let manjian = []; //满减
      let manjianDetails = []; //满减商品
      var tempDate = new Date();
      var days = tempDate.getDay();
      var week;
      switch (days) {
        case 1:
          week = "周一";
          break;
        case 2:
          week = "周二";
          break;
        case 3:
          week = "周三";
          break;
        case 4:
          week = "周四";
          break;
        case 5:
          week = "周五";
          break;
        case 6:
          week = "周六";
          break;
        case 0:
          week = "周日";
          break;
      }
      setWeeks(week);
      console.log(userId, pharmacyId);
      //购物车列表
      cartlistRequest(userId, pharmacyId)
        .then(async (res) => {
          console.log(res);
          if (res.code === 1) {
            // 满减
            let res0 = await cardManjian(userId);
            console.log(res0);
            manjian = res0.data[1];
            manjianDetails = res0.data[2];
            console.log(manjian, manjianDetails);
            res.data[0].length &&
              res.data[0].map((item) => {
                item.activitiesCycle = [];
                item.activitiesDiscount = [];
                item.activitiesStartTime = "";
                item.activitiesEndTime = "";
                item.activitiesId = "";
                item.activitiesType = "";
                item.state = false;
              });
            if (manjian.length) {
              manjian.map((i) => {
                i.activitiesCycle = JSON.parse(i.activitiesCycle);
                i.activitiesDiscount = JSON.parse(i.activitiesDiscount);
              });
            }
            if (manjianDetails) {
              manjianDetails.map((v) => {
                v.activitiesCycle = [];
                v.activitiesDiscount = [];
                v.activitiesStartTime = "";
                v.activitiesEndTime = "";
                v.activitiesId = "";
                v.activitiesType = "";
                v.pharmacyId = "";
              });
            }
            // 店铺满减
            if (res.data[0].length && manjian) {
              res.data[0].map((item) => {
                manjian.map((i) => {
                  if (
                    item.pharmacyId == i.pharmacyId &&
                    i.activitiesType == 2
                  ) {
                    i.activitiesCycle.map((v) => {
                      if (v == week) {
                        item.state = true;
                      }
                    });
                    if (item.state) {
                      item.activitiesCycle = i.activitiesCycle;
                      item.activitiesDiscount = i.activitiesDiscount;
                      item.activitiesStartTime = i.activitiesStartTime;
                      item.activitiesEndTime = i.activitiesEndTime;
                      item.activitiesId = i.activitiesId;
                      item.activitiesType = i.activitiesType;
                    }
                  }
                });
              });
            }
            if (manjianDetails && manjian.length) {
              manjianDetails.map((item) => {
                manjian.map((i) => {
                  if (
                    item.activeId == i.activitiesId &&
                    i.activitiesType == 1
                  ) {
                    item.activitiesCycle = i.activitiesCycle;
                    item.activitiesDiscount = i.activitiesDiscount;
                    item.activitiesStartTime = i.activitiesStartTime;
                    item.activitiesEndTime = i.activitiesEndTime;
                    item.activitiesId = i.activitiesId;
                    item.activitiesType = i.activitiesType;
                    item.pharmacyId = i.pharmacyId;
                  }
                });
              });
            }
            // 部分满减
            if (res.data[0].length && manjianDetails) {
              res.data[0].map((item) => {
                manjianDetails.map((i) => {
                  if (
                    item.pharmacyId == i.pharmacyId &&
                    i.activitiesType == 1 &&
                    item.medQrcode == i.medQrcode
                  ) {
                    i.activitiesCycle.map((v) => {
                      if (v == week) {
                        item.state = true;
                      }
                    });
                    if (item.state) {
                      item.activitiesCycle = i.activitiesCycle;
                      item.activitiesDiscount = i.activitiesDiscount;
                      item.activitiesStartTime = i.activitiesStartTime;
                      item.activitiesEndTime = i.activitiesEndTime;
                      item.activitiesId = i.activitiesId;
                      item.activitiesType = i.activitiesType;
                    }
                  }
                });
              });
            }
            setactivitiesManjian(res0.data[1]);
            // 活动商品
            let res1 = await cartDiscount({ userId });
            console.log(res1);
            if (res1.data[1].length) {
              res1.data[1].map((item) => {
                item.discountCycle = JSON.parse(item.discountCycle);
                if (!item.discountMedicineCount) {
                  item.discountMedicineCount = 0;
                }
              });
              res.data[0].map((item) => {
                item.discountMedicineCount = 0;
                item.discountId = "";
                item.discountLimits = "";
                item.discountPrice = "";
                item.percentage = "";
                item.discountStock = "";
                item.state1 = false;
              });
              if (res1.data[1].length && res.data[0].length) {
                res.data[0].map((i) => {
                  res1.data[1].map((v) => {
                    console.log(v);
                    if (i.medQrcode == v.medQrcode) {
                      v.discountCycle.map((v) => {
                        if (v == week) {
                          i.state1 = true;
                        }
                      });
                      console.log(i.state1);
                      if (i.state1) {
                        i.discountMedicineCount = v.discountMedicineCount;
                        i.discountId = v.discountId;
                        i.discountLimits = v.discountLimits;
                        i.discountPrice = v.discountPrice;
                        i.percentage = v.percentage;
                        i.discountStock = v.discountStock;
                      }
                    }
                  });
                });
              }
              setdiscountDetails(res1.data[1]);
            }
            console.log(res.data[0]);

            let pharmacyList = [];
            // 购物车所有药品
            let data = res.data[0];
            //计算总价
            let allmoney = 0;
            let anymoney = 0;
            let count = 0;
            let money1 = 0;
            console.log(data);
            data.forEach((item) => {
              if (
                !titlecode &&
                item.checkStatus == 1 &&
                parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
                  parseInt(item.pharmacyStartTime.substring(3, 5)) <
                  nowTime &&
                nowTime <
                  parseInt(item.pharmacyEndTime.substring(0, 2) * 60) +
                    parseInt(item.pharmacyEndTime.substring(3, 5)) &&
                item.promoteStu == "0"
              ) {
                console.log(item);
                allmoney += item.medPrice * item.medQuantity;
                // if (!item.discountId||item.discountLimits==item.discountMedicineCount||(item.discountStock==0)) {
                //     allmoney += item.medPrice * item.medQuantity
                // }else{
                if (item.percentage) {
                  // 立减
                  if (item.percentage == -1) {
                    // 库存不限
                    if (item.discountStock == -1) {
                      if (
                        item.discountLimits == -1 ||
                        (item.discountLimits != -1 &&
                          item.discountLimits - item.discountMedicineCount >=
                            item.medQuantity)
                      ) {
                        // allmoney += item.discountPrice * item.medQuantity
                        anymoney +=
                          (item.medPrice - item.discountPrice) *
                          item.medQuantity;
                      } else if (
                        item.discountLimits != -1 &&
                        item.discountLimits - item.discountMedicineCount <
                          item.medQuantity
                      ) {
                        // allmoney += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                        anymoney +=
                          (item.medPrice - item.discountPrice) *
                          (item.discountLimits - item.discountMedicineCount);
                      }
                      // 限库存
                    } else {
                      if (item.discountStock >= item.medQuantity) {
                        if (
                          item.discountLimits == -1 ||
                          (item.discountLimits != -1 &&
                            item.discountLimits - item.discountMedicineCount >=
                              item.medQuantity)
                        ) {
                          // allmoney += item.discountPrice * item.medQuantity
                          anymoney +=
                            (item.medPrice - item.discountPrice) *
                            item.medQuantity;
                        } else if (
                          item.discountLimits != -1 &&
                          item.discountLimits - item.discountMedicineCount <
                            item.medQuantity
                        ) {
                          // allmoney += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                          anymoney +=
                            (item.medPrice - item.discountPrice) *
                            (item.discountLimits - item.discountMedicineCount);
                        }
                      } else {
                        if (
                          item.discountStock >=
                          item.discountLimits - item.discountMedicineCount
                        ) {
                          // allmoney += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                          anymoney +=
                            (item.medPrice - item.discountPrice) *
                            (item.discountLimits - item.discountMedicineCount);
                        } else {
                          // allmoney += item.discountPrice * item.discountStock+item.medPrice * (item.medQuantity-item.discountStock)
                          anymoney +=
                            (item.medPrice - item.discountPrice) *
                            item.discountStock;
                        }
                      }
                    }
                    // 折扣
                  } else if (item.percentage != -1) {
                    if (item.discountStock == -1) {
                      if (
                        item.discountLimits == -1 ||
                        (item.discountLimits != -1 &&
                          item.discountLimits - item.discountMedicineCount >=
                            item.medQuantity)
                      ) {
                        // allmoney += item.medPrice*item.percentage/10 * item.medQuantity
                        anymoney +=
                          (item.medPrice -
                            (item.medPrice * item.percentage) / 10) *
                          item.medQuantity;
                      } else if (
                        item.discountLimits != -1 &&
                        item.discountLimits - item.discountMedicineCount <
                          item.medQuantity
                      ) {
                        // allmoney += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                        anymoney +=
                          (item.medPrice -
                            (item.medPrice * item.percentage) / 10) *
                          (item.discountLimits - item.discountMedicineCount);
                      }
                    } else {
                      if (item.discountStock >= item.medQuantity) {
                        if (
                          item.discountLimits == -1 ||
                          (item.discountLimits != -1 &&
                            item.discountLimits - item.discountMedicineCount >=
                              item.medQuantity)
                        ) {
                          // allmoney += item.medPrice*item.percentage/10 * item.medQuantity
                          anymoney +=
                            (item.medPrice -
                              (item.medPrice * item.percentage) / 10) *
                            item.medQuantity;
                        } else if (
                          item.discountLimits != -1 &&
                          item.discountLimits - item.discountMedicineCount <
                            item.medQuantity
                        ) {
                          // allmoney += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                          anymoney +=
                            (item.medPrice -
                              (item.medPrice * item.percentage) / 10) *
                            (item.discountLimits - item.discountMedicineCount);
                        }
                      } else {
                        if (
                          item.discountStock >=
                          item.discountLimits - item.discountMedicineCount
                        ) {
                          // allmoney += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
                          anymoney +=
                            (item.medPrice -
                              (item.medPrice * item.percentage) / 10) *
                            (item.discountLimits - item.discountMedicineCount);
                        } else {
                          // allmoney += item.medPrice*item.percentage/10 * item.discountStock+item.medPrice * (item.medQuantity-item.discountStock)
                          anymoney +=
                            (item.medPrice -
                              (item.medPrice * item.percentage) / 10) *
                            item.discountStock;
                        }
                      }
                    }
                  }
                }
                // }
                count += 1;
                setorderFreight(item.orderFreight);
                // setpharmacyPackingFee(item.pharmacyPackingFee)
                setminFreight(item.minFreight);
                if (item.activitiesId) {
                  money1 += item.medPrice * item.medQuantity;
                }
              }
            });
            console.log(anymoney);
            setCartSum(count);
            setCartPrice(allmoney); //药品总金额
            setmaxUserdiscount(anymoney); //活动商品优惠金额
            setactivitiesPrice(money1); //参与满减商品的总金额
            let data1 = res.data[1] ? res.data[1] : [];
            // console.log(data1);
            let flag0 = false;
            data.forEach((item) => {
              item.checked = item.checkStatus == 0 ? false : true;
              item.cartPrice = item.medPrice * item.medQuantity;
              if (item.medClassify == "1" && item.checked) {
                flag0 = true;
              }
            });
            let newCartArr = [];
            if (data.length == 1) {
              let arritem = [];
              arritem.push({
                cartId: data[0].cartId,
                medId: data[0].medId,
                medName: data[0].medName,
                medPhoto: data[0].medPhoto,
                medSpec: data[0].medSpec,
                medPacking: data[0].medPacking,
                medApproval: data[0].medApproval,
                medCompany: data[0].medCompany,
                medQrcode: data[0].medQrcode,
                medPrice: data[0].medPrice,
                cartPrice: data[0].cartPrice,
                medQuantity: data[0].medQuantity,
                checkStatus:
                  (parseInt(data[0].pharmacyStartTime.substring(0, 2) * 60) +
                    parseInt(data[0].pharmacyStartTime.substring(3, 5)) <
                    nowTime &&
                    nowTime <
                      parseInt(data[0].pharmacyEndTime.substring(0, 2) * 60) +
                        parseInt(data[0].pharmacyEndTime.substring(3, 5)) &&
                    data[0].promoteStu == "0") ||
                  titlecode
                    ? data[0].checkStatus
                    : 0,
                checked:
                  (parseInt(data[0].pharmacyStartTime.substring(0, 2) * 60) +
                    parseInt(data[0].pharmacyStartTime.substring(3, 5)) <
                    nowTime &&
                    nowTime <
                      parseInt(data[0].pharmacyEndTime.substring(0, 2) * 60) +
                        parseInt(data[0].pharmacyEndTime.substring(3, 5)) &&
                    data[0].promoteStu == "0") ||
                  titlecode
                    ? data[0].checked
                    : false,
                pharmacyId: data[0].pharmacyId,
                promoteStu: data[0].promoteStu,
                pharmacyName: data[0].pharmacyName,
                promoteFull: data[0].promoteFull,
                promoteCut: data[0].promoteCut,
                freeFreight: data[0].freeFreight,
                minFreight: data[0].minFreight,
                orderFreight: data[0].orderFreight,
                pharmacyStartTime: data[0].pharmacyStartTime,
                pharmacyEndTime: data[0].pharmacyEndTime,
                medClassify: data[0].medClassify,
                medApparatus: data[0].medApparatus,
                medValidity: data[0].medValidity,
                medEnterprise: data[0].medEnterprise,
                // activitiesCycle:data[0].activitiesCycle,
                // activitiesDiscount:data[0].activitiesDiscount,
                // activitiesName:data[0].activitiesName,
                // activitiesStartTime:data[0].activitiesStartTime,
                // activitiesEndTime:data[0].activitiesEndTime,
              });
              newCartArr.push({
                pharmacy: {
                  pharmacyId: arritem[0].pharmacyId,
                  pharmacyName: arritem[0].pharmacyName,
                  freeFreight: arritem[0].freeFreight,
                  minFreight: arritem[0].minFreight,
                  orderFreight: arritem[0].orderFreight,
                  promoteStu: arritem[0].promoteStu,
                  pharmacyStartTime: arritem[0].pharmacyStartTime,
                  pharmacyEndTime: arritem[0].pharmacyEndTime,
                  isClassify: flag0 ? 2 : 1,
                },
                medList: arritem,
              });

              let code = true;
              arritem.forEach((item) => {
                if (item.checkStatus == 0) {
                  code = false;
                }
              });
              pharmacyList.push({
                pharmacyId: arritem[0].pharmacyId,
                pharmacyName: arritem[0].pharmacyName,
                freeFreight: arritem[0].freeFreight,
                minFreight: arritem[0].minFreight,
                orderFreight: arritem[0].orderFreight,
                promoteStu: arritem[0].promoteStu,
                checked: code,
                pharmacyEndTime: arritem[0].pharmacyEndTime,
                pharmacyStartTime: arritem[0].pharmacyStartTime,
              });
              setCartArr(newCartArr);
            } else {
              let nowdata = JSON.parse(JSON.stringify(data));
              // console.log(nowdata);
              // 购物车所有店铺id(pharmacyId)
              let idlist = [];
              nowdata.forEach((item) => {
                idlist.push(item.pharmacyId);
              });
              for (let i = 0; i < idlist.length; i++) {
                for (let j = i + 1; j < idlist.length; j++) {
                  if (idlist[i] == idlist[j]) {
                    idlist.splice(j, 1);
                    j--;
                  }
                }
              }
              for (let i = 0; i < idlist.length; i++) {
                let arritem = [];
                nowdata.forEach((item, index) => {
                  if (item.pharmacyId == idlist[i]) {
                    // console.log(item);
                    arritem.push({
                      cartId: item.cartId,
                      medId: item.medId,
                      medName: item.medName,
                      medPhoto: item.medPhoto,
                      medSpec: item.medSpec,
                      medPacking: item.medPacking,
                      medApproval: item.medApproval,
                      medCompany: item.medCompany,
                      medPrice: item.medPrice,
                      medQrcode: item.medQrcode,
                      cartPrice: item.cartPrice,
                      medQuantity: item.medQuantity,
                      checkStatus:
                        (parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
                          parseInt(item.pharmacyStartTime.substring(3, 5)) <
                          nowTime &&
                          nowTime <
                            parseInt(
                              item.pharmacyEndTime.substring(0, 2) * 60
                            ) +
                              parseInt(item.pharmacyEndTime.substring(3, 5)) &&
                          item.promoteStu == "0") ||
                        titlecode
                          ? item.checkStatus
                          : 0,
                      checked:
                        (parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
                          parseInt(item.pharmacyStartTime.substring(3, 5)) <
                          nowTime &&
                          nowTime <
                            parseInt(
                              item.pharmacyEndTime.substring(0, 2) * 60
                            ) +
                              parseInt(item.pharmacyEndTime.substring(3, 5)) &&
                          item.promoteStu == "0") ||
                        titlecode
                          ? item.checked
                          : false,
                      promoteStu: item.promoteStu,
                      pharmacyId: item.pharmacyId,
                      pharmacyName: item.pharmacyName,
                      promoteFull: item.promoteFull,
                      promoteCut: item.promoteCut,
                      freeFreight: item.freeFreight,
                      minFreight: item.minFreight,
                      orderFreight: item.orderFreight,
                      pharmacyStartTime: item.pharmacyStartTime,
                      pharmacyEndTime: item.pharmacyEndTime,
                      medClassify: item.medClassify,
                      pharmacyPackingFee: item.pharmacyPackingFee,
                      medApparatus: item.medApparatus,
                      medValidity: item.medValidity,
                      medEnterprise: item.medEnterprise,
                      // activitiesDiscount:item.activitiesDiscount,
                      // activitiesName:item.activitiesName,
                      // activitiesStartTime:item.activitiesStartTime,
                      // activitiesEndTime:item.activitiesEndTime,
                    });
                  }
                });
                newCartArr.push({
                  pharmacy: {
                    pharmacyId: arritem[0].pharmacyId,
                    pharmacyName: arritem[0].pharmacyName,
                    freeFreight: arritem[0].freeFreight,
                    minFreight: arritem[0].minFreight,
                    promoteStu: arritem[0].promoteStu,
                    orderFreight: arritem[0].orderFreight,
                    pharmacyStartTime: arritem[0].pharmacyStartTime,
                    pharmacyEndTime: arritem[0].pharmacyEndTime,
                    isClassify: flag0 ? 2 : 1,
                  },
                  medList: arritem,
                });
                // console.log(arritem);
                let code = true;
                arritem.forEach((item) => {
                  if (item.checkStatus == 0) {
                    code = false;
                  }
                });
                pharmacyList.push({
                  pharmacyId: arritem[0].pharmacyId,
                  pharmacyName: arritem[0].pharmacyName,
                  freeFreight: arritem[0].freeFreight,
                  minFreight: arritem[0].minFreight,
                  orderFreight: arritem[0].orderFreight,
                  promoteStu: arritem[0].promoteStu,
                  checked: code,
                  pharmacyEndTime: arritem[0].pharmacyEndTime,
                  pharmacyPackingFee: arritem[0].pharmacyPackingFee,
                  pharmacyStartTime: arritem[0].pharmacyStartTime,
                });
              }
              newCartArr.map((item) => {
                const newitemlist = item.medList.filter((item2) => {
                  return item2.checkStatus == 1;
                });
                item.medList = newitemlist;
                return item;
              });
              let checkedArr = newCartArr.filter((item) => {
                return item.medList.length > 0;
              });
              if (checkedArr.length) {
                setNowstoreId(checkedArr[0].pharmacy.pharmacyId);
              } else {
                setNowstoreId(1);
              }
              setCartArr(checkedArr);
            }
            let allstatus = true;
            console.log(pharmacyList);
            pharmacyList.map((item) => {
              if (!item.checked) {
                allstatus = false;
              }
            });
            setAllChecked(allstatus);
            setCartList(data);
            setPharmacyList(pharmacyList);
            setRecommand(data1);
          } else {
            setCartList([]);
            setPharmacyList([]);
            setRecommand([]);
          }
          setLoading(true);
        })
        .catch((err) => {
          setLoading(true);
        });
    }
  };
  // console.log(maxUserdiscount);
  //购物车一个店铺所有商品选中
  const checkStore = async (e, shop, index) => {
    // debugger
    //设置某个店铺商品选择状态
    // console.log(e.target.checked);
    //修改购物车一个店铺所有商品状态
    const res = await changestoreallState({
      checkStatus: e.target.checked ? 1 : 0,
      userId: userId,
      pharmacyId: shop.pharmacyId,
    });
    // console.log(shop);
    if (res.code == 1) {
      getCartList(userId);
      //console.log(shop.pharmacyId);
      setNowstoreId(shop.pharmacyId);
      setNowstorename(shop.pharmacyName);
      setorderFreight(shop.orderFreight);
      // setpharmacyPackingFee(shop.pharmacyPackingFee?shop.pharmacyPackingFee:0)
      setminFreight(shop.minFreight);
      let count = 0;
      // 选中药店的所有商品信息
      let medList = cartList;
      console.log(cartList);
      // 店铺详情
      let storeList = pharmacyList;
      let newCartArr = [];
      let SUM = cartSum;
      let sumPrice = cartPrice;
      let anymoney = maxUserdiscount;
      let money2 = activitiesPrice; //满减商品总金额
      // console.log(cartPrice);
      let medIdArr = [];
      //let statusarr=[]
      storeList[index].checked = e.target.checked;
      if (e.target.checked) {
        console.log(medList);
        medList.forEach((item) => {
          // statusarr.push({
          //     checkStatus:item.checked?0:1,
          //     cartId:item.cartId
          // })
          if (!item.checked && item.pharmacyId === shop.pharmacyId) {
            count++;
            item.checked = true;
            medIdArr.push({
              cartId: item.cartId,
              medId: item.medId,
              medName: item.medName,
              medPhoto: item.medPhoto,
              medSpec: item.medSpec,
              medPacking: item.medPacking,
              medQrcode: item.medQrcode,
              medApproval: item.medApproval,
              medCompany: item.medCompany,
              medPrice: item.medPrice,
              cartPrice: item.cartPrice,
              medQuantity: item.medQuantity,
              checked: true,
              checkStatus: 1,
              // activitiesCycle:item.activitiesCycle,
              // activitiesDiscount:item.activitiesDiscount,
              // activitiesName:item.activitiesName,
              // activitiesStartTime:item.activitiesStartTime,
              // activitiesEndTime:item.activitiesEndTime,
            });
            SUM += 1;
            sumPrice += item.medPrice * item.medQuantity;
            // if (!item.discountId||item.discountLimits==item.discountMedicineCount||(item.discountStock==0)) {
            //     sumPrice += item.medPrice * item.medQuantity
            // }else{
            //     if (item.percentage==-1) {
            //         // 库存不限
            //         if (item.discountStock==-1) {
            //             if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                 sumPrice += item.discountPrice * item.medQuantity
            //                 anymoney +=(item.medPrice-item.discountPrice) * item.medQuantity
            //             }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                 sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                 anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //             }
            //             // 限库存
            //         }else{
            //             if (item.discountStock>=item.medQuantity) {
            //                 if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                     sumPrice += item.discountPrice * item.medQuantity
            //                     anymoney +=(item.medPrice-item.discountPrice) * item.medQuantity
            //                 }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                     sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //                 }
            //             }else{
            //                 if (item.discountStock>=(item.discountLimits-item.discountMedicineCount)) {
            //                     sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //                 }else{
            //                     sumPrice += item.discountPrice * item.discountStock
            //                     anymoney +=(item.medPrice-item.discountPrice) * item.discountStock
            //                 }
            //             }
            //         }
            //         // 折扣
            //     }else if(item.percentage!=-1) {
            //         if (item.discountStock==-1) {
            //             if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                 // sumPrice += item.medPrice*item.percentage/10 * item.medQuantity
            //                 anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.medQuantity
            //             }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                 // sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                 anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //             }
            //         }else{
            //             if (item.discountStock>=item.medQuantity) {
            //                 if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                     // sumPrice += item.medPrice*item.percentage/10 * item.medQuantity
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.medQuantity
            //                 }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                     // sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //                 }
            //             }else{
            //                 if (item.discountStock>=(item.discountLimits-item.discountMedicineCount)) {
            //                     // sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //                 }else{
            //                     // sumPrice += item.medPrice*item.percentage/10 * item.discountStock
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.discountStock
            //                 }
            //             }
            //         }
            //     }
            // // }
            if (item.activitiesId) {
              money2 += item.medQuantity * item.medPrice;
            }
          }
        });
        newCartArr.push({
          pharmacy: {
            pharmacyId: shop.pharmacyId,
            pharmacyName: shop.pharmacyName,
            freeFreight: shop.freeFreight,
            minFreight: shop.minFreight,
            orderFreight: shop.orderFreight,
            pharmacyStartTime: shop.pharmacyStartTime,
            pharmacyEndTime: shop.pharmacyEndTime,
          },
          medList: medIdArr,
        });
        console.log(newCartArr);
        if (count === medList.length) {
          setAllChecked(true);
        }
        setCartArr(newCartArr);
      } else {
        //console.log(medList);
        setCartArr([]);
        medList.forEach((item) => {
          if (item.checked && item.pharmacyId === shop.pharmacyId) {
            item.checked = false;
            SUM -= 1;
            sumPrice += item.medPrice * item.medQuantity;
            // if (!item.discountId||item.discountLimits==item.discountMedicineCount||(item.discountStock==0)) {
            //     sumPrice += item.medPrice * item.medQuantity
            // }else{
            //     if (item.percentage==-1) {
            //         // 库存不限
            //         if (item.discountStock==-1) {
            //             if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                 sumPrice += item.discountPrice * item.medQuantity
            //                 anymoney +=(item.medPrice-item.discountPrice) * item.medQuantity
            //             }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                 sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                 anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //             }
            //             // 限库存
            //         }else{
            //             if (item.discountStock>=item.medQuantity) {
            //                 if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                     sumPrice += item.discountPrice * item.medQuantity
            //                     anymoney +=(item.medPrice-item.discountPrice) * item.medQuantity
            //                 }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                     sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //                 }
            //             }else{
            //                 if (item.discountStock>=(item.discountLimits-item.discountMedicineCount)) {
            //                     sumPrice += item.discountPrice * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.discountPrice) * (item.discountLimits-item.discountMedicineCount)
            //                 }else{
            //                     sumPrice += item.discountPrice * item.discountStock
            //                     anymoney +=(item.medPrice-item.discountPrice) * item.discountStock
            //                 }
            //             }
            //         }
            //         // 折扣
            //     }else if(item.percentage!=-1) {
            //         if (item.discountStock==-1) {
            //             if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                 sumPrice += item.medPrice*item.percentage/10 * item.medQuantity
            //                 anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.medQuantity
            //             }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                 sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                 anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //             }
            //         }else{
            //             if (item.discountStock>=item.medQuantity) {
            //                 if (item.discountLimits==-1||(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount>=item.medQuantity))) {
            //                     sumPrice += item.medPrice*item.percentage/10 * item.medQuantity
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.medQuantity
            //                 }else if(item.discountLimits!=-1&&(item.discountLimits-item.discountMedicineCount<item.medQuantity)){
            //                     sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //                 }
            //             }else{
            //                 if (item.discountStock>=(item.discountLimits-item.discountMedicineCount)) {
            //                     sumPrice += item.medPrice*item.percentage/10 * (item.discountLimits-item.discountMedicineCount)+item.medPrice * (item.medQuantity-(item.discountLimits-item.discountMedicineCount))
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * (item.discountLimits-item.discountMedicineCount)
            //                 }else{
            //                     sumPrice += item.medPrice*item.percentage/10 * item.discountStock
            //                     anymoney +=(item.medPrice-item.medPrice*item.percentage/10) * item.discountStock
            //                 }
            //             }
            //         }
            //     }
            // }
            if (item.activitiesId) {
              money2 -= item.medQuantity * item.medPrice;
            }
          }
        });
        setAllChecked(false);
      }
      setCartPrice(sumPrice);
      setmaxUserdiscount(anymoney); //活动商品优惠金额
      setactivitiesPrice(money2);
      setCartSum(SUM);
      setPharmacyList(storeList);
      setCartList(medList);
      setRefresh(true);
    }
  };
  useEffect(() => {
    if (cartPrice) {
      // getCartList(userId)
    }
  }, [cartPrice]);
  //编辑管理
  const editManage = () => {
    setTitlecode(!titlecode);
    // 将购物车所有商品状态改为未选中状态
    allStatus(0, userId).then((res) => {
      if (res.code == 1) {
        let medList = cartList;
        let storeList = pharmacyList;
        console.log(medList);
        console.log(storeList);

        medList.forEach((item) => {
          item.checked = false;
        });
        storeList.forEach((item) => {
          item.checked = false;
        });
        console.log(medList);
        if (deleteStaus) {
          getCartList(userId);
        }
        setCartList(medList);
        setPharmacyList(storeList);
        setCartArr([]);
        setCartSum(0);
        setCartPrice(0);
        setactivitiesPrice(0);
        setAllChecked(false);
        setdeleteStaus(!deleteStaus);
      }
    });
  };
  //小单选按钮事件
  const changebuy = async (e, v, index, i) => {
    // console.log(v);
    if (!deleteStaus) {
      console.log(v);
      //修改购物车列表状态
      const res = await changeState({
        cartId: v.cartId,
        checkStatus: v.checkStatus == 0 ? 1 : 0,
      });
      // console.log(res)
      getCartList(userId);
    }

    setNowstoreId(v.pharmacyId);
    setNowstorename(v.pharmacyName);
    setorderFreight(v.orderFreight);
    // setpharmacyPackingFee(v.pharmacyPackingFee)
    setminFreight(v.minFreight);
    let count = 0;
    let storeCount = 0;
    let storeSum = 0;
    let medList = cartList;
    let storeList = pharmacyList;
    let newCartArr = [];
    let SUM = cartSum;
    let sumPrice = cartPrice;
    let anymoney = maxUserdiscount;
    let money3 = activitiesPrice;
    const n = newCartArr.findIndex(
      (item) => item.pharmacy.pharmacyId === v.pharmacyId
    );
    medList[index].checked = e.target.checked;
    if (e.target.checked) {
      SUM += 1;
      sumPrice += v.medPrice * v.medQuantity;
      if (v.activitiesId) {
        money3 += v.medQuantity * v.medPrice;
      }
      if (n === -1) {
        const newarr = medList.filter((item) => {
          return item.checked;
        });
        console.log(newarr);
        let medIdArr = [];
        newarr.forEach((item) => {
          medIdArr.push({
            cartId: item.cartId,
            medId: item.medId,
            medName: item.medName,
            medPhoto: item.medPhoto,
            medSpec: item.medSpec,
            medPacking: item.medPacking,
            medApproval: item.medApproval,
            medCompany: item.medCompany,
            medPrice: item.medPrice,
            medQrcode: item.medQrcode,
            cartPrice: item.cartPrice,
            medQuantity: item.medQuantity,
            medClassify: item.medClassify,
            // activitiesCycle:item.activitiesCycle,
            // activitiesDiscount:item.activitiesDiscount,
            // activitiesName:item.activitiesName,
            // activitiesStartTime:item.activitiesStartTime,
            // activitiesEndTime:item.activitiesEndTime,
          });
        });
        newCartArr.push({
          pharmacy: {
            pharmacyId: v.pharmacyId,
            pharmacyName: v.pharmacyName,
            freeFreight: v.freeFreight,
            minFreight: v.minFreight,
            orderFreight: v.orderFreight,
            pharmacyStartTime: v.pharmacyStartTime,
            pharmacyEndTime: v.pharmacyEndTime,
            isClassify: v.medClassify == "1" ? 2 : 1,
          },
          medList: medIdArr,
        });
      } else {
        newCartArr[n].medList.push({
          cartId: v.cartId,
          medId: v.medId,
          medName: v.medName,
          medPhoto: v.medPhoto,
          medSpec: v.medSpec,
          medPacking: v.medPacking,
          medPrice: v.medPrice,
          medQrcode: v.medQrcode,
          cartPrice: v.cartPrice,
          medQuantity: v.medQuantity,
          medClassify: v.medClassify,
          // activitiesCycle:v.activitiesCycle,
          // activitiesDiscount:v.activitiesDiscount,
          // activitiesName:v.activitiesName,
          // activitiesStartTime:v.activitiesStartTime,
          // activitiesEndTime:v.activitiesEndTime,
        });
      }
      medList.forEach((item, index) => {
        if (item.checked) {
          count++;
          if (item.pharmacyId === v.pharmacyId) {
            storeCount++; //购物车中每个店铺选中的商品种类
          }
        }
        if (item.pharmacyId === v.pharmacyId) {
          storeSum++; //购物车中每个店铺商品种类
        }
      });
      if (count === medList.length) {
        setAllChecked(true);
      }
      console.log(storeCount, storeSum);
      // if (storeCount === storeSum) {
      storeList[i].checked = true;
      // }
      console.log(storeList[i].checked);
    } else {
      SUM -= 1;
      sumPrice -= v.cartPrice;
      if (v.activitiesId) {
        money3 -= v.cartPrice;
      }
      console.log(sumPrice);
      const newarr = medList.filter((item) => {
        return item.checked;
      });
      newarr.forEach((item) => {
        newCartArr.push({
          pharmacy: {
            pharmacyId: item.pharmacyId,
            pharmacyName: item.pharmacyName,
            freeFreight: v.freeFreight,
            minFreight: v.minFreight,
            orderFreight: v.orderFreight,
            pharmacyStartTime: v.pharmacyStartTime,
            pharmacyEndTime: v.pharmacyEndTime,
            isClassify: v.medClassify == "1" ? 2 : 1,
          },
          medList: [
            {
              cartId: item.cartId,
              medId: item.medId,
              medName: item.medName,
              medPhoto: item.medPhoto,
              medSpec: item.medSpec,
              medPacking: item.medPacking,
              medApproval: item.medApproval,
              medQrcode: item.medQrcode,
              medCompany: item.medCompany,
              medPrice: item.medPrice,
              cartPrice: item.cartPrice,
              medQuantity: item.medQuantity,
              medClassify: item.medClassify,
              // activitiesCycle:item.activitiesCycle,
              // activitiesDiscount:item.activitiesDiscount,
              // activitiesName:item.activitiesName,
              // activitiesStartTime:item.activitiesStartTime,
              // activitiesEndTime:item.activitiesEndTime,
            },
          ],
        });
      });

      storeList[i].checked = false;
      setAllChecked(false);
    }
    setCartPrice(sumPrice);
    setmaxUserdiscount(anymoney); //活动商品优惠金额
    setactivitiesPrice(money3);
    setCartSum(SUM);
    setCartArr(newCartArr);
    setPharmacyList(storeList);
    setCartList(medList);
    // setRefresh(true)
  };
  // 所有店铺全选
  const allCartChecked = (e) => {
    let medList = cartList;
    let storeList = pharmacyList;
    // let newCartArr = cartArr
    let newCartArr = [];
    let SUM = 0;
    let sumPrice = 0;
    let anymoney = 0;
    let money4 = 0;
    console.log(newCartArr);
    if (!titlecode) {
      medList.forEach((item) => {
        if (
          parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
            parseInt(item.pharmacyStartTime.substring(3, 5)) <
            nowTime &&
          nowTime <
            parseInt(item.pharmacyEndTime.substring(0, 2) * 60) +
              parseInt(item.pharmacyEndTime.substring(3, 5)) &&
          item.promoteStu == "0"
        ) {
          item.checked = e.target.checked;
        }
      });
      storeList.forEach((item) => {
        if (
          parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
            parseInt(item.pharmacyStartTime.substring(3, 5)) <
            nowTime &&
          nowTime <
            parseInt(item.pharmacyEndTime.substring(0, 2) * 60) +
              parseInt(item.pharmacyEndTime.substring(3, 5)) &&
          item.promoteStu == "0"
        ) {
          item.checked = e.target.checked;
        }
      });
    } else {
      medList.forEach((item) => {
        item.checked = e.target.checked;
      });
      storeList.forEach((item) => {
        item.checked = e.target.checked;
      });
    }
    if (e.target.checked) {
      let flag1 = false;
      medList.map((item) => {
        if (item.medClassify == "1") {
          flag1 = true;
        }
      });
      medList.forEach((item) => {
        console.log(item);
        if (
          type == "1" &&
          parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
            parseInt(item.pharmacyStartTime.substring(3, 5)) <
            nowTime &&
          nowTime <
            parseInt(item.pharmacyEndTime.substring(0, 2) * 60) +
              parseInt(item.pharmacyEndTime.substring(3, 5)) &&
          item.promoteStu == "0"
        ) {
          SUM += 1;
          sumPrice += item.medPrice * item.medQuantity;
          if (item.activitiesId) {
            money4 += item.medQuantity * item.medPrice;
          }
          const n = newCartArr.findIndex(
            (v) => v.pharmacy.pharmacyId === item.pharmacyId
          );
          console.log(n);
          if (n === -1) {
            newCartArr.push({
              pharmacy: {
                pharmacyId: item.pharmacyId,
                pharmacyName: item.pharmacyName,
                freeFreight: item.freeFreight,
                minFreight: item.minFreight,
                orderFreight: item.orderFreight,
                pharmacyStartTime: item.pharmacyStartTime,
                pharmacyEndTime: item.pharmacyEndTime,
                isClassify: flag1 ? 2 : 1,
              },
              medList: [
                {
                  cartId: item.cartId,
                  medId: item.medId,
                  medName: item.medName,
                  medPhoto: item.medPhoto,
                  medSpec: item.medSpec,
                  medPacking: item.medPacking,
                  medApproval: item.medApproval,
                  medCompany: item.medCompany,
                  medPrice: item.medPrice,
                  cartPrice: item.cartPrice,
                  medQuantity: item.medQuantity,
                  medQrcode: item.medQrcode,
                  medClassify: item.medClassify,
                  // activitiesCycle:item.activitiesCycle,
                  // activitiesDiscount:item.activitiesDiscount,
                  // activitiesName:item.activitiesName,
                  // activitiesStartTime:item.activitiesStartTime,
                  // activitiesEndTime:item.activitiesEndTime,
                },
              ],
            });
          } else {
            console.log(newCartArr);
            newCartArr[n].medList.push({
              cartId: item.cartId,
              medId: item.medId,
              medName: item.medName,
              medPhoto: item.medPhoto,
              medSpec: item.medSpec,
              medPacking: item.medPacking,
              medApproval: item.medApproval,
              medCompany: item.medCompany,
              medPrice: item.medPrice,
              cartPrice: item.cartPrice,
              medQrcode: item.medQrcode,
              medQuantity: item.medQuantity,
              medClassify: item.medClassify,
              // activitiesCycle:item.activitiesCycle,
              // activitiesDiscount:item.activitiesDiscount,
              // activitiesName:item.activitiesName,
              // activitiesStartTime:item.activitiesStartTime,
              // activitiesEndTime:item.activitiesEndTime,
            });
          }
          console.log(newCartArr);
        }
      });
      console.log(newCartArr[0].medList.length);
      if (
        newCartArr[0].medList.length == 2 &&
        newCartArr[0].medList[0].cartId == newCartArr[0].medList[1].cartId
      ) {
        newCartArr[0].medList.pop(1);
      }
      let arr0 = [];
      if (!titlecode) {
        cartList.forEach((i) => {
          if (
            i.checked &&
            parseInt(i.pharmacyStartTime.substring(0, 2) * 60) +
              parseInt(i.pharmacyStartTime.substring(3, 5)) <
              nowTime &&
            nowTime <
              parseInt(i.pharmacyEndTime.substring(0, 2) * 60) +
                parseInt(i.pharmacyEndTime.substring(3, 5))
          ) {
            arr0.push(i.pharmacyId);
          }
        });
        for (var i = 0; i < arr0.length; i++) {
          for (var j = i + 1; j < arr0.length; j++) {
            if (arr0[i] === arr0[j]) {
              arr0.splice(j, 1);
              j--;
            }
          }
        }
        setarr(arr0);
      }
    } else {
      setarr([]);
      SUM = 0;
      sumPrice = 0;
      money4 = 0;
      newCartArr = [];
      // 将购物车所有商品状态改为未选中状态
      allStatus(0, userId).then((res) => {
        console.log(res);
      });
    }
    setCartPrice(sumPrice);
    setmaxUserdiscount(anymoney); //活动商品优惠金额
    setactivitiesPrice(money4);
    setCartSum(SUM);
    setCartArr(newCartArr);
    setAllChecked(e.target.checked);
    setPharmacyList(storeList);
    setCartList(medList);
    // setRefresh(true)
  };

  //购物车加减
  const quantity = async (index, val, medQuantity) => {
    //getCartList(userId)

    if (medQuantity == 1 && val == 0) {
      Toast.show({
        content: `药品未达到起购数量`,
      });
      return;
    }
    console.log(medQuantity);
    let storearr = pharmacyList;
    let medList = cartList;
    // if (!isNaN(val)&&val%1==0&&val.toString().substr(0,1)!='-'&&val.toString().substr(0,1)!='+') {
    //     console.log(111);
    //     if (val.toString().substr(-1)=='.') {
    //         val=val.toString().substr(0,val.length-1)
    //     }
    // }else{
    //     console.log(222);
    //     val=val
    // }

    //药品详情
    const detailres = await meddetailRequest(medList[index].medId);
    console.log(val, detailres.data[3][0].stockNum);
    if (val > detailres.data[3][0].stockNum) {
      Toast.show({
        content: `该药品库存仅剩${detailres.data[3][0].stockNum}`,
      });
      val = detailres.data[3][0].stockNum;
    }
    // medList.map((item,code)=>{
    //     if (code==index) {
    //         item.checked=true
    //     }
    //     return item
    // })
    // setCartList(medList)
    let code = 0;
    let code2 = 0;
    medList.forEach((item, mun) => {
      if (item.checked && mun == index) {
        code++;
      }
    });
    storearr.forEach((item) => {
      if (item.checked) {
        code2++;
      }
    });
    let newCartArr = cartArr;
    let sumPrice = 0;
    let anymoney = 0;
    medList[index].medQuantity = val;
    medList[index].cartPrice = val * medList[index].medPrice;
    // medList[index].cartPrice = sumPrice
    medList[index].checked = true;
    medList[index].checkStatus = 1;
    //console.log(medList[index]);
    //修改购物车列表状态
    const stateres = await changeState({
      checkStatus: 1,
      cartId: medList[index].cartId,
    });
    //console.log(stateres);
    if (newCartArr.length > 0) {
      let arrson = newCartArr[0].medList;
      arrson.map((item) => {
        if (item.cartId == medList[index].cartId) {
          item.medQuantity = val;
        }
        return item;
      });
      newCartArr[0].medList = arrson;
      //    console.log(newCartArr);
    }

    //购物车修改
    updatecartRequest(val, medList[index].cartId).then((res) => {
      // console.log(res);
      if (res.code === 1) {
        setCartArr(newCartArr);
      }
    });
    let cartMoney = 0;
    let money5 = 0;
    let num = 0;
    console.log(medList);
    medList.forEach((item) => {
      if (item.checked) {
        num += 1;
        cartMoney += Number(item.cartPrice);
        if (item.activitiesId) {
          money5 += Number(item.cartPrice);
        }
      }
      //console.log(item.cartPrice);
    });
    // console.log(cartMoney);
    setCartPrice(cartMoney);
    setactivitiesPrice(money5);
    if (code > 0 || code2 > 0) {
      if (type == "2") {
        if (cartMoney / 100 > freeFreight) {
          setCartPriceNow(cartMoney);
        } else {
          setCartPriceNow(Number(cartMoney) + orderFreight * 100);
        }
      } else {
        setCartPriceNow(cartMoney);
      }
    }
    setCartList(medList);
    let SUM = 0;
    medList.forEach((item) => {
      if (item.checked) {
        SUM += 1;
      }
    });
    setCartSum(SUM);
    setRefresh(true);
    // getCartList(userId)
  };
  //   console.log(cartArr);

  //点击结算判断
  const toOrder = () => {
    if (!userId) {
      redirectDispatch("/cart");
      props.history.push("/login");
    }
    if (cartArr.length > 0) {
      console.log(cartArr);
      let arr = 0;
      cartArr[0].medList.map((item) => {
        if (item.medQuantity <= 0) {
          arr += 1;
        }
        // props.history.push({ pathname: '/fillorder', state: cartArr })
      });
      if (arr != 0) {
        Toast.show({
          content: "购买药品数量必须大于0",
          duration: 1000,
        });
      } else {
        props.history.push({
          pathname: "/fillorder",
          state: cartArr,
          params: {
            freeFreight: freeFreight,
            orderFreight: orderFreight,
            minFreight: minFreight,
          },
        });
      }
    } else {
      Toast.info("你还没有选择药品哦", 1);
    }
  };
  //清除购物车
  const delCart = () => {
    let cartIdArr = [];
    cartArr.forEach((item) => {
      item.medList.forEach((item1) => {
        cartIdArr.push(item1.cartId);
      });
    });
    console.log(cartArr);
    if (cartArr.length == 0) {
      Toast.show({
        content: "请选择需要删除的商品",
        duration: 1000,
      });
    } else {
      setVisiable7(true);
    }
  };

  // console.log(cartArr);
  const onDelete = () => {
    let cartIdArr = [];
    cartArr.forEach((item) => {
      item.medList.forEach((item1) => {
        cartIdArr.push(item1.cartId);
      });
    });
    setdeleteStaus(false);
    //购物车删除
    delCartRequest({ cartId: cartIdArr }).then((res) => {
      if (res.code === 1) {
        cref.getarrlist();
        getCartList(userId);
        editManage();
        setCartArr([]);
        window.location.reload();
      }
    });
    setRefresh(true);
    setVisiable7(false);
  };
  const addCollection = () => {
    let newCartList = cartList;
    let params = {
      array: [],
    };
    newCartList.forEach((item) => {
      params.array.push({
        userId: userId,
        medId: item.medId,
      });
    });
    if (params.array.length > 0) {
      // 加入收藏
      addcollectRequest(params).then((res) => {
        Toast.info("已加入收藏", 1);
      });
    }
  };
  // console.log(recommand);
  const renderShopContent = (tabindex) => {
    return (
      <div className="cart-shop">
        <div className="cart-nodata">
          <NoData type="购物车" />
        </div>
        <div className="recommend-med">
          <div className="recommend-med-title flex-apart-row">
            <div>推荐商品</div>
          </div>
          <div className="recommend-med-list">
            <RecommmendMed recommand={recommand} shopcart={1} />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let arr0 = [];
    if (!titlecode) {
      cartList.forEach((i) => {
        if (
          i.checked &&
          parseInt(i.pharmacyStartTime.substring(0, 2) * 60) +
            parseInt(i.pharmacyStartTime.substring(3, 5)) <
            nowTime &&
          nowTime <
            parseInt(i.pharmacyEndTime.substring(0, 2) * 60) +
              parseInt(i.pharmacyEndTime.substring(3, 5))
        ) {
          arr0.push(i.pharmacyId);
        }
      });
      for (var i = 0; i < arr0.length; i++) {
        for (var j = i + 1; j < arr0.length; j++) {
          if (arr0[i] === arr0[j]) {
            arr0.splice(j, 1);
            j--;
          }
        }
      }
    }
    setarr(arr0);
  }, [cartList]);
  useEffect(() => {
    // let res1=await cartYouhuiquan({userId, pharmacyId})
    //             console.log(res1);
    console.log(pharmacyList);
  }, [pharmacyList]);

  // console.log(activitiesPrice);
  useEffect(() => {
    let array0 = "";
    let array1 = "";
    let array2 = [];
    // 选中一个店铺
    if (arr && arr.length == 1) {
      // 满减
      if (activitiesManjian && activitiesManjian.length) {
        activitiesManjian.map((item) => {
          if (item.pharmacyId == arr) {
            array0 = item;
          }
        });
      }
      if (array0) {
        array1 = array0.activitiesDiscount;
        for (var i = 0; i < array1.length - 1; i++) {
          for (var j = 0; j < array1.length - i - 1; j++) {
            // 1.对每一个值和它的下一个值进行比较
            if (array1[j].full > array1[j + 1].full) {
              // 如果第一个值更多，则将其赋予自定义计数值 count
              var count = array1[j];
              // 反复交换
              array1[j] = array1[j + 1];
              array1[j + 1] = count;
            }
          }
        }
        console.log(array1);
        for (let i = 0; i < array1.length; i++) {
          if (array1[i].full <= activitiesPrice) {
            array2.push(array1[i]);
          }
        }
      }
      console.log(array0);
      if (array2.length) {
        setactivitiesUse(array2[array2.length - 1]);
      } else {
        setactivitiesUse("");
      }
    } else {
      setactivitiesUse("");
    }
  }, [activitiesPrice, activitiesManjian, arr]);
  useEffect(() => {
    let array = [];
    let array1 = []; //店铺可用优惠券
    let array2 = []; //购物车勾选商品多个商品存在商品优惠券
    let array3 = []; //购物车勾选商品仅一个商品存在商品优惠券
    let array4 = []; //部分商品最终可使用的商品优惠券
    let array5 = []; //所有满减
    let flag2 = false;
    if (arr && arr.length == 1) {
      console.log(arr);
      pharmacyList.map((i) => {
        if (i.pharmacyId == arr[0]) {
          setpharmacyPackingFee(
            i.pharmacyPackingFee ? i.pharmacyPackingFee : 0
          );
        }
      });
      // 优惠券
      cartYouhuiquan({ userId, pharmacyId: arr }).then((res) => {
        console.log(res);
        cartList.map((item) => {
          // 选中的药品
          if (item.checked) {
            array.push(item);
          }
        });
        if (res.data[1].length) {
          res.data[1].map((i) => {
            if (i.couponMoney <= cartPrice) {
              array1.push(i); //店铺满减可使用
            }
          });
        }
        if (res.data[2] && res.data[2].length) {
          array.map((i) => {
            res.data[2].map((v) => {
              if (i.cartId == v.cartId) {
                v.medPrice = i.medPrice;
                v.medQuantity = i.medQuantity;
                v.date = [v.cartId];
                v.medPriceAll = i.medPrice * i.medQuantity;
                array2.push(v);
              }
            });
          });
        }
        if (array2.length) {
          for (let i = 0; i < array2.length; i++) {
            for (let j = i + 1; j < array2.length; j++) {
              if (array2[i].couponManagerId == array2[j].couponManagerId) {
                array2[i].date.push(array2[j].cartId);
                array2[i].medPriceAll +=
                  array2[j].medPrice * array2[j].medQuantity;
                array2.splice(j, 1);
                j--;
                flag2 = true;
              }
            }
          }
        }
        if (!flag2) {
          array2.map((i) => {
            if (i.medQuantity * i.medPrice > i.couponMoney) {
              array3.push(i);
            }
          });
        } else {
          array2.map((i) => {
            if (i.couponMoney < i.medPriceAll) {
              array4.push(i);
            }
          });
        }
        array5 = array1.concat(array4);
        // console.log(array5);
        if (array5 && array5.length) {
          let a = array5[0].couponLimit;
          for (let i = 0; i < array5.length; i++) {
            if (array5[i].couponLimit > a) {
              a = array5[i].couponLimit;
            }
          }
          console.log(a);
          setmaxUsercoupon(a);
        } else {
          setmaxUsercoupon(0);
        }
        // 可使用的店铺优惠券
        setcouponDetails(array1);
        //部分商品满减可使用的优惠券
        setusercouponDetails(array4);
      });
    }
  }, [arr, cartList, cartPrice]);
  // 折扣商品
  useEffect(() => {
    // console.log(discountDetails);
    // console.log(cartList);
  }, [arr, cartList, discountDetails]);
  console.log(cartList);
  const renderMedContent = (tabindex) => {
    return (
      <div>
        {cartList.length > 0 ? (
          <div>
            {visiable7 ? (
              <div className="del-address-outer">
                <div className="del-address">
                  <p className="del-sure">是否删除已选中的商品？</p>
                  <p className="del-bottom">
                    <span onClick={() => setVisiable7(false)}>取消</span>
                    <span onClick={() => onDelete()}>确认</span>
                  </p>
                </div>
              </div>
            ) : null}
            <div className="cart-show-part">
              {pharmacyList.map((shop, i) => {
                return (
                  <div key={i}>
                    {(parseInt(shop.pharmacyStartTime.substring(0, 2) * 60) +
                      parseInt(shop.pharmacyStartTime.substring(3, 5)) <
                      nowTime &&
                      nowTime <
                        parseInt(shop.pharmacyEndTime.substring(0, 2) * 60) +
                          parseInt(shop.pharmacyEndTime.substring(3, 5)) &&
                      shop.promoteStu == "0") ||
                    titlecode ? (
                      <div className="cart-part flex-col">
                        <div className="cart-drugstore flexc">
                          <CheckboxItem
                            onChange={(e) => {
                              checkStore(e, shop, i);
                            }}
                            checked={shop.checked}
                          >
                            {shop.pharmacyName}
                            {type == "2" ? (
                              <button
                                style={{
                                  backgroundColor: "#FF5555",
                                  color: "white",
                                  border: "none",
                                  float: "right",
                                  width: "43px",
                                  height: "20px",
                                  borderRadius: "10px",
                                }}
                              >
                                领卷
                              </button>
                            ) : null}{" "}
                          </CheckboxItem>
                          <div className="cart-drugstore-activity ">
                            {type == "1" ? (
                              <div className="drugstore-manjian flexr">
                                {shop.minFreight ? (
                                  <span>
                                    满{(shop.minFreight / 100).toFixed(2)}起送
                                  </span>
                                ) : (
                                  <span>满0.00元起送</span>
                                )}
                                {/* {shop.freeFreight ? <span>满{(shop.freeFreight/100).toFixed(2)}包邮</span> :  <span>满0.00元包邮</span>} */}
                              </div>
                            ) : null}
                            <div
                              onClick={() =>
                                props.history.push(`/shop/${shop.pharmacyId}`)
                              }
                            >
                              <span>去逛逛</span>
                              <i className="iconfont icon-xiangyou"></i>
                            </div>
                          </div>
                        </div>
                        {cartList.map((v, index) => {
                          return shop.pharmacyId === v.pharmacyId ? (
                            <div key={index} className="cart-drugstore flexc">
                              <div className="cart-med">
                                <div className="cart-item-select">
                                  <CheckboxItem
                                    onChange={(e) => changebuy(e, v, index, i)}
                                    checked={v.checked}
                                    className="my-radio"
                                  />
                                </div>
                                <div
                                  className="cart-med-info"
                                  onClick={() =>
                                    props.history.push(`/med/${v.medId}`)
                                  }
                                >
                                  {/* src={v.medPhoto ? v.medPhoto : defaultImg} */}
                                  <img
                                    src={
                                      v.medClassify === "1"
                                        ? zhezaho
                                        : v.medPhoto
                                        ? v.medPhoto
                                        : defaultImg
                                    }
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = defaultImg;
                                    }}
                                  />
                                  <div className="cart-med-detail flexc">
                                    <div className="flexc">
                                      {v.medClassify === "1" ? (
                                        <p className="med-name-cart">
                                          <span className="cfy">Rx</span>
                                          {v.medName}
                                        </p>
                                      ) : (
                                        <p className="med-name-cart">
                                          {v.medName}
                                        </p>
                                      )}
                                      <span>{v.medSpec}</span>
                                    </div>
                                    {v.activitiesDiscount ? (
                                      <p className="manjian-outer">
                                        {v.activitiesDiscount.map((a) => (
                                          <span className="manjian">
                                            满{a.full / 100}减{a.minus / 100}
                                          </span>
                                        ))}
                                      </p>
                                    ) : null}
                                    <p>￥{v.medPrice / 100}</p>
                                  </div>
                                </div>

                                {titlecode ? (
                                  <Stepper
                                    showNumber={true}
                                    style={{
                                      "--border-inner": "none",
                                      "--button-background-color": "#F8F9FA",
                                      "--height": "30px",
                                      "--input-width": "40px",
                                      "--active-border": "1px solid #1677ff",
                                      "--button-text-color":
                                        type == "2" ? "red" : "blue",
                                    }}
                                    // max={10}
                                    // min={1}
                                    className="changnum"
                                    value={v.medQuantity}
                                    inputReadOnly={true}
                                    disabled={true}
                                  />
                                ) : (
                                  <Stepper
                                    showNumber={true}
                                    style={{
                                      "--border-inner": "none",
                                      "--button-background-color": "#F8F9FA",
                                      "--height": "30px",
                                      "--input-width": "40px",
                                      "--active-border": "1px solid #1677ff",
                                      "--button-text-color":
                                        type == "2" ? "red" : "blue",
                                    }}
                                    // max={10}
                                    min={1}
                                    className="changnum"
                                    value={v.medQuantity}
                                    digits={0}
                                    // inputReadOnly={true}
                                    onChange={(value) => {
                                      quantity(index, value, v.medQuantity);
                                    }}
                                  />
                                )}
                              </div>
                              {index !== cartList.length - 1 ? (
                                <div
                                  style={{
                                    height: "1px",
                                    width: "93%",
                                    marginLeft: "20px",
                                    backgroundColor: "#DDDFE5",
                                  }}
                                ></div>
                              ) : null}
                            </div>
                          ) : null;
                        })}
                        <WhiteSpace></WhiteSpace>
                        <div className="other-tishi">
                          {/* <p >
                                                    <span>配送费</span>
                                                    {cartPrice>=freeFreight?<span>+ ¥0.00</span>
                                                    :<span>+ ¥{(orderFreight/100).toFixed(2)}</span>}
                                                </p> */}
                          <p>
                            <span>打包费</span>
                            <span>
                              {" "}
                              ¥
                              {shop.pharmacyPackingFee
                                ? (shop.pharmacyPackingFee / 100).toFixed(2)
                                : "0.00"}
                            </span>
                          </p>
                          {/* <p>
                                                    <span>优惠劵</span>
                                                    {arr.length==1&&arr[0]==shop.pharmacyId?<span style={{ color: '#F34848' }}>- ¥{(maxUsercoupon/100).toFixed(2)}</span>:
                                                    <span style={{ color: '#F34848' }}>- ¥{(0/100).toFixed(2)}</span>}
                                                </p>
                                                <p>
                                                    <span>满减</span>
                                                    {activitiesUse&&activitiesUse.minus&&arr.length==1&&arr[0]==shop.pharmacyId?<span style={{ color: '#F34848' }}>- ¥{(activitiesUse.minus/100).toFixed(2)}</span>:
                                                    <span style={{ color: '#F34848' }}>- ¥{(0/100).toFixed(2)}</span>}
                                                </p> */}
                          <p>
                            <span>折扣优惠</span>
                            {arr.length > 1 && !titlecode ? (
                              <span style={{ color: "#F34848" }}>- ¥0.00</span>
                            ) : (
                              <>
                                {arr[0] == shop.pharmacyId ? (
                                  <span style={{ color: "#F34848" }}>
                                    - ¥{(maxUserdiscount / 100).toFixed(2)}
                                  </span>
                                ) : (
                                  <span style={{ color: "#F34848" }}>
                                    - ¥0.00
                                  </span>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="cart-part flex-col"
                        style={{ backgroundColor: "#fff", opacity: "0.8" }}
                      >
                        <div className="cart-drugstore flexc">
                          <div
                            style={{
                              marginLeft: "15px",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <input
                              type="radio"
                              style={{ marginRight: "15px" }}
                              disabled
                            />
                            <span style={{ fontSize: "15px", color: "#999" }}>
                              {shop.pharmacyName}
                            </span>
                            <span style={{ color: "#999" }}>(店铺已打烊)</span>
                          </div>
                          <p
                            style={{
                              backgroundColor: "#ababab",
                              marginLeft: "40px",
                              width: "100px",
                              textAlign: "center",
                              padding: "5px 10px",
                              color: "#fff",
                            }}
                          >
                            店铺已打烊
                          </p>
                        </div>
                        {cartList.map((v, index) => {
                          return shop.pharmacyId === v.pharmacyId ? (
                            <div key={index} className="cart-drugstore flexc">
                              <div className="cart-med">
                                <div className="cart-item-select">
                                  {/* <CheckboxItem onChange={(e) => changebuy(e, v, index, i)} checked={v.checked} className='my-radio' /> */}
                                  <input type="radio" disabled />
                                </div>
                                <div className="cart-med-info">
                                  <img
                                    src={
                                      v.medClassify === "1"
                                        ? zhezaho
                                        : v.medPhoto
                                        ? v.medPhoto
                                        : defaultImg
                                    }
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = defaultImg;
                                    }}
                                  />
                                  <div className="cart-med-detail flexc">
                                    <div className="flexc">
                                      {v.medClassify === "1" ? (
                                        <p style={{ color: "#999" }}>
                                          <span className="cfy cfy1">Rx</span>
                                          {v.medName}
                                        </p>
                                      ) : (
                                        <p style={{ color: "#999" }}>
                                          {v.medName}
                                        </p>
                                      )}
                                      <span style={{ color: "#999" }}>
                                        {v.medSpec}
                                      </span>
                                    </div>
                                    <p style={{ color: "#999" }}>
                                      ￥{v.medPrice / 100}
                                    </p>
                                  </div>
                                </div>

                                <Stepper
                                  showNumber={true}
                                  style={{
                                    "--border-inner": "none",
                                    "--button-background-color": "#F8F9FA",
                                    "--height": "30px",
                                    "--input-width": "40px",
                                    "--active-border": "1px solid #1677ff",
                                    "--button-text-color":
                                      type == "2" ? "red" : "blue",
                                  }}
                                  // max={10}
                                  // min={1}
                                  className="changnum"
                                  value={v.medQuantity}
                                />
                              </div>
                              {index !== cartList.length - 1 ? (
                                <div
                                  style={{
                                    height: "1px",
                                    width: "93%",
                                    marginLeft: "20px",
                                    backgroundColor: "#DDDFE5",
                                  }}
                                ></div>
                              ) : null}
                            </div>
                          ) : null;
                        })}
                        <WhiteSpace></WhiteSpace>
                        <div className="other-tishi">
                          {/* <p >
                                                <span>配送费</span>
                                                {cartPrice>=freeFreight?<span>- ¥0.00</span>
                                                :<span>- ¥{(orderFreight/100).toFixed(2)}</span>}
                                            </p> */}
                          <p>
                            <span>打包费</span>
                            <span>
                              {" "}
                              ¥
                              {shop.pharmacyPackingFee
                                ? (shop.pharmacyPackingFee / 100).toFixed(2)
                                : "0.00"}
                            </span>
                          </p>
                          {/* <p>
                                                <span>优惠劵</span>
                                                {arr.length==1&&arr[0]==shop.pharmacyId?<span style={{ color: '#F34848' }}>- ¥{(maxUsercoupon/100).toFixed(2)}</span>:
                                                <span style={{ color: '#F34848' }}>- ¥{(0/100).toFixed(2)}</span>}
                                            </p>
                                            <p>
                                                <span>满减</span>
                                                {activitiesUse&&activitiesUse.minus&&arr.length==1&&arr[0]==shop.pharmacyId?<span style={{ color: '#F34848' }}>- ¥{(activitiesUse.minus/100).toFixed(2)}</span>:
                                                <span style={{ color: '#F34848' }}>- ¥{(0/100).toFixed(2)}</span>}
                                            </p> */}
                          <p>
                            <span>折扣优惠</span>
                            {arr.length > 1 && !titlecode ? (
                              <span style={{ color: "#F34848" }}>- ¥0.00</span>
                            ) : (
                              <>
                                {arr[0] == shop.pharmacyId ? (
                                  <span style={{ color: "#F34848" }}>
                                    - ¥{(maxUserdiscount / 100).toFixed(2)}
                                  </span>
                                ) : (
                                  <span style={{ color: "#F34848" }}>
                                    - ¥0.00
                                  </span>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                    <WhiteSpace />
                  </div>
                );
              })}
              <div className="recommend-med">
                <div className="recommend-med-title flex-apart-row">
                  <div>平台推荐</div>
                </div>
                <div className="recommend-med-list cart-med-list">
                  {recommand.length === 0 ? null : (
                    <RecommmendMed
                      sunthis={cref}
                      getCartList={getCartList}
                      recommand={recommand}
                      shopcart={1}
                    />
                  )}
                </div>
              </div>
            </div>
            {arr.length > 1 && !titlecode ? (
              <div>
                <div className="tishi-jiesuan">暂时仅支持单店铺下单</div>
                <div className="xiaosanjiao"></div>
              </div>
            ) : null}
            <div className="cart-sum flex-apart-row">
              <CheckboxItem
                checked={allChecked}
                onChange={(e) => {
                  allCartChecked(e);
                }}
                className="my-radio"
              >
                全选
              </CheckboxItem>
              {titlecode ? (
                <div className="edit-cart flex-apart-row">
                  <div onClick={() => delCart()}>删除</div>
                </div>
              ) : type == "2" ? (
                <div className="cart-to-order flex-apart-row"></div>
              ) : (
                <>
                  {cartPrice >= minFreight ? (
                    <div className="cart-to-order flex-apart-row">
                      <p className="manjian-outer">
                        <p className="manjian-inner">
                          <p>总计：{cartSum}件</p>
                          {cartSum > 0 ? (
                            <>
                              <p>
                                ￥
                                {(
                                  (cartPrice +
                                    pharmacyPackingFee -
                                    maxUserdiscount) /
                                  100
                                ).toFixed(2)}
                              </p>
                              {/* {activitiesUse&&activitiesUse.minus?<p>￥{((cartPrice-activitiesUse.minus+pharmacyPackingFee-maxUsercoupon) / 100).toFixed(2)}</p>:<p>￥{((cartPrice+pharmacyPackingFee-maxUsercoupon) / 100).toFixed(2)}</p>} */}
                            </>
                          ) : (
                            <p>￥0</p>
                          )}
                        </p>
                      </p>
                      {arr.length <= 1 && cartSum > 0 ? (
                        <div
                          onClick={() => toOrder()}
                          className="cart-count flex-row"
                        >
                          去结算
                        </div>
                      ) : (
                        <div
                          className="cart-count flex-row"
                          style={{ backgroundColor: "#CCCCCC" }}
                        >
                          去结算
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="cart-to-order flex-apart-row">
                      <p>总计：{cartSum}件</p>
                      {cartSum > 0 ? (
                        <>
                          <p>
                            ￥
                            {(
                              (cartPrice +
                                pharmacyPackingFee -
                                maxUserdiscount) /
                              100
                            ).toFixed(2)}
                          </p>
                          {/* {activitiesUse&&activitiesUse.minus?<p>￥{((cartPrice-activitiesUse.minus+pharmacyPackingFee-maxUsercoupon) / 100).toFixed(2)}</p>:<p>￥{((cartPrice+pharmacyPackingFee-maxUsercoupon) / 100).toFixed(2)}</p>} */}
                        </>
                      ) : (
                        <p>￥0</p>
                      )}
                      {cartSum > 0 ? (
                        <div
                          className="cart-count flex-row"
                          style={{ backgroundColor: "#CCCCCC" }}
                        >
                          差{((minFreight - cartPrice) / 100).toFixed(2)}起送
                        </div>
                      ) : (
                        <div
                          className="cart-count flex-row"
                          style={{ backgroundColor: "#CCCCCC" }}
                        >
                          去结算
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="cart-nodata">
              <NoData type="购物车" props={props} />
            </div>
            {localStorage.singleTob === "1" ? (
              ""
            ) : (
              <div className="recommend-med">
                <div className="recommend-med-title flex-apart-row">
                  <div>平台推荐</div>
                </div>
                <div className="recommend-med-list cart-med-list">
                  {recommand.length === 0 ? null : (
                    <RecommmendMed
                      getCartList={getCartList}
                      sunthis={cref}
                      recommand={recommand}
                      shopcart={1}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const onRef = (ref) => {
    //console.log(ref);
    setcref(ref);
  };

  return (
    <div style={{ paddingBottom: "30px" }}>
      <div className="shopcart-header flex-apart-row">
        <div className="shopcart-scan" onClick={cameraScan}>
          <i className="iconfont-l icon-saoyisao"></i>
        </div>
        <p onClick={editManage}>{titlecode ? "取消编辑" : "编辑管理"}</p>

        <div className="shopcart-header-classify flex-row">
          <div className="flex-row" onClick={() => settabindex(0)}>
            商品
          </div>
        </div>
      </div>
      <WhiteSpace />
      {tabindex === 0
        ? renderMedContent(tabindex)
        : renderShopContent(tabindex)}
      <FootNav onRef={onRef} />
    </div>
  );
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
  user: state.user.user,
  cart: state.cart,
});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {
    redirectDispatch(data) {
      dispatch(resetdirect(data));
    },
  };
};

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(Shopcart);
