import React, { Component } from "react";
import { connect } from "react-redux";
import { Toast } from "antd-mobile";

import FootNav from "../../component/FootNav";
import "./style.less";

import {
  mineRequest,
  getuserstoreinfo,
  getuserinfo,
} from "../../api/request/user";
import { tjState } from "../../api/request/order";
import { oneMessage } from "../../api/request/msg";

import { resetdirect } from "../../redux/user.redux";

import VIP from "../../static/img/app/vip.png";
import icon1 from "../../static/img/icon/chufang.png";
import icon2 from "../../static/img/icon/yishi.png";
import icon3 from "../../static/img/icon/yiyuan.png";
import icon4 from "../../static/img/icon/bingren.png";
import icon5 from "../../static/img/icon/news3.png";
import icon6 from "../../static/img/morenhead.png";

import img1 from "../../static/img/app/1.png";
import img2 from "../../static/img/app/2.png";
import img3 from "../../static/img/app/3.png";
import img4 from "../../static/img/app/4.png";
import img5 from "../../static/img/app/5.png";
import logo from "../../static/img/mine/logo.png";
import shezhi from "../../static/img/mine/shezhi.png";
import xiaoxi from "../../static/img/mine/xiaoxi.png";
import pficon1 from "../../static/img/mine/pf-icon1.png";
import pficon2 from "../../static/img/mine/pf-icon2.png";
import pficon3 from "../../static/img/mine/pf-icon3.png";
import pficon4 from "../../static/img/mine/pf-icon4.png";
import pficon5 from "../../static/img/mine/pf-icon5.png";
import pficon6 from "../../static/img/mine/pf-icon6.png";
import pficon7 from "../../static/img/mine/pf-icon7.png";
import pficon8 from "../../static/img/mine/pf-icon8.png";
import pficon9 from "../../static/img/mine/pf-icon9.png";
import pficon10 from "../../static/img/mine/pf-icon10.png";
import pficon11 from "../../static/img/mine/pf-icon11.png";
import pficon12 from "../../static/img/mine/pf-icon12.png";
import pficon13 from "../../static/img/mine/pf-icon13.png";
import pficon14 from "../../static/img/mine/pf-icon14.png";
import right from "../../static/img/right.png";
import ga from "../../static/img/ga.png";
import getUrl from "../../component/GetUrlParam";

class Mine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pharmacyId: localStorage.pharmacyId,
      //userId: localStorage.userId,
      userId:
        localStorage.type == 1
          ? localStorage.userId1
          : localStorage.type == 2
          ? localStorage.userId2
          : localStorage.userId3,
      wxId: localStorage.wxId,
      //userName: localStorage.userName==='undefined'||localStorage.userName==='null'?null:localStorage.userName,
      userName: "",
      userAcount:
        localStorage.type == 1
          ? localStorage.userAcount1
          : localStorage.type == 2
          ? localStorage.userAcount2
          : localStorage.userAcount3,
      //userAvatar: localStorage.userAvatar==='undefined'||localStorage.userName==='null'?null:localStorage.userAvatar,
      userAvatar: "",
      //userVip: localStorage.userVip,
      userVip:
        localStorage.type == 1
          ? localStorage.userVip1
          : localStorage.type == 2
          ? localStorage.userVip2
          : localStorage.userVip3,
      wx: localStorage.getItem("wx"),
      userIntegral: localStorage.userIntegral ? localStorage.userIntegral : 0,
      browsings: 0,
      collections: 0,
      collections1: 0,
      coupons: 0,
      type: localStorage.type,
      storename: "",
      getallnum: null,
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      xtCount: 0,
      yhCount: 0,
      ddCount: 0,
    };
  }

  // 判断用户信息
  componentWillMount = () => {
    const { userId } = this.state;
    const type = localStorage.getItem("type");
    switch (type) {
      case "1":
        tjState(userId).then((res) => {
          console.log(res);
          this.setState({
            one: res.data[0][0].total1,
            two: res.data[1][0].total2,
            three: res.data[2][0].total3,
            four: res.data[3][0].total4,
          });
        });
        this.setState(
          {
            userName:
              localStorage.userName1 === "undefined" ||
              localStorage.userName1 === "null"
                ? null
                : localStorage.userName1,
            userAvatar:
              localStorage.userAvatar1 === "undefined" ||
              localStorage.userAvatar1 === "null"
                ? null
                : localStorage.userAvatar1,
          },
          () => {
            console.log(this.state.userName);
          }
        );
        return;
      case "2":
        this.setState({
          userName:
            localStorage.userName2 === "undefined" ||
            localStorage.userName2 === "null"
              ? null
              : localStorage.userName2,
          userAvatar:
            localStorage.userAvatar2 === "undefined" ||
            localStorage.userAvatar2 === "null"
              ? null
              : localStorage.userAvatar2,
        });
        return;
      case "3":
        this.setState({
          userName:
            localStorage.userName3 === "undefined" ||
            localStorage.userName3 === "null"
              ? null
              : localStorage.userName3,
          userAvatar:
            localStorage.userAvatar3 === "undefined" ||
            localStorage.userAvatar3 === "null"
              ? null
              : localStorage.userAvatar3,
        });
        return;
    }
  };
  // 初始化用户信息
  componentDidMount = async () => {
    //Toast.success(window.location.hash)
    // const url=window.location.hash.split("=")[1]
    // if(url==1){
    //     localStorage.removeItem("singleTob")
    //     localStorage.setItem("pharmacyId",0)
    //     localStorage.setItem("type",1)
    // }
    const res = await getuserstoreinfo({
      qualiType: 0,
      userId: this.state.userId,
    });
    console.log(res);
    if (res.data) {
      if (res.data[0].length) {
        this.setState({
          storename: res.data[0][0].companyName,
        });
      }
    }
    if (this.state.userId) {
      oneMessage(this.state.userId).then((res) => {
        console.log(res);
        if (res.code == 1) {
          if (res.data[0].length) {
            this.setState({
              xtCount: res.data[0][0].allNews,
            });
          }
          if (res.data[1].length) {
            this.setState({
              yhCount: res.data[1][0].allNews1,
            });
          }
          if (res.data[2].length) {
            this.setState({
              ddCount: res.data[2][0].allNews2,
            });
          }
        }
      });
      this.getMine();
    }
    //8.16更改
    const urlHash = window.location.hash;
    const typearr = urlHash.split("=");
    const str = urlHash.substr(urlHash.indexOf("?"));
    console.log(urlHash);
    // 判断用户类型
    if (typearr[2]) {
      if (typearr[2] == 2) {
        localStorage.setItem("type", 3);
        localStorage.setItem("pharmacyId", 2);
        //console.log(localStorage.getItem("pharmacyId"))
      } else if (typearr[2] == 25) {
        localStorage.setItem("type", 2);
        localStorage.setItem("pharmacyId", 25);
      }
    } else {
      localStorage.setItem("type", 1);
      localStorage.setItem("pharmacyId", 0);
    }
    if (urlHash.indexOf("?") !== -1) {
      const str = urlHash.substr(urlHash.indexOf("?"));
      const env = getUrl("env", str);
      const pharmacyId = getUrl("pharmacyId", str);
      localStorage.setItem("env", env || 0);
      localStorage.setItem("pharmacyId", pharmacyId || 0);
      this.setState({
        env: env,
      });
    } else {
      localStorage.setItem("env", 0);
      localStorage.setItem("pharmacyId", 0);
      localStorage.setItem("singleTob", 0);
      localStorage.setItem("singleTitle", "");
      localStorage.setItem("singleSubtitlea", "");
      document.title = "众康云药购";
    }
  };
  // 获取用户信息
  getMine = () => {
    mineRequest(this.state.userId).then((res) => {
      console.log(res);
      if (res.code === 1) {
        // console.log(res)
        localStorage.userIntegral = res.data[3][0]
          ? res.data[3][0].integral
          : 0;
        this.setState({
          browsings: res.data[0][0].browsings,
          collections: res.data[1][0].collections,
          collections1: res.data[1][0].collections1,
          coupons: res.data[2][0].coupons,
          userIntegral: res.data[3][0] ? res.data[3][0].integral : 0,
          userAvatar: res.data[3][0]
            ? res.data[3][0].userAvatar
            : this.state.userAvatar,
        });
      } else {
        console.log(res);
      }
    });
  };
  // 判断是否登录，点击对应标签进行跳转
  mineData = (type) => {
    if (this.state.userId) {
      switch (type) {
        case "mine":
          break;
        case "setup":
          this.props.history.push("/mine/set");
          break;
        case "viewrecord":
          this.props.history.push("/mine/viewrecord");
          break;
        case "collection":
          this.props.history.push("/mine/collection");
          break;
        case "coupon":
          this.props.history.push("/mine/coupon");
          break;
        case "lookAll":
          // this.props.history.push('/mine/order')
          this.props.history.push(`/mine/order?tabId=0`);
          break;
        case "bill":
          this.props.history.push("/mine/bill");
          break;
        case "revenueexpenditure":
          this.props.history.push("/mine/revenueexpenditure");
          break;
        default:
          break;
      }
    } else {
      this.props.redirectDispatch(this.props.location.pathname);
      this.props.history.push("/login/Login1");
    }
  };
  // 打开消息界面
  toTidings = () => {
    if (this.state.userId) {
      this.props.history.push(`/tidings?mine=${1}`);
    } else {
      this.props.redirectDispatch(this.props.location.pathname);
      this.props.history.push("/login/Login1");
    }
  };
  // 开通vip
  openVip = () => {
    if (this.state.userId) {
      Toast.info("管理员未开放", 1);
      return;
    } else {
      this.props.redirectDispatch(this.props.location.pathname);
      this.props.history.push("/login/Login1");
    }
  };

  inquiry = (code) => {
    if (code) {
      switch (code) {
        case 1:
          if (this.state.userId) {
            this.props.history.push("/mine/adress");
          } else {
            this.props.history.push("/login/Login1");
          }
      }
    } else {
      Toast.info("管理员未开放", 1);
    }
  };

  wallet = () => {
    this.props.history.push("/mine/storemoney");
  };
  // 跳转到订单管理界面
  toOrder = (id) => {
    if (id !== null) {
      if (this.state.userId) {
        this.props.history.push(`/mine/order?tabId=${id}`);
        return;
      } else {
        this.props.redirectDispatch(this.props.location.pathname);
        this.props.history.push("/login/Login1");
      }
    } else {
      if (this.state.userId) {
        this.props.history.push("/mine/order/apple_refund");
      } else {
        this.props.history.push("/login/Login1");
      }
    }
  };
  // 私人医生
  privatePhysician = () => {
    console.log(this.state.userId);
    if (this.state.userId) {
      this.props.history.push(`/mine/privatePhysician`);
    } else {
      this.props.history.push("/login/Login1");
    }
  };
  // 平台资质
  platformQualification = () => {
    this.props.history.push(`/mine/platformQualification`);
  };
  //用药人
  medicinalUser = () => {
    if (this.state.userId) {
      this.props.history.push(`/mine/medicinalUser`);
    } else {
      this.props.history.push("/login/Login1");
    }
  };
  //获取子组件this
  onRef = (ref) => {
    console.log(ref);
    this.setState({
      getallnum: ref,
    });
  };
  controlInvoices = () => {
    if (this.state.userId) {
      this.props.history.push(`/mine/controlInvoices`);
    } else {
      this.props.history.push("/login/Login1");
    }
  };
  render() {
    const {
      one,
      two,
      three,
      four,
      storename,
      ddCount,
      collections1,
      yhCount,
      xtCount,
      pharmacyId,
      wxId,
      userId,
      wx,
      browsings,
      collections,
      coupons,
      userName,
      userAcount,
      userAvatar,
      userVip,
      userIntegral,
      type,
    } = this.state;
    // console.log(parseInt(collections+collections1));
    return (
      <div>
        {type == "2" ? (
          <div>
            <div className="pf-box">
              <div className="pf-top">
                <div className="pf-top-left">
                  <img src={logo} alt="" />
                  <span>{userName ? userName : userAcount}</span>
                </div>
                <div className="pf-top-right">
                  <img
                    onClick={() => this.mineData("setup")}
                    src={shezhi}
                    alt=""
                  />
                  <img onClick={() => this.toTidings()} src={xiaoxi} alt="" />
                </div>
              </div>
              <div className="pf-store">
                <div className="pf-store-top">
                  <h3>{storename}</h3>
                </div>
                <div className="pf-store-bottom">
                  <div
                    onClick={() => this.props.history.push("/mine/storeinfor")}
                  >
                    <img src={pficon1} alt="" />
                    <p>门店信息</p>
                  </div>
                  <div onClick={() => this.props.history.push("/mine/storeSH")}>
                    <img src={pficon2} alt="" />
                    <p>门店资质</p>
                  </div>
                  <div
                    onClick={() => this.props.history.push("/mine/storeticket")}
                  >
                    <img src={pficon3} alt="" />
                    <p>开票信息</p>
                  </div>
                  <div onClick={() => this.wallet()}>
                    <img src={pficon4} alt="" />
                    <p>门店钱包</p>
                  </div>
                </div>
              </div>
              <div className="pf-dan">
                <h2>我的订单</h2>
                <div className="pf-dan-item">
                  <div onClick={() => this.toOrder(1)}>
                    <img src={pficon5} alt="" />
                    <p>待付款</p>
                  </div>
                  <div onClick={() => this.toOrder(2)}>
                    <img src={pficon6} alt="" />
                    <p>待出库</p>
                  </div>
                  <div onClick={() => this.toOrder(3)}>
                    <img src={pficon7} alt="" />
                    <p>待收货</p>
                  </div>
                  <div className="item-4" onClick={() => this.toOrder(null)}>
                    <img src={pficon8} alt="" />
                    <p>售后订单</p>
                  </div>
                  <div onClick={() => this.mineData("lookAll")}>
                    <img src={pficon9} alt="" />
                    <p>全部订单</p>
                  </div>
                </div>
              </div>
              <div className="pf-bottom">
                <h2>常用功能</h2>
                <div className="pf-bottom-item">
                  <div onClick={() => this.props.history.push("/mine/adress")}>
                    <img
                      src={pficon10}
                      alt=""
                      style={{ width: "20px", height: "24px" }}
                    />
                    <p>我的地址</p>
                  </div>
                  <div onClick={() => this.mineData("viewrecord")}>
                    <img src={pficon11} alt="" />
                    <p>浏览足迹</p>
                  </div>
                  <div
                    onClick={() =>
                      this.props.history.push("/mine/electronicInvoice")
                    }
                  >
                    <img
                      src={pficon12}
                      alt=""
                      style={{ width: "22px", height: "24px" }}
                    />
                    <p>电子发票</p>
                  </div>
                  <div onClick={() => this.mineData("collection")}>
                    <img src={pficon13} alt="" />
                    <p>收藏店家</p>
                  </div>
                  <div>
                    <img
                      src={pficon14}
                      style={{ width: "24px", height: "24px" }}
                      alt=""
                    />
                    <p>联系客服</p>
                  </div>
                </div>
              </div>
              <div className="pf-bill">
                <h2>账单信息</h2>
                <div className="pf-bill-item">
                  <div onClick={() => this.mineData("bill")}>
                    <img
                      src={pficon14}
                      style={{ width: "24px", height: "24px" }}
                      alt=""
                    />
                    <p>收支详情</p>
                  </div>
                </div>
              </div>
              <FootNav onRef={this.onRef} />
            </div>
          </div>
        ) : (
          <div>
            <div className="screen-home">
              <div className="mine-top">
                <div className="mine-back flex-apart-row">
                  <div
                    className="back-left"
                    onClick={() => this.mineData("mine")}
                  >
                    {userAvatar ? (
                      <div className="mine-avatar">
                        <img src={userAvatar} onError={userAvatar} alt="" />
                      </div>
                    ) : (
                      <div className="mine-avatar">
                        <img src={icon6} alt="" />
                      </div>
                    )}
                    {userId || wxId ? (
                      <div className="user-info">
                        <span>{userName ? userName : userAcount}</span>
                        <span>
                          {localStorage.singleTob == 1
                            ? "商家采购用户"
                            : userVip === "0"
                            ? "未开通会员"
                            : `Lv${userVip}会员`}
                        </span>
                      </div>
                    ) : wx == 1 ? (
                      <div className="user-info">
                        <span>{userName ? userName : userAcount}</span>
                        <span>未开通会员</span>
                      </div>
                    ) : (
                      <div className="user-info">
                        <span>请登录</span>
                        <span>未开通会员</span>
                      </div>
                    )}
                  </div>
                  <div className="back-right">
                    <div onClick={() => this.mineData("setup")}>
                      <i className="iconfont icon-shezhi"></i>
                    </div>
                    <div onClick={() => this.toTidings()}>
                      <i className="iconfont icon-xiaoxi"></i>
                      {parseInt(xtCount) +
                        parseInt(yhCount) +
                        parseInt(ddCount) !=
                      0 ? (
                        <span className="count">
                          {parseInt(xtCount) +
                            parseInt(yhCount) +
                            parseInt(ddCount) <
                          100
                            ? parseInt(xtCount) +
                              parseInt(yhCount) +
                              parseInt(ddCount)
                            : "99+"}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="mine-middle flex-apart-row">
                  <div
                    onClick={() => this.mineData("viewrecord")}
                    className="flex-col"
                  >
                    <span className="mine-middle-num">
                      {browsings > 999 ? "999+" : browsings}
                    </span>
                    <span>浏览记录</span>
                  </div>
                  <div
                    onClick={() => this.mineData("collection")}
                    className="flex-col"
                  >
                    <span className="mine-middle-num">
                      {parseInt(collections + collections1) > 999
                        ? "999+"
                        : parseInt(collections + collections1)}
                    </span>
                    <span>我的收藏</span>
                  </div>
                  <div
                    className="flex-col"
                    onClick={() => this.mineData("revenueexpenditure")}
                  >
                    <span className="mine-middle-num">
                      {userId ? userIntegral : 0}
                    </span>
                    <span>积分</span>
                  </div>
                  <div
                    onClick={() => this.mineData("coupon")}
                    className="flex-col"
                  >
                    <span className="mine-middle-num">
                      {coupons > 999 ? "999+" : coupons}
                    </span>
                    <span>优惠券</span>
                  </div>
                </div>
                <div className="mine-vip">
                  <div className="vip-card flex-apart-row">
                    <div className="flex-row">
                      <img src={VIP} alt="" />
                      <span>开通享有优惠特权</span>
                    </div>
                    <div
                      className="open-btn flex-row"
                      onClick={() => this.openVip()}
                    >
                      立即开通
                    </div>
                  </div>
                </div>
                <div className="mine-order-title flex-apart-row">
                  <span>我的订单</span>
                  <div onClick={() => this.mineData("lookAll")}>
                    <span>查看全部</span>
                    <i className="iconfont icon-xiangyou"></i>
                  </div>
                </div>
                <div className="mine-order flex-apart-row">
                  <div
                    onClick={() => this.toOrder(1)}
                    className="flex-apart-col"
                  >
                    {/* <i className='iconfont icon-daifukuan'></i> */}
                    <img src={img1} alt="" />
                    <span>待付款</span>
                    {one > 0 ? (
                      <span className="order-count">{one}</span>
                    ) : null}
                  </div>
                  <div
                    onClick={() => this.toOrder(2)}
                    className="flex-apart-col"
                  >
                    {/* <i className='iconfont icon-daifahuo'></i> */}
                    <img src={img2} alt="" />
                    <span>配送中</span>
                    {two > 0 ? (
                      <span className="order-count">{two}</span>
                    ) : null}
                  </div>
                  {/* <div onClick={() => this.toOrder(4)} className='flex-apart-col'>
                                    <img src={img3} alt='' />
                                    <span>已完成</span>
                                </div> */}
                  <div
                    onClick={() => this.toOrder(3)}
                    className="flex-apart-col"
                  >
                    {/* <i className='iconfont icon-quanbudingdan'></i> */}
                    <img src={img4} alt="" />
                    <span>待评价</span>
                    {three > 0 ? (
                      <span className="order-count">{three}</span>
                    ) : null}
                  </div>
                  <div
                    onClick={() => this.toOrder(4)}
                    className="flex-apart-col"
                  >
                    {/* <i className='iconfont icon-tuikuanshouhou'></i> */}
                    <img src={img5} alt="" />
                    <span>退换/售后</span>
                    {four > 0 ? (
                      <span className="order-count">{four}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {pharmacyId === "0" ? (
                <div className="mine-bottom">
                  <span>口袋药房</span>
                  <div className="mine-pocket">
                    <ul className="clear-fix">
                      <li onClick={() => this.inquiry(1)}>
                        <img src={icon1} alt="" />
                        <span>我的地址</span>
                      </li>
                      {/* <li onClick={() => this.inquiry()}>
                                            <img src={icon1} alt='' />
                                            <span>处方清单</span>
                                        </li> */}
                      <li onClick={() => this.privatePhysician()}>
                        <img src={icon2} alt="" />
                        <span>私人医师</span>
                      </li>
                      <li onClick={() => this.inquiry()}>
                        <img src={icon3} alt="" />
                        <span>关注医院</span>
                      </li>
                      <li onClick={() => this.controlInvoices()}>
                        <img src={icon5} alt="" />
                        <span>发票管理</span>
                      </li>
                      <li onClick={() => this.medicinalUser()}>
                        <img src={icon4} alt="" />
                        <span>用药人</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className="pingtai-zizhi">
                <h1>众康云药购</h1>
                <p className="pingtai-zizhi-renzheng">
                  <p>
                    <img src={right} alt="" />
                    <span>药监正品认证</span>
                  </p>
                  <p>
                    <img src={right} alt="" />
                    <span>平均30分钟送达</span>
                  </p>
                  <p>
                    <img src={right} alt="" />
                    <span>专业药师服务</span>
                  </p>
                </p>
                <p
                  className="pingtai-bottom"
                  onClick={() => this.platformQualification()}
                >
                  平台资质 <span>{">"}</span>
                </p>
                <p style={{ textAlign: "center", lineHeight: "24px" }}>
                  <img
                    style={{
                      width: "20px",
                      marginRight: "5px",
                      position: "relative",
                      top: "5px",
                    }}
                    src={ga}
                    alt=""
                  />
                  <a href="https://beian.miit.gov.cn/#/Integrated/index">
                    蜀ICP备20017919号
                  </a>
                </p>
                <p style={{ textAlign: "center", lineHeight: "24px" }}>
                  <a href="http://www.beian.gov.cn/portal/index">
                    川公网安备51011402000523号3
                  </a>
                </p>
                <p style={{ textAlign: "center", lineHeight: "24px" }}>
                  电信业务经营许可证编号:川B2-20220468
                </p>
                <p style={{ textAlign: "center", lineHeight: "24px" }}>
                  网络食品交易主体备案凭证:CT(2022)0027
                </p>
                <p style={{ textAlign: "center", lineHeight: "24px" }}>
                  《互联网药品信息服务资格证书》:(川)-经营性-2020-0033
                </p>
              </div>
            </div>
            <FootNav onRef={this.onRef} />
          </div>
        )}
      </div>
    );
  }
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {
    redirectDispatch(data) {
      dispatch(resetdirect(data));
    },
  };
};

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(Mine);
