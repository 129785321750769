import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { resetdirect } from '../../redux/user.redux'
import './style.less'
import '../../static/css/iconfont.css'

class HeaderNavpf extends Component {
    constructor(props) {
        super(props)
        this.state = {
            props: props.props,
            city: localStorage.posDistrict ? localStorage.posDistrict : null,
            userId: localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3,
            onclick: props.onclick
        }
    }
   
    render() {
        const { props, city, userId, onclick } = this.state
        
        return (
            <div id='home-header2' className='flex-apart-row'>

                <p className="top-title">批发平台</p>

                <div className='home-header-right'>
                    <Link onClick={() => {
                        if (userId) {
                            props.history.push('/tidings')
                        } else {
                            this.props.redirectDispatch(localStorage.env === 'single' ? `/?env=single&pharmacyId=${localStorage.pharmacyId}` : '/')
                            props.history.push('/login')
                        }
                    }}>
                        <i className='iconfont icon-xiaoxi1'></i>
                    </Link>
                    <div onClick={onclick}>
                        <i className='iconfont icon-saoyisao'></i>
                    </div>
                </div>
                <div className='home-header-middle2'>
                    <div className='search-container'>
                        <Link to='/search' className='flexr' onClick={()=>{console.log(111)}}>
                            <i className='iconfont icon-suosou'></i>
                            <input type='text' readOnly placeholder='搜索药名' />
                        </Link>
                    </div>
                </div>
                <div className="top-swiper"></div>
                {/* <DemoBlock title='自动播放'>
                    <Swiper autoplay>{items}</Swiper>
                </DemoBlock> */}
            </div>
        )
    }
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavpf)