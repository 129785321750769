import axios from "axios";

export const baseUrl = "https://exam-anubis.ele.me/anubis-webapi/v3/invoke";

// axios的实例及拦截器配置
const axiosInstance = axios.create({
//   baseURL: baseUrl
});

axiosInstance.interceptors.response.use(
  res => res.data,
  err => {
    console.log(err, "网络错误");
  }
);

export { axiosInstance };

