import React, { Component } from 'react'

import Axios from 'axios';
import { identyRequest } from "../../../api/request/user"
import { preCreateOrder, getToken } from "../../../api/request2/rudan"
import { getTau, getRange } from "../../../api/request/user"
import {oderdetailRequest} from "../../../api/request/order"
import { Map, Marker, NavigationControl, InfoWindow } from 'react-bmapgl'
import { beforehand, formalOrder, getDetails, getKnightInfo, getcancelreasonlist, cancelorder, preCancelOrder, getlocation ,downorder} from "../../../api/request/logistics"
import { position } from 'dom-helpers';
import icon1 from "../../../static/img/icon/qs.png"
import BDMap from '../../Map/BDMap';
const BMap = window.BMap
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            id: "",
            longitude :localStorage.longitude ? localStorage.longitude : 104.06576236480714,
            latitude :localStorage.latitude ? localStorage.latitude : 30.65745887548027
        }
    }
    // 获取外部订单号
    componentWillMount = async () => {
        //console.log(position.coords.latitude)
        this.setState({
            id: this.props.match.params.id
        },()=>console.log(this.state.id))
        // const res=await identyRequest({
        //     userPhone:13458396457
        // })
        // console.log(res)
    }
    // 下单流程测试
    componentDidMount = async () => {
        // let grant_type = "CODE";
        // let code = ""; 
        // let app_id = "8591135066127605909";
        // let merchant_id = "4250283";
        //const time3 = new Date().getTime();
        //console.log(time3)

        const res = await getTau()
        const data = JSON.parse(res.data.business_data).access_token
        // const res2=await downorder({
        //     status:"success",
        //     tradeno:"16347824930606937182576"
        // })
        // console.log(res2)

        // 订单详情
        const res3=await oderdetailRequest(this.state.id)
        console.log(res3)

        //查询商家配送范围
        // const res1=await getRange({
        //     access_token:data,
        //     chain_store_id:"204749131",
        //     out_shop_code:"testorder001",
        //     basic_goods_id:""
        // })
        // console.log(data)
        // 预下单
        // const res2=await beforehand({
        //     access_token:data,
        //     partner_order_code:this.state.id,
        //     order_type:1,
        //     position_source:1,
        //     receiver_address:"成都市青羊区",
        //     receiver_longitude:116.29642650954169,
        //     receiver_latitude:40.04955253124145,
        //     goods_total_amount_cent:10,
        //     goods_actual_amount_cent:10,
        //     goods_weight:1,
        //     goods_count:1,
        //     chain_store_id:"204749131",
        //     out_shop_code:"testorder001"
        // })

        // 正式下单
        // const res2 = await formalOrder({
        //     access_token: data,
        //     partner_order_code: this.state.id,
        //     // 取货经纬度
        //     transport_longitude: 116.29642650954169,
        //     transport_latitude: 40.04955253124145,
        //     // 取货点联系人电话
        //     transport_tel: 13458396457,
        //     order_type: 1,
        //     position_source: 1,
        //     receiver_address: "成都市大丰家居城",
        //     receiver_longitude: 116.29642650954169,
        //     receiver_latitude: 40.04955253124145,
        //     goods_total_amount_cent: 10,
        //     goods_actual_amount_cent: 10,
        //     goods_weight: 1,
        //     goods_count: 1,
        //     // 外部门店/门店id
        //     chain_store_id: "204749131",
        //     //out_shop_code:"testorder001",
        //     // 收货人姓名
        //     receiver_name: "DJ",
        //     receiver_primary_phone: 13458396457
        // })
        //  console.log(res2);
        // const order_id = res2.data.business_data.substring(12,res2.data.business_data.length-1)

        // 查询物流信息
        // const res3=await getDetails({
        //     //partner_order_code:this.state.id,
        //     order_id:"150000001961178377",
        //     access_token:data
        // })
        // console.log(res3)
        //查询骑手信息
        // const res4=await getKnightInfo({
        //     //partner_order_code:this.state.id,
        //     order_id,
        //     access_token:data
        // })
        // console.log(res4)
        //获取取消原因列表
        // const res5=await getcancelreasonlist({
        //     //partner_order_code:this.state.id,
        //     order_id,
        //     access_token:data
        // })
        // console.log(res5)
        // 取消订单
        // const res1=await cancelorder({
        //     order_id,
        //     access_token:data,
        //     // 取消原因从接口获取
        //     order_cancel_code:1,
        //     // 取消实际需扣金额，从预取消接口拿
        //     //actual_cancel_cost_cent:10,
        //     // 取消方(商家，用户)
        //     order_cancel_role:2
        // })
        // console.log(res1)
        //预取消订单
        // const res6=await preCancelOrder({
        //     order_id,
        //     access_token:data,
        //     // 取消原因从接口获取
        //     order_cancel_code:1
        // })
        //console.log(res6)
        //console.log(JSON.parse(res2.data.business_data).range_list[0].ranges)
        // console.log(res1)
        // console.log(res2)
        //console.log(JSON.parse(res2.data.business_data));
        //console.log(JSON.parse(res1.data.business_data))
        //console.log(res,data)



        //    地图
        const {longitude,latitude}=this.state
        //let longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
        //let latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
        var mp = new BMap.Map("container");
        var point = new BMap.Point(longitude, latitude);
        mp.centerAndZoom(point, 18);
        mp.enableScrollWheelZoom(true);
        // 创建小车图标
        var myIcon = new BMap.Icon(icon1, new BMap.Size(32, 32));
        // 创建Marker标注，使用小车图标
        var pt = new BMap.Point(longitude, latitude);
        var marker = new BMap.Marker(pt, {
            icon: myIcon
        });
        // 将标注添加到地图
        mp.addOverlay(marker)
        // 创建图文信息窗口
        var sContent = `<p>骑手正在加急派送</p>`
        var infoWindow = new BMap.InfoWindow(sContent);
        // marker添加点击事件
        marker.addEventListener('click', function () {
            this.openInfoWindow(infoWindow);
            // 图片加载完毕重绘infoWindow
        });
        
    }
    render() {
        return (
            <div>
                <div id="container" style={{ width: "100%", height: "300px" }}></div>
                {/* <BDMap width="100%" height="300px"></BDMap> */}
            </div>
        )
    }
}
