import React, { Component } from 'react'
import { Flex, Toast, Button } from 'antd-mobile'
import { Link } from 'react-router-dom'
import { wxConfig } from '../../component/scan/index'
import FootNav from '../../component/FootNav'
import HeaderNavpf from '../../component/HeaderNavpf'
import Swiper from '../../component/Swiper/index3'
import Activity from './Activity'
import Shopitem from '../../component/Listitem/shopitem'
import RecommmendMed2 from '../../component/Goods/rmd-med2'

import { homeactivityRequest, limitactivityRequest, homerecommandRequest } from '../../api/request/home'

import './style1.less'
import "../../static/css/iconfont.css"
import defaultImg from '../../static/img/default.png'
import iconA from '../../static/img/icon/hot1.png'
import iconB from '../../static/img/icon/mingyi.png'
import icon1 from "../../static/img/home/xiaoxi@2x.png"
import icon2 from "../../static/img/home/saoyisao@2x.png"
import seckill from '../../static/img/med/title.png'
import { wzUrl } from "../../publicurl"
import imglist from "../../static/img/home/lunbo.png"
class Platform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // 接收props
            props: this.props.props,
            categoryTab: [],
            category: [],
            activeBanner: [],
            activeBannerLink: [],
            activity: [],
            activityLink: [],
            limitKill: [],
            preference: [],
            recommand: [],
            hot: null,
            imglistall:[imglist,imglist,imglist,imglist],
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            type:localStorage.pharmacyId=="25"?1:0,
            sunthis:null
        }
    }

    componentDidMount() {
        localStorage.setItem('pharmacyName', '云药购')
        // 获取经纬度
        let longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
        let latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
        this.homeRequest(0, longitude, latitude,this.state.type)
        // if (navigator.geolocation){
        //     navigator.geolocation.getCurrentPosition(
        //         function(ev){
        //             let latitude = ev.coords.latitude
        //             let longitude = ev.coords.longitude
        //             console.log(latitude,longitude)
        //         },
        //         function(err){
        //             console.log(err.code+':'+err.message)
        //         })
        // } else {
        //     alert('该浏览器不支持')
        // }
    }

    cameraScan = () => {
        wxConfig()
    }

    homeRequest = async (pharmacyId, longitude, latitude,type) => {
        console.log(pharmacyId, longitude, latitude,type)
        console.log(this.state.userId)
        const res = await Promise.all([
            homeactivityRequest(pharmacyId, longitude, latitude,type),
            limitactivityRequest(2),
            homerecommandRequest({
                pharmacyId: pharmacyId,
                userId: this.state.userId || undefined,
                page: 0,
                size: 20
            })
        ])
        console.log(res)
        // 获取分类列表
        let category = res[0].data[0]
        res[0].data[1].forEach(item => {
            if (item.activityBanner) {
                item.activityBanner = item.activityBanner.split(',')
                item.activityUrl = item.activityUrl.split(',')
            } else if (item.activityPic) {
                item.activityPic = item.activityPic.split(',')
                item.activityUrl = item.activityUrl.split(',')
            }
        })
        this.setState({
            category: category,
            categoryTab: category.slice(1, category.length),
            activeBanner: res[0].data[1][0].activityBanner,
            activeBannerLink: res[0].data[1][0].activityUrl,
            activity: res[0].data[1][1].activityPic,
            activityLink: res[0].data[1][1].activityUrl,
            limitKill: res[1].data[0],
            // 特惠药房列表
            preference: res[0].data[2],
            recommand: res[2].data[res[2].data.length - 1],
            hot: res[0].data[3][0]
        })
    }
    // 点击分类列表跳转
    toMedList = (item) => {
        const { categoryId, categoryName } = item
        if (categoryName === '全部分类') {
            this.state.props.history.push('/classify')
        } else {
            this.state.props.history.push(`/medlist/${categoryId}/0/${categoryName}`)
        }
    }
    // 跳转搜索界面
    activeClick = (link) => {
        this.state.props.history.push(link)
    }
    // 更多药房
    moreStore = () => {
        this.state.props.history.push('/morestore')
    }
    // 问诊跳转
    inquiry = () => {
        // Toast.info('管理员未开放', 1)
        // this.state.props.history.push('http://wz.sczyzk.com/#/user/home')
        window.location.href = (`${wzUrl}/#/user/home`)
    }
    //获取子组件this
    onRef=(ref)=>{
        this.setState({
            sunthis:ref
        })
    }
    render() {
        const { props, category, categoryTab, activeBanner, activeBannerLink, activity, activityLink, limitKill, preference, recommand, hot, userId } = this.state

        return (
            <div>
                <div className='screen-home'>
                    <div className='color-block2'></div>
                    {/* <HeaderNavpf onclick={this.cameraScan} props={props} /> */}
                    <div className="screen-home-top">
                        <div className="screen-home-top-title">
                            <h2>批发平台</h2> <div className="top-title-box">
                            <img src={icon2} alt="" />
                            <Link onClick={() => {
                        if (userId) {
                            props.history.push('/tidings')
                        } else {
                            this.props.redirectDispatch(localStorage.env === 'single' ? `/?env=single&pharmacyId=${localStorage.pharmacyId}` : '/')
                            props.history.push('/login')
                        }
                    }}>
                        <img src={icon1} alt="" />
                    </Link>
                           
                            </div>
                        </div>
                        <div className="top-search">
                            <Link to='/search'>
                            <input type='text' readOnly placeholder='搜索商品' />
                            </Link>
                        </div>
                        <div className="top-swiper">
                        <Swiper banners={this.state.imglistall}></Swiper>
                        </div>
                    </div>
                    <div id='allmap' style={{ width: '0', height: '0' }} />
                    <div className='home-downpart-headernav'>
                        <div className='home-downpart-whiteback'></div>
                        {/* <div className='main-use flex-apart-row'>
                            <div className='function flex-row' style={{color:'#F34848'}}
                            onClick={()=>{
                                // if(hot) this.props.history.push(hot.hotUrl)
                                wxConfig()
                            }}>
                                <img src={iconA} alt=''/>
                                <div className='flex-col'>
                                    <span>{hot?hot.hotTitle:null}</span>
                                    <span>{hot?hot.hotSubtitle:null}</span>
                                </div>
                            </div>
                            <div className='function flex-row' style={{color:'#00B7F5'}}>
                                <img src={iconB} alt=''/>
                                <div onClick={this.inquiry}>
                                    <div className='flex-col'>
                                        <span>名医问诊</span>
                                        <span>24小时在线</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='other-use2'>
                            <ul className='clear-fix'>
                                {
                                    category.map(item => (
                                        <li key={item.categoryId}
                                            onClick={() => this.toMedList(item)}>
                                            <img src={item.categoryPng} alt='' />
                                            <span>{item.categoryName}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {/* <div className='swiper-part flex-row'>
                            <div className='swiper-img'>
                                <Swiper banners={activeBanner}
                                link={activeBannerLink}/>
                            </div>
                        </div> */}
                        <Flex justify='between' className='flex-activity'>
                            {
                                activity.map((item, index) => (
                                    <Flex.Item key={index}
                                        className='flex-actie-row'
                                        onClick={() => this.activeClick(activityLink[index])}>
                                        <img src={item} alt='' />
                                    </Flex.Item>
                                ))
                            }
                        </Flex>
                        {limitKill.length === 0 ? null :
                            <div className='timelimit-part'>
                                <div className='timelimit-title-line flex-apart-row'>
                                    <div className='timelimit-title flexr'>
                                        <div className='timelimit-seckill'>
                                            <img src={seckill} alt='' />
                                        </div>
                                        <div className='timimg flexr'>
                                            <div>10点场</div>
                                            <div>00:21:16</div>
                                        </div>
                                    </div>
                                    <div className='timelimit-more flexr' onClick={() => this.state.props.history.push('/seckill')}>
                                        <span>查看更多</span>
                                        <i className='iconfont icon-xiangyou'></i>
                                    </div>
                                </div>
                                <div className='med-part'>
                                    <Activity category={categoryTab} limitKill={limitKill} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='cost-dagstore'>
                        <div className='dragstore-title-line flex-apart-row'>
                            <div className='dragstore-title flexr'>
                                <span>品牌店铺</span>
                            </div>
                            <div className='dragstore-more flexr'
                                onClick={this.moreStore}>
                                <span>查看更多</span>
                                <i className='iconfont icon-xiangyou'></i>
                            </div>
                        </div>
                        {/* <div className='shop-around flexc'>
                            <div className='shop-around-title flex-row'>附近药房</div>
                            <div className='flex-apart-row'>
                                <span>综合排序</span>
                                <span>销量</span>
                                <span>距离</span>
                                <span>优惠</span>
                                <span>筛选</span>
                            </div>
                        </div> */}
                        {preference.map(item => (
                            <div key={item.pharmacyId}>
                                <Shopitem pharmacy={item} />
                            </div>
                        ))}
                    </div>
                    <div className='recommend-med'>
                        <div className='recommend-med-title flex-apart-row'>
                            <div className="top-med-title" id="top-med-title">平台推荐</div>
                            {/* <div className='vary-line'/>
                            <img src={rmd} alt=''/>
                            <div className='vary-line'/> */}
                        </div>
                        <div className='recommend-med-list'>
                            <RecommmendMed2 sunthis={this.state.sunthis} recommand={recommand}/>
                        </div>
                    </div>
                </div>
                <FootNav  onRef={this.onRef}/>
            </div>
        )
    }
}

export default Platform