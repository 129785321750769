export function storage(res3,usercode){
    console.log(res3,usercode)
    if(usercode==1){
        localStorage.setItem("userId1", res3.data[0][0].userId)
        localStorage.setItem("userAcount1", res3.data[0][0].userAcount)
        localStorage.setItem("userVip1", res3.data[0][0].userVip)
        localStorage.setItem("userName1", res3.data[0][0].userName)
        localStorage.setItem("userAvatar1", res3.data[0][0].userAvatar)
    }else if(usercode==2){
        localStorage.setItem("userId2", res3.data[0][0].userId)
        localStorage.setItem("userAcount2", res3.data[0][0].userAcount)
        localStorage.setItem("userVip2", res3.data[0][0].userVip)
        localStorage.setItem("userName2", res3.data[0][0].userName)
        localStorage.setItem("userAvatar2", res3.data[0][0].userAvatar)
    }else{
        localStorage.setItem("userId3", res3.data[0][0].userId)
        localStorage.setItem("userAcount3", res3.data[0][0].userAcount)
        localStorage.setItem("userVip3", res3.data[0][0].userVip)
        localStorage.setItem("userName3", res3.data[0][0].userName)
        localStorage.setItem("userAvatar3", res3.data[0][0].userAvatar)
    }
}