import { axiosInstance } from '../config'

//查询优惠券
export const couponlistRequest = (userId, couponState) => {
    return axiosInstance.get(`/api/hosp/coupon/query?userId=${userId}&couponState=${couponState}`)
}

//添加优惠券
export const addcouponRequest = () => {
    return axiosInstance.get('/api/hosp/coupon/add')
}

//更新优惠券状态
export const updatecouponRequest = () => {
    return axiosInstance.get('/api/hosp/coupon/state')
}
// 用户领取优惠券		
export const userGetCoupon = (data) => {
    return axiosInstance.post(`/api/hosp/coupon/user-get-coupon`,data)
}
// 个人中心优惠券详情	
export const ownCouponDetail = (data) => {
    return axiosInstance.post(`/api/hosp/coupon/own-coupon-detail`,data)
}