import React, { useState, useReducer, useRef, useEffect } from 'react'
import { WhiteSpace, Checkbox, Toast, InputItem, Button } from 'antd-mobile'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import {getUserSH} from "../../api/request/user"
import { login, register, phonetest, codetest, resetmsg, resetdirect } from '../../redux/user.redux'
import { regsmsRequest, logincodeRequest, onlyAccountRequest,updatesxVerifystate, smssignRequest,codesignRequest, verifyRequest, accountsignRequest, loginRequest } from '../../api/request/user'
import enhancedReducer from '../../component/form/index'
import Inputitem from '../../component/Inputitem/index'

import ICON1 from '../../static/img/icon/zhifubao.png'
import ICON2 from '../../static/img/icon/weixin.png'
import './style.less'
import localStorage from 'redux-persist/es/storage'
import { storage } from "./userStorage"

function Login(props) {
	const initalState = {
		type: 1,
		user: '',
		pwd: '',
		phone: '',
		code: {
			i1: '',
			i2: '',
			i3: '',
			i4: '',
			i5: '',
			i6: '',
		}
	}
	const { msg, redirectTo } = props
	const { loginDispatch, registerDispatch, phonetestDispatch, codetestDispatch, resetmsgDispatch, resetdirectDispatch } = props
	// 获取用户类型和平台类型
	const [acountType, setacountType] = useState(window.localStorage.getItem("type"))
	const [pharmacyId, setpharmacyId] = useState(window.localStorage.getItem("pharmacyId")=="25"?"0":window.localStorage.getItem("pharmacyId"))
	const [usercode, setusercode] = useState(window.localStorage.getItem("type"))
	const [loginway, setLoginway] = useState('手机验证码登录')
	const [regway, setRegway] = useState('忘记密码')
	const [title, setTitle] = useState('请输入手机号')
	const [reg, setreg] = useState(0)
	const [regMsg, setRegMsg] = useState('')
	const [state, updateState] = useReducer(enhancedReducer, initalState)
	const [nowinput, setNowinput] = useState(0)
	const [preredirectTo, setPreredirectTo] = useState(redirectTo)
	const num1 = useRef()
	const num2 = useRef()
	const num3 = useRef()
	const num4 = useRef()
	const num5 = useRef()
	const num6 = useRef()
	const code = useRef()
	const [inputval, setinputval] = useState("")
	let [newinput, setnewinput] = useState("")
	const [checked, setchecked] = useState(false)
	let [lable, setlable] = useState(false)
	let [time, settime] = useState(60)
	let [time2, setTime2] = useState(60)
	useEffect(() => {
		console.log(acountType);
	}, [])
	useEffect(() => {
		if (title === '请输入验证码') {
			switch (nowinput) {
				case 0:
					num1.current.focus()
					return
				case 1:
					num2.current.focus()
					return
				case 2:
					num3.current.focus()
					return
				case 3:
					num4.current.focus()
					return
				case 4:
					num5.current.focus()
					return
				case 5:
					num6.current.focus()
					return
				case 6:
					const nowcode = Object.values(state.code).join().replace(/,/g, "")
					const data = {
						type: state.type,
						code: nowcode,
						phone: state.phone
					}
					if (state.type === 2) {
						verifyRequest(data.phone, data.phone).then(res => {
							if (res.code === 1) {
								if (res.data[0].length === 0) {
									setTitle('请设置登录密码')
								}
							}
						})
					} else {
						codetestDispatch(data)
					}
					return
				default:
					return
			}
		}
		resetmsgDispatch('')
	}, [nowinput])
	const regFinish = () => {
		const nowcode = Object.values(state.code).join().replace(/,/g, "")
		const data = {
			type: state.type,
			code: nowcode,
			phone: state.phone,
			pwd: state.pwd
		}
		codetestDispatch(data)
	}
	// 验证码登录判断
	const prepwd = async () => {
		console.log(state.phone, code.current.state.value, pharmacyId?pharmacyId:0)
		const res1 = await onlyAccountRequest(state.phone, pharmacyId?pharmacyId:0)
		console.log(res1)
		if (res1.data[0].length == 0) {
			//const acountType=pharmacyId=="0"?"1":pharmacyId=="2"?"3":"2"
			const acountType=window.localStorage.getItem("type")
			console.log(acountType);

			const res = await verifyRequest(state.phone, code.current.state.value, pharmacyId?pharmacyId:0)
			console.log(res)
			if (res.data[0].length) {
				const res2=await smssignRequest(state.phone,acountType,pharmacyId)
				console.log(res2)
				if (res2.code==1){
					const res3 = await codesignRequest({ userAcount: state.phone, smsCode: code.current.state.value, pharmacyId: pharmacyId?pharmacyId:0 })
					console.log(res3)
					//localStorage.setItem("wx",2)
					//window.localStorage.setItem("phonestate",false)
					storage(res3, usercode)
					// localStorage.setItem("userId", res2.data[0][0].userId)
					// localStorage.setItem("userAcount", res2.data[0][0].userAcount)
					// localStorage.setItem("userVip", res2.data[0][0].userVip)
					// localStorage.setItem("userName", res2.data[0][0].userName)
					// localStorage.setItem("userAvatar", res2.data[0][0].userAvatar)
						props.history.push("/")
				}

			} else {
				Toast.fail("验证码错误");
			}
		} else {
			const res = await verifyRequest(state.phone, code.current.state.value, pharmacyId?pharmacyId:0)
			console.log(res)
			if (res.data[0].length) {
				const res3 = await codesignRequest({ userAcount: state.phone, smsCode: code.current.state.value, pharmacyId: pharmacyId?pharmacyId:0 })
				console.log(res3)
				// if (res3.data[0].length&&res3.data[0][0].userStatus==2) {
					//localStorage.setItem("wx",2)
					//window.localStorage.setItem("phonestate",false)
					storage(res3, usercode)
					// localStorage.setItem("userId", res2.data[0][0].userId)
					// localStorage.setItem("userAcount", res2.data[0][0].userAcount)
					// localStorage.setItem("userVip", res2.data[0][0].userVip)
					// localStorage.setItem("userName", res2.data[0][0].userName)
					// localStorage.setItem("userAvatar", res2.data[0][0].userAvatar)
						props.history.push("/")
				// }else{
				// 	Toast.fail("该账号已冻结，无法继续登录");
				// }

			} else {
				Toast.fail("验证码错误");
			}
		}

	}
	// 存输入框的值
	const updateFrom = React.useCallback(({ target: { value, name, type } }) => {
		const updatePath = name.split(".")
		if (type === "checkbox") {
			
			updateState((prevState) => ({
				[name]: !prevState[name]
			}))

			return
		}
		// console.log(updatePath);
		if (updatePath.length === 1) {
			const [key] = updatePath
			// console.log(key,value);
			if (key=='user') {
				if (!isNaN(value)&&value%1==0&&value.substr(0,1)!='-'&&value.substr(0,1)!='+'&&value.length<12) {
					updateState({
						[key]: value
					})
					if (value.substr(-1)=='.') {
						updateState({
							[key]: value.substr(0,value.length-1)
						})
					}
				}
			}else{
				if (value.length<19) {
					updateState({
						[key]: value
					})
				}
			}
			// updateState({
			// 	[key]: value
			// })
		}

		if (updatePath.length === 2) {
			if (value.length > 1) { value = value.slice(0, 1) }
			updateState({
				_path: updatePath,
				_value: value
			})
			if (value.length > 0) {
				setNowinput(nowinput => nowinput + 1)
			}
		}
	}, [])
	// 手机号存储
	useEffect(() => {
		localStorage.setItem("phone", JSON.stringify(state.phone))
	}, [state.phone])
	// 登录判断
	const handleLogin = async () => {
		console.log(state.pwd);
			const res = await onlyAccountRequest(state.user, pharmacyId?pharmacyId:0)
			console.log(res)
			if (res.data[0].length == 0) {
				Toast.fail("该账户未被注册")
			} else {
				const res3 = await loginRequest({ userAcount: state.user, userPwd: state.pwd, pharmacyId: pharmacyId?pharmacyId:0 })
				 console.log(res3)
				if (res3.data[0].length) {
					// if (res3.data[0][0].userStatus==2) {
						Toast.success('登录成功', 1);
						console.log(res3)
						//localStorage.setItem("wx",2)
						//window.localStorage.setItem("phonestate",false)
						storage(res3, usercode)
						// localStorage.setItem("userId", res3.data[0][0].userId)
						// localStorage.setItem("userAcount", res3.data[0][0].userAcount)
						// localStorage.setItem("userVip", res3.data[0][0].userVip)
						// localStorage.setItem("userName", res3.data[0][0].userName)
						// localStorage.setItem("userAvatar", res3.data[0][0].userAvatar)
						// console.log(localStorage.singleTob, localStorage.env)
						if (pharmacyId === 2) {
							props.history.push("/mine?env=single&pharmacyId=2")
						} else if (pharmacyId == 25) {
							props.history.push("/mine?env=single&pharmacyId=25")
						}
						else {
							props.history.push("/mine")
						}
					// }else {
					// 	Toast.fail("该账号已冻结，无法继续登录")
					// }
				} else {
					Toast.fail("密码输入错误")
				}
			}

			// props.loginDispatch({ ...state, acountType: localStorage.singleTob === '1' ? 2 : 1 })
	}
	// 注册判断    账号密码注册
	const handleReg = async () => {
		console.log(acountType,pharmacyId)
		console.log(state.user, state.yzm,pharmacyId);
		if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(state.pwd)) || !(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(state.pwd))) {
			Toast.fail("请输入6~18位由数字和字母组成的密码")
		} else {
			const res = await verifyRequest(state.user, state.yzm, pharmacyId ? pharmacyId : 0)
			// 验证码判断
			if (res.data[0].length) {
				const res2 = await onlyAccountRequest(state.user, pharmacyId ? pharmacyId : 0)
				// 账号唯一性
				console.log(res2)
				if (res2.data[0].length == 0) {
					// if (checked) {
					if (state.pwd == state.pwd2) {
						const res = await accountsignRequest({
							userAcount: state.user,
							userPwd: state.pwd,
							acountType: acountType,
							pharmacyId: pharmacyId ? pharmacyId : 0
						})
						console.log(res)
						if (res.code == 1) {
							Toast.info('注册成功', 1);
							props.history.push("/login/Login1")
						} else {
							Toast.info('网络出错了', 1);
						}
					} else {
						Toast.info('两次密码不相同', 1);
					}
					// } else {
					// 	Toast.info('请先勾选相关用户协议', 1);
					// }
				} else {
					Toast.info('账号已存在', 1);
				}

				// onlyAccountRequest(state.user).then(res => {
				// 	if (checked) {
				// 		if (res.code === 1) {
				// 			if (state.pwd == state.pwd2) {
				// 				const res = await accountsignRequest({ userAcount: state.user, userPwd: state.pwd })
				// 				if (res.code == 1) {
				// 					Toast.info('注册成功', 1);
				// 					props.history.push("/login")
				// 				} else {
				// 					Toast.info('网络出错了', 1);
				// 				}
				// 			} else {
				// 				Toast.info('两次密码不相同', 1);
				// 			}
				// 		}
				// 	} else {
				// 		Toast.info('请先勾选相关用户协议', 1);
				// 	}
				// })
			} else {
				Toast.info('验证码输入错误', 1);
			}
		}
	}
	// 动态切换标题更改页面功能
	const ChooseWay = () => {
		if (loginway === '手机验证码登录') {
			setLoginway('账号密码登录')
			setTitle('请输入手机号')
		}
		if (loginway === '账号密码登录') {
			setLoginway('注册')
			setTitle('欢迎登录智云众康')
		}
		if (loginway === '注册') {
			setLoginway('账号密码登录')
			setTitle('账号密码注册')
		}
		if (loginway === '手机快速注册') {
			setLoginway('账号密码注册')
			setTitle('请输入手机号')
		}
	}
	const registerWay = () => {
		props.history.push("/login/retrieveone")
		if (regway === '账号密码登录') {
			updateState({
				type: 1,
				user: '',
				pwd: '',
				phone: '',
				code: {
					i1: '',
					i2: '',
					i3: '',
					i4: '',
					i5: '',
					i6: '',
				}
			})
			setRegway('新用户注册')
			setLoginway('手机验证码登录')
			setTitle('账号密码登录')
			resetmsgDispatch('')
		}
		if (regway === '新用户注册') {
			updateState({
				type: 2,
				user: '',
				pwd: '',
				phone: '',
				code: {
					i1: '',
					i2: '',
					i3: '',
					i4: '',
					i5: '',
					i6: '',
				}
			})
			setRegway('账号密码登录')
			// setLoginway('手机快速注册')
			setLoginway('')
			// setTitle('账号密码注册')
			setTitle('请输入手机号')
			resetmsgDispatch('')
		}
	}
	// 重置time
	useEffect(() => {
		if (time <= 0) {
			console.log(1111)
			settime(60)
		}
	}, [time])
	// 重新获取验证码
	const getcode = () => {
		const phone = state.phone
		setlable(false)
		updatesxVerifystate(phone).then(res0=>{
			console.log(res0);
			if (res0.code==1) {
				if (state.type == 1) {
					regsmsRequest({ pharmacyId:pharmacyId,userPhone: phone }).then(res => {
						if (res.code === 1) {
							Toast.success('获取成功', 1);
							const timer = setInterval(() => {
								settime(time -= 1)
								if (time == 0) {
									updatesxVerifystate(phone).then(res5=>{
										if (res5.code==1) {
											setlable(true)
											clearInterval(timer)
										}
									})
								}
							}, 1000);
						} else {
							Toast.fail('该手机号获取次数已满', 1);
						}
					})
				} else {
					onlyAccountRequest(phone, pharmacyId?pharmacyId:0).then(res => {
						if (res.code === 1) {
							Toast.success('获取成功', 1);
							// let newtime=time
							// console.log(newtime)
							const timer = setInterval(() => {
								settime(time -= 1)
								if (time == 0) {
									updatesxVerifystate(phone).then(res5=>{
										if (res5.code==1) {
											setlable(true)
											clearInterval(timer)
										}
									})
								}
							}, 1000);
						} else {
							Toast.fail('该手机号获取次数已满', 1);
						}
					})
				}
			}
		})
	}
	// 点击获取验证码
	const ToNext = () => {
		// if (checked) {
			const phone = state.phone
		console.log(phone)
		if (!phone){
			resetmsgDispatch('请输入手机号')
		}else if (!(/^1[3456789]\d{9}$/.test(phone))) {
			resetmsgDispatch('手机号码有误，请重填')
			return
		}else {
			resetmsgDispatch('')
			updatesxVerifystate(phone).then(res0=>{
				console.log(res0);
				if (res0.code==1) {
					if (state.type === 1) {
						logincodeRequest({pharmacyId:pharmacyId, userPhone: phone }).then(res => {
							console.log(res)
							if (res.code === 1) {
								setTitle('请输入验证码')
								const timer = setInterval(() => {
									settime(time -= 1)
									if (time == 0) {
										updatesxVerifystate(phone).then(res5=>{
											if (res5.code==1) {
												setlable(true)
												clearInterval(timer)
											}
										})
									}
								}, 1000);
							} else {
								Toast.fail('该手机号获取次数已满', 1);
							}
						})
					} else {
						onlyAccountRequest(phone, pharmacyId?pharmacyId:0).then(res => {
							if (res.code === 1) {
								if (res.data[0].length === 0) {
									regsmsRequest({ userPhone: phone }).then(res => {
										if (res.code === 1) {
											setTitle('请输入验证码')
											const timer = setInterval(() => {
												settime(time -= 1)
												if (time == 0) {
													updatesxVerifystate(phone).then(res5=>{
														if (res5.code==1) {
															setlable(true)
															clearInterval(timer)
														}
													})
												}
											}, 1000);
										}
									})
								} else {
									resetmsgDispatch('该手机号已注册')
								}
							} else {
								Toast.fail('该手机号获取次数已满', 1);
							}
						})
					}
				}
			})
			return
		}
		// } else {
		// 	Toast.info('请先勾选相关用户协议', 1);
		// }
	}
	const checkdelete = (e) => {
		if (e.keyCode === 8) {
			switch (nowinput) {
				case 0:
					updateState({
						_path: ["code", "i1"],
						_value: ''
					})
					return
				case 1:
					updateState({
						_path: ["code", "i1"],
						_value: ''
					})
					setNowinput(0)
					return
				case 2:
					updateState({
						_path: ["code", "i2"],
						_value: ''
					})
					setNowinput(1)
					return
				case 3:
					updateState({
						_path: ["code", "i3"],
						_value: ''
					})
					setNowinput(2)
					return
				case 4:
					updateState({
						_path: ["code", "i4"],
						_value: ''
					})
					setNowinput(3)
					return
				case 5:
					updateState({
						_path: ["code", "i5"],
						_value: ''
					})
					setNowinput(4)
					return
				case 6:
					updateState({
						_path: ["code", "i6"],
						_value: ''
					})
					setNowinput(5)
					return
				default:
					return
			}
		}
	}

	const back = () => {
		window.history.back()
	}
	// 单选框判断
	const onChange = (e) => {
		setchecked(e.target.checked)
	}
	// 改变btn状态
	const onChange2 = () => {
		setreg(1)
	}
	// 注册时获取验证码
	const getyzm = () => {
		if (!(/^1[3456789]\d{9}$/.test(state.user))) {
			Toast.success("手机号码有误，请重填");
		} else {
			logincodeRequest({pharmacyId: pharmacyId, userPhone: state.user}).then(res => {
				if (res.code == 1) {

					const timer = setInterval(() => {
						setTime2(time2 -= 1)
						if (time2 === 0) {
							clearInterval(timer)
							setTime2(60)
						}
					}, 1000);

					Toast.success("获取成功");
				}
			})
		}
	}
	// 判断验证码是否正确
	// const blur = async () => {
	// 	const res = await verifyRequest({ phone: state.user, code: state.yzm })
	// 	if (res.data.length) {
	// 		Toast.info('验证码正确', 1)
	// 		console.log(res)
	// 	} else {
	// 		Toast.info('验证码输入错误', 1);
	// 	}
	// }

	// 注册界面
	const renderAccount = () => {
		return (
			<>
				<div className='login-account flexc'>
					<Inputitem>
						<input
							placeholder="请输入手机号"
							type="number"
							name='user'
							maxLength={11} 
							onChange={updateFrom}
							value={state.user}
						/>
					</Inputitem>
					{loginway == "账号密码登录" ?
						<Inputitem>
							<input
								placeholder="请输入验证码"
								type="text"
								style={{fontSize:'18px'}}
								name='yzm'
								onChange={updateFrom}
								value={state.yzm}
							/>
							{
								time2 === 60 ? <a onClick={getyzm}>发送验证码</a> : <a>{time2}s后重发</a>
							}
						</Inputitem> : null
					}
					<WhiteSpace />
					<Inputitem>
						<input
							placeholder="请输入6-18位密码"
							type="password"
							name='pwd'
							onChange={updateFrom}
							value={state.pwd}
						/>
					</Inputitem>
					{loginway == "账号密码登录" ?
						<Inputitem>
							<input
								placeholder="请再次输入密码"
								type="password"
								name='pwd2'
								onChange={updateFrom}
								value={state.pwd2}
							/>
						</Inputitem> : null
					}
					<p className='login-error-msg'>{msg}</p>
				</div>
				<div className="bottom">
					{/* <Checkbox onChange={(e) => onChange(e)}> 已阅读并同意 <a href="">《软件使用许可协议》</a> <a href="">《法律声明与隐私权政策》</a><a href="">《众康云药平台服务条款》</a></Checkbox> */}

				</div>
				{title === '账号密码注册' ?
					<button
						onClick={handleReg}
						className={state.user && state.pwd && state.yzm && state.pwd2 ? 'login-button' : 'login-button disabled'}
					>注册</button> :
					<button onClick={handleLogin}
							className={state.user && state.pwd ? 'login-button' : 'login-button disabled'}>登录</button>
				}
			</>
		)
	}
	// 手机验证码登录
	const renderPhone = () => {
		return (
			<>
				<div className='login-phone'>
					<Inputitem>
						<input
							placeholder="请输入手机号码"
							type="number"
							name='phone'
							maxLength={11}
							onChange={updateFrom}
							value={state.phone}
						/>
					</Inputitem>
					<p className='login-error-msg'>{msg}</p>
					<p className="title">未注册的手机号验证后自动创建账户</p>
				</div>
				<div className="bottom">
					{/* <Checkbox onChange={(e) => onChange(e)}> 已阅读并同意 <a href="">《软件使用许可协议》</a> <a href="">《法律声明与隐私权政策》</a>
						<a href="">《众康云药平台服务条款》</a></Checkbox> */}
				</div>
				{/*{usercode=='2'?<button onClick={ToNext} className={state.phone !== '' ? 'login-button' : 'login-button disabled'} >获取短信验证码</button>:*/}
				<button onClick={ToNext} className={state.phone !== '' ? 'login-button' : 'login-button1'} >获取短信验证码</button>
			</>
		)
	}
	// 输入验证码
	const renderCode = () => {
		return (
			<div className='login-code'>
				<p style={{ marginBottom: "10px" }}>验证码已发送至 <b>+86  {state.phone}</b> &nbsp;&nbsp;&nbsp;获取验证码</p>
				{/* <div className='flex-row'>
					<input autoFocus name='code.i1' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i1} ref={num1} type="number" />
					<input name='code.i2' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i2} ref={num2} type="number" />
					<input name='code.i3' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i3} ref={num3} type="number" />
					<input name='code.i4' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i4} ref={num4} type="number" />
					<input name='code.i5' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i5} ref={num5} type="number" />
					<input name='code.i6' onKeyDown={(e) => { checkdelete(e) }} onChange={updateFrom} value={state.code.i6} ref={num6} type="number" />
				</div> */}
				<InputItem
					type="number"
					style={{ width: "90%" }}
					placeholder="请输入验证码"
					style={{fontSize:'16px'}}

					onChange={onChange2}
					ref={code}
				></InputItem>
				<p className='login-error-msg'>{msg}</p>
				{usercode=='2'?<Button onClick={prepwd} className={reg == 0 ? "btn" : "btn1"}><p style={{ fontSize: "16px", lineHeight: "45px" }}>确认</p></Button>:
				<Button onClick={prepwd} className={reg == 0 ? "btn3" : "btn4"}><p style={{ fontSize: "16px", lineHeight: "45px" }}>确认</p></Button>}
				{lable == true ? <p className="tips" onClick={getcode}>重新获取验证码</p> : <p className="tips">还有{time}秒重新发送</p>}
			</div>
		)
	}
	// 
	const loginPwd = () => {
		return (
			<>
				<div className='login-account flexc'>
					<Inputitem>
						<input
							placeholder="请设置为8-20位字符"
							type="password"
							name='pwd'
							onChange={updateFrom}
							value={state.pwd}
						/>
					</Inputitem>
					<p className='login-error-msg'>{regMsg}</p>
				</div>
				<button onClick={regFinish} className='login-button'>完成</button>
			</>
		)
	}
	
	// 跳转到验证码登录
	const tologinnum = () => {
		if (loginway == "账号密码登录" || loginway == "注册") {
			setLoginway('手机验证码登录')
			setTitle('请输入手机号')
		} else if (loginway == "手机验证码登录") {
			setLoginway('注册')
			setTitle('欢迎登录智云众康')
		}

	}
	return (

		<div className='login-full-screen'>
			{redirectTo !== preredirectTo ? <Redirect to={redirectTo} /> : null}
			<div className='choose-login' >
				<i className='iconfont icon-xiangzuo' onClick={back}></i>
				{usercode=='2'?<span onClick={tologinnum}>{loginway == "账号密码登录" || loginway == "注册" ? "手机验证码登录" : "账号密码登录"}</span>:
				<span style={{color:"#2086FE"}} onClick={tologinnum}>{loginway == "账号密码登录" || loginway == "注册" ? "手机验证码登录" : "账号密码登录"}</span>}
				{/* <span onClick={ChooseWay}>{loginway}</span> */}
			</div>
			<p>{localStorage.singleTob === '1' ? '商家采购用户登录' : title}</p>
			<div className='login-area'>
				{title === '欢迎登录智云众康' || title === '账号密码注册' ?
					<>
						{renderAccount()}
					</>
					: null}
				{title === '请输入手机号' ?
					<>
						{renderPhone()}
					</>
					: null}
				{title === '请输入验证码' ?
					<>
						{renderCode()}
					</>
					: null}
				{title === '请设置登录密码' ?
					<>
						{loginPwd()}
					</>
					: null}
				{loginway == "手机验证码登录" ? null : <div className='choose-login'>
					<span onClick={ChooseWay} style={{color:'#2086FE'}}>{loginway}</span>
					{loginway === '注册' ?
						<span onClick={registerWay} style={{color:'#2086FE'}}>{regway}</span> : null
					}

				</div>}
			</div>
			{/* <div className='login-other'>
				<div className='login-thirdway flex-apart-row'>
					<div className='huixian'></div>
					<div>使用合作账号登录</div>
					<div className='huixian'></div>
				</div>
				<div className='login-icon flex-row'>
					<img src={ICON1} alt=""/>
					<img src={ICON2} alt=""/>
				</div>
			</div> */}
			{/* {localStorage.singleTob === '1' ? <div className='agreement'>注意:仅支持商家采购用户登录</div> : <div className='agreement'>登录代表您已阅读并同意
				<Link to='/privacy'>《云药购隐私保护政策》</Link>和<Link to='/consumer'>《云药购用户协议》</Link>
			</div>} */}

		</div>
	)
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
	msg: state.user.msg,
	redirectTo: state.user.redirectTo,
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
	return {
		registerDispatch(data) {
			dispatch(register(data))
		},
		loginDispatch(data) {
			dispatch(login(data))
		},
		phonetestDispatch(data) {
			dispatch(phonetest(data))
		},
		codetestDispatch(data) {
			dispatch(codetest(data))
		},
		resetmsgDispatch(data) {
			dispatch(resetmsg(data))
		},
		resetdirectDispatch(data) {
			dispatch(resetdirect(data))
		},
	}
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Login))