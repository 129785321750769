import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {resetdirect} from '../../redux/user.redux'

import './style.less'
import '../../static/css/iconfont.css'
import { oneMessage } from '../../api/request/msg'
class HeaderNav extends Component{
    constructor(props) {
        super(props)
        this.state = {
            props: props.props,
            city: localStorage.posDistrict?localStorage.posDistrict:null,
            userId: localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3,
            onclick: props.onclick,
            xtCount:0,
            yhCount:0,
            ddCount:0
        }
    }
    componentDidMount(){
        const { userId } = this.state
        oneMessage(userId).then(res => {
            console.log(res);
            if (res.code==1) {
                if (res.data[0].length) {
                    this.setState({
                        xtCount:res.data[0][0].allNews
                    })
                }
                if (res.data[1].length) {
                    this.setState({
                        yhCount:res.data[1][0].allNews1
                    })
                }
                if (res.data[2].length) {
                    this.setState({
                        ddCount:res.data[2][0].allNews2
                    })
                }
            }
        })
    }
    render(){
        const { props, city, userId, onclick,ddCount,yhCount,xtCount } = this.state
        console.log(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount));
        return(
            <div id='home-header' className='flex-apart-row'>
                <div className='home-header-left flexr'>
                    {city ? <div>{city}</div> : null}
                    {/* <i className='iconfont icon-xiangxia'></i> */}
                </div>
                <div className='home-header-middle'>
                    <div className='search-container'>
                        <Link to='/search' className='flexr'>
                            <i className='iconfont icon-suosou'></i>
                            <input type='text' readOnly placeholder='搜索商品'/>
                        </Link>
                    </div>
                </div>
                <div className='home-header-right'>
                    <Link onClick={()=>{
                        if(localStorage.userId1) {
                            props.history.push('/tidings')
                        } else {
                            this.props.redirectDispatch(localStorage.env==='single'?`/?env=single&pharmacyId=${localStorage.pharmacyId}`:'/')
                            props.history.push('/login/login1')
                        }
                    }}>
                        <i className='iconfont icon-xiaoxi'></i>
                        {(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))!=0&&userId?
                        <span className='count'>{(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))<100?(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount)):'99+'}</span>:null}
                    </Link>
                    <div onClick={onclick}>
                        <i className='iconfont icon-saoyisao'></i>
                    </div>
                </div>

            </div>
        )
    }
}
    
// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav)