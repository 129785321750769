import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import acttest from '../../static/img/app/acttest.jpeg'
import acttest1 from '../../static/img/actpage2-bgc.png'
import defaultImg from '../../static/img/default.png'
import mj from '../../static/img/mj.png'
import Nodata from '../../component/Nodata'
import { getActivities, allPharmacys, getMeds } from '../../api/request/home'

import './style2.less'


class ActPage1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arrDetails: [],
            list: '',
            pharmacyIds: '',
            value: '',
            weeks: '',
            allDetails: [],
        }
    }
    componentDidMount = async () => {
        const { arrDetails} = this.state
        var tempDate = new Date();
        var days = tempDate.getDay();
        var week;
        switch (days) {
            case 1:
                week = '周一';
                break;
            case 2:
                week = '周二';
                break;
            case 3:
                week = '周三';
                break;
            case 4:
                week = '周四';
                break;
            case 5:
                week = '周五';
                break;
            case 6:
                week = '周六';
                break;
            case 0:
                week = '周日';
                break;
        }
        this.setState({
            weeks: week
        })
        let res = await getActivities()
        console.log(res);
        let arr = []
        let arr1 = []

        let details = []
        if (res.data[0].length) {
            res.data[0].map(item => {
                item.state = false
                item.activitiesCycle = JSON.parse(item.activitiesCycle)
                item.activitiesDiscount = JSON.parse(item.activitiesDiscount)
                item.activitiesCycle.map(v => {
                    if (v == week) {
                        item.state = true
                    }
                })
                if (item.pharmacyId) {
                    arr.push(item.pharmacyId)
                    if (item.activitiesType==2) {
                        arr1.push(item.pharmacyId)
                    }
                }
                if (item.state && item.activitiesType == 1) {
                    if (res.data[1].length) {
                        let nArr1 = res.data[1].filter((currentValue, currentIndex, selfArr) => {
                            return selfArr.findIndex(x => x.activeMedId === currentValue.activeMedId) === currentIndex
                        });
                        nArr1.map(v => {
                            if (item.pharmacyId == v.activeStoreId) {
                                details.push(v)
                            }
                        })
                        console.log(details);
                        this.setState({
                            arrDetails: details,
                        })
                    }
                }
            })
            let array0 = [...new Set(arr)]
            let array1 = [...new Set(arr1)]

            let arr2=[]
            
            console.log(array1);
            res.data[0].map(item => {
                if (item.state && item.activitiesType == 2&&array1.length) {
                    array1.map(i=>{
                        if (i) {
                            getMeds({pharmacyId:i}).then(res2 => {
                                console.log(res2);
                                if (res2.data[0].length) {
                                    res2.data[0].map(v=>{
                                        arr2.push(v)
                                    })
                                    console.log(arr2);
                                    let nArr3 =arr2.filter((currentValue, currentIndex, selfArr) => {
                                        return selfArr.findIndex(x => x.medQrcode === currentValue.medQrcode) === currentIndex
                                    });
                                    this.setState({
                                        arrDetails: details.concat(nArr3),
                                    })
                                }
                            })
                        }
                    })
                }
            })
            this.setState({
                allDetails: res.data[0],
            },()=>{
                console.log(res.data[0])
            })
        }
        let array = [...new Set(arr)]
        if (array.length) {
            this.setState({
                pharmacyIds: array
            }, () => {
                setTimeout(()=>{
                    this.allPharmacyIds()
                },500)
            })

        }
    }
    allPharmacyIds = async () => {
        const { pharmacyIds, allDetails, arrDetails,value } = this.state
        let longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
        let latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
        let res = await allPharmacys({ longitude, latitude,keyName: value })
        console.log(res);
        console.log(arrDetails);
        let list0 = []
        if (res.data[0].length) {
            res.data[0].map(i => {
                i.activitiesDiscount = ''
                i.state1=0
                i.count=0
                pharmacyIds&&pharmacyIds.map(v => {
                    if (i.pharmacyId == v) {
                        list0.push(i)
                    }
                })
                allDetails.length && allDetails.map(e => {
                    if (i.pharmacyId == e.pharmacyId) {
                        i.activitiesDiscount = e.activitiesDiscount
                        i.state1=1
                    }
                })
                arrDetails.length&&arrDetails.map(m=>{
                    if (m.activeStoreId==i.pharmacyId){
                        i.count=1
                    }
                })
            })
            console.log(list0);
            // if (list0.length){
            //     list0.map(i=>{
            //         if (i.state1){
            //             i.count=1
            //         }
            //     })
            // }
            this.setState({
                list: list0,
            })
        }else{
            allPharmacys({ longitude, latitude}).then(res2=>{
                console.log(res2)
                if (res2.data[0].length){
                    res2.data[0].map(i=>{
                        i.activitiesDiscount = ''
                        i.state1=0
                        i.count=0
                        pharmacyIds&&pharmacyIds.map(v => {
                            if (i.pharmacyId == v) {
                                list0.push(i)
                            }
                        })
                        allDetails.length && allDetails.map(e => {
                            if (i.pharmacyId == e.pharmacyId) {
                                i.activitiesDiscount = e.activitiesDiscount
                                i.state1=1
                            }
                        })
                        arrDetails.length&&arrDetails.map(m=>{
                            if (m.activeStoreId==i.pharmacyId){
                                i.count=1
                            }
                        })
                    })
                    // if (list0.length){
                    //     list0.map(i=>{
                    //         if (i.state1){
                    //             i.count=1
                    //         }
                    //     })
                    // }
                    this.setState({
                        list: list0,
                    })
                }
            })

        }
    }
    backPage = () => {
        window.history.back()
    }
    getValue = (e) => {
        this.setState({
            value: e.target.value,
        })
    }
    onSearch = async () => {
        const { value, arrDetails,weeks } = this.state
        if (value){
            let res = await getActivities({ keyName: value })
            console.log(res);
            let arr = []
            let arr1 = []

            let details = []
            if (res.data[0].length) {
                let nArr = res.data[0].filter((currentValue, currentIndex, selfArr) => {
                    return selfArr.findIndex(x => x.activitiesId === currentValue.activitiesId) === currentIndex
                });
                console.log(nArr);

                nArr.map(item => {
                    item.state = false
                    item.activitiesCycle = JSON.parse(item.activitiesCycle)
                    item.activitiesDiscount = JSON.parse(item.activitiesDiscount)
                    item.activitiesCycle.map(v => {
                        if (v == weeks) {
                            item.state = true
                        }
                    })
                    if (item.pharmacyId) {
                        arr.push(item.pharmacyId)
                        if (item.activitiesType==2) {
                            arr1.push(item.pharmacyId)
                        }
                    }
                    if (item.state && item.activitiesType == 1) {
                        if (res.data[1].length) {
                            let nArr1 = res.data[1].filter((currentValue, currentIndex, selfArr) => {
                                return selfArr.findIndex(x => x.activeMedId === currentValue.activeMedId) === currentIndex
                            });
                            nArr1.map(v => {
                                if (item.pharmacyId == v.activeStoreId) {
                                    details.push(v)
                                }
                            })
                            console.log(details);
                        //     this.setState({
                        //         arrDetails: details,
                        //     })
                        // }else{
                        //     this.setState({
                        //         arrDetails: details,
                        //     })
                        }
                    }
                    let array0 = [...new Set(arr)]
                    let array1 = [...new Set(arr1)]
                    let arr2=[]

                    console.log(array0,array1);
                    if (item.state && item.activitiesType == 2&&array1.length) {
                        array1.map(i=>{
                            if (i) {
                                getMeds({pharmacyId:i,keyName:value}).then(res2 => {
                                    console.log(res2);
                                    if (res2.data[0].length) {
                                        res2.data[0].map(v=>{
                                            arr2.push(v)
                                        })
                                        console.log(arr2);
                                        let nArr3 =arr2.filter((currentValue, currentIndex, selfArr) => {
                                            return selfArr.findIndex(x => x.medQrcode === currentValue.medQrcode) === currentIndex
                                        });
                                        console.log(nArr3);
                                        this.setState({
                                            arrDetails: details.concat(nArr3),
                                        },()=>{
                                            this.allPharmacyIds()
                                        })
                                    }
                                })
                            }
                        })
                    }
                    if(details.length){
                        this.setState({
                            arrDetails: details
                        },()=>{
                            this.allPharmacyIds()
                        })
                    }else{
                        this.setState({
                            list: []
                        })
                    }
                    console.log(details)
                })

            } else {
                this.setState({
                    list: []
                })
            }
        }

    }

    render() {
        const { count, arrDetails, list } = this.state
        console.log(list,count,arrDetails)
        return (
            <div className='active2-container'>
                <div className='actpage2-header'>
                    <div className='actpage2-header-zhanwei'>
                        <i className='iconfont icon-xiangzuo' onClick={this.backPage}></i>
                        <span>满减计划</span>
                        <span className='actpage2-search'>
                            <input type="text" placeholder='请输入商家或者商品名称' onChange={(e) => this.getValue(e)} />
                            <i className='iconfont icon-suosou' onClick={() => this.onSearch()}></i>
                        </span>
                    </div>
                </div>
                <div className='actpage2-content'>
                    <img src={acttest1} alt='' />
                </div>
                <div className='actpage2-top'>
                    {list.length&&arrDetails.length ? list.map(item => (
                        <>{item.state1&&item.count?<div className='actpage2-body'>
                            <div className='actpage2-body-top' onClick={() => this.props.history.push(`/shop/${item.pharmacyId}`)}>
                                <p className='actpage2-body-img'>
                                    <img src={item.pharmacyPhoto} alt="" />
                                </p>
                                <p className='actpage2-body-right'>
                                    <h2>{item.pharmacyName}</h2>
                                    <p>月销{item.salesVolume ? item.salesVolume : 0}</p>
                                    <p className='actpage2-body-b'>
                                        <p>起送￥{item.minFreight ? (item.minFreight / 100).toFixed(2) : 0} </p>
                                        <p>{item.distance ? item.distance >= 1000 ? (item.distance / 1000).toFixed(1) + 'km' : item.distance + 'm' : '0m'}</p>
                                    </p>
                                </p>
                            </div>
                            <div className='actpage2-body-manjian'>
                                <img src={mj} alt="" />
                                <span className='actpage2-body-manjian-right'>
                                    {item.activitiesDiscount&&item.activitiesDiscount.map(v => (
                                        <span>满{v.full / 100}减{v.minus / 100}</span>
                                    ))}
                                </span>
                            </div>
                            <div className='actpage2-body-bottom'>
                                {arrDetails.length ? arrDetails.map(i => (
                                    <>
                                        {i.activeStoreId == item.pharmacyId ?
                                            <div className='actpage2-body-bottom-all' onClick={() => this.props.history.push(`/med/${i.medId}`)}>
                                                <p className='actpage2-body-bottom-img'>
                                                    <img src={i.medPhoto ? i.medPhoto : defaultImg} alt="" />
                                                </p>
                                                <p className='actpage2-body-bottom-name'>{i.medName}</p>
                                                <p className='actpage2-body-bottom-price'>
                                                    <span>{i.medPrice ? (i.medPrice / 100).toFixed(2) : 0}</span>
                                                </p>
                                            </div> : null}
                                    </>
                                )) : null}
                            </div>
                        </div>:null}</>
                    )) : <Nodata type='活动'/>}
                </div>
            </div>
        )
    }
}

export default withRouter(ActPage1)