import React,{Component} from 'react'
import SwipeableViews from 'react-swipeable-views'
import {autoPlay} from 'react-swipeable-views-utils'
import Pagination from './Pagination'

import './style4.less'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends Component{

    constructor(){
        super()
        this.state={index:0}
    }

    handleChangeIndex(index){
        this.setState({
            index:index
        })
    }

    activeClick = (link) => {
        window.location.href = link
    }

    render(){
        const banners = this.props.banners
        // const banners = ['关于药品质量规范公告相关信息','关于物流停发相关信息','关于平台药品经营规范相关公示']

        const link = this.props.link
        // console.log(banners);
        return(
            <div className='swiper4'>
                <AutoPlaySwipeableViews interval={6000} onChangeIndex={this.handleChangeIndex.bind(this)}>
                    {
                        banners.length?banners.map((v,index)=>{
                            return(
                                <div key={index} className='swiper4-view'
                                // onClick={()=>this.activeClick(link[index])}
                                >
                                    <span>{v}</span>
                                </div>
                            )
                        }):
                        <div className='swiper4-view'
                        // onClick={()=>this.activeClick(link[index])}
                        >
                        <span>暂无公告</span>
                        </div>
                    }
                </AutoPlaySwipeableViews>
            </div>
        )
    }
}

export default Swiper