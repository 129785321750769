import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/fanhuihei.png"

export default class index extends Component {
    constructor() {
        super()
        this.state = {

        }
    }
    componentDidMount = () => {

    }
    render() {
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top box-top1'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/login/Login1")} />   众康云药购用户服务协议条款
                </div>
                <h2 className='risk-title'>众康云药购用户服务协议条款</h2>
                <p className='risk-right'>协议版本：【1.0.0.0】</p>
                <p className='risk-right'>发布/生效日期：【2022年11月29日首次发布】</p>
                <p className='risk-body'>
                    <p>欢迎您使用众康云药购的各项服务！</p>
                    <p>
                        您在使用众康云药购服务前，应当仔细认真阅读本《众康云药购用户服务协议》(下称“本条款”)中的全部规则的内容（<span className='blod'>特别是其中以黑体字标示出的关于众康云药购及用户重大权益的规则，该规则可能涉及相关方的责任免除或限制、法律适用与争议解决条款，请您重点阅读</span>），确认对本协议及各项服务规则均已知晓、理解并接受，并已同意将其作为确定双方权利义务的依据。
                        <span className='blod1'>如您使用账号访问服务或以其他方式使用或接受众康云药购的任何服务，即视为您已阅读并与众康云药购达成协议，自愿接受本协议的所有内容的约束。所有服务规则视为本协议不可分割的一部分，与本协议具有同等法律效力。</span>本条款是您与众康云药购平台之间关于您使用众康云药购平台提供的各项服务所订立的服务条款，具有正式书面合同的效力。

                        <p><span className='blod1'>您理解并同意，众康云药购将根据《众康云药购用户服务协议》的约定，对本条款或各项服务规则不时地进行修改更新。修改更新内容的发布和实施均适用《众康云药购用户服务协议》的相关约定。</span></p>
                        <p><span className='blod'>一、定义</span></p>
                    <p>
                        1.1 众康云药购：指四川智云众康网络科技有限公司的网上零售平台
                    </p>
                    <p>
                        1.2商家：指入驻众康云药购并通过众康云药购向用户提供各类商品/服务订购的第三方商家。
                    </p>
                    <p>
                        1.3 客户端：指包含安卓及已有或未来新增的任何桌面平台或移动平台的众康云药购客户端的单称或合称。
                    </p>
                    <p>
                        1.4交易：指用户通过众康云药购进行的商品/服务的交易活动。
                    </p>
                    <p>
                        1.5信息：指商家通过众康云药购发布的、在众康云药购页面上展示的商品/服务信息，包括但不限于商品/服务的名称、种类、数量、质量、价格、有效期、预计送达时间、商户地址、配送方式、退换货方式、退款条件、售后服务等内容。
                    </p>
                    <p><span className='blod'>二、用户账号</span></p>
                    <p>
                        2.1您知悉并同意，您可通过注册新的众康云药购账号或登录您现有的众康云药购账号以使用众康云药购的各项服务及功能，并应遵守《众康云药购用户服务协议》中的各项规定。
                    </p>
                    <p><span className='blod'>三、平台服务</span></p>
                    <p>
                        3.1众康云药购将向您提供如下服务：
                    </p>
                    <p>
                        3.1.1四川智云众康网络科技有限公司维护众康云药购的正常运行，积极改进技术，提升您在平台交易时的服务体验。
                    </p>
                    <p>
                        3.1.2众康云药购对您就平台服务、交易安全等相关问题所反映的客观情况和建设性建议，进行积极研究并予以改进。
                    </p>
                    <p>
                        3.1.3众康云药购在您与商家或其他用户之间产生争议时，在法律要求的范围内协助进行协商调解，并在您或商家通过司法或执法机关要求众康云药购提供相关资料时，予以积极配合。
                    </p>
                    <p>
                        3.1.4其他在本条款及《众康云药购用户服务协议》项下约定的服务，或众康云药购所不时推出的新功能或新服务。
                    </p>
                    <p>
                        3.2 您可根据本条款、《众康云药购用户服务协议》及众康云药购不时公布的服务规则，使用您的账号查阅在平台上发布的信息、通过平台购药、问诊、发布商品/服务评价信息、众康云药购现有及将来可能提供的其他服务。同时，您有权对众康云药购平台上的商家服务做出评价和投诉，并提出建议和意见。
                    </p>
                    <p>
                        3.3您理解并同意，<span className='blod1'>众康云药购为您与商家进行交易提供网络交易平台服务，众康云药购属于独立的第三方平台，并非用户所购的商品或服务的交易相对方。您在进行交易的过程中所产生的相关税费以及有关硬件、软件、服务及其他方面的费用将由您或商家、配送服务提供方（如适用）或其他商品/服务提供方依据相关合同或法律规定予以承担，众康云药购不承担缴纳与您的交易有关的任何税费及代为开具发票等义务。</span>
                    </p>
                    <p>
                        3.4<span className='blod1'>众康云药购将根据《中华人民共和国电子商务法》等法律法规要求，对平台商家予以审慎审核（包括但不限于证照审核等），并记录、保存平台上发布的商品和服务信息、交易信息，以积极保障您的人身、财产安全，但鉴于平台存在海量信息及客观上信息与实物相分离的特点，众康云药购客观上无法逐一实质审查每一商品/服务的信息，除法律法规强制性规定外，众康云药购不对平台上的任何第三方所销售的产品或服务的真实性、适用性、合法性、安全性提供任何形式的明示或默示的担保、声明或承诺，亦不对因此所导致的您的任何损失（含第三方侵权行为给您造成的损害）承担任何责任，但众康云药购在其中存在故意或重大过失的除外。如您在使用交易服务的过程中发现平台中的相关产品或服务信息违反法律规定，您可及时向众康云药购或有关机关举报或投诉，众康云药购将在收到您的举报或投诉时采取相应的核实与处理措施。</span>
                    </p>
                    <p>
                        3.5<span className='blod1'>您知悉、理解并同意，众康云药购服务过程中，可能涉及收集、存储、使用、共享和保护用户个人信息。在您使用众康云药购提供的服务时，您同意众康云药购依据《众康云药购隐私政策》的规定执行相关个人信息的收集、使用和共享。您进一步同意，就众康云药购平台所产生的交易，您授权众康云药购使用或允许众康云药购审核许可的第三方在必要、合理的限度内使用您的个人信息，包括但不限于身份信息、账户信息、交易信息等。</span>
                    </p>
                    <p>
                        3.6 您理解并同意<span className='blod1'>：为向您提供更为细致、贴心的服务，众康云药购或众康云药购授权的商家将向您发送与众康云药购网站管理、服务规则更新、或您可能感兴趣的商品信息（包括但不限于商家促销、优惠券、产品更新等产品或服务相关信息）相关的电子邮件、站内信和/或手机短消息，其方式和范围可不经向您特别通知而变更。您有权选择是否退订众康云药购发送的关于商品信息的电子邮件、站内信和/或短信息。</span>
                    </p>
                    <p><span className='blod'>四、交易规则</span></p>
                    <p>
                        您通过众康云药购进行交易时，应当遵守本条款、《众康云药购服务协议》及各项服务规则。具体而言，您承诺在通过众康云药购进行交易的过程中遵守如下规则：
                    </p>
                    <p>4.1 浏览商品信息</p>
                    <p>
                        您在众康云药购上浏览商品/服务的信息时，应当仔细阅读信息中包含的全部内容，包括但不限于商品/服务的名称、种类、数量、质量、商品价格/服务、包装费、有效期、预计送达时间、商家地址、营业时间、配送方式等内容，其中您应特别注意商品/服务的有效期、优惠券和积分使用条件等内容，您应在认真阅读、理解并完全接受交易信息中所包含的全部内容后方可点击购买（或“下单”等类似功能按键，下同）。
                    </p>
                    <p>
                        4.2 提交及确认订单
                    </p>
                    <p>
                        4.2.1 您在点击购买后，将进入订单页面（具体页面内容及标题可能因网页或客户端版本不同，在不同时期而有所差异）。您应当仔细阅读订单页面中所包含的全部内容，包括但不限于上述信息中的全部内容、为再次提示您注意而标明的本单商品/服务的有效期、退款条件等内容（如有），选择及确认购买数量、价格、应付总额、用户接收电子消费凭证的联系方式或接收货物的收货地址和送货时间等内容。<span className='blod1'>前述订单页面中所包含的全部内容，构成了用户与商家之间达成的合同的合同内容，将视为商家的要约，您在完全同意订单的全部内容后方可提交订单</span>。您需配合提供最新、真实、完整、有效的信息，以使订单及时准确的完成。
                    </p>
                    <p>
                        4.2.2 特别提示您：<span className='blod1'>您在提交订单前应再次阅读并确认订单的全部内容后方可点击确认订单并付款，您提交订单即视为您已知晓、同意并接受订单中的全部内容，与商家、配送服务商（如适用）成立了交易合同。订单中所包含的全部内容即为合同的内容，包括但不限于商品/服务的名称、种类、数量、质量、价格、有效期、商家地址、营业时间、配送方式等，您与商家均应当按照前述合同的约定履行各自的权利义务。</span>
                    </p>
                    <p>4.3 支付价款</p>
                    <p>
                        在合同成立之后，您应根据付款页面的提示通过的网上支付众康云药购平台完成价款的支付。您在支付价款之前不得要求商家向您提供商品/服务，众康云药购在特定商品或服务页面中特别明示说明的除外。
                    </p>
                    <p>4.4 配送服务</p>
                    <p>
                        4.4.1您知悉、理解并同意：“预计送达时间”是系统根据商家距离、商品/服务准备时间、用户评价的时间等因素进行自动综合计算而得到的参考时间，商家的实际配送时间会受到当天的天气、订单量等多种外界因素影响而有所差异。您理解并同意，<span className='blod1'>预计送达时间不作为众康云药购或商家对您做出的配送到达时间的承诺。</span>
                    </p>
                    <p>
                        4.4.2您理解并同意，在合同成立且您已付款的情况下，商家或众康云药购平台将负责协调相应配送资源并向您提供配送服务，配送服务费用（如有）的具体金额以您已确认及提交的订单上所的显示金额为准，您知悉并授权众康云药购平台协调相应的配送资源（如适用）向商家取货并将商品送到您所指定的收货地址，并同意通过众康云药购平台支付相关的配送费用（如有）。
                    </p>
                    <p>4.5 退款规则</p>
                    <p>
                        为了使您在使用众康云药购平台网上购买服务中有更好的体验，众康云药购平台特制定退款规则，以供各方遵守。您成功支付价款后，如需要进行退款的，将按照如下规则进行：
                    </p>
                    <p>
                        4.5.1 取消订单原则：如果商家尚未接单，您可以在订单详情页通过“取消订单”功能进行取消；如果商家确认接单但没有配送中，则您需要向商家申请取消订单；如果订单已经处在配送中，则您需要自行电话联系商家协商，由商家给出商品到达后的处理方式。<span className='blod1'>您知悉、理解并同意：如商家因已售出等合理原因不同意您取消订单的，则您将不能取消该笔订单；如您坚持取消订单，则相关费用与损失将由您全部承担，相关损失将会视实际情况由商家在您支付的价款中予以部分或全额扣除。此外，如取消的订单中附有赠送商品、优惠券或附赠服务且该商品或服务已消费或无法退还商家，则赠送商品、优惠券或附赠服务的价值金额应首先从退款中予以扣除。您理解并同意，取消订单成功后，您与商家间的合同即宣告解除。</span>
                    </p>
                    <p>4.5.2 发生以下情形之一的，合同解除，您有权要求众康云药购代相应商家进行退款：</p>
                    <p>
                        (1)您通过众康云药购付款成功后，因不可抗力或不可归责于商家的原因，导致商家无法向您提供商品/服务，经众康云药购核实后属实的；
                    </p>
                    <p>
                        (2)您通过众康云药购付款成功后，确因客观情况变化、页面信息显示错误、系统故障、物料短缺等特殊原因导致商家需要合理变更合同内容，您不接受变更后的内容的。
                    </p>
                    <p>
                        4.5.3 您知悉、理解并同意：因药品相关特殊性，如您在商家已确认接单或已配送后，要求商家退款或要求众康云药购代商家进行退款的，商家及/或众康云药购有权拒绝，但下述情形除外：
                    </p>
                    <p>
                        (1)商品/服务与订单约定内容严重不符；
                    </p>
                    <p>
                        (2)在交付给您之前，外包装损坏严重导致商品在数量、质量上严重不符合订单约定；
                    </p>
                    <p>
                        (3)存在其他严重质量问题或违反《中华人民共和国消费者权益保护法》等法律规定的强制性、禁止性规定的情况。
                    </p>
                    <p>
                        4.5.4 符合众康云药购关于退款规定的，您可以向众康云药购申请退款。经众康云药购审核确认后，众康云药购将于7个工作日内将款项按照您的支付路径原路退回至您的支付账户。
                    </p>
                    <p>
                        4.5.5 其他情形下的退款（如适用）将按照合同内容约定的退款条件及退款方式进行。
                    </p>
                    <p>
                        4.5.6 在退款进行过程中，您应当遵守众康云药购关于退款的相关服务规则的规定。
                    </p>
                    <p>4.5.7 您确认，除法律法规另有强制性规定外，下列性质的商品不适用退货规定：</p>
                    <p>（一）拆封后易影响人身安全或者生命健康的商品，或者拆封后易导致商品品质发生改变的商品，例如药品、食品等。 </p>
                    <p>（二）一经激活或者试用后价值贬损较大的商品； </p>
                    <p>（三）销售时已明示的临近保质期的或有其他瑕疵的商品； </p>
                    <p>（四）法律、法规明确规定及众康云药购发布的其他规则规定不适用退货规定的其他商品。</p>
                    <p>4.6 用户评价</p>
                    <p>4.6.1您有权在众康云药购提供的评价系统中对通过众康云药购与您达成交易的商品/服务进行评价。您知晓并同意，您在众康云药购的评价信息是公开的，如您不愿意在评价信息中向公众披露本人的身份信息，您有权选择通过匿名形式发表评价。</p>
                    <p>4.6.2您的评价行为应遵守法律法规、《众康云药购用户评价规则》及众康云药购因管理需要所制定的相关规则。评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告、不正当竞争等法律法规与本条款及/或众康云药购其他规则所列明的其他禁止性信息。 </p>
                    <p>4.6.3您不得以“刷单”等不正当方式帮助商家提升信用，或利用评价权利对其他商家或用户进行恶意评价，或实施威胁、敲诈勒索或法律法规与本条款及/或众康云药购其他服务规则列明的其他禁止性行为，否则众康云药购可依据情况对您采取禁止/屏蔽评价资格、剥夺优惠资格、网站上公布违法行为、封禁注销账号等处罚措施，必要时将保留向您追究民事、刑事等法律责任的权利。</p>
                    <p>4.6.4 您违反法律法规或者本条款及/或众康云药购其他服务规则之禁止性规定，发布违法、侵权、违规评价信息的，应当自行承担法律责任。因此给众康云药购造成损失的，您应负责全部赔偿责任，包括且不限于财产损害赔偿、名誉损害赔偿、诉讼费、律师费、公证费、交通费等因维权而产生的合理费用。</p>
                    <p>4.7参与活动</p>
                    <p>4.7.1您应当按照众康云药购发布的相关服务规则参加活动，并遵守活动秩序，任何以刷单、外挂、作弊、扰乱系统、实施网络攻击、恶意套现、刷信誉、批量注册账号、用机器模拟客户端等手段骗取奖品或参加其他众康云药购平台所组织的活动的，众康云药购将保留取消奖励或参加活动资格、封禁注销账号、向主管机关举报并向您进一步追究民事、刑事等法律责任的权利。</p>
                    <p>4.7.2您在使用积分和优惠券时应遵守众康云药购发布的包括《积分使用规则》、《优惠券使用规则》在内的各项服务规则。</p>
                    <p>4.8用户不正当行为及责任</p>
                    <p>4.8.1 为维护正常用户的合法权益，您在遵守《众康云药购用户服务协议》的基础之上，进一步承诺不会独自和/或伙同他人（包括众康云药购平台的商家）实施虚假、欺诈、破坏、不诚信行为，包括但不限于：</p>
                    <p>(1)批量注册众康云药购账号；</p>
                    <p>(2)无正当理由批量购买后批量申请退款； </p>
                    <p>(3)虚假交易（如提交订单后并未产生真实交易，无论用户是否因此获利）；</p>
                    <p>(4)虚假分享； </p>
                    <p>(5)虚假或恶意评论； </p>
                    <p>(6)填写错误配送地址、留存错误的电话、拒绝接听骑手电话、无正当理由拒收商品在合理时间内送达订单载明收货地址后10分钟内无法联系到您或您无正当理由拒收商品等； </p>
                    <p>(7)发布炒作信用为目的的信息、与网上交易无关或不以交易为目的的信息；</p>
                    <p>(8)恶意干扰正常交易秩序，或其他违反诚实信用原则或损害众康云药购、用户、商家或其他第三方合法利益的行为。 </p>
                    <p>如发现您有上述行为，众康云药购有权终止订单，且不予退款。如您尚未付款，众康云药购有权向您追偿订单费用及其他损失，同时对于您的账户采取包括但不限于删除违法信息、禁止/屏蔽评价资格、剥夺优惠资格、网站上公布违法行为、封禁注销账号等处罚措施，必要时将保留向您追究民事、刑事等法律责任的权利。</p>
                    <p>4.8.2 您违反本条款、《众康云药购用户服务协议》或众康云药购不时发布的任何服务规则，包括但不限于虚假交易、虚假投诉、从事非法活动等给众康云药购、商家或其他第三方造成损失的，您应负全部赔偿责任，包括且不限于财产损害赔偿、名誉损害赔偿、诉讼费、律师费、公证费、交通费等因维权而产生的合理费用。</p>
                    <p><span className='blod'>五、知识产权</span></p>
                    <p>5.1众康云药购所展示的包含网上交易系统在内的各运营系统由四川智云众康网络科技有限公司自主开发、运营提供技术支持，并对众康云药购服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。众康云药购提供各项服务时所依托软件的著作权、专利权，所使用的各项商标、商业形象、商业标识、技术诀窍，其著作权、商标权及其他各项相关权利均归众康云药购所有。</p>
                    <p>5.2您理解并同意：<span className='blod1'>您在众康云药购平台发表的产品使用体验、产品讨论或图片等所有信息（以下简称“信息”）及其衍生品的知识产权及所有权，适用《众康云药购用户服务协议》中的相关约定。</span></p>
                    <p><span className='blod'>六、争议解决</span></p>
                    <p>6.1 <span className='blod1'>如您与商家因合同的履行发生任何争议，包括但不限于对商品/服务的数量、质量、价格、有效期、商家地址、配送方式、退换货方式、退款条件、售后服务等问题发生争议的，您应与商家根据合同内容的约定确定双方各自的权利义务、承担各自的责任，解决争议。众康云药购可在法律法规要求的范围内协助您与商家之间争议的协商调解。您同意，众康云药购有权通过电话或电子邮件等方式向争议双方了解情况，并将所了解的情况通过必要方式通知对方。此外，您有权通过司法或执法机关要求众康云药购提供相关资料，但您理解并同意，众康云药购无任何义务对您与商家之间的任何争议承担任何责任。</span></p>
                    <p>6.2 若您发现商家在提供商品或服务时损害了您的合法权益，您有权依据《中华人民共和国消费者权益保护法》及相关法律、法规的规定向该商家主张并维护自己的合法权益。</p>
                    <p>6.3 若您与众康云药购因本条款内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本条款签订地有管辖权的人民法院提起诉讼。</p>
                    <p>6.4 因您使用众康云药购服务而引起或与之相关的一切争议、权利主张或其它事项，均适用中华人民共和国大陆地区法律，并且排除一切冲突法规定的适用。</p>
                    <p><span className='blod'>七、环保倡议</span></p>
                    <p>众康云药购重视绿色环保，倡导绿色消费。提倡您保护环境，尽量减少不必要的使用。</p>
                    <p><span className='blod'>八、附则</span></p>
                    <p><span className='blod1'>在众康云药购未向您收取平台服务费的情况下，众康云药购可以任何理由自行决定终止众康云药购服务，解除与您之间订立的本条款，并终止您的众康云药购账号对众康云药购的使用。该等情况下，众康云药购</span>将提前三十日在网站或客户端首页显著位置持续公示有关信息。停止服务后，除法律法规另有明确规定外，众康云药购将没有义务为您保留账号中的任何信息，或留存、转发任何账号内的任何站内信或短消息。您同意众康云药购不就终止众康云药购服务而对您或任何第三方承担任何责任。</p>
                    <p>如您对本服务协议有任何问题或建议，请在工作时间联系众康云药购客服部门</p>
                    <p>再次感谢您的耐心阅读！</p>
                    </p></p>
            </div>
        )
    }
}