import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import {oderdetailRequest,applyPartRequest,upOrderStuRequest,applyRefundRequest,updatePartrefundState } from '../../../api/request/order'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import fanhui from '../../../static/img/fanhuihei.png'
import finish from '../../../static/img/finish.png'

import './style.less'
const RadioItem = Radio.RadioItem
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const reasons = [ '厂家召回', '出库单问题','没收到货','拍错/多拍/不想要','实物与商品描述不符（批号/包裹）','药品近效期','商品质量问题','发货少货','商品压损','商家发错货', '其它原因']
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [orderDetail, setOrderDetail] = useState(null)
    const [medPhoto, setmedPhoto] = useState('')
    const [medName, setmedName] = useState('')
    const [medPrice, setmedPrice] = useState()
    const [medQuantity, setmedQuantity] = useState()
    const [medQuantity1, setmedQuantity1] = useState()
    const [medCompany, setmedCompany] = useState('')
    const [visible, setVisiable] = useState('none')
    const [radioVal, setRadioVal] = useState('')
    const [reasonContent, setReasonContent] = useState()
    const [orderMedId, setorderMedId] = useState()
    const [miaosu, setMiaosu] = useState('')
    const [orderState, setorderState] = useState('')
    const [countQuantity, setcountQuantity] = useState()
    const [countQuantity1, setcountQuantity1] = useState()

    
    const cartArr=props.location.state
    let orderNumber;  //先定义一个存储参数的变量
    let medId;
    if(cartArr){  //判断是否有参数
        orderNumber=cartArr[0].pharmacy.orderNumber;//采购商
        medId=cartArr[0].pharmacy.medId;//联系方式
        localStorage.setItem('orderNumber0', orderNumber);
        localStorage.setItem('medId0', medId);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber0');
        medId = localStorage.getItem('medId0');
    }
    useEffect(() => {
        oderdetailRequest(orderNumber).then(res=>{
            console.log(res);
            // setOrderDetail(res.data[0][1])
            let a;
            let count=0
            let count1=0
            if (res.code==1) {
                setorderState(res.data[0][0].orderState)
                res.data[1].map(item=>{
                    count+=item.medQuantity
                    count1+=item.medRefundQuantity
                    if (item.medId==medId) {
                        a=item
                    }
                })
                setOrderDetail(a)
                setmedPhoto(a.medPhoto)
                setmedName(a.medName)
                setmedPrice(a.medPrice)
                setmedQuantity(a.medQuantity)
                setmedQuantity1(a.medQuantity)
                setmedCompany(a.medCompany)
                setorderMedId(a.orderMedId)
                setcountQuantity(count)
                setcountQuantity1(count1)
            }
        })
    }, [])
    const fanhui1=()=>{
        window.history.back()
    }
    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic1
        if (type === 'add') {
            let file = files[files.length - 1].file
            console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    console.log(res1);
                    console.log(files);
                    if (res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
    const jian=()=>{
        setmedQuantity(medQuantity-1)
        if (medQuantity<=1) {
            setmedQuantity(1)
        }
    }
    const jia=()=>{
        setmedQuantity(medQuantity+1)
        if (medQuantity>=medQuantity1) {
            setmedQuantity(medQuantity1)
        }
    }
    const smRreasons=()=>{
        setVisiable('block')
    }
    const dianji=(e)=>{

    }
    const sureReason=()=>{
        if (reasonContent&&reasonContent != '其它原因') {
            setVisiable('none')
        }else{
            Toast.info('请选择退款原因')
        }
    }
    const tijiao=()=>{
        let params={
            medRefundQuantity:medQuantity,
            medState:2,
            orderMedId:orderMedId,
            refundReason:reasonContent,
            refundRefuse:''
        }
        let params1={
            orderState:orderState,
            isPartrefund:1,
            refundOriginator:0,
            refundDestination:0,
            refundReason:reasonContent,
            refundRefuse:'',
            refundDetails:miaosu,
            orderNumber:orderNumber
        }
        let params3={
            orderState:'5',
            isPartrefund:0,
            refundOriginator:0,
            refundDestination:0,
            refundReason:reasonContent,
            refundRefuse:'',
            refundDetails:miaosu,
            orderNumber:orderNumber
        }
        let params2={
            orderState:'5',
            refundRefuse:'',
            refunDetails:'',
            orderNumber:orderNumber
        }
        let params4={
            partRefundState:1,
            orderNumber:orderNumber
        }
        
        if (reasonContent) {
            if (miaosu) {
                // 订单部分药品退款
                applyPartRequest(params).then(res=>{
                    console.log(res);
                    if (res.code==1) {
                        if (countQuantity==(countQuantity1+medQuantity)) {
                            // 申请退款
                            applyRefundRequest(params3).then(res1=>{
                                // 更新订单状态
                                upOrderStuRequest(params2).then(res2=>{
                                    console.log(res1);
                                    window.history.back()
                                }) 
                            })
                        }else{
                            console.log(params1);
                            applyRefundRequest(params1).then(res1=>{
                                console.log(res1);
                                updatePartrefundState(params4).then(res2=>{
                                    console.log(res2);
                                    window.history.back()
                                })
                            }) 
                        }
                    }
                })
            }else{
                Toast.info('具体原因未填写')
            }
        }else{
            Toast.info('请选择申请退款原因')
        }
    }
    const fuzhi=(v)=>{
        setMiaosu(v.target.value)
    }
    return (
        <div>
            <div className='refund-top'>
                <div className='pifa-refund'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>申请退款</p>   
                </div>
                <div className='refund-detail'>
                    <p className='refund-detail-outer'>
                        <img src={medPhoto} alt="" />
                        <p className='refund-detail-right'>
                            <h2>{medName}</h2>
                            <p className='company-name'>{medCompany}</p>
                            <p className='refund-detail-inner-right'>
                                <span className='refund-detail-l'>￥{medPrice/100}</span>
                                <span className='refund-detail-r'>x{medQuantity1}</span>
                            </p>
                        </p>
                    </p>
                </div>
            </div>
            <div className='refund-count'>
                <p className='refund-count-title'>
                    <span>申请数量</span>
                    <span className='refund-count-title-r'>（请选择您要申请售后的产品数量）</span>
                </p>
                <p className='refund-count-number'>
                    <span className='jian' onClick={()=>jian()}>-</span>
                    <span className='number'>{medQuantity}</span>
                    <span className='jia' onClick={()=>jia()}>+</span>
                </p>
            </div>
            <div className='selsect-reason'>
                <span>{reasonContent?reasonContent:'请选择申请原因'}</span>
                <span className='gt' onClick={()=>smRreasons()}>{'>'}</span>
            </div>
            <div className='reason-pic'>
                <p className='shuoming'>请给商家说明具体原因</p>
                <div className='upvoucher-in' onClick={(e) => dianji(e)}>
                    <textarea className='miaoshu' placeholder='请描述具体退款要求' maxlength="100" value={miaosu} name='textarea' cols="54" rows="11" onChange={(v)=>fuzhi(v)}></textarea>
                    <span style={{position:'absolute',left:'320px',marginTop:'130px'}}>
                        <span>{miaosu.length}</span>
                        <span>/</span>
                        <span>100</span>
                    </span>

                    {/* <ImagePicker
                        files={files1}
                        length={4}
                        onChange={imgChange1}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files1.length < 4}
                        accept='image/gif,image/jpeg,image/jpg,image/png'
                    /> */}
                </div>
            </div>
            <div className='tijiao' onClick={()=>tijiao()}>
                <img src={finish} alt="" />
                <span>提交申请</span>
            </div>
            {visible=='block'?
                <div className='select-all-reason'>
                    <List className='popup-list'>
                        <div className='select'>请选择申请退款原因</div>
                        {reasons.map((item, index) => (
                            <RadioItem key={index}
                                checked={radioVal === item}
                                onChange={() => {
                                    setRadioVal(item)
                                    if (reasonContent === '其它原因') {
                                        setReasonContent('')
                                    } else {
                                        setReasonContent(item)
                                    }
                                }}>
                                {item}
                            </RadioItem>
                        ))}
                        {radioVal === '其它原因' ?
                            <TextareaItem
                                placeholder='请输入退款原因'
                                rows={5}
                                count={100}
                                onChange={(val) => setReasonContent(val)} /> : null
                        }
                    <div className='sure' onClick={()=>sureReason()}>确定</div>
                    </List>
                </div>
            :null}
        </div>
    )
}