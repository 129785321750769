import { axiosInstance } from '../config'

//是否已有问诊信息
export const whethernews = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/is-chatinfo', data)
}

//添加问诊信息
export const addchat = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-chatinfo', data)
}

//获取问诊信息
export const getchat = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-chatinfo', data)
}

//添加聊天内容
export const addchatnote = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-chatnote', data)
}
//获取问诊管理列表
export const getlist = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/list', data)
}

