import React, { Component } from 'react'

export default class index extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    componentDidMount() {
        //console.log(window.location.href)
        var jdPrintWs;
        try {
            var host ="ws://localhost:9113";
            jdPrintWs = new WebSocket(host);
            jdPrintWs.onopen = function () {
                send();
                //todo 打开链接
            }
            jdPrintWs.onmessage = function (msg) {
                // todo 返回信息
                console.log(msg)
            }
            jdPrintWs.onclose = function () {
                disconnect()
                // todo 关闭事件
            }
        } catch (exception) {
            //todo 异常处理
            console.log("出错了")
        }
        //发送数据方法
        function send() {
            //发送信息
            let data = {
                orderType: "PRINT",
                key: "",
                //dataType:"app",
                parameters: {
                    printName: "Microsoft Print to PDF",
                    offsetLeft: "10mm",
                    tempUrl: "https://template-content.jd.com/template-content?type=standards&id=100001",
                    //printData:[{pp:"kkkk"}],
                    printData:["9XR7FEUvv/J9BWiMc02qM8IBW3f+j592F4nskdvvGT2Ip4kIn4gwSGxFz3RG22CYR27DyRdgmCCWbeXGTcVDn/DuoFeRmL+KhH15WHMZf9v0aumY8AiYU9iziwiAKHEuzthoL6Tnrr8HiZKyayUXnL16eKyVplyz57mWhdjrDWp0UAgNxJGZ4lCugfCqfgUqD8TtuHDYRxpu+4G7KN5BlXktQwkO9w0CeAnY2tPtMGFva7c/rUpODtMRb0Epfn59rCsJOcNLmsFauXjnHQp8Ow7aqOoP1UWZ/KKUbPFgUSSdYC5o9bYtGIbOd6LBpHDrp+sSgrjU84uxGD9n+DO2NsqHzDDaVANcYpbnxBeJcbSAaoxI5wIqTVchoCz0fh+h1RiBL1cATGPZlloXEBAB/fpuDahnNVP2U+6aZy5tNjln2zvuVGPguHP0HFsyDdlbynpZIIVziKpTUPRXfckvMaP+F0BLw1uIfQP0K7QlSoiIh3ZJZ1SL9mEb2kb+R6lRkMuxtM+NVtvKiRnsKJVYvkwpNShI2F1KVZo+Oi5XVgnTGYCo8hkSlyauNG1eZPMs1ypxk3LFT4nMbRSwo7zPv3g/BAJJW38l+qe7TVdW5rKZUrW7hTtWYuWoGM4cwKhFVHMdXRuvj27gXUSmRgmeGGZLwmfjt2sNHQUv8yVcMtA1hZYcqegKcpJy8QWHIcc2oLGa4wNwHIhxh36ZgsvYPEABEsXZSjCBRTgpiof6+kMqL2GL4ejA+3h6qcc/Sl1oqta7foe/N8cgDY55yS0jq1tCZice4vZ+GyFL1fcNI73gomtswus5eSWsr1LYYLCRMSnLCJHj6MjTLPXMFB2eHrlnv8AJ1tHBgZG+biSZw92PEqXewGk2+N3+ZGkTYEqsptAdQVk8vgokWYKaNlV62fk33FOtsYOqvA4zZ3TSILxPwmq3LAWTgXqrjAsFVdqn4FMyPwjGyJHxrmWFI+ZUQLfmk7inJRnAwGMkXok6w5t3RS52bYNGV3vtaKdrQ2HEeu2o+ihQbU9I8aDRFTNy1D3EF+Iig1CLI2wC3s6BMQ9Ecqq08L6vPX4q6bMimmZZFWo5DSYgAOpmlOJY2y+usskshhStnEDpY8l8xIY+2uJjYhDlMSf8a6n4jBU2paQIUsFUZ/Ov9+98puaxPlSNo6e8bYkNwN/SZ85g4ET5FsFzcsE15ipFguHG7EMhgj5KJlU3MycsKgEfKLZxJFnpBdOevfucRwwvW0V0FcKRqPCVIbSADmyK9QVnQp/HYnXXWSbA2sa/LE+2jSjY5kND0SkJiy8h/QfXHRCwamMaGnGQGO8eGfCgh1ehhoFnT1NAJJNpdh21CAeV5CcqaV6abhiSP1TS0SN2oSeCX0fj1kXba/bgtS7bvF6nSsT8G7FJ6fRo/SsYOvtXq77IvLL27eKEuuq92y2+XL7ulyoYqPscd5qKxiXUHOflgl35994PU8H/6i+LTpiCljPcbhTuirLr6xqCYme1xGf/HoizisGU9lwOfYoAbZ/3tgwecFLjxzcZp/DRZ076ivf7TinZmVcC+PfOIB5d7IT52CGTn+E="]
                }
            }
            data=JSON.stringify(data)
            jdPrintWs.send(data)
        }
        //断开链接方法
        function disconnect() {
            jdPrintWs.close();
        }
    }
    render() {
        return (
            <div>
                京东获取token测试
            </div>
        )
    }
}
