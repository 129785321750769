import React, { Component } from 'react'
import "./style.less"
import FH from "../../../static/img/mine/fanhui.png"
import add from "../../../static/img/mine/add.png"
import succ from "../../../static/img/mine/success.png"
import {getUserSH} from "../../../api/request/user"
export default class index extends Component {
    constructor(){
        super()
        this.state={
            userId: window.localStorage.getItem("userId2"),
            userarr:{},
            user:[]
        }
    }
    componentWillMount(){
        console.log(btoa("jsdakalfn"))
    }
    componentDidMount=async()=>{
        const res=await getUserSH({
            userId:this.state.userId
        })
        console.log(res)
        let data=res.data[0][0]
        let user=[]
        const obj1={
            pic:data.license_pic,
            name:"营业执照",
            state:data.license_state,
            pic2:data.temp_license_pic,
            state2:data.temp_license_state,
            isLongterm:data.is_longterm,
            number:data.license_number,
            endtime:data.license_end_time
        }
        const obj2={
            pic:data.business_pic,
            name:"药品经营许可证",
            state:data.business_state,
            pic2:data.temp_business_pic,
            state2:data.temp_business_state,
            number:data.business_number,
            endtime:data.bus_end_time
        }
        const obj3={
            pic:data.circulation_pic,
            name:"食品经营许可证",
            state:data.circulation_state,
            pic2:data.temp_circulation_pic,
            state2:data.temp_circulation_state,
            number:data.circulation_number,
            endtime:data.cir_end_time
        }
        const obj4={
            pic:data.medical_device_pic,
            name:"医疗器械经营许可证",
            state:data.medical_state,
            pic2:data.temp_medical_device_pic,
            state2:data.temp_medical_state,
            number:data.medical_device_number,
            endtime:data.medical_end_time
        }
        const obj5={
            pic:data.network_medical_pic,
            name:"医疗器械网络销售",
            state:data.network_state,
            pic2:data.temp_network_medical_pic,
            state2:data.temp_network_state,
            number:data.network_medical_number,
            endtime:data.network_end_time
        }
        const obj6={
            pic:data.medicalII_device_pic,
            name:"第二类医疗器械经营许可证",
            state:data.medicalII_state,
            pic2:data.temp_medicalII_device_pic,
            state2:data.temp_medicalII_state,
            number:data.medicalII_device_number,
            endtime:data.medicalII_end_time
        }
        const obj7={
            pic:data.entrust_pic,
            name:"法人授权委托书",
            state:data.entrust_state,
            pic2:data.temp_entrust_pic,
            state2:data.temp_entrust_state,
            endtime:data.entrust_end_time
        }
        const obj8={
            pic1:data.card_front,
            pic2:data.card_reverse,
            pic3:data.temp_card_front,
            pic4:data.temp_card_reverse,
            name:"身份证",
            state:data.card_state,
            state2:data.temp_card_state,
            endtime:data.card_end_time,
            number:data.card_number
        }
        user=[obj1,obj2,obj3,obj4,obj5,obj6,obj7,obj8]
        console.log(user)
        this.setState({
            userarr:res.data[0][0],
            user
        })
    }
    goon=(item,index)=>{
        this.props.history.push({
            pathname:`/mine/storeSH/storeSHin`,
            params:{
                item,
                index
            }
        })
    }
    render() {
        const {userarr,user} =this.state
        return (
            <div>
                <div className="ZHtop">
                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>门店资质</h2>
                </div>
                {user.map((item,index)=>{
                    return  <div className="ZHtop-text" key={index} onClick={()=>this.goon(item,index)}>
                    <img src={item.state==1?succ:add} /> 
                    <span className="ZHtop-text-one">{item.name=="营业执照"||item.name=="法人授权委托书"||item.name=="身份证"||item.name=="药品经营许可证"?<span style={{ color: "#FF5555" }}>*</span>:null}{item.name}</span>
                    {item.state2?item.state2==1?<span className="tishi2">已上传</span>:item.state2==-1?<span className="tishi3">待审核</span>:<span className="tishi">未上传</span>:item.state==1?<span className="tishi2">已上传</span>:item.state==-1?<span className="tishi3">待审核</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                })}
                {/* <div className="ZHtop-text">
                    <img src={add} /> 
                    <span className="ZHtop-text-one"><span style={{ color: "#FF5555" }}>*</span>营业执照</span>
                    {userarr.business_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one"><span style={{ color: "#FF5555" }}>*</span>药品经营许可证</span>
                    {userarr.quality_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one">食品经营许可证</span>
                    {userarr.circulation_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one">医疗器械经营许可证</span>
                    {userarr.medical_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one">医疗器械网络销售</span>
                    {userarr.network_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one">第二类医疗器械经营许可证</span>
                    {userarr.medicalII_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one">法人授权委托书</span>
                    {userarr.entrust_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div>
                <div className="ZHtop-text">
                    <img src={add} />
                    <span className="ZHtop-text-one"><span style={{ color: "#FF5555" }}>*</span>身份证</span>
                    {userarr.card_state==1?<span className="tishi2">已上传</span>:<span className="tishi">未上传</span>}
                    <span> ＞</span>
                </div> */}
            </div>
        )
    }
}
