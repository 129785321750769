import React, { useState, useEffect } from 'react'

import Head from '../../component/Head'
import Nodata from '../../component/Nodata'
import xt from '../../static/img/xt.png'
import yh from '../../static/img/yh.png'
import dd from '../../static/img/dd.png'

import { allnewsRead, updateMsgRequest,oneMessage } from '../../api/request/msg'
import getUrl from '../../component/GetUrlParam'
import './style.less'

export default function Tidings(props) {
    const userId = localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3
    const [count, setCount] = useState(0)
    const [xtCount, setxtCount] = useState(0)
    const [yhCount, setyhCount] = useState(0)
    const [ddCount, setddCount] = useState(0)

    const [xtNews, setxtNews] = useState('')
    const [yhNews, setyhNews] = useState('')
    const [ddNews, setddNews] = useState('')
    const [time1, setTime1] = useState('')
    const [timext, setTimext] = useState('')
    const [timeyh, setTimeyh] = useState('')
    const [timedd, setTimedd] = useState('')

    const mine = getUrl('mine', props.location.search)
    const [tidings, setTidings] = useState([])
    const [refresh,setRefresh] = useState(false)

    useEffect(() => {
        oneMessage(userId).then(res => {
            console.log(res);
            if (res.code==1) {
                if (res.data[0].length) {
                    setxtCount(res.data[0][0].allNews)
                }
                if (res.data[1].length) {
                    setyhCount(res.data[1][0].allNews1)
                }
                if (res.data[2].length) {
                    setddCount(res.data[2][0].allNews2)
                }
                if (res.data[3].length) {
                    setxtNews(res.data[3][0])
                    let a=res.data[3][0].messageTime
                    let a1 = new Date(a.replace(/-/g, "/"));
                    let a2=a1.getTime()
                    setTimext(a2)
                }
                if (res.data[4].length) {
                    setyhNews(res.data[4][0])
                    let a=res.data[4][0].messageTime
                    let a1 = new Date(a.replace(/-/g, "/"));
                    let a2=a1.getTime()
                    setTimeyh(a2)
                }
                if (res.data[5].length) {
                    setddNews(res.data[5][0])
                    let a=res.data[5][0].messageTime
                    let a1 = new Date(a.replace(/-/g, "/"));
                    let a2=a1.getTime()
                    setTimedd(a2)
                }
            }
        })
        let date=new Date()
        var year = date.getFullYear() //年
        var month = (date.getMonth() + 1);//月
        var day = (date.getDate());//日
    
        //当前时间 
        var date1 = year + "-" + month + "-" + day
        var time1 = new Date(date1.replace(/-/g, "/"));
        var times1 = time1.getTime()
        setTime1(times1)
        
    }, [userId,refresh])

    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])

    const clearReaded = () => {
        allnewsRead({userId,type3:1}).then(res => {
            if(res.code === 1) {
                setRefresh(true)
            }
        })
    }

    const lookNews = (val) => {
        const params = {
            newsId: [val.newsId]
        }
        updateMsgRequest(params).then(res => {
            if(res.code === 1) {
                window.location.href = val.newsUrl
            }
        })
    }
    const tosystemPrompt=()=>{
        allnewsRead({userId,type1:1}).then(res => {
            if(res.code === 1) {
                props.history.replace(`/tidings?mine=${mine}`)
                props.history.push(`/systemPrompt?mine=${mine}`)
            }
        })
    }
    const topreferentialPromotion=()=>{
        allnewsRead({userId,type2:1}).then(res => {
            if(res.code === 1) {
                props.history.replace(`/tidings?mine=${mine}`)
                props.history.push(`/preferentialPromotion?mine=${mine}`)
            }
        })
    }
    const toorderDelivery=()=>{
        allnewsRead({userId,type4:1}).then(res => {
            if(res.code === 1) {
                props.history.replace(`/tidings?mine=${mine}`)
                props.history.push(`/orderDelivery?mine=${mine}`)
            }
        })
    }
    return(
        <>
            <Head title={`消息${(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))==0?'':'('+(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))+')'}`}
            iconname='icon-shuazi'
            mine={mine}
            clickmethod={()=>clearReaded()}/>
            <div className='tidings-screen-page' style={{background:'#FFFFFF'}}>
                {/* {tidings.length>0? */}
                  <div className='tidings-outer'  onClick={() => tosystemPrompt()}>
                        <p className='tidings-outer-img'>
                            <img src={xt} alt="" />
                            {xtCount>0?<span></span>:null}
                        </p>
                        <p className='tidings-outer-right'>
                            <p className='tidings-outer-right-top'>
                                <span>系统通知</span>
                                {xtNews?<span>{parseInt((time1-timext) / (1000 * 60 * 60 * 24))==0?xtNews.messageTime0:
                                parseInt((time1-timext) / (1000 * 60 * 60 * 24))==1?'昨天':
                                parseInt((time1-timext) / (1000 * 60 * 60 * 24))==2?'前天':
                                xtNews.messageTime
                                }</span>:<span></span>}
                            </p>
                            {xtNews?<p>{xtNews.messageContent}</p>:
                            <p>暂无通知</p>}
                        </p>
                  </div>
                  <div className='tidings-outer' onClick={() => topreferentialPromotion()}>
                        <p className='tidings-outer-img'>
                            <img src={yh} alt="" />
                            {yhCount>0?<span></span>:null}
                        </p>
                        <p className='tidings-outer-right'>
                            <p className='tidings-outer-right-top'>
                                <span>优惠促销</span>
                                {yhNews?<span>{parseInt((time1-timeyh) / (1000 * 60 * 60 * 24))==0?yhNews.messageTime0:
                                parseInt((time1-timeyh) / (1000 * 60 * 60 * 24))==1?'昨天':
                                parseInt((time1-timeyh) / (1000 * 60 * 60 * 24))==2?'前天':
                                yhNews.messageTime
                                }</span>:<span></span>}
                            </p>
                            {yhNews?<p>{yhNews.messageContent}</p>:
                            <p>暂无优惠促销活动</p>}
                        </p>
                  </div>
                  <div className='tidings-outer' onClick={() => toorderDelivery()}>
                        <p className='tidings-outer-img'>
                            <img src={dd} alt="" />
                            {ddCount>0?<span></span>:null}
                        </p>
                        <p className='tidings-outer-right'>
                            <p className='tidings-outer-right-top'>
                                <span>订单配送</span>
                                {ddNews?<span>{parseInt((time1-timedd) / (1000 * 60 * 60 * 24))==0?ddNews.messageTime0:
                                parseInt((time1-timedd) / (1000 * 60 * 60 * 24))==1?'昨天':
                                parseInt((time1-timedd) / (1000 * 60 * 60 * 24))==2?'前天':
                                ddNews.messageTime
                                }</span>:<span></span>}
                            </p>
                            {ddNews?<p>{ddNews.messageContent}</p>:
                            <p>暂无订单信息</p>}
                        </p>
                  </div>
                {/* // : <Nodata type='消息'/> */}
                {/* } */}
            </div>
        </>
    )
}