import React, { useRef, useState, useEffect } from "react";
import { Toast } from "antd-mobile";

import Head from "../../component/Head";
import Select from "../../component/Select/index";
import Shopmedlist from "../medicine/meditem";

import {
  symptomRequest,
  medsearchRequest,
  getonemedAll,
} from "../../api/request/med";
import Nodata from "../../component/Nodata";

import getUrl from "../../component/GetUrlParam";

import "./medlist.less";

export default function Medlist(props) {
  const medListRef = useRef(null);
  const urlHash = window.location.hash;
  const str =
    urlHash.indexOf("?") !== -1 ? urlHash.substr(urlHash.indexOf("?")) : null;
  const shopId = str ? getUrl("shopId", str) : null;
  const env = localStorage.env;
  const longitude = localStorage.longitude
    ? localStorage.longitude
    : 104.06576236480714;
  const latitude = localStorage.latitude
    ? localStorage.latitude
    : 30.65745887548027;
  // const pharmacyId = shopId || localStorage.pharmacyId
  const pharmacyId = shopId || null;
  const pharmacyId2 = localStorage.pharmacyId;
  // const [tabs, setTabs] = useState([])
  const [symptomId, setSymptomId] = useState(1);
  const [categoryId] = useState(props.match.params.categoryId);

  const [classifyId] = useState(props.match.params.classifyId);
  const [content] = useState(
    props.match.params.content
      ? props.match.params.content
      : localStorage.getItem("categoryName")
  );
  const [medlist, setMedlist] = useState([]);
  const [page] = useState(1);
  const [size] = useState(20);
  const [refresh, setRefresh] = useState(false);
  const [recommend, setRecommend] = useState(0);
  const [sales, setSales] = useState(0);
  const scrollTop1 = getUrl("scrollTop", props.location.search);
  const page1 = getUrl("page", props.location.search);
  const title1 = getUrl("title", props.location.search);
  const home = getUrl("home", props.location.search);
  const categoryId0 = getUrl("categoryId0", props.location.search);

  const [distance, setDistance] = useState(0);
  const [price, setPrice] = useState(0);
  const [medNum, setmedNum] = useState(0);
  useEffect(() => {
    getMedList(symptomId);
  }, [symptomId]);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    getallnum();
    console.log(classifyId);
    localStorage.setItem("categoryName", content);
  }, []);
  const getallnum = async () => {
    if (classifyId != 0) {
      const res = await getonemedAll({
        categoryId: categoryId,
        classifyId: classifyId,
      });
      setmedNum(res.data[0][0].num);
    } else {
      const res = await getonemedAll({
        categoryId: categoryId,
      });
      setmedNum(res.data[0][0].num);
    }
  };

  const medFilter = (type, filter) => {
    switch (type) {
      case "1":
        setRecommend(filter);
        setSales(0);
        setDistance(0);
        setPrice(0);
        break;
      case "2":
        setRecommend(0);
        setSales(filter);
        setDistance(0);
        setPrice(0);
        break;
      case "3":
        setRecommend(0);
        setSales(0);
        setDistance(filter);
        setPrice(0);
        break;
      case "4":
        setRecommend(0);
        setSales(0);
        setDistance(0);
        setPrice(filter);
        break;

      default:
        break;
    }
    getMedList(symptomId, type, filter);
  };
  console.log(content);
  const getMedList = (symptomId, type = 0, filter = 0) => {
    Toast.loading("请稍后……", 0);
    const params = {
      recommend: type === "1" ? filter : 0,
      sales: type === "2" ? filter : 0,
      distance: type === "3" ? filter : 0,
      price: type === "4" ? filter : 0,
      pharmacyId: pharmacyId,
      pharmacyId2: pharmacyId2,
      symptomId: symptomId,
      longitude: longitude,
      latitude: latitude,
      content: content,
      page: page,
      size: size,
    };
    console.log(params);
    if (pharmacyId > 0) {
      params.pharmacyCategoryId = categoryId;
      params.pharmacyClassifyId = classifyId === "0" ? null : classifyId;
    } else {
      params.categoryId = categoryId === "1" ? null : categoryId;
      params.classifyId = classifyId === "0" ? null : classifyId;
    }
    // const res = await Promise.all([
    //     symptomRequest(),
    //     medsearchRequest(params)
    // ])
    medsearchRequest(params)
      .then((res) => {
        console.log(params);
        console.log(res);
        if (res.code === 1) {
          let data = res.data;
          if (data[0].length > 0) {
            console.log(data[0]);
            if (env && env === "single") {
              document.title = data[0][0].pharmacyName;
            }
            data[0].forEach((item) => {
              if (data[1].length > 0) {
                let index = data[1].findIndex((v) => v.medId === item.medId);
                if (index !== -1) {
                  item["evaluates"] = data[1][index].evaluates;
                  item["rate"] = data[1][index].rate * 100 + "%";
                } else {
                  item["evaluates"] = 0;
                  item["rate"] = "100%";
                }
              } else {
                item["evaluates"] = 0;
                item["rate"] = "100%";
              }
            });
            // setMedlist(data[0])
            let time = new Date();
            let time1 = time.getHours() * 60 + time.getMinutes();
            let arr = [];
            data[0].map((item) => {
              let startTime =
                parseInt(item.pharmacyStartTime.substring(0, 2) * 60) +
                parseInt(item.pharmacyStartTime.substring(3, 5));
              let endTime =
                parseInt(item.pharmacyEndTime.substring(0, 2) * 60) +
                parseInt(item.pharmacyEndTime.substring(3, 5));
              if (
                startTime < time1 &&
                time1 < endTime &&
                item.promoteStu == "0"
              ) {
                arr.push(item);
              }
            });
            console.log(arr);
            setMedlist(arr);
          } else {
            setMedlist([]);
          }
        }
        // if(params.recommend !== 0 || params.sales !== 0 || params.distance !== 0 || params.price !== 0) {
        medListRef.current.onRefresh();
        // }
        Toast.hide();
      })
      .catch((err) => {
        Toast.hide();
      });
    // setRefresh(true)
    // setTabs(res[0].data[0])
  };

  // const tabsChange = (tab, index) => {
  //     setSymptomId(tab.symptomId)
  //     const params = {
  //         pharmacyId: pharmacyId,
  //         symptomId: tab.symptomId,
  //         longitude: longitude,
  //         latitude: latitude,
  //         content: content,
  //         page: page,
  //         size: size
  //     }
  //     if(pharmacyId > 0) {
  //         params.pharmacyCategoryId = categoryId
  //         params.pharmacyClassifyId = classifyId==='0' ? null : classifyId
  //     } else {
  //         params.categoryId = categoryId==='1' ? null : categoryId
  //         params.classifyId = classifyId==='0' ? null : classifyId
  //     }
  //     medsearchRequest(params).then(res => {
  //         if(res.code === 1) {
  //             let data = res.data
  //             if(data[0].length > 0) {
  //                 data[0].forEach(item => {
  //                     if(data[1].length > 0) {
  //                         data[1].forEach(item1 => {
  //                             if(item.medId === item1.medId) {
  //                                 item.evaluates = item1.evaluates
  //                                 item.rate = (item1.rate * 100) + '%'
  //                             } else {
  //                                 item.evaluates = 0
  //                                 item.rate = '100%'
  //                             }
  //                         })
  //                     } else {
  //                         item.evaluates = 0
  //                         item.rate = '100%'
  //                     }
  //                 })
  //                 setMedlist(data[0])
  //             } else {
  //                 setMedlist([])
  //             }
  //         }
  //     })
  // }

  // const renderContent = (tab,index) =>{
  //     return(
  //         <div className='medlist-tabs'>
  //             <div className='ill-info flexc'>
  //                 <p>{tab.symptomName}</p>
  //                 <span>疾病介绍：{tab.symptomIntroduce}</span>
  //                 <span>症状描述：{tab.symptomDescribe}</span>
  //             </div>
  //             <Select></Select>
  //             <div style={{width:'100%'}}>
  //             {medlist.length === 0 ? null :
  //                 <Shopmedlist medlist={medlist}
  //                 symptomId={symptomId}
  //                 categoryId={categoryId}
  //                 classifyId={classifyId}
  //                 content={content}/>
  //             }
  //             </div>
  //         </div>
  //     )
  // }
  const goBack = () => {
    props.history.push(`/search`);
  };
  console.log(medlist);
  return (
    <div className="medlist-container">
      <Head
        title={content}
        medNum={medNum}
        clickmethod={() => goBack()}
        shopId={pharmacyId}
        home={home}
        categoryId0={categoryId0}
        iconname="icon-weimingmingwenjianjia_sousuo"
      ></Head>
      {/* <Tabs tabs={tabs}
            className='medlist-tabs'
            onChange={tabsChange}
            tabBarActiveTextColor='#0B1523'
            tabBarInactiveTextColor='#353A42'
            tabBarUnderlineStyle={{border: '2px #1F87FF solid',transform:'scaleX(0.4)',bottom:5}}
            renderTab={tab => <span>{tab.symptomName}</span>}> */}
      {/* {renderContent} */}
      {/* </Tabs> */}
      <div className="medlist-tabs">
        {/* <div className='ill-info flexc'>
                    <p>{tab.symptomName}</p>
                    <span>疾病介绍：{tab.symptomIntroduce}</span>
                    <span>症状描述：{tab.symptomDescribe}</span>
                </div> */}
        <Select onFilter={medFilter} />
        <div style={{ width: "100%" }}>
          {medlist.length === 0 ? (
            <Nodata type="店铺" />
          ) : (
            <Shopmedlist
              ref={medListRef}
              history={props.history}
              medlist={medlist}
              recommend={recommend}
              scrollTop1={scrollTop1}
              page1={page1}
              sales={sales}
              distance={distance}
              price={price}
              title1={title1}
              home={home}
              categoryId0={categoryId0}
              pharmacyId={pharmacyId}
              symptomId={symptomId}
              categoryId={categoryId}
              classifyId={classifyId}
              content={content}
            />
          )}
        </div>
      </div>
    </div>
  );
}
