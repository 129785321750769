import React, { useState, useEffect } from "react";
import { Checkbox, Toast } from "antd-mobile";
import { Popup, Space } from "antd-mobile-v5";
import moment from "moment";

import Head from "../../../component/Head";
import Button from "../../../baseui/fuctionbutton/index";
import NoData from "../../../component/Nodata";
import { meddetailRequest } from "../../../api/request/med";
import {
  addcartRequest,
  querycartRequest,
  addcartidentical,
  cartlistRequest,
} from "../../../api/request/cart";
import {
  browseListRequest,
  browseDelRequest,
} from "../../../api/request/history";

import defaultImg from "../../../static/img/default.png";
import zhezaho from "../../../static/img/rx.jpg";
import "./style.less";
import closeimg from "../../../static/img/home/close.png";

const CheckboxItem = Checkbox.CheckboxItem;

export default function Viewrecord(props) {
  const pharmacyId = localStorage.pharmacyId;
  const userId =
    localStorage.type == "1"
      ? localStorage.userId1
      : localStorage.type == "2"
      ? localStorage.userId2
      : localStorage.userId3;
  const [dateList, setDateList] = useState([]);
  const [browseList, setBrowseList] = useState([]);
  const [browsingId, setBrowsingId] = useState([]);
  // 切换管理和完成状态
  const [edit, setEdit] = useState(false);
  // 所有浏览商品的选中
  const [allChecked, setAllChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [load, setLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [size] = useState(20);

  const [meditem, setMeditem] = useState(null);
  const [mednum, setMednum] = useState(null);
  const [num, setNum] = useState(1);
  const [cartlist, setcartlist] = useState(null);
  const [visible1, setvisible1] = useState(false);
  useEffect(() => {
    getBrowseList();
    getcart();
    // switch (localStorage.type) {
    //     case "1":
    //         setuserId(localStorage.userId1)
    //         setTimeout(() => {
    //             getBrowseList()
    //         }, 1000);
    //         break
    //     case "2":
    //         setuserId(localStorage.userId2)
    //         setTimeout(() => {
    //             getBrowseList()
    //         }, 1000);
    //         break
    //     case "3":
    //         setuserId(localStorage.userId3)
    //         setTimeout(() => {
    //             getBrowseList()
    //         }, 1000);
    //         break
    // }
  }, []);

  //获取用户购物车数据
  const getcart = async () => {
    const res = await cartlistRequest(userId, pharmacyId);
    //console.log(res);
    setcartlist(res.data[0]);
  };

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  // 浏览日期处理
  const resetBrowseList = (data, type) => {
    //获取日期
    const year = moment().year() + "";
    const month =
      moment().month() + 1 < 10
        ? "0" + (moment().month() + 1)
        : "" + (moment().month() + 1);
    const date =
      moment().date() < 10 ? "0" + moment().date() : "" + moment().date();
    const browseDate = year + month + date;
    let newData = data;
    let newDateList = [];
    console.log(newData);
    newData.forEach((item) => {
      item.checked = false;
      if (item.createTime.replace(/-/g, "") === browseDate) {
        item.createTime = "今天";
      } else if (+item.createTime.replace(/-/g, "") === +browseDate - 1) {
        item.createTime = "昨天";
      } else if (item.createTime.slice(0, 4) === year) {
        item.createTime =
          item.createTime.slice(5, 7) +
          "月" +
          item.createTime.slice(8, 10) +
          "日";
      } else {
        item.createTime =
          item.createTime.slice(0, 4) +
          "年" +
          item.createTime.slice(5, 7) +
          "月" +
          item.createTime.slice(8, 10) +
          "日";
      }
    });
    for (let i = 0; i < newData.length; i++) {
      for (let j = i + 1; j < newData.length; j++) {
        if (newData[i].createTime === newData[j].createTime) {
          ++i;
        }
      }
      newDateList.push({
        createTime: newData[i].createTime,
      });
    }
    let obj = {};
    newDateList = [...newDateList];
    // newDateList = [...dateList, ...newDateList]
    newDateList = newDateList.filter((x, index, self) => {
      var arrids = [];
      newDateList.forEach((item, i) => {
        arrids.push(item.createTime);
      });
      return arrids.indexOf(x.createTime) === index;
    });
    setDateList([...newDateList]);
    setBrowseList(type === "get" ? newData : [...browseList, ...newData]);
  };
  // 获取浏览记录列表
  const getBrowseList = () => {
    const params = {
      userId: userId,
      page: 1,
      size: size,
    };
    // if(pharmacyId !== '0') {
    //     params.pharmacyId = pharmacyId
    // }
    browseListRequest(params).then((res) => {
      console.log(res, params);
      if (res.code === 1 && res.data[0].length > 0) {
        const data = res.data[0];
        console.log(data);
        resetBrowseList(data, "get");
      } else {
        setDateList([]);
        setBrowseList([]);
      }
    });
    setRefresh(true);
  };
  // 触底更新数据
  const onscroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      10
    ) {
      pageBrowselist();
    }
  };
  // 触底时换页
  const pageBrowselist = () => {
    if (load) {
      const params = {
        userId: userId,
        page: page + 1,
        size: size,
      };
      browseListRequest(params).then((res) => {
        if (res.code === 1) {
          if (res.data[0].length > 0) {
            const data = res.data[0];
            resetBrowseList(data, "limit");
            setPage(params.page);
            if (data[0].length < size) {
              setLoad(false);
            }
          } else {
            if (res.data[0].length < size) {
              setLoad(false);
            }
          }
        }
      });
      setRefresh(true);
    }
  };
  // 普通和删除界面切换
  const iconcliick = () => {
    let browseChange = browseList;
    let arrId = browsingId;
    if (edit) {
      arrId = [];
      browseChange.forEach((item) => {
        item.checked = false;
      });
    }
    setAllChecked(false);
    setBrowseList(browseChange);
    setBrowsingId(arrId);
    setEdit(!edit);
    setRefresh(true);
  };
  // 详情跳转
  const medDetail = (val) => {
    if (val.pharmacyState == 1) {
      if (val.violation == 0) {
        props.history.push(`/med/${val.medId}`);
      } else {
        Toast.info("该药品已失效");
      }
    } else {
      Toast.info("店铺已下架");
    }
  };
  // 取消全选
  const browseCheck = (e, val, index) => {
    e.stopPropagation();
    let arrId = browsingId;
    let browseChange = browseList;
    browseChange[index].checked = !browseChange[index].checked;
    setAllChecked(false);
    if (val.checked) {
      arrId.push(val.browsingId);
      setBrowsingId(arrId);
      setAllChecked(arrId.length === browseList.length);
    } else {
      arrId.splice(
        arrId.findIndex((item) => item === val.browsingId),
        1
      );
      setBrowsingId(arrId);
    }
    setBrowseList(browseChange);
    setRefresh(true);
  };
  useEffect(() => {
    getcart();
  }, [mednum]);
  // 显示加入购物车页面
  const addCart = async (e, val) => {
    getcart();
    e.stopPropagation();
    const res = await meddetailRequest(val.medId);
    console.log(res.data[0][0]);
    setMeditem(res.data[0][0]);
    let nownum = 0;
    if (cartlist.length) {
      cartlist.forEach((item) => {
        if (item.medId == res.data[0][0].medId) {
          console.log(11);
          nownum = item.medQuantity;
        }
      });
      setMednum(res.data[3][0].stockNum - nownum);
    } else {
      setMednum(res.data[3][0].stockNum);
    }
    //console.log(res)
    setvisible1(true);
    // const params = {
    //     userId: userId,
    //     pharmacyId: val.pharmacyId,
    //     medId: val.medId,
    //     medQuantity: 1
    // }
    // querycartRequest(params.userId, params.medId).then(res => {
    //     if (res.code === 1) {
    //         if (res.data[0].length > 0) {
    //             console.log('已存在于购物车')
    //         } else {
    //             addcartRequest(params).then(res => {
    //                 console.log(res)
    //             })
    //         }
    //     }
    // })
  };
  // 商品全选
  const allCheck = (e) => {
    let browseChange = browseList;
    let arrId = browsingId;
    setAllChecked(e.target.checked);
    if (e.target.checked) {
      browseChange.forEach((item) => {
        item.checked = true;
        arrId.push(item.browsingId);
      });
    } else {
      arrId = [];
      browseChange.forEach((item) => {
        item.checked = false;
      });
    }
    setBrowseList(browseChange);
    setBrowsingId(arrId);
    setRefresh(true);
    console.log(browsingId);
  };
  // 删除收藏
  const delBrowse = () => {
    if (browsingId.length > 0) {
      const params = {
        userId: userId,
        browsingId: browsingId,
        checkAll: allChecked ? "1" : "0",
      };
      browseDelRequest(params).then((res) => {
        if (res.code === 1) {
          setBrowsingId([]);
          setLoad(true);
          getBrowseList();
        }
      });
    }
  };

  //改变加入数量
  const cahngenum = (e) => {
    if (isNaN(e.target.value)) {
      Toast.fail("请输入数字", 1);
    } else {
      if (e.target.value) {
        setNum(e.target.value);
      } else {
        setNum("");
      }
    }
  };
  const movenum = (code) => {
    if (code == 1) {
      if (num > 1) {
        setNum(num - 1);
      }
    } else {
      setNum(num + 1);
    }
  };
  //点击添加购物车
  const goshopcar = async () => {
    setTimeout(() => {
      getcart();
    }, 1000);
    if (userId) {
      let code = false;
      // console.log(cartlist,'111111111111111111');
      cartlist.forEach((item) => {
        if (item.medId == meditem.medId) {
          code = true;
        }
      });
      //console.log(num,mednum);
      if (num) {
        if (mednum <= 0) {
          Toast.fail(`该药品暂无库存`, 1);
        } else {
          if (num > Number(mednum)) {
            Toast.fail(`该药品库存仅剩${mednum}`, 1);
            setNum(1);
          } else {
            //console.log(mednum);
            if (code) {
              //console.log(num);
              const res = await addcartidentical({
                userId: userId,
                pharmacyId: meditem.pharmacyId,
                medId: meditem.medId,
                medQuantity: num,
              });
              // console.log(res);
            } else {
              const res = await addcartRequest({
                userId: userId,
                pharmacyId: meditem.pharmacyId,
                medId: meditem.medId,
                medQuantity: num,
              });
              //console.log(res);
            }
            Toast.success("加入成功", 1);
            setvisible1(false);
          }
        }
      } else {
        Toast.fail("请输入有效数字", 1);
        setNum(1);
      }
    } else {
      Toast.fail("请先登录", 1);
      props.history.push("/login");
    }
  };
  // console.log(dateList,browseList)
  return (
    <>
      <Head
        title="浏览记录"
        ability={edit ? "完成" : "管理"}
        clickmethod={iconcliick.bind(null, {})}
      />
      <div
        className={edit ? "screen" : "order-screen-page"}
        onScroll={onscroll}
      >
        {dateList.length > 0 ? (
          <div className="viewrecord-part flex-col">
            {dateList.map((item, index) => {
              return (
                <div key={index} className="vrecord-box">
                  <div className="viewrecord-day flexc">{item.createTime}</div>
                  {browseList.map((v, index) => {
                    console.log(v, "0000000000000000000");
                    return item.createTime === v.createTime ? (
                      <div key={index}>
                        <div
                          className="viewrecord-med"
                          onClick={() => medDetail(v)}
                        >
                          {edit ? (
                            <div
                              className="viewrecord-item-select"
                              onClick={(e) => browseCheck(e, v, index)}
                            >
                              <CheckboxItem
                                className="my-radio"
                                checked={v.checked}
                              />
                            </div>
                          ) : (
                            <div className="viewrecord-space"></div>
                          )}
                          <div className="viewrecord-img flexc">
                            {/* src={v.medPhoto ? v.medPhoto : defaultImg} */}
                            <img
                              src={
                                v.medClassify === "1"
                                  ? zhezaho
                                  : v.medPhoto
                                  ? v.medPhoto
                                  : defaultImg
                              }
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultImg;
                              }}
                            />
                          </div>
                          <div className="viewrecord-med-detail flexc">
                            <div className="flexc">
                              <p className="line2">{v.medName}</p>
                              <span>{v.medSpec}</span>
                            </div>
                            <p>￥{v.medPrice / 100}</p>
                          </div>
                          {/*{edit ? null :*/}
                          {/*    <div className='viewrecord-buy flex-row'*/}
                          {/*        onClick={(e) => addCart(e, v)}>*/}
                          {/*        <i className='iconfont icon-tubiaozhizuomoban' ></i>*/}
                          {/*    </div>*/}
                          {/*}*/}
                        </div>
                        <div
                          style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#DDDFE5",
                          }}
                        ></div>
                      </div>
                    ) : null;
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <NoData type="信息" />
        )}
      </div>
      {edit ? (
        <div className="edit-items flex-apart-row">
          <CheckboxItem
            className="my-radio"
            onChange={allCheck}
            checked={allChecked}
          >
            全选
          </CheckboxItem>
          <Button name="删除" basecolor="#F34848" w={54} onClick={delBrowse} />
        </div>
      ) : null}

      <Popup
        visible={visible1}
        className="popup"
        onMaskClick={() => {
          setvisible1(false);
        }}
        bodyStyle={{
          minHeight: "60vh",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <div className="addbox2">
          <div
            className="addbox2-top"
            style={{ padding: "10px", textAlign: "right" }}
          >
            <img
              src={closeimg}
              onClick={() => setvisible1(false)}
              style={{ width: "18px" }}
              alt=""
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0px",
              paddingLeft: "20px",
              height: "100px",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <img
              src={
                meditem
                  ? meditem.medPhoto
                    ? meditem.medPhoto
                    : defaultImg
                  : null
              }
              style={{ width: "80px", height: "80px", marginRight: "10px" }}
              alt=""
            />
            <div>
              <p style={{ fontSize: "18px", fontWeight: 600 }}>
                {meditem ? meditem.medComName : null}
              </p>
              <p style={{ fontSize: "24px" }}>
                <span style={{ marginRight: "10px" }}>原价</span>
                <span style={{ color: "#FF5555" }}>
                  ￥{meditem ? meditem.medPrice / 100 : null}
                </span>
              </p>
            </div>
          </div>
          <div
            style={{
              paddingLeft: "20px",
              lineHeight: "25px",
              marginBottom: "10px",
            }}
          >
            <p>
              库存{" "}
              <span style={{ marginLeft: "10px" }}>
                {mednum ? mednum : null}
              </span>
            </p>
            <p>
              效期{" "}
              <span style={{ marginLeft: "10px" }}>
                {meditem ? meditem.expiration : null}
              </span>
            </p>
          </div>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              paddingRight: "20px",
              paddingLeft: "20px",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>购买数量</p>
            <p>
              <button
                onClick={() => movenum(1)}
                style={{
                  width: "36px",
                  height: "21px",
                  border: "none",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                -
              </button>
              <input
                type="text"
                value={num}
                onChange={(e) => cahngenum(e)}
                style={{
                  height: "20px",
                  textAlign: "center",
                  width: "50px",
                  borderWidth: "1px",
                  borderColor: "rgb(239, 239, 239)",
                }}
              />
              <button
                onClick={() => movenum(2)}
                style={{
                  width: "36px",
                  height: "21px",
                  border: "none",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                +
              </button>
            </p>
          </div>
          <div style={{ paddingLeft: "20px", marginBottom: "70px" }}>
            <span style={{ marginRight: "10px", fontSize: "15px" }}>合计</span>{" "}
            <span
              style={{
                color: "#FF5555",
                fontSize: "15px",
              }}
            >
              ￥{meditem ? (num * meditem.medPrice) / 100 : null}{" "}
            </span>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              onClick={goshopcar}
              style={{
                width: "330px",
                border: "none",
                height: "42px",
                backgroundColor: "#FF5555",
                borderRadius: "21px",
                color: "#000",
              }}
            >
              确定
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
}
