import React from 'react'

import './style.less'
import '../../static/css/iconfont.css'

export default function StepButton(props){
    return(
        <div className='login-input'>
        <div className='input-line'>
            <div className='input-control'>
                {props.children}
            </div>
        </div>
    </div >
    )
    
}