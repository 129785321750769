import React, {} from 'react'
import Head from '../../../component/Head/index'

function Consumer(props) {
    
    return (
        <div>
            <Head title='云药购用户协议'/>
            <div style={{padding:10}}>
                <div>版本：V1.0</div>
                <br/>
                <div>发布日期：2021年1月26日</div>
                <br/>
                <div>欢迎您与四川智云众康网络科技有限公司签署本《用户服务协议》（下称"本协议"）并使用云药购（定义见下文）服务。</div>
                <br/>
                <div>【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款，特别是粗体下划线标识之处，您应重点阅读。如您对协议有任何疑问，可向云药购客服咨询。</div>
                <br/>
                <div>签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与云药购达成一致，成为云药购用户（下称"用户"或"您"）。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</div>
                <br/>
                <div>一、 定义</div>
                <br/>
                <div>云药购：四川智云众康网络科技有限公司连锁有限公司所运营的、向用户提供医药健康服务的互联网平台。</div>
                <br/>
                <div>云药购服务：云药购基于互联网，以包含云药购APP软件、网站、微信公众号等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</div>
                <br/>
                <div>云药购平台规则：云药购平台已经发布及后续发布的全部规则、解读、公告等内容以及云药购平台在论坛、帮助中心等发布的各类规则、实施细则、产品流程说明、公告等。</div>
                <br/>
                <div>二、用户注册</div>
                <br/>
                <div>1. 用户资格</div>
                <br/>
                <div>您确认，在您开始注册程序使用云药购服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。您知悉，无民事行为能力人、限制民事行为能力人以及无经营或特定经营资格的组织（视情况）不当注册为云药购用户或超过其民事权利或行为能力范围从事与云药购进行交易的，其与云药购之间的服务协议自始无效，一经发现，云药购有权立即停止与该用户的交易、注销该用户，您及您的监护人应依照法律规定承担因此而导致的一切后果。</div>
                <br/>
                <div>2. 账户注册</div>
                <br/>
                <div>账户注册是指用户登陆云药购，按要求填写相关信息并确认同意履行本协议的过程。云药购只允许每位用户注册及使用一个云药购账户。如有证据证明或云药购根据相关规则判断您存在不当注册或不当使用多个云药购账户的情形，云药购可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给云药购及相关方造成损失的，您还应承担赔偿责任。</div>
                <br/>
                <div>3. 账户安全</div>
                <br/>
                <div>您有权使用您设置或确认的云药购会员名、手机号码（以下简称"账户名称"）及您设置的密码（账户名称及密码合称"账户"）登录云药购。您的账户为您自行设置并由您保管，云药购任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开云药购平台。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，云药购并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</div>
                <br/>
                <div>您有权使用您设置或确认的云药购会员名、手机号码（以下简称"账户名称"）及您设置的密码（账户名称及密码合称"账户"）登录云药购。您的账户为您自行设置并由您保管，云药购任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开云药购平台。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，云药购并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</div>
                <br/>
                <div>由于您的云药购平台账户关联您的个人信息及云药购平台商业信息，您的云药购平台账户仅限您本人使用。未经云药购平台同意，您直接或间接授权第三方使用您云药购平台账户或获取您账户项下信息的行为无效。如云药购根据云药购平台规则中约定的违约认定程序及标准判断您云药购平台账户的使用可能危及您的账户安全及/或云药购平台信息安全的，云药购平台可拒绝提供相应服务或终止本协议。</div>
                <br/>
                <div>4. 账户转让</div>
                <br/>
                <div>由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经云药购平台同意，并符合云药购平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则云药购平台有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。</div>
                <br/>
                <div>5. 实名认证</div>
                <br/>
                <div>作为云药购平台经营者，为使您更好地使用云药购服务，保障您的账户安全，云药购平台可要求您按相关法律法规规定完成实名认证。</div>
                <br/>
                <div>6. 信息真实</div>
                <br/>
                <div>在使用云药购平台服务时，您应当按云药购平台页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址、收货地址等），以便云药购或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。如您的信息发生变动，您应当在变动发生后10日内及时更新您的信息。因您未及时更新信息导致未能正常使用云药购服务或发生其他损失的，均由您承担相关责任。</div>
                <br/>
                <div>7. 税费承担</div>
                <br/>
                <div>您因使用云药购进行交易、获取有偿服务等而发生的所有您应纳的税赋由您自行承担。</div>
                <br/>
                <div>三、 商品交易</div>
                <br/>
                <div>您在云药购购买商品时必须遵守以下条款：</div>
                <br/>
                <div>1. 您在使用云药购服务时应遵守所有适用的中国法律、法规、条例和地方性法律的要求。您还必须确保遵守本协议及纳入本协议的所有其他条款和规则的规定。</div>
                <br/>
                <div>2. 您决定通过云药购购买某一商品的，即代表您同意受该商品描述所含的出售条件的约束，只要该等出售条件不违反法律或本协议规定。</div>
                <br/>
                <div>3. 您理解并认可云药购上的订单生效规则：云药购展示的商品信息（如商品名称、价格、商品描述等）仅构成要约邀请。当您通过云药购订购商品，确定购买并成功提交订单时（订单内容应包含购买的商品数量、价格及支付方式、收货人、联系方式、收货地址等信息），即视为您向销售方发出了购买订单商品的要约。云药购订单出库时，合同成立。</div>
                <br/>
                <div>4. 在您下订单的同时，也同时承认了您已经达到购买所订商品的法定年龄，并对您在订单中提供的所有信息的真实性负责。由于云药购出售部分产品的特殊性，您可能被要求进一步提供与购买、使用、服用订单项下商品相关的信息，方能继续订单流程；该等信息视情况可能包括您或使用、服用商品人的个人信息或其他信息，您应保证提供的信息真实有效。云药购有权对该等信息进行审核，并要求您做出进一步说明，并视情况可能拒绝您发出的订单或取消已经生成的订单。</div>
                <br/>
                <div>5. 云药购保留对您产品订购数量、频次的限制权。</div>
                <br/>
                <div>6. 您的订单确认后，如发生意外情况的，包括但不限于：云药购上显示的订单内商品和/或订单明显错误或缺货；由于供应商提价、税额变化等引起价格变化等情况，云药购将会通过站内信、短信、邮件或电话通知您，与您协商，您应当及时予以回应。</div>
                <br/>
                <div>7. 商品价格和可获性都将在云药购上标明，显示的每一项价格都包含了增值税（按照法定税率）。运费将另外结算，费用因您选择的送货方式的不同而异。云药购将在结算页面通知您，您应当仔细阅读。</div>
                <br/>
                <div>8. 当您在云药购平台购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</div>
                <br/>
                <div>您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱云药购平台正常交易秩序的行为。基于维护云药购平台交易秩序及交易安全的需要，云药购发现上述情形时可主动执行关闭相关交易订单等操作。</div>
                <br/>
                <div>9. 您有权在云药购平台提供的评价系统中对与您达成交易的其他用户商品及/或服务进行评价。您应当理解，您在云药购平台的评价信息是公开的，如您不愿意在评价信息中向公众披露您的身份信息，您有权选择通过匿名形式发表评价内容。</div>
                <br/>
                <div>为确保评价内容能为用户购物决策提供可靠的依据，反映商品及/或服务的真实情况，您的所有评价行为应遵守云药购平台规则的相关规定，评价内容应当合法、客观、真实，与交易的商品及/或服务具有关联性，且不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。云药购可按照云药购平台规则的相关规定对您实施上述行为所产生的评价信息采取必要处置措施。</div>
                <br/>
                <div>四、 您的权利和义务</div>
                <br/>
                <div>1. 您有权根据本协议的规定以及云药购上发布的相关规则在云药购上查询商品信息、发表使用体验、参与商品讨论、邀请关注好友、上传商品图片、参加云药购的有关活动，以及享受云药购提供的其它信息服务。</div>
                <br/>
                <div>2. 您应当保证在使用云药购购买商品过程中遵守诚实信用原则，不在购买过程中采取不正当行为，不扰乱网上交易的正常秩序。</div>
                <br/>
                <div>3. 您享有言论自由权利，并拥有适度修改、删除自己发表的文章的权利。但您不得在云药购发表包含以下内容的言论：</div>
                <br/>
                <div>①煽动、抗拒、破坏宪法和法律、行政法规实施的；</div>
                <br/>
                <div>②煽动颠覆国家政权，推翻社会主义制度的；</div>
                <br/>
                <div>③煽动、分裂国家，破坏国家统一的；</div>
                <br/>
                <div>④煽动民族仇恨、民族歧视，破坏民族团结的；</div>
                <br/>
                <div>⑤任何包含对种族、性别、宗教、地域内容等歧视的；</div>
                <br/>
                <div>⑥捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</div>
                <br/>
                <div>⑦宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</div>
                <br/>
                <div>⑧公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</div>
                <br/>
                <div>⑨损害国家机关信誉的；</div>
                <br/>
                <div>⑩其他违背社会公共利益或公共道德或依据相关云药购平台协议、规则的规定不适合在云药购平台上发布的；</div>
                <br/>
                <div>⑪其他违反宪法、法律和行政法规的。</div>
                <br/>
                <div>您在发表使用体验、讨论图片等，除遵守本条款外，还应遵守国家相关法律法规。</div>
                <br/>
                <div>5. 未经云药购同意，禁止您在网站发布任何形式的广告。</div>
                <br/>
                <div>五、 云药购的权利和义务</div>
                <br/>
                <div>1. 云药购有义务在现有技术上维护整个网上交易平台的正常运行，并努力提升和改进技术，使您网上交易活动得以顺利进行。</div>
                <br/>
                <div>2. 对您在注册使用云药购网上交易平台中所遇到的与交易或注册有关的问题及反映的情况，云药购应及时作出回复。</div>
                <br/>
                <div>3. 对于您在云药购网上交易平台上的不当行为，或任何云药购认为应当暂时中止服务的情况，云药购有权即刻作出暂时屏蔽相关信息、中止提供服务等处理，并通知您；您对云药购的处理措施存在异议的，可以提供相关证据予以说明。若经核实，您的行为确有违法违规或违反本平台用户协议、交易规则的情况，云药购有权终止对您提供服务。</div>
                <br/>
                <div>4. 云药购将尽最大努力、采取必要合理措施，保障云药购网络安全、稳定运行，保障电子商务交易安全。若您在与银行支付系统直接产生业务时发生网络安全问题，为提高效率，请与银行直接联系，云药购将在业务范围内为您提供最大帮助。</div>
                <br/>
                <div>5. 云药购上存在第三方产品或服务的提供商。您与第三方服务商之间发生的交易，本协议没有约定的，适用您与第三方服务商之间的约定；本协议有约定，适用本协议的约定，除非云药购另行做出意思表示。</div>
                <br/>
                <div>六、 网站规则、修改和可分性</div>
                <br/>
                <div>1. 价格变动规则</div>
                <br/>
                <div>直到您发出订单，云药购才能确认商品的价格。云药购将尽最大努力保证您所购商品与云药购上公布的价格一致，但价目表和声明并不构成要约。尽管做出最大的努力，云药购的商品中一小部分产品可能会有定价错误。如果发现错误定价，云药购将采取下列措施之一：</div>
                <br/>
                <div>① 如果某一产品的正确定价低于错误定价，云药购将按照较低的定价向您销售该产品或设定新的价格向您进行销售。</div>
                <br/>
                <div>② 如果某一商品的正确定价高于错误定价，云药购会根据具体情况决定是否在交付前联系您寻求指示, 或者取消订单并通知您。</div>
                <br/>
                <div>2. 产品缺货规则</div>
                <br/>
                <div>您希望购买的产品如果发生缺货，云药购有权不确认订单，也可以选择为您登记缺货产品（"预售登记"），云药购会尽最大努力在最快时间内满足您的购买需求，当缺货产品到货，云药购将第一时间通过邮件、短信或电话通知您，方便您进行购买。该等预售登记并不做订单处理。</div>
                <br/>
                <div>3. 邮件/短信服务规则</div>
                <br/>
                <div>云药购保留通过邮件和短信的形式，对云药购注册、购物用户发送订单信息、促销活动、商品广告等告知服务的权利。如果您在云药购注册、购物，表明您已默示接受此项服务。</div>
                <br/>
                <div>您不想接收来自云药购除订单以外的邮件和短信，您有权通过云药购提供的相应的退订功能进行退订。</div>
                <br/>
                <div>4. 送货规则</div>
                <br/>
                <div>云药购将会把产品送到您所指定的送货地址。所有在云药购上列出的送货时间为参考时间，供您参照使用。参考时间的计算系根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出。</div>
                <br/>
                <div>5. 退换货、补货规则</div>
                <br/>
                <div>在下订单的同时，您知晓并同意了云药购的《退换货、补发货规则》。详情请参见 云药购退换货、补发货规则。</div>
                <br/>
                <div>6. 规则变更</div>
                <br/>
                <div>本协议内容包括协议正文及所有云药购已经发布的或将来可能发布、修改的各类规则。所有规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。云药购可根据国家法律法规变化及维护交易秩序、保护消费者权益和各类需要，不时修改本协议。本协议和新发布、修改的各类规则于１药网按照法定程序公示、通知或征求您同意后生效。您继续使用１药网，则视为您接受１药网对平台规则所做的修改。</div>
                <br/>
                <div>七、 服务的中断和终止</div>
                <br/>
                <div>1. 如您向云药购提出注销注册用户身份时，经云药购审核同意，由云药购注销该注册用户，您即解除与云药购的服务协议关系。</div>
                <br/>
                <div>2. 在下列情况下，云药购可以通过注销用户的方式终止服务：</div>
                <br/>
                <div>① 在您违反本服务协议相关规定时，云药购有权终止向该您提供服务。云药购将在中断服务时通知您。但如您在云药购终止提供服务后，再一次直接或间接或以他人名义注册为云药购用户的，云药购有权再次单方面终止向您提供服务；</div>
                <br/>
                <div>② 如云药购通过您提供的信息与您联系时，发现您在注册时填写的电子邮箱或手机等已不存在或无法接收信息的，经云药购以您在注册时填写的其它联系方式通知您更改，而您在三个工作日内仍未能提供或云药购无法根据您注册时预留的信息联系到您的；</div>
                <br/>
                <div>③ 一经云药购发现您注册数据中主要内容是虚假的；</div>
                <br/>
                <div>④ 本服务协议终止或更新时，您明示不愿接受新的服务协议的；</div>
                <br/>
                <div>⑤ 您违反本协议约定，云药购依据违约条款终止本协议的；</div>
                <br/>
                <div>⑥ 您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，云药购依据云药购平台规则对您的账户予以查封的；</div>
                <br/>
                <div>⑦ 除上述情形外，因您多次违反云药购平台规则相关规定且情节严重，云药购依据云药购平台规则对您的账户予以查封的；</div>
                <br/>
                <div>⑧ 您在云药购平台有欺诈、发布或销售假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；</div>
                <br/>
                <div>⑨ 其它云药购认为需终止服务的情况。</div>
                <br/>
                <div>3 . 协议终止后的处理</div>
                <br/>
                <div>本协议终止后，除法律有明确规定外，云药购无义务向您或您指定的第三方披露您账户中的任何信息。</div>
                <br/>
                <div>本协议终止后，云药购仍享有下列权利：</div>
                <br/>
                <div>①在匿名化处理您的用户信息的前提下，继续保存您的注册数据及以前的交易行为记录，以及您留存于云药购平台的各类信息；</div>
                <br/>
                <div>②对于您过往的违约行为，云药购仍可依据本协议向您追究违约责任。</div>
                <br/>
                <div>本协议终止后，对于您在本协议存续期间产生的交易订单，云药购可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</div>
                <br/>
                <div>八、 通知</div>
                <br/>
                <div>1. 您在注册成为云药购平台用户，并接受云药购服务时，您应该向云药购提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</div>
                <br/>
                <div>您在注册云药购平台用户时生成的账户用于登陆云药购平台接收站内信和系统消息，也作为您的有效联系方式。</div>
                <br/>
                <div>云药购将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</div>
                <br/>
                <div>2. 云药购通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在云药购平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址寄出后的第五个自然日即视为送达。</div>
                <br/>
                <div>3.对于在云药购平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信或电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码或电子邮箱等联系方式为您在云药购平台注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</div>
                <br/>
                <div>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</div>
                <br/>
                <div>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</div>
                <br/>
                <div>您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</div>
                <br/>
                <div>九、 知识产权</div>
                <br/>
                <div>1. 云药购上的图表、标识、网页页眉、按钮图标、文字、服务品名等标示在网站上的信息都是四川智云众康网络科技有限公司连锁有限公司或其关联公司的财产，受中国和国际知识产权相关法律的保护。未经云药购许可，您不得以任何可能引起用户混淆的方式或任何贬低或诽谤四川智云众康网络科技有限公司连锁有限公司或其关联公司的方式用于与非云药购的任何产品或服务上。在云药购上出现的不属于上述主体的所有其他商标是其商标权利人各自的财产，这些权利人可能是也可能不是与四川智云众康网络科技有限公司连锁有限公司相关联、相联系或由四川智云众康网络科技有限公司连锁有限公司的关联公司赞助的。未经四川智云众康网络科技有限公司连锁有限公司或相关商标所有人的书面许可，云药购上的任何东西都不应被解释为以默许或其他方式授予许可或使用网站上出现的商标的权利。</div>
                <br/>
                <div>2. 云药购用户发表的文章仅代表作者本人观点，与云药购立场无关，作者文责自负。</div>
                <br/>
                <div>3. 云药购有权将用户在云药购发表的产品使用体验、产品讨论或图片自行使用或者与其他人合作使用于其他用途，包括但不限于网站、电子杂志、杂志、刊物等，使用时需为作者署名，以发表文章时注明的署名为准。文章有附带版权声明者除外。</div>
                <br/>
                <div>4. 用户在云药购上发表的文章及图片（包括转贴的文章及图片）版权仅归原作者所有，若作者有版权声明或原作从其它网站转载而附带有原版权声明者，其版权归属以附带声明为准。</div>
            </div>
        </div>
    )
}

export default Consumer