import React, { Component } from 'react'
import icon from "../../static/img/home/fj_02.png"
import "./style.less"
import icon2 from "../../static/img/home/ytsmall.jpg"
import icon3 from "../../static/img/home/y.png"
import icon4 from "../../static/img/home/yticon1.png"
import icon5 from "../../static/img/home/yticon2.png"
import JsBarcode from 'jsbarcode'
import { oderdetailRequest } from "../../api/request/order"
import { getstore } from "../../api/request/store"
const font = "10pt"
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            value: null,
            code: true,
            storeId: 1,
            storedata: {},
            orderdata: {},
            orderarr: {},
            orderId: "16365213139121038284643",
            dateTime: "",
            date: "",
            orderShortAddress: "",
            di:""
        }
    }
    componentWillMount = async () => {
        const res = await oderdetailRequest(this.state.orderId)
        console.log(res)
        this.setState({
            orderdata: res.data[0][0],
            value: res.data[0][0].courierNumber,
            orderarr: res.data[1][0],
            orderShortAddress: res.data[0][0].orderShortAddress,
            di: res.data[0][0].orderCourierid
        }, () => console.log(this.state.value))
        const res2 = await getstore({
            pharmacyId: this.state.storeId
        })
        //console.log(res2)
        this.setState({
            storedata: res2.data[0][0]
        })
        let yy = new Date().getFullYear()
        let mm = new Date().getMonth() + 1
        let dd = new Date().getDate()
        let hh = new Date().getHours()
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
            :
            new Date().getMinutes()
        let dateTime
        let date
        dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf
        date = yy + '-' + mm + '-' + dd
        this.setState({
            dateTime,
            date
        })
    }
    // 条形码生成,获取订单信息
    componentDidMount = async () => {
        JsBarcode(this.barcode, this.state.value, {
            displayValue: false,
            width: 6.3,
            height: 90,
            margin: 0,
        });
        JsBarcode(this.barcode2, this.state.value, {
            displayValue: false,
            width: 3.6,
            height: 50,
            margin: 0,
        });

    }
    doPrint = () => {
        this.setState({
            code: false
        }, () => {
            document.getElementsByTagName('body')[0].style.zoom = 0.50
            window.print();
            document.getElementsByTagName('body')[0].style.zoom = 1
            this.setState({
                code: true
            })
        })

    }
    render() {
        const { orderdata, storedata, orderarr, dateTime, date, orderShortAddress,di } = this.state
        return (
            <div>
                {this.state.code ? <div style={{ width: "800px", height: "35px" }}>
                    <button onClick={this.doPrint}>
                        <i className="fa fa-print"></i>打印
                    </button>
                </div> : null}
                <div className="yd-box">
                    <div className="yd-box-title">
                        <h1>标准快递</h1>
                    </div>
                    <div className="yd-box-3ma">
                        {orderdata.orderShortAddress}
                    </div>
                    <div className="yd-box-jiadress">
                        集包地: <span>{di}</span>
                    </div>
                    <div className="yd-box-shou">
                        <div className="yd-box-shou-left">收件</div>
                        <div className="yd-box-shou-right">
                            <p>{orderdata.addressee}:{orderdata.phone}</p>
                            <p>{orderdata.province + orderdata.city + orderdata.district + orderdata.address}</p>
                        </div>
                    </div>
                    <div className="yd-box-ji">
                        <div className="yd-box-ji-left">寄件</div>
                        <div className="yd-box-ji-right">
                            <p>{storedata.accountName}:{storedata.contactPhone}</p>
                            <p>{storedata.companyAddress}</p>
                        </div>
                    </div>
                    <div className="yd-box-ma1">
                        <div className="box-ma1-top">
                            <svg
                                ref={(ref) => {
                                    this.barcode = ref;
                                }}
                            />
                        </div>
                        <p>{orderdata.courierNumber}</p>
                    </div>
                    <div className="yd-box-qian">
                        <p>签收人:</p>
                        <p>时间:</p>
                    </div>
                    <div className="yd-box-ma2">
                        <div className="yd-box-ma2-left"></div>
                        <div className="yd-box-ma2-right">
                            <div>
                                <svg
                                    ref={(ref) => {
                                        this.barcode2 = ref;
                                    }}
                                />
                            </div>
                            <div>{orderdata.courierNumber}</div>
                        </div>
                    </div>
                    <div className="yd-box-shou2">
                    <div className="yd-box-shou2-left">收件</div>
                        <div className="yd-box-shou2-right">
                            <p>{orderdata.addressee}:{orderdata.phone}</p>
                            <p>{orderdata.province + orderdata.city + orderdata.district + orderdata.address}</p>
                        </div>
                    </div>
                    <div className="yd-box-ji2">
                    <div className="yd-box-ji2-left">寄件</div>
                        <div className="yd-box-ji2-right">
                            <p>{storedata.accountName}:{storedata.contactPhone}</p>
                            <p>{storedata.companyAddress}</p>
                        </div>
                    </div>
                    <div className="yd-box-bottom">
                        {orderarr.medName}
                        <span>已验视</span>
                    </div>
                </div>
            </div>
        )
    }
}
