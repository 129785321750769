import React ,{ useState, useEffect }from 'react'
import { Tabs,Toast } from 'antd-mobile'
import {Link} from 'react-router-dom'
import Head from '../../../component/Head'
import Tag from '../../../baseui/tag/index'

import { couponlistRequest,ownCouponDetail } from '../../../api/request/coupon'

import './style.less'
import IMG from '../../../static/img/logo.png'
import coupon from '../../../static/img/coupon.png'

import noCoupon from '../../../static/img/nodata/coupon.png'
import useCoupon from '../../../static/img/useCoupon.png'


export default function Coupon(props) {
    
    const userId = localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    // 优惠卷列表
    const [couponlist, setCouponlist] = useState([])
    const [state, setState] = useState('0')
    // 药店信息
    const [pharmacyList, setPharmacyList] = useState([])
    const tabs=[{ title: '未使用', state: '0' },{ title: '已使用', state: '1' },{ title: '已过期', state: '-1' }]

    useEffect(() => {
        getCoupon('0')
    }, [])
    // 获取优惠卷列表
    const getCoupon = (state) => {
        setState(state);
        let pharmacyList1=[]
        if (state=='0') {
            ownCouponDetail({userId}).then(res=>{
                console.log(res);
                if(res.code === 1 && res.data[0].length > 0) {
                    let data = res.data[0]
                    setCouponlist(data)
                } else {
                    setCouponlist([])
                    setPharmacyList([])
                }
            })
        }else if (state=='1') {
            ownCouponDetail({userId}).then(res=>{
                console.log(res);
                if(res.code === 1 && res.data[1].length > 0) {
                    let data = res.data[1]
                    setCouponlist(data)
                } else {
                    setCouponlist([])
                    setPharmacyList([])
                }
            })
        }else if (state=='-1'){
            ownCouponDetail({userId}).then(res=>{
                console.log(res);
                if(res.code === 1 && res.data[2].length > 0) {
                    let data = res.data[2]
                    setCouponlist(data)
                } else {
                    setCouponlist([])
                    setPharmacyList([])
                }
            })
        }
    }
    useEffect(()=>{
        if (couponlist) {
            let pharmacyList1=[]
            for (let i = 0; i < couponlist.length; i++) {
                pharmacyList1.push({
                    pharmacyId: couponlist[i].pharmacyId,
                    pharmacyName: couponlist[i].pharmacyName,
                    pharmacyPhoto: couponlist[i].pharmacyPhoto
                })
            }
            // 去重
            let nArr = pharmacyList1.filter((currentValue, currentIndex, selfArr) => {
                return selfArr.findIndex(x => x.pharmacyId === currentValue.pharmacyId) === currentIndex
              });

            setPharmacyList(nArr);
            setCouponlist(couponlist)
        }
    },[couponlist])
    console.log(pharmacyList);
    
    // 切换标签页，获取对应优惠卷信息
    const tabsChange = (tab, index) => {
        getCoupon(tab.state)
    }

    const enterStore = (pharmacyId) => {
        console.log(pharmacyId)
    }
    // 展示店铺信息
    const showcoupon=(tab)=>{
        // console.log(state);
        return(
            <>
                {tab.map((s,i)=>{
                    return(
                        <div key={i} className='coupon-dragstore flexc'>
                            <div className='flex-apart-row'>
                                <div className='coupon-dragstore-title flex-row'>
                                    <div>
                                        <img src={s.pharmacyPhoto?s.pharmacyPhoto:IMG} alt=''/>
                                    </div>
                                    <p>{s.pharmacyName?s.pharmacyName:'云药购'}</p>
                                </div>
                            {/* {s.pharmacyId === 0 ? null :
                                <div onClick={()=>enterStore(s.pharmacyId)}>
                                    <Link to={`/shop/${s.pharmacyId}`}>
                                        <Tag type='radius'
                                        text='进店'
                                        maincolor='#F34848'
                                        active={false}/>
                                    </Link>
                                </div>
                            } */}
                            </div>
                            {showitems(couponlist, s)}
                        </div>
                    )
                })}
            </>
        )
    }
    const toShop=(s)=>{
        console.log(s)
        if (s.pharmacyState==0){
            Toast.info('店铺已下架')
        }
    }
    // 展示优惠卷列表
    const showitems=(tab, s)=>{
        console.log(tab);
        return(
            <div>
                {tab.map((v,index)=>{
                    return(v.pharmacyId === s.pharmacyId ?
                        <div key={index}>
                            <div className='coupon-tiket flexr' onClick={()=>toShop(v)}>
                                {/* {v.new?
                                <>
                                    <div className='coupon-new'></div>
                                    <span className='coupon-newword'>新</span>
                                </>:null
                                } */}
                                {state=='0'?<img className='coupon-pic' src={coupon} alt="" />:
                                <img className='coupon-pic' src={useCoupon} alt="" />}
                                <div className='coupon-detail flexc'>
                                    <div className='coupon-detail-top'>
                                        <div className='manjian-date'>
                                            {v.couponType=='2'?<div className='manjian-pharmacy-date'>店铺满减</div>:
                                            <div className='manjian-pharmacy-date'>部分满减</div>}
                                            <div className='manjian-daoqi-date'>{v.couponExpire}到期</div>
                                        </div>
                                        <div className='coupon-tiket-left flex-col'>
                                            <div className='coupon-num'>
                                                <span>￥</span>
                                                <span>{v.couponLimit/100}</span>
                                            </div>
                                            <p style={{color:"#999"}}>满{v.couponMoney/100}元可用</p>
                                        </div>
                                    </div>
                                    <div className='coupon-divide-line'></div>
                                    <div className='coupon-last-row'>
                                        <div>
                                            <p>起始时间{v.couponValidity}</p>
                                            {v.couponType=='2'?<p>限指定门店可用，所有商品均可用</p>:
                                            <p>限指定门店可用，部分商品不可用</p>}
                                        </div>
                                        {state=='0'?<Link to={v.pharmacyState==1?`/shop/${s.pharmacyId}`:'/mine/coupon'}><Tag type='radius' text='立即使用' maincolor='#F34848' active={true}/></Link>:
                                        <Tag type='radius' text='立即使用' maincolor='#F34848' active={true}/>}
                                    </div>
                                </div>
                            <div className={state=='0'?"":"coupon-outer"}></div>
                            </div>
                        </div> : null
                    )
                })
                }
            </div>
        )
    }
    // 页面无优惠卷提示/优惠卷渲染
    const renderContent = (tab,index) =>{
        return(
            <div>
            {couponlist.length > 0 ?
                <div className='contain-item'>{showcoupon(pharmacyList)}</div> :
                <div className='no-data coupon-no-data'>
                    <img src={noCoupon} alt=''/>
                    <p>抱歉，暂无优惠券</p>
                </div>
            }
            </div>
        )
    }
    

    return(
        <div>
            <Head title='优惠券' style={{}}/>
            <Tabs
            tabs={tabs}
            onChange={tabsChange}
            tabBarTextStyle={{fontSize:'14px',padding:'0',height:'29px'}}
            tabBarActiveTextColor={'#333333'}
            tabBarUnderlineStyle={{border: '3px #1F87FF solid',transform:'scaleX(0.25)'}}
            renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3} />}>
                {renderContent}
            </Tabs>
        </div>
    )
}