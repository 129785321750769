import React, { useState, useEffect } from 'react'
import FucButton from '../../../baseui/fuctionbutton/index'

import './style.less'
import defaultImg from '../../../static/img/default.png'
import zhezaho from "../../../static/img/zhezaho.jpg"

export default function Oderitem(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const { toMedicine,toAfterMarket, toRefund, toCancel, state, medDetail,arr1,tofailReason} = props
    // console.log(medDetail);
    // console.log(arr1);

    return(
        <div >
            <div>
                {medDetail.map((item, index) => (
                    <div key={index} className='order-meditem flex-apart-row'
                    onClick={()=>toMedicine(item)}>
                        <div className='flexr'>
                            {/* src={item.medPhoto?item.medPhoto:defaultImg} */}
                            <img src={item.medClassify === "1" ? zhezaho : (item.medPhoto ? item.medPhoto : defaultImg)} alt=''
                            onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                            <div className='flexc order-meditem-detail'>
                                <p>
                                    <span className='name'>
                                        {item.medClassify==='1'?<span className='chufang'>Rx</span>:null}
                                        {item.medName}
                                    </span>
                                    <span className='price'>
                                        {item.originPrice?<span>￥{item.originPrice/100}</span>:null}
                                        ￥{item.medPrice/100}</span>
                                    </p>

                                <p className='guige'>{item.medSpec}</p>
                                <span className='money'>x{item.medQuantity}</span>
                            </div>
                        </div>
                        {state=='12'?
                            <>
                                {arr1.length>0?
                                <>
                                    {arr1.map(i=>(
                                        <>
                                            {item.medId==i.med_id?
                                            <>
                                                {i.aftersale_state=='1'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>待商家同意</span>:null}
                                                {i.aftersale_state=='2'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>等待退回</span>:null}
                                                {i.aftersale_state=='5'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>货物已退回</span>:null}
                                                {i.aftersale_state=='6'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>售后处理中</span>:null}
                                                {i.aftersale_state=='7'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>商家已收到货</span>:null}
                                                {i.aftersale_state=='8'&& i.aftersale_type=='商品类型'?<span onClick={(e)=>toAfterMarket(e,item.orderNumber,item.medId)} style={{position:'absolute',right:'0px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>售后完成</span>:null}
                                            </>:null}
                                        </>
                                    ))}
                                </>
                            :null}
                        </>:null}
                        {item.length>1&&index!=item.length-1?<div className='xian'></div>:null}
                        {/* <span>共{item.medQuantity}件</span> */}
                        {/* <div className='order-meditem-right flexc'>
                            {state===3||state===4?
                            <div className='order-meditem-button flexr'>
                                <FucButton name='申请售后'/>
                                <FucButton name='加购物车'/>
                            </div>
                            :null}
                        </div> */}
                    </div>
                ))}
            </div>
        </div>
    )
}