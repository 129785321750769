import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AuthRoute from "../component/authroute/authroute";
import Introduce from "../container/Introduce/index";
import Home from "../container/Home/index";
import Morestore from "../container/Home/morestore/index";
import Classify from "../container/Classify/index";
import Shopcart from "../application/cart/index";
import Health from "../container/Health/index";
import Healthpost from "../container/Health/Post/index";
import Healthrmd from "../container/Health/Recommand/index";
import Fillorder from "../application/cart/fillorder";
import Mine from "../container/Mine/index";
import Collection from "../container/Mine/collection/index";
import Order from "../container/Mine/order/index";
// import Orderdetail from '../container/Mine/order/orderdetail/index'
import Orderdetail from "../application/order/orderdetail";
import refundDetails from "../application/order/refundDetails";
import aftersaleDetails from "../application/order/aftersaleDetails";

import AppleRefund from "../container/Mine/order/refund";
import Adress from "../container/Mine/adress/index";
import ElectronicInvoice from "../container/Mine/electronicInvoice/index";

import Adressedit from "../container/Mine/adress/adressedit/index";
import Coupon from "../container/Mine/coupon/index";
import Viewrecord from "../container/Mine/viewrecord/index";
import Set from "../container/Mine/set/index";
import informationlist from "../container/Mine/informationlist/index";
import revenueexpenditure from "../container/Mine/revenueexpenditure/index";
import medicinalUser from "../container/Mine/medicinalUser/index";
import controlInvoices from "../container/Mine/controlInvoices/index";
import invoiceDetails from "../container/Mine/invoiceDetails/index";

import patient from "../container/Mine/patient/index";
import privatePhysician from "../container/Mine/privatePhysician/index";
import platformQualification from "../container/Mine/platformQualification/index";

import Login from "../container/Login/login";
import Login1 from "../container/Login/Login1";
import retrieve from "../container/Login/retrieve";
import retrieveone from "../container/Login/retrieveone";
import Privacy from "../container/Login/agreement/privacy";
import Consumer from "../container/Login/agreement/consumer";
// import Register from '../container/Register/register'
import Search from "../container/Search/index";
import Tidings from "../container/Tidings/index";
import systemPrompt from "../container/Tidings/systemPrompt/index";
import preferentialPromotion from "../container/Tidings/preferentialPromotion/index";
import orderDelivery from "../container/Tidings/orderDelivery/index";

import Med from "../application/medicine/index";
import Medlist from "../application/medicine/medlist";
import Recipel from "../application/medicine/recipel";
import Adduser from "../application/medicine/adduser";
import Userlist from "../application/medicine/userlist";
import Shop from "../application/shop/index";
import Shopinfo from "../application/shop/shopinfo/index";
import Shopmed from "../application/shop/shopmed";
import Inquiry from "../container/Inquiry/index";
import InquiryHospial from "../container/Inquiry/onhospital/index";
import InquiryHospialId from "../container/Inquiry/onhospital/onhospital-id";
import InquiryDisease from "../container/Inquiry/ondisease/index";
import InquiryDoctor from "../container/Inquiry/doctor/index";
import PatientInfo from "../container/Inquiry/patient/patientinfo";
import InquiryListImageText from "../container/Inquiry/patient/inquirylist1";
import InquiryListPhone from "../container/Inquiry/patient/inquirylist2";
import InquiryPay from "../container/Inquiry/patient/pay";
import Notfound from "../container/Notfound/index";
import Drag from "../container/Notfound/drag";
import Seckill from "../container/Home/Activity/seckill";
import Bulletinboard from "../container/Home/Activity/bulletinboard";
import Announcementdetails from "../container/Home/Activity/announcementdetails";
import Middledetails from "../container/Home/Activity/middledetails";

import yygActivity from "../container/activity/index";
import Actpage from "../container/activity/actpage";
import Actpage1 from "../container/activity/actpage1";
import Actpage2 from "../container/activity/actpage2";

import Comment from "../application/order/comment/index";
import SendComment from "../application/order/comment/comment";
import logistics from "../application/order/logistics";
import aftermarket from "../application/order/aftermarket/index";
import aftermarket2 from "../application/order/aftermarket/aftermarket2/index";
import invoiceAfterSales from "../application/order/aftermarket/invoiceAfterSales/index";
import qualificationsAfterSales from "../application/order/aftermarket/qualificationsAfterSales/index";

import goodsrejected from "../application/order/aftermarket/goodsrejected/index";

import getUserInfo from "../container/fanhui/getUserInfo";
import Consultate from "../application/order/consultate/index";
import Drawback from "../application/order/drawback/index";
import Agree from "../application/order/agree/index";
import Refund from "../application/order/refund1/index";

import Nowaddress from "../application/order/nowaddress/index";
import Refuse from "../application/order/refuse/index";
import Qualifications from "../application/qualifications/index";
import Upload from "../application/qualifications/upload/index";
import Await from "../application/qualifications/await/index";
import storeinfor from "../application/minepf/storeinfor/index";
import storeticket from "../application/minepf/storeticket/index";
import zhuanedit from "../application/minepf/storeticket/zhuanedit/index";
import moedit from "../application/minepf/storeticket/moedit/index";
import storemoney from "../application/minepf/storemoney/index";
import Withdrawal from "../application/minepf/storemoney/Withdrawal/index";
import txDetail from "../application/minepf/storemoney/Withdrawal/txDetail/index";
import bill from "../application/minepf/bill";
import billinfo from "../application/minepf/bill/billinfo";

import detailed from "../application/minepf/storemoney/detailed/index";
import storeSH from "../application/minepf/storeSH/index";
import storeSHin from "../application/minepf/storeSH/storeSHin/index";
import addpass from "../container/Mine/set/addpass/index";
import wltest from "../application/wltest";
import dztest from "../application/danzitest";

import ztdztest from "../application/ztdanzitest";
import yddztest from "../application/yddanzitest";
import stdztest from "../application/stdanzitest";
import Region from "../application/region";

import JDtest from "../container/JDtest";
import ele from "../eletest/index";
import eletest from "../application/eledanzitest/index";
import chat from "../application/chat/index";
import risknotification from "../application/risknotification";
import privacyagreement from "../application/privacyagreement";
import TradSnapshot from "../application/order/snapshot/TradSnapshot";
import OrderMed from "../application/order/snapshot/OrderMed";

class SubRouter extends Component {
  render() {
    return (
      <div>
        <AuthRoute></AuthRoute>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/ele" component={ele}></Route>
          <Route exact path="/eletest" component={eletest}></Route>
          <Route path="/risknotification" component={risknotification}></Route>
          <Route path="/privacyagreement" component={privacyagreement}></Route>
          <Route path="/introduce" component={Introduce}></Route>
          <Route path="/wltest" component={wltest}></Route>
          <Route path="/dztest" component={dztest}></Route>
          <Route path="/ztdztest" component={ztdztest}></Route>
          <Route path="/yddztest" component={yddztest}></Route>
          <Route path="/stdztest" component={stdztest}></Route>
          <Route path="/getUserInfo" component={getUserInfo}></Route>
          <Route path="/JDtest" component={JDtest}></Route>
          <Route path="/classify" component={Classify}></Route>
          <Route path="/shopcart" component={Shopcart}></Route>
          <Route exact path="/health" component={Health}></Route>
          <Route path="/health/post/:topicId" component={Healthpost}></Route>
          <Route path="/health/recommand" component={Healthrmd}></Route>
          <Route path="/morestore" component={Morestore}></Route>
          <Route path="/fillorder" component={Fillorder}></Route>
          <Route exact path="/mine" component={Mine}></Route>
          <Route path="/mine/collection" component={Collection}></Route>
          <Route exact path="/mine/order" component={Order}></Route>
          <Route path="/mine/order/id" component={Orderdetail}></Route>
          <Route
            path="/mine/order/refundDetails"
            component={refundDetails}
          ></Route>
          <Route
            path="/mine/order/aftersaleDetails"
            component={aftersaleDetails}
          ></Route>
          <Route path="/mine/order/aftermarket" component={aftermarket}></Route>
          <Route path="/aftermarket2" component={aftermarket2}></Route>
          <Route
            path="/invoiceAfterSales"
            component={invoiceAfterSales}
          ></Route>
          <Route
            path="/qualificationsAfterSales"
            component={qualificationsAfterSales}
          ></Route>
          <Route path="/goodsrejected" component={goodsrejected}></Route>
          <Route path="/chat" component={chat}></Route>
          <Route path="/mine/bill" exact component={bill}></Route>
          <Route path="/mine/bill/billinfo" component={billinfo}></Route>
          <Route
            path="/mine/order/logistics/:id?"
            component={logistics}
          ></Route>
          <Route
            path="/mine/order/apple_refund"
            component={AppleRefund}
          ></Route>
          <Route exact path="/mine/adress" component={Adress}></Route>
          <Route
            path="/mine/electronicInvoice"
            component={ElectronicInvoice}
          ></Route>
          <Route
            path="/mine/adress/edit/:type/:addressId"
            component={Adressedit}
          ></Route>
          <Route path="/mine/coupon" component={Coupon}></Route>
          <Route path="/mine/storeinfor" component={storeinfor}></Route>
          <Route path="/mine/storeticket" exact component={storeticket}></Route>
          <Route
            path="/mine/storeticket/zhuanedit"
            component={zhuanedit}
          ></Route>
          <Route path="/mine/storeticket/moedit" component={moedit}></Route>
          <Route path="/mine/storemoney" exact component={storemoney}></Route>
          <Route
            path="/mine/storemoney/Withdrawal"
            exact
            component={Withdrawal}
          ></Route>
          <Route
            path="/mine/storemoney/Withdrawal/txDetail"
            exact
            component={txDetail}
          ></Route>
          <Route
            path="/mine/storemoney/detailed"
            exact
            component={detailed}
          ></Route>
          <Route path="/mine/storeSH" exact component={storeSH}></Route>
          <Route path="/mine/storeSH/storeSHin" component={storeSHin}></Route>
          <Route path="/mine/viewrecord" component={Viewrecord}></Route>
          <Route path="/mine/medicinalUser" component={medicinalUser}></Route>
          <Route
            path="/mine/controlInvoices"
            component={controlInvoices}
          ></Route>
          <Route path="/mine/invoiceDetails" component={invoiceDetails}></Route>

          <Route path="/mine/patient" component={patient}></Route>
          <Route
            path="/mine/privatePhysician"
            component={privatePhysician}
          ></Route>
          <Route
            path="/mine/platformQualification"
            component={platformQualification}
          ></Route>

          <Route path="/mine/set" exact component={Set}></Route>
          <Route
            path="/mine/informationlist"
            exact
            component={informationlist}
          ></Route>
          <Route
            path="/mine/revenueexpenditure"
            exact
            component={revenueexpenditure}
          ></Route>
          <Route path="/mine/set/addpass" component={addpass}></Route>
          <Route path="/login" exact component={Login}></Route>
          <Route path="/login/Login1" component={Login1}></Route>
          <Route path="/login/retrieve" component={retrieve}></Route>
          <Route path="/login/retrieveone" component={retrieveone}></Route>
          <Route path="/privacy" component={Privacy}></Route>
          <Route path="/consumer" component={Consumer}></Route>
          {/* <Route path='/register' component={Register}></Route> */}
          <Route path="/search" component={Search}></Route>
          <Route path="/tidings" component={Tidings}></Route>
          <Route path="/systemPrompt" component={systemPrompt}></Route>
          <Route
            path="/preferentialPromotion"
            component={preferentialPromotion}
          ></Route>
          <Route path="/orderDelivery" component={orderDelivery}></Route>

          <Route exact path="/med/:medId" component={Med}></Route>
          <Route
            exact
            path="/medlist/:categoryId/:classifyId/:content"
            component={Medlist}
          ></Route>
          <Route path="/med/recipel" component={Recipel}></Route>
          <Route path="/med/adduser" component={Adduser}></Route>
          <Route path="/med/userlist" component={Userlist}></Route>
          <Route exact path="/shop/:pharmacyId" component={Shop}></Route>
          <Route path="/shopinfo" component={Shopinfo}></Route>
          <Route path="/shop/shopmed" component={Shopmed}></Route>
          <Route exact path="/inquiry" component={Inquiry}></Route>
          <Route
            exact
            path="/inquiry/hospital"
            component={InquiryHospial}
          ></Route>
          <Route
            path="/inquiry/hospital/id"
            component={InquiryHospialId}
          ></Route>
          <Route path="/inquiry/disease" component={InquiryDisease}></Route>
          <Route path="/inquiry/doctor" component={InquiryDoctor}></Route>
          <Route path="/inquiry/patientinfo" component={PatientInfo}></Route>
          <Route
            path="/inquiry/list/imagetext"
            component={InquiryListImageText}
          ></Route>
          <Route
            path="/inquiry/list/phone"
            component={InquiryListPhone}
          ></Route>
          <Route path="/inquiry/pay" component={InquiryPay}></Route>
          <Route path="/drag" component={Drag}></Route>
          <Route path="/seckill" component={Seckill}></Route>
          <Route path="/bulletinboard" component={Bulletinboard}></Route>
          <Route
            path="/Announcementdetails"
            component={Announcementdetails}
          ></Route>
          <Route path="/Middledetails" component={Middledetails}></Route>

          <Route path="/activity" component={yygActivity}></Route>
          <Route path="/actpage" component={Actpage}></Route>
          <Route path="/actpage1" component={Actpage1}></Route>
          <Route path="/actpage2" component={Actpage2}></Route>

          <Route path="/comment" component={Comment}></Route>
          <Route path="/drawback" component={Drawback}></Route>
          <Route path="/agree" component={Agree}></Route>
          <Route path="/refund1" component={Refund}></Route>

          <Route path="/nowaddress" component={Nowaddress}></Route>
          <Route path="/refuse" component={Refuse}></Route>
          <Route path="/qualifications" component={Qualifications}></Route>
          <Route path="/upload" component={Upload}></Route>
          <Route path="/await" component={Await}></Route>
          <Route path="/region" component={Region}></Route>

          <Route path="/consultate" component={Consultate}></Route>
          <Route path="/sendcomment" component={SendComment}></Route>
          {/* 交易快照 */}
          <Route path="/snapshot" component={TradSnapshot}></Route>
          <Route path="/snapshotmed" component={OrderMed}></Route>
          <Route path="*" component={Notfound}></Route>
        </Switch>
      </div>
    );
  }
}

export default SubRouter;
