import React, { Component } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader';
import './MapContainer.less';
// const AMap = window.AMap
export default class GDmaps extends Component {
    constructor() {
        super()
        this.map = {};
        this.state = {
            with:"",
            height:""
        }
    }
    componentWillMount(){
        this.setState({
            width: this.props.width,
            height: this.props.height
        })
    }
    componentDidMount = () => {
        const getCode = this.props.getCode
        AMapLoader.load({
            key: "3061764fa2398c814d2b34909abfcaa6",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {

            let map = new AMap.Map("container", { //设置地图容器id
                viewMode: "3D",         //是否为3D地图模式
                zoom: 14,                //初始化地图级别
                resizeEnable: true,
            });
            //输入提示
            let autoOptions = {
                input: "tipinput"
            };
            AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
                let auto = new AMap.AutoComplete(autoOptions);
                let placeSearch = new AMap.PlaceSearch({
                    map: map
                });  //构造地点查询类
                auto.on("select", select);//注册监听，当选中某条记录时会触发
                function select(e) {
                    console.log(e);
                    let longitude=e.poi.location.lng
                    let latitude=e.poi.location.lat
                    let all=e.poi.district
                    let business=e.poi.name
                    let code=false
                   for (let index = 0; index < all.length; index++) {
                       if(all[index]=="省"){
                        code=true
                       }
                   }
                   let province=""
                   let city=""
                   let district=""
                   if(code){
                     let arr= all.split("省")
                     province=arr[0]+"省"
                     let arr2=arr[1].split("市")
                     city=arr2[0]+"市"
                     district=arr2[1]
                   }else{
                    let arr= all.split("市")
                    city=arr[0]+"市"
                    district=arr[1]
                   }
                    // console.log(longitude,latitude,province,city,district,business)
                    getCode(longitude,latitude,province,city,district,business)
                    placeSearch.setCity(e.poi.adcode);
                    placeSearch.search(e.poi.name);  //关键字查询查询
                }
            });
        }).catch(e => {
            console.log(e);
        })
    }
    render() {
        const {width,height} =this.state
        return (
            <div>
                <div id="container" style={{ width:width, height: height }}></div>
                <div id="myPage">
                    <input id="tipinput"  placeholder="请输入地址" size="20"/>
                </div>
            </div>
        )
    }
}
