import { axiosInstance } from '../config'
//查询用户问诊信息
export const wzpatientInfo = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/info`,data)
}
// 添加问诊人
export const addDruguser = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/add-druguser`,data)
}
// 修改问诊信息
export const updateDruguser = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/update-druguser`,data)
}
// 获取用药人
export const getPatientMember = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/get-patient-member`,data)
}
// 修改用药人
export const updatePatientMember = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/update-patient-member`,data)
}
// 添加用药人
export const addPatientMember = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/add-patient-member`,data)
}
// 获取用户信息		
export const patientinfo = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/patientinfo`,data)
}
// 图文-是否已有问诊信息	
export const isChatinfo = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/is-chatinfo`,data)
}
// 图文-添加问诊信息		
export const addChatinfo = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/add-chatinfo`,data)
}
// 图文-获取问诊信息	
export const getChatinfo = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/get-chatinfo`,data)
}
// 查询问诊状态	
export const wzstate = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/wzstate`,data)
}
// 问诊医生列表	
export const docterlist = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/docterlist`,data)
}
// 查询可接诊状态的医生	
export const getDoctype = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/get-doctype`,data)
}
// 修改医生接诊状态	
export const updateDoctype = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/update-doctype`,data)
}
// 获取单个订单所有问诊信息	
export const getAllorderwz = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/get-allorderwz`,data)
}
//删除用药人
export const delPatientMember = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/del-patient-member`,data)
}
// 查询关注医生	
export const getGzdoc = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/get-gzdoc`,data)
}
// 查询患者详情	
export const patientDetail = (data) => {
    return axiosInstance.post(`/restful/hosp/wzpatient/patient-detail`,data)
}