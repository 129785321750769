import { axiosInstance } from '../config'

// 获取蜂鸟token
export const getTau = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/ele-sign', data)
}
// 查询商品范围
export const getRange = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/get-range', data)
}
// 预下单
export const beforehand = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/beforehand', data)
}
// 正式下单
export const formalOrder = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/formal-order', data)
}
// 查询物流信息
export const getDetails = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/get-details', data)
}
// 查询骑手信息
export const getKnightInfo = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/getknightinfo', data)
}
// 获取可用取消原因列表
export const getcancelreasonlist = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/getcancelreasonlist', data)
}
// 取消订单
export const cancelorder = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/cancelorder', data)
}
//预取消订单
export const preCancelOrder = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/preCancelOrder', data)
}
// 获取当前经纬度
export const getlocation = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/get-location', data)
}
//支付成功蜂鸟下单
export const downorder = (data) => {
    return axiosInstance.get('/faas/hosp/erp-base/downoder', data)
}
// 订单状态回调
export const getstatus = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/get-status', data)
}
//蜂鸟新增门店
export const addstore = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/addstore', data)
}
//蜂鸟图片上传
export const uploadimg = (data) => {
    return axiosInstance.post('/faas/hosp/erp-base/upload-img', data)
}
//获取店铺回调状态
export const getstorestatus = (data) => {
    return axiosInstance.post('/api/hosp/order/callback-search', data)
}