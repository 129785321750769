import React, {Component} from 'react'
import {Checkbox, Toast} from 'antd-mobile';
import {wx} from "weixin-js-sdk"
import img from "../../static/img/login/wx2x.png"
import "./login1.less"
import {baseUrl2, code1, baseUrl} from "../../publicurl"

const CheckboxItem = Checkbox.CheckboxItem;
const AgreeItem = Checkbox.AgreeItem;
// wx.config({
//     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//     appId: 'wxabf674ac2a12c46e', // 必填，公众号的唯一标识
//     timestamp: , // 必填，生成签名的时间戳
//     nonceStr: '', // 必填，生成签名的随机串
//     signature: '',// 必填，签名
//     jsApiList: [] // 必填，需要使用的JS接口列表
//   });
export default class Login1 extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            type: "",

        }
    }

    componentWillMount() {
        this.setState({
            type: localStorage.getItem("type")
        })
    }

    onChange = (e) => {
        this.setState({
            checked: e.target.checked
        })
    }
    numlogin = () => {
        if (this.state.checked) {
            this.props.history.push("/login")
        } else {
            Toast.info('请先勾选相关用户协议', 1);
        }
        // const { type } = this.state
        // if (type == "2") {
        //     if (this.state.checked) {
        //         this.props.history.push("/login")
        //     } else {
        //         Toast.info('请先勾选相关用户协议', 1);
        //     }
        // }else{

        // }
    }
    numlogin2 = () => {
        this.props.history.push("/login")
    }
    // http%3A%2F%2Flocalhost%3A3000%2F%23%2Flogin%2Flogin1
    // 微信授权登录
    wxlogon = () => {
        console.log(encodeURIComponent('http://t-h5.sczyzk.com'+'/index.html#/getUserInfo'))
        const type = localStorage.getItem("type")
        if (this.state.checked) {
            localStorage.setItem("wxlogin", 1)
            let a=encodeURIComponent(baseUrl+'/index.html#/getUserInfo')
            if (code1==1000){
                window.location.href = `${baseUrl2}?client_id=${code1}&redirect_uri=https%3A%2F%2Fh5.sczyzk.com%2Findex.html%2523%2FgetUserInfo`
            }else{
                window.location.href = `http://t-pay.sczyzk.com/pay/weixin/user?client_id=6000&redirect_uri=http%3A%2F%2Ft-h5.sczyzk.com%2Findex.html%2523%2FgetUserInfo`
            }
            // window.location.href = `${baseUrl2}?client_id=${code1}&redirect_uri=https%3A%2F%2Fgateway.sczyzk.com%2Findex.html%23%2FgetUserInfo`
            // window.location.href = `${baseUrl2}?client_id=${code1}&redirect_uri=http%3A%2F%2Fsczyzk.f3322.net%3A3002%2Findex.html%23%2FgetUserInfo`
            // window.open(`${baseUrl2}?client_id=${code1}&redirect_uri=${encodeURIComponent('http://localhost:3001/#/mine/set')}`)

            // window.location.href = `${baseUrl2}?client_id=${code1}&redirect_uri=http%3A%2F%2Fsczyzk.f3322.net%3A3002%2Findex.html%23%2FgetUserInfo`

            //localStorage.setItem("wx",1)
            // if(type=="1"){
            //     window.location.href = "http://pay.sczyzk.com/pay/weixin/user?client_id=1000&redirect_uri=http%3A%2F%2Fh5.sczyzk.com%2Findex.html%2523%2F"
            // }else{
            //     window.location.href = "http://pay.sczyzk.com/pay/weixin/user?client_id=1000&redirect_uri=http%3A%2F%2Fh5.sczyzk.com%2Findex.html%2523%2F%3FpharmacyId%3D2%26env%3Dsingle"
            // }

        } else {
            Toast.info('请先勾选相关用户协议', 1);
        }
    }
    // 返回上一个界面
    fanhui = () => {
        // window.history.back()
        this.props.history.push("/mine")
    }

    render() {
        const {type} = this.state
        return (
            <div className="box">
                <div className="box-top">
                    <button onClick={this.fanhui}></button>
                </div>
                <div className={"logo"}>
                </div>
                <div className="allbtn">
                    <button onClick={this.numlogin}
                            style={{backgroundColor: type == "2" ? "#FF5555" : "#2086FE"}}>手机号码登录
                    </button>
                    <button onClick={this.wxlogon}>
                        <img src={img} alt=""/>
                        <p>微信登录</p>
                    </button>

                </div>
                <div className="bottom">
                    <Checkbox onChange={(e) => this.onChange(e)}> 已阅读并同意
                        <a onClick={() => this.props.history.push("/risknotification")}>《用户服务协议》</a>
                        <a onClick={() => this.props.history.push("/privacyagreement")}>《用户隐私政策》</a>
                    </Checkbox>
                </div>
            </div>
        )
    }
}
