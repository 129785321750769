import React ,{ Component } from 'react'
import { ImagePicker, TextareaItem,Checkbox, Toast } from 'antd-mobile'
import { oderdetailRequest,upOrderStuRequest } from '../../../api/request/order'
import { commentmedRequest } from '../../../api/request/comment'
import getUrl from '../../../component/GetUrlParam'
import Head from '../../../component/Head'
import Button from '../../../baseui/fuctionbutton/index'
import defaultImg from '../../../static/img/default.png'
import pingjia from '../../../static/img/pingjia.png'
import pingjia2 from '../../../static/img/pingjia2.png'
import photo from '../../../static/img/photo.png'

import './style.less'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
const CheckboxItem = Checkbox.CheckboxItem
export default class Comment extends Component {
    constructor() {
        super()
        this.state = {
            orderNumber: '',
            orderDetail: '',
            medList: '',
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            evaluateText: [],//评价内容
            tijiaoColor:false,//提交按钮
            pharmacyPhoto:"", 
            files: [],
            evaluatePic:[],
            star1: [1, 2, 3, 4, 5],
            medId:'',
            pharmacyId:'',
            pharmacyName:''
        }
    }
    componentDidMount = async () => {
        const {orderNumber} = this.state
        this.setState({
            orderNumber:getUrl('orderNumber', this.props.location.search)
        })
        oderdetailRequest(getUrl('orderNumber', this.props.location.search)).then(res => {
            console.log(res);
            if(res.code === 1 && res.data[0].length > 0) {
                // 默认全部未选中
                res.data[1].forEach(item => {
                    item.checked = false
                    item.test=[]
                    item.zan=false
                    item.ok=false
                    item.date=false
                    item.bao=false
                    item.files=[]
                    item.starCount=0
                    item.evaluatePic=[]
                    item.writeTest=''//输入评价
                })
                this.setState({
                    orderDetail:res.data[0][0],
                    pharmacyPhoto:res.data[0][0].pharmacyPhoto,
                    medList:res.data[1],
                    pharmacyId:res.data[0][0].pharmacyId,
                    pharmacyName:res.data[0][0].pharmacyName
                })
            }
        })
    }
     healthPost = (val) => {
        const {medId,medList} = this.state
         console.log(val);
         medList.map(item0=>{
            if (item0.medId==medId) {
                item0.writeTest=val
            }
        })
        this.setState({
            medList:medList
        })
    }
    setMedId=(medId)=>{
        this.setState({
            medId:medId
        })
    }
    // 上传图片
    imgChange = (files, type, index) => {
        console.log(this.state.medId);
        const {evaluatePic,medId,medList} = this.state
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic
        if(type === 'add') {
            let file = files[files.length-1].file
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if (file.size < 1024 * 1024) {
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadImg(formData).then(res1 => {
                            if(res1.code === 2) {
                                medList.map(item0=>{
                                    if (item0.medId==medId) {
                                        item0.files=files
                                    }
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            console.log(medId,imgList);
                            medList.map(item0=>{
                                if (item0.medId==medId) {
                                    item0.evaluatePic=imgList.toString()
                                }
                            })
                            this.setState({
                                evaluatePic:imgList,
                                medList:medList
                            })
                            Toast.hide()
                        }).catch(err => {
                            Toast.hide()
                        })
                    })
                }else{
                    Toast.show('请上传小于1M的图片', 1)
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            medList.map(item0=>{
                if (item0.medId==medId) {
                    item0.evaluatePic=imgList.toString()
                    item0.files=files
                }
            })
            this.setState({
                evaluatePic:imgList,
                medList:medList
            })
            console.log(imgList);
            Toast.hide()
        }
    }

    starCount=(medId,index1)=>{
        const {medList} = this.state
        medList.map(item0=>{
            if (item0.medId==medId) {
                item0.starCount=index1+1
            }
        })
        this.setState({
            medList:medList
        })
    }
    // 快捷评价
    commitKj=(e,medId,val)=>{
        const {evaluateText,medList} = this.state
        let starlistText={}
        let arr=evaluateText
        let arr0=[]
        let count=0//之前是否已选中 0,未选中
        let arr1=[]
        let flag=false
        console.log(e.target);
        switch (val) {
            case 1:
                if (arr.length) {
                    arr.map(item=>{
                        if (item.medId==medId) {
                            flag=true
                            arr0=item.test
                            item.test&&item.test.map(v=>{
                                if (v=='质量赞') {
                                    count=1
                                }
                            })
                            if (count) {
                                item.test&&item.test.map(v=>{
                                    if (v!='质量赞') {
                                        arr1.push(v)
                                    }
                                })
                                item.test=arr1
                            }else{
                                arr0.push('质量赞') 
                                item.test=arr0
    
                            }
                        }
                    })
                    if (!flag) {
                        starlistText.medId=medId
                        starlistText.test=['质量赞']
                        let star0=arr.concat(starlistText)
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.zan=!item0.zan
                                star0&&star0.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:star0,
                            medList:medList
                        })
                    }else{
                        
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.zan=!item0.zan
                                arr&&arr.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:arr,
                            medList:medList
                        })
                    }
                }else {
                    starlistText.medId=medId
                    starlistText.test=['质量赞']
                    let star0=arr.concat(starlistText)
                    
                    medList.map(item0=>{
                        if (item0.medId==medId) {
                            item0.zan=!item0.zan
                            star0&&star0.map(i=>{
                                if (i.medId==medId) {
                                    item0.test=i.test
                                }
                            })
                        }
                    })
                    this.setState({
                        evaluateText:star0,
                        medList:medList
                    })
                } 
                break;
            case 2:
                if (arr.length) {
                    arr.map(item=>{
                        if (item.medId==medId) {
                            flag=true
                            arr0=item.test
                            item.test&&item.test.map(v=>{
                                if (v=='效果好') {
                                    count=1
                                }
                            })
                            if (count) {
                                item.test&&item.test.map(v=>{
                                    if (v!='效果好') {
                                        arr1.push(v)
                                    }
                                })
                                item.test=arr1
                            }else{
                                arr0.push('效果好') 
                                item.test=arr0
    
                            }
                        }
                    })
                    if (!flag) {
                        starlistText.medId=medId
                        starlistText.test=['效果好']
                        let star0=arr.concat(starlistText)
                         
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.ok=!item0.ok
                                star0&&star0.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:star0,
                            medList:medList
                        })
                    }else{
                         
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.ok=!item0.ok
                                arr&&arr.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:arr,
                            medList:medList
                        })
                    }
                }else {
                    starlistText.medId=medId
                    starlistText.test=['效果好']
                    let star0=arr.concat(starlistText)
                    
                    medList.map(item0=>{
                        if (item0.medId==medId) {
                            item0.ok=!item0.ok
                            star0&&star0.map(i=>{
                                if (i.medId==medId) {
                                    item0.test=i.test
                                }
                            })
                        }
                    })
                    this.setState({
                        evaluateText:star0,
                        medList:medList
                    })
                }
                break;
            case 3:
                if (arr.length) {
                    arr.map(item=>{
                        if (item.medId==medId) {
                            flag=true
                            arr0=item.test
                            item.test&&item.test.map(v=>{
                                if (v=='配送速度快') {
                                    count=1
                                }
                            })
                            if (count) {
                                item.test&&item.test.map(v=>{
                                    if (v!='配送速度快') {
                                        arr1.push(v)
                                    }
                                })
                                item.test=arr1
                            }else{
                                arr0.push('配送速度快')
                                item.test=arr0
    
                            }
                        }
                    })
                    if (!flag) {
                        starlistText.medId=medId
                        starlistText.test=['配送速度快']
                        let star0=arr.concat(starlistText)
                        
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.date=!item0.date
                                star0&&star0.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:star0,
                            medList:medList
                        })
                    }else{
                         
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.date=!item0.date
                                arr&&arr.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:arr,
                            medList:medList
                        })
                    }
                }else {
                    starlistText.medId=medId
                    starlistText.test=['配送速度快']
                    let star0=arr.concat(starlistText)
                     
                    medList.map(item0=>{
                        if (item0.medId==medId) {
                            item0.date=!item0.date
                            star0&&star0.map(i=>{
                                if (i.medId==medId) {
                                    item0.test=i.test
                                }
                            })
                        }
                    })
                    this.setState({
                        evaluateText:star0,
                        medList:medList
                    })
                }
                break;
            case 4:
                if (arr.length) {
                    arr.map(item=>{
                        if (item.medId==medId) {
                            flag=true
                            arr0=item.test
                            item.test&&item.test.map(v=>{
                                if (v=='包装完好') {
                                    count=1
                                }
                            })
                            if (count) {
                                item.test&&item.test.map(v=>{
                                    if (v!='包装完好') {
                                        arr1.push(v)
                                    }
                                })
                                item.test=arr1
                            }else{
                                arr0.push('包装完好') 
                                item.test=arr0
    
                            }
                        }
                    })
                    if (!flag) {
                        starlistText.medId=medId
                        starlistText.test=['包装完好']
                        let star0=arr.concat(starlistText)
                         
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.bao=!item0.bao
                                star0&&star0.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:star0,
                            medList:medList
                        })
                    }else{
                         
                        medList.map(item0=>{
                            if (item0.medId==medId) {
                                item0.bao=!item0.bao
                                arr&&arr.map(i=>{
                                    if (i.medId==medId) {
                                        item0.test=i.test
                                    }
                                })
                            }
                        })
                        this.setState({
                            evaluateText:arr,
                            medList:medList
                        })
                    }
                }else {
                    starlistText.medId=medId
                    starlistText.test=['包装完好']
                    let star0=arr.concat(starlistText)
                     
                    medList.map(item0=>{
                        if (item0.medId==medId) {
                            item0.bao=!item0.bao
                            star0&&star0.map(i=>{
                                if (i.medId==medId) {
                                    item0.test=i.test
                                }
                            })
                        }
                    })
                    this.setState({
                        evaluateText:star0,
                        medList:medList
                    })
                }
                break;
            default:
                break;
        }
    }

    
    // 选中商品
    changechose=(e, v,index)=>{
        const {evaluateText,medList} = this.state
        console.log(e.target.checked,v);
        medList[index].checked = e.target.checked
        this.setState({
            medList:medList
        })
        console.log(medList);
        let flag=false
        medList.map(item=>{
            if (item.checked) {
                flag=true
            }
        })
        if (flag) {
            this.setState({
                tijiaoColor:true
            })
        }else{
            this.setState({
                tijiaoColor:false
            })
        }
    }
    // 提交
    commit=()=>{
        const {userId,orderNumber,medList,pharmacyName,pharmacyId} = this.state
        console.log(medList);
        // const params = {
        //     orderMedId: orderMedId,
        //     userId: userId,
        //     evaluateText: evaluateText,
        //     evaluatePic: evaluatePic.join(','),
        //     evaluateStar: starCount,
        //     medId: medDetail.medId,
        //     medName: medDetail.medName,
        //     medPhoto: medDetail.medPhoto,
        //     medSpec: medDetail.medSpec,
        //     pharmacyId: pharmacy.pharmacyId,
        //     pharmacyName: pharmacy.pharmacyName,
        //     orderNumber:orderNumber
        // }
        let arr=[]
        let list={}
        let flag=false
        medList&&medList.map(item=>{
            if (item.checked) {
                // list.orderMedId=item.orderMedId,
                // list.userId=userId,
                // list.evaluateText=item.test.toString()+item.writeTest,
                // list.evaluatePic=item.evaluatePic
                // list.evaluateStar=item.starCount
                // list.medId=item.medId,
                // list.medName=item.medName,
                // list.medPhoto=item.medPhoto,
                // list.medSpec=item.medSpec,
                // list.pharmacyId=item.pharmacyId,
                // list.pharmacyName=item.pharmacyName,
                // list.orderNumber=orderNumber
                list={
                    orderMedId: item.orderMedId,
                    userId: userId,
                    evaluateText: item.test.toString()+item.writeTest,
                    evaluatePic:item.evaluatePic,
                    evaluateStar: item.starCount,
                    medId: item.medId,
                    medName: item.medName,
                    medPhoto: item.medPhoto,
                    medSpec: item.medSpec,
                    pharmacyId: pharmacyId,
                    pharmacyName: pharmacyName,
                    orderNumber:orderNumber
                }
                arr.push(list)
            }
        })
        console.log(arr);
        arr&&arr.map(item=>{
            if(item.evaluateStar == 0||!item.evaluateText) {
                flag=true
            }
        })
        if(flag) {
            Toast.info('尚未评价完已勾选商品', 1)
        } else {
            commentmedRequest({array:arr}).then(res => {
                console.log(res);
                if(res.code === 1) {
                    upOrderStuRequest({orderState:'3',orderNumber:orderNumber}).then(res => {
                        console.log(res)
                        if (res.code==1) {
                            window.history.back()
                        }
                    })
                }
            })
        }
    }

    // 
    commit1=()=>{
        Toast.info('您还没有选择需要评价的药品,无法提交评价')
    }
    closeClick=()=>{
        Toast.info('请先勾选需要评价的药品')
    }
    render() {
        const { tijiaoColor, orderDetail, medList, userId, evaluateText,pharmacyPhoto, evaluatePic,star1 } = this.state
        return(
            <div className='outer'>
                <Head title='评价晒单'></Head>
                {!tijiaoColor?<p className='tijiao-top' onClick={()=>this.commit1()}>提交</p>:
                <p className='tijiao-top' style={{color:"#fff",backgroundColor:"#1F87FF"}} onClick={()=>this.commit()}>提交</p>}
                <div className='order-comment-screen' style={{marginTop:'10px'}}>
                {medList.length==0 ? null :
                    <div>
                        {medList.map((item, index) => (
                            <>
                                {item.checked?<div>
                                    <div className='medlist-title'>
                                        <img src={item.medPhoto!='null'?item.medPhoto:defaultImg} alt="" />
                                        <div className='xing'>
                                            <p children='med-name'>{item.medName}</p>
                                            <div className='xing-right'>
                                                {star1.map((item1, index1) => (<div>
                                                    {item1 <= item.starCount ?
                                                        <img style={{width:'20px', marginRight:'6px'}} src={pingjia} onClick={()=>this.starCount(item.medId,index1)} alt="" />:
                                                        <img style={{width:'20px', marginRight:'6px'}} src={pingjia2} onClick={()=>this.starCount(item.medId,index1)} alt="" />}
                                                </div>))}
                                                {item.starCount>0?
                                                    item.starCount==1||item.starCount==2?<span style={{position:'absolute',marginTop:'0px',marginLeft:'135px'}}>差评</span>:
                                                        item.starCount==3?<span style={{position:'absolute',marginTop:'0px',marginLeft:'135px'}}>中评</span>:
                                                            item.starCount==4||item.starCount==5?<span style={{position:'absolute',marginTop:'0px',marginLeft:'135px'}}>好评</span>:null:null}
                                            </div>
                                        </div>
                                        <CheckboxItem onChange={(e) => this.changechose(e, item,index)} />
                                    </div>
                                    <div className='comment-content' onClick={()=>this.setMedId(item.medId)}>
                                        <div className='sendcomment-area'>
                                            <TextareaItem
                                            className='sendcomment-area-text'
                                            placeholder='在此写出您的评价'
                                            rows={4}
                                            count={100}
                                            onChange={this.healthPost}/>
                                        </div>
                                        <div className='sendcomment-bottom'>
                                            <ImagePicker
                                            files={item.files}
                                            length={4}
                                            onChange={this.imgChange}
                                            onImageClick={(index, fs) => console.log(index, fs)}
                                            selectable={item.files.length < 2}
                                            accept='image/gif,image/jpeg,image/jpg,image/png'/>
                                        </div>
                                    </div>
                                    <p className='kuaijie'>快捷标签</p>
                                    <p className='kuaijie-biaoqian'>
                                        {item.zan?<p onClick={(e)=>this.commitKj(e,item.medId,1)} style={{backgroundColor:"#1F87FF",color:"#fff"}}>质量赞</p>:
                                        <p onClick={(e)=>this.commitKj(e,item.medId,1)}>质量赞</p>}
                                        {item.ok?<p onClick={(e)=>this.commitKj(e,item.medId,2)} style={{backgroundColor:"#1F87FF",color:"#fff"}}>效果好</p>:
                                        <p onClick={(e)=>this.commitKj(e,item.medId,2)}>效果好</p>}
                                        {item.date?<p onClick={(e)=>this.commitKj(e,item.medId,3)} style={{backgroundColor:"#1F87FF",color:"#fff"}}>配送速度快</p>:
                                        <p onClick={(e)=>this.commitKj(e,item.medId,3)}>配送速度快</p>}
                                        {item.bao?<p onClick={(e)=>this.commitKj(e,item.medId,4)} style={{backgroundColor:"#1F87FF",color:"#fff"}}>包装完好</p>:
                                        <p onClick={(e)=>this.commitKj(e,item.medId,4)}>包装完好</p>}
                                    </p>
                                </div>:
                                <div>
                                    <div className='medlist-title'>
                                        <img src={item.medPhoto!='null'?item.medPhoto:defaultImg} alt="" />
                                        <div className='xing'>
                                            <p children='med-name'>{item.medName}</p>
                                            <div className='xing-right'>
                                                {star1.map((item1, index1) => (<div>
                                                    <img style={{width:'20px', marginRight:'6px'}} src={pingjia2} onClick={this.closeClick} alt="" />
                                                </div>))}
                                            </div>
                                        </div>
                                        <CheckboxItem onChange={(e) => this.changechose(e, item,index)} />
                                    </div>
                                    <div className='comment-content' onClick={this.closeClick}>
                                        <div className='sendcomment-area'>
                                            <TextareaItem
                                            className='sendcomment-area-text'
                                            placeholder='在此写出您的评价'
                                            rows={4}
                                            count={100}
                                            disabled
                                            />
                                        </div>
                                        <div className='sendcomment-bottom' onClick={this.closeClick}>
                                            <img src={photo}/>
                                        </div>
                                    </div>
                                    <p className='kuaijie'>快捷标签</p>
                                    <p className='kuaijie-biaoqian'>
                                        <p onClick={this.closeClick}>质量赞</p>
                                        <p onClick={this.closeClick}>效果好</p>
                                        <p onClick={this.closeClick}>配送速度快</p>
                                        <p onClick={this.closeClick}>包装完好</p>
                                    </p>
                                </div>}
                            </>
                        ))}
                    </div>}
                </div>
            </div>
        )
    }
}