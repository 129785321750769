import React,{Component} from 'react'
import SwipeableViews from 'react-swipeable-views'
import {autoPlay} from 'react-swipeable-views-utils'
import Pagination from './Pagination'

import './style2.less'
import defaultImg from '../../static/img/default.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class Swiper extends Component{

    constructor(){
        super();
        this.state={
            index:0,
            banners:''
        }
    }
        
    handleChangeIndex(index){
        this.setState({
            index:index
        })
    }

    render(){
        const banners=this.props.banners;
        let arr=[]
        if (banners.length==0) {
            arr.push(defaultImg)
            // banners=arr
        }else{
            banners.map(item=>{
                if (item) {
                    arr.push(item)
                }
            })
            if (!arr.length) {
                arr.push(defaultImg)
            }
        }
        // console.log(arr);
        return(
            <div className='swiper2'>
                <AutoPlaySwipeableViews interval={4000} onChangeIndex={this.handleChangeIndex.bind(this)}>
                    {
                        arr.map((v,index)=>{
                            return(
                                <div key={index} className='swiper-view2'>
                                    {index!=0&&v||index==0?<img src={v?v:defaultImg} alt=''
                                    onError={(e)=>{e.target.onerror = null;e.target.src=defaultImg}}/>:null}
                                </div>
                            )
                        })
                    }
                </AutoPlaySwipeableViews>
                <Pagination dots={arr.length} index={this.state.index}/>
            </div>
        )
    }
}

export default Swiper