import React, { Component } from 'react'
import { Toast, Modal, List, Radio, Checkbox, TextareaItem, ImagePicker } from 'antd-mobile'
import Meditem from '../orderitem/index'

import { adressListRequest } from '../../../api/request/adress'
import { addAftersale,addAftersaleMed,updateAftersale, cancelAftersale } from '../../../api/request/aftersale'

import { oderdetailRequest, upOrderStuRequest } from '../../../api/request/order'
import { storeDetailsRequest } from "../../../api/request/store"
import { addIntegralRequest } from '../../../api/request/integral'
import { pharmacyStorecs } from '../../../api/request/cart'
import getUrl from '../../../component/GetUrlParam'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import '../../../static/css/iconfont.css'
import defaultImg from '../../../static/img/default.png'
import zhezaho from "../../../static/img/zhezaho.jpg"
import fanhui from '../../../static/img/fanhuihei.png'
import tuikuanright from '../../../static/img/tuikuanright.png'
import tuikuanleft from '../../../static/img/tuikuanleft.png'


import './style.less'
const { alert } = Modal
const RadioItem = Radio.RadioItem
const CheckboxItem = Checkbox.CheckboxItem


//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default class OrderState extends Component {
    constructor() {
        super()
        this.state = {
            orderNumber: '',
            orderDetail: '',
            medList: '',
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            status: '',
            medDetail: '',
            files1: [],
            evaluatePic: [],
            miaosu: '',
            radioVal:'',
            reasonContent:'',
            visible:'none',
            allchecked:false,
            pharmacyId:'',
            aftersaleAmount:'',//实际售后总金额
            frequencyLimit:'',//剩余售后次数
            isAfterSale:'',//是否在售后中 1否2是
            money:0,
            orderFreight:'',//配送费
            pharmacyPackingFee:'',//打包费
            orderAmount:'',//实付金额
            reasons : [ '厂家召回', '出库单问题','没收到货','拍错/多拍/不想要','实物与商品描述不符（批号/包裹）','药品近效期','商品质量问题','发货少货','商品压损','商家发错货', '其它原因']
        }
    }
    componentDidMount = () => {
        oderdetailRequest(getUrl('tradeno', this.props.location.search)).then(res => {
            console.log(res);
            if (res.code == 1) {
                let allPrice=0
                res.data[1].map(item=>{
                    allPrice+=item.medQuantity*item.medPrice
                })
                res.data[1].map(item=>{
                    if (!item.afterSalesQuantity) {
                        item.afterSalesQuantity=0
                    }
                    item.medQuantity1=item.medQuantity-item.afterSalesQuantity//可申请售后的数量
                    item.checked=false
                    item.allchecked=false
                    item.nowPrice=Math.round((item.medPrice/allPrice)*(res.data[0][0].orderAmount-res.data[0][0].orderFreight-res.data[0][0].pharmacyPackingFee))?Math.round((item.medPrice/allPrice)*(res.data[0][0].orderAmount-res.data[0][0].orderFreight-res.data[0][0].pharmacyPackingFee)):item.medPrice
                })
                this.setState({
                    medDetail: res.data[1],
                    orderDetail: res.data[0][0],
                    pharmacyId:res.data[0][0].pharmacyId,
                    orderNumber:getUrl('tradeno', this.props.location.search),
                    isAfterSale:res.data[0][0].isAfterSale,
                    frequencyLimit:res.data[0][0].frequencyLimit,
                    orderFreight:res.data[0][0].orderFreight,
                    pharmacyPackingFee:res.data[0][0].pharmacyPackingFee,
                    orderAmount:res.data[0][0].orderAmount
                },()=>{
                    console.log(this.state.medDetail);
                })
            }
        })
    }

    toMedicine = (medId) => {
        // this.props.history.push(`/med/${medId}`)
    }
    fanhui1 = () => {
        const { orderNumber } = this.state
        this.props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    toRefund = (e, orderNumber, medId) => {
        
    }
    

    // 申请退款理由
    smRreasons = () => {
        this.setState({
            visible: 'block'
        })
    }
    // 提交
    tijiao = () => {
        const {aftersaleAmount,orderDetail,money,pharmacyPackingFee,orderFreight,orderAmount,medDetail,orderNumber,reasonContent,evaluatePic,userId,pharmacyId,frequencyLimit,miaosu}=this.state
        let flag=false
        let allPrice=0
        medDetail.map(item=>{
            // allPrice+=item.medQuantity*item.medPrice
            if (item.checked) {
                flag=true
            }
        })
        // let aftersaleAmount1=Math.round((money/allPrice)*(orderAmount-orderFreight-pharmacyPackingFee))
        // console.log(aftersaleAmount1,money,allPrice,orderAmount);
        console.log(money);
        // 5.71  11.58   23.51
        if (orderDetail.isAfterSale==2){
            Toast.info('该订单已在售后中，请勿重复提交')
        }else{
            if (flag) {
                let params={
                    orderNumber:orderNumber,
                    aftersaleReason:reasonContent,
                    aftersaleCertificate:evaluatePic.toString(),
                    userId:userId,
                    orderAftersaleDetails:miaosu,
                    pharmacyId:pharmacyId,
                    aftersaleDestination:0,
                    aftersaleType:'商品类型',
                    frequencyLimit:frequencyLimit-1
                }


                if (reasonContent) {
                    // if (miaosu) {
                    addAftersale(params).then(res=>{
                        console.log(res);
                        let arr=[]
                        let list={}
                        let flag=false
                        medDetail&&medDetail.map(item=>{
                            if (item.checked) {
                                list={
                                    aftersaleId:res.data[1][0].aftersaleId,
                                    orderNumber:orderNumber,
                                    medId:item.medId,
                                    medName: item.medName,
                                    medPhoto: item.medPhoto,
                                    medSpec: item.medSpec,
                                    medApproval:item.medApproval,
                                    medPacking:item.medPacking?item.medPacking:1,
                                    medCompany:item.medCompany,
                                    medPrice:item.medPrice,
                                    medQuantity:item.medQuantity,
                                    medRefundQuantity:item.medQuantity1,
                                }
                                arr.push(list)
                            }
                        })
                        let params2={
                            aftersaleState:'1',
                            aftersaleRefuse:'',
                            aftersaleDetails:miaosu,
                            orderNumber:orderNumber,
                            aftersaleId:res.data[1][0].aftersaleId
                        }
                        console.log(arr);
                        if (res.code==1) {
                            addAftersaleMed({array:arr,aftersaleAmount:money,aftersaleId:res.data[1][0].aftersaleId}).then(res2=>{
                                console.log(res2);
                                if (res2.code==1) {
                                    updateAftersale(params2).then(res1=>{
                                        console.log(res1);
                                        this.props.history.replace(`/mine/order?tabId=${0}`)
                                        this.props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
                                    })
                                }
                            })
                        }
                    })
                    // }else{
                    //     Toast.info('具体原因未填写')
                    // }
                }else{
                    Toast.info('请选择申请退款原因')
                }
            }else{
                Toast.info('请选择需要退款的药品')
            }
        }

    }
    imgChange1 = (files, type, index) => {
        const { files1, evaluatePic } = this.state
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic
        if (type === 'add') {
            let file = files[files.length - 1].file
            console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    console.log(res1);
                    console.log(files);
                    if (res1.code === 2) {
                        this.setState({
                            files1: files
                        })
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    this.setState({
                        evaluatePic: imgList,
                    })
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            imgList.splice(index, 1)
            this.setState({
                evaluatePic: imgList,
                files1: files
            })
            Toast.hide()
        }
    }
    // 退款原因
    dianji = (e) => {
    }
    // 
    fuzhi = (v) => {
        this.setState({
            miaosu: v.target.value
        })
    }
    // 其它原因
    sureReason=()=>{
        const { reasonContent, radioVal } = this.state
        if (reasonContent&&reasonContent != '其它原因') {
            this.setState({
                visible: 'none'
            })
        }else if(radioVal=='其它原因'&&!reasonContent ){
            Toast.info('请输入退款原因')
        }else{
            Toast.info('请选择退款原因')
        }
    }
    // 数量减
    jian=(medQuantity1,medId)=>{
        const { medDetail } = this.state
        let price=0
        medDetail.map(item=>{
            if (item.medId==medId) {
                if (item.medQuantity1<=1) {
                    item.medQuantity1=1
                }else{
                    item.medQuantity1=medQuantity1-1
                }
            }
        })
        medDetail.map(item=>{
            if (item.checked) {
                price+=item.medQuantity1*item.nowPrice
            }
        })
        this.setState({
            medDetail:medDetail,
            money:price
        })
    }
    // 数量加
    jia=(medQuantity,afterSalesQuantity,medId)=>{
        const { medDetail,orderDetail } = this.state
        let price=0
        let count=0
        medDetail.map(item=>{
            if (item.medId==medId) {
                if (item.medQuantity1<medQuantity-afterSalesQuantity) {
                    item.medQuantity1=item.medQuantity1+1
                }else{
                    item.medQuantity1=medQuantity-afterSalesQuantity
                }
            }
        })
        medDetail.map(item=>{
            if (item.medQuantity1<item.medQuantity||!item.checked){
                count=1
            }
        })
        if (count){
            medDetail.map(item=>{
                if (item.checked) {
                    price+=item.medQuantity1*item.nowPrice
                }
            })
        }else{
            price=orderDetail.orderAmount
        }
        this.setState({
            medDetail:medDetail,
            money:price
        })
    }
    // 全选
    allChecked=(e)=>{
        const {medDetail,orderDetail}=this.state
        let price=0
        let count=0
        medDetail.map(item=>{
            item.checked=e.target.checked
            if (item.medQuantity1<item.medQuantity){
                count=1
            }
        })
        console.log(count)
        if (count){
            medDetail.map(item=>{
                if (item.checked) {
                    price+=item.medQuantity1*item.nowPrice
                }
            })
        }else{
            price=orderDetail.orderAmount
        }
        this.setState({
            medDetail:medDetail,
            allchecked:e.target.checked,
            money:price
        })
    }
    // 选择单个药品
    oneChecked=(e,medId,index)=>{
        const {medDetail,orderDetail}=this.state
        let price=0
        let count=0
        medDetail[index].checked = e.target.checked
        let flag=false  //判断是否所有药品均为选中状态
        medDetail.map(item=>{
            if (!item.checked&&item.medQuantity-item.afterSalesQuantity>0) {
                flag=true  //存在未选中的药品
            }
            if (!item.checked||item.medQuantity>item.medQuantity1) {
                count=1
            }
        })
        if (count){
            medDetail.map(item=>{
                if (item.checked) {
                    price+=item.medQuantity1*item.nowPrice
                }
            })
        }else{
            price=orderDetail.orderAmount
        }
        if (!flag) {
            this.setState({
                medDetail:medDetail,
                allchecked:true,
                money:price
            })
        }else{
            this.setState({
                medDetail:medDetail,
                allchecked:false,
                money:price
            })
        }
    }
    render() {
        const { orderDetail, medDetail,allchecked,evaluatePic,money, reasonContent, miaosu, files1, radioVal, reasons, visible } = this.state
        return (
            <div>
                <div className='lingshou-shou'>
                    <div className='pifa-shou'>
                        <div className='shou-top'>
                            <img src={fanhui} onClick={() => this.fanhui1()} alt="" />
                            <p>申请售后</p>
                        </div>
                        <div className='pifa-dingdan'>
                            <p className='goods-xg'>特殊商品退款</p>
                            <p className='goods-sh'>因为药品为特殊商品，请合理申请商家才会同意退款</p>
                            <p className='goods-sh'>商家同意后，用户需将商品退还至商家后，退款才可退回账户</p>
                            <h2>{orderDetail.pharmacyName} &gt;</h2>
                            {medDetail.length == 0 ? null :
                                <>
                                    {medDetail.map((item, index) => (
                                        <div key={index} className='pifa-order-meditem'
                                            onClick={() => this.toMedicine(item.medId)}>
                                            <div className='flexr-med'>
                                                {item.medQuantity1>0?<CheckboxItem checked={item.checked} onClick={(e)=>this.oneChecked(e,item.medId,index)}></CheckboxItem>:
                                                <input type="radio" disabled style={{marginLeft:'10px',marginTop:'30px'}}/>}
                                                <div className='order-meditem-img'>
                                                    <img src={item.medClassify === "1" ? zhezaho : (item.medPhoto ? item.medPhoto : defaultImg)} alt=''
                                                        onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} />
                                                    {item.medState == 2 ? <p>申请退货数x{item.medRefundQuantity}</p> : null}
                                                </div>
                                                <div className='order-meditem-lishou'>
                                                    <span className='name'>
                                                        {item.medClassify === '1' ? <span className='chufang'>处方药</span> : null}
                                                        {item.medName}
                                                    </span>
                                                    <span className='l'><span>{item.medSpec}</span></span>
                                                    <span className='r'>
                                                        <span>x{item.medQuantity}</span>
                                                        <span style={{marginLeft:'8px',color:'#aaa'}}>￥{(item.medPrice/100).toFixed(2)}</span>
                                                        <span style={{marginLeft:'8px',color:'#ff0000'}}>￥{(item.nowPrice/100).toFixed(2)}</span>
                                                    </span>
                                                </div>
                                                {item.medQuantity1>0?<p className='count-meditem-lishou'>
                                                    <span className='jian' onClick={()=>this.jian(item.medQuantity1,item.medId)}>-</span>
                                                    <span className='count'>{item.medQuantity1}</span>
                                                    <span className='jia' onClick={()=>this.jia(item.medQuantity,item.afterSalesQuantity,item.medId)}>+</span>
                                                </p>:
                                                <p className='count-meditem-lishou'>
                                                    <span className='jian' style={{backgroundColor:'#aaa'}}>-</span>
                                                    <span className='count' style={{color:'#aaa'}}>{item.medQuantity1}</span>
                                                    <span className='jia' style={{backgroundColor:'#aaa'}}>+</span>
                                                </p>}
                                            </div>
                                            <div className='xian'></div>
                                        </div>
                                    ))}
                                    <p className='all-money'>
                                        <CheckboxItem checked={allchecked} onClick={(e)=>this.allChecked(e)}>全选</CheckboxItem>
                                        <p>退款金额 ￥{(money/100).toFixed(2)}</p>
                                    </p>
                                </>}
                        </div>
                        <div className='selsect-reason'>
                            <span className='selsect-reason-left'>{reasonContent ? reasonContent : '请选择申请售后原因'}<span style={{color:"#ff0000",marginLeft:'5px'}}>*</span></span>
                            <span className='gt' onClick={() => this.smRreasons()}>{'>'}</span>
                        </div>
                        <div className='reason-pic'>
                            {/* <p className='shuoming'>补充详细退款原因，有利于商家更快的帮您解决</p> */}
                            <div className='upvoucher-in'>
                                <textarea className='miaoshu' placeholder='补充详细退款原因，有利于商家更快的帮您解决' maxlength="100" value={miaosu} name='textarea' cols={28} rows={6} onChange={(v) => this.fuzhi(v)}></textarea>
                                <span style={{ position: 'absolute', left: '320px', marginTop: '60px' }}>
                                    <span>{miaosu.length}</span>
                                    <span>/</span>
                                    <span>100</span>
                                </span>
                                <ImagePicker
                                    files={files1}
                                    length={4}
                                    onChange={this.imgChange1}
                                    onImageClick={(index, fs) => console.log(index, fs)}
                                    selectable={files1.length < 4}
                                    accept='image/gif,image/jpeg,image/jpg,image/png'
                                />
                                <p style={{ marginTop: '-8px' }}>(最多上传4张图片)</p>
                            </div>
                        </div>
                        <div className='tijiao-shenqing' onClick={() => this.tijiao()}>
                            <p className='word'>
                                <img src={tuikuanleft} alt="" />
                                <p>退款金额 ￥{(money/100).toFixed(2)}</p>
                                <p>退款成功将退回到支付账户中</p>
                            </p>
                            <p className='img'>
                                <img src={tuikuanright} alt="" />
                                <span>申请退款</span>
                            </p>
                        </div>
                    </div>
                    {visible == 'block' ?
                        <div className='refund-reasons'>
                            <p className='refund-reasons-title'>退款申请原因</p>
                            {/*<List className='popup-list'>*/}
                            {/*    <div className='select'>请选择申请售后原因</div>*/}
                            {/*    {reasons.map((item, index) => (*/}
                            {/*        <RadioItem key={index}*/}
                            {/*            checked={radioVal === item}*/}
                            {/*            onChange={() => {*/}
                            {/*                this.setState({*/}
                            {/*                    radioVal: item*/}
                            {/*                })*/}
                            {/*                if (item === '其它原因') {*/}
                            {/*                    this.setState({*/}
                            {/*                        reasonContent: ''*/}
                            {/*                    })*/}
                            {/*                } else {*/}
                            {/*                    this.setState({*/}
                            {/*                        reasonContent: item*/}
                            {/*                    })*/}
                            {/*                }*/}
                            {/*            }}>*/}
                            {/*            {item}*/}
                            {/*        </RadioItem>*/}
                            {/*    ))}*/}
                            {/*    {radioVal === '其它原因' ?*/}
                            {/*        <TextareaItem*/}
                            {/*            placeholder='请输入售后原因'*/}
                            {/*            rows={1}*/}
                            {/*            count={100}*/}
                            {/*            onChange={(val) => this.setState({*/}
                            {/*                reasonContent: val*/}
                            {/*            })} /> : null*/}
                            {/*    }*/}
                            {/*</List>*/}
                            {/*<div className='sure' onClick={() => this.sureReason()}>确定</div>*/}
                            {/*<div className='quxiao' onClick={() => this.setState({*/}
                            {/*                    visible: 'none'*/}
                            {/*                })}>取消</div>*/}
                            {reasons.map((item, index) => (
                                <>
                                    <div key={index} className='refund-reasons-all'>
                                        <div>{item}</div>
                                        <input type="radio" name='b' checked={radioVal === item} onChange={() => {
                                            // const commit = document.getElementsByClassName('commit')[0];
                                            // commit.style.background = "#1F87FF"
                                            this.setState({
                                                radioVal: item
                                             })
                                            if (item === '其它原因') {
                                                this.setState({
                                                    reasonContent: ''
                                                })
                                            } else {
                                                this.setState({
                                                    reasonContent: item
                                                })
                                            }
                                        }}/>
                                    </div>
                                </>
                            ))}
                            {radioVal === '其它原因' ?
                                <TextareaItem
                                    placeholder='请输入退款原因'
                                    rows={1}
                                    count={100}
                                    onChange={(val) =>
                                        this.setState({
                                        reasonContent: val
                                    })} /> : null
                            }
                            <div className='commit' onClick={this.sureReason}>确定</div>
                            <div  className='commit2' onClick={() => {
                                this.setState({
                                    visible: 'none' ,
                                    reasonContent:'',
                                    radioVal:''
                                })
                            }}>取消</div>
                        </div>
                        : null}
                    {/* {visible1=='block'?<div className='del-address'>
                        <p className='del-sure'>是否确认取消售后？</p>
                        <p className='del-bottom'>
                            <span onClick={()=>setVisible1('none')}>取消</span>
                            <span onClick={()=>onSure()}>确认</span>
                        </p>
                    </div>:null} */}
                </div>
            </div>
        )
    }
}