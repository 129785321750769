import React from 'react'
import {NavLink} from "react-router-dom"
import './style.less'
import '../../static/css/iconfont.css'
import {cartGoodslist} from "../../api/request/cart"

export default class FootNav extends React.Component{
    constructor(props){
        super(props);
        this.state={
            cartnum:null,
            userId :localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3,
            pharmacyId:localStorage.type=="1"?1:localStorage.type=="2"?1:3
        }
    }
    componentDidMount=async()=>{
        //console.log(this.props.onRef);
        if (this.props.onRef) {
            this.props.onRef(this)
        }
       
        this.getarrlist()
        // const {userId,pharmacyId}=this.state
        // const res=await cartlistRequest(userId,pharmacyId)
        // this.setState({
        //     cartnum:res.data[0].length
        // })
    }
    getarrlist=async()=>{
        const {userId,pharmacyId}=this.state
        const res=await cartGoodslist(localStorage.userId1,pharmacyId)
        this.setState({
            cartnum:res.data[0].length
        },()=>console.log(this.state.cartnum))
    }
    render(){
        const {cartnum}=this.state
        return(
            <div className="nav-footer">
                {/* <ul className="clear-fix">
                    <li>
                        <NavLink exact to={`${localStorage.env==='single'?`/?env=single&pharmacyId=${localStorage.pharmacyId}`:'/'}`} activeClassName="selectednav">
                            <i className="iconfont icon-shouye"></i>
                            首页
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/classify" activeClassName="selectednav">
                            <i className="iconfont icon-fenlei"></i>
                            分类
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/health" activeClassName="selectednav">
                            <i className="iconfont icon-hua"></i>
                            健康圈
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/shopcart" activeClassName="selectednav">
                            <i className="iconfont icon-gouwuchekong"></i>
                            购物车
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/mine" activeClassName="selectednav">
                            <i className="iconfont icon-wode"></i>
                            我的
                        </NavLink>
                    </li>
                </ul> */}
                <div className='nav-footer-tab'>
                    <NavLink exact to={`${localStorage.env==='single'?`/?env=single&pharmacyId=${localStorage.pharmacyId}`:'/'}`} className='nav-a' activeClassName="selectednav nav-1">
                        <div></div>
                        <span>首页</span>
                    </NavLink>
                </div>
                <div className='nav-footer-tab'>
                    <NavLink exact to="/classify" className='nav-b' activeClassName="selectednav nav-2">
                        <div></div>
                        <span>分类</span>
                    </NavLink>
                </div>
                <div className='nav-footer-inner'>
                    <NavLink exact to="/health">
                        <div className='nav-3'></div>
                    </NavLink>
                </div>
                <div className='nav-footer-tab'>
                    <NavLink exact to="/shopcart"  className='nav-d' activeClassName="selectednav nav-4">
                        <p className="nav-footer-tab-p">{cartnum}</p>
                        <div></div>
                        <span>购物车</span>
                    </NavLink>
                </div>
                <div className='nav-footer-tab'>
                    <NavLink exact to="/mine"  className='nav-e' activeClassName="selectednav nav-5">
                        <div></div>
                        <span>我的</span>
                    </NavLink>
                </div>
            </div>
        )
    }
}