import React, { useRef, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Toast, Popup, setDefaultConfig } from "antd-mobile-v5";

// import Goods from "../../../component/Goods/index";
import Swiper from "../../../component/Swiper/index2";
import Table from "../../../baseui/table/index";

import { resetdirect } from "../../../redux/user.redux";

import { getCoupon } from "../../../api/request/med";
import {
  addcollectRequest,
  delCollectionMed,
} from "../../../api/request/fllow";
import { addBrowsingRequest } from "../../../api/request/history";

import { userGetCoupon } from "../../../api/request/coupon";
import { medDiscount } from "../../../api/request/med";

import {
  addcartRequest,
  updatecartRequest,
  querycartRequest,
  cartlistRequest,
  cartGoodslist,
  delCartRequest,
  allFreights,
} from "../../../api/request/cart";
import getUrl from "../../../component/GetUrlParam";
import { wxConfig } from "../../../component/scan/index";
import Evaluate from "../../evaluate";
import "./orderstyle.less";
import "../../../static/css/iconfont.css";
import { medcommentRequest } from "../../../api/request/comment";
import defaultImg from "../../../static/img/default.png";
import juanImg from "../../../static/img/juan.png";
import prescriptionImg from "../../../static/img/rx.jpg";
import { snapshotMedDetail } from "../../../api/request/order";
import { useLocation, withRouter } from "react-router-dom";
import Nodata from "../../../component/Nodata";
import Head from "../../../component/Head";
function Medcine(props) {
  const env = localStorage.env || getUrl("env", props.location.search);
  const [userId, setuserId] = useState("");
  const userId1 = localStorage.getItem("userId1");
  const userId2 = localStorage.getItem("userId2");
  const scrollTop = getUrl("scrollTop", props.location.search);
  const page = getUrl("page", props.location.search);
  const shop = getUrl("shop", props.location.search);
  const title = getUrl("title", props.location.search);
  const home = getUrl("home", props.location.search);
  const shopcart = getUrl("shopcart", props.location.search);
  const morestore = getUrl("morestore", props.location.search);
  const categoryId0 = getUrl("categoryId0", props.location.search);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderNumber = query.get("orderno");
  const medId = query.get("mid");
  const categoryName = localStorage.getItem("categoryName");
  const type = localStorage.type;
  useEffect(() => {
    switch (localStorage.type) {
      case "1":
        setuserId(localStorage.userId1);
        return;
      case "2":
        setuserId(localStorage.userId2);
        return;
      case "3":
        setuserId(localStorage.userId3);
        return;
    }
  }, []);
  // console.log(type);
  //const userId = localStorage.userId
  //   const medId = props.match.params.medId;
  const userVip = localStorage.userVip;
  // const address = localStorage.address
  const tablist = [
    { id: "med-introduce", name: "商品" },
    // { id: "med-comment", name: "评价" },
    { id: "med-detail", name: "详情" },
  ];
  const [isFollow, setIsFollow] = useState(false);
  const [dataLoading, setDataLoad] = useState(false);
  const [evaluate, setEvaluate] = useState(false);
  const [collectionId, setCollectId] = useState(0);
  const [medDetail, setMedDetail] = useState(null);
  const [pharmacyId, setPharmacyId] = useState(null);
  const [pharmacy, setPharmacy] = useState(null);
  const [recommend, setRecommend] = useState([]);
  const [medComment, setMedComment] = useState([]);
  const [nowIndex, setNowIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [startTime, setstartTime] = useState(0); //营业时间
  const [endTime, setendTime] = useState(0); //打样时间
  const [allprice, setAllprice] = useState(0);
  const [stockNum, setstockNum] = useState(0);
  const [promoteStu, setpromoteStu] = useState("0");
  const [weeks, setWeeks] = useState();
  const [visible1, setvisible1] = useState(false);
  const [flag, setflag] = useState(false);
  const [couponDetails, setcouponDetails] = useState([]); //优惠券
  const [usercouponDetails, setusercouponDetails] = useState([]); //用户已领取的优惠券
  const [isNewUser, setisNewUser] = useState();
  const [activities, setActivities] = useState([]); //满减
  const [discount, setDiscount] = useState([]); //折扣商品
  const [visible, setVisible] = useState("none");

  // const [startY, setStartY] = useState(0)
  const [refresh, setRefresh] = useState(false);
  const headerRef1 = useRef();
  const headerRef2in1 = useRef();
  // const headerRef2in2 = useRef()
  const anchorPoint = useRef();

  const { redirectDispatch } = props;
  // useEffect(()=>{
  //     cartDeteils()
  // },[userId])
  useEffect(() => {
    queryMedDetail();
    if (userId) {
      addBrowsingRequest({ userId: userId, medId: medId }).then((res) => {
        console.log(res);
      });
    }
  }, [medId, userId]);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  let nowtime00 = new Date();
  let timecha = nowtime00.getHours() * 60 + nowtime00.getMinutes();
  const queryMedDetail = async () => {
    const res = await Promise.all([
      snapshotMedDetail({
        orderNumber: orderNumber,
        medId: medId,
      }),
      medcommentRequest({
        medId: medId,
        userId: userId,
        star: [1, 2, 3, 4, 5],
      }),
    ]);
    console.log(res[0], "查看交易快照详情");
    if (res[0].code === 1 && res[0].data[0].length > 0) {
      if (res[0].data[4].length) {
        res[0].data[4][0].activitiesCycle = JSON.parse(
          res[0].data[4][0].activitiesCycle
        );
        res[0].data[4][0].activitiesDiscount = JSON.parse(
          res[0].data[4][0].activitiesDiscount
        );
        if (res[0].data[4][0].activitiesType == 1 && res[0].data[5].length) {
          setActivities(res[0].data[4][0]);
        } else if (res[0].data[4][0].activitiesType == 2) {
          setActivities(res[0].data[4][0]);
        } else {
          setActivities([]);
        }
      }
      let data1 = res[0].data[0][0];
      let data2 = res[0].data[1];
      let data3 = res[0].data[2];
      let data4 = res[0].data[3];
      console.log(res[0]);
      setpromoteStu(data1.promoteStu);
      setstartTime(
        parseInt(data1.pharmacyStartTime.substring(0, 2) * 60) +
          parseInt(data1.pharmacyStartTime.substring(3, 5))
      );
      setendTime(
        parseInt(data1.pharmacyEndTime.substring(0, 2) * 60) +
          parseInt(data1.pharmacyEndTime.substring(3, 5))
      );
      // data1.medPiclist = data1.medPiclist ? data1.medPiclist.split(',') : [defaultImg]
      data1.medPiclist = data1.medPiclist
        ? data1.medClassify === "1"
          ? [prescriptionImg]
          : data1.medPiclist.split(",")
        : [defaultImg];
      // console.log(data1,"!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // data1.activitiesCycle = JSON.parse(data1.activitiesCycle)
      // data1.activitiesDiscount = JSON.parse(data1.activitiesDiscount)
      res[0].data[0][0].discountPrice = 0;
      medDiscount({ medId }).then((res) => {
        console.log(res);
        if (res.data[1]) {
          setDiscount(res.data[1]);
        }
      });
      setMedDetail(data1);
      setPharmacyId(data1.pharmacyId);
      if (data4 && data4[0]) {
        setstockNum(data4[0].stockNum);
      }

      document.title = data1.pharmacyName;
      setPharmacy(data3 && data2.length > 0 ? data2[0] : null);
      setRecommend(data3 && data3.length > 0 ? data3 : data2);
      const sareParam = {
        title: data1.medName,
        desc: data1.medName + " " + data1.medSpec,
        link: encodeURIComponent(document.URL),
        imgUrl: data1.medPhoto,
      };
      wxConfig("share", sareParam);
    }

    if (res[1].code === 1) {
      let data = res[1].data;
      if (data[0].length > 0) {
        data[0].forEach((item) => {
          item.evaluatePic = item.evaluatePic
            ? item.evaluatePic.split(",")
            : [];
        });
      }
      // console.log(data);
      setMedComment(data);
      setIsFollow(res[1].data[5].length === 0 ? false : true);
      setCollectId(
        res[1].data[5].length === 0 ? 0 : res[1].data[5][0].collectionId
      );
    }
    setDataLoad(true);
  };
  // 折扣商品
  useEffect(() => {
    let state = false;
    let price = 0;
    if (discount && discount.length && weeks) {
      console.log(discount, weeks);
      discount[0].discountCycle = JSON.parse(discount[0].discountCycle);
      discount[0].discountCycle.map((item) => {
        if (item == weeks) {
          state = true;
        }
      });
      if (state) {
        if (discount[0].percentage == -1) {
          price = discount[0].discountPrice;
        } else {
          price = (discount[0].originPrice * discount[0].percentage) / 10;
        }
        if (medDetail) {
          medDetail.discountPrice = price; //折扣商品价格
          setMedDetail(medDetail);
        }
      } else {
        setDiscount([]);
      }
    }
  }, [discount, weeks]);

  // console.log(stockNum);
  // 收藏
  const fllowMedRequest = () => {
    if (userId) {
      const params = {
        // type: 1,
        userId: userId,
        medId: medId,
        pharmacyId: pharmacyId,
      };
      // console.log(params);
      // console.log(isFollow);
      if (isFollow) {
        const delparam = {
          userId: userId,
          medId: medId,
          pharmacyId: pharmacyId,
          // type: '2',
          // collectionId: collectionId
        };
        // console.log(delparam);
        // //删除收藏
        delCollectionMed(delparam).then((res) => {
          console.log(res);
          setIsFollow(false);
        });
      } else {
        // 收藏
        addcollectRequest(params).then((res) => {
          console.log(res);
          if (res.code === 1) {
            setCollectId(res.data[0][0]);
            setIsFollow(true);
          }
        });
      }
      setRefresh(true);
    } else {
      props.history.push("/login/login1");
    }
  };
  // 查询优惠劵
  const juanRequest = () => {
    // let arr1=[]
    // console.log(medDetail);
    // getCoupon(medDetail.pharmacyId,medDetail.medQrcode,userId).then(res=>{
    //     console.log(res);
    //     if (res.data[0].length||res.data[3].length) {
    //         if (res.data[0].length) {
    //             // 店铺通用优惠券
    //             res.data[0].map(item0=>{
    //                 item0.state=false //未领取
    //                 arr1.push(item0)
    //             })
    //         }
    //         if (res.data[3].length) {
    //             // 单个药品优惠券
    //             res.data[3].map(item1=>{
    //                 item1.state=false //未领取
    //                 arr1.push(item1)
    //             })
    //         }
    //         if (arr1.length) {
    //             arr1.map(item0=>{
    //                 // 已领取的优惠券
    //                 if (res.data[1].length) {
    //                     res.data[1].map(item1=>{
    //                         if (item1.couponManagerId==item0.couponManagerId) {
    //                             item0.state=true
    //                         }
    //                     })
    //                 }
    //             })
    //         }
    //         console.log(arr1);
    //         setcouponDetails(arr1)//所有优惠券
    if (userId) {
      setvisible1(true);
      cartDeteils();
    } else {
      props.history.push("/login/login1");
    }
    //     }else{
    //         Toast.show("暂无优惠券")
    //     }
    //     setusercouponDetails(res.data[1])//已领取优惠券
    //     setisNewUser(res.data[2][0].isNewUser)//是否新用户
    // })
  };
  // 领取优惠券
  const getCoupon1 = (item) => {
    console.log(item);
    if (item.couponNumber > 0) {
      let params = {
        userId,
        couponManagerId: item.couponManagerId,
        couponState: "1",
      };
      userGetCoupon(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          Toast.show("领取成功");
          let arr1 = [];
          console.log(medDetail);
          getCoupon(medDetail.pharmacyId, medDetail.medQrcode, userId).then(
            (res) => {
              console.log(res);
              if (res.data[0].length || res.data[3].length) {
                if (res.data[0].length) {
                  // 店铺通用优惠券
                  res.data[0].map((item0) => {
                    item0.state = false; //未领取
                    arr1.push(item0);
                  });
                }
                if (res.data[3].length) {
                  // 单个药品优惠券
                  res.data[3].map((item1) => {
                    item1.state = false; //未领取
                    arr1.push(item1);
                  });
                }
                if (arr1.length) {
                  arr1.map((item0) => {
                    // 已领取的优惠券
                    if (res.data[1].length) {
                      res.data[1].map((item1) => {
                        if (item1.couponManagerId == item0.couponManagerId) {
                          item0.state = true;
                        }
                      });
                    }
                  });
                }
                // console.log(arr1);
                setcouponDetails(arr1); //所有优惠券
                setvisible1(true);
              }
              // else {
              //     Toast.show("暂无优惠券")
              // }
              setusercouponDetails(res.data[1]); //已领取优惠券
              setisNewUser(res.data[2][0].isNewUser); //是否新用户
            }
          );
        }
      });
    } else {
      Toast.show("领取失败,本次优惠券已领取完");
    }
  };
  const cartDeteils = () => {
    cartlistRequest(userId, pharmacyId).then((res) => {
      setCount(res.data[0].length);
    });
    cartGoodslist(userId, medId, pharmacyId).then((res) => {
      if (res.data[0]) {
        res.data[0].map((item) => {
          if (item.medId == medId) {
            setCount1(item.medQuantity);
            setAllprice(
              Math.floor(((item.medQuantity * item.medPrice) / 100) * 100) / 100
            );
            setVisible("block");
          }
        });
      }
    });
  };
  useEffect(() => {
    console.log(medDetail);
    var tempDate = new Date();
    var days = tempDate.getDay();
    var week;
    switch (days) {
      case 1:
        week = "周一";
        break;
      case 2:
        week = "周二";
        break;
      case 3:
        week = "周三";
        break;
      case 4:
        week = "周四";
        break;
      case 5:
        week = "周五";
        break;
      case 6:
        week = "周六";
        break;
      case 0:
        week = "周日";
        break;
    }
    setWeeks(week);
    let arr1 = [];
    // console.log(medDetail);
    if (medDetail) {
      getCoupon(medDetail.pharmacyId, medDetail.medQrcode, userId).then(
        (res) => {
          console.log(res);
          if (res.data[0].length || res.data[3].length) {
            if (res.data[0].length) {
              // 店铺通用优惠券
              res.data[0].map((item0) => {
                item0.state = false; //未领取
                arr1.push(item0);
              });
            }
            if (res.data[3].length) {
              // 单个药品优惠券
              res.data[3].map((item1) => {
                item1.state = false; //未领取
                arr1.push(item1);
              });
            }
            if (arr1.length) {
              arr1.map((item0) => {
                // 已领取的优惠券
                if (res.data[1].length) {
                  res.data[1].map((item1) => {
                    if (item1.couponManagerId == item0.couponManagerId) {
                      item0.state = true;
                    }
                  });
                }
              });
            }
            // console.log(arr1);
            setcouponDetails(arr1); //所有优惠券
            // setvisible1(true)
          }
          // else {
          //     Toast.show("暂无优惠券")
          // }
          if (res.data[1].length) {
            setusercouponDetails(res.data[1]); //已领取优惠券
          }
          if (res.data[2].length) {
            setisNewUser(res.data[2][0].isNewUser); //是否新用户
          }
        }
      );
    }
  }, [medDetail, count]);
  useEffect(() => {
    if (activities && activities.activitiesCycle) {
      activities.activitiesCycle.map((item) => {
        if (weeks == item) {
          setflag(true);
        }
      });
    }
  }, [activities, weeks]);
  const buyNow = (num_package) => {
    // console.log(stockNum);
    if (userId) {
      if (num_package == 1) {
        if (stockNum == 0) {
          Toast.show({
            content: "库存为0，不能进行购买",
            duration: 800,
          });
        } else {
          let allDetails = [];
          allFreights(medDetail.pharmacyId).then((res0) => {
            console.log(res0);
            allDetails = res0.data[0][0];
            const cartArr = [
              {
                pharmacy: {
                  pharmacyId: medDetail.pharmacyId,
                  pharmacyName: medDetail.pharmacyName,
                  freeFreight: allDetails.freeFreight,
                  minFreight: allDetails.minFreight,
                  orderFreight: allDetails.orderFreight,
                },
                medList: [
                  {
                    medId: medDetail.medId,
                    medName: medDetail.medName,
                    medPhoto: medDetail.medPhoto,
                    medSpec: medDetail.medSpec,
                    medPacking: medDetail.medPacking,
                    medApproval: medDetail.medApproval,
                    medCompany: medDetail.medCompany,
                    medPrice: medDetail.medPrice,
                    medQrcode: medDetail.medQrcode,
                    medQuantity: 1,
                    cartPrice:
                      medDetail.medPrice * (num_package ? num_package : 1),
                    medApparatus: medDetail.medApparatus,
                    medValidity: medDetail.medValidity,
                    medEnterprise: medDetail.medEnterprise,
                    // activeId: activities.activeId,
                    // activeMedId: activities.activeMedId,
                    // activeStoreId: activities.activeStoreId,
                    // activitiesBanner: activities.activitiesBanner,
                    // activitiesCycle: activities.activitiesCycle,
                    // activitiesDiscount: activities.activitiesDiscount,
                    // activitiesDescribe: activities.activitiesDescribe,
                    // activitiesEndTime: activities.activitiesEndTime,
                    // activitiesKey: activities.activitiesKey,
                    // activitiesName: activities.activitiesName,
                    // activitiesStartTime: activities.activitiesStartTime,
                  },
                ],
              },
            ];
            props.history.push({ pathname: "/fillorder", state: cartArr });
          });
        }
      } else {
        const cartArr = [
          {
            pharmacy: {
              pharmacyId: medDetail.pharmacyId,
              pharmacyName: medDetail.pharmacyName,
              freight: medDetail.freight,
            },
            medList: [
              {
                medId: medDetail.medId,
                medName: medDetail.medName,
                medPhoto: medDetail.medPhoto,
                medSpec: medDetail.medSpec,
                medPacking: medDetail.medPacking,
                medApproval: medDetail.medApproval,
                medCompany: medDetail.medCompany,
                medQrcode: medDetail.medQrcode,
                medPrice: medDetail.medPrice,
                medQuantity: num_package ? num_package : 1,
                cartPrice: medDetail.medPrice * (num_package ? num_package : 1),
                medApparatus: medDetail.medApparatus,
                medValidity: medDetail.medValidity,
                medEnterprise: medDetail.medEnterprise,
                // activeId: activities.activeId,
                // activeMedId: activities.activeMedId,
                // activeStoreId: activities.activeStoreId,
                // activitiesBanner: activities.activitiesBanner,
                // activitiesCycle: activities.activitiesCycle,
                // activitiesDiscount: activities.activitiesDiscount,
                // activitiesDescribe: activities.activitiesDescribe,
                // activitiesEndTime: activities.activitiesEndTime,
                // activitiesKey: activities.activitiesKey,
                // activitiesName: activities.activitiesName,
                // activitiesStartTime: activities.activitiesStartTime,
              },
            ],
          },
        ];
        props.history.push({ pathname: "/fillorder", state: cartArr });
      }
    } else {
      redirectDispatch(props.location.pathname);
      props.history.push("/login/Login1");
    }
  };
  // console.log(medDetail);
  const addShopCart = () => {
    if (userId) {
      if (stockNum > 0) {
        const params = {
          userId: userId,
          pharmacyId: medDetail.pharmacyId,
          medId: medId,
          medQuantity: 1,
        };
        querycartRequest(userId, medId).then((res) => {
          console.log(res);
          if (res.code === 1 && res.data[0].length === 0) {
            addcartRequest(params).then((res1) => {
              if (res1.code === 1) {
                setAllprice(Math.floor((medDetail.medPrice / 100) * 100) / 100);
                Toast.show({
                  content: "加入成功",
                  duration: 800,
                });
                cartlistRequest(userId, pharmacyId).then((res) => {
                  setCount(res.data[0].length);
                });
              }
            });
          } else if (res.code === 1 && res.data[0].length > 0) {
            // console.log(count1,stockNum);
            // console.log(userId);
            if (type == "1" && count1 == stockNum) {
              Toast.show({
                content: "库存只剩" + stockNum + "，无法继续加购",
                duration: 800,
              });
              return;
            }
            let data = res.data[0][0];
            const quantity = data.medQuantity + 1;
            updatecartRequest(quantity, data.cartId).then((res1) => {
              console.log(res1);
              if (res1.code === 1) {
                setAllprice(
                  Math.floor(((quantity * medDetail.medPrice) / 100) * 100) /
                    100
                );
                Toast.show({
                  content: "加入成功",
                  duration: 800,
                });
                cartGoodslist(userId, medId, pharmacyId).then((res2) => {
                  console.log(res2);
                  if (res2.data[0]) {
                    res2.data[0].map((item) => {
                      if (item.medId == medId) {
                        setCount1(item.medQuantity);
                        setVisible("block");
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        Toast.show({
          content: "库存为0",
          duration: 800,
        });
      }
    } else {
      redirectDispatch(props.location.pathname);
      props.history.push("/login/Login1");
    }
  };
  const backPage = () => {
    console.log(scrollTop);
    if (scrollTop && title == 1) {
      props.history.push(
        `/medlist/${medDetail.categoryId}/0/${categoryName}?scrollTop=${scrollTop}&page=${page}&home=${home}&categoryId0=${categoryId0}`
      );
    } else if (scrollTop && title == 2) {
      props.history.push(
        `/medlist/0/0/${categoryName}?scrollTop=${scrollTop}&page=${page}&title=${2}&home=${home}`
      );
    } else if (scrollTop && home == 1) {
      props.history.push(`/?scrollTop=${scrollTop}`);
    } else if (scrollTop && shop) {
      props.history.push(
        `/shop/${pharmacyId}?scrollTop=${scrollTop}&page=${page}`
      );
    } else if (scrollTop && morestore) {
      props.history.push(
        `/shop/${pharmacyId}?scrollTop=${scrollTop}&page=${page}&morestore=${morestore}`
      );
    } else if (shopcart) {
      props.history.push(`/shopcart`);
    } else {
      window.history.back();
    }
  };

  const handleScroll = useCallback((pos) => {
    let minScrollY = -50;
    let percent = Math.abs(pos.y / minScrollY);
    let headerDom1 = headerRef1.current;
    let headerDom2in1 = headerRef2in1.current;
    // let headerDom2in2 = headerRef2in2.current
    if (pos.y > minScrollY && pos.y < 0) {
      headerDom1.style.opacity = Math.min(1, percent);
      headerDom2in1.style.color = "#FFFFFF";
      headerDom2in1.style["background-color"] = "#C2C5CB";
      // headerDom2in2.style.color = '#FFFFFF'
      // headerDom2in2.style['background-color'] = '#C2C5CB'
    } else if (pos.y < minScrollY && pos.y < 0) {
      headerDom1.style.opacity = 1;
      headerDom2in1.style["background-color"] = "#FFFFFF";
      headerDom2in1.style.color = "#0B1523";
      // headerDom2in2.style['background-color'] = '#FFFFFF'
      // headerDom2in2.style.color = '#0B1523'
    }
  }, []);

  const movetotab = (name, index) => {
    if (name) {
      setNowIndex(index);
      // 找到锚点
      let anchorElement = document.getElementById(name);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        if (anchorElement.offsetTop === 220) {
          anchorPoint.current.scrollTo(0, 0);
        } else {
          anchorPoint.current.scrollTo(0, anchorElement.offsetTop - 100);
        }
      }
      setRefresh(true);
    }
  };

  const onscroll = (e) => {
    let scrollTop = e.target.scrollTop;
    let minScrollY = -50;
    let percent = Math.abs(scrollTop / minScrollY);
    let headerDom1 = headerRef1.current;
    let headerDom2in1 = headerRef2in1.current;
    // let headerDom2in2 = headerRef2in2.current
    if (scrollTop > 5) {
      headerDom1.style.opacity = Math.min(1, percent);
      headerDom2in1.style["background-color"] = "#FFFFFF";
      headerDom2in1.style.color = "#0B1523";
      // headerDom2in2.style['background-color'] = '#FFFFFF'
      // headerDom2in2.style.color = '#0B1523'
    } else if (scrollTop < 5) {
      headerDom1.style.opacity = 0;
      headerDom2in1.style.color = "#FFFFFF";
      headerDom2in1.style["background-color"] = "#C2C5CB";
      // headerDom2in2.style.color = '#FFFFFF'
      // headerDom2in2.style['background-color'] = '#C2C5CB'
    }
    if (scrollTop > 120 && scrollTop <= 480) {
      setNowIndex(0);
    }
    if (scrollTop > 480 && scrollTop <= 980) {
      setNowIndex(1);
    }
    if (scrollTop > 980) {
      setNowIndex(2);
    }
  };

  const evaluateDetail = () => {
    // props.history.push(`/evaluate/${medId}`)
    setEvaluate(!evaluate);
    setRefresh(true);
  };
  console.log(tablist);

  const RenderHeader = () => {
    return (
      <div id="med-header">
        <div className="med-header-icon flex-apart-row">
          <div
            className="round-icon flex-row"
            onClick={backPage}
            ref={headerRef2in1}
          >
            <i className="icon-xiangzuo iconfont"></i>
          </div>
          {/* <div className='round-icon flex-row' ref={headerRef2in2}>
                        <i className='icon-fenxiang iconfont'></i>
                    </div> */}
        </div>

        <div className="header-tab " ref={headerRef1}>
          {tablist.map((v, index) => {
            return (
              <div key={index} onClick={() => movetotab(v.id, index)}>
                <span>{v.name}</span>
                {index === nowIndex ? (
                  <div className="active-line"></div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  // console.log(medDetail);
  const RenderBrief = () => {
    return (
      <>
        <div id="med-introduce">
          <div className="med-brand flexc">
            <div className="med-brand-fee flexr">
              {type == "1" ? (
                <>
                  {medDetail ? (
                    <>
                      {discount[0] && discount[0].discountId ? (
                        <>
                          {discount[0].percentage == -1 ? (
                            <p className="discount-img">
                              ￥{discount[0].discountPrice / 100}
                              <span>￥{medDetail.medPrice / 100}</span>
                            </p>
                          ) : (
                            <p className="discount-img">
                              ￥
                              {(
                                (discount[0].originPrice *
                                  discount[0].percentage) /
                                10 /
                                100
                              ).toFixed(2)}
                              <span>￥{medDetail.medPrice / 100}</span>
                            </p>
                          )}
                          <p className="discount-hui">
                            {discount[0].percentage == -1 ? (
                              <p>立减优惠</p>
                            ) : (
                              <p>折扣优惠</p>
                            )}
                            <p>
                              {discount[0].percentage != -1 ? (
                                <span style={{ marginRight: "5px" }}>
                                  {discount[0].percentage}折
                                </span>
                              ) : null}
                              {discount[0].discountLimits == -1 ? (
                                <span>不限购</span>
                              ) : (
                                <span>限{discount[0].discountLimits}份</span>
                              )}
                            </p>
                          </p>
                        </>
                      ) : (
                        <p>￥{medDetail.medPrice / 100}</p>
                      )}
                    </>
                  ) : (
                    0
                  )}
                </>
              ) : null}

              {type == "2" ? (
                <p>
                  ￥
                  {medDetail.toB === 1 && !userId2
                    ? "登录可见"
                    : medDetail.medPrice / 100}
                </p>
              ) : null}
              {medDetail.medPrice < medDetail.originPrice &&
              (!discount[0] || !discount[0].discountId) ? (
                <p className="price">
                  ￥
                  {medDetail.toB === 1 && !userId
                    ? ""
                    : medDetail.originPrice / 100}
                </p>
              ) : null}
              {!userVip || userVip === "0" ? null : <div>Lv{userVip}会员</div>}
            </div>
            <div className="yaopin-all">
              <div className="yaopin-name">
                {medDetail.medClassify == "1" ? (
                  <span className="chufang">Rx</span>
                ) : null}
                <span className="xiangqing">
                  {medDetail.medName} {medDetail.medSpec}
                </span>
              </div>
              {/* {!userId ? null : */}
              <div className="med-brand-title flex-apart-row">
                <div
                  className={isFollow ? "collected" : ""}
                  onClick={() => fllowMedRequest()}
                >
                  <i
                    className={`${
                      isFollow ? "icon-xingxing" : "icon-tuanduicankaoxian-"
                    } iconfont med-collet`}
                  ></i>
                  <p>收藏</p>
                </div>
              </div>
              {/* } */}
            </div>
          </div>
          {medDetail.medClassify === "1" ? (
            <div className="recipel-warn">
              处方药须凭处方在药师指导下购买和使用
            </div>
          ) : null}
          {/* <div className='med-vip flex-apart-row'
                    onClick={()=>Toast.info('管理员未开放', 1)}>
                        <span>开通VIP可以优惠￥6.00</span>
                        <span>立即开通</span>
                    </div> */}
          {/* {medDetail.toB===1?<div className='med-tab3'>
                        <p>件量:</p>
                        <span>{medDetail.num_package}个</span>
                    </div>:''} */}
          {/* {medDetail.medIf===0?<div className='med-tab3'>
                        <p>主治</p>
                        <span>{medDetail.medExplain}</span>
                    </div>:''} */}
          {/* <div className='code-factory flex-apart-row'>
                        <p>{medDetail.medApproval}</p>
                        {medDetail.medIf===0?<span>{medDetail.medCompany}</span>:null}
                    </div> */}
        </div>
        {/* <div id="med-specs">
          <div className="med-tab2">
            <p>优惠</p>
            {flag ? (
              <span className="have-youhui">
                {activities.activitiesDiscount &&
                  activities.activitiesDiscount.map((item) => (
                    <span>
                      满{item.full / 100}减{item.minus / 100}
                    </span>
                  ))}
              </span>
            ) : (
              <span>无</span>
            )}
            {couponDetails.length ? (
              <img
                className="img"
                src={juanImg}
                onClick={() => juanRequest()}
                alt=""
              />
            ) : null}
          </div>
          {medDetail.medIf===0?<div className='med-tab2'>
                        <p>规格</p>
                        <span>{medDetail.medSpec}</span>
                    </div>:null}
          <div className='med-tab2'>
                        <p>运费</p>
                        <span>{medDetail.freight===0?'免运费':medDetail.freight}</span>
                    </div>
          <div className='med-tab2'>
                        <p>送至</p>
                        <span>{address===0?null:address}</span>
                    </div>
        </div> */}
        <div id="med-save">
          {medDetail.medIf === 0 ? (
            <div className="med-tab2">
              <p>规格</p>
              <span>{medDetail.medSpec}</span>
            </div>
          ) : null}
          <div className="med-tab2 code-factory flex-apart-row">
            <p>批准文号</p>
            <span>{medDetail.medApproval}</span>
          </div>
          <div className="med-tab2 sc">
            <p>生产厂家</p>
            {medDetail.medIf === 0 ? <span>{medDetail.medCompany}</span> : null}
          </div>
          {localStorage.type === "2" ? (
            <div className="med-tab2 sc">
              <p>库存</p>
              <span>{stockNum}</span>
            </div>
          ) : (
            ""
          )}
          {localStorage.type === "2" ? (
            <div className="med-tab2 guoqi">
              <p>有效期</p>
              <span>{medDetail.expiration}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <Popup
          visible={visible1}
          // className="popup"
          onMaskClick={() => {
            setvisible1(false);
          }}
          bodyStyle={{
            minHeight: "50vh",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            overflow: "scroll",
          }}
        >
          <div className="youhuijuan">
            <p className="weeks">优惠券在活动期间均可使用</p>
            {couponDetails.length &&
              couponDetails.map((item) => (
                <>
                  {item.state ? (
                    <div className="youhuiquan-nouse youhuiquan-nouse1">
                      <p className="money">
                        ￥<span>{item.couponLimit / 100}</span>
                      </p>
                      <p className="date-show">
                        <span className="date-show-top">
                          订单满{item.couponMoney / 100}元可使用
                        </span>
                        <span className="date-show-bottom">
                          {item.couponValidity} - {item.couponExpire}
                        </span>
                      </p>
                      <p className="quan-click"></p>
                    </div>
                  ) : (
                    <>
                      {item.couponUser == 2 ||
                      (isNewUser == 1 && item.couponUser == 1) ? (
                        <div className="youhuiquan-nouse">
                          <p className="money">
                            ￥<span>{item.couponLimit / 100}</span>
                          </p>
                          <p className="date-show">
                            <span className="date-show-top">
                              订单满{item.couponMoney / 100}元可使用
                            </span>
                            <span className="date-show-bottom">
                              {item.couponValidity} - {item.couponExpire}
                            </span>
                          </p>
                          <p
                            className="quan-click"
                            onClick={() => getCoupon1(item)}
                          >
                            点击领取
                          </p>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              ))}
          </div>
        </Popup>
      </>
    );
  };
  const RenderTips = () => {
    return (
      <div id="med-comment" className="flexc">
        <div>
          <p>
            1、医疗器械请仔细阅读产品说明书或者在医务人员的指导下购买和使用，禁忌内容或注意事项详见说明书；
          </p>
          <p>
            2、由于厂家不定期的更换产品包装，如遇新包装上市可能存在更新滞后，请已收到的实物包装为准
          </p>
        </div>
      </div>
    );
  };
  // console.log(medComment);
  //   const RenderComment = () => {
  //     return (
  //       <div id="med-comment" className="flexc">
  //         <div className="med-comment-title flex-apart-row">
  //           <div className="flex-row">
  //             <p>评价</p>
  //             <span>（{medComment[1][0].total}）</span>
  //           </div>
  //           <div className="flex-row" onClick={() => evaluateDetail()}>
  //             <span>查看更多</span>
  //             <i className="icon-xiangyou iconfont"></i>
  //           </div>
  //         </div>
  //         {/* <div className='med-comment-sort flexr'>
  //                     <div>全部</div>
  //                     <div>好评({medComment[4][0].great})</div>
  //                     <div>差评({medComment[2][0].bad})</div>
  //                 </div> */}
  //         {medComment[0].length === 0 ? null : (
  //           <div className="med-customer-commit flexc">
  //             <div className="customer-head flexr">
  //               <div className="customer-img">
  //                 {medComment[0][0].userAvatar ? (
  //                   <img src={medComment[0][0].userAvatar} alt="" />
  //                 ) : null}
  //               </div>
  //               <div className="customer-info flexc">
  //                 {medComment[0][0].userName ? (
  //                   <p>{medComment[0][0].userName.substr(0, 1) + "**"}</p>
  //                 ) : (
  //                   <p>
  //                     {medComment[0][0].userAcount.substr(0, 3) +
  //                       "****" +
  //                       medComment[0][0].userAcount.substr(7, 12)}
  //                   </p>
  //                 )}
  //                 <span>{medComment[0][0].createTime}</span>
  //               </div>
  //             </div>
  //             <p>{medComment[0][0].evaluateText}</p>
  //           </div>
  //         )}
  //         <div></div>
  //       </div>
  //     );
  //   };
  const RenderShop = () => {
    return (
      <div>
        <div id="med-belongshop" className="flexr" onClick={() => toPharmacy()}>
          <img src={medDetail.pharmacyPhoto} alt="" />
          <div>
            <p>{medDetail.pharmacyName}</p>
            <span>
              {pharmacy === null
                ? "0"
                : pharmacy.followNumber > 9999
                ? `${(pharmacy.followNumber / 10000).toFixed(1)}万`
                : pharmacy.followNumber}
              人关注
            </span>
          </div>
        </div>
        {/* <div id="med-belongshop-recommend">
          <p>店铺推荐</p>
          <Goods
            recommend={recommend}
            onclick={() => anchorPoint.current.scrollTo(0, 0)}
          />
        </div> */}
      </div>
    );
  };
  const RenderDetail = () => {
    return (
      <div id="med-desc" className="flexc ">
        <p>商品简介</p>
        <Table medDetail={medDetail} />
        {/* <span>商品详情</span>

        <div
          className="med-html-details"
          dangerouslySetInnerHTML={{ __html: medDetail.medDescribe }}
        /> */}
      </div>
    );
  };

  const toPharmacy = () => {
    if (env === "single") {
      props.history.push(`/?env=single&pharmacyId=${medDetail.pharmacyId}`);
    } else {
      props.history.push(`/shop/${medDetail.pharmacyId}`);
    }
  };

  const toDoctor = () => {
    // window.location.href='http://wz.sczyzk.com/#/user/home'
    // Toast.show({
    //     content:"该店铺暂未开启医师问诊服务",
    //     duration:800
    // })
    if (userId) {
      props.history.push(`/mine/privatePhysician`);
    } else {
      props.history.push("/login/login1");
    }
  };

  const toCart = () => {
    props.history.push("/shopcart");
  };
  // console.log(medDetail);
  return (
    <>
      {medDetail ? (
        <>
          {evaluate ? (
            <Evaluate
              onclick={evaluateDetail}
              userId={userId}
              medId={medId}
              medComment={medComment}
            />
          ) : (
            <div
              ref={anchorPoint}
              onScroll={onscroll}
              style={{
                width: "100%",
                height: "100vh",
                overflow: "auto",
              }}
            >
              {RenderHeader()}

              <div id="med-img">
                <Swiper
                  banners={dataLoading && medDetail ? medDetail.medPiclist : []}
                />
              </div>
              {dataLoading ? RenderBrief() : null}
              {dataLoading && medDetail.medApproval.indexOf("械") > -1
                ? RenderTips()
                : null}
              {/* {dataLoading ? RenderComment() : null} */}
              {dataLoading ? RenderShop() : null}
              {dataLoading ? RenderDetail() : null}
            </div>
          )}
          {medDetail && medDetail.recall == 2 ? (
            <div className="reback">
              <span>
                此药品已被召回，不能继续购买，若您已买此商品，建议您退回此商品
              </span>
            </div>
          ) : null}
          <div className="med-buy-tab">
            {medDetail && medDetail.medPrice == 0 ? (
              <div className="med-buy-btn">
                <div className="med-down">该药品已下架</div>
              </div>
            ) : (
              <>
                {medDetail && medDetail.recall == 2 ? (
                  <div className="med-buy-btn">
                    <div className="med-down">此产品已被召回</div>
                  </div>
                ) : (
                  <>
                    {(medDetail && timecha > startTime && timecha < endTime) ||
                      (promoteStu != "1" && (
                        <>
                          {medDetail && medDetail.medDelete != "1" && (
                            <div className="med-buy-btn">
                              <div className="med-down">该药品已下架</div>
                            </div>
                          )}
                        </>
                      ))}
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div>
          <Head title="交易快照" />
          <Nodata type="信息" />
        </div>
      )}
    </>
  );
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {
    redirectDispatch(data) {
      dispatch(resetdirect(data));
    },
  };
};

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(Medcine);
