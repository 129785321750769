import React, { Component } from 'react'
import "./style.less"
import {Popup,Rate, Tabs,Toast } from "antd-mobile-v5"
import { getPatientMember, delPatientMember,patientinfo} from "../../../api/request/wzpatient"
import { Modal } from 'antd-mobile';
import icon1 from "../../../static/img/fanhuihei.png"
import icon2 from "../../../static/img/bianji.png"
import icon3 from "../../../static/img/delete.png"
import Nodata from "../../../component/Nodata";

const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            visiable2:'none',
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            userlist: [],
            nowuser: null,
            date: null,
            contenttext: "",
            memberId:''
        }
    }
    componentDidMount = async () => {
        const { userId } = this.state
        if (!userId) {
          Toast.show({
              content:"当前未登录，请先登录",
              duration:1000
          })
          this.props.history.push("/login")
        }else{
            this.getuserlist()
        }
    }
    //获取就诊人信息列表
    getuserlist = () => {
        const myDate = new Date()
        const { userId } = this.state
        let a=localStorage.getItem('phone')
        console.log(a);
        let b
        if (a) {
            b=a.substring(1,a.length-1)
        }else{
            b=localStorage.getItem('userAcount1')
        }
        // 获取用药人id(问诊端)
        patientinfo({patientPhone:b}).then(res=>{
            console.log(res);
            if (res.code==1) {
                // 获取就诊人信息列表
                getPatientMember({
                    patientId:res.data.data[0][0].patientId
                }).then(res1=>{
                    console.log(res1);
                    let datalist = res1.data.data[0]
                    let nowuser = null
                    datalist.forEach((item) => {
                        if (item.patient_default != 0) {
                            console.log(item);
                            nowuser = item
                        }
                    })
                    console.log(nowuser);
                    this.setState({
                        userlist: res1.data.data[0],
                        date: myDate.getFullYear(),
                        nowuser
                    })
                })
            }
        })
    }
    
    gobank=()=>{
        this.props.history.push("/mine")
    }
    // 删除
    delOrder=(memberId)=>{
        console.log(memberId);
        this.setState({
            memberId:memberId,
            visiable2:'block'
        })
    }
    // 编辑
    toWrite=(e,item)=>{ 
        e.preventDefault()
        e.stopPropagation()
        console.log(item);
        this.props.history.push({
            pathname: "/mine/patient",
            params: {
                list0: item,
                patientManagement:1
            }
        })
    }
    // 删除用药人
    sureDel=()=>{
        const {memberId} = this.state
        delPatientMember({memberId}).then(res=>{
            console.log(res);
            if (res.code==1) {
                this.getuserlist()
                this.setState({
                    memberId:'',
                    visiable2:'none'
                })
            }
        })
    }
    render() {
        const { userlist,visiable2 }=this.state
        return (
            <div className='recordbox1'>
                <div className='box-top1'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>就诊人管理</h1>
                </div>
                <div className='box-content1'>
                    {userlist.length?userlist.map(item=>(
                        <div className='patient-outer'>
                            <p className='patient-outer-left'>
                                <p className='name'>{item.patient_name}</p>
                                <p>
                                    <span>{item.patient_sex} </span>
                                    <span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁 </span>
                                    <span>{item.patient_phone}</span>
                                </p>
                            </p>
                            <p className='patient-outer-right'>
                                <img src={icon2} onClick={(e) => this.toWrite(e,item)} alt="" />
                                <img src={icon3} onClick={() => this.delOrder(item.member_id)} />
                            </p>
                        </div>
                    )):<Nodata type='就诊人'/>}
                </div>
                <div className='xinzeng' onClick={() => {
                    this.props.history.push("/mine/patient")
                }}>新增就诊人</div>
                {visiable2=='block'?<div className='del-address'>
                    <p className='del-sure'>是否确认删除用药人？</p>
                    <p className='del-bottom'>
                        <span onClick={()=>this.setState({
                            visiable2:'none'
                        })}>取消</span>
                        <span onClick={()=>this.sureDel()}>确认</span>
                    </p>
                </div>:null}
            </div>
        )
    }
}
