import React ,{ useState, useRef,useEffect } from 'react'
import { oderdetailRequest,addPharmacyUser,addWzdanhao } from '../../../api/request/order'
import { wzpatientInfo,addDruguser,updateDruguser,getPatientMember,patientinfo,updatePatientMember,addPatientMember,
    isChatinfo,addChatinfo,getChatinfo,wzstate ,docterlist,updateDoctype,getDoctype,delPatientMember} from '../../../api/request/wzpatient'


import { docWenzhen } from '../../../api/request/home'
// import { DemoBlock } from 'demos'
import { InputItem, Checkbox, Toast } from 'antd-mobile'
import getUrl from '../../../component/GetUrlParam'
// import Button from '../../../baseui/fuctionbutton/index'
import Head from '../../../component/Head'
import fanhui from '../../../static/img/fanhui.png'
import jiantou from '../../../static/img/jiantou.png'
import jixu from '../../../static/img/jixu.png'
import bianji from '../../../static/img/bianji.png'
import sousuo from '../../../static/img/sousuo.png'
import del from '../../../static/img/del.svg'
import {wzUrl,baseUrl,baseUrl0} from "../../../publicurl"
import './style.less'
import { number } from 'prop-types'
const CheckboxItem = Checkbox.CheckboxItem

export default function Consultate(props){
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [wzStatus, setwzStatus] = useState(1)
    
    const [druName, setDruName] = useState(null)
    const [druName0, setDruName0] = useState(0)

    const [druIdcard, setDruIdcard] = useState(null)
    const [druIdcard0, setDruIdcard0] = useState(0)

    const [druPhone, setDruPhone] = useState(null)
    const [druPhone0, setDruPhone0] = useState(0)

    const [druHistory, setDruHistory] = useState('')
    const [jwHistory, setJwHistory] = useState('')
    const [jwHistory0, setJwHistory0] = useState(0)

    const [gmHistory, setGmHistory] = useState('')
    const [gmHistory0, setGmHistory0] = useState(0)

    const [jzHistory, setJzHistory] = useState('')
    const [jzHistory0, setJzHistory0] = useState(0)

    const [ganHistory, setGanHistory] = useState('')
    const [ganHistory0, setGanHistory0] = useState(0)

    const [shenHistory, setShenHistory] = useState('')
    const [shenHistory0, setShenHistory0] = useState(0)
    
    const orderNumber = getUrl('orderNumber', props.location.search)
    const userId1 = getUrl('userId', props.location.search)
    const pharmacyId = getUrl('pharmacyId', props.location.search)
    const storename = getUrl('storename', props.location.search)
    
    const [sexAndAge0, setsexAndAge] = useState()
    
    const [arr, setArr] = useState([])
    const [arr1, setArr1] = useState()
    const [patientId, setpatientId] = useState()
    const [type, setType] = useState(0)

    const [onSure, setonSure] = useState()
    const [array, setArray] = useState('')
    const [docterId, setdocterId] = useState()
    const [consultationId, setconsultationId] = useState([])
    const [patientPhone, setpatientPhone] = useState()
    const [patientAvatar, setpatientAvatar] = useState()
    const [patientName, setpatientName] = useState()

    const [pastHistoryNote, setpastHistoryNote] = useState('')
    const [allergicHistoryNote, setallergicHistoryNote] = useState('')
    const [familyGeneticHistoryNode, setfamilyGeneticHistoryNode] = useState('')
    
    const [druguserId, setdruguserId] = useState()

    const [druInformation, setDruInformation] = useState([])
    const [druIsused, setDruIsused] = useState('')
    const [visible0, setVisible0] = useState('none')

    const [visible1, setVisible1] = useState('none')
    const [visible2, setVisible2] = useState('none')
    const [visible3, setVisible3] = useState('none')
    const [visible4, setVisible4] = useState('none')
    const [visible5, setVisible5] = useState('none')
    const [visible6, setVisible6] = useState('none')
    const [visible7, setVisible7] = useState('none')
    const [visible8, setVisible8] = useState('none')
    const [visible9, setVisible9] = useState('none')//删除用药人

    const backPage=()=>{
        window.history.back()
    }
// console.log(localStorage.getItem('phone'));
    useEffect(() => {
        // let a=localStorage.getItem('phone')
        // console.log(a);
        let b
        // if (a) {
        //     b=a.substring(1,a.length-1)
        // }else{
            b=localStorage.getItem('userAcount1')
        // }
        getUserNews()
        if (b) {
            docWenzhen({patientPhone:b}).then(res=>{
                if (res.code==1) {
                    setpatientPhone(res.data.data[1][0].patientPhone)
                    setpatientAvatar(res.data.data[1][0].patientAvatar)
                    setpatientName(res.data.data[1][0].patientName)
                }
            })
        }
        console.log(props.location.pharmacyId);
    }, [])
    const getUserNews=()=>{
        // let a=localStorage.getItem('phone')
        // console.log(a);
        let b
        // if (a) {
        //     b=a.substring(1,a.length-1)
        // }else{
            b=localStorage.getItem('userAcount1')
        // }
        patientinfo({patientPhone:b}).then(res=>{
            console.log(res);
            if (res.data.data[0].length) {
                setpatientId(res.data.data[0][0].patientId)
               
                getPatientMember({patientId:res.data.data[0][0].patientId}).then(res1=>{
                    console.log(res1);
                    setArr(res1.data.data[0])
                    if (res1.data.data[0].length>0) {
                        res1.data.data[0].map(item=>{
                            if (item.patient_default==1) {
                                setArr1(item)
                                
                            }
                        })
                        setVisible7('block')
                    }
                })
            }
        })
    }
    // useEffect(() => {
    //     getChatinfo({docterId:27,userId:patientId}).then(res=>{
    //         console.log(res);
    //         setconsultationId(res.data.data[0][0].consultationId)
    //     })
    // }, [patientId])
  const  handleChange=(key,val)=>{
      let str = val.replace(/\s*/g,"");
      console.log(str);
        switch (key) {
            case 'druName':
                setDruName(str)
                if (str) {
                    setDruName0(2)
                }else{
                    setDruName0(1)
                }
                break;
            case 'druIdcard':
                // let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                    setDruIdcard(str)
                    if (str.length==15||str.length==18) {
                        analyzeIDCard(val)
                    }else{
                        setsexAndAge('')
                    }
                if (str) {
                    setDruIdcard0(2)
                }else{
                    setDruIdcard0(1)
                }
                break;
            case 'druPhone':
                setDruPhone(str)
                if (str) {
                    setDruPhone0(2)
                }else{
                    setDruPhone0(1)
                }
                break;
            case 'familyGeneticHistoryNode':
                setfamilyGeneticHistoryNode(val.target.value)
                if (val.target.value) {
                    setJzHistory0(2)
                }else{
                    setJzHistory0(1)
                }
                break;
            case 'pastHistoryNote':
                setpastHistoryNote(val.target.value)
                if (val.target.value) {
                    setJwHistory0(2)
                }else{
                    setJwHistory0(1)
                }
                break;
            case 'allergicHistoryNote':
                setallergicHistoryNote(val.target.value)
                if (val.target.value) {
                    setGmHistory0(2)
                }else{
                    setGmHistory0(1)
                }
                break;
            case 'druHistory1':
                setDruHistory('1')
                setVisible0('block')
                
                break;
            case 'druHistory2':
                setDruHistory('0')
                setVisible0('none')
                setVisible1('none')
                // setVisible2('none')
                // setVisible3('none')
            break;
            case 'jwHistory1':
                setJwHistory('1')
                setVisible2('block')
                break;
            case 'jwHistory2':
                setJwHistory('0')
                setVisible2('none')
                setJwHistory0(0)
            break;
            case 'gmHistory1':
                setGmHistory('1')
                setVisible3('block')
                break;
            case 'gmHistory2':
                setGmHistory('0')
                setVisible3('none')
                setGmHistory0(0)
            break;
            case 'jzHistory1':
                setJzHistory('1')
                setVisible4('block')
                
                break;
            case 'jzHistory2':
                setJzHistory('0')
                setVisible4('none')
                setJzHistory0(0)
            break;
            case 'ganHistory1':
                setGanHistory('1')
                break;
            case 'ganHistory2':
                setGanHistory('0')
            break;
            case 'shenHistory1':
                setShenHistory('1')
                break;
            case 'shenHistory2':
                setShenHistory('0')
            break;
            case 'druInformation':
                setDruInformation(val)
                break;
            default:
                break;
        }
    }
    // console.log(jzHistory,gmHistory,jwHistory);
    // console.log(familyGeneticHistoryNode,allergicHistoryNote,pastHistoryNote);
    const  handleChange1=(key,val)=>{
        switch (key) {
            case 'jwHistory1':
                setJwHistory('1')
                setVisible2('block')
                break;
            case 'jwHistory2':
                setJwHistory('0')
                setVisible2('none')
            break;
            default:
                break;
        }
    }
//    添加线下确诊的疾病名称
    const addDisease=()=>{
        setVisible1('none')
        if (druInformation.length>0) {
            // arr.push(firstName2)
            if (array) {
                setArray(array.concat(','+druInformation))
                
            }else{
                setArray(druInformation)
            }
        }
    }
    // console.log(arr1);
    // console.log(type);
    const nextStep=()=>{
        if (wzStatus==1) {
            setwzStatus(0)
            // if (druIsused!='0') {
                if (onSure) {
                    if (arr.length&&type==0) {
                        if (!arr1) {
                            Toast.info('请选择用药人')
                            setwzStatus(1)
                            return;
                        }
                    }
                    if (array.length==0) {
                        Toast.info('请添加确诊疾病')
                        setwzStatus(1)
                        return;
                    }
                    let reg = /^1[3|4|5|8][0-9]\d{4,8}$/;
                    let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                    if (arr1&&type==0) {
                        getDoctype().then(res=>{
                            console.log(res);
                            let a=res.data.data[0].length
                            if (a>0) {
                                let b=Math.floor(Math.random()*a)
                                console.log(a,b);
                                setdocterId(res.data.data[0][b].docterId)
                                // updateDoctype({docterId:res.data.data[0][b].docterId,docterType:2}).then(res1=>{
                                    if (res.code==1) {
                                        // 图文-添加问诊信息
                                        addChatinfo({
                                            userId: patientId,
                                            docterId: res.data.data[0][b].docterId,
                                            consultationAmount: 0,
                                            consultationDetail: array,
                                            pharmacyName:storename,
                                            consultationStu:'-1',
                                            orderNumber,
                                            pharmacyId,
                                        }).then(res2=>{
                                            console.log(res2);
                                            // 图文-获取问诊信息
                                            getChatinfo({docterId:res.data.data[0][b].docterId,userId:patientId,orderNumber}).then(res3=>{
                                                console.log(res3);
                                                setconsultationId(res3.data.data[0][0].consultationId)
                                                if (res3.code==1) {
                                                    // 保存问诊单号
                                                    addWzdanhao({orderNumber,consultationId:res3.data.data[0][0].consultationId}).then(res4=>{
                                                        console.log(res4);
                                                        if (res4.code==1) {
                                                            let c=(new Date()).getTime()
                                                            console.log(consultationId);
                                                            window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorchat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${res3.data.data[0][0].consultationId}&orderNumber=${orderNumber}&docterId=${res.data.data[0][b].docterId}`)))
                                                        }
                                                    }).catch(err=>{
                                                        console.log(err);
                                                        Toast.info('用药人信息提交失败，请重新提交，111')
                                                        setwzStatus(1)
                                                    })
                                                }
                                            }).catch(err=>{
                                                console.log(err);
                                                Toast.info('用药人信息提交失败，请重新提交222')
                                                setwzStatus(1)
                                            })
                                        }).catch(err=>{
                                            console.log(err);
                                            Toast.info('用药人信息提交失败，请重新提交333')
                                            setwzStatus(1)
                                        })
                                    }
                                // })
                            }else{
                                Toast.info('暂无可接诊的医生，请稍后重新发送问诊信息')
                                setwzStatus(1)
                            }
                        }).catch(err=>{
                            console.log(err);
                            Toast.info('用药人信息提交失败，请重新提交444')
                            setwzStatus(1)
                        }).finally(err=>{
                            // console.log(111111111);
                        })
                    }else if ((type==0&&arr.length==0)||type==2) {//新增
                        if (druHistory) {
                            if (druHistory=='0') {//无疾病史
                                if (druName&&druIdcard&&druPhone) {
                                    if (p.test(druIdcard)) {
                                        if (reg.test(druPhone)) {
                                            getDoctype().then(data0=>{
                                                console.log(data0);
                                                let a=data0.data.data[0].length
                                                if (a>0) {
                                                    let b=Math.floor(Math.random()*a)
                                                    console.log(a,b);
                                                    setdocterId(data0.data.data[0][b].docterId)
                                                    // updateDoctype({docterId:data0.data.data[0][b].docterId,docterType:2}).then(data1=>{
                                                        if (data0.code==1) {
                                                            let params={
                                                                patientId,
                                                                patientName:druName,
                                                                patientIdCard:druIdcard,
                                                                patientPhone:druPhone,
                                                                pastHistory:0,
                                                                pastHistoryNote:'无',
                                                                allergicHistory:0,
                                                                allergicHistoryNote:'无',
                                                                familyGeneticHistory:0,
                                                                familyGeneticHistoryNote:'无',
                                                                abnormalLiverFunction:'0',
                                                                abnormalRenalFunction:'0',
                                                                patientDefault:1
                                                            }
                                                            addPatientMember(params).then(res=>{
                                                                console.log(res);
                                                                if (res.code==1) {
                                                                    addChatinfo({//新增问诊信息
                                                                        userId: patientId,
                                                                        docterId: data0.data.data[0][b].docterId,
                                                                        consultationAmount: 0,
                                                                        consultationDetail: array,
                                                                        pharmacyName:storename,
                                                                        consultationStu:'-1',
                                                                        orderNumber,
                                                                        pharmacyId
                                                                    }).then(res2=>{
                                                                        console.log(res2);
                                                                       // 图文-获取问诊信息
                                                                        getChatinfo({docterId:data0.data.data[0][b].docterId,userId:patientId,orderNumber}).then(res3=>{
                                                                            console.log(res3);
                                                                            setconsultationId(res3.data.data[0][0].consultationId)
                                                                            if (res3.code==1) {
                                                                                // 保存问诊单号
                                                                                addWzdanhao({orderNumber,consultationId:res3.data.data[0][0].consultationId}).then(res4=>{
                                                                                    console.log(res4);
                                                                                    if (res4.code==1) {
                                                                                        let c=(new Date()).getTime()
                                                            window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorchat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${res3.data.data[0][0].consultationId}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)))
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                            
                                                        }
                                                    // })
                                                }else{
                                                    Toast.info('暂无可接诊的医生，请稍后重新发送问诊信息')
                                                    setwzStatus(1)
                                                }
                                            })
        
        
                                        }else{
                                            Toast.info('请填写正确的手机号')
                                            setwzStatus(1)
                                        }
                                    }else{
                                        Toast.info('请填写正确的身份证号')
                                        setwzStatus(1)
                                    }
                                }else{
                                    Toast.info('请完善问诊信息')
                                    setwzStatus(1)
                                }
                            }else if(druHistory=='1'){//有疾病史
                                console.log(druHistory);
                                if (druName&&druIdcard&&druPhone&&jwHistory&&gmHistory&&jzHistory&&ganHistory&&shenHistory&&((jwHistory=='1'&&pastHistoryNote)||jwHistory=='0')&&
                                ((gmHistory=='1'&&allergicHistoryNote)||gmHistory=='0')&&((jzHistory=='1'&&familyGeneticHistoryNode)||jzHistory=='0')) {
                                    if (p.test(druIdcard)) {
                                        if (reg.test(druPhone)) {
                                            getDoctype().then(data0=>{
                                                console.log(data0);
                                                let a=data0.data.data[0].length
                                                if (a>0) {
                                                    let b=Math.floor(Math.random()*a)
                                                    console.log(a,b);
                                                    setdocterId(data0.data.data[0][b].docterId)
                                                    // updateDoctype({docterId:data0.data.data[0][b].docterId,docterType:2}).then(data1=>{
                                                        if (data0.code==1) {
                                                            let params={
                                                                patientId,
                                                                patientName:druName,
                                                                patientIdCard:druIdcard,
                                                                patientPhone:druPhone,
                                                                pastHistory:jwHistory=='0'?0:1,
                                                                pastHistoryNote:jwHistory=="0"?'无':pastHistoryNote,
                                                                allergicHistory:gmHistory=="0"?0:1,
                                                                allergicHistoryNote:gmHistory=="0"?'无':allergicHistoryNote,
                                                                familyGeneticHistory:jzHistory=="0"?0:1,
                                                                familyGeneticHistoryNote:jzHistory=="0"?'无':familyGeneticHistoryNode,
                                                                abnormalLiverFunction:ganHistory=='1'?1:'0',
                                                                abnormalRenalFunction:shenHistory=='1'?1:'0',
                                                                patientDefault:1
                                                            }
                                                            // 添加用药人
                                                            addPatientMember(params).then(res=>{
                                                                console.log(res);
                                                                if (res.code==1) {
                                                                    // 图文-添加问诊信息
                                                                    addChatinfo({
                                                                        userId: patientId,
                                                                        docterId: data0.data.data[0][b].docterId,
                                                                        consultationAmount: 0,
                                                                        consultationDetail: array,
                                                                        pharmacyName:storename,
                                                                        consultationStu:'-1',
                                                                        orderNumber,
                                                                        pharmacyId
                                                                    }).then(res2=>{
                                                                        console.log(res2);
                                                                        // 图文-获取问诊信息
                                                                        getChatinfo({docterId:data0.data.data[0][b].docterId,userId:patientId,orderNumber}).then(res3=>{
                                                                            console.log(res3);
                                                                            setconsultationId(res3.data.data[0][0].consultationId)
                                                                            if (res3.code==1) {
                                                                                // 保存问诊单号
                                                                                addWzdanhao({orderNumber,consultationId:res3.data.data[0][0].consultationId}).then(res4=>{
                                                                                    console.log(res4);
                                                                                    if (res4.code==1) {
                                                                                        let c=(new Date()).getTime()
                                                            window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorchat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${res3.data.data[0][0].consultationId}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)))
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                            
                                                        }
                                                    // })
                                                }else{
                                                    Toast.info('暂无可接诊的医生，请稍后重新发送问诊信息')
                                                    setwzStatus(1)
                                                }
                                            })
                                        }else{
                                            Toast.info('请填写正确的手机号')
                                            setwzStatus(1)
                                        }
                                    }else{
                                        Toast.info('请填写正确的身份证号')
                                        setwzStatus(1)
                                    }
                                }else{
                                    Toast.info('请完善问诊信息')
                                    setwzStatus(1)
                                }
                            }
                        }else{
                            Toast.info('请选择有无疾病史')
                            setwzStatus(1)
                        }
                    }else{//编辑
                        console.log(jwHistory0,gmHistory0,jzHistory0);
                        if ((druName0!=1)&&(druIdcard0!=1)&&(druPhone0!=1)&&(druIdcard0||arr1.patient_id_card||druIdcard)&&(jwHistory0!=1)&&
                        (gmHistory0!=1)&&(jzHistory0!=1)) {
                            if ((druHistory!='1')&&arr1.past_history==0&&arr1.allergic_history==0&&arr1.family_genetic_history==0&&
                            arr1.abnormal_liver_function==0&&arr1.abnormal_renal_function==0) {
                                if (druIdcard0==2&&p.test(druIdcard)||druIdcard0==0) {
                                    if ((druPhone0==2&&reg.test(druPhone))||druPhone0==0) {
                                        getDoctype().then(data0=>{
                                            console.log(data0);
                                            let a=data0.data.data[0].length
                                            if (a>0) {
                                                let b=Math.floor(Math.random()*a)
                                                console.log(a,b);
                                                setdocterId(data0.data.data[0][b].docterId)
                                                // updateDoctype({docterId:data0.data.data[0][b].docterId,docterType:2}).then(data1=>{
                                                    if (data0.code==1) {
                                                        let params={
                                                            memberId:druguserId,
                                                            patientName:druName0==0?arr1.patient_name:druName,
                                                            patientIdCard:druIdcard0==0?arr1.patient_id_card:druIdcard,
                                                            patientPhone:druPhone0==0?arr1.patient_phone:druPhone,
                                                            patientSex:sexAndAge0.sex?sexAndAge0.sex:arr1.patient_sex,
                                                            pastHistory:0,
                                                            pastHistoryNote:'无',
                                                            allergicHistory:0,
                                                            allergicHistoryNote:'无',
                                                            familyGeneticHistory:0,
                                                            familyGeneticHistoryNote:'无',
                                                            abnormalLiverFunction:'0',
                                                            abnormalRenalFunction:'0',
                                                            patientDefault:1
                                                        }
                                                        // 修改用药人
                                                        updatePatientMember(params).then(res=>{
                                                            console.log(res);
                                                            if (res.code==1) {
                                                                // 图文-添加问诊信息
                                                                addChatinfo({
                                                                    userId: patientId,
                                                                    docterId: data0.data.data[0][b].docterId,
                                                                    consultationAmount: 0,
                                                                    consultationDetail: array,
                                                                    pharmacyName:storename,
                                                                    consultationStu:'-1',
                                                                    orderNumber,
                                                                    pharmacyId
                                                                }).then(res2=>{
                                                                    console.log(res2);
                                                                    // 图文-获取问诊信息
                                                                    getChatinfo({docterId:data0.data.data[0][b].docterId,userId:patientId,orderNumber}).then(res3=>{
                                                                        console.log(res3);
                                                                        setconsultationId(res3.data.data[0][0].consultationId)
                                                                        if (res3.code==1) {
                                                                            // 保存问诊单号
                                                                            addWzdanhao({orderNumber,consultationId:res3.data.data[0][0].consultationId}).then(res4=>{
                                                                                console.log(res4);
                                                                                let c=(new Date()).getTime()
                                                            window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorchat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${res3.data.data[0][0].consultationId}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)))
    
                                                                                // window.location.href=(`${baseUrl0}/index.html#/doctorinfo/chat?a=${c}&patientPhone=${patientPhone}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)
                                                                            })
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                        })
                                                        
                                                    }
                                                // })
                                            }else{
                                                Toast.info('暂无可接诊的医生，请稍后重新发送问诊信息')
                                                setwzStatus(1)
                                            }
                                        })
                                    }else{
                                        Toast.info('请填写正确的手机号')
                                        setwzStatus(1)
                                    }
                                }else{
                                    Toast.info('请填写正确的身份证号')
                                    setwzStatus(1)
                                }
                            }else{
                                if (druIdcard0==2&&p.test(druIdcard)||druIdcard0==0) {
                                    if ((druPhone0==2&&reg.test(druPhone))||druPhone0==0) {
                                        getDoctype().then(data0=>{
                                            console.log(data0);
                                            let a=data0.data.data[0].length
                                            if (a>0) {
                                                let b=Math.floor(Math.random()*a)
                                                console.log(a,b);
                                                setdocterId(data0.data.data[0][b].docterId)
                                                // updateDoctype({docterId:data0.data.data[0][b].docterId,docterType:2}).then(data1=>{
                                                    if (data0.code==1) {
                                                        let params={
                                                            memberId:druguserId,
                                                            patientName:druName0==0?arr1.patient_name:druName,
                                                            patientIdCard:druIdcard0==0&&arr1.patient_id_card?arr1.patient_id_card:druIdcard,
                                                            patientPhone:druPhone0==0?arr1.patient_phone:druPhone,
                                                            patientSex:sexAndAge0.sex?sexAndAge0.sex:arr1.patient_sex,
                                                            pastHistory:jwHistory0==0&&arr1.past_history==0?0:1,
                                                            pastHistoryNote:jwHistory0==0&&arr1.past_history==0?'无':pastHistoryNote?pastHistoryNote:arr1.past_history_note,
                                                            allergicHistory:gmHistory0==0&&arr1.allergic_history==0?0:1,
                                                            allergicHistoryNote:gmHistory0==0&&arr1.allergic_history==0?'无':allergicHistoryNote?allergicHistoryNote:arr1.allergic_history_note,
                                                            familyGeneticHistory:jzHistory0==0&&arr1.family_genetic_history==0?0:1,
                                                            familyGeneticHistoryNote:jzHistory0==0&&arr1.family_genetic_history==0?'无':familyGeneticHistoryNode?familyGeneticHistoryNode:arr1.family_genetic_history_note,
                                                            abnormalLiverFunction:(ganHistory=='1'||(!ganHistory&&arr1.abnormal_liver_function==1))?1:'0',
                                                            abnormalRenalFunction:(shenHistory=='1'||(!shenHistory&&arr1.abnormal_renal_function==1))?1:'0',
                                                            patientDefault:1
                                                        }
                                                        console.log(params);
                                                        // 修改用药人
                                                        updatePatientMember(params).then(res=>{
                                                            console.log(res);
                                                            if (res.code==1) {
                                                                // 图文-添加问诊信息
                                                                addChatinfo({
                                                                    userId: patientId,
                                                                    docterId: data0.data.data[0][b].docterId,
                                                                    consultationAmount: 0,
                                                                    consultationDetail: array,
                                                                    pharmacyName:storename,
                                                                    consultationStu:'-1',
                                                                    orderNumber,
                                                                    pharmacyId
                                                                }).then(res2=>{
                                                                    console.log(res2);
                                                                    if (res2.code==1) {
                                                                        // 图文-获取问诊信息
                                                                        getChatinfo({docterId:data0.data.data[0][b].docterId,userId:patientId,orderNumber}).then(res3=>{
                                                                            console.log(res3);
                                                                            setconsultationId(res3.data.data[0][0].consultationId)
                                                                            if (res3.code==1) {
                                                                                addWzdanhao({orderNumber,consultationId:res3.data.data[0][0].consultationId}).then(res4=>{
                                                                                    console.log(res4);
                                                                                    let c=(new Date()).getTime()
                                                            window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorchat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${res3.data.data[0][0].consultationId}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)))
    
                                                                                    // window.location.href=(encodeURI(encodeURI(`${baseUrl0}/index.html#/doctorinfo/chat?a=${c}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${consultationId}&orderNumber=${orderNumber}&docterId=${data0.data.data[0][b].docterId}`)))
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                        
                                                    }
                                                // })
                                            }else{
                                                Toast.info('暂无可接诊的医生，请稍后重新发送问诊信息')
                                                setwzStatus(1)
                                            }
                                        })
                                        
                                    }else{
                                        Toast.info('请填写正确的手机号')
                                        setwzStatus(1)
                                    }
                                }else{
                                    Toast.info('请填写正确的身份证号')
                                    setwzStatus(1)
                                }
                            }
                        }else{
                            Toast.info('请完善问诊信息')
                            setwzStatus(1)
                        }
                    }
                }else{
                    setVisible6('block')
                }
            // }else{
            //     setVisible5('block')
            //     setwzStatus(1)
            // }
        }else{
            Toast.info('请勿重复提交')
            setwzStatus(1)
        }
    }
    const sure=(e)=>{
        setVisible6('none')
        setonSure(e.target.value);
        setwzStatus(1)
    }
    // 确诊疾病
    const deleteIll=(index)=>{
        console.log(index);
        let a=[]
        if (array) {
            a=array.split(",")
            a.splice(index,1)
        }
        setArray(a.toString());
    }

    // 编辑信息
    const selectPatient=(e,memberId)=>{
        e.preventDefault()
        e.stopPropagation()
        setdruguserId(memberId);
        setVisible7('none')
        setVisible8('block')

        setType(1)//编辑
        // updatePatientMember({memberId,patientDefault:1}).then(res=>{
        //     console.log(res);
        // })
        console.log(memberId);
        arr.map(item=>{
            if (item.member_id==memberId) {
                setArr1(item)
                // wzstate({docterId:27,userId:patientId,memberId}).then(res2=>{
                //     console.log(res2);
                // })
                // setArray(item.confirmed_disease)
            }
        })
    }
    // 删除用药人
    const delPatient=(e,memberId)=>{
        console.log(arr1);
        console.log(memberId);
        if (memberId==arr1.member_id) {
            setArr1('')
        }
        e.preventDefault()
        e.stopPropagation()
        setVisible9('block')
        setdruguserId(memberId)
    }
    const onDelete=()=>{
        setVisible9('none')
        setdruguserId('')
        delPatientMember({memberId:druguserId}).then(res=>{
            console.log(res);
            getUserNews()
        })
    }
    // 修改默认就诊人
    const selectDefault=(memberId)=>{
        updatePatientMember({memberId,patientDefault:1}).then(res=>{
            console.log(res);
            if (res.code==1) {
                getPatientMember({patientId}).then(res1=>{
                    console.log(res1);
                    setArr(res1.data.data[0])
                    if (res1.data.data[0].length) {
                        res1.data.data[0].map(item=>{
                            if (item.patient_default==1) {
                                setArr1(item)
                            }
                        })
                    }
                })
            }
        })
    }
    // 新增就诊人
    const addUser=()=>{
        // setVisible8('none')
        console.log(druHistory);
        let reg = /^1[3|4|5|8][0-9]\d{4,8}$/;
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (druHistory) {
            if (druHistory=='0') {//无疾病史
                if (druName&&druIdcard&&druPhone) {
                    if (p.test(druIdcard)||sexAndAge0==-1) {
                        if (reg.test(druPhone)) {
                            let params={
                                patientId,
                                patientName:druName,
                                patientIdCard:druIdcard,
                                patientPhone:druPhone,
                                patientSex:sexAndAge0.sex,
                                patientBirthday:sexAndAge0.age,
                                pastHistory:0,
                                pastHistoryNote:'无',
                                allergicHistory:0,
                                allergicHistoryNote:'无',
                                familyGeneticHistory:0,
                                familyGeneticHistoryNote:'无',
                                abnormalLiverFunction:'0',
                                abnormalRenalFunction:'0',
                                patientDefault:1
                            }
                            addPatientMember(params).then(res=>{
                                console.log(res);
                                if (res.code==1) {
                                    getUserNews()
                                    setVisible8('none')
                                    window.location.reload()
                                }
                            })
                            console.log(params);
                        }else{
                            Toast.info('请填写正确的手机号')
                            setwzStatus(1)
                        }
                    }else{
                        Toast.info('请填写正确的身份证号')
                        setwzStatus(1)
                    }
                }else{
                    Toast.info('请完善问诊信息')
                    setwzStatus(1)
                }
            }else if(druHistory=='1'){
                if (druName&&druIdcard&&druPhone&&jwHistory&&gmHistory&&jzHistory&&ganHistory&&shenHistory&&((jwHistory=='1'&&pastHistoryNote)||jwHistory=='0')&&
                ((gmHistory=='1'&&allergicHistoryNote)||gmHistory=='0')&&((jzHistory=='1'&&familyGeneticHistoryNode)||jzHistory=='0')) {
                    if (p.test(druIdcard)||sexAndAge0==-1) {
                        if (reg.test(druPhone)) {
                            let params={
                                patientId,
                                patientName:druName,
                                patientIdCard:druIdcard,
                                patientPhone:druPhone,
                                patientSex:sexAndAge0.sex,
                                patientBirthday:sexAndAge0.age,
                                pastHistory:jwHistory=='0'?0:1,
                                pastHistoryNote:jwHistory=="0"?'无':pastHistoryNote,
                                allergicHistory:gmHistory=="0"?0:1,
                                allergicHistoryNote:gmHistory=="0"?'无':allergicHistoryNote,
                                familyGeneticHistory:jzHistory=="0"?0:1,
                                familyGeneticHistoryNote:jzHistory=="0"?'无':familyGeneticHistoryNode,
                                abnormalLiverFunction:ganHistory=='1'?1:'0',
                                abnormalRenalFunction:shenHistory=='1'?1:'0',
                                patientDefault:1
                            }
                            console.log(params);
                            addPatientMember(params).then(res=>{
                                console.log(res);
                                if (res.code==1) {
                                    getUserNews()
                                    setVisible8('none')
                                    window.location.reload()
                                }
                            })
                        }else{
                            Toast.info('请填写正确的手机号')
                            setwzStatus(1)
                        }
                    }else{
                        Toast.info('请填写正确的身份证号')
                        setwzStatus(1)
                    }
                }else{
                    Toast.info('请完善问诊信息')
                    setwzStatus(1)
                }
            }
        }else{
            Toast.info('请选择有无疾病史')
            setwzStatus(1)
        }
    }
    // 修改用药人信息
    const xiugaiUser=()=>{
        let reg = /^1[3456789]\d{9}$/;
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        console.log(arr1);
        if ((druName0!=1)&&(druIdcard0!=1)&&(druPhone0!=1)&&(druIdcard0||arr1.patient_id_card||druIdcard)&&(jwHistory0!=1)&&
            (gmHistory0!=1)&&(jzHistory0!=1)) {
                if ((druHistory!='1')&&arr1.past_history==0&&arr1.allergic_history==0&&arr1.family_genetic_history==0&&
                arr1.abnormal_liver_function==0&&arr1.abnormal_renal_function==0) {
                    if (druIdcard0==2&&p.test(druIdcard)||druIdcard0==0||sexAndAge0==-1) {
                        if ((druPhone0==2&&reg.test(druPhone))||(druPhone0==0&&reg.test(arr1.patient_phone))) {
                            let params={
                                memberId:druguserId,
                                patientName:druName0==0?arr1.patient_name:druName,
                                patientIdCard:druIdcard0==0?arr1.patient_id_card:druIdcard,
                                patientPhone:druPhone0==0?arr1.patient_phone:druPhone,
                                patientSex:sexAndAge0&&sexAndAge0.sex?sexAndAge0.sex:arr1.patient_sex,
                                patientBirthday:sexAndAge0&&sexAndAge0.age?sexAndAge0.age:arr1.patient_birthday,
                                pastHistory:0,
                                pastHistoryNote:'无',
                                allergicHistory:0,
                                allergicHistoryNote:'无',
                                familyGeneticHistory:0,
                                familyGeneticHistoryNote:'无',
                                abnormalLiverFunction:'0',
                                abnormalRenalFunction:'0',
                                patientDefault:1
                            }
                            // 修改用药人
                            updatePatientMember(params).then(res=>{
                                if (res.code==1) {
                                    getUserNews()
                                    setVisible8('none')
                                    window.location.reload()
                                }
                            })
                        }else{
                            Toast.info('请填写正确的手机号')
                            setwzStatus(1)
                        }
                    }else{
                        Toast.info('请填写正确的身份证号')
                        setwzStatus(1)
                    }
                }else{
                    if (druIdcard0==2&&p.test(druIdcard)||druIdcard0==0||sexAndAge0==-1) {
                        if ((druPhone0==2&&reg.test(druPhone))||druPhone0==0) {
                            let params={
                                memberId:druguserId,
                                patientName:druName0==0?arr1.patient_name:druName,
                                patientIdCard:druIdcard0==0&&arr1.patient_id_card?arr1.patient_id_card:druIdcard,
                                patientPhone:druPhone0==0?arr1.patient_phone:druPhone,
                                patientSex:sexAndAge0.sex?sexAndAge0.sex:arr1.patient_sex,
                                patientBirthday:sexAndAge0.age?sexAndAge0.age:arr1.patient_birthday,
                                pastHistory:jwHistory0==0&&arr1.past_history==0?0:1,
                                pastHistoryNote:jwHistory0==0&&arr1.past_history==0?'无':pastHistoryNote?pastHistoryNote:arr1.past_history_note,
                                allergicHistory:gmHistory0==0&&arr1.allergic_history==0?0:1,
                                allergicHistoryNote:gmHistory0==0&&arr1.allergic_history==0?'无':allergicHistoryNote?allergicHistoryNote:arr1.allergic_history_note,
                                familyGeneticHistory:jzHistory0==0&&arr1.family_genetic_history==0?0:1,
                                familyGeneticHistoryNote:jzHistory0==0&&arr1.family_genetic_history==0?'无':familyGeneticHistoryNode?familyGeneticHistoryNode:arr1.family_genetic_history_note,
                                abnormalLiverFunction:(ganHistory=='1'||(!ganHistory&&arr1.abnormal_liver_function==1))?1:'0',
                                abnormalRenalFunction:(shenHistory=='1'||(!shenHistory&&arr1.abnormal_renal_function==1))?1:'0',
                                patientDefault:1
                            }
                            console.log(params);
                            // 修改用药人
                            updatePatientMember(params).then(res=>{
                                if (res.code==1) {
                                    getUserNews()
                                    setVisible8('none')
                                    window.location.reload()
                                }
                            })
                        }else{
                            Toast.info('请填写正确的手机号')
                            setwzStatus(1)
                        }
                    }else{
                        Toast.info('请填写正确的身份证号')
                        setwzStatus(1)
                    }
                }
        }else{
            Toast.info('请完善问诊信息')
            setwzStatus(1)
        }
    }
    // 通过身份证号获取性别年龄
    const analyzeIDCard = (IDCard)=> {
        var sexAndAge = {};
        //获取用户身份证号码
        var userCard = IDCard;
        //如果身份证号码为undefind则返回空
        if (!userCard) {
            return sexAndAge;
        }
        //获取到性别
        if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
            sexAndAge.sex = '男'
        } else {
            sexAndAge.sex = '女'
        }
        //获取出生年月日
        //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
        var yearBirth = userCard.substring(6, 10);
        var monthBirth = userCard.substring(10, 12);
        var dayBirth = userCard.substring(12, 14);
        //获取当前年月日并计算年龄
        var myDate = new Date();
        var monthNow = myDate.getMonth() + 1;
        var dayNow = myDate.getDay();
        var yearNow = myDate.getFullYear();
        var age=yearBirth+'-'+monthBirth+'-'+dayBirth
        if (yearNow<yearBirth||((yearNow==yearBirth)&&(monthNow<monthBirth))||((yearNow==yearBirth)&&(monthNow==monthBirth)&&(dayNow<dayBirth))) {
            setsexAndAge(-1)
            return;
        }else{
            // var myDate = new Date();
            // var monthNow = myDate.getMonth() + 1;
            // var dayNow = myDate.getDay();
            // var age = myDate.getFullYear() - yearBirth;
            // if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
            //     age--;
            // }
            //获取到年龄
            sexAndAge.age = age;
            console.log(sexAndAge);
            setsexAndAge(sexAndAge)
            //返回性别和年龄
            return sexAndAge;
        }
    }
    // console.log(druIdcard);
    return(
        <div>
            <div className='top'>
                <div className='title'>
                    <img src={fanhui} onClick={backPage} alt="" />
                    <h1 >填写问诊信息</h1>
                </div>
                <div className='tep'>
                    <span>填写信息</span>
                    <img src={jiantou} alt="" />
                    <span>医生开方</span>
                    <img src={jiantou} alt="" />
                    <span>医师审方</span>
                    <img src={jiantou} alt="" />
                    <span>药房发货</span>
                </div>
            </div>
            <div className='tips'>
                <h2>依据国家规定，处方药需凭处方购买</h2>
                <p>填写问诊信息后医生将为您开具处方，<span>10分钟</span>未填写将自动退款</p>
            </div>
            {visible7=='none'?<div className='news'>
                <InputItem
                    placeholder='请输入用药人姓名'
                    onChange={v=>handleChange('druName',v)}
                    defaultValue={arr1&&type==1?arr1.patient_name:''}
                >用药人姓名<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></InputItem>
                <InputItem
                    placeholder='请输入用药人身份证'
                    onChange={v=>handleChange('druIdcard',v)}
                    maxLength={18}
                    defaultValue={arr1&&type==1?arr1.patient_id_card:''}
                >身份证号<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></InputItem>
                <InputItem
                    placeholder='请输入用药人手机号'
                    onChange={v=>handleChange('druPhone',v)}
                    type={number}
                    maxLength={11}
                    defaultValue={arr1&&type==1?arr1.patient_phone:''}
                >手机号<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></InputItem>
                <div className='bing'>
                    <span>疾病史<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                    <span className="a b">
                        <span>
                            <input type="radio" name='bing' checked={(((!druHistory)&&arr1&&type==1&&(arr1.past_history||arr1.allergic_history||arr1.family_genetic_history))||druHistory=='1')?"checked":''} onClick={(v) => handleChange('druHistory1',v)} value='man'/><span className='have'>有</span>
                            <input type="radio" name='bing' checked={(((!druHistory)&&arr1&&type==1&&(!(arr1.past_history||arr1.allergic_history||arr1.family_genetic_history)))||druHistory=='0')?"checked":''} onClick={(v) => handleChange('druHistory2',v)} value='woman' /><span>无</span>
                        </span>
                    </span>
                </div>
                {(druHistory!='0')&&(visible0=='block'||(arr1&&type==1&&((arr1.past_history==1)||(arr1.allergic_history==1)||(arr1.family_genetic_history==1))))?<div className='ill'>
                    <div className='ill-outer'>
                        <div>
                            <span style={{marginRight:'40px'}}>既往史<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                            <span>
                                <input type="radio" name='bing1' checked={(!jwHistory&&arr1&&type==1&&arr1.past_history==1)||jwHistory=='1'?"checked":''} onClick={(v) => handleChange1('jwHistory1',v)} value='man1'/><span className='have'>有</span>
                                <input type="radio" name='bing1' checked={(!jwHistory&&arr1&&type==1&&arr1.past_history==0)||jwHistory=='0'?"checked":''} onClick={(v) => handleChange1('jwHistory2',v)} value='woman1' /><span>无</span>
                            </span>
                        </div>
                        {visible2=='block'||((arr1&&type==1&&arr1.past_history==1)&&(jwHistory!='0'))?<input className='word' defaultValue={arr1&&type==1?arr1.past_history_note:''} type="text" placeholder='请写出您的既往史' onChange={v=>handleChange('pastHistoryNote',v)}/>:null}
                    </div>
                    <div className='ill-outer'>
                        <div>
                            <span style={{marginRight:'40px'}}>过敏史<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                            <span>
                                <input type="radio" name='bing2' checked={(!gmHistory&&arr1&&type==1&&arr1.allergic_history==1)||gmHistory=='1'?"checked":''} onClick={(v) => handleChange('gmHistory1',v)} value='man2'/><span className='have'>有</span>
                                <input type="radio" name='bing2' checked={(!gmHistory&&arr1&&type==1&&arr1.allergic_history==0)||gmHistory=='0'?"checked":''} onClick={(v) => handleChange('gmHistory2',v)} value='woman2' /><span>无</span>
                            </span>
                        </div>
                        {visible3=='block'||((arr1&&type==1&&arr1.allergic_history==1)&&(gmHistory&&gmHistory=='0'?0:1))?<input className='word' defaultValue={arr1&&type==1?arr1.allergic_history_note:''} type="text" placeholder='请输入您对什么过敏' onChange={v=>handleChange('allergicHistoryNote',v)}/>:null}
                    </div>
                    <div className='ill-outer'>
                        <div>
                            <span>家族遗传史<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                            <span>
                                <input type="radio" name='bing3' checked={(!jzHistory&&arr1&&type==1&&arr1.family_genetic_history==1)||jzHistory=='1'?"checked":''} onClick={(v) => handleChange('jzHistory1',v)} value='man3'/><span className='have'>有</span>
                                <input type="radio" name='bing3' checked={(!jzHistory&&arr1&&type==1&&arr1.family_genetic_history==0)||jzHistory=='0'?"checked":''} onClick={(v) => handleChange('jzHistory2',v)} value='woman3' /><span>无</span>
                            </span>
                        </div>
                        {visible4=='block'||((arr1&&type==1&&arr1.family_genetic_history==1)&&(jzHistory&&jzHistory=='0'?0:1))?<input className='word' defaultValue={arr1&&type==1?arr1.family_genetic_history_note:''} type="text"  placeholder='请输入您的家族遗传史' onChange={v=>handleChange('familyGeneticHistoryNode',v)}/>:null}
                    </div>
                    <div className='ill-outer'>
                        <div>
                            <span>肝功能异常<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                            <span>
                                <input type="radio" name='bing4' checked={(!ganHistory&&arr1&&type==1&&arr1.abnormal_liver_function==1)||ganHistory=='1'?"checked":''} onClick={(v) => handleChange('ganHistory1',v)} value='man4'/><span className='have'>有</span>
                                <input type="radio" name='bing4' checked={(!ganHistory&&arr1&&type==1&&arr1.abnormal_liver_function==0)||ganHistory=='0'?"checked":''} onClick={(v) => handleChange('ganHistory2',v)} value='woman4' /><span>无</span>
                            </span>
                        </div>
                    </div>
                    <div className='ill-outer'>
                        <div>
                            <span>肾功能异常<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                            <span>
                                <input type="radio" name='bing5' checked={(!shenHistory&&arr1&&type==1&&arr1.abnormal_renal_function==1)||shenHistory=='1'?"checked":''} onClick={(v) => handleChange('shenHistory1',v)} value='man5'/><span className='have'>有</span>
                                <input type="radio" name='bing5' checked={(!shenHistory&&arr1&&type==1&&arr1.abnormal_renal_function==0)||shenHistory=='0'?"checked":''} onClick={(v) => handleChange('shenHistory2',v)} value='woman5' /><span>无</span>
                            </span>
                        </div>
                    </div>
                </div>:''}
            </div>:null}
            {visible7=='block'?<div className='news1'>
                <div className='select'>
                    <span className='select-left'>请选择用药人<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></span>
                    <span className='select-right' onClick={()=>(setVisible7('none'),setVisible8('block'),setType(2))}><span>+</span>添加</span>
                </div>
                <div className='add'>
                    {arr.map((item,index)=>(
                        <>
                        {item.patient_default==1?<p  onClick={()=>selectDefault(item.member_id)} style={{backgroundColor:'#E9F3FF'}} className='add-news'>
                            <div key={index}>
                                <h3 className='name'>{item.patient_name}</h3>
                                <img src={bianji}  onClick={(e)=>selectPatient(e,item.member_id)} />
                            </div>
                            <p className='del-phone'>
                                <span>{item.patient_phone}</span>
                                <img src={del}  onClick={(e)=>delPatient(e,item.member_id)} />
                            </p>
                        </p>:<p className='add-news' onClick={()=>selectDefault(item.member_id)}>
                            <div key={index}>
                                <h3 className='name'>{item.patient_name}</h3>
                                <img src={bianji}  onClick={(e)=>selectPatient(e,item.member_id)} />
                            </div>
                            <p className='del-phone'>
                                <span>{item.patient_phone}</span>
                                <img src={del}  onClick={(e)=>delPatient(e,item.member_id)} />
                            </p>
                        </p>}
                        </>
                    ))}
                </div>
            </div>:null}
            {visible8=='none'?<div className='disease'>
                <p>本次用药的确诊疾病（可多选）<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></p>
                {/* {arr1&&visible7=='none'?<div className='confirmed'>
                    {arr1.confirmed_disease.split(",").map((item,index)=>(<span key={index} style={{borderColor:"#2A8CFE"}}>{item}<span onClick={()=>deleteIll(index)} style={{color:'#999',marginLeft:'10px',borderLeft:'1px solid #999',paddingLeft:'8px'}}>x</span></span>))}
                    <span onClick={() => {setVisible1('block')}}>+其他</span>
                </div>: */}
                <div className='confirmed'>
                    {array&&array.split(",").map((item,index)=>(<span key={index} style={{borderColor:"#2A8CFE"}}>{item}<span onClick={()=>deleteIll(index)} style={{color:'#999',marginLeft:'10px',borderLeft:'1px solid #999',paddingLeft:'8px'}}>x</span></span>))}
                    <span onClick={() => {setVisible1('block')}}>+其他</span>
                </div>
                {visible1=='block'?<div className='bgc-popup' >
                    <div className='popup-top' onClick={() => {setVisible1('none')}}></div>
                    <div  className='popup'>
                        <h3>请添加确诊疾病</h3>
                        {/* <Head searchword='老百姓大药房'  ability='搜索' clickmethod={()=>searchMed}/> */}
                        <div className='sousuo'>
                            <InputItem className='shuru' placeholder='请添加线下确诊的疾病名称' type='text'
                            onChange={v=>handleChange('druInformation',v)}
                        ></InputItem><span className='add' onClick={() => addDisease()}>添加</span>
                        </div>
                    </div>
                </div>:null}
            </div>:null}
            {visible8=='none'?<div className='used'>
                <div className='used-left'>
                    <p className='used-left-top'>是否使用过此药品<span style={{color:'#ff0000',marginLeft:'5px'}}>*</span></p>
                    <p className='used-left-bottom'>无相关禁忌症，无过敏史和不良反应</p>
                </div>
                <div className='used-right'>
                    <p>
                        {/*  checked={(!druIsused&&arr1&&arr1.dru_isused==1)||druIsused=='1'?"checked":''} */}
                        <input type="radio" checked onClick={(v) => (setDruIsused('1'),setVisible5('none'))} name='use'/><span className='use'>有</span>
                        <input type="radio" onClick={(v) => (setDruIsused('0'),setVisible5('block'))} name='use' /><span>无</span>
                    </p>
                </div>
            </div>:null}
            {visible5=='block'?<div className='know'>
                <div className='word'>您选购的药品中含有处方药，如未使用过，无法在线购买。为保障用药安全,建议您到线下医院就诊。</div>
                <div className='yes' onClick={()=>(setVisible5('none'))}>知道了</div>
            </div>:null}
            {visible8=='none'?
            <div className='continue'>
                <p><input type="radio" onClick={(e)=>sure(e)}/> 如您使用此服务,视您同意<span>《互联网诊疗风险告知及知情同意书》</span></p>
                {visible6=='block'?<p style={{textAlign:'center',color:'#ff5555',margin:'5px 0'}}>请勾选同意此服务，否则将无法继续进行</p>:null}
                <div onClick={()=>{nextStep()}}>
                    <img src={jixu} alt="" />
                    <span>继续</span>
                </div>
            </div>:
            <div className='continue1'>
                {type==2?<div onClick={()=>addUser()}>
                    <img src={jixu} alt="" />
                    <span>添加用药人</span>
                </div>:
                <div className='xiugai' onClick={()=>xiugaiUser()}>
                    <img src={jixu} alt="" />
                    <span>修改用药人信息</span>
                </div>}
            </div>}
            {visible9=='block'?
                <div className='del-address'>
                    <p className='del-sure'>是否删除此用药人？</p>
                    <p className='del-bottom'>
                        <span onClick={()=>(setdruguserId(''),setVisible9('none'))}>取消</span>
                        <span onClick={()=>onDelete()}>确认</span>
                    </p>
                </div>
            :null}
        </div>
    )
}
