import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import {oderdetailRequest,applyPartRequest,upOrderStuRequest,applyRefundRequest } from '../../../../api/request/order'
import { aftersaleList,aftersaleMedList,updateAftersale,updateCourier } from '../../../../api/request/aftersale'

import { getqualifications } from '../../../../api/request/qualifications'

import { uploadImg } from '../../../../api/request/upload'
import { zipImg } from '../../../../component/zipImage/index'
import fanhui from '../../../../static/img/fanhuihei.png'
import finish from '../../../../static/img/finish.png'

import './style.less'
const RadioItem = Radio.RadioItem
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const reasons = [ '厂家召回', '出库单问题','没收到货','拍错/多拍/不想要','实物与商品描述不符（批号/包裹）','药品近效期','商品质量问题','发货少货','商品压损','商家发错货', '其它原因']
    const [evaluatePic, setEvaluatePic] = useState([])
    const [medName, setmedName] = useState('')
    const [companyName, setcompanyName] = useState()
    const [medrefundQuantity, setmedrefundQuantity] = useState()
    const [visible, setVisiable] = useState('none')
    const [reasonContent, setReasonContent] = useState()
    const [miaosu, setMiaosu] = useState('')
    const [time, setTime] = useState()
    const [time3, setTime3] = useState()
    const [kdname, setkdName] = useState()
    const [remark, setRemark] = useState()
    const [danhao, setDanhao] = useState()
    const cartArr=props.location.state

    let orderNumber;  //先定义一个存储参数的变量
    let medId;
    let aftersaleId;

    if(cartArr){  //判断是否有参数
        orderNumber=cartArr[0].pharmacy.orderNumber;
        medId=cartArr[0].pharmacy.medId;
        aftersaleId=cartArr[0].pharmacy.aftersaleId
        localStorage.setItem('orderNumber1', orderNumber);
        localStorage.setItem('medId1', medId);
        localStorage.setItem('aftersaleId1', aftersaleId);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber1');
        medId = localStorage.getItem('medId1');
        aftersaleId = localStorage.getItem('aftersaleId1');
    }
    useEffect(() => {
        aftersaleMedList({aftersaleId}).then(res=>{
            console.log(res);
            if (res.code==1) {
                setmedName(res.data[0][0].med_name)
                setmedrefundQuantity(res.data[0][0].med_refund_quantity)
                setmedName(res.data[0][0].med_name)
                setmedName(res.data[0][0].med_name)
                setmedName(res.data[0][0].med_name)

            }
        })
        aftersaleList({userId}).then(res=>{
            console.log(res);
            if (res.code==1) {
                res.data[0].map(item=>{
                    if (item.aftersale_id==aftersaleId) {
                        setReasonContent(item.aftersale_reason)
                        setMiaosu(item.order_aftersale_details)
                        setTime(item.apply_aftersale_time)
                        setEvaluatePic(item.aftersale_certificate.split(','))
                    }
                })
            }
        })
        getqualifications({userId}).then(res=>{
            if (res.code==1) {
                setcompanyName(res.data[0][0].company_name)
            }
        })
    }, [])
    useEffect(() => {
        let time2=new Date(time)
        let time1=time2.getFullYear()+'-'+(time2.getMonth()+1)+'-'+time2.getDate()+' '+time2.getHours()+':'+time2.getMinutes()+':'+time2.getSeconds()
        setTime3(time1)
    }, [time])
    const fanhui1=()=>{
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    const fillLogisticsNumber=()=>{
        setVisiable('block')
    }
    const danao=(v,e)=>{
        switch (v) {
            // case 'kdname':
            //     setkdName(e.target.value);
            //     break;
            case 'danhao':
                setDanhao(e.target.value);
                break;
            case 'remark':
                setRemark(e.target.value);
                break;
            default:
                break;
        }
    }
    const sure=()=>{
        if (kdname) {
            if (danhao) {
                let params={
                    orderCourier:kdname,
                    courierNumber:danhao,
                    orderCourierRemarks:remark,
                    aftersaleId:aftersaleId
                }
                let params1={
                    aftersaleState:'5',
                    aftersaleRefuse:'',
                    aftersaleDetails:'',
                    aftersaleId:aftersaleId
                }
                updateCourier(params).then(res=>{
                    console.log(res);
                    if (res.code==1) {
                        updateAftersale(params1).then(res1=>{
                            console.log(res1);
                            if (res.code==1) {
                                props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
                            }
                        })
                    }
                })
            }else{
                Toast.info('请填写快递单号')
            }
        }else{
            Toast.info('请填写快递名称')
        }
    }
    const wuliu=(e)=>{
        console.log(e.target.value);
        switch (e.target.value) {
            case 'a':
                setkdName('圆通快递');
                break;
            case 'b':
                setkdName('中通快递');
                break;
            case 'c':
                setkdName('申通快递');
                break;
            case 'd':
                setkdName('韵达快递');
                break;
            case 'e':
                setkdName('京东快递');
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <div className='goodsrejected-top'>
                <div className='pifa-goodsrejected'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>等待退货</p>   
                </div>
                <div className='goodsrejected-tishi'>
                    <p>等待您退回货物</p>
                    <p>商家要求先技下方地址退回货物,商家确认收到货后将立即处理</p>
                </div>
                <div className='cgs-news'>
                    <p>{companyName}</p>
                    <p>订单：{orderNumber}</p>
                </div>
            </div>
            <div className='goodsrejected-details'>
                <p className='sh-xiangqing'>售后订单详情</p>
                <p>商品名字：{medName}</p>
                <p>数量：{medrefundQuantity}</p>
                <p>原因：{reasonContent}</p>
                <p>说明：{miaosu?miaosu:'无'}</p>
                <p>申请时间：{time3}</p>
                {evaluatePic.map(item=>(
                    <img src={item} alt="" />
                ))}
            </div>
            <div className='tuihui-address'>
                <p>退回地址</p>
                <p>收件单位：{}</p>
                <p>收件人：{}</p>
                <p>收件地址：{}</p>
            </div>
            <div className='tuihui' onClick={()=>fillLogisticsNumber()}>通知商家货已退回</div>
            {visible=='block'?<div className='fillLogistics'>
                <div className='fillLogistics-up'>
                    <p className='fillLogistics-up-title'>填写退回物流消息</p>
                    <p>
                        <span className='danhao'>快递：</span>
                        {/* <input type="text" placeholder='请填写快递名称' onChange={(v)=>danao('kdname',v)}/> */}
                        <select onChange={e=>wuliu(e)}>
                            <option value="">请选择快递</option>
                            <option value="a">圆通快递</option>
                            <option value="b">中通快递</option>
                            <option value="c">申通快递</option>
                            <option value="d">韵达快递</option>
                            <option value="e">京东快递</option>
                        </select>
                    </p>
                    <p>
                        <span className='danhao'>单号：</span><input type="text" placeholder='请填写快递单号' onChange={(v)=>danao('danhao',v)}/>
                    </p>
                    <p>
                        <span className='danhao'>备注：</span><input type="text" placeholder='请填写备注信息' onChange={(v)=>danao('remark',v)}/>
                    </p>
                    <p className='fillLogistics-up-button'>
                        <span onClick={()=>setVisiable('none')}>取消</span>
                        <span onClick={()=>sure()}>确定</span>
                    </p>
                </div>
            </div>:null}
        </div>
    )
}