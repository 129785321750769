import React, { Component } from 'react'
import icon from "../../static/img/home/fj_02.png"
import "./style.less"
import icon2 from "../../static/img/home/ytsmall.jpg"
import icon3 from "../../static/img/home/y.png"
import icon4 from "../../static/img/home/yticon1.png"
import icon5 from "../../static/img/home/yticon2.png"
import JsBarcode from 'jsbarcode'
import { oderdetailRequest } from "../../api/request/order"
import { getstore } from "../../api/request/store"
const font = "10pt"
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            value: null,
            code: true,
            storeId: 1,
            storedata: {},
            orderdata: {},
            orderarr: {},
            orderId: "16365213139121038284643",
            dateTime: "",
            date: "",
            orderShortAddress: ""
        }
    }
    componentWillMount = async () => {
        const res = await oderdetailRequest(this.state.orderId)
        console.log(res)
        this.setState({
            orderdata: res.data[0][0],
            value: res.data[0][0].courierNumber,
            orderarr: res.data[1][0],
            orderShortAddress: res.data[0][0].orderShortAddress
        }, () => console.log(this.state.value))
        const res2 = await getstore({
            pharmacyId: this.state.storeId
        })
        //console.log(res2)
        this.setState({
            storedata: res2.data[0][0]
        })
        let yy = new Date().getFullYear()
        let mm = new Date().getMonth() + 1
        let dd = new Date().getDate()
        let hh = new Date().getHours()
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
            :
            new Date().getMinutes()
        let dateTime
        let date
        dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf
        date = yy + '-' + mm + '-' + dd
        this.setState({
            dateTime,
            date
        })
    }
    // 条形码生成,获取订单信息
    componentDidMount = async () => {
        JsBarcode(this.barcode, this.state.value, {
            displayValue: false,
            width: 6.5,
            height: 80,
            margin: 0,
        });
        JsBarcode(this.barcode2, this.state.value, {
            displayValue: false,
            width: 3.6,
            height: 40,
            margin: 0,
        });

    }
    doPrint = () => {
        this.setState({
            code: false
        }, () => {
            document.getElementsByTagName('body')[0].style.zoom = 0.50
            window.print();
            document.getElementsByTagName('body')[0].style.zoom = 1
            this.setState({
                code: true
            })
        })

    }
    render() {
        const { orderdata, storedata, orderarr, dateTime, date, orderShortAddress } = this.state
        return (
            <div>
                {this.state.code ? <div style={{ width: "800px", height: "35px" }}>
                    <button onClick={this.doPrint}>
                        <i className="fa fa-print"></i>打印
                    </button>
                </div> : null}
                <div className="zbox">
                    <div className="zbox-top">
                        <img src={icon} alt="" />
                        <h2>标准快递</h2>
                    </div>
                    <div className="zbox-num">
                        {orderShortAddress}
                    </div>
                    <div className="zbox-title">
                        <div>集包地</div>
                        <div>{date}</div>
                    </div>
                    <div className="zbox-shou">
                        <div>收 <br /> 件</div>
                        <div>
                            <p style={{ marginBottom: "10px" }}>
                                <span style={{ marginRight: "20px" }}>{orderdata.addressee}</span><span>{orderdata.phone}</span>
                            </p>
                            <p>
                                {orderdata.province + orderdata.city + orderdata.district + orderdata.address}
                            </p>
                        </div>
                    </div>
                    <div className="zbox-ji">
                        <div>寄 <br /> 件</div>
                        <div>
                            <p style={{ marginBottom: "10px" }}>
                                <span style={{ marginRight: "20px" }}>{storedata.accountName}</span>
                            </p>
                            <p>
                                {storedata.companyAddress}
                            </p>
                        </div>
                    </div>
                    <div className="zbox-ma1">
                        <div className="box-ma1-top">
                            <svg
                                ref={(ref) => {
                                    this.barcode = ref;
                                }}
                            />
                        </div>
                        <p>{orderdata.courierNumber}</p>
                    </div>
                    <div className="zbox-qian">
                        <div>您对此单的签收,代表您已经验货,确认商品信息无误,包装完好,没有划痕、破损等表面质量问题。</div>
                        <div>
                            <p>签收人:</p>
                            <p>时间:  <span className="qian-span">已检视</span></p>
                        </div>
                    </div>
                    <div className="zbox-ma2">
                        <div className="zbox-ma2-left">
                            <img src={icon} alt="" />
                        </div>

                        <div className="zbox-ma2-right">
                            <div className="box-ma2-top">
                                <svg
                                    ref={(ref) => {
                                        this.barcode2 = ref;
                                    }}
                                />
                            </div>
                            <p>{orderdata.courierNumber}</p>
                        </div>
                    </div>
                    <div className="zbox-shou">
                        <div>收 <br /> 件</div>
                        <div>
                            <p style={{ marginBottom: "10px" }}>
                                <span style={{ marginRight: "20px" }}>{orderdata.addressee}</span><span>{orderdata.phone}</span>
                            </p>
                            <p>
                                {orderdata.province + orderdata.city + orderdata.district + orderdata.address}
                            </p>
                        </div>
                    </div>
                    <div className="zbox-ji">
                        <div>寄 <br /> 件</div>
                        <div>
                            <p style={{ marginBottom: "10px" }}>
                                <span style={{ marginRight: "20px" }}>{storedata.accountName}</span>
                            </p>
                            <p>
                                {storedata.companyAddress}
                            </p>
                        </div>
                    </div>
                    <div className="zbox-bottom">
                        <div className="zbox-bottom-left">
                            备注
                        </div>
                        <div className="zbox-bottom-right">
                            <p>品名:{orderarr.medName}</p>
                            <p>数量:{orderarr.medQuantity}</p>
                            <p>备注:</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
