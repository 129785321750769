import React, { useState, useEffect, useRef } from 'react'
import FH from "../../../static/img/mine/fanhui.png"
import sousuo from "../../../static/img/sousuo.png"
import defaultImg from '../../../static/img/default.png'
import icon1 from "../../../static/img/left.png"
import close from '../../../static/img/close1.png'
import { Toast,Pagination} from 'antd-mobile'
import {allInvoice,allOrderInvoice} from "../../../api/request/yt"
import "./style.less"
import Pdf from '@mikecousins/react-pdf'
import getUrl from '../../../component/GetUrlParam'

export default function InvoiceDetails(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [invoiceList,setinvoiceList]=useState('')
    const [orderinvoiceList,setorderinvoiceList]=useState('')
    const [invoicePath,setinvoicePath]=useState('')
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);
    const [visiable,setvisiable]=useState(false)
    const [currentPage,setCurrentPage]=useState(1)
    const [totalPage,setTotalPages]=useState(1)
    const orderNumber=getUrl('orderNumber', props.location.search)
    useEffect(() => {
        console.log(orderNumber);
        if (userId) {
            firstNews1()
            firstNews2()
        }
    }, [])
    const firstNews1=async()=>{
        let res=await allInvoice(userId)
        console.log(res);
        if (res.data[0].length) {
            setinvoiceList(res.data[0])
        }
    }
    const firstNews2=async()=>{
        let res1= await allOrderInvoice({userId,orderNumber:getUrl('orderNumber', props.location.search)})
        console.log(res1);
        if (res1.data[0].length) {
            res1.data[0].map(item=>{
                let arr=[]
                let count1=0
                item.photo=[]
                item.count=0
                if (res1.data[1].length) {
                    res1.data[1].map(i=>{
                        if (i.orderNumber==item.orderNumber) {
                            arr.push(i.medPhoto)
                            count1+=1
                        }
                    })
                }
                item.photo=arr
                item.count=count1
            })
            setorderinvoiceList(res1.data[0][0])
            setinvoicePath(res1.data[0][0].invoicePath)
        }
    }
    const back=()=>{
        props.history.replace(`/mine/invoiceDetails?orderNumber=${orderNumber}`)
        props.history.push("/mine/controlInvoices")
    }
    const lookInvioce=()=>{
        if (invoicePath) {
            setvisiable(true)
        }else{
            Toast.info('商家还未开发票')
        }
    }
    const dianji=()=>{
        setvisiable(false)
    }
    const onChangePage=(page)=>{
        setCurrentPage(page)
    }
    const onDocumentLoadSuccess=(totalPage)=>{
        console.log(totalPage);
        setTotalPages(totalPage._pdfInfo.numPages);
        setCurrentPage(1);
    }
    return (
        <div style={{backgroundColor:'#fff',height:"100vh"}}>
            <div className='invoiceDetails-alltop'>
                <div className='invoiceDetails-top'>
                    <img src={icon1} alt="" onClick={() => back()} />   
                </div>
                <div className='already-invoice'>
                    {invoicePath?<p>已开票</p>:<p>未开票</p>}
                    {invoicePath?<p>已开发票金额：￥{((orderinvoiceList.orderAmount+orderinvoiceList.refundpricebyShop)/100).toFixed(2)}</p>:
                    <p>预期开发票金额：￥{((orderinvoiceList.orderAmount+orderinvoiceList.refundpricebyShop)/100).toFixed(2)}</p>}
                </div>
            </div>
            <p className='invoice-title'>电子普通发票</p>
            <p className='invoice-between'>
                <span>发票内容</span>
                <span>商品明细</span>
            </p>
            <p className='invoice-between'>
                <span>抬头类型</span>
                <span>个人</span>
            </p>
            <p className='invoice-between'>
                <span>抬头名称</span>
                <span>个人</span>
            </p>
            <p className='invoice-between'>
                <span>开票金额</span>
                <span>{((orderinvoiceList.orderAmount+orderinvoiceList.refundpricebyShop)/100).toFixed(2)}</span>
            </p>
            <p className='invoice-between'>
                <span>开票时间</span>
                <span>{orderinvoiceList.invoiceTime?orderinvoiceList.invoiceTime:'---'}</span>
            </p>
            <p className='invoice-between'>
                <span>申请时间</span>
                <span>{orderinvoiceList.createTime}</span>
            </p>
            <p className='mendian-name'>{orderinvoiceList.pharmacyName}</p>
            <p className='order-details'>
                <p className='order-details-img'>
                    {orderinvoiceList.photo&&orderinvoiceList.photo.length?orderinvoiceList.photo.map(item=>
                        (<img src={item!="null"?item:defaultImg} alt="" />)):null}
                </p>
                <p className='order-details-price'>
                    <span>￥{((orderinvoiceList.orderAmount+orderinvoiceList.refundpricebyShop)/100).toFixed(2)}</span>
                    <span>共{orderinvoiceList.count}件</span>
                </p>
            </p>
            <p className='invoice-between'>
                <span>订单状态</span>
                <span>{orderinvoiceList.orderState== -2?'待付款':
                orderinvoiceList.orderState==-1?'备货':
                orderinvoiceList.orderState==-11?'确认订单':
                orderinvoiceList.orderState=='0'?'运输':
                orderinvoiceList.orderState==1?'待自取':
                orderinvoiceList.orderState==2?'待评价':
                orderinvoiceList.orderState==3?'已完成':
                orderinvoiceList.orderState==4?'已取消':
                orderinvoiceList.orderState==5?'已申请退款':
                orderinvoiceList.orderState==6?'已退款':
                orderinvoiceList.orderState==7?'申请失败':
                orderinvoiceList.orderState==-3?'待接收订单':
                orderinvoiceList.orderState==-4?'待配送':
                orderinvoiceList.orderState==8?'自提订单':
                orderinvoiceList.orderState==9?'自提完成':
                orderinvoiceList.orderState==10?'商家申请退款':
                orderinvoiceList.orderState==-5?'转账中待确认':
                orderinvoiceList.orderState==12?'售后':
                orderinvoiceList.orderState==13?'退款凭证待审核':
                orderinvoiceList.orderState==14?'退款凭证审核失败':
                orderinvoiceList.orderState==15?'退款凭证审核通过':
                orderinvoiceList.orderState==16?'零售有处方药订单支付成功状态':''}</span>
            </p>
            <p className='invoice-between'>
                <span>订单编号</span>
                <span>{orderinvoiceList.orderNumber}</span>
            </p>
            <p className='invoice-between'>
                <span>下单时间</span>
                <span>{orderinvoiceList.createTime}</span>
            </p>
            <div onClick={()=>lookInvioce()} className='look-invoice'>查看发票</div>
            {visiable ? <div className='invoice-outer'>
                    <img className='close' src={close} alt="" onClick={() => dianji()} />
                    {/* <img className='pic' src={invoicepath} alt="" /> */}
                    {/* <PDF className='pic'
                file={"data:application/pdf;base64,"+invoicepath}
            /> */}
                    <Pdf className='pdf-top' scale={0.8} file={invoicePath}
                        onDocumentLoadSuccess={onDocumentLoadSuccess} page={currentPage} />
                <Pagination className='pdf-bottom' onChange={(page) => onChangePage(page)} total={totalPage} current={currentPage} />
                </div> : null}
        </div>
    )
}
