import { axiosInstance } from '../config'

//药品详情
export const meddetailRequest = (medId) => {
    return axiosInstance.get(`/api/hosp/medicine/details?medId=${medId}`)
}

//药品分类
export const medcategoryRequest = () => {
    return axiosInstance.get('/api/hosp/medicine/category')
}

//药品小分类
export const medsubcategoryRequest = (categoryId) => {
    return axiosInstance.get(`/api/hosp/medicine/classify?categoryId=${categoryId}`)
}

//药房分类
export const pharmacyClassifyRequest = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/classify`, data)
}

//药品搜索
export const medsearchRequest = (data) => {
    console.log(data,"==============================");
    if((data.categoryId === '0' && data.classifyId === null)
    || (data.pharmacyCategoryId === '0' && data.pharmacyClassifyId === null)) {
        const params = {
            recommend: data.recommend,
            sales: data.sales,
            distance: data.distance,
            price: data.price,
            pharmacyId: data.pharmacyId,
            latitude: data.latitude,
            longitude: data.longitude,
            symptomId: data.symptomId,
            content: data.content,
            page: data.page,
            size: data.size
        }
        return axiosInstance.post('/api/hosp/medicine/search',params)
    } else {
        //药品按类别查询
        if(localStorage.getItem("pharmacyId")=="0"||localStorage.getItem("pharmacyId")=="25"){
            return axiosInstance.get(`/api/hosp/medicine/list?recommend=${data.recommend}&sales=${data.sales}&distance=${data.distance}&latitude=${data.latitude}&longitude=${data.longitude}&price=${data.price}&page=${data.page?data.page:null}&size=${data.size?data.size:null}&symptomId=${data.symptomId}${data.categoryId?'&categoryId='+data.categoryId : ''}${data.classifyId?'&classifyId='+data.classifyId : ''}${data.pharmacyCategoryId?'&pharmacyCategoryId='+data.pharmacyCategoryId : ''}${data.pharmacyClassifyId?'&pharmacyClassifyId='+data.pharmacyClassifyId : ''}`)
        }else{
            return axiosInstance.get(`/api/hosp/medicine/list?recommend=${data.recommend}&sales=${data.sales}&pharmacyId=${data.pharmacyId2?data.pharmacyId2:null}&distance=${data.distance}&latitude=${data.latitude}&longitude=${data.longitude}&price=${data.price}&page=${data.page}&size=${data.size}&symptomId=${data.symptomId}${data.categoryId?'&categoryId='+data.categoryId : ''}${data.classifyId?'&classifyId='+data.classifyId : ''}${data.pharmacyCategoryId?'&pharmacyCategoryId='+data.pharmacyCategoryId : ''}${data.pharmacyClassifyId?'&pharmacyClassifyId='+data.pharmacyClassifyId : ''}`)
        }
       
    }
}

// 查询症状信息
export const symptomRequest = () => {
    return axiosInstance.get('/api/hosp/symptom/query')
}

// 活动药品列表	
export const actMedListRequest = (data) => {
    return axiosInstance.get(`/api/hosp/medicine/activelist?activeType=${data.type}${data.pharmacyId===0?'':'&pharmacyId='+data.pharmacyId}`)
}

// 一维码查询
export const qrcodeRequest = (qrcode) => {
    return axiosInstance.get(`/api/hosp/medicine/onecode?qrcode=${qrcode}`)
}

// 获取一类药品总数
export const getonemedAll = (data) => {
    return axiosInstance.post(`/api/hosp/medicine/get-count`,data)
}
// 查询药品优惠劵	
export const getCoupon = (pharmacyId,medQrcode,userId) => {
    return axiosInstance.get(`/api/hosp/medicine/get-coupon?pharmacyId=${pharmacyId}&medQrcode=${medQrcode}&userId=${userId}`)
}
// 查询药品折扣	
export const medDiscount = (data) => {
    return axiosInstance.post(`/api/hosp/medicine/med-discount`,data)
}
// 更新库存中间表	
export const updateStockOccupy = (data) => {
    return axiosInstance.post(`/api/hosp/order/update-stock-occupy`,data)
}