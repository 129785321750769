import React, { Component } from "react";
import { connect } from "react-redux";
import { Toast } from "antd-mobile";
import { getUserSH } from "../../api/request/user";
import cls from "classnames";
import getUrl from "../../component/GetUrlParam";
// import Scroll from '../../component/scroll'
import "./style.less";
import { resetdirect } from "../../redux/user.redux";
import FootNav from "../../component/FootNav";

import {
  medcategoryRequest,
  medsubcategoryRequest,
  pharmacyClassifyRequest,
} from "../../api/request/med";
import { wxConfig } from "../../component/scan/index";
import { oneMessage } from "../../api/request/msg";
class Sort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId:
        localStorage.type == 1
          ? localStorage.userId1
          : localStorage.type == 2
          ? localStorage.userId2
          : localStorage.userId3,
      pharmacyId: localStorage.pharmacyId || "0",
      searchValue: "",
      subCategories: [],
      selectMenuId: undefined,
      categoryArr: [],
      mycategory: [],
      xtCount: 0,
      yhCount: 0,
      ddCount: 0,
      home: "",
      categoryId0: 2,
    };
  }

  componentWillMount = () => {
    const { userId, categoryId0 } = this.state;
    this.setState({
      categoryId0: getUrl("categoryId0", this.props.location.search)
        ? getUrl("categoryId0", this.props.location.search)
        : 2,
    });
    if (this.state.pharmacyId == "0" || this.state.pharmacyId == "25") {
      medcategoryRequest().then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          this.setState({ mycategory: res.data[0] });
        }
      });
      medsubcategoryRequest(
        getUrl("categoryId0", this.props.location.search)
          ? getUrl("categoryId0", this.props.location.search)
          : categoryId0
      ).then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          this.setState({ subCategories: res.data[0] });
        }
      });
    } else {
      let mycategory = this.state.mycategory;
      let subCategories = [];
      const params = {
        pharmacyId: localStorage.getItem("pharmacyId"),
      };
      pharmacyClassifyRequest(params).then((res) => {
        console.log(res);
        if (res.code === 1 && res.data[0].length > 0) {
          res.data[0].forEach((item, index) => {
            if (item.parentCode === "0") {
              mycategory.push({
                categoryId: item.classifyId,
                categoryCode: item.classifyCode,
                categoryName: item.classifyName,
                categoryPng: item.classifyPng,
              });
            } else if (item.parentCode === res.data[0][0].classifyCode) {
              subCategories.push({
                categoryId: res.data[0][0].classifyId,
                ...item,
              });
            }
          });
          this.setState({
            categoryArr: res.data[0],
            mycategory: mycategory,
            subCategories: subCategories,
          });
        }
      });
    }
    oneMessage(userId).then((res) => {
      console.log(res);
      if (res.code == 1) {
        if (res.data[0].length) {
          this.setState({
            xtCount: res.data[0][0].allNews,
          });
        }
        if (res.data[1].length) {
          this.setState({
            yhCount: res.data[1][0].allNews1,
          });
        }
        if (res.data[2].length) {
          this.setState({
            ddCount: res.data[2][0].allNews2,
          });
        }
      }
    });
  };
  componentDidMount = async () => {
    this.setState({
      home: getUrl("home", this.props.location.search),
    });
    //   if (this.state.pharmacyId == "25"&&this.state.userId) {
    //     const res = await getUserSH({
    //         userId: this.state.userId
    //     })
    //     console.log(res)
    //     if (res.data[0].length == 0) {
    //         Toast.fail("未经资质审核")
    //         this.props.history.push("/qualifications")
    //     } else if (res.data[0][0].entrust_state != 1 || res.data[0][0].card_state != 1 || res.data[0][0].license_state != 1) {
    //         Toast.fail("资质审核未通过")
    //         this.props.history.push("/await")
    //     }
    // }
  };

  cameraScan = () => {
    wxConfig();
  };

  searchVal = ({ target: { value, name, type } }) => {
    this.setState({
      searchValue: value,
    });
  };

  searchMed = () => {
    // let hislist = localStorage.hislist?JSON.parse(localStorage.hislist):[]
    // if(this.state.searchValue !== '') {
    //   localStorage.hislist = hislist.push(this.state.searchValue)
    //   this.props.history.push(`/medlist/0/0/${this.state.searchValue}`)
    // }
    this.props.history.push("/search");
  };

  menuClick = (val) => {
    console.log(val);
    if (this.state.pharmacyId === "0" || this.state.pharmacyId === "25") {
      medsubcategoryRequest(val.categoryId).then((res) => {
        this.setState({
          subCategories: res.data[0],
          selectMenuId: val.categoryId,
        });
      });
    } else {
      const categoryArr = this.state.categoryArr;
      let subCategories = [];
      categoryArr.forEach((item, index) => {
        if (item.parentCode === val.categoryCode) {
          subCategories.push({
            categoryId: val.categoryId,
            ...item,
          });
        }
      });
      this.setState({
        subCategories: subCategories,
        selectMenuId: val.categoryId,
      });
    }
  };
  subItemClick = (val) => {
    const { home } = this.state;
    // console.log(val)
    this.props.history.replace(
      `/classify?home=${2}&categoryId0=${val.categoryId}`
    );
    this.props.history.push(
      `/medlist/${val.categoryId}/${val.classifyId}/${
        val.classifyName
      }?home=${2}&categoryId0=${val.categoryId}`
    );
  };

  render() {
    const {
      userId,
      mycategory,
      categoryId0,
      subCategories,
      selectMenuId,
      ddCount,
      yhCount,
      xtCount,
    } = this.state;
    const menuModalItemStyle = (categoryId, compareArr, compareId) => {
      console.log(selectMenuId);

      if (selectMenuId === undefined) {
        this.setState({
          // selectMenuId: compareArr[0].categoryId
          selectMenuId: categoryId0,
        });
        // const cid = compareArr[0].categoryId
        const cid = categoryId0;
        return cls("item", cid == compareId ? "active" : null);
      }
      return cls("item", categoryId == compareId ? "active" : null);
    };
    return (
      <>
        <div className="classify-header flex-apart-row">
          <div onClick={this.cameraScan}>
            <i className="iconfont-l icon-saoyisao"></i>
          </div>
          <div
            className="classify-search-container flexr"
            onClick={this.searchMed}
          >
            <div>
              <i className="iconfont icon-suosou"></i>
            </div>
            <input
              type="text"
              placeholder="搜索药名"
              onChange={this.searchVal}
              readOnly
            />
          </div>
          {/* <i className='iconfont-l icon-xiaoxi1'
            onClick={() => {
              if(userId) {
                this.props.history.push('/tidings')
              } else {
                  this.props.redirectDispatch(this.props.location.pathname)
                  this.props.history.push('/login')
              }
            }}></i>
            {(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))!=0?
              <span className='count'>{(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount))<100?(parseInt(xtCount)+parseInt(yhCount)+parseInt(ddCount)):'99+'}</span>:null} */}
        </div>
        <div className="classify-body">
          <div className="menu">
            <div className="menu-scroll">
              {mycategory.map((v) => (
                <div
                  key={v.categoryId}
                  className={menuModalItemStyle(
                    v.categoryId,
                    this.state.mycategory,
                    selectMenuId
                  )}
                  onClick={() => this.menuClick(v)}
                >
                  <span>{v.categoryName}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="detail">
            <div className="d-box">
              {subCategories.map((v) => (
                <div
                  key={v.classifyId}
                  onClick={() => this.subItemClick(v)}
                  className="ill-item flex-col"
                >
                  <div className="img">
                    <img src={v.classifyPng} alt="" />
                  </div>
                  <span className="title">{v.classifyName}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <FootNav />
      </>
    );
  }
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {
    redirectDispatch(data) {
      dispatch(resetdirect(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
