import React, { useState, useEffect } from "react";
import {
  Toast,
  Modal,
  List,
  Radio,
  TextareaItem,
  Button,
  ImagePicker,
} from "antd-mobile";
import { Popup } from "antd-mobile-v5";
import Card from "../../baseui/card/index";
import BottomButton from "../../baseui/fuctionbutton/index";
import Meditem from "./orderitem/index";
import { RightOutline } from "antd-mobile-icons";
import { adressListRequest } from "../../api/request/adress";
import {
  oderdetailRequest,
  upOrderStuRequest,
  applyRefundRequest,
  addRemittance,
  remittanceList,
  delRemittance,
  ytGetwl,
  jdGetwl,
  stGetwl,
  updatePartrefundState,
  applyPartRequest,
  changenum,
  refundView,
  refundUserview,
  addCode,
  lookCode,
  getKfphone,
  isZiti,
  qishouJuli,
  getOrdersate,
  huifuCouponState,
} from "../../api/request/order";
import {
  getTau,
  getDetails,
  getKnightInfo,
  getcancelreasonlist,
  getstatus,
  addstore,
  uploadimg,
  getstorestatus,
} from "../../api/request/logistics";
import { storeDetailsRequest } from "../../api/request/store";
import { meddetailRequest } from "../../api/request/med";
import { integralIsuse, getUserIntegral } from "../../api/request/integral";
import { pharmacyStorecs, tuiNum, allFreights } from "../../api/request/cart";
import {
  aftersaleMedList,
  aftersaleList,
  getState,
  getFrequencyLimit,
  updateFrequencyLimit,
} from "../../api/request/aftersale";
import {
  getPatientMember,
  patientinfo,
  updatePatientMember,
  addPatientMember,
  isChatinfo,
  addChatinfo,
  getChatinfo,
  wzstate,
  docterlist,
  getAllorderwz,
} from "../../api/request/wzpatient";
import { docWenzhen } from "../../api/request/home";
import { updateStockOccupy } from "../../api/request/med";
import { deliveryLocation } from "../../api/request/user";

import getUrl from "../../component/GetUrlParam";
import { uploadImg } from "../../api/request/upload";
import { zipImg } from "../../component/zipImage/index";
import "./orderdetail.less";
import "../../static/css/iconfont.css";
import quxiao from "../../static/img/quxiao.png";
import lianxi from "../../static/img/lianxi.png";
import jixu from "../../static/img/jixu.png";
import kefu from "../../static/img/kefu.png";
import shangjia from "../../static/img/shangjia.png";
import xiadan from "../../static/img/xiadan.png";
import xiadan2 from "../../static/img/xiadan2.png";
import close from "../../static/img/close.png";
import zhifu from "../../static/img/zhifu.png";
import pingjia from "../../static/img/pingjia.png";
import tuikuan from "../../static/img/tuikuan.png";
import you from "../../static/img/you.png";
import qishou from "../../static/img/qishou.png";
import dizhi from "../../static/img/dizhi.png";
import icon1 from "../../static/img/icon/qs.png";
import icon2 from "../../static/img/icon/sj.png";
import BDMapw from "../Map/BDMapw";
import yuan from "../../static/img/yuan.png";
import yuan2 from "../../static/img/yuan2.png";
import kxy from "../../static/img/kxy.png";
import sx from "../../static/img/sx.png";
import Odersome from "./orderitem/ordersome";
import { wzUrl, baseUrl, baseUrl0, code1 } from "../../publicurl";
const { alert } = Modal;
const RadioItem = Radio.RadioItem;
const BMap = window.BMap;
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
  const userId =
    localStorage.type == "1"
      ? localStorage.userId1
      : localStorage.type == "2"
      ? localStorage.userId2
      : localStorage.userId3;
  const type = localStorage.type;
  const reasons = [
    "不想要了/临时有事",
    "拍错/多拍/不想要了",
    "地址电话填错",
    "忘记用优惠券",
    "商家联系我取消",
    "骑手联系我取消",
    "其它原因",
  ];
  const reasons1 = [
    "订单信息填写错误",
    "重复下单了",
    "订单长时间未分配骑手",
    "商品缺货/无法出货/已售完",
    "商户发错单",
    "商户/顾客自身定位错误",
    "商户改其他第三方配送",
    "顾客下错单/临时不想要了",
    "顾客自取/不在家/要求另改时间配送",
    "其它原因",
  ];

  const [files1, setFiles1] = useState([]);
  const [evaluatePic1, setEvaluatePic1] = useState([]);
  const [evaluatePic2, setEvaluatePic2] = useState();
  const [remittanceId, setremittanceId] = useState();

  const [state, setState] = useState("-2");
  const [orderDetail, setOrderDetail] = useState(null);
  const [orderAddress, setOrderAddress] = useState(null);
  const [medDetail, setMedDetail] = useState(null);
  const [radioVal, setRadioVal] = useState("");
  const [refundModal, setRefundModal] = useState(false);
  const [reasonContent, setReasonContent] = useState("");
  const orderNumber = getUrl("tradeno", props.location.search);
  const status = getUrl("status", props.location.search);
  const delivery = getUrl("delivery", props.location.search);
  const mine = getUrl("mine", props.location.search);

  const [visible1, setVisible1] = useState("none");
  const tabId = getUrl("tabId", props.location.search)
    ? getUrl("tabId", props.location.search)
    : 0;
  // const [visible5, setVisible5] = useState('none');
  // const [visible6, setVisible6] = useState('none');//物流信息
  const [visiable7, setVisiable7] = useState("none");
  const [visible8, setVisible8] = useState("none");

  const [visible2, setVisible2] = useState("none");
  const [visible3, setVisible3] = useState(false);
  const [logisticsarr, setLogisticsarr] = useState([]);
  const [partRefundState, setpartRefundState] = useState(0);
  const [myBox, setmyBox] = useState(null);
  const [orderMedId, setorderMedId] = useState();
  const [aftersaleId, setaftersaleId] = useState();
  const [aftersaleId1, setaftersaleId1] = useState();
  const [aftersaleId2, setaftersaleId2] = useState();
  const [aftersaleId3, setaftersaleId3] = useState();
  const [aftersaleId4, setaftersaleId4] = useState();
  const [aftersaleId5, setaftersaleId5] = useState();
  const [refundOriginator, setrefundOriginator] = useState();
  const [randomNum, setrandomNum] = useState();
  const [arr, setArr] = useState([]);
  const [arr1, setArr1] = useState([]);
  const [arr2, setArr2] = useState([]);
  const [arr3, setArr3] = useState([]);
  const [arr4, setArr4] = useState([]);
  const [arr5, setArr5] = useState([]);
  const [consultationId, setconsultationId] = useState();
  const [arr000, setArr000] = useState([]);

  const [tel, setTel] = useState();
  const [orderShipping, setorderShipping] = useState("2"); //是否自提  1，自提  2，配送

  const [count, setCount] = useState([]);
  const [count1, setCount1] = useState([]);
  const [count2, setCount2] = useState([]);

  const [companyName, setcompanyName] = useState();
  const [bankName, setbankName] = useState();
  const [bankAccount, setbankAccount] = useState();

  const [time1, setTime] = useState("");
  const [paytime, setPayTime] = useState("");
  const [orderstate, setOrderState] = useState("");
  const [orderState1, setorderState1] = useState("");
  const [oldOrderStu, setoldOrderStu] = useState(""); //前一个状态
  const [medClassify, setmedClassify] = useState("");
  const [total, setTotal] = useState();
  const [countQuantity, setcountQuantity] = useState();
  const [orderState2, setorderState2] = useState();
  const [patientPhone, setpatientPhone] = useState();
  const [patientAvatar, setpatientAvatar] = useState();
  const [patientName, setpatientName] = useState();
  const [patientId, setpatientId] = useState();
  const [consultationStu, setconsultationStu] = useState();
  const [ordermedacail, setOrdermedacail] = useState([]);
  const [startTime, setstartTime] = useState(0); //营业时间
  const [endTime, setendTime] = useState(0); //打样时间
  const [promoteStu, setpromoteStu] = useState("0"); //是否营业0营业1打烊
  const [frequencyLimit, setFrequencyLimit] = useState(); //剩余售后次数
  const [isAfterSale, setisAfterSale] = useState(); //是否售后中  1 否  2是
  const [isAftersaleOrder, setisAftersaleOrder] = useState(); //是否申请过售后  1 否  2是

  const [receivingTime, setReceivingTime] = useState(); //确认收货时间

  // 店铺名字
  const [storename, setstorename] = useState("");
  const [pharmacyId, setpharmacyId] = useState();
  const [timeb, setTimeb] = useState(new Date().getTime());

  // 店铺图片
  const [storeimg, setstoreimg] = useState("");
  // 店铺经纬度
  const [storelongitude, setstorelongitude] = useState(null);
  const [storelatitude, setstorelatitude] = useState(null);

  // 骑手信息
  const [qishounews, setQishounews] = useState({});
  const [distance, setDistance] = useState(0);

  // 订单状态
  const [orderstatus, setorderstatus] = useState(null);
  // const [paymoney,setPaymoney]= useState(0);
  let [longitude, setlongitude] = useState(
    localStorage.longitude ? localStorage.longitude : 104.06576236480714
  );
  let [latitude, setlatitude] = useState(
    localStorage.latitude ? localStorage.latitude : 30.65745887548027
  );
  useEffect(() => {
    // 查询商品是否是自提订单
    isZiti({ orderNumber }).then((res) => {
      setorderShipping(res.data[0][0].orderShipping);
    });

    deliveryLocation({ orderNumber }).then((res) => {
      console.log(res);
      if (res.data.data) {
        setQishounews(res.data.data);
        qishouJuli({
          orderNumber: orderNumber,
          latitude: res.data.data.rider_latitude,
          longitude: res.data.data.rider_longitude,
        }).then((res2) => {
          console.log(res2);
          setDistance(res2.data[0][0].distance);
          // setVisible6('block')
        });
      }
    });
  }, [userId]);
  // console.log(userId);
  // let address1;
  // orderAddress && orderAddress.map((item, index) => (
  //     item.addressDefault === '1' ? address1 = item : null
  // ))
  const lookReason = () => {
    let params = {
      orderNumber,
      userId,
    };
    refundUserview(params).then((res) => {
      console.log(res);
      let a = res.data[0][0].origrefundReason;
      alert("商家退款理由", a, [
        {
          text: "确认",
          onPress: () => console.log("cancel"),
          style: "default",
        },
      ]);
    });
  };
  let nowtime00 = new Date();
  let timecha = nowtime00.getHours() * 60 + nowtime00.getMinutes();

  useEffect(() => {
    // let time=new Date()
    // let timecha=time.getHours()*60+time.getMinutes()

    oderdetailRequest(orderNumber).then((res) => {
      console.log(res);
      if (res.code === 1 && res.data[0].length > 0) {
        setReceivingTime(res.data[0][0].receivingTime);
        setisAfterSale(res.data[0][0].isAfterSale);
        setisAftersaleOrder(res.data[0][0].isAftersaleOrder);
        setoldOrderStu(res.data[0][0].oldOrderStu);
        setpharmacyId(res.data[0][0].pharmacyId);
        setTime(res.data[0][0].createTime.substring(0, 19));
        setPayTime(res.data[0][0].payTime);
        setOrderState(res.data[0][0].orderState);
        if (res.data[0][0].partRefundState) {
          setpartRefundState(res.data[0][0].partRefundState);
        }
        if (res.data[0][0].frequencyLimit == null) {
          getFrequencyLimit().then((res1) => {
            console.log(res1);
            setFrequencyLimit(res1.data[0][0].frequencyLimit);
            updateFrequencyLimit(
              res1.data[0][0].frequencyLimit,
              orderNumber
            ).then((res2) => {
              console.log(res2);
            });
          });
        } else {
          setFrequencyLimit(res.data[0][0].frequencyLimit);
        }
        // console.log(orderAddress);
        setstartTime(
          parseInt(res.data[0][0].pharmacyStartTime.substring(0, 2) * 60) +
            parseInt(res.data[0][0].pharmacyStartTime.substring(3, 5))
        );
        setendTime(
          parseInt(res.data[0][0].pharmacyEndTime.substring(0, 2) * 60) +
            parseInt(res.data[0][0].pharmacyEndTime.substring(3, 5))
        );
        setpromoteStu(res.data[0][0].promoteStu);
        // console.log(timecha,startTime,endTime);
        // console.log(startTime,endTime);
        // if (startTime<timecha&&timecha<endTime) {
        //     setnowTime(1)
        // }else{
        //     setnowTime(-1)
        // }
        setArr000(res.data[0]);
        setOrderAddress(res.data[0][0]);
        setorderState2(res.data[0][0].orderState);
        setOrdermedacail(res.data[1]);
        setState(res.data[0][0].orderState);
        setOrderDetail(res.data[0][0]);
        setMedDetail(res.data[1]);
        if (res.data[0][0].pharmacyId) {
          getKfphone(res.data[0][0].pharmacyId).then((res3) => {
            console.log(res3);
            setTel(res3.data[0][0].servicePhone);
          });
        }
        let companyId = res.data[0][0].pharmacyId;
        pharmacyStorecs(companyId).then((res) => {
          // console.log(res);
          setcompanyName(res.data[0][0].companyName);
          setbankName(res.data[0][0].bankName);
          setbankAccount(res.data[0][0].bankAccount);
        });
        getstore(companyId);
      }
    });
    // // 用户售后订单列表
    if (type == "1") {
      aftersaleList({ userId }).then((res) => {
        console.log(res);
        let array = [];
        let array1 = [];
        let array2 = [];
        let array3 = [];
        let array4 = [];
        let array5 = [];

        let orderCount = [];
        let orderCount1 = [];
        let orderCount2 = [];

        if (res.data[0].length > 0) {
          res.data[0].map((item) => {
            // 售后状态 1.售后待确认,2.等待退货,3.申请被退回,4.用户取消,5.用户已退回货物,
            // 6.售后处理中,7.商家已收到退货,8.售后完成
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "1"
            ) {
              array.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "2"
            ) {
              array1.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "5"
            ) {
              array2.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "6"
            ) {
              array3.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "7"
            ) {
              array4.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (
              item.order_number == orderNumber &&
              item.aftersale_state == "8"
            ) {
              array5.push({
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
            if (item.order_number == orderNumber) {
              orderCount.push(item);
              if (item.aftersale_state == "4") {
                orderCount1.push(item);
              }
              if (item.aftersale_state == "3") {
                orderCount2.push(item);
              }
            }
          });
        }
        // console.log(orderCount,orderCount1,orderCount2);
        // console.log(array);
        // console.log(array1);
        // console.log(array2);
        // console.log(array3);
        // console.log(array4);
        // console.log(array5);

        setCount(orderCount);
        setCount1(orderCount1);
        setCount2(orderCount2);
        setaftersaleId(array);
        setaftersaleId1(array1);
        setaftersaleId2(array2);
        setaftersaleId3(array3);
        setaftersaleId4(array4);
        setaftersaleId5(array5);
        // if (array.length==0&&array1.length==0&&array2.length==0&&array3.length==0&&array4.length==0&&array5.length==0&&oldOrderStu) {
        //     upOrderStuRequest({ orderState: oldOrderStu, orderNumber: orderNumber }).then(res1 => {
        //         if (res1.code==1) {
        //             startOrder()
        //         }
        //     })
        // }
      });
    }
  }, [userId]);
  const getstore = async (pharmacyId) => {
    const storedata = await storeDetailsRequest(pharmacyId, userId);
    console.log(storedata);
    if (storedata.data[0].length) {
      setstorename(storedata.data[0][0].pharmacyName);
      setstoreimg(storedata.data[0][0].pharmacyPhoto);
      setstorelatitude(storedata.data[0][0].latitude);
      setstorelongitude(storedata.data[0][0].longitude);
    }
    //createMap()
  };
  useEffect(() => {
    if (type == 1 && (orderState2 == "8" || orderState2 == "1")) {
      lookCode(orderNumber).then((res) => {
        if (res.data[0][0].orderShippingCode) {
          setrandomNum(res.data[0][0].orderShippingCode);
        } else {
          var Num = "";
          for (var i = 0; i < 4; i++) {
            Num += Math.floor(Math.random() * 10);
          }
          addCode({ orderNumber, orderShippingCode: Num }).then((res) => {
            console.log(res);
            if (res.code == 1) {
              setrandomNum(Num);
            }
          });
        }
      });
    }
  }, [orderState2]);
  useEffect(() => {
    if (type == "2") {
      if (count.length > 0 && count.length == count1.length) {
        getState({ orderNumber }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            upOrderStuRequest({
              orderState: res.data[0][0].oldOrderStu,
              orderNumber: orderNumber,
            }).then((res1) => {
              // window.location.reload()
            });
          }
        });
      }
    }
  }, [count, count1]);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId) {
      let a = [];
      aftersaleId.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        //  获取售后订单商品列表
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr(a);
      });
    }
    // }
  }, [aftersaleId]);
  // console.log(arr);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId1) {
      let a = [];
      aftersaleId1.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr1(a);
      });
    }
    // }
  }, [aftersaleId1]);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId2) {
      let a = [];
      aftersaleId2.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr2(a);
      });
    }
    // }
  }, [aftersaleId2]);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId3) {
      let a = [];
      aftersaleId3.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr3(a);
      });
    }
    // }
  }, [aftersaleId3]);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId4) {
      let a = [];
      aftersaleId4.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr4(a);
      });
    }
    // }
  }, [aftersaleId4]);
  useEffect(() => {
    // if (type=='2') {
    if (aftersaleId5) {
      let a = [];
      aftersaleId5.map((item) => {
        let params = {
          aftersaleId: item.aftersale_id,
        };
        if (item.aftersale_type == "发票类型") {
          a.push({
            aftersale_id: item.aftersale_id,
            aftersale_state: item.aftersale_state,
            aftersale_type: item.aftersale_type,
          });
        }
        aftersaleMedList(params).then((res) => {
          console.log(res);
          if (res.data[0].length > 0) {
            if (res.data[0][0].aftersale_id == item.aftersale_id) {
              a.push({
                med_id: res.data[0][0].med_id,
                aftersale_id: item.aftersale_id,
                aftersale_state: item.aftersale_state,
                aftersale_type: item.aftersale_type,
              });
            }
          }
        });
        setArr5(a);
      });
    }
    // }
  }, [aftersaleId5]);
  // console.log(arr);
  // console.log(arr1);
  // 刷新物流信息
  const shuaxin = () => {
    deliveryLocation({ orderNumber }).then((res) => {
      console.log(res);
      if (res.data.data) {
        setQishounews(res.data.data);
        qishouJuli({
          orderNumber: orderNumber,
          latitude: res.data.data.rider_latitude,
          longitude: res.data.data.rider_longitude,
        }).then((res2) => {
          console.log(res2);
          setDistance(res2.data[0][0].distance);
          // setVisible6('block')
        });
      }
    });
  };
  const getOrder = async () => {
    // const orderid = res.data[0][0].courierNumber
    // console.log(orderid, orderNumber)
    // const res2 = await getTau()
    // const data = JSON.parse(res2.data.business_data).access_token
    // 查询订单信息
    // const res3 = await getDetails({
    //     // order_id: orderid,
    //     // access_token: data
    // })
    // console.log(JSON.parse(res3.data.business_data))
    // console.log(JSON.parse(res3.data.business_data))
    if (orderAddress && orderAddress.courierNumber) {
      //   查询骑手信息
      const res4 = await getDetails({
        orderId: orderAddress.courierNumber,
        storeId: orderAddress.pharmacyId,
      });
      console.log(res4);
      // qishouJuli({orderNumber:orderNumber,latitude:,longitude:}).then(res=>{
      //     console.log(res);
      // })
    }
    // console.log(JSON.parse(res4.data.business_data))

    // 查询取消原因列表
    // const res5 = await getcancelreasonlist({
    //     order_id: orderid,
    //     access_token: data
    // })
    // console.log(JSON.parse(res5.data.business_data))

    // 新增门店
    // const res6=await addstore()
    // console.log(res6)
    // 图片上传
    // const res7=await uploadimg()
    // console.log(res7)
    //门店状态回调
    // const res8=await getstorestatus({
    //     callbackBusinessType:"orderStatusNotify",
    //     info:orderid
    // })
    // let orderstatusdata=JSON.parse(decodeURIComponent(res8.data[0][0].callbackInfo))
    // setorderstatus(orderstatusdata.param.order_status)
    //console.log(orderstatusdata.param.order_status)
    // console.log(JSON.parse(decodeURIComponent(res8.data[0][0].callbackInfo)))
  };
  useEffect(() => {
    // let a=localStorage.getItem('phone')
    // console.log(a);
    let b;
    // if (a) {
    //     b=a.substring(1,a.length-1)
    // }else{
    b = localStorage.getItem("userAcount1");
    // }

    console.log(b);
    if (b) {
      patientinfo({ patientPhone: b }).then((res) => {
        console.log(res);
        if (res.code == 1 && res.data.data[0].length) {
          getAllorderwz({
            userId: res.data.data[0][0].patientId,
            orderNumber,
          }).then((res1) => {
            console.log(res1);
            if (res1.data.data[0].length) {
              setconsultationId(res1.data.data[0][0].consultationId);
              setconsultationStu(res1.data.data[0][0].consultationStu);
              docWenzhen({ patientPhone: b }).then((res) => {
                console.log(res);
                if (res.code == 1) {
                  setpatientPhone(res.data.data[1][0].patientPhone);
                  setpatientAvatar(res.data.data[1][0].patientAvatar);
                  setpatientName(res.data.data[1][0].patientName);
                  setpatientId(res.data.data[1][0].patientId);
                }
              });
            }
          });
        }
      });
    }
  }, [orderNumber]);

  const toWenzhen = () => {
    let b = new Date().getTime();
    // debugger
    // window.location.href=(`${baseUrl0}/index.html/doctorchat`)
    window.location.href = encodeURI(
      encodeURI(
        `${baseUrl0}/index.html#/doctorchat?a=${b}&patientAvatar=${patientAvatar}&patientPhone=${patientPhone}&patientName=${patientName}&patientId=${patientId}&consultationId=${consultationId}&orderNumber=${orderNumber}`
      )
    );
  };
  // console.log(partRefundState);
  const toComment = (orderNumber) => {
    props.history.push(`/comment?orderNumber=${orderNumber}`);
  };
  let time = time1.substring(11, 19);
  let time2 = time1.substring(0, 10).replace(/\-/g, "/");
  let time3 = Math.floor(Date.parse(time2) / 1000);
  const updateOrder = () => {
    let pharmacyDetail = [];
    let countRecall = 1;
    allFreights(orderDetail.pharmacyId).then((res0) => {
      console.log(res0);
      pharmacyDetail = res0.data[0][0];
      if (res0.code == 1) {
        let orderMedList = [];
        oderdetailRequest(orderNumber).then((res) => {
          console.log(res);
          res.data[1].forEach((item) => {
            if (
              item.recall == 2 ||
              item.medExamine != 2 ||
              item.violation == 1
            ) {
              countRecall = 2;
            } else {
              meddetailRequest(item.medId).then((res2) => {
                console.log(item);
                if (
                  res2.data[0][0].medPrice == 0 ||
                  !res2.data[0][0].libraryId
                ) {
                  Toast.info("此订单部分商品已下架，不能进行下单");
                } else if (res2.data[0][0].medDelete != "1") {
                  Toast.info("此订单部分商品已下架，不能进行下单");
                } else {
                  orderMedList.push({
                    orderMedId: item.orderMedId,
                    medId: item.medId,
                    medName: res2.data[0][0].medName,
                    medPhoto: res2.data[0][0].medPhoto,
                    medSpec: res2.data[0][0].medSpec,
                    medPrice: res2.data[0][0].medPrice,
                    medCompany: res2.data[0][0].medCompany,
                    medPacking: "1",
                    medApproval: res2.data[0][0].medApproval,
                    cartPrice: item.medPrice * item.medQuantity,
                    medQuantity: item.medQuantity,
                    medClassify: item.medClassify,
                    medQrcode: item.medQrcode,
                    medApparatus: res2.data[0][0].medApparatus,
                    medValidity: res2.data[0][0].medValidity,
                    medEnterprise: res2.data[0][0].medEnterprise,
                    // activitiesCycle:JSON.parse(item.activitiesCycle),
                    // activitiesDiscount:JSON.parse(item.activitiesDiscount),
                    // activitiesName:item.activitiesName,
                    // activitiesStartTime:item.activitiesStartTime,
                    // activitiesEndTime:item.activitiesEndTime,
                  });
                }
                const cartArr = [
                  {
                    pharmacy: {
                      // orderNumber: orderDetail.orderNumber,
                      orderShipping: orderDetail.orderShipping,
                      pharmacyId: orderDetail.pharmacyId,
                      pharmacyName: orderDetail.pharmacyName,
                      payMethod: orderDetail.payMethod,
                      orderFreight: pharmacyDetail.orderFreight,
                      freeFreight: pharmacyDetail.freeFreight,
                      minFreight: pharmacyDetail.minFreight,
                    },
                    medList: orderMedList,
                  },
                ];
                if (cartArr[0].medList && cartArr[0].medList.length) {
                  props.history.push({
                    pathname: "/fillorder",
                    state: cartArr,
                  });
                }
              });
            }
            if (countRecall == 2) {
              Toast.info("已被召回或审核未通过的商品无法继续下单");
            }
          });
        });
      }
    });
  };
  const payFor = (orderNumber, orderAmount) => {
    const orderName = `${medDetail[0].medName}等`;
    let b = new Date().getTime();
    window.location.href = `https://pay.sczyzk.com/pay/route?client_id=${code1}&channel=&client_trade_no=${orderNumber}&total_fee=${orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
  };

  const toMedicine = (item) => {
    console.log(item);
    if (orderDetail.pharmacyState == 1) {
      if (item.medExamine == 2) {
        if (item.violation == 0) {
          props.history.push(`/med/${item.medId}`);
        } else {
          Toast.info("该药品已失效");
        }
      } else {
        Toast.info("商品审核未通过，无法查看商品详情");
      }
    } else {
      Toast.info("店铺已下架");
    }
  };
  const toRefund = (e, orderNumber, medId) => {
    e.stopPropagation();
    console.log(orderNumber);
    const cartArr = [
      {
        pharmacy: {
          orderNumber: orderNumber,
          medId: medId,
        },
      },
    ];
    console.log(cartArr);
    props.history.push({ pathname: "/refund1", state: cartArr });
  };
  const tofailReason = (e, orderNumber, medId) => {
    e.stopPropagation();
    refundView({ orderNumber, medId }).then((res) => {
      console.log(res);
      let a = res.data[0][0].refundRefuse;
      alert("商家拒绝退款理由", a, [
        {
          text: "确认",
          onPress: () => console.log("cancel"),
          style: "default",
        },
      ]);
    });
  };

  const toCancel = (e, orderNumber, medId) => {
    e.stopPropagation();
    oderdetailRequest(orderNumber).then((res) => {
      console.log(res);
      // setOrderDetail(res.data[0][1])
      let a;
      if (res.code == 1) {
        setorderState1(res.data[0][0].orderState);
        setoldOrderStu(res.data[0][0].oldOrderStu);
        res.data[1].map((item) => {
          if (item.medId == medId) {
            a = item;
          }
        });
        alert("是否确认取消申请？", "", [
          {
            text: "取消",
            onPress: () => console.log("cancel"),
            style: "default",
          },
          {
            text: "确认",
            onPress: () => {
              setorderMedId(a.orderMedId);
              let params = {
                medRefundQuantity: 0,
                medState: 1,
                orderMedId: a.orderMedId,
              };
              let params1 = {
                orderState: res.data[0][0].oldOrderStu,
                refundRefuse: "",
                refunDetails: "",
                orderNumber: orderNumber,
              };
              let params2 = {
                partRefundState: 4,
                orderNumber: orderNumber,
              };
              console.log(res.data[0][0].orderState);
              if (res.data[0][0].orderState != "5") {
                //更新部分商品退款状态
                updatePartrefundState(params2).then((res1) => {
                  console.log(res1);
                  // 订单部分药品退款
                  applyPartRequest(params).then((res2) => {
                    console.log(res2);

                    // window.location.reload()
                  });
                });
              } else {
                // 更新订单状态
                upOrderStuRequest(params1).then((res1) => {
                  if (res1.code == 1) {
                    applyPartRequest(params).then((res) => {
                      // window.location.reload()
                    });
                  }
                });
              }
            },
          },
        ]);
      }
    });
  };
  useEffect(() => {
    if (type == "2") {
      // let params={
      //     medRefundQuantity:0,
      //     medState:1,
      //     orderMedId:orderMedId,
      // }
      // let params1={
      //     orderState:oldOrderStu,
      //     refundRefuse:'',
      //     refunDetails:'',
      //     orderNumber:orderNumber
      // }
      // let params2={
      //     partRefundState:4,
      //     orderNumber:orderNumber
      // }
      // console.log(orderState1);
      // if (orderState1!='5') {
      //     //更新部分商品退款状态
      //     updatePartrefundState(params2).then(res1=>{
      //         // 订单部分药品退款
      //         applyPartRequest(params).then(res=>{
      //             // window.location.reload()
      //         })
      //     })
      // }else{
      //     // 更新订单状态
      //     upOrderStuRequest(params1).then(res1=>{
      //         if (res1.code==1) {
      //             applyPartRequest(params).then(res=>{
      //                 // window.location.reload()
      //             })
      //         }
      //     })
      // }
    }
  }, [orderMedId]);

  const buyAgin = () => {
    if (orderDetail.pharmacyState == 1) {
      updateOrder();
    } else {
      Toast.info("店铺已下架，无法继续下单");
    }
  };

  const confirmReceipt = () => {
    alert("是否确认收货？", "", [
      { text: "取消", onPress: () => console.log("cancel"), style: "default" },
      {
        text: "确认",
        onPress: () => {
          upOrderStuRequest({ orderState: "2", orderNumber: orderNumber }).then(
            (res) => {
              if (res.code === 1) {
                props.history.go(-1);
                // Toast.info('已确认收货', 1)
              }
            }
          );
        },
      },
    ]);
  };
  const refundApply = (refundRefuse) => {
    // alert('取消订单并退款', '退款将原路退回到支付账户。如果你想修改订单信息，可点击下方“修改订单信息”进行操作，无需取消订单。', [
    //     { text: '修改订单信息', onPress: () => console.log('cancel'), style: 'default' },
    //     { text: '取消订单', onPress: () => {setRefundModal(true)} },
    // ],)
    if (refundRefuse || orderDetail.isAftersaleOrder == 2) {
      console.log(refundRefuse);
      Toast.info("该订单不能申请退款，继续退款请联系商家");
    } else {
      setVisible1("block");
    }
  };
  // 选择退款原因后提交
  const sureRefund = () => {
    if (reasonContent === "") {
      return;
    }
    const params = {
      orderState: "5",
      refundReason: reasonContent,
      orderNumber: orderNumber,
      refundOriginator: 0,
      refundDestination: 0,
      oldOrderStu: state,
    };
    console.log(params);
    applyRefundRequest(params).then((res) => {
      console.log(res);
      if (res.code === 1) {
        Toast.info("已申请退款，正在审核中……", 1);
        setRefundModal(false);
        setVisible1("none");
        window.location.reload();
      }
    });
  };
  // console.log(medDetail);
  // 取消订单
  const sureRefund1 = async () => {
    const array = [];
    medDetail.forEach((item) => {
      array.push({
        stockNum: item.medQuantity,
        med_id: item.medId,
      });
    });
    // console.log(array);
    const res = await changenum({
      array,
    });
    console.log(res);
    upOrderStuRequest({
      orderState: "4",
      orderNumber: orderNumber,
      refundOriginator: 0,
    }).then((res) => {
      if (res.code === 1) {
        props.history.go(-1);
        // Toast.info('已确认收货', 1)
      }
    });
  };
  // 叉掉，不选退款原因
  const noRefund = () => {
    const params = {
      orderState: "5",
      refundReason: "",
      orderNumber: orderNumber,
    };
    applyRefundRequest(params).then((res) => {
      if (res.code === 1) {
        Toast.info("已申请退款，正在审核中……", 1);
        // props.history.push('/mine/order/apple_refund')
        setRefundModal(false);
        window.location.reload();
      }
    });
  };
  // 复制订单号
  const copyDanhao = () => {
    const spanText = document.getElementById("dingdanhao").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };

  // 预计到达的时间，下单后45分钟
  let h0 =
    parseInt(time.substring(0, 2)) < 10
      ? parseInt("0" + parseInt(time.substring(0, 2)))
      : parseInt(time.substring(0, 2));
  let m0 =
    parseInt(time.substring(3, 5)) + 45 >= 60
      ? Math.floor((parseInt(time.substring(3, 5)) + 45) % 60)
      : parseInt(time.substring(3, 5)) + 45;
  if (m0 < 45) {
    h0 = h0 + 1 > 24 ? "0" + 1 : h0 + 1;
  }
  if (m0 < 10) {
    m0 = "0" + m0;
  }
  let lastTime = h0 + ":" + m0; //预计到达的时间

  // 倒计时
  let [countDown, setCountDown] = useState(900);
  // let [countDown1, setCountDown1] = useState(1440);

  // 定时器
  let [timer, setTimer] = useState(0);
  // let [timer1, setTimer1] = useState(0);

  // 下单时间
  let h =
    parseInt(time.substring(0, 2)) < 10
      ? parseInt("0" + parseInt(time.substring(0, 2)))
      : parseInt(time.substring(0, 2));
  let m =
    parseInt(time.substring(3, 5)) < 10
      ? parseInt("0" + parseInt(time.substring(3, 5)))
      : parseInt(time.substring(3, 5));
  let s =
    parseInt(time.substring(6, 8)) < 10
      ? parseInt("0" + parseInt(time.substring(6, 8)))
      : parseInt(time.substring(6, 8));

  // // 当前时间
  let nowTime = new Date().toString().substring(16, 24);
  let nowTime1 = new Date();
  let nowTime2 = nowTime1.toLocaleDateString().replace(/\-/g, "/");
  let nowTime3 = Math.floor(Date.parse(nowTime2) / 1000);
  let h3 =
    parseInt(nowTime.substring(0, 2)) < 10
      ? parseInt("0" + parseInt(nowTime.substring(0, 2)))
      : parseInt(nowTime.substring(0, 2));
  let m3 =
    parseInt(nowTime.substring(3, 5)) < 10
      ? parseInt("0" + parseInt(nowTime.substring(3, 5)))
      : parseInt(nowTime.substring(3, 5));
  let s3 =
    parseInt(nowTime.substring(6, 8)) < 10
      ? parseInt("0" + parseInt(nowTime.substring(6, 8)))
      : parseInt(nowTime.substring(6, 8));
  let ltime = ((h3 - h) * 60 + m3 - m) * 60 + s3 - s + nowTime3 - time3;

  // 改变倒计时 countDown 的值
  const handleTimer = () => {
    setCountDown(900);
    // setCountDown1(1440);
  };
  // localStorage.setItem(num,1);
  // 使用 useEffect 监听 countDown 变化
  useEffect(() => {
    if (type == "1") {
      if (countDown >= 0) {
        const newTimer = window.setInterval(() => {
          if (ltime > 900 && state === "-2" && localStorage.type == "1") {
            const array1 = [];
            medDetail.forEach((item) => {
              array1.push({
                medRefundQuantity: item.medQuantity,
                orderMedId: item.orderMedId,
              });
            });
            upOrderStuRequest({
              orderState: "4",
              orderNumber: orderNumber,
              refundOriginator: 0,
            }).then((res) => {
              if (res.code === 1) {
                updateStockOccupy({ array: array1 }).then((res1) => {
                  console.log(res1);
                });
              }
            });
            window.clearInterval(newTimer);
          } else {
            setCountDown((countDown -= 1));
          }
        }, 1000);
        setTimer(newTimer);
      }
    }
  }, [countDown]);
  // useEffect(() => {
  //     if (type=='2') {
  //         if (countDown1 >= 0) {
  //             const newTimer1 = window.setInterval(() => {
  //                 if (ltime > 86400 && !paytime) {
  //                     console.log(1111);
  //                     upOrderStuRequest({ orderState: '4', orderNumber: orderNumber }).then(res => {
  //                         console.log(res);
  //                         if (res.code === 1) {
  //                             const array=[]
  //                             medDetail.forEach(item=>{
  //                                 array.push({
  //                                     stockNum:item.medQuantity,
  //                                     med_id:item.medId
  //                                 })
  //                             })
  //                             // console.log(array);
  //                             changenum({array}).then(res1=>{})
  //                             //   window.location.reload()
  //                             // localStorage.removeItem(num)
  //                         }
  //                     })
  //                     setCountDown1(-1)
  //                     window.clearInterval(newTimer1);
  //                 } else {
  //                     setCountDown1(countDown1 -= 1)
  //                 }
  //             }, 60000);
  //             setTimer1(newTimer1);
  //         }
  //     }
  // }, [countDown1]);
  // 显示剩余时间
  let m2, s2;
  if (900 - ltime > 0) {
    m2 =
      Math.floor(((900 - ltime) / 60) % 60) < 10
        ? "0" + Math.floor(((900 - ltime) / 60) % 60)
        : Math.floor(((900 - ltime) / 60) % 60);
    s2 =
      Math.floor((900 - ltime) % 60) < 10
        ? "0" + Math.floor((900 - ltime) % 60)
        : Math.floor((900 - ltime) % 60);
  } else {
    m2 = "0" + 0;
    s2 = "0" + 0;
  }
  // let h4, m4;
  // if ((86400 - ltime) > 0) {
  //     h4 = Math.floor((86400 - ltime) / 60 / 60) < 10 ? '0' + Math.floor((86400 - ltime) / 60 / 60) : Math.floor((86400 - ltime) / 60 / 60);
  //     m4 = Math.floor((86400 - ltime) / 60 % 60) < 10 ? '0' + Math.floor((86400 - ltime) / 60 % 60) : Math.floor((86400 - ltime) / 60 % 60);
  // } else {
  //     h4 = '0' + 0
  //     m4 = '0' + 0
  // }
  // 跳转物流信息
  // const showLogistics = () => {
  //     // props.history.push(`/mine/order/logistics/${orderNumber}`)
  //     if (orderAddress&&orderAddress.courierNumber) {
  //         //   查询骑手信息
  //          getDetails({
  //             orderId:orderAddress.courierNumber,
  //             storeId:orderAddress.pharmacyId
  //         }).then(res4=>{
  //             console.log(res4);
  //             if (res4.code==1) {
  //                 console.log(JSON.parse(res4.data.business_data));
  //                 let time=JSON.parse(res4.data.business_data)
  //                 var date = new Date(time.estimate_arrive_time);
  //                 // 格式化日期
  //                 // let m = (date.getMonth()+1)<10?'0'+(date.getMonth()+1):date.getMonth()+1;
  //                 // let d = date.getDate()<10?'0'+date.getDate():date.getDate();
  //                 let s = date.getHours()<10?'0'+date.getHours():date.getHours();
  //                 let ms = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes();
  //                 time.estimate_arrive_time=s+':'+ms
  //                 console.log(time);
  //                 setQishounews(time);

  //                 //   查询骑手信息
  //                 getKnightInfo({
  //                     orderId:orderAddress.courierNumber,
  //                     storeId:orderAddress.pharmacyId
  //                 }).then(res=>{
  //                     let qishou=JSON.parse(res.data.business_data)
  //                     qishouJuli({orderNumber:orderNumber,latitude:qishou.carrier_driver_latitude,longitude:qishou.carrier_driver_longitude}).then(res=>{
  //                         console.log(res);
  //                         setDistance(res.data[0][0].distance)
  //                         // setVisible6('block')
  //                     })
  //                 })
  //             }
  //         })
  //     }
  // }

  // 组件销毁清除倒计时
  useEffect(() => {
    return () => window.clearInterval(timer);
  }, [timer]);
  // useEffect(() => {
  //     return () => window.clearInterval(timer1);
  // }, [timer1])
  const fn = () => {
    let total1 = 0;
    let countQuantity1 = 0;
    medDetail &&
      medDetail.map((item, index) => {
        if (item.medState === 1) {
          // setPaymoney(paymoney+item.medPrice)
          total1 += item.medPrice;
        }
        if (item.medClassify == "1") {
          setmedClassify("1");
        }
        countQuantity1 = medDetail.length;
      });
    setcountQuantity(countQuantity1);
    setTotal(total1);
  };
  useEffect(() => {
    fn();
  }, [medDetail]);
  // console.log(randomNum);
  const imgChange1 = (files, type, index) => {
    Toast.loading("Loading...", 0);
    let imgList = evaluatePic1;
    if (type === "add") {
      let file = files[files.length - 1].file;
      console.log(file);
      zipImg(file).then((res) => {
        let formData = new FormData();
        formData.append("file", res);
        uploadImg(formData)
          .then((res1) => {
            console.log(res1);
            console.log(files);
            if (res1.code === 2) {
              setFiles1(files);
              imgList.push(res1.data);
            } else {
              Toast.info("上传失败", 1);
            }
            setEvaluatePic1(imgList);
            Toast.hide();
          })
          .catch((err) => {
            Toast.hide();
          });
      });
    } else {
      setFiles1(files);
      imgList.splice(index, 1);
      setEvaluatePic1(imgList);
      Toast.hide();
    }
  };
  const dianji = () => {
    setVisible2("none");
  };
  const dianji1 = (e) => {
    e.stopPropagation();
  };
  // 上传支付凭证
  const uploadVoucher = () => {
    setVisible2("block");
    // upOrderStuRequest({ orderState: '-5', orderNumber: orderNumber }).then(res => {
    //     console.log(res);
    // })
  };
  const uploadPz = () => {
    console.log(evaluatePic1);
    if (evaluatePic1.length > 0) {
      let data = {
        orderNumber: orderNumber,
        remittancePath: evaluatePic1,
      };
      addRemittance(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          upOrderStuRequest({
            orderState: "-5",
            orderNumber: orderNumber,
          }).then((res1) => {
            if (res1.code == 1) {
              props.history.push(`/mine/order`);
            }
          });
        }
      });
    } else {
      Toast.info("未上传转账凭证");
    }
  };
  const delPz = () => {
    delRemittance({ remittanceId, orderNumber }).then((res) => {
      if (res.code == 1) {
        props.history.push(`/mine/order`);
      }
    });
  };
  // 售后
  const afterMarket = () => {
    let time = new Date();
    let time1 = time.getTime();
    let time2 = new Date(receivingTime).getTime();
    console.log(orderDetail);
    if (
      orderDetail.orderIntegralPrice +
        orderDetail.orderAmount -
        orderDetail.pharmacyPackingFee -
        orderDetail.orderFreight +
        orderDetail.refundPriceByShop >
      0
    ) {
      // if (time1-time2>172800000) {
      //     Toast.info('商品已售后时间已过，不能进行售后操作')
      // }else{
      if (isAfterSale == 1) {
        if (frequencyLimit > 0) {
          props.history.push(`/mine/order/aftermarket?tradeno=${orderNumber}`);
        } else {
          Toast.info("剩余售后次数为0，不能继续申请售后");
        }
      } else {
        Toast.info("该订单正在售后中，不能继续申请售后");
      }
      // }
    } else {
      Toast.info("该订单已完成售后，不能继续申请售后");
    }
  };
  const toAfterMarket = (e, orderNumber, medId) => {
    e.stopPropagation();
    props.history.push({
      pathname: "/mine/order/aftermarket",
      state: arr,
      tradeno: orderNumber,
      arr1: arr1,
      arr2: arr2,
      arr3: arr3,
      arr4: arr4,
      arr5: arr5,
    });
  };
  //打开物流信息
  const openlogistics = async () => {
    if (orderDetail.orderCourier == "圆通快递") {
      const res = await ytGetwl({
        Number: orderDetail.courierNumber,
      });
      setLogisticsarr(JSON.parse(res.data).reverse());
      //console.log(JSON.parse(res.data));
    } else if (orderDetail.orderCourier == "京东快递") {
      const res = await jdGetwl({
        customerCode: "010K1038726",
        waybillCode: orderDetail.courierNumber,
        josPin: "ECP0020008178251",
      });
      console.log(res);
      setLogisticsarr(res.data.data.reverse());
    } else if (orderDetail.orderCourier == "申通快递") {
      const res = await stGetwl({
        billCode: orderDetail.courierNumber,
      });
      let code = orderDetail.courierNumber;
      //console.log(res.data.data[code]);
      setLogisticsarr(res.data.data[code].reverse());
    }
    //console.log(orderDetail.orderCourier);
    //console.log(orderDetail.courierNumber);
    setVisible3(true);
  };
  //控制显示
  const handleScroll = (e) => {
    console.log(11);
    // setCsscode(true)
    // if (e.nativeEvent.deltaY) {
    //     setCsscode(true)
    // }
  };
  const moveMouse = () => {
    console.log(myBox);
  };
  // 零售未支付取消订单
  const refundApply0 = () => {
    console.log(orderDetail);
    if (orderDetail.orderMedicineDiscountPrice > 0) {
      alert("此订单含有特价商品，若下单后取消订单限购数量将不予恢复", "", [
        {
          text: "暂不取消",
          onPress: () => console.log("cancel"),
          style: "default",
        },
        {
          text: "确认取消",
          onPress: () => {
            onDelete();
          },
        },
      ]);
    } else {
      setVisiable7("block");
    }
  };
  // 零售未支付取消订单
  const onDelete = () => {
    setVisiable7("none");
    const array1 = [];
    medDetail.forEach((item) => {
      array1.push({
        medRefundQuantity: item.medQuantity,
        orderMedId: item.orderMedId,
      });
    });
    upOrderStuRequest({
      orderState: "4",
      orderNumber: orderNumber,
      refundOriginator: 0,
    }).then((res1) => {
      if (res1.code == 1) {
        updateStockOccupy({ array: array1 }).then((res1) => {
          console.log(res1);
        });
        integralIsuse({
          userId,
          userIntegralIsuse: -orderDetail.orderIntegral,
        }).then((res3) => {
          console.log(res3);
        });
        ordermedacail.map((item) => {
          tuiNum({
            stockNum: item.medQuantity,
            orderNumber: orderNumber,
            medId: item.medId,
          }).then((res) => {
            console.log(res);
          });
        });
        if (orderDetail.couponManagerId) {
          huifuCouponState({
            couponManagerId: orderDetail.couponManagerId,
            pharmacyId: orderDetail.pharmacyId,
          }).then((res) => {
            console.log(res);
          });
        }
        window.location.reload();
      }
    });
  };
  // 零售售后
  const lineShangjia = () => {};
  // console.log(orderDetail);
  // 零售取消退款申请
  const quxiaorefundApply = (orderNumber) => {
    getOrdersate({ orderNumber }).then((res0) => {
      // console.log(res0);
      if (res0.data[0][0].orderState == "5") {
        upOrderStuRequest({
          orderState: orderDetail.oldOrderStu,
          orderNumber: orderNumber,
        }).then((res1) => {
          console.log(res1);
          if (res1.code == 1) {
            window.location.reload();
          }
        });
      } else {
        Toast.info("退款操作已完成，不能取消退款申请");
        window.location.reload();
      }
    });
  };
  // 打烊
  const daYang = () => {
    Toast.info("店铺已打烊，不能继续下单");
  };
  const goback = () => {
    if (delivery) {
      props.history.push(`/orderDelivery?mine=${mine}`);
    } else if (tabId) {
      props.history.push(`/mine/order?tabId=${tabId}`);
    } else {
      props.history.push(`/mine/order?tabId=${0}`);
    }
  };
  const toWenzhen1 = () => {
    props.history.push(
      `/consultate?userId=${userId}&orderNumber=${orderDetail.orderNumber}&pharmacyId=${orderDetail.pharmacyId}&storename=${storename}`
    );
  };
  const toOtherstor = () => {
    props.history.push(`/`);
  };
  const toShop = () => {
    if (orderDetail.pharmacyState == 1) {
      props.history.push(`/shop/${orderDetail.pharmacyId}`);
    } else {
      Toast.info("店铺已下架");
    }
  };
  const toPages = (v) => {
    props.history.push(v);
  };
  // console.log(orderDetail);
  return orderDetail === null ? null : (
    <div>
      {localStorage.type == "1" ? (
        <div>
          <div className="screen-orderdetail">
            <div className="order-card-state flexc">
              <div className="order-fanhui">
                <div
                  className="order-state-back flexr"
                  onClick={() => goback()}
                >
                  <i className="iconfont icon-xiangzuo"></i>
                </div>
                {/*<img src={kefu} alt="" />*/}
              </div>
              <Odersome
                state={state}
                orderShipping={orderShipping}
                m2={m2}
                s2={s2}
                tel={tel}
                lastTime={lastTime}
                orderDetail={orderDetail}
                payFor={payFor}
                refundApply0={refundApply0}
                refundApply={refundApply}
                visible1={visible1}
                setVisible1={setVisible1}
                setRefundModal={setRefundModal}
                timeb={timeb}
                buyAgin={buyAgin}
                toComment={toComment}
                randomNum={randomNum}
                toOtherstor={toOtherstor}
                pharmacyId={pharmacyId}
                consultationId={consultationId}
                toWenzhen={toWenzhen}
                medClassify={medClassify}
                qishounews={qishounews}
                toWenzhen1={toWenzhen1}
                storelatitude={storelatitude}
                storelongitude={storelongitude}
                orderstatus={orderstatus}
                storename={storename}
                daYang={daYang}
                startTime={startTime}
                timecha={timecha}
                endTime={endTime}
                promoteStu={promoteStu}
                afterMarket={afterMarket}
                consultationStu={consultationStu}
                quxiaorefundApply={quxiaorefundApply}
              ></Odersome>
            </div>
            <div>
              {state === "5" ? (
                <>
                  {paytime && orderstate === "5" ? (
                    <div className="tuikuan">
                      <p className="tuikuan-left">
                        退款：￥{(orderDetail.orderAmount / 100).toFixed(2)}
                      </p>
                      <p className="tuikuan-right">退款中{">"}</p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            {isAftersaleOrder == 2 ? (
              <>
                <div
                  className="tuikuan"
                  onClick={() =>
                    props.history.push(
                      `/mine/order/aftersaleDetails?tel=${tel}&orderNumber=${orderNumber}`
                    )
                  }
                >
                  <p className="tuikuan-left">售后订单</p>
                  <p
                    className="tuikuan-right"
                    style={{ fontSize: "14px", marginRight: "5px" }}
                  >
                    查看详情 {">"}
                  </p>
                </div>
              </>
            ) : null}
            {orderDetail.refundRefuse ? (
              <>
                <div
                  className="tuikuan"
                  onClick={() =>
                    props.history.push(
                      `/mine/order/refundDetails?tel=${tel}&orderNumber=${orderNumber}`
                    )
                  }
                >
                  <p className="tuikuan-left">商家拒绝退款理由</p>
                  <p className="tuikuan-right">
                    {orderDetail.refundRefuse}
                    {">"}
                  </p>
                </div>
              </>
            ) : null}
            {state === "0" ? (
              <Card>
                <div className="a">
                  <p
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>骑手信息</span>
                    <img
                      src={sx}
                      alt=""
                      style={{ width: "20px", marginRight: "30px" }}
                      onClick={() => shuaxin()}
                    />
                  </p>
                  {/* <p>{JSON.stringify(qishounews)}</p> */}
                  <div className="order-myadress flex-apart-row">
                    <p style={{ width: "70%" }}>
                      {qishounews.rider_name}
                      {state === "0" ? (
                        <span
                          style={{
                            marginLeft: "5px",
                            color: "#2086FE",
                            fontSize: "16px",
                          }}
                        >
                          骑手配送中{" "}
                        </span>
                      ) : (
                        <span
                          style={{
                            marginLeft: "5px",
                            color: "#2086FE",
                            fontSize: "16px",
                          }}
                        >
                          等待骑手配送{" "}
                        </span>
                      )}
                    </p>
                    <span style={{ fontSize: "12px" }}>
                      距离你还有
                      {distance >= 1000
                        ? (distance / 1000).toFixed(2) + "km"
                        : distance + "m"}
                    </span>
                  </div>
                  <span>{qishounews.rider_phone}</span>
                </div>
              </Card>
            ) : null}
            {state === "-2" ? (
              <>
                {orderAddress && orderShipping == "2" ? (
                  <Card>
                    <div className="a">
                      <span className="address">
                        {orderAddress.province +
                          orderAddress.city +
                          orderAddress.district}
                      </span>
                      <div className="order-myadress flex-apart-row">
                        <p>{orderAddress.address}</p>
                        <i
                          className="iconfont icon-xiangyou"
                          onClick={() =>
                            props.history.push(
                              `/nowaddress?pharmacyId=${orderAddress.pharmacyId}&orderNumber=${orderNumber}&state=${state}&time=${timeb}`
                            )
                          }
                        ></i>
                      </div>
                      <span>
                        {orderAddress.addressee} {orderAddress.phone}
                      </span>
                    </div>
                  </Card>
                ) : null}
              </>
            ) : null}

            {state === "-1" ||
            state === "-11" ||
            state === "-3" ||
            state === "16" ? (
              <>
                {orderAddress && orderShipping == "2" ? (
                  <Card>
                    <div className="a">
                      <span className="address">
                        {orderAddress.province +
                          orderAddress.city +
                          orderAddress.district}
                      </span>
                      <div className="order-myadress flex-apart-row">
                        <p>{orderAddress.address}</p>
                        {orderAddress.isUpdateaddress == 1 ? (
                          <i
                            className="iconfont icon-xiangyou"
                            onClick={() =>
                              props.history.push(
                                `/nowaddress?pharmacyId=${orderAddress.pharmacyId}&orderNumber=${orderNumber}&state=${state}&time=${timeb}`
                              )
                            }
                          ></i>
                        ) : null}
                      </div>
                      <span>
                        {orderAddress.addressee} {orderAddress.phone}
                      </span>
                    </div>
                  </Card>
                ) : null}
              </>
            ) : null}
            {state === "1" ||
            state === "2" ||
            state === "3" ||
            state === "0" ||
            state === "4" ||
            state === "5" ||
            state === "6" ||
            state === "7" ||
            state === "-4" ||
            state === "12" ? (
              <>
                {orderAddress && orderShipping == "2" ? (
                  <Card>
                    <div className="a">
                      <span className="address">
                        {orderAddress.province +
                          orderAddress.city +
                          orderAddress.district}
                      </span>
                      <div className="order-myadress flex-apart-row">
                        <p>{orderAddress.address}</p>
                        {/* <i className='iconfont icon-xiangyou'></i> */}
                      </div>
                      <span>
                        {orderAddress.addressee} {orderAddress.phone}
                      </span>
                    </div>
                  </Card>
                ) : null}
              </>
            ) : null}
            <Card>
              <div onClick={() => toShop()}>
                <span className="phname">{orderDetail.pharmacyName}</span>
                <i className="iconfont icon-xiangyou"></i>
              </div>
              {medDetail === null ? null : (
                <Meditem
                  state={state}
                  medDetail={medDetail}
                  arr1={arr1.concat(arr, arr2, arr3, arr4, arr5)}
                  toMedicine={toMedicine}
                  toAfterMarket={toAfterMarket}
                />
              )}
            </Card>

            <Card pure={true}>
              <div className="order-moneysum flex-apart-row">
                <span>商品金额</span>
                <span style={{ color: "#333" }}>
                  ￥{(orderDetail.orderPrice / 100).toFixed(2)}
                </span>
              </div>
              <div className="order-moneysum flex-apart-row">
                <span>配送费</span>
                <span style={{ color: "#333" }}>
                  + ￥{(orderDetail.orderFreight / 100).toFixed(2)}
                </span>
              </div>
              <div className="order-moneysum flex-apart-row">
                <span>打包费</span>
                <span style={{ color: "#333" }}>
                  + ￥{(orderDetail.pharmacyPackingFee / 100).toFixed(2)}
                </span>
              </div>
              <div className="order-moneysum flex-apart-row">
                <span>积分抵扣</span>
                <span style={{ color: "#F34848" }}>
                  {" "}
                  （{orderDetail.orderIntegral}积分） -￥
                  {(orderDetail.orderIntegralPrice / 100).toFixed(2)}
                </span>
              </div>
              <div className="order-moneysum flex-apart-row">
                <span>优惠券</span>
                <span style={{ color: "#F34848" }}>
                  - ￥{(orderDetail.couponPrice / 100).toFixed(2)}
                </span>
              </div>
              <div className="order-moneysum flex-apart-row">
                <span>满减</span>
                <span style={{ color: "#F34848" }}>
                  - ￥{(orderDetail.activitiesPrice / 100).toFixed(2)}
                </span>
              </div>
              {orderDetail.firstReductionAmount ? (
                <div className="order-moneysum flex-apart-row">
                  <span>新客立减</span>
                  <span style={{ color: "#F34848" }}>
                    - ￥{(orderDetail.firstReductionAmount / 100).toFixed(2)}
                  </span>
                </div>
              ) : null}
              <div className="order-moneysum flex-apart-row heji">
                <span className="all">合计：</span>
                <span className="price">
                  ￥{(orderDetail.orderAmount / 100).toFixed(2)}
                </span>
              </div>
            </Card>
            <Card pure={true}>
              <p className="order-moneysum flex-apart-row">
                <span>订单编号</span>
                <span>
                  <span id="dingdanhao" style={{ color: "#333" }}>
                    {orderDetail.orderNumber}
                  </span>
                  <span className="fuzhi" onClick={() => copyDanhao()}>
                    复制
                  </span>
                </span>
              </p>
              <p className="order-moneysum flex-apart-row">
                <span>下单时间</span>
                <span style={{ color: "#333" }}>{orderDetail.createTime}</span>
              </p>
              {orderDetail.confirmTime ? (
                <p className="order-moneysum flex-apart-row">
                  <span>送达时间</span>
                  <span style={{ color: "#333" }}>
                    {orderDetail.confirmTime}
                  </span>
                </p>
              ) : null}
              <p className="order-moneysum flex-apart-row">
                <span>支付方式</span>
                <span style={{ color: "#333" }}>{orderDetail.payMethod}</span>
              </p>
              <p className="order-moneysum flex-apart-row">
                <span>交易快照</span>
                <span
                  onClick={() =>
                    toPages(`/snapshot?orderNumber=${orderDetail.orderNumber}`)
                  }
                  style={{ color: "#333" }}
                >
                  可作为争议判断依据
                  <RightOutline />
                </span>
              </p>
              {orderDetail.orderUserRemarks ? (
                <p className="order-moneysum flex-apart-row">
                  <span>订单备注：{orderDetail.orderUserRemarks}</span>
                </p>
              ) : null}
            </Card>
            <div className="bottom2" style={{ textAlign: "center" }}>
              到底了~
            </div>
          </div>
          {/*<Modal*/}
          {/*    className='quxiaodingdan'*/}
          {/*    popup*/}
          {/*    visible={refundModal}*/}
          {/*    onClose={() => {*/}
          {/*        setRefundModal(false)*/}
          {/*        setReasonContent('')*/}
          {/*        setRadioVal('')*/}
          {/*    }}*/}
          {/*    animationType='slide-up' >*/}
          {/*    /!* <Button type='primary' className='commit1' onClick={sureRefund}>提交</Button> *!/*/}
          {/*    <List renderHeader={() => <div className='yiquxiao'>退款申请原因</div>} className='popup-list'>*/}
          {/*        <div className='select'>请选择取消订单原因</div>*/}
          {/*        {reasons1.map((item, index) => (*/}
          {/*            <RadioItem key={index}*/}
          {/*                checked={radioVal === item}*/}
          {/*                onChange={() => {*/}
          {/*                    // const commit1 = document.getElementsByClassName('commit1')[0];*/}
          {/*                    // commit1.style.background = "#1F87FF"*/}
          {/*                    setRadioVal(item)*/}
          {/*                    if (reasonContent === '其它原因') {*/}
          {/*                        setReasonContent('')*/}
          {/*                    } else {*/}
          {/*                        setReasonContent(item)*/}
          {/*                    }*/}
          {/*                }}>*/}
          {/*                {item}*/}
          {/*            </RadioItem>*/}
          {/*        ))}*/}
          {/*        {radioVal === '其它原因' ?*/}
          {/*            <TextareaItem*/}
          {/*                placeholder='请输入退款原因'*/}
          {/*                rows={1}*/}
          {/*                count={100}*/}
          {/*                onChange={(val) => setReasonContent(val)} /> : null*/}
          {/*        }*/}
          {/*        <Button type='primary' className='commit1' style={{width:'90%', backgroundColor:'#1F87FF',color:'#fff',marginLeft:"5%"}} onClick={sureRefund}>确定</Button>*/}
          {/*        <Button type='primary' className='commit1' style={{width:'90%',backgroundColor:'#aaa',marginTop:'5px',marginLeft:"5%"}} onClick={() => {*/}
          {/*            setRefundModal(false)*/}
          {/*            setReasonContent('')*/}
          {/*            setRadioVal('')*/}
          {/*            setVisible1('none')*/}
          {/*        }}>取消</Button>*/}
          {/*    </List>*/}
          {/*</Modal>*/}
          {refundModal ? (
            <div className="refund-reasons">
              <p className="refund-reasons-title">退款申请原因</p>
              {reasons1.map((item, index) => (
                <>
                  <div key={index} className="refund-reasons-all">
                    <div>{item}</div>
                    <input
                      type="radio"
                      name="b"
                      checked={radioVal === item}
                      onChange={() => {
                        // const commit = document.getElementsByClassName('commit')[0];
                        // commit.style.background = "#1F87FF"
                        setRadioVal(item);
                        if (item === "其它原因") {
                          setReasonContent("");
                        } else {
                          setReasonContent(item);
                        }
                      }}
                    />
                  </div>
                </>
              ))}
              {radioVal === "其它原因" ? (
                <TextareaItem
                  placeholder="请输入退款原因"
                  rows={2}
                  count={100}
                  onChange={(val) => setReasonContent(val)}
                />
              ) : null}
              <div className="commit" onClick={sureRefund}>
                确定
              </div>
              <div
                className="commit2"
                onClick={() => {
                  setRefundModal(false);
                  setReasonContent("");
                  setRadioVal("");
                  setVisible1("none");
                }}
              >
                取消
              </div>
            </div>
          ) : null}
          {visiable7 == "block" ? (
            <div className="del-address-outer">
              <div className="del-address">
                <p className="del-sure">是否确认取消订单？</p>
                <p className="del-bottom">
                  <span onClick={() => setVisiable7("none")}>取消</span>
                  <span onClick={() => onDelete()}>确认</span>
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <div>
                    <div className='pifa-orderdetail'>
                        <div className='order-pifa'>
                            {state === '-2' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>待付款</span>
                                    </div>
                                    <p className='red'>剩<span>{h4}小时{m4}分</span>自动关闭订单</p>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        {orderDetail.payMethod == '对公转账' ? <span className='jibenxinxi-right'>转账中</span> : <span className='jibenxinxi-right'>待付款</span>}
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '-5' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>对公转账中</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>对公转账中</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '4' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>已取消</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>已取消</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '-1' || state === '-4' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>待出库</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>待出库</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '0' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>待收货</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>待收货</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                                 {state === '2' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>已完成</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>已收货</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '5' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>申请退款</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>退款中</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '6' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>退款/售后</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>已退款</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '8' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>等待自提</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>等待自提</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '9' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.go(-1)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>交易完成</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>交易完成</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                            {state === '10'||(orderDetail.refundOriginator==1&&state === '14')||state === '15' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.push(`/mine/order`)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>商家退款</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        {orderDetail.payMethod=='对公转账'&&state === '15'?<span className='jibenxinxi-right'>商家已退款</span>:null}
                                        {orderDetail.payMethod=='对公转账'&&(state === '14'||state === '10')?<span className='jibenxinxi-right'>商家退款中</span>:null}
                                        {orderDetail.payMethod!='对公转账'&&state === '10'?<span className='jibenxinxi-right'>商家已退款</span>:null}
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                            : null}
                            {state === '12' ?
                                <>
                                    <div className='order-fanhui'>
                                        <div className='pifa-fanhui'
                                            onClick={() => props.history.push(`/mine/order`)}>
                                            <i className='iconfont icon-xiangzuo'></i>
                                        </div>
                                        <span className='daizhifu'>申请售后中</span>
                                    </div>
                                    <div className='jibenxinxi'>
                                        <span className='jibenxinxi-left'>基本信息</span>
                                        <span className='jibenxinxi-right'>申请售后中</span>
                                    </div>
                                    <div className='pifa-news'>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>订单编号</span>
                                            <span>
                                                <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.orderNumber}</span>
                                                <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>下单时间</span>
                                            <span style={{ color: '#333' }}>{orderDetail.createTime}</span>
                                        </p>
                                        <p className='order-moneysum flex-apart-row'>
                                            <span>采购门店</span>
                                            <span style={{ color: '#333' }}>{orderDetail.pharmacyName}</span>
                                        </p>
                                    </div>
                                </>
                                : null}
                        </div>
                        {state === '-2' && orderDetail.payMethod == '对公转账' ?
                            <div className='dgzz' style={{ backgroundColor: '#fff', height: '130px' }}>
                                <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px', marginBottom: '5px' }}>商家对公账户信息</h2>
                                <p>账户名：{companyName}</p>
                                <p>账号：{bankAccount}</p>
                                <p>开户银行：{bankName}</p>
                            </div> : null}
                        {state === '0' || state === '9' ?
                            <div className='wuliu-news order-pifa'>
                                <div className='jibenxinxi'>
                                    <span className='jibenxinxi-left'>物流信息</span>
                                    <span className='jibenxinxi-right' style={{ color: '#FF7A48' }} onClick={openlogistics}>物流查询 &gt; </span>
                                </div>
                                <div className='pifa-news'>
                                    <p className='order-moneysum flex-apart-row'>
                                        <span>配送方式</span>
                                        <span style={{ color: '#333' }}>快递</span>
                                    </p>
                                    <p className='order-moneysum flex-apart-row'>
                                        <span>物流公司</span>
                                        <span style={{ color: '#333' }}>{orderDetail.orderCourier}</span>
                                    </p>
                                    <p className='order-moneysum flex-apart-row'>
                                        <span>物流单号</span>
                                        <span>
                                            <span id='dingdanhao' style={{ color: '#333' }}>{orderDetail.courierNumber}</span>
                                            <span className='fuzhi' onClick={() => copyDanhao()}>复制</span></span>
                                    </p>
                                </div>
                            </div> : null}
                        {state === '8' ?
                            <div className='wuliu-news order-pifa'>
                                <div className='jibenxinxi'>
                                    <span className='jibenxinxi-left'>自提信息</span>
                                    <span className='jibenxinxi-right'></span>
                                </div>
                                <div className='pifa-news'>
                                    <p className='order-moneysum flex-apart-row'>
                                        <span>自提地点</span>
                                        <span style={{ color: '#333' }}>{orderDetail.province + orderDetail.city + orderDetail.district + orderDetail.address}</span>
                                    </p>
                                    <p className='order-moneysum flex-apart-row'>
                                        <span>商家电话</span>
                                        <span style={{ color: '#333' }}>135</span>
                                    </p>
                                </div>
                            </div> : null}
                        <div className='pifa-dingdan'>
                            <div className='yaodan-name' onClick={() => props.history.push(`/shop/${orderDetail.pharmacyId}`)}>
                                <span className='phname'>{orderDetail.pharmacyName}</span>
                            </div>
                            <div className='xian'></div>
                            {medDetail === null ? null :
                                <Meditem state={state} medDetail={medDetail} arr1={arr1.concat(arr,arr2,arr3,arr4,arr5)} partRefundState={partRefundState}
                                    toMedicine={toMedicine} toRefund={toRefund} toCancel={toCancel} tofailReason={tofailReason}/>}
                            <div className='all'>
                                <span>{countQuantity}个商品|(运费：￥0.00)</span>
                                <span className='heji-price'>合计：<span>￥{orderDetail.orderPrice / 100}</span></span>
                            </div>
                        </div>
                        <div className='pifa-fapiao'>发票类型：<span>增值税普通发票</span></div>
                        {state === '-5' ? <div className='dgzz-outer'>
                            <p>对公转账凭证</p>
                            <p className='dgzz-img'>
                                {evaluatePic2 && evaluatePic2.map(item => (
                                    <span>
                                        <img src={item} alt="" />
                                    </span>
                                ))}
                            </p>
                        </div> : null}
                        {state === '-2' ? <div className='bottom'>
                            <span className='bottom-left' onClick={() => refundApply()}>取消订单</span>
                            {orderDetail.payMethod == '对公转账' ? <span className='bottom-right bottom-right0' onClick={() => uploadVoucher(orderDetail.orderNumber, orderDetail.orderAmount)}>上传转账凭证</span> :
                                <span className='bottom-right' onClick={() => payFor(orderDetail.orderNumber, orderDetail.orderAmount)}>立即支付</span>}
                            {visible2 == 'block' ? <div className='upvoucher' onClick={() => dianji()}>
                                <div className='upvoucher-in' onClick={(e) => dianji1(e)}>
                                    <ImagePicker
                                        files={files1}
                                        length={2}
                                        onChange={imgChange1}
                                        onImageClick={(index, fs) => console.log(index, fs)}
                                        selectable={files1.length < 4}
                                        accept='image/gif,image/jpeg,image/jpg,image/png' />
                                    <p><span className='one' onClick={() => uploadPz()}>上传凭证</span><span className='two'>(最多上传4张)</span></p>
                                </div>
                            </div> : null}
                            {visible5 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单</p>
                                    <p className='neirong' style={{textAlign:'center'}}>确认取消订单？</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible5('none')}>取消</span>
                                        <span className='sure'  onClick={sureRefund1}>确认</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div> : null}
                        {state === '-1' || state === '-4' || state === '0' || state === '8' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right1'>
                                <span className='bottom-right-r' style={{marginLeft:'70px'}} onClick={() => confirmReceipt()}>确认收货</span>
                            </span>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>退款将原路退回到支付账户。如果你想修改订单信息，可点击下方“修改订单信息”进行操作，无需取消订单。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>修改订单信息</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div> : null}
                        {state === '0' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right1'>
                                <span className='bottom-right-l' onClick={() => afterMarket()}>申请售后</span>
                                <span className='bottom-right-r' onClick={() => confirmReceipt()}>确认收货</span>
                            </span>
                        </div> : null}
                        {state === '2'||state === '12' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right2'onClick={() => afterMarket()}>申请售后</span>
                        </div> : null}
                        {state === '10'||(orderDetail.refundOriginator==1&&state === '14')||state === '15' ? <div className='bottom'>
                            <span></span>
                            <span className='bottom-right2' onClick={()=>lookReason()}>退款理由</span>
                        </div> : null}
                        {state === '5' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right2'><a href={`tel:${tel}`}>联系商家</a ></span>
                        </div> : null}
                        {state === '6' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right2' onClick={() => buyAgin()}>重新下单</span>
                        </div> : null}
                        {state === '-5' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right2' onClick={() => delPz()}>删除凭证</span>
                        </div> : null}
                        {state === '9' ? <div className='bottom'>
                            <span className='bottom-left'></span>
                            <span className='bottom-right1'>
                                <span className='bottom-right-l' onClick={() => refundApply()}>申请退款</span>
                                <span className='bottom-right-r' onClick={() => buyAgin()}>再次购买</span>
                            </span>
                            {visible1 == 'block' ? <div className='tankuang'>
                                <div className='inner'>
                                    <p className='quxiao'>取消订单并退款</p>
                                    <p className='neirong'>退款将原路退回到支付账户。如果你想修改订单信息，可点击下方“修改订单信息”进行操作，无需取消订单。</p>
                                    <p className='bottom' style={{ textAlign: 'center' }}>
                                        <span className='xiugai' onClick={() => setVisible1('none')}>修改订单信息</span>
                                        <span className='sure' onClick={() => { setRefundModal(true) }}>取消订单</span>
                                    </p>
                                </div>
                                <div>
                                    <img src={close} alt="" onClick={() => setVisible1('none')} />
                                </div>
                            </div> : <div style={{ display: 'none' }}></div>}
                        </div> : null}
                    </div>
                    <Modal
                        className='quxiaodingdan'
                        popup
                        visible={refundModal}
                        onClose={() => {
                            setRefundModal(false)
                            setReasonContent('')
                            setRadioVal('')
                        }}
                        animationType='slide-up' >
                        
                        <Button type='primary' className='commit' onClick={sureRefund}>提交</Button>
                        <List renderHeader={() => <div className='yiquxiao'>订单已取消</div>} className='popup-list'>
                            <div className='select'>请选择取消订单原因</div>
                            {reasons.map((item, index) => (
                                <RadioItem key={index}
                                    checked={radioVal === item}
                                    onChange={() => {
                                        const commit = document.getElementsByClassName('commit')[0];
                                        commit.style.background = "#1F87FF"
                                        setRadioVal(item)
                                        if (reasonContent === '其它原因') {
                                            setReasonContent('')
                                        } else {
                                            setReasonContent(item)
                                        }
                                    }}>
                                    {item}
                                </RadioItem>
                            ))}
                            {radioVal === '其它原因' ?
                                <TextareaItem
                                    placeholder='请输入退款原因'
                                    rows={5}
                                    count={100}
                                    onChange={(val) => setReasonContent(val)} /> : null
                            }
                        </List>
                    </Modal>
                    <Popup
                        visible={visible3}

                        onMaskClick={() => {
                            setVisible3(false)
                        }}

                        // bodyStyle={{ maxHeight: '60vh' }}
                    >
                        <div className="wlbox" style={{maxHeight:"60vh", overflowY: 'scroll'}}>
                            {orderDetail.orderCourier == "圆通快递"? <>
                                {logisticsarr.map((item,index) => {
                                    return (

                                        <div className="wlbox-item" key={item.upload_Time} style={{color:index!=0?"#999999":"#333333"}}>
                                            <img src={index==0?kxy:yuan2} alt="" /><p>{item.processInfo}</p><h3>{item.upload_Time}</h3>
                                        </div>


                                    )
                                })}
                            </>:null}
                            {orderDetail.orderCourier == "京东快递"? <>
                                {logisticsarr.map((item,index) => {
                                    return (

                                        <div className="wlbox-item" key={index}  style={{color:index!=0?"#999999":"#333333"}}>
                                            <img src={index==0?kxy:yuan2} alt="" /><p>{item.opeRemark}</p><h3>{item.opeTime}</h3>
                                        </div>


                                    )
                                })}
                            </>:null}

                            {orderDetail.orderCourier == "申通快递"? <>
                                {logisticsarr.map((item,index) => {
                                    return (

                                        <div className="wlbox-item" key={index}  style={{color:index!=0?"#999999":"#333333"}}>
                                            <img src={index==0?kxy:yuan2} alt="" /><p>{item.memo}</p><h3>{item.opeTime}</h3>
                                        </div>


                                    )
                                })}
                            </>:null}

                        </div>
                    </Popup>
                </div>} */}
    </div>
  );
}
