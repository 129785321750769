import React, { Component } from 'react'
import "./bulletinboard.less"
import FH from "../../../static/img/fanhuihei.png"
import you from "../../../static/img/you.png"
import Nodata from '../../../component/Nodata'
import { getNoticeList } from '../../../api/request/msg'
export default class bulletinboard extends Component {
    constructor(){
        super()
        this.state={
            userId: window.localStorage.getItem("userId2"),
            userarr:{},
            user:[],
            companyType:'',
            examine:'',
            activeBanner4:'',
            bulletinList:''
        }
    }
   
    componentDidMount=async()=>{
         // 公告
        getNoticeList().then(res=>{
            console.log(res);
            let arr=[]
            if (res.data[0].length) {
                this.setState({
                    bulletinList:res.data[0]
                })
            }
        })
    }
    goon=(id)=>{
        this.props.history.replace(`/bulletinboard`)
        this.props.history.push(`/middledetails?bulletinId=${id}`)
    }
    render() {
        const {bulletinList} =this.state
        return (
            <div>
                <div className="bulletinboard-top">
                    <img src={FH} alt="" onClick={() =>  this.props.history.push('/')} /><h2>公告板</h2>
                </div>
                {bulletinList?<div className='bulletinboard-body'>
                    {bulletinList.map(item=>(<div onClick={()=>this.goon(item.id)}>
                        <span>{item.notice_title}</span>
                        <img src={you} alt="" />
                    </div>))}
                </div>:<Nodata type='公告'/>}
            </div>
        )
    }
}
