import axios from "axios";
import {baseUrl} from "../publicurl"

// axios的实例及拦截器配置
const axiosInstance = axios.create({
  baseURL: baseUrl
});
// 请求超时时间
axios.defaults.timeout = 60000;

axiosInstance.interceptors.response.use(
  res => 
  // res.data,
  {
    if (res.status==200) {
      if (res.data.code==-1) {
        return Promise.reject(res.data)//抛出异常
      }
      return Promise.resolve(res.data)
    }else{
      return Promise.reject(res.data)//抛出异常
    }
  },
  err => {
    return Promise.reject(err)
  }
);

export { axiosInstance };

