import React from 'react'
import { Tabs, WhiteSpace } from 'antd-mobile'
import {withRouter} from 'react-router-dom'

import Goods from '../../../component/Goods/index'
import NoData from '../../../component/Nodata/index'

import { limitactivityRequest } from '../../../api/request/home'

import './style.less'

class Activity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      limitKill: []
    }
  }

  componentDidMount() {
    this.setState({
      limitKill: this.props.limitKill
    })
  }

  limitChange = (tab, index) => {
    limitactivityRequest(tab.categoryId).then(res => {
      if(res.code === 1 && res.data[0].length > 0) {
        this.setState({
          limitKill: res.data[0]
        })
      } else {
        this.setState({
          limitKill: []
        })
      }
    })
  }

  renderContent = (tab,index) =>
  this.state.limitKill.length > 0
    ?(<Goods recommend={this.state.limitKill}/>)
    :(<div className='limit-kill-nodata'>
      <NoData type='信息' />
    </div>)

  render() {
    const tabs = this.props.category

    return (
      <>
        <Tabs tabs={tabs}
         onChange={this.limitChange}
         tabBarActiveTextColor='#2086FE'
        //  tabBarTextStyle={{fontSize:'15px'}}
         tabBarUnderlineStyle={{border: '2px #2086FE solid',transform:'scaleX(.2)'}}
         renderTab={tab => <span>{tab.categoryName}</span>}>
          {this.renderContent}
        </Tabs>
        <WhiteSpace />
      </>
    )
  }
}

export default withRouter(Activity)