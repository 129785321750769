import React, { Component } from 'react'
import { Toast, Button, InputItem } from "antd-mobile"
import { logincodeRequest, verifyRequest } from '../../api/request/user'
import "./retrieveone.less"
export default class retrieveone extends Component {
  constructor() {
    super();
    this.state = {
      phone: 0,
      value: "",
      newvalue: "",
      hasError: false,
      hasError2: false,
      code: 0,
      time: 60,
      pharmacyId:localStorage.getItem("pharmacyId")=="25"?"0":localStorage.getItem("pharmacyId")
    }
  }
  componentDidUpdate() {

  }
  // 提交
  toNext = async () => {
    const res = await verifyRequest(this.state.phone, this.state.newvalue,this.state.pharmacyId)
    console.log(res)
    if (res.data[0].length) {
      localStorage.setItem("phone", this.state.phone)
      this.props.history.push("/login/retrieve")
    } else {
      Toast.fail("验证码错误");
    }
  }

  // 错误反馈
  onErrorClick = () => {
    if (this.state.hasError) {
      Toast.info("请输入正确的手机号");
    }
  }
  onErrorClick2 = () => {
    if (this.state.hasError2) {
      Toast.info("验证码输入错误");
    }
  }
  //   判断输入的格式
  onChange = (phone) => {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
    }
    this.setState({
      phone,
    });
  }
  onChange2 = (newvalue) => {
    this.setState({
      newvalue,
    })
  }
  //   判断验证码
  blur = () => {
    console.log(this.state.newvalue)
  }
  // 获取验证码
  getnum = () => {
    logincodeRequest({pharmacyId:this.state.pharmacyId, userPhone: this.state.phone}).then(res => {
      console.log(res)
      if (res.code == 1) {
        Toast.success("获取成功");
        this.setState({
          code: 1
        }, () => {
          const timer = setInterval(() => {
            this.setState({
              time: this.state.time - 1
            }, () => {
              if (this.state.time == 0) {
                this.setState({
                  time: 60
                }, () => {
                  clearInterval(timer)
                  this.setState({
                    code: 3
                  })
                })
              }
            })
          }, 1000);
        })
      }
    })
  }
  // 返回上一个界面
  back = () => {
    window.history.back()
  }
  render() {
    const { value, newvalue } = this.state
    return (
      <div className="box3">
        <div className="box3-top">
          <button onClick={this.back}></button>
        </div>
        <h1>请填写您要找回密码的账号</h1>
        <InputItem
          type="number"
          style={{fontSize:'18px'}}
          placeholder="请输入手机号"
          error={this.state.hasError}
          onErrorClick={this.onErrorClick}
          onChange={this.onChange}
          value={this.state.phone}
        ></InputItem>
        <InputItem
          type="text"
          style={{fontSize:'18px'}}
          placeholder="请输入验证码"
          error={this.state.hasError2}
          onErrorClick={this.onErrorClick2}
          onChange={this.onChange2}
          onBlur={this.blur}
          value={this.state.newvalue}
        ></InputItem>
        {this.state.code == 0 ? <a className="ab" onClick={this.getnum}>发送验证码</a> : this.state.code == 1 ? <a className="ab">还有{this.state.time}秒重新获取</a> : <a className="ab" onClick={this.getnum}>重新获取验证码</a>}
        <Button className={value.length > 0 || newvalue.length > 0 ? "btn1" : "btn"} type="primary" style={{ fontSize: "40px" ,border:'none'}} onClick={this.toNext}><p style={{ fontSize: "16px", lineHeight: "45px" }}>下一步</p></Button>
      </div>
    )
  }
}
