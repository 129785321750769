import produce from 'immer'
import {set,has} from 'lodash'

export default function enhancedReducer(state,updateArg){
    //检查updateArg参数是否为回调函数
    if(updateArg.constructor===Function){
        return {...state,...updateArg(state)}
    }

    //如果updateArg参数是对象
    if(updateArg.constructor===Object){
        //如果updateArg对象中有_path和_value这两个key
        //那就用它们更新深层对象值
        if(has(updateArg,"_path")&&has(updateArg,"_value")){
            const {_path,_value}=updateArg
            return produce(state,draft=>{
                set(draft,_path,_value)
            })
        }else{
            return{...state,...updateArg}
        }
    }
}



