import React, { Component } from 'react'
import icon1 from "../../static/img/icon/qs.png"
import icon2 from "../../static/img/icon/sj.png"
import icon3 from "../../static/img/icon/home.png"
const BMap = window.BMap
const BMapLib = window.BMapLib
export default class BDMapw extends Component {
    constructor() {
        super();
        this.state = {
            longitude: localStorage.longitude ? localStorage.longitude : 104.06576236480714,
            latitude: localStorage.latitude ? localStorage.latitude : 30.65745887548027,
            storelongitude: null,
            storelatitude: null,
            orderNUM: 1,
            orderStatus: null
        }
    }
    componentWillMount() {
        console.log(this.props)

    }
    componentDidMount() {
        this.setState({
            storelatitude: this.props.storelatitude,
            storelongitude: this.props.storelongitude,
            storename: this.props.storename,
            orderStatus: this.props.orderstatus
        }, () => {
            const { storelatitude, storelongitude, storename, longitude, latitude, orderNUM, orderStatus } = this.state
            const mp = new BMap.Map("container")
            //mp.enableScrollWheelZoom(true)
            // 商家位置显示
            let point = new BMap.Point(storelongitude, storelatitude);
            // 显示用户位置
            let point2 = new BMap.Point(longitude, latitude);
            // 骑手位置显示
            let point3 = new BMap.Point(longitude, latitude);
            mp.centerAndZoom(point, 16);
            mp.centerAndZoom(point2, 16);
            mp.centerAndZoom(point3, 16);
            mp.enableScrollWheelZoom(true);
            // 创建小车图标
            let myIcon = new BMap.Icon(icon2, new BMap.Size(32, 32));
            // 创建用户图标
            let myIcon2 = new BMap.Icon(icon3, new BMap.Size(32, 32));
            // 创建骑手图标
            let myIcon3 = new BMap.Icon(icon1, new BMap.Size(32, 32));
            // 创建Marker标注，使用小车图标
            let pt = new BMap.Point(storelongitude, storelatitude);
            let pt2 = new BMap.Point(longitude, latitude);
            let pt3 = new BMap.Point(longitude, latitude);
            let marker = new BMap.Marker(pt, {
                icon: myIcon
            });
            let marker2 = new BMap.Marker(pt2, {
                icon: myIcon2
            });
            let marker3 = new BMap.Marker(pt3, {
                icon: myIcon3
            });
            // 将标注添加到地图
            mp.addOverlay(marker)
            mp.addOverlay(marker2)
            mp.addOverlay(marker3)
            // 创建图文信息窗口
            let sContent
            // 订单状态跟踪
            if (orderStatus) {
                switch (orderStatus) {
                    case 0:
                        sContent = `<p>${storename}(订单生成)</p>`
                        break;
                    case 1:
                        sContent = `<p>${storename}(运单生成成功)</p>`
                        break;
                    case 20:
                        sContent = `<p>${storename}(运单生成成功)</p>`
                        break;
                    case 80:
                        sContent = `<p>${storename}(骑手到店)</p>`
                        break;
                    case 2:
                        sContent = `<p>${storename}(配送中)</p>`
                        break;
                    case 3:
                        sContent = `<p>${storename}(已完成)</p>`
                        break;
                    case 4:
                        sContent = `<p>${storename}(已取消)</p>`
                        break;
                    case 5:
                        sContent = `<p>${storename}(配送异常)</p>`
                        break;
                }
            } else {
                sContent = `<p>${storename}</p>`
            }

            let infoWindow = new BMap.InfoWindow(sContent);
            // marker添加点击事件
            marker.addEventListener('click', function () {
                this.openInfoWindow(infoWindow);
                // 图片加载完毕重绘infoWindow
            });
            let sContent2 = `<p>您的位置</p>`
            let infoWindow2 = new BMap.InfoWindow(sContent2);
            marker2.addEventListener('click', function () {
                this.openInfoWindow(infoWindow2);
                // 图片加载完毕重绘infoWindow
            });
            let sContent3 = `<p>骑手信息</p>`
            let infoWindow3 = new BMap.InfoWindow(sContent3);
            marker3.addEventListener('click', function () {
                this.openInfoWindow(infoWindow3);
                // 图片加载完毕重绘infoWindow
            });

            // 点聚合
            //let MAX = 10;
            let markers = [];
            const arr1 = new BMap.Marker(pt)
            markers.push(arr1)
            const arr2 = new BMap.Marker(pt2)
            markers.push(arr2)
            //let code = null;
            // let i = 0;
            // for (; i < MAX; i++) {
            //     code = new BMap.Point(Math.random() * 40 + 85, Math.random() * 30 + 21);
            //     markers.push(new BMap.Marker(code));
            // }
            //最简单的用法，生成一个marker数组，然后调用markerClusterer类即可。
            let markerClusterer = new BMapLib.MarkerClusterer(mp, { markers: markers });
        })

    }
    render() {
        return (
            <div>
                <div id="container" style={{ width: "100%", height: "300px" }}></div>
            </div>
        )
    }
}
