import React, {Component, useEffect, useState} from 'react'
import { List, WhiteSpace, Modal, Toast } from 'antd-mobile'


import './style.less'
import icon1 from "../../../static/img/fanhuihei.png";

export default class index extends Component {
    constructor() {
        super()
        this.state = {}
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <div className='setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine/set")}/> 收集个人信息清单
                </div>
                <div className='list-table'>
                    <p className='table-title'>收集个人信息清单</p>
                    <p className='table-title-two'>
                        <p>场景/业务功能</p>
                        <p>个人信息种类</p>
                        <p>目的</p>
                    </p>
                    <p className='table-one'>
                        <p className='table-one-left'>注册、登录</p>
                        <p className='table-one-right'>
                            <p className='table-one-right-top'>
                                <p>手机号、验证码、密码</p>
                                <p>用于注册创建账号和登录</p>
                            </p>
                            <p className='table-one-right-top'>
                                <p>头像、昵称</p>
                                <p>用于完善网络身份标识</p>
                            </p>
                        </p>
                    </p>
                    <p className='table-list'>
                        <p>第三方账号登录</p>
                        <p style={{paddingTop:'50px'}}>第三方账号</p>
                        <p style={{paddingTop:'40px'}}>用于使用第三方账号授权登录的情形</p>
                    </p>
                    <p className='table-list'>
                        <p>基于位置的推荐展示</p>
                        <p style={{paddingTop:'50px'}}>位置</p>
                        <p>根据用户的位置推荐周边的生活服务，用户可以自行选择开启位置权限</p>
                    </p>
                    <p className='table-list'>
                        <p>配送下单</p>
                        <p style={{paddingTop:'30px'}}>联系人信息、收货地址、联系电话、订单交易信息</p>
                        <p style={{paddingTop:'40px'}}>用于配送下单完成履约送货</p>
                    </p>
                    <p className='table-list'>
                        <p>实名认证</p>
                        <p style={{paddingTop:'40px'}}>真实姓名、身份证号、手机号</p>
                        <p style={{paddingTop:'40px'}}>用于进行实名认证</p>
                    </p>
                    <p className='table-list'>
                        <p>支付</p>
                        <p style={{paddingTop:'35px'}}>支付时间、支付金额、支付渠道等支付信息</p>
                        <p style={{paddingTop:'40px'}}>用于完成在线支付功能</p>
                    </p>
                    <p className='table-list'>
                        <p>评价和信息发布</p>
                        <p style={{paddingTop:'35px'}}>头像、昵称、发布信息(评价内容）</p>
                        <p style={{paddingTop:'40px'}}>用于展示评价等用户发布的信息</p>
                    </p>
                    <p className='table-list'>
                        <p>搜索和浏览</p>
                        <p style={{paddingTop:'20px'}}>访问及使用过程中产生的点击、浏览、收藏、搜索、查询、分享等产生的信息</p>
                        <p style={{paddingTop:'25px'}}>根据用户的搜索、浏览等需求进行相应展示和推荐</p>
                    </p>
                    <p className='table-list'>
                        <p>客服与售后</p>
                        <p>用户与客服的联系记录、账号信息、身份核验信息、订单信息等客服解决所必要的信息</p>
                        <p style={{paddingTop:'40px'}}>用于客服与售后服务及争议处理</p>
                    </p>
                    <p className='table-list1'>
                        <p>附加功能</p>
                        <p style={{paddingTop:'150px'}}>相机、相册、悬浮窗</p>
                        <p>
                            <p>相机：扫码、拍照、上传图片</p>
                            <p>相册：从相册中上传图片和储存图片</p>
                            <p>悬浮窗：用于用户保持客服消息时提供快捷入口等功能</p>
                            <p>上述附加功能仅在特定场景下用户使用相关功能时才会申请询问，用户拒绝不影响用户使用基本功能</p>
                        </p>
                    </p>
                </div>
            </div>
        )
    }
}
