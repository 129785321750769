import React ,{Component}from 'react'
// import {Link,withRouter} from 'react-router-dom'
import { Flex, Toast, Button } from 'antd-mobile'
import { connect } from 'react-redux'

import {resetdirect} from '../../redux/user.redux'
import FootNav from '../../component/FootNav'
import Swiper from '../../component/Swiper/index'

import { singleHomeRequest, hotmedRequest } from '../../api/request/home'
import { addcartRequest, updatecartRequest, querycartRequest } from '../../api/request/cart'

import './style.less'

import hot from '../../static/img/icon/hotsale.png'
import defaultImg from '../../static/img/default.png'
import zhezaho from "../../static/img/zhezaho.jpg"

class Single extends Component{
    constructor(props) {
        super(props)
        this.state = {
            userId:localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3,
            props: this.props.props,
            pharmacyDetail: null,
            pharmacyId: null,
            category: [],
            activeBanner: [],
            activeBannerLink: [],
            activity: [],
            activityLink: [],
            recommand: [],
            load: true,
            page: 1,
            size: 10
        }
    }

    componentDidMount() {
        //Toast.success("111111")
        const pharmacyId = localStorage.pharmacyId
        console.log(pharmacyId)
        this.setState({
            pharmacyId: pharmacyId && pharmacyId!=='0' ? pharmacyId : 0
        })
        this.homeRequest(pharmacyId)
    }

    homeRequest = async(pharmacyId) => {
        singleHomeRequest(pharmacyId).then(res => {
            console.log(res)
            if(res.code === 1 && res.data[0].length>0) {
                const pharmacyDetail = res.data[0][0]
                document.title = pharmacyDetail.pharmacyName
                localStorage.setItem('pharmacyName', pharmacyDetail.pharmacyName)
                localStorage.setItem('singleTitle', pharmacyDetail.singleTitle)
                localStorage.setItem('singleSubtitlea', pharmacyDetail.singleSubtitlea)
                localStorage.setItem('singleTob', pharmacyDetail.toB)

                const activity = pharmacyDetail.singleActivityPic.split(',')
                const activityLink = pharmacyDetail.singleActivityUrl.split(',')
                const category = res.data[1]
                let activeBanner = []
                let activeBannerLink = []
                res.data[2].forEach((item, index) => {
                    activeBanner.push(item.bannerPic)
                    activeBannerLink.push(item.bannerUrl)
                })
                this.setState({
                    pharmacyDetail: pharmacyDetail,
                    category: category,
                    activity: activity,
                    activityLink: activityLink,
                    activeBanner: activeBanner,
                    activeBannerLink: activeBannerLink,
                    recommand: res.data[3],
                    load: res.data[3].length < this.state.size ? false : true
                })
            }
        })
    }

    pageMedlist = () => {
        if(this.state.load) {
            const params = {
                pharmacyId: this.state.pharmacyId,
                page: this.state.page + 1,
                size: this.state.size
            }
            hotmedRequest(params).then(res => {
                if(res.code === 1) {
                    let data = res.data[0]
                    if(data.length > 0) {
                        this.setState({
                            recommand: [...this.state.recommand, ...data],
                            page: params.page
                        })
                        if(data.length < params.size) {
                            this.setState({
                                load: false
                            })
                        }
                    } else {
                        if(data.length < params.size) {
                            this.setState({
                                load: false
                            })
                        }
                    }
                }
            })
        }
    }

    onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            this.pageMedlist()
        }
    }

    toMedList = (item) => {
        const { categoryId, categoryName } = item
        console.log(categoryId,categoryName)
        if(categoryName === '全部分类') {
            this.state.props.history.push('/classify')
        } else {
            this.state.props.history.push(`/medlist/${categoryId}/0/${categoryName}`)
        }
    }

    activeClick = (link) => {
        this.state.props.history.push(link)
    }

    moreStore = () => {
        this.state.props.history.push('/morestore')
    }

    medDetail = (medId) => {
        this.state.props.history.push(`/med/${medId}`)
    }

    addCart = (e, v) => {
        e.stopPropagation()
        const params = {
            userId: this.state.userId,
            pharmacyId: this.state.pharmacyId,
            medId: v.medId,
            medQuantity: 1
        }
        if(this.state.userId) {
            querycartRequest(params.userId, params.medId).then(res => {
                if(res.code === 1) {
                    if(res.data[0].length > 0) {
                        let data = res.data[0][0]
                        const quantity = data.medQuantity + 1
                        updatecartRequest(quantity, data.cartId).then(res1 => {
                            if(res1.code === 1) {
                                Toast.info('已加入购物车', 1)
                            }
                        })
                    } else {
                        addcartRequest(params).then(res => {
                            if(res.code === 1) {
                                Toast.info('已加入购物车', 1)
                            }
                        })
                    }
                }
            })
        } else {
            this.props.redirectDispatch(`${this.state.props.location.pathname}?env=single&pharmacyId=${params.pharmacyId}`)
            this.state.props.history.push('/login')
        }
    }

    render(){
        const { props, pharmacyDetail, category, activeBanner, activeBannerLink, activity, activityLink, recommand,userId } = this.state

        return(
            <div>
            {pharmacyDetail ?
                <div className='screen-home'
                onScroll={this.onscroll}>
                    <div className='single-color-block'></div>
                    <div className='single-title'>
                        <span>{pharmacyDetail.singleTitle}</span>
                        <span>{pharmacyDetail.singleSubtitlea}</span>
                    </div>
                    <div className='single-search'
                    onClick={()=>props.history.push('/search')}>
                        <i className='iconfont icon-suosou'></i>
                        <span>搜索商品</span>
                    </div>
                    <div className='home-downpart-headernav'>
                        <div className='home-downpart-whiteback'></div>
                        <Flex justify='between' className='flex-activity'>
                        {
                            activity.map((item, index) => (
                                <Flex.Item key={index}
                                className='flex-actie-row'
                                onClick={()=>this.activeClick(activityLink[index])}>
                                    <img src={item} alt=''/>
                                </Flex.Item>
                            ))
                        }
                        </Flex>
                        <div className='swiper-part flex-row'>
                            <div className='swiper-img'>
                                <Swiper banners={activeBanner}
                                link={activeBannerLink}/>
                            </div>
                        </div>
                        <div className='single-all-goods'>
                            <div>{pharmacyDetail.singleSubtitleb}</div>
                            <div onClick={()=>this.toMedList({
                                categoryId: 0,
                                categoryName: '全部分类'
                            })}>全部商品</div>
                        </div>
                        <div className='other-use'>
                            <ul className='clear-fix'>
                            {
                                category.map(item => (
                                    <li key={item.categoryId}
                                    onClick={()=>this.toMedList(item)}>
                                        <img src={item.categoryPng} alt=''/>
                                        <span>{item.categoryName}</span>
                                    </li>
                                ))
                            }
                            </ul>
                        </div>
                    </div>
                    <div className='recommend-med single-recommend-med'>
                        <div className='single-hot-sale'>
                            <div>
                                <img src={hot} alt=''/>
                            </div>
                            <div>热销商品</div>
                        </div>
                        <div className='single-med-list'>
                            <div className='single-med-area flexr'>
                            {recommand.map((v,index)=>{
                                return(
                                    <div className='single-meditem flexr' key={index}
                                    onClick={()=>this.medDetail(v.medId)}>
                                        <div className='single-img'>
                                        {/* src={v.medPhoto?v.medPhoto:defaultImg} */}
                                            <img src={v.medClassify === "1" ? zhezaho : (v.medPhoto ? v.medPhoto : defaultImg)} alt=''
                                            onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                        </div>
                                        <div className='single-info flexc'>
                                            <div className='single-med-name'>{v.medName}{v.medSpec}</div>
                                            <div className='single-info-company'>{v.medCompany}</div>
                                            <div className='single-info-sold'>已售<span>{v.quantity?v.quantity:0}</span></div>
                                            {/* <div className='single-sale-time'>
                                                <div className='sale-delivery-time'>
                                                    <div>预售时间   <span>{v.preSaleTime}</span></div>
                                                    <div>发货时间   <span>{v.deliveryTime}</span></div>
                                                </div>
                                                <div className='single-sold'>已售<span>{v.quantity?v.quantity:0}</span>份</div>
                                            </div> */}
                                            <div className='single-med-price'>
                                                <div className='single-meditem-row flex'>
                                                    <span className={`${v.toB==1&&!userId?'single-price single-price-fontsize30':'single-price single-price-fontsize39'}`}><span>¥</span>{v.toB==1&&!userId?'登录可见':(v.medPrice/100)}</span>
                                                    {v.priceSingle === null ? null : v.toB==1&&!userId ? null :
                                                    <span className='single-spike'><span>¥</span>{v.priceSingle/100}</span>}
                                                </div>
                                                <div className='single-add-car'
                                                onClick={(e)=>this.addCart(e,v)}>
                                                    <Button type='warning'>加入购物车</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}</div>
                        </div>
                    </div>
                </div> : null
            }
                <FootNav/>
            </div>
        )
    }
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Single)