import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import {oderdetailRequest,applyPartRequest,upOrderStuRequest,applyRefundRequest } from '../../../../api/request/order'
import { addAftersaleMed,addAftersale,updateAftersale,aftersaleMedList } from '../../../../api/request/aftersale'
import { uploadImg } from '../../../../api/request/upload'
import { zipImg } from '../../../../component/zipImage/index'
import fanhui from '../../../../static/img/fanhuihei.png'
import finish from '../../../../static/img/finish.png'
import close from '../../../../static/img/close1.png'

import './style.less'
const RadioItem = Radio.RadioItem
//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [invoice, setInvoice] = useState('')
    const [visible, setVisiable] = useState('none')
    const [miaosu, setMiaosu] = useState('')
    const [pharmacyId, setpharmacyId] = useState()

    
    let orderNumber;  //先定义一个存储参数的变量
    if(props.location.state){  //判断是否有参数
        orderNumber=props.location.state;
        localStorage.setItem('orderNumber2', orderNumber);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber2');
    }
    useEffect(() => {
        oderdetailRequest(orderNumber).then(res=>{
            console.log(res);
            setpharmacyId(res.data[0][0].pharmacyId)
        })
    }, [])

    const fanhui1=()=>{
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic1
        if (type === 'add') {
            let file = files[files.length - 1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    console.log(res1);
                    console.log(files);
                    if (res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
   
    const smRreasons=()=>{
        setVisiable('block')
    }
    const tijiao=()=>{
        let time=new Date()
        let time1=time.getFullYear()+'-'+(time.getMonth()+1)+'-'+time.getDate()+' '+time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()
        let params={
            aftersaleNumber:'',
            orderNumber:orderNumber,
            aftersaleReason:invoice,
            aftersaleCertificate:'',
            applyAftersale_time:time1,
            userId:userId,
            pharmacyId:pharmacyId,
            aftersaleDestination:0,
            aftersaleType:'发票类型'
        }
        
        
        if (invoice) {
            if (miaosu) {
                addAftersale(params).then(res=>{
                    console.log(res);
                    let params2={
                        aftersaleState:'1',
                        aftersaleRefuse:'',
                        aftersaleDetails:miaosu,
                        aftersaleId:res.data[1][0].aftersaleId
                    }
                    if (res.code==1) {
                        updateAftersale(params2).then(res1=>{
                        console.log(res1);
                        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
                        })
                    }
                })
            }else{
                Toast.info('具体原因未填写')
            }
        }else{
            Toast.info('请选择发票问题')
        }
    }
    const fuzhi=(v)=>{
        setMiaosu(v.target.value)
    }
    const selectFapiao=(e)=>{
        switch (e.target.value) {
            case 'a':
                setInvoice('发票相关-无票')
                break;
            case 'b':
                setInvoice('发票相关-错票')
                break;
            case 'c':
                setInvoice('发票相关-申请专票')
                break;
            default:
                break;
        }
    }
    const sure=()=>{
        if (invoice) {
            setVisiable('none')
        }else{
            Toast.info('请选择原因')
        }
    }
    return (
        <div>
            <div className='invoice-top'>
                <div className='pifa-invoice'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>发票相关售后</p>   
                </div>
                <div className='selsect-reason'>
                    <span>{invoice?invoice:'发票问题'}</span>
                    <span className='gt' onClick={()=>smRreasons()}><span>请选择</span>{'>'}</span>
                </div>
            </div>
            <div className='invoice-pic'>
                <p className='shuoming'>请给商家说明具体原因</p>
                <div className='upvoucher-in'>
                    <textarea className='miaoshu' placeholder='请描述具体售后要求' maxlength="100" value={miaosu} name='textarea' cols="54" rows="11" onChange={(v)=>fuzhi(v)}></textarea>
                    <span style={{position:'absolute',left:'320px',marginTop:'130px'}}>
                        <span>{miaosu.length}</span>
                        <span>/</span>
                        <span>100</span>
                    </span>
                    {/* <ImagePicker
                        files={files1}
                        length={4}
                        onChange={imgChange1}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files1.length < 4}
                        accept='image/gif,image/jpeg,image/jpg,image/png'
                    /> */}
                </div>
            </div>
            <div className='tijiao' onClick={()=>tijiao()}>
                <img src={finish} alt="" />
                <span>提交申请</span>
            </div>
            {visible=='block'?
                <div className='select-all-reason1'>
                    <div className='selsect-inner'>
                        <img src={close} onClick={()=>setVisiable('none')} alt="" />
                        <h2>请选择原因</h2>
                        <p>
                            <span>发票相关-无票</span>
                            <input type="radio" value='a' name='fapiao' onChange={e=>selectFapiao(e)}/>
                        </p>
                        <p>
                            <span>发票相关-错票</span>
                            <input type="radio" value='b' name='fapiao' onChange={e=>selectFapiao(e)} />
                        </p>
                        <p>
                            <span>发票相关-申请专票</span>
                            <input type="radio" value='c' name='fapiao' onChange={e=>selectFapiao(e)} />
                        </p>
                        <div className='sure' onClick={()=>sure()}>
                            <img src={finish} alt="" />
                            <span>确定</span>
                        </div>
                    </div>
                </div>
            :null}
        </div>
    )
}