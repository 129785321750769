import React, { Component } from 'react'
import "./style.less"
import FH from "../../../static/img/mine/fanhui.png"
import { getUserInvoice, changeUserInvoiceMR, changeUserInvoice } from "../../../api/request/user"
import { Toast } from 'antd-mobile'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            num1: null,
            num2: null,
            checked1: false,
            checked2: false,
            allarr: null,
            id1: null,
            id2: null
        }
    }
    //显示默认发票
    componentDidMount = async () => {
        const { userId } = this.state
        const res = await getUserInvoice({
            userId: userId
        })
        console.log(res);
        let newarr = res.data[0]
        //console.log(newarr)
        newarr.forEach(item => {
            if (item.invoice_type == "专用发票") {
                if (item.invoiceDefault == 1) {
                    this.setState({
                        checked1: true
                    })
                }
                this.setState({
                    num1: item.invoice_Identifier,
                    id1: item.invoiceId
                })
            } else if (item.invoice_type == "普通发票") {
                if (item.invoiceDefault == 1) {
                    this.setState({
                        checked2: true
                    })
                }
                this.setState({
                    num2: item.invoice_Identifier,
                    id2: item.invoiceId
                })
            }
        });
    }
    // 输入框数据关联
    changeone = async (code, e) => {
        const { allarr, id1, id2 } = this.state
        if (id1&&id2) {
            const changeone = async () => {
                const res = await changeUserInvoiceMR({
                    invoiceDefault: 1,
                    invoiceId: id1
                })
                
                const res2 = await changeUserInvoiceMR({
                    invoiceDefault: 0,
                    invoiceId: id2
                })
                console.log(res,res2);
            }
            const changetwo = async () => {
                const res = await changeUserInvoiceMR({
                    invoiceDefault: 0,
                    invoiceId: id1
                })
                const res2 = await changeUserInvoiceMR({
                    invoiceDefault: 1,
                    invoiceId: id2
                })
                console.log(res,res2);
            }
            switch (code) {
                case 1:
                    changeone()
                    this.setState({
                        checked1: e.target.checked,
                        checked2: !e.target.checked
                    }, () => console.log(this.state.checked1, this.state.checked2))
                    break;
                case 2:
                    changetwo()
                    this.setState({
                        checked2: e.target.checked,
                        checked1: !e.target.checked
                    }, () => console.log(this.state.checked1, this.state.checked2))
                    break;
                default:
                    break
            }
        }else{
            Toast.fail("请先编辑模板")
        }
        //console.log(e.target.dataset.id);
        

    }
    callback=()=>{
        const code=localStorage.getItem("cartstatus")
        if (code=="1") {
            localStorage.setItem("cartstatus","0")
            this.props.history.push("/shopcart")
        }else{
            this.props.history.push("/mine")
        }
        
    }
    render() {
        const { num1, num2, checked1, checked2, id1, id2 } = this.state
        return (
            <div>
                <div className="tickettop">
                    <img src={FH} alt="" onClick={this.callback} /><h2>开票信息</h2>
                </div>
                <div className="tickettext">
                    <input type="radio" name="1" checked={checked1} data-id={id1} onChange={(e) => this.changeone(1, e)} />
                    <div>
                        <p>增值税专用发票 {checked1 ? <span>默认</span> : null}</p>
                        <p>识别号：{num1}</p>
                    </div>
                    <span onClick={() => this.props.history.push("/mine/storeticket/zhuanedit")}>编辑</span>
                </div>
                <div className="tickettext">
                    <input type="radio" name="1" checked={checked2} data-id={id2} onChange={(e) => this.changeone(2, e)} />
                    <div>
                        <p>增值税普通发票 {checked2 ? <span>默认</span> : null}</p>
                        <p>识别号：{num2}</p>
                    </div>
                    <span onClick={() => this.props.history.push("/mine/storeticket/moedit")}>编辑</span>
                </div>
            </div>
        )
    }
}
