import { axiosInstance } from "../config"

//用户积分增加
export const addIntegralRequest = (data) => {
    return axiosInstance.post(`/api/hosp/integral/add`,data)
}
// 暂存积分	
export const integralIsuse = (data) => {
    return axiosInstance.post(`/api/hosp/integral/integral-isuse`,data)
}
// 积分查询		
export const getUserIntegral = (data) => {
    return axiosInstance.post(`/api/hosp/integral/get-user-integral`,data)
}
// 积分兑换	
export const pointExchange = () => {
    return axiosInstance.get(`/api/hosp/integral/point-exchange`)
}
//查看积分使用明细
export const integralDetails = (userId) => {
    return axiosInstance.get(`/api/hosp/integral/integral-details?userId=${userId}`)
}