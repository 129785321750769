import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, TextArea } from "antd-mobile-v5"
import { Toast, ImagePicker } from "antd-mobile"
import icon1 from "../../static/img/fanhuihei.png"
import icon2 from "../../static/img/logo.png"
import { getchat, addchatnote,getlist } from "../../api/request/chat"
import { wzpatientInfo } from "../../api/request/wzpatient"


import { zipImg } from '../../component/zipImage/index'
import { uploadImg } from "../../api/request/upload"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            visible1: false,
            value: "",
            consultationId: "",
            detailContent: "",
            newslist: [],
            userpic: localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3,
            userId:localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3,
            fileList: [],
            listtext:{}
        }
    }
    componentDidMount = async () => {
        const {userId}=this.state
        this.getchatnews()
        const res = await getchat({
            userId,
            // docterId: this.state.item.docterId
        })
        console.log(res);
        const res1 = await wzpatientInfo({
            userId,
            // docterId: this.state.item.docterId
        })
        console.log(res1);
        const res2=await getlist({
            // docterId:this.state.item.docterId,
            userId
        })
        console.log(res2);
        res1.data[0].map(item=>{
            if (item.dru_default==1) {
                this.setState({
                    listtext:item
                },()=>{
                    console.log(this.state.listtext);
                })
            }
        })
       
        this.setState({
            consultationId: res.data[0][0].consultationId,
        })
        this.scrollToBottom()
    }
    //获取聊天节点设置默认到最底部
    scrollToBottom = () => {
        const scrollHeight = this.container.scrollHeight
        const clientHeight = this.container.clientHeight
        const scrollTop = scrollHeight - clientHeight
        this.container.scrollTop = scrollTop > 0 ? scrollTop : 0
    }
    totalk = () => {
        this.setState({
            visible1: false
        })
    }
    toinfo = () => {
        const { item } = this.state
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                item: item
            }
        })
    }
    goback = () => {
        const { item } = this.state
        this.props.history.push( "/mine/order")
    }
    //发送信息
    sendout = async () => {
        const { item, consultationId, detailContent, userpic } = this.state
        if (item) {
            if (detailContent) {
                const res = await addchatnote({
                    consultationId: consultationId,
                    Identity: 1,
                    detailType: 1,
                    detailContent
                })
                this.getchatnews()
                this.setState({
                    detailContent: ""
                })
            } else {
                Toast.fail("请先输入内容", 1)
            }

        } else {
            Toast.fail("数据错误", 1)
            this.props.history.push("/")
        }
    }
    // 获取聊天信息
    getchatnews = async () => {
        const { item } = this.state
        console.log(item);
        const res = await getchat({
            userId: localStorage.getItem("patientId"),
            // docterId: this.state.item.docterId
        })
        console.log(res);
        this.setState({
            consultationId: res.data[0][0].consultationId,
            newslist: res.data[1]
        }, () => {
            this.scrollToBottom()
        })
    }
    getdetailContent = (e) => {
        this.setState({
            detailContent: e.target.value
        })
    }
    //图片上传发送
    imgChange = async (files, type, index) => {
        const { item, consultationId, detailContent, userpic } = this.state
        if (type == "add") {
            let file = files[files.length - 1].file
            let formData = new FormData()
            const res = await zipImg(file)
            // console.log(res);
            formData.append('file', res)
            const res2 = await uploadImg(formData)
            //  console.log(res2);
            // this.setState({
            //     fileList: [...files]
            // })
            console.log(res2);
            if (item) {
                const res = await addchatnote({
                    consultationId: consultationId,
                    Identity: 1,
                    detailType: 2,
                    detailContent: res2.data
                })
                this.getchatnews()
                this.setState({
                    detailContent: ""
                })
            } else {
                Toast.fail("数据错误", 1)
                this.props.history.push("/")
            }
        } else {
            this.setState({
                fileList: []
            })
        }
    }
    render() {
        const { item, visible1, value, detailContent, newslist, userpic, fileList,listtext } = this.state
        return (
            <>
                <div className='chatbox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.goback} />
                        <h1>
                            <span>复诊开方</span>
                            {/* <span>{item.docterName}</span><span>{item.title}</span><span>{item.department}</span> */}
                            
                        </h1>
                    </div>
                    <div className='box-tips'>
                        <span className='blue'>填写信息</span>
                        <span className='blue gt'>{'>'}</span>
                        <span className='blue'>医生开方</span>
                        <span className='gt'>{'>'}</span>
                        <span>药师审方</span>
                        <span className='gt'>{'>'}</span>
                        <span>药房发货</span>
                    </div>
                    <div className='box-container' ref={el => this.container = el}>
                    <div className='container-dan'>
                            {/* <img src={icon2} alt="" /> */}
                        <div className='dan-left'>
                            <div className='left-top'>
                                <p>患者信息</p>
                            </div>
                            <div className='itembox' style={{ backgroundColor: "white" }}>
                                <div className='left-item'>
                                    <p>患者</p>
                                    <p>{listtext.dru_name}</p>
                                </div>
                                <div className='left-item'>
                                    <p>既往史</p>
                                    <p>{listtext.past_history==0?'无':listtext.past_history_note}</p>
                                </div>
                                <div className='left-item'>
                                    <p>过敏史</p>
                                    <p>{listtext.allergic_history==0?'无':listtext.allergic_history_note}</p>
                                </div>
                                <div className='left-item'>
                                    <p>家族遗传史</p>
                                    <p>{listtext.family_genetic_history==0?'无':listtext.family_genetic_history_node}</p>
                                </div>
                                <div className='left-item'>
                                    <p>肝功能异常</p>
                                    <p>{listtext.abnormal_liver_function==0?'正常':'异常'}</p>
                                </div>
                                <div className='left-item'>
                                    <p>肾功能异常</p>
                                    <p>{listtext.abnormal_renal_function==0?'正常':'异常'}</p>
                                </div>
                                <div className='left-title'></div>
                                <div className='left-item'>
                                    <p>本次确诊疾病</p>
                                    <p>{listtext.confirmed_disease}</p>
                                </div>
                                <div className='left-item'>
                                    <p>药品需求</p>
                                    <p className='last-bottom'>
                                        <span>[品牌名]胜多负少的充分的付出撒旦</span>
                                        <span>x2</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* {newslist.map((item, index) => {
                            return (
                                <div className={item.Identity == "1" ? "container-right" : "container-left"} key={item.createTime}>
                                    <img src={userpic ? userpic : icon2} alt="" />
                                    {item.detailType == "1" ? <div className='left-text'>
                                        {item.detailContent}
                                    </div> : item.detailType == "2" ? <div className='left-text'>
                                        <img src={item.detailContent} alt="" className='right-img' />
                                    </div> : null}
                                </div>
                            )
                        })} */}
                        {/* <div className='container-left'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                那地方立刻释放你的什么麦当劳少女但是看了你反馈
                            </div>
                        </div>
                        <div className='container-right'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                那地方立刻释放你的什么麦当劳少女但是看了你反馈
                            </div>
                        </div> */}
                        {/* <div className='container-cfd'>
                            <img src={icon2} alt="" />
                            <div className='dan-left'>
                                <div className='left-top'>
                                    <p>处方单</p>
                                </div>
                                <div className='itembox' style={{ backgroundColor: "white" }}>
                                    <div className='left-item'>
                                        <p>患者</p>
                                        <p>
                                            <span>李</span><span>男</span><span>27</span>
                                        </p>
                                    </div>
                                    <div className='left-item'>
                                        <p>诊断</p>
                                        <p>肺炎</p>
                                    </div>
                                    <div className='left-title'>
                                        <button onClick={this.toinfo}>查看详情</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='container-conclusion'>
                            <img src={icon2} alt="" />
                            <div className='left-text'>
                                <img src={icon2} alt="" />
                                <div>
                                    <p>问诊小结</p>
                                    <p>查看问诊小结</p>
                                </div>
                            </div>
                        </div>
                        <div className='container-evaluate'>
                            <div>
                                <h1>请您对医生的这次服务进行评价</h1>
                                <button onClick={() => this.setState({
                                    visible1: true
                                })}>给评价</button>
                            </div>
                        </div> */}
                    </div>
                    <div className='box-bottom'>
                        <input type="text" placeholder='病情描述' value={detailContent} onChange={(e) => this.getdetailContent(e)} />
                        <ImagePicker
                            files={fileList}
                            length={1}
                            style={{ width: "50px", height: "50px", padding: "0", display: "inline-block", verticalAlign: "middle" }}
                            onChange={this.imgChange}
                            selectable={fileList.length < 1}
                            accept='image/gif,image/jpeg,image/jpg,image/png' />
                        <button onClick={this.sendout}>发送</button>
                    </div>
                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '40vh' }}
                >
                    <div className='popup-box'>
                        <div className='box-top'>
                            <img src={icon2} alt="" />
                            <div>
                                <h1>评价医生本次服务</h1>
                                <p>
                                    <span>朱心寒</span><span>副主任医师</span><span>呼吸内科</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pingfen'>
                        <Rate
                            style={{
                                '--active-color': '#1F87FF'
                            }}
                            onChange={val => Toast.show(val.toString())} />
                    </div>
                    <div className='popup-text'>
                        <textarea name="" id="" cols="50" rows="6" placeholder='谈谈你对本次就医医生的评价吧~'>

                        </textarea>

                    </div>
                    <div className='popup-btn'>
                        <button onClick={this.totalk}>提交评论</button>
                    </div>

                </Popup>
            </>

        )
    }
}
