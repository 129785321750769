import React, { useState, useEffect } from "react";
import { ErrorBlock, ImageViewer } from "antd-mobile-v5";
import "./style.less";

export default function Table(props) {
  const [isShow, setisShow] = useState(false);
  const [medDetail, setMedDetail] = useState(props.medDetail);
  const [image, setImage] = useState({
    url: "",
    visible: false,
  });

  useEffect(() => {
    setMedDetail(props.medDetail);
  }, [props.medDetail]);

  let word = 0;
  let qualificationsArray = [];
  if (medDetail.medApproval.indexOf("械") > -1) {
    word = 1;
  } else if (medDetail.medApproval.indexOf("药") > -1) {
    word = 2;
  } else if (medDetail.medApproval.indexOf("消") > -1) {
    word = 3;
  } else if (medDetail.medApproval.indexOf("食健") > -1) {
    word = 4;
  } else if (medDetail.medApproval.indexOf("妆") > -1) {
    word = 5;
  } else {
    word = 6;
  }

  if (medDetail.medQualifications) {
    qualificationsArray = medDetail.medQualifications.split(",");
  }
  // console.log(word)
  return (
    <>
      <table className="med-table" border="1" cellSpacing="0" cellPadding="0">
        {/*  药品*/}
        {word == 2 ? (
          <tbody>
            <tr>
              <td>通用名称{medDetail.medComName}</td>
              <td>{medDetail.medComName}</td>
            </tr>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>处方类型</td>
              {medDetail.medClassify === "1" ? (
                <td>处方药</td>
              ) : (
                <td>非处方药</td>
              )}
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            {medDetail.medIf === 0 ? (
              <tr>
                <td>规格/包装</td>
                <td>{medDetail.medSpec}</td>
              </tr>
            ) : null}
            {medDetail.medIf === 0 ? (
              <tr>
                <td>药品类别</td>
                <td>{medDetail.medType}</td>
              </tr>
            ) : null}
            {isShow === true ? (
              <>
                {medDetail.medIf === 0 ? (
                  <tr>
                    <td>剂型</td>
                    <td>{medDetail.medDosageForm}</td>
                  </tr>
                ) : null}
                <tr>
                  <td>药品成分</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>功能主治</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                {/*{medDetail.medIf===0?<tr>*/}
                {/*    <td>适用人群</td>*/}
                {/*    <td>{medDetail.medApply}</td>*/}
                {/*</tr>:null}*/}
                {medDetail.medIf === 0 ? (
                  <tr>
                    <td>用法用量</td>
                    <td>{medDetail.medUsage}</td>
                  </tr>
                ) : null}
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>用药禁忌</td>
                  <td>{medDetail.medTaboo}</td>
                </tr>
                <tr>
                  <td>不良反应</td>
                  <td>{medDetail.medAdverse}</td>
                </tr>
                {medDetail.medIf === 0 ? (
                  <tr>
                    <td>储存条件</td>
                    <td>{medDetail.medStorage}</td>
                  </tr>
                ) : null}
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>条形码</td>
                  <td>{medDetail.medQrcode}</td>
                </tr>
                <tr>
                  <td>{medDetail.medIf === 0 ? "批准文号" : "注册证号"}</td>
                  <td>{medDetail.medApproval}</td>
                </tr>
                <tr>
                  <td>生产厂家</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                <tr>
                  <td>国产/进口</td>
                  <td>{medDetail.medOrigin === 0 ? "国产" : "进口"}</td>
                </tr>
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
        {/*    器械*/}
        {word == 1 ? (
          <tbody>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            <tr>
              <td>规格/包装</td>
              <td>{medDetail.medSpec}</td>
            </tr>
            <tr>
              <td>注册证号/备案号</td>
              <td>{medDetail.medApproval}</td>
            </tr>

            <tr>
              <td>产品技术编号</td>
              <td>{medDetail.medTechnology}</td>
            </tr>
            <tr>
              <td>产地类型</td>
              <td>{medDetail.medOrigin == 0 ? "国产" : "进口"}</td>
            </tr>
            {medDetail.medOrigin == 1 && (
              <tr>
                <td>代理人</td>
                <td>{medDetail.agent}</td>
              </tr>
            )}
            {isShow === true ? (
              <>
                <tr>
                  <td>生产企业</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                <tr>
                  <td>生产许可证/备案号</td>
                  <td>{medDetail.medApparatus}</td>
                </tr>
                <tr>
                  <td>结构及组成</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>适用范围</td>
                  <td>{medDetail.medApplySymptoms}</td>
                </tr>
                <tr>
                  <td>用法用量</td>
                  <td>{medDetail.medUsage}</td>
                </tr>
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>不良反应</td>
                  <td>{medDetail.medAdverse}</td>
                </tr>
                <tr>
                  <td>禁忌</td>
                  <td>{medDetail.medTaboo}</td>
                </tr>

                <tr>
                  <td>储存条件</td>
                  <td>{medDetail.medStorage}</td>
                </tr>
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>医疗器械分类</td>
                  <td>
                    {medDetail.medClassification == "一类"
                      ? "Ⅰ类"
                      : medDetail.medClassification == "二类"
                      ? "Ⅱ类"
                      : medDetail.medClassification == "三类"
                      ? "Ⅲ类"
                      : null}
                  </td>
                </tr>
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
        {/*  消毒用品*/}
        {word == 3 ? (
          <tbody>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            <tr>
              <td>规格/包装</td>
              <td>{medDetail.medSpec}</td>
            </tr>
            <tr>
              <td>产地类型</td>
              <td>{medDetail.medOrigin == 0 ? "国产" : "进口"}</td>
            </tr>
            {medDetail.medOrigin == 1 && (
              <tr>
                <td>代理人</td>
                <td>{medDetail.agent}</td>
              </tr>
            )}
            {isShow === true ? (
              <>
                <tr>
                  <td>生产企业</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                <tr>
                  <td>适用范围</td>
                  <td>{medDetail.medApplySymptoms}</td>
                </tr>
                {/*<tr>*/}
                {/*    <td>适用人群</td>*/}
                {/*    <td>{medDetail.medApply}</td>*/}
                {/*</tr>*/}
                <tr>
                  <td>成分</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>产品功效</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                <tr>
                  <td>用法用量</td>
                  <td>{medDetail.medUsage}</td>
                </tr>
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>储存条件</td>
                  <td>{medDetail.medStorage}</td>
                </tr>
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
        {/*  保健食品*/}
        {word == 4 ? (
          <tbody>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            <tr>
              <td>规格/包装</td>
              <td>{medDetail.medSpec}</td>
            </tr>
            <tr>
              <td>产地类型</td>
              <td>{medDetail.medOrigin == 0 ? "国产" : "进口"}</td>
            </tr>
            {medDetail.medOrigin == 1 && (
              <tr>
                <td>代理人</td>
                <td>{medDetail.agent}</td>
              </tr>
            )}
            {isShow === true ? (
              <>
                <tr>
                  <td>生产企业</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                {/*<tr>*/}
                {/*    <td>适宜人群</td>*/}
                {/*    <td>{medDetail.medApply}</td>*/}
                {/*</tr>*/}
                <tr>
                  <td>不适宜人群</td>
                  <td>{medDetail.medTaboo}</td>
                </tr>
                <tr>
                  <td>保健功能</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                <tr>
                  <td>成分</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>产品功效</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                <tr>
                  <td>使用方法/食用量</td>
                  <td>{medDetail.medUsage}</td>
                </tr>
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>储存条件</td>
                  <td>{medDetail.medStorage}</td>
                </tr>
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
        {/*  化妆品*/}
        {word == 5 ? (
          <tbody>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            <tr>
              <td>规格/包装</td>
              <td>{medDetail.medSpec}</td>
            </tr>
            <tr>
              <td>产地类型</td>
              <td>{medDetail.medOrigin == 0 ? "国产" : "进口"}</td>
            </tr>
            {medDetail.medOrigin == 1 && (
              <tr>
                <td>代理人</td>
                <td>{medDetail.agent}</td>
              </tr>
            )}
            {isShow === true ? (
              <>
                <tr>
                  <td>生产企业</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                <tr>
                  <td>适用范围</td>
                  <td>{medDetail.medApplySymptoms}</td>
                </tr>
                {/*<tr>*/}
                {/*    <td>适宜人群</td>*/}
                {/*    <td>{medDetail.medApply}</td>*/}
                {/*</tr>*/}
                <tr>
                  <td>成分</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>产品功效</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                <tr>
                  <td>用法用量</td>
                  <td>{medDetail.medUsage}</td>
                </tr>
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>储存条件</td>
                  <td>{medDetail.medStorage}</td>
                </tr>
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
        {/*  普通食品*/}
        {word == 6 ? (
          <tbody>
            <tr>
              <td>商品名称</td>
              <td>{medDetail.medName}</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{medDetail.medBrand}</td>
            </tr>
            <tr>
              <td>规格/包装</td>
              <td>{medDetail.medSpec}</td>
            </tr>
            <tr>
              <td>产地类型</td>
              <td>{medDetail.medOrigin == 0 ? "国产" : "进口"}</td>
            </tr>
            {medDetail.medOrigin == 1 && (
              <tr>
                <td>代理人</td>
                <td>{medDetail.agent}</td>
              </tr>
            )}
            {isShow === true ? (
              <>
                {/*<tr>*/}
                {/*    <td>产地</td>*/}
                {/*    <td>{medDetail.medCompany}</td>*/}
                {/*</tr>*/}
                <tr>
                  <td>生产企业</td>
                  <td>{medDetail.medCompany}</td>
                </tr>
                <tr>
                  <td>适用范围</td>
                  <td>{medDetail.medExplain}</td>
                </tr>
                {/*<tr>*/}
                {/*    <td>适宜人群</td>*/}
                {/*    <td>{medDetail.medApply}</td>*/}
                {/*</tr>*/}
                <tr>
                  <td>成分</td>
                  <td>{medDetail.medBasis}</td>
                </tr>
                <tr>
                  <td>性状</td>
                  <td>{medDetail.medDosageForm}</td>
                </tr>
                <tr>
                  <td>产品功效</td>
                  <td>{medDetail.medApplySymptoms}</td>
                </tr>
                <tr>
                  <td>食用方法/食用量</td>
                  <td>{medDetail.medUsage}</td>
                </tr>
                <tr>
                  <td>注意事项</td>
                  <td>{medDetail.medAttention}</td>
                </tr>
                <tr>
                  <td>储存条件</td>
                  <td>{medDetail.medStorage}</td>
                </tr>
                {medDetail.medValidity ? (
                  <tr>
                    <td>有效期</td>
                    <td>{medDetail.medValidity}个月</td>
                  </tr>
                ) : (
                  <tr>
                    <td>有效期</td>
                    <td></td>
                  </tr>
                )}
                {medDetail.medQualifications ? (
                  <tr>
                    <td>商品资质图</td>
                    {qualificationsArray && qualificationsArray.length > 0 && (
                      <td>
                        {qualificationsArray.map((item, index) => (
                          <img
                            key={index}
                            onClick={() => {
                              setImage({
                                url: item,
                                visible: true,
                              });
                            }}
                            style={{ width: "50px" }}
                            src={item}
                            alt={`qualification-${index}`}
                          />
                        ))}
                      </td>
                    )}
                  </tr>
                ) : null}
              </>
            ) : null}
          </tbody>
        ) : null}
      </table>
      <div
        className="med-table-collapse"
        onClick={() => setisShow(!isShow)}
        style={isShow === true ? { display: "block" } : { display: "none" }}
      >
        <span>收起</span>
        <i className="icon-xiangshang iconfont"></i>
      </div>
      <div
        className="med-table-collapse"
        onClick={() => setisShow(!isShow)}
        style={isShow === false ? { display: "block" } : { display: "none" }}
      >
        <span>展开</span>
        <i className="icon-xiangxia iconfont"></i>
      </div>
      <ImageViewer
        image={image.url}
        visible={image.visible}
        onClose={() => {
          setImage({
            url: "",
            visible: false,
          });
        }}
      />
    </>
  );
}
