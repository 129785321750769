import React, { Component } from 'react'
import Axios from 'axios';
import {getToken,getJMURL,wxGetuserid} from "../../api/request/user"
import { baseUrl,code1 } from "../../publicurl"
export default class getUserInfo extends Component {
    constructor(){
        super();
        this.state={
            list:[],
            a:localStorage.getItem('wxlogin'),
            l:''
        }
    }
    componentDidMount=async()=>{
        const arr = window.location.href.split("&")
        let url
        console.log(arr)
        if (code1==1000){
            url = arr[0].substring(53, arr[0].length)
        }else{
            url = arr[0].substring(54, arr[0].length)
        }
        // let url = arr[0].substring(53, arr[0].length)
        // let url = arr[0].substring(54, arr[0].length)
        console.log(url);
        const res=await getJMURL({
            url
        })
        console.log(res)
        this.setState({
            list:res.data,
            l:arr
        },()=>{
            const wximg=res.data.headimgurl
            const wxuserName=res.data.nickname
            const wxuserOpenid=res.data.openid
            //console.log(wximg,wxuserName,wxuserOpenid)
            localStorage.setItem("wxuserName",wxuserName)
            localStorage.setItem("wximg",wximg)
            localStorage.setItem("wxuserOpenid",wxuserOpenid)
            wxGetuserid(res.data.openid).then(res1=>{
                console.log(res1)
                if (res1.data[0].length){
                    localStorage.setItem("userId1",res1.data[0][0].userId)
                }
            })
        })

            if(!localStorage.getItem("type")){
                localStorage.setItem('type',1)
            }
            if (code1==1000){
                window.location.href="https://h5.sczyzk.com/index.html#/"
            }else{
                window.location.href="http://t-h5.sczyzk.com/index.html#/"
            }
            // window.location.href=window.location.protocol + '//' + window.location.host +'/index.html#/'
            // window.location.href=`${baseUrl}/index.html#/`
    }
    render() {
        return (
            <div>
               <h1>请稍等</h1>
               {/* <p>{JSON.stringify(this.state.l)}</p> */}
            {/* <p>{localStorage.getItem("type")}</p> */}
            </div>
        )
    }
}
