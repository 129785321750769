import { axiosInstance } from "../config";
//添加订单整体
export const addAll = (data) => {
  return axiosInstance.post(`/api/hosp/order/addall-snapshot`, data);
};
//修改库存
export const changenum = (data) => {
  return axiosInstance.post(`/api/hosp/erp-base/update-stock`, data);
};

//查询订单列表
export const oderlistRequest = (data) => {
  return axiosInstance.post("/api/hosp/order/list", data);
};

//添加订单
export const addorderRequest = (data) => {
  return axiosInstance.post("/api/hosp/order/add", data);
};

//查询订单详情
export const oderdetailRequest = (orderNumber) => {
  return axiosInstance.get(
    `/api/hosp/order/details?orderNumber=${orderNumber}`
  );
};

//删除订单
export const delOrderRequest = (orderNumber) => {
  return axiosInstance.get(`/api/hosp/order/delete?orderNumber=${orderNumber}`);
};

//修改订单
export const updateOrderRequest = (data) => {
  return axiosInstance.post("/api/hosp/order/update", data);
};

//添加订单药品
export const addordermedRequest = (data) => {
  return axiosInstance.post("/api/hosp/order/addmed", data);
};

// 更新订单状态
export const upOrderStuRequest = (data) => {
  return axiosInstance.post(`/api/hosp/order/updatestu`, data);
};

// 申请退款
export const applyRefundRequest = (data) => {
  return axiosInstance.post(`/api/hosp/order/apply_refund`, data);
};
// 订单部分药品退款
export const applyPartRequest = (data) => {
  return axiosInstance.post(`/api/hosp/order/refund_med`, data);
};
// 取消申请退款
export const cancelRefundRequest = (orderNumber) => {
  return axiosInstance.get(
    `/api/hosp/order/cancel_refund?orderNumber=${orderNumber}`
  );
};

// 用药人添加
export const addPharmacyUser = (data) => {
  return axiosInstance.post(`/api/hosp/user/adddrug`, data);
};

//中通取消订单
export const cancelZTorder = (data) => {
  return axiosInstance.post(`/faas/hosp/erp-base/zt-quorder`, data);
};

//添加订单汇款凭证
export const addRemittance = (data) => {
  return axiosInstance.post(`/api/hosp/order/add-remittance`, data);
};

//获取订单转账凭证
export const remittanceList = (data) => {
  return axiosInstance.post(`/api/hosp/order/remittance-list`, data);
};

//删除订单汇款凭证
export const delRemittance = (data) => {
  return axiosInstance.post(`/api/hosp/order/del-remittance`, data);
};

//获取默认发票模板
export const getDefaultinvoice = (data) => {
  return axiosInstance.post(`/api/hosp/invoice/get-default-invoice`, data);
};

//获取圆通物流信息
export const ytGetwl = (data) => {
  return axiosInstance.post(`/faas/hosp/erp-base/yt-getwl`, data);
};

//获取京东物流信息
export const jdGetwl = (data) => {
  return axiosInstance.post(`/faas/hosp/erp-base/jd-sjgettrajector`, data);
};

//获取申通物流信息
export const stGetwl = (data) => {
  return axiosInstance.post(`/faas/hosp/erp-base/st-getlogistics`, data);
};
//更新部分商品退款状态
export const updatePartrefundState = (data) => {
  return axiosInstance.post(`/api/hosp/order/update-partrefund-state`, data);
};

//查询蜂鸟订单详情
export const geteleorder = (data) => {
  return axiosInstance.post(`/faas/hosp/erp-base/get-details`, data);
};
// 获取默认发票名称
export const getDefaultname = (data) => {
  return axiosInstance.post(`/api/hosp/invoice/get-defaultname`, data);
};
// 查看退款失败原因
export const refundView = (data) => {
  return axiosInstance.post(`/api/hosp/order/refund-view`, data);
};
// 用户查看退款原因
export const refundUserview = (data) => {
  return axiosInstance.post(`/api/hosp/order/refund-userview`, data);
};
//增加取件码
export const addCode = (data) => {
  return axiosInstance.post("/api/hosp/order/add-code", data);
};

//查询取件码
export const lookCode = (orderNumber) => {
  return axiosInstance.get(
    `/api/hosp/order/look-code?orderNumber=${orderNumber}`
  );
};
// 添加问诊单号
export const addWzdanhao = (data) => {
  return axiosInstance.post("/api/hosp/order/add-wzdanhao", data);
};
// 获取药房客服电话
export const getKfphone = (pharmacyId) => {
  return axiosInstance.get(
    `/api/hosp/order/get-kfphone?pharmacyId=${pharmacyId}`
  );
};
// 查询商品是否是自提订单isZiti
export const isZiti = (data) => {
  return axiosInstance.post("/api/hosp/order/is-ziti", data);
};
// 修改订单地址
export const updateAddress = (data) => {
  return axiosInstance.post("/api/hosp/order/update-address", data);
};
// 获取骑手距离
export const qishouJuli = (data) => {
  return axiosInstance.post("/api/hosp/order/qishou-juli", data);
};
// 统计订单
export const tjState = (userId) => {
  return axiosInstance.get(`/api/hosp/order/tj-state?userId=${userId}`);
};
// 查询订单状态
export const getOrdersate = (data) => {
  return axiosInstance.post("/api/hosp/order/get-ordersate", data);
};
// 支付页面查询优惠券
export const payYouhui = (data) => {
  return axiosInstance.post("/api/hosp/order/pay-youhui", data);
};
// 支付页面查询满减
export const payManjian = (data) => {
  return axiosInstance.post("/api/hosp/order/pay-manjian", data);
};
// 取消支付恢复优惠券状态
export const huifuCouponState = (data) => {
  return axiosInstance.post("/api/hosp/order/huifu-coupon-state", data);
};
// 支付页面折扣商品	/api/hosp/order/pay-discount
export const payDiscount = (data) => {
  return axiosInstance.post("/api/hosp/order/pay-discount", data);
};
// 修改折扣商品数量和库存
export const updateDiscountSum = (data) => {
  return axiosInstance.post("/api/hosp/order/update-discount-sum", data);
};
// 支付时查询配送费
export const payReducedFreight = (data) => {
  return axiosInstance.post("/api/hosp/order/pay-reduced-freight", data);
};
// 生成订单号
export const setOrdernumber = () => {
  return axiosInstance.get(`/api/hosp/order/set-ordernumber`);
};
//获取距离
export const getDistance = (data) => {
  return axiosInstance.post("/api/hosp/order/get-distance", data);
};
//通过订单号获取收货地址
export const getaddress = (data) => {
  return axiosInstance.post("/api/hosp/order/getaddress", data);
};
//查询订单交易快照商品详情
export const snapshotMedDetail = (data) => {
  return axiosInstance.post(`/api/hosp/order/snapshot-details`, data);
};
