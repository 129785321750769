import React, { Component } from 'react'
import icon from "../../static/img/home/yuantong.png"
import "./style.less"
import icon2 from "../../static/img/home/ytsmall.jpg"
import icon3 from "../../static/img/home/y.png"
import icon4 from "../../static/img/home/yticon1.png"
import icon5 from "../../static/img/home/yticon2.png"
import JsBarcode from 'jsbarcode'
import { oderdetailRequest } from "../../api/request/order"
import { getstore } from "../../api/request/store"
const font = "10pt"
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            value: null,
            code: true,
            storeId: 25,
            storedata: {},
            orderdata: {},
            orderarr: {},
            orderId: "16357312658915527273458",
            dateTime:"",
            date:"",
            orderShortAddress:""
        }
    }
    componentWillMount = async () => {
        const res = await oderdetailRequest(this.state.orderId)
        console.log(res)
        this.setState({
            orderdata: res.data[0][0],
            value: res.data[0][0].courierNumber,
            orderarr: res.data[1][0],
            orderShortAddress:res.data[0][0].orderShortAddress.substring(4,10)
        }, () => console.log(this.state.orderShortAddress))
        const res2 = await getstore({
            pharmacyId: this.state.storeId
        })
        //console.log(res2)
        this.setState({
            storedata: res2.data[0][0]
        })
        let yy = new Date().getFullYear()
        let mm = new Date().getMonth() + 1
        let dd = new Date().getDate()
        let hh = new Date().getHours()
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
        :
        new Date().getMinutes()
        let dateTime
        let date
        dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf
        date= yy + '-' + mm + '-' + dd
        this.setState({
            dateTime,
            date
        })
    }
    // 条形码生成,获取订单信息
    componentDidMount = async () => {
        JsBarcode(this.barcode, this.state.value, {
            displayValue: false,
            width: 3,
            height: 32,
            margin: 0,
        });
        JsBarcode(this.barcode2, this.state.value, {
            displayValue: false,
            width: 2.6,
            height: 25,
            margin: 0,
        });

    }
    doPrint = () => {
        this.setState({
            code: false
        }, () => {
            document.getElementsByTagName('body')[0].style.zoom = 0.50
            window.print();
            document.getElementsByTagName('body')[0].style.zoom = 1
            this.setState({
                code: true
            })
        })

    }
    render() {
        const { orderdata, storedata, orderarr ,dateTime,date,orderShortAddress} = this.state
        return (
            <div>
                {this.state.code ? <div style={{ width: "800px", height: "35px" }}>
                    <button onClick={this.doPrint}>
                        <i className="fa fa-print"></i>打印
                    </button>
                </div> : null}
                {/* script */}
                <div className="pbox">
                    <div className="box-top">
                        <div className="box-top-left">
                            <div className="box-top-left-top">
                                <img src={icon} alt="" />
                            </div>
                            <div className="box-top-left-bottom">
                                <p style={{ fontSize: font }}>{orderdata.courierNumber}</p>
                                <p style={{ fontSize: font }}><span style={{ fontSize: font }}>{dateTime}</span></p>
                            </div>
                        </div>
                        <div className="box-top-right">
                            <div style={{ fontSize: font }}>
                                代收货款
                                <p style={{ fontSize: font }}>￥<span style={{ fontSize: font }}></span></p>
                            </div>
                            <div className="box-top-right-text"><img src={icon5} alt="" /></div>
                            <div className="box-top-right-text"><img src={icon4} alt="" /></div>
                            <div className="box-top-right-text"><img src={icon3} alt="" /></div>
                        </div>
                    </div>
                    <div className="box-ma1">
                        <div className="box-ma1-top">
                            <svg
                                ref={(ref) => {
                                    this.barcode = ref;
                                }}
                            />
                        </div>
                        <p>{orderdata.courierNumber}</p>
                    </div>
                    <div className="box-num">
                       {orderdata.orderShortAddress}
                    </div>
                    <div className="box-shou">
                        <div className="box-shou-left">
                            收
                        </div>
                        <div className="box-shou-right">
                            <div style={{ marginBottom: "5pt" }}><span style={{ fontSize: font, marginRight: "5pt" }}>{orderdata.addressee}</span><span style={{ fontSize: font }}>{orderdata.phone}</span></div>
                            <div style={{ fontSize: font }}>{orderdata.province + orderdata.city + orderdata.district + orderdata.address}</div>
                        </div>
                    </div>
                    <div className="box-ji">
                        <div className="box-ji-left">
                            寄
                        </div>
                        <div className="box-ji-right">
                            <div style={{ marginBottom: "5pt" }}><span style={{ fontSize: font, marginRight: "5pt" }}>{storedata.accountName}</span><span style={{ fontSize: font }}>{storedata.contactPhone}</span></div>
                            <div style={{ fontSize: font }}>{storedata.companyAddress}</div>
                        </div>
                    </div>
                    <div className="box-name">
                        <div className="box-name-left">
                            品名
                        </div>
                        <div className="box-name-right">
                            <p style={{ fontSize: font }}>物品名称: <span style={{ fontSize: font }}>{orderarr.medName}</span></p>
                            <p style={{ fontSize: font }}>系统: <span style={{ fontSize: font }}>YZ</span></p>
                            <p style={{ fontSize: font }}>重量: <span style={{ fontSize: font }}></span>kg</p>
                            <p style={{ fontSize: font }}>运费: ￥<span style={{ fontSize: font }}></span></p>
                        </div>
                    </div>
                    <div className="box-username">
                        <div className="box-username-left">{orderShortAddress}</div>
                        <div className="box-username-right">
                            <div>
                                <p>签收人: <span>{storedata.contacts}</span></p>
                                <p>日期: <span>{date}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="box-ma2">
                        <div className="box-ma2-left">
                            <img src={icon} alt="" />
                        </div>
                        <div className="box-ma2-right">
                            <div>
                                <svg
                                    ref={(ref) => {
                                        this.barcode2 = ref;
                                    }}
                                />
                            </div>
                            <div>{orderdata.courierNumber}</div>
                        </div>
                    </div>
                    <div className="box-text">
                        <div className="box-text-left">
                            收
                        </div>
                        <div className="box-text-right">
                            <div style={{ marginBottom: "5pt" }}><span style={{ fontSize: font, marginRight: "5pt" }}>{orderdata.addressee}</span><span style={{ fontSize: font }}>{orderdata.phone}</span></div>
                            <div style={{ fontSize: font }}>{orderdata.province + orderdata.city + orderdata.district + orderdata.address}</div>
                        </div>
                    </div>
                    <div className="box-text">
                        <div className="box-text-left">
                            寄
                        </div>
                        <div className="box-text-right">
                            <div style={{ marginBottom: "5pt" }}><span style={{ fontSize: font, marginRight: "5pt" }}>{storedata.accountName}</span><span style={{ fontSize: font }}>{storedata.contactPhone}</span></div>
                            <div style={{ fontSize: font }}>{storedata.companyAddress}</div>
                        </div>
                    </div>
                    <div className="box-name2">
                        <div className="box-name2-one">品名</div>
                        <div className="box-name2-two">
                            <p style={{ fontSize: font }}>物品名称: <span style={{ fontSize: font }}>{orderarr.medName}</span></p>
                            <p style={{ fontSize: font }}>重量: <span style={{ fontSize: font }}></span>kg</p>
                            <p style={{ fontSize: font }}>系统: <span style={{ fontSize: font }}>YZ</span></p>
                            <p style={{ fontSize: font }}>运费: ￥<span style={{ fontSize: font }}></span></p>
                        </div>
                        <div className="box-name2-three">
                            <img src={icon2} alt="" /><div>微信扫一扫 <br />获取物流轨迹</div>
                        </div>
                    </div>
                    <div className="box-bottom" style={{ fontSize: font }}>
                        form:   <span style={{ fontSize: font }}>210045</span>
                    </div>
                </div>
                {/* b */}
            </div >
        )
    }
}
