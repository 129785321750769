import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import acttest from '../../static/img/app/acttest.jpeg'
import acttest1 from '../../static/img/actpage1-bgc.png'
import defaultImg from '../../static/img/default.png'
import zhezaho from "../../static/img/zhezaho.jpg"
import zk from '../../static/img/zk.png'
import Nodata from '../../component/Nodata'
import { discountMed,allPharmacys } from '../../api/request/home'

import './style1.less'


class ActPage1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arrDetails:[],
            list:'',
            pharmacyIds:'',
            value:'',
            weeks:''
        }
    }
    componentDidMount=async()=>{
        const {} = this.state
        var tempDate = new Date();
        var days = tempDate.getDay();
        var week;
        switch (days) {
            case 1:
                week = '周一';
                break;
            case 2:
                week = '周二';
                break;
            case 3:
                week = '周三';
                break;
            case 4:
                week = '周四';
                break;
            case 5:
                week = '周五';
                break;
            case 6:
                week = '周六';
                break;
            case 0:
                week = '周日';
                break;
        }
        this.setState({
            weeks:week
        })
        let res=await discountMed()
        console.log(res);
        let arr=[]
        let details=[]
        if (res.data[0].length) {
            let nArr = res.data[0].filter((currentValue, currentIndex, selfArr) => {
                return selfArr.findIndex(x => x.discountId === currentValue.discountId) === currentIndex
              });
              console.log(nArr);
              nArr.map(item=>{
                item.state=false
                item.discountCycle=JSON.parse(item.discountCycle)
                item.discountCycle.map(v=>{
                    if (v==week) {
                        item.state=true
                    }
                })
                arr.push(item.pharmacyId)
                if (item.state) {
                    details.push(item)
                }
            })
            this.setState({
                arrDetails:details
            })
        }
        let array=[...new Set(arr)]
        if (array.length) {
            this.setState({
                pharmacyIds:array
            },()=>{
                this.allPharmacyIds()
            })
        }
    }
    allPharmacyIds=async()=>{
        const {pharmacyIds,arrDetails,list} = this.state
        let longitude = localStorage.longitude ? localStorage.longitude : 104.06576236480714
        let latitude = localStorage.latitude ? localStorage.latitude : 30.65745887548027
        let res=await allPharmacys({longitude,latitude})
        console.log(res);
        console.log(this.state.pharmacyIds);
        let list0=[]
        if (res.data[0].length) {
            res.data[0].map(i=>{
                pharmacyIds.map(v=>{
                    if (i.pharmacyId==v) {
                        list0.push(i)
                    }
                })
            })
            console.log(list0);
            this.setState({
                list:list0
            })
        }
    }
    backPage=()=>{
        window.history.back()
    }
    getValue=(e)=>{
        this.setState({
            value:e.target.value
        })
    }
    onSearch=async()=>{
        const {value,weeks} = this.state
        let res=await discountMed({keyName:value})
        console.log(res);
        let arr=[]
        let details=[]
        if (res.data[0].length) {
            let nArr = res.data[0].filter((currentValue, currentIndex, selfArr) => {
                return selfArr.findIndex(x => x.discountId === currentValue.discountId) === currentIndex
              });
              console.log(nArr);
              nArr.map(item=>{
                item.state=false
                item.discountCycle=JSON.parse(item.discountCycle)
                item.discountCycle.map(v=>{
                    if (v==weeks) {
                        item.state=true
                    }
                })
                arr.push(item.pharmacyId)
                if (item.state) {
                    details.push(item)
                }
            })
            this.setState({
                arrDetails:details
            })
            let array=[...new Set(arr)]
            console.log(array);
            if (array.length) {
                this.setState({
                    pharmacyIds:array
                },()=>{
                    this.allPharmacyIds()
                })
            }
        }else{
            this.setState({
                list:[]
            })
        }
    }
    render() {
        const {pharmacyIds,arrDetails,list} = this.state
        return (
            <div className='active1-container'>
                <div className='actpage1-header'>
                    <div className='actpage1-header-zhanwei'>
                        <i className='iconfont icon-xiangzuo' onClick={this.backPage}></i>
                        <span>超值折扣</span>
                        <span className='actpage1-search'>
                            <input type="text" placeholder='请输入商家或者商品名称' onChange={(e)=>this.getValue(e)}/>
                            <i className='iconfont icon-suosou' onClick={()=>this.onSearch()}></i>
                        </span>
                    </div>
                </div>
                <div className='actpage1-content'>
                    <img src={acttest1} alt=''/>
                </div>
                <div className='actpage1-top'>
                    {list.length?list.map(item=>(
                        <div className='actpage1-body'>
                            <div className='actpage1-body-top' onClick={()=>this.props.history.push(`/shop/${item.pharmacyId}`)}>
                                <p className='actpage1-body-img'>
                                    <img src={item.pharmacyPhoto} alt="" />
                                </p>
                                <img style={{position:'absolute',width:'40px'}} src={zk} alt="" />
                                <p className='actpage1-body-right'>
                                    <h2>{item.pharmacyName}</h2>
                                    <p>月销{item.salesVolume?item.salesVolume:0}</p>
                                    <p className='actpage1-body-b'>
                                        <p>起送￥{item.minFreight?(item.minFreight/100).toFixed(2):0} </p>
                                        <p>{item.distance?item.distance>=1000?(item.distance/1000).toFixed(1)+'km':item.distance+'m':'0m'}</p>
                                    </p>
                                </p>
                            </div>
                            <div className='actpage1-body-bottom'>
                                {arrDetails.length?arrDetails.map(i=>(
                                    <>
                                        {i.pharmacyId==item.pharmacyId?
                                        <div className='actpage1-body-bottom-all'  onClick={()=>this.props.history.push(`/med/${i.medId}`)}>
                                            <p className='actpage1-body-bottom-img'>
                                            {/* src={i.medPhoto?i.medPhoto:defaultImg} */}
                                                <img src={i.medClassify === "1" ? zhezaho : (i.medPhoto ? i.medPhoto : defaultImg)} alt="" />
                                            </p>
                                            <p className='actpage1-body-bottom-name'>{i.medName}</p>
                                            <p  className='actpage1-body-bottom-price'>
                                                <span>{i.percentage==-1?(i.discountPrice/100).toFixed(2):(Math.round(i.originPrice*i.percentage/10)/100).toFixed(2)}</span>
                                                <span>{(i.originPrice/100).toFixed(2)}</span>
                                            </p>
                                        </div>:null}
                                    </>
                                )):null}
                            </div>
                        </div>
                    )):<Nodata type='活动'/>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(ActPage1)