// import React ,{ useState, useEffect } from 'react'
// import { Modal, Toast} from 'antd-mobile'
// import { adressListRequest, updateAdressRequest } from '../../../api/request/adress'
// import { homeactivityRequest } from '../../../api/request/home'
// import { oderdetailRequest,updateAddress } from '../../../api/request/order'
//
// import getUrl from '../../../component/GetUrlParam'
// import sure from '../../../static/img/sure.png'
// import shuoming from '../../../static/img/shuoming.png'
//
// import './style.less'
// const { alert } = Modal
// export default function Nowaddress(props){
//     const userId = localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
//     const [orderDetail, setOrderDetail] = useState(null)
//     const [refresh,setRefresh] = useState(false)
//     const [cartArr,setCartArr]=useState([])
//     const [detail,setDetail]=useState()
//     const [visiable,setVisiable]=useState('none')
//     const [visiable1,setVisiable1]=useState('none')
//
//     const type = localStorage.type
//     const pharmacyId = getUrl('pharmacyId', props.location.search)
//     const orderNumber = getUrl('orderNumber', props.location.search)
//     const state = getUrl('state',props.location.search)
//
//     useEffect(() => {
//         console.log(state,props.location);
//
//         adressListRequest(userId).then(res => {
//             console.log(res);
//             if(res.code === 1) {
//                 const data = res.data[0]
//                 data.forEach((item, index) => {
//                     homeactivityRequest(pharmacyId,item.longitude,item.latitude).then(res=>{
//                         console.log(res);
//                         let data0=res.data[2]
//                         data0.map(item0=>{
//                             if (item0.pharmacyId==pharmacyId) {
//                                 console.log(item0.distance>=1000?(item0.distance/1000).toFixed(1)+'km':item0.distance+'m');
//                                 item.distance=item0.distance
//                                 console.log(data);
//                                 setOrderDetail([...data])
//                             }
//                         })
//                     })
//                 })
//             }
//         })
//         oderdetailRequest(orderNumber).then(res=>{
//             console.log(res);
//             setDetail(res.data[0][0])
//         })
//     }, [])
//
//     const backPage=()=>{
//         window.history.back()
//     }
// //   选择地址
//     const changeaddress = (e, item,index) => {
//         let medList = orderDetail
//         let newCartArr = cartArr
//         medList[index].checked = e.target.checked
//         console.log(e.target,index,medList[index].checked);
//         if(e.target.checked) {
//             newCartArr.push({
//                 medList: [{
//                   address: item.address,
//                     addressee:item.addressee,
//                     phone: item.phone,
//                     addressId:item.addressId,
//                     province:item.province,
//                     district:item.district,
//                     city:item.city,
//                     longitude:item.longitude,
//                     latitude:item.latitude,
//                     checked: true
//                 }]
//             })
//         } else {
//             newCartArr.push({
//                 medList: [{
//                   address: item.address,
//                     addressee:item.addressee,
//                     phone: item.phone,
//                     addressId:item.addressId,
//                     province:item.province,
//                     district:item.district,
//                     city:item.city,
//                     longitude:item.longitude,
//                     latitude:item.latitude,
//                     checked: false
//                 }]
//             })
//         }
//         setCartArr(newCartArr)
//         setRefresh(true)
//     }
//     console.log(cartArr);
//     const toKeepaddress = () => {
//         const params = {
//             address: cartArr[0].medList[0].address,
//             addressee:cartArr[0].medList[0].addressee,
//             phone:cartArr[0].medList[0].phone,
//             province:cartArr[0].medList[0].province,
//             district:cartArr[0].medList[0].district,
//             longitude:cartArr[0].medList[0].longitude,
//             latitude:cartArr[0].medList[0].latitude,
//             city:cartArr[0].medList[0].city,
//             isUpdateaddress:2,
//             orderNumber
//         }
//         // alert('已支付订单只能修改一次地址', '是否确认修改收货地址？', [
//         //     { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
//         //     {
//         //         text: '确认', onPress: () => {
//                     updateAddress(params).then(res => {
//                         console.log(res);
//                         if (res.code === 1) {
//                             window.history.back()
//                         }
//                     })
//         //         }
//         //     },
//         // ])
//     }
//     const toKeepaddress1 = () => {
//         window.history.back()
//     }
//     const unableChangeaddress=()=>{
//         Toast.info('该地址超出配送范围，请重新选择')
//     }
//     // orderDetail&&orderDetail.map((item, index) => {
//     //     if (item.addressDefault==='1') {
//     //         detail=item
//     //         return
//     //     }else{
//     //         if (index==0) {
//     //             detail=item
//     //         }
//     //     }
//     //     // item.addressDefault==='1'?detail=item:null
//     //     // detail=item
//     // })
//     console.log(orderDetail);
//     console.log(type);
//     const editAddress = (type, id=0) => {
//         props.history.push(`/mine/adress/edit/${type}/${id}`)
//     }
//     // 确认地址
//     const sureAddress=()=>{
//         if (cartArr.length) {
//             setVisiable('block')
//         }else{
//             setVisiable1('block')
//         }
//     }
//     return(
//         <>
//             {detail?<div className='header2'>
//                 <div className='drawback-header'>
//                     <div className='drawback-header-left' onClick={backPage}>
//                         <i className='icon-xiangzuo iconfont' style={{fontSize:'18px'}}></i>
//                         <span>修改收货地址</span>
//                     </div>
//                     <div className='drawback-header-right'>
//                         <span onClick={()=> editAddress('add')}>新增地址</span>
//                     </div>
//                 </div>
//                 <div className='shoufei'>
//                     <p>仅能修改一次：当前订单支持修改距离原地址5km内的地址，1km内可免费修改，超出1km需收费</p>
//                 </div>
//                 <div className='nowaddress'>
//                     <h3>当前下单地址</h3>
//                     <p className='address'>{detail.address}</p>
//                     <p className='name-phone'>
//                         <span>{detail.addressee}</span>
//                         <span className='phone'>{detail.phone.toString().substr(0,3)}****{detail.phone.toString().substr(7,11)}</span>
//                     </p>
//                 </div>
//                 {orderDetail&&orderDetail.length>1?
//                 <div className='more-address'>
//                     <p className='xiugai'>
//                         <h3>可更改地址</h3>
//                         <p className='shuoming'>
//                             <img src={shuoming} alt="" />
//                             <span>修改地址收费说明</span>
//                         </p>
//                     </p>
//                     {orderDetail && orderDetail.map((item, index) => (
//                     <>
//                         {type=='1'?
//                         <>
//                             {item.distance<5000?<div key={index}>
//                                 <div className='all-address' onClick={(v)=>changeaddress(v,item, index)}>
//                                     <input type="radio" name='b' />
//                                     <p className='all-address-phone'>
//                                         <p className='address'>{item.address}</p>
//                                         <p className='name-phone'>
//                                             <span>{item.addressee}</span>
//                                             <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
//                                         </p>
//                                     </p>
//                                 </div>
//                                 {index<orderDetail.length-1?<div className='xian'></div>:null}
//                             </div>
//                             :<div key={index} onClick={()=>unableChangeaddress()}>
//                                 <div className='all-address1' style={{color:'#aaa'}}>
//                                     <input type="radio" name='b' disabled />
//                                     <p className='all-address-phone1' >
//                                         <p className='address1'>{item.address}</p>
//                                         <p className='name-phone1'>
//                                             <span>{item.addressee}</span>
//                                             <span className='phone1'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
//                                         </p>
//                                     </p>
//                                 </div>
//                                 {index<orderDetail.length-1?<div className='xian'></div>:null}
//                             </div>}
//                         </>
//                         :
//                         <div key={index}>
//                             <div className='all-address' onClick={(v)=>changeaddress(v,item, index)}>
//                                 <input type="radio" name='b'  checked={item.checked?'checked':''}/>
//                                 <p className='all-address-phone'>
//                                     <p className='address'>{item.address}</p>
//                                     <p className='name-phone'>
//                                         <span>{item.addressee}</span>
//                                         <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
//                                     </p>
//                                 </p>
//                             </div>
//                             {index<orderDetail.length-1?<div className='xian'></div>:null}
//                         </div>}
//                     </>
//                     ))}
//                 </div>
//                 :
//                 <div className='no-more-address'>
//                     <p className='xiugai'>
//                         <h3>可更改地址</h3>
//                         <p className='shuoming'>
//                             <img src={shuoming} alt="" />
//                             <span>修改地址收费说明</span>
//                         </p>
//                     </p>
//                     <p className='no-xiugai'>暂无符合更改条件的地址，请点击新增地址</p>
//                 </div>
//                 }
//                 <div className='sure-bottom' onClick={()=>sureAddress()}>
//                     <img src={sure} alt="" />
//                     <span>确认地址</span>
//                 </div>
//                 {visiable=='block'?<div className='del-address'>
//                     <p className='del-sure'>是否确认修改收货地址？</p>
//                     {state!='-2'?<p style={{textAlign:'center',color:"#999"}}>已支付订单只能修改一次地址</p>:null}
//
//                     <p className='del-bottom'>
//                         <span onClick={()=>setVisiable('none')}>取消</span>
//                         <span onClick={()=>toKeepaddress()}>确认</span>
//                     </p>
//                 </div>:null}
//                 {visiable1=='block'?<div className='del-address'>
//                     <p className='del-sure'>暂不修改收货地址？</p>
//                     <p className='del-bottom'>
//                         <span onClick={()=>setVisiable1('none')}>取消</span>
//                         <span onClick={()=>toKeepaddress1()}>确认</span>
//                     </p>
//                 </div>:null}
//             </div>:null}
//         </>
//     )
// }

import React ,{ useState, useEffect } from 'react'
import {Modal, Toast} from 'antd-mobile'
import { adressListRequest, updateAdressRequest } from '../../../api/request/adress'
import {getaddress, getDistance, updateAddress} from '../../../api/request/order'

import sure from '../../../static/img/sure.png'
import shuoming from '../../../static/img/shuoming.png'
import getUrl from '../../../component/GetUrlParam'
import './style.less'
const { alert } = Modal
export default function Nowaddress(props){
    const userId = localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [orderDetail, setOrderDetail] = useState(null)
    const [orderDetail2, setOrderDetail2] = useState(null)
    const [orderDetail1, setOrderDetail1] = useState(null)
    const [detail, setDetail] = useState(null)
    const [refresh,setRefresh] = useState(false)
    const [cartArr,setCartArr]=useState({})
    const [addressId, setaddressId] = useState(null)
    const [addressId1, setaddressId1] = useState(null)
    const orderNumber = getUrl('orderNumber', props.location.search)
    const pharmacyId = getUrl('pharmacyId', props.location.search)

    useEffect(() => {
        let a
        getaddress({orderNumber}).then(res=>{
            console.log(res)
            if(res.code === 1&&res.data[0].length) {
                a=res.data[0][0].addressId
                setCartArr(res.data[0][0])
                setDetail(res.data[0][0])
            }
        })
        adressListRequest(userId).then(res => {
            console.log(res);
            let arr=[]
            let arr1=[]
            if(res.code === 1&&res.data[0].length) {
                res.data[0].map(item=>{
                    item.distance=0
                    item.checked=false
                    let params={
                        latitude: item.latitude,
                        longitude: item.longitude,
                        pharmacyId: pharmacyId
                    }
                    getDistance(params).then(res00=>{
                        console.log(res00)
                        if (res00.data[0].length){
                            console.log(a)
                            item.distance=res00.data[0][0].distance
                            arr.push(item)
                            if (item.addressId==a){
                                item.checked=true
                            }
                        }
                        console.log(arr,arr1)
                        setOrderDetail([...arr])
                    })
                })
            }
        })

    }, [userId])
    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])
    const backPage=()=>{
        window.history.back()
    }
//   选择地址
    const changeaddress = (e, item,index) => {
        console.log(e.target.checked)
        let medList = orderDetail
        medList.map(i=>{
            if (i.addressId==item.addressId){
                i.checked=e.target.checked
                setCartArr(i)
            }else{
                i.checked=false
            }
        })
        setOrderDetail([...medList])
        setRefresh(true)

    }
    const changeaddress1=(v,i)=>{
        console.log(i)
        Toast.info('该地址不在配送范围内，请重新选择')
    }
    console.log(cartArr);
    const toKeepaddress = () => {
        const params = {
            orderNumber:orderNumber,
            address: cartArr.address,
            addressee:cartArr.addressee,
            phone:cartArr.phone,
            addressId:cartArr.addressId,
            province:cartArr.province,
            district:cartArr.district,
            longitude:cartArr.longitude,
            latitude:cartArr.latitude,
            city:cartArr.city,
            isUpdateaddress:cartArr.addressId==detail.addressId?1:2
        }
        alert('是否确认修改收货地址？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    updateAddress(params).then(res => {
                        console.log(res);
                        if (res.code === 1) {
                            window.history.back()
                        }
                    })
                }
            },
        ])
    }
    // orderDetail&&orderDetail.map((item, index) => (
    //     item.addressDefault==='1'?detail=item:null
    //     // detail=item
    // ))
    console.log(orderDetail);
    console.log(detail);
    const editAddress = (type, id=0) => {
        props.history.push(`/mine/adress/edit/${type}/${id}`)
    }
    return(
        <>
            <div className='header2'>
                <div className='drawback-header'>
                    <div className='drawback-header-left' onClick={backPage}>
                        <i className='icon-xiangzuo iconfont'></i>
                        <span>修改收货地址</span>
                    </div>
                    <div className='drawback-header-right'>
                        <span onClick={()=> editAddress('add')}>新增地址</span>
                    </div>
                </div>
                <div className='shoufei'>
                    <p>仅能修改一次：当前订单支持修改距离原地址5km内的地址，1km内可免费修改，超出1km需收费</p>
                </div>
                <div className='nowaddress'>
                    <h3>当前下单地址</h3>
                    <p className='address'>{detail&&detail.address}</p>
                    <p className='name-phone'>
                        <span>{detail&&detail.addressee}</span>
                        <span className='phone'>{detail&&detail.phone.toString().substr(0,3)}****{detail&&detail.phone.toString().substr(7,11)}</span>
                    </p>
                </div>
                {orderDetail&&orderDetail.length>1?
                    <div className='more-address'>
                        <p className='xiugai'>
                            <h3>可更改地址</h3>
                            <p className='shuoming'>
                                <img src={shuoming} alt="" />
                                <span>修改地址收费说明</span>
                            </p>
                        </p>
                        {orderDetail ? orderDetail.map((item, index) => (
                            <div key={index}>
                                {item.distance<=5000?<div className='all-address' onClick={(v)=>changeaddress(v,item, index)}>
                                        <input type="radio" name='b' checked={item.checked?'checked':''}/>
                                        <p className='all-address-phone'>
                                            <p className='address'>{item.address}</p>
                                            <p className='name-phone'>
                                                <span>{item.addressee}</span>
                                                <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
                                            </p>
                                        </p>
                                    </div>
                                    :null}
                                {index<orderDetail.length-1?<div className='xian'></div>:null}
                            </div>)):null}
                        {orderDetail ? orderDetail.map((item, index) => (
                            <div key={index}>
                                {item.distance>5000?<div className='all-address' onClick={(v)=>changeaddress1(v,item, index)}>
                                    <input type="radio" name='b' disabled/>
                                    <p className='all-address-phone'>
                                        <p className='address' style={{color:'#999'}}>{item.address}</p>
                                        <p className='name-phone' style={{color:'#999'}}>
                                            <span>{item.addressee}</span>
                                            <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
                                        </p>
                                    </p>
                                </div>:null}
                                {index<orderDetail.length-1?<div className='xian'></div>:null}
                            </div>
                        )):null}
                    </div>
                    :
                    <div className='no-more-address'>
                        <p className='xiugai'>
                            <h3>可更改地址</h3>
                            <p className='shuoming'>
                                <img src={shuoming} alt="" />
                                <span>修改地址收费说明</span>
                            </p>
                        </p>
                        <p className='no-xiugai'>暂无符合更改条件的地址，请点击新增地址</p>
                    </div>
                }
                <div className='sure-bottom' onClick={()=>toKeepaddress()}>
                    <img src={sure} alt="" />
                    <span>保持地址</span>
                </div>
            </div>
        </>
    )
}