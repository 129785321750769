import React, { Component } from 'react'
import "./style.less"
import { Switch ,Toast} from 'antd-mobile'
import FH from "../../../../static/img/mine/fanhui.png"
import { getUserInvoice, addUserInvoice, changeUserInvoice,changeUserInvoiceMR } from "../../../../api/request/user"
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            checked: false,
            name: "",
            num: "",
            address: "",
            phone: "",
            bankname: "",
            banknum: "",
            userId: localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            allarr: [],
            id1:null,
            id2:null
        }
    }
    componentDidMount = async () => {
       this.getone()
    }
    //获取原有模板数据
    getone=async()=>{
        const res = await getUserInvoice({
            userId: this.state.userId
        })
        const dada = res.data[0]
        let newarr = []
        dada.forEach(item => {
            if (item.invoice_type == "专用发票") {
                console.log(item);
                newarr.push(item)
                this.setState({
                    checked: item.invoiceDefault == 1 ? true : false,
                    name: item.invoice_title,
                    num: item.invoice_Identifier,
                    address: item.invoice_address,
                    phone: item.invoice_phone,
                    bankname: item.invoice_bank,
                    banknum: item.invoice_bank_account,
                    id1:item.invoiceId
                })
            }else{
                this.setState({
                    id2:item.invoiceId
                })
            }
        });
        this.setState({
            allarr: newarr
        })
    }
    toggleChecked = (checked) => {
        this.setState({
            checked
        })
    }
    changeone = (code, e) => {
        switch (code) {
            case 1:
                //console.log(e.target.value);
                this.setState({
                    name: e.target.value
                })
                break;
            case 2:
                //console.log(e.target.value);
                this.setState({
                    num: e.target.value
                })
                break;
            case 3:
                //console.log(e.target.value);
                this.setState({
                    address: e.target.value
                })
                break;
            case 4:
                //console.log(e.target.value);
                this.setState({
                    phone: e.target.value
                })
                break;
            case 5:
                //console.log(e.target.value);
                this.setState({
                    bankname: e.target.value
                })
                break;
            case 6:
                //console.log(e.target.value);
                this.setState({
                    banknum: e.target.value
                })
                break;
            default:
                return
        }
    }
    //点击存储
    Saveall = async () => {
        const { allarr, userId, checked, name, num, address, phone, bankname, banknum,id2 } = this.state
       
        if (name && num && address && phone && bankname && banknum) {
            if (allarr.length) {
                //console.log(allarr);
                if (checked) {
                    const res=await changeUserInvoiceMR({
                        invoiceId:id2,
                        invoiceDefault:0
                    })
                }
                const res=await changeUserInvoice({
                    invoiceDefault: checked == true ? 1 : 0,
                    userId: userId,
                    templateName: "专用发票",
                    invoicetype: "专用发票",
                    invoicetitle: name,
                    invoiceIdentifier: num,
                    invoicephone: phone,
                    invoicemail: "无",
                    invoiceaddress: address,
                    invoicebank: bankname,
                    invoicebankaccount: banknum,
                    invoiceId:allarr[0].invoiceId,
                    email:"无"
                })
                if (res.code==1) {
                    Toast.success("修改成功",1)
                }else{
                    Toast.fail("网络出错了")
                }
            } else {
                const res = await addUserInvoice({
                    invoiceDefault: checked == true ? 1 : 0,
                    userId: userId,
                    templateName: "专用发票",
                    invoiceType: "专用发票",
                    invoiceTitle: name,
                    invoiceIdentifier: num,
                    invoicePhone: phone,
                    invoiceMail: "无",
                    invoiceAddress: address,
                    invoiceBank: bankname,
                    invoice_bank_account: banknum,
                    email:"无"
                })
               if (res.code==1) {
                   Toast.success("添加成功",1)
                   this.props.history.push("/mine/storeticket")
               }else{
                   Toast.fail("网络出错了")
               }
            }

        } else {
            Toast.fail("请将信息填写完整")
        }
    }
    render() {
        const { checked, name, num, address, phone, bankname, banknum } = this.state
        return (
            <div style={{backgroundColor:"white"}}>
                <div className="zedittop">
                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>开票信息</h2>
                </div>
                <div className="zeditbox">
                    <div className="zeditbox-text">
                        <h2>公司名称：</h2>
                        <input className="zeditbox-text-item" type="text" value={name} onChange={(e) => this.changeone(1, e)} />
                    </div>
                    <div className="zeditbox-text">
                        <h2>纳税人识别号：</h2>
                        <input className="zeditbox-text-item" type="text" value={num} onChange={(e) => this.changeone(2, e)} />
                    </div>
                    <div className="zeditbox-text">
                        <h2>注册地址：</h2>
                        <input className="zeditbox-text-item" type="text" value={address} onChange={(e) => this.changeone(3, e)} />
                    </div>
                    <div className="zeditbox-text">
                        <h2>注册电话：</h2>
                        <input className="zeditbox-text-item" type="text" value={phone} onChange={(e) => this.changeone(4, e)} />
                    </div>
                    <div className="zeditbox-text">
                        <h2>开户银行：</h2>
                        <input className="zeditbox-text-item" type="text" value={bankname} onChange={(e) => this.changeone(5, e)} />
                    </div>
                    <div className="zeditbox-text">
                        <h2>银行账号：</h2>
                        <input className="zeditbox-text-item" type="text" value={banknum} onChange={(e) => this.changeone(6, e)} />
                    </div>
                    <div className="moren">
                        <span>设为默认发票</span>
                        <Switch
                            checked={checked}
                            style={{ 'checked-color': '#ff8f1f' }}
                            onChange={checked => {
                                this.toggleChecked(checked)
                            }}
                        />
                    </div>
                    <button className="zedit-btn" onClick={this.Saveall}>保存</button>
                </div>
            </div>
        )
    }
}
