import React, { Component } from 'react'
import FH from "../../../static/img/mine/fanhui.png"
import WH from "../../../static/img/mine/wenhao.png"
import "./style.less"
import {addWallet, walletWithdrawal,walletBalance,walletList} from '../../../api/request/wallet'
export default class index extends Component {
    constructor(props){
        super(props);
        this.state={
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3,
            balance:''
        }
    }
    componentWillMount() {
        let params={
            userId:this.state.userId
        }
        console.log(this.state.userId);
        walletBalance(params).then((res)=>{
            this.setState({
                balance:res.data[0].length?res.data[0][0].Balance/100:0
            })
        })
    }
    goTS=()=>{
        this.props.history.push("/mine/storemoney/Withdrawal")
    }
    goMX=()=>{
        this.props.history.push("/mine/storemoney/detailed")  
    }
    render() {
        const {balance}=this.state
        return (
            <div>
                <div className="money-box">
                    <div className="moneytop">
                        <img src={FH} alt="" onClick={() => window.history.back()} /><h2>门店钱包</h2><p><img src={WH} alt="" /> 提现规则</p>
                    </div>
                    <div className="money-text">
                        <p>账户余额</p>
                        <p className="allmoney">{balance?balance:0}</p>
                        <p onClick={this.goMX}>余额明细   ＞ </p>
                    </div>
                    <div className="money-btn">
                        <button onClick={this.goTS}>提现</button>
                    </div>
                </div>
            </div>
        )
    }
}
