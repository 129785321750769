import React, { Component } from 'react'
import { Modal, Toast } from "antd-mobile-v5"
import "./style.less"
import icon1 from "../../../static/img/fanhuihei.png"
import icon3 from "../../../static/img/five.png"
import Nodata from "../../../component/Nodata";
import { docterlist, getGzdoc,patientinfo} from "../../../api/request/wzpatient"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: '',
            docList: [],
            list: []
        }
    }
    componentDidMount = () => {
        const { patientId, docList } = this.state
        let a=localStorage.getItem('phone')
        console.log(a);
        let b
        if (a) {
            b=a.substring(1,a.length-1)
        }else{
            b=localStorage.getItem('userAcount1')
        }
        // 获取用药人id(问诊端)
        patientinfo({patientPhone:b}).then(res1=>{
            console.log(res1);
            if (res1.code==1) {
                this.setState({
                    patientId:res1.data.data[0][0].patientId
                })
                getGzdoc({ patientId:res1.data.data[0][0].patientId}).then(res => {
                    console.log(res);
                    let arr = []
                    if (res.code == 1) {
                        if (res.data.data[0][0].gzDocterId) {
                            let a = res.data.data[0][0].gzDocterId.split(",")
                            console.log(a);
                            this.setState({
                                docList: a
                            })
                            docterlist().then(res1 => {
                                console.log(res1);
                                if (res1.code == 1) {
                                    for (let i = 0; i < res1.data.data[0].length; i++) {
                                        for (let j = 0; j < a.length; j++) {
                                            if (res1.data.data[0][i].docterId == parseInt(a[j])) {
                                                arr.push(res1.data.data[0][i])
                                            }
        
                                        }
                                    }
                                }
                                console.log(arr);
                                this.setState({
                                    list: arr
                                })
                            })
                        }
                    }
                })
            }
        })
    }
    render() {
        const { list } = this.state
        return (
            <div className='setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />   关注医生
                </div>
                {list.length?list.map(item => (
                    <div className='setprescribed'>
                        <div className='info-1'>
                            <div className='box-container'>
                                <div className='container-box'>
                                    <img style={{ borderRadius: "50%" }} src={item.dcocterAvatar} alt="" />
                                    <div className='box-text'>
                                        <div>
                                            <p className='box-name'>
                                                <span>{item.docterName}</span><span>{item.title}</span><span>{item.depName}</span>
                                            </p>
                                            <div className='box-title'>
                                                <span className='sj'>三甲</span> <span className='yy'>{item.hospital}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='box-evaluate'>
                                <div>
                                    <img src={icon3} alt="" /> <span style={{ color: '#1F87FF' }}>4.8 </span><span> / </span>
                                </div>
                                <div>
                                    <span>接诊次数 </span> <span style={{ color: '#1F87FF' }}>{item.countNum} </span><span> / </span>
                                </div>

                                <div>
                                    <span>回复速度:</span> <span style={{ color: '#1F87FF' }}>较快</span>
                                </div>
                            </div>
                            <div className='box-introduce'>
                                <span>专业擅长：</span> {item.expert}
                            </div>
                        </div>
                    </div>)):<Nodata type='医生'/>}
            </div>
        )
    }
}
