import React, { useEffect, useState } from "react";
import { List, WhiteSpace, Modal, Toast } from "antd-mobile";
import { getuserinfo } from "../../../api/request/user";
import { connect } from "react-redux";
import icon from "../../../static/img/mine/logo.png";
import head from "../../../static/img/mine/head.png";
import icon6 from "../../../static/img/morenhead.png";
import { resetdirect } from "../../../redux/user.redux";

import Head from "../../../component/Head/index";
// import FucButton from '../../../baseui/fuctionbutton/index'

import "./style.less";
import { baseUrl, cacheUrl } from "../../../publicurl";

const Item = List.Item;
const alert = Modal.alert;

function Set(props) {
  const [pass, setPass] = useState("");
  const phone = localStorage.userAcount2;
  const userAcount =
    localStorage.type == 1
      ? localStorage.userAcount1
      : localStorage.type == 2
      ? localStorage.userAcount2
      : localStorage.userAcount3;
  const { redirectDispatch } = props;
  const type = localStorage.getItem("type");
  const [avatar, setAvatar] = useState("");
  const [phone1, setPhone1] = useState("");
  const [visiable, setVisiable] = useState("none");

  useEffect(() => {
    // console.log(phone);
    getuser();
  }, []);
  const getuser = async () => {
    const res = await getuserinfo({
      userAcount: userAcount,
    });
    console.log(res);
    setPass(res.data[0][0].userpassword);
    setAvatar(res.data[0][0].useravatar);
    setPhone1(res.data[0][0].useracount);
  };
  const loginOut = () => {
    alert("确认登出吗？", "", [
      { text: "取消", onPress: () => console.log("cancel") },
      {
        text: "确认",
        onPress: () => {
          //let singleUrl = localStorage.env==='single'?`/?env=${localStorage.env}&pharmacyId=${localStorage.pharmacyId}`:'/login/login1';
          // localStorage.clear();
          // if (window.localStorage.getItem("type") == "1") {
          localStorage.removeItem("userName1");
          localStorage.removeItem("userId1");
          localStorage.removeItem("userVip1");
          localStorage.removeItem("userAvatar1");
          localStorage.removeItem("userAcount1");
          localStorage.removeItem("wxuserOpenid");
          localStorage.removeItem("userId");
          localStorage.removeItem("phonestate");

          // } else if (window.localStorage.getItem("type") == "2") {
          //     localStorage.removeItem("userName2")
          //     localStorage.removeItem("userId2")
          //     localStorage.removeItem("userVip2")
          //     localStorage.removeItem("userAvatar2")
          //     localStorage.removeItem("userAcount2")
          // } else {
          //     localStorage.removeItem("userName3")
          //     localStorage.removeItem("userId3")
          //     localStorage.removeItem("userVip3")
          //     localStorage.removeItem("userAvatar3")
          //     localStorage.removeItem("userAcount3")
          // }
          // redirectDispatch('/mine')
          props.history.push("/login/Login1");
        },
      },
    ]);
  };
  const loginOut1 = () => {
    setVisiable("block");
  };
  const onDelete = () => {
    setVisiable("none");
    localStorage.removeItem("userName1");
    localStorage.removeItem("userId1");
    localStorage.removeItem("userVip1");
    localStorage.removeItem("userAvatar1");
    localStorage.removeItem("userAcount1");
    localStorage.removeItem("wxuserOpenid");
    localStorage.removeItem("userId");
    localStorage.removeItem("phonestate");
    redirectDispatch("/mine");
    props.history.push("/login/Login1");
  };
  const clearall = () => {
    window.localStorage.clear();
    if (type == "2") {
      window.location.href =
        "http://pf-h5.sczyzk.com/index.html#/?env=single&pharmacyId=25";
    } else if (type == "1") {
      window.localStorage.setItem("type", 1);
      window.localStorage.setItem("pharmacyId", 0);
      // window.location.href="http://h5.sczyzk.com/index.html#/"
      // window.location.href = `${baseUrl}?/index.html#/`;
      window.location.href = `${cacheUrl}/index.html#/`;
    }

    // Toast.success("暂未开放")
  };
  const ownall = () => {
    props.history.push("/mine/informationlist");
  };
  return (
    <div className="myset-list">
      <Head title="设置" />
      <WhiteSpace />
      <List className="list" style={{ minHeight: "200px" }}>
        {type == "2" ? (
          <Item arrow="horizontal">
            店铺logo <img src={icon} style={{ float: "right" }} alt="" />
          </Item>
        ) : type == "1" ? (
          <Item arrow="horizontal">
            头像{" "}
            <img
              src={avatar ? avatar : icon6}
              style={{ float: "right" }}
              alt=""
            />
          </Item>
        ) : null}
        <Item arrow="horizontal">
          手机号{" "}
          <span style={{ float: "right", color: "#C2C5CB", fontSize: "15px" }}>
            {phone1
              ? phone1.toString().substr(0, 3) +
                "****" +
                phone1.toString().substr(7)
              : null}
          </span>
        </Item>
        <Item
          arrow="horizontal"
          onClick={() => props.history.push("/mine/set/addpass")}
        >
          账号密码
        </Item>
        {/* <Item arrow='horizontal' onClick={() => props.history.push('/mine/adress')}>地址管理</Item> */}
        {/* <Item arrow='horizontal' onClick={() => {}}>设置字体大小</Item>
                <Item arrow='horizontal' onClick={() => {}}>消息推送设置</Item> */}
        <Item extra="21.67M" arrow="horizontal" onClick={() => clearall()}>
          清除本地缓存
        </Item>
        <Item arrow="horizontal">
          版本号{" "}
          <span style={{ float: "right", color: "#C2C5CB", fontSize: "15px" }}>
            v1.0.0.0
          </span>
        </Item>
        <Item extra="" arrow="horizontal" onClick={() => ownall()}>
          个人信息清单
        </Item>
      </List>
      <WhiteSpace />
      {/* <List className="list">
            <Item extra={'V1.0.00'}>版本号</Item> 
            </List> */}
      {type == "2" ? (
        <div className="myset-logout flex-row" onClick={() => loginOut()}>
          退出登录
        </div>
      ) : (
        <div
          className="myset-logout flex-row"
          style={{ backgroundColor: "#2086fe" }}
          onClick={() => loginOut1()}
        >
          退出登录
        </div>
      )}
      {visiable == "block" ? (
        <div className="del-address-outer">
          <div className="del-address">
            <p className="del-sure">是否确认退出登录？</p>
            <p className="del-bottom">
              <span onClick={() => setVisiable("none")}>取消</span>
              <span onClick={() => onDelete()}>确认</span>
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({});

// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
  return {
    redirectDispatch(data) {
      dispatch(resetdirect(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Set);
